import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import TextField from '../../../../Components/Form/TextField'
import ValidationError from '../../../../Components/Errors/ValidationError'
import ViewRateCard from '../../../Dashboard/HireNewTalent/StepsDetail/ViewRateCard'
import SubmitBtn from '../../../../Components/Auth/Onboarding/SubmitBtn'

import imgSrc from '../../../../assets/img/mindrazr-mainlogo.svg'
import RoleSearch from '../../../../Components/Hiring/HireTalent/RoleSearch'
import StepTabs from '../../../Dashboard/HireNewTalent/StepTabs'
import LeftCard from '../../../../Components/Auth/LeftCard'
import RegisterActionBtns from './RegisterActionBtns'
import SelectField from '../../../../Components/Form/SelectField'
import { useState, useEffect } from 'react'
import { utilities } from '../../../../Config/Helper'
import DomainSearch from '../../../../Components/Hiring/HireTalent/DomainSearch'
import RegisterViewRateCard from './RegisterViewRateCard'
import ExperienceSelectField from '../../../../Components/Hiring/HireTalent/ExperienceSelectField'
import NumberField from '../../../../Components/Form/NumberField'

const validationSchema = yup.object({
    //domain_name: yup.string().min(2, 'Company name must be at least 2 characters').max(50,'Company name must not be greater than 50 characters').required('Company name field is required.'),
    stack_id: yup.string().required('Role is required.'),
    team_size: yup
        .number()
        .min(1, 'Openings must be greater than 1')
        .max(50, 'Openings must be less than 50')
        .required('Openings is required.'),
    experience: yup.string().required('Experience is required.'),
    skill_ids: yup
        .array()
        .min(1, 'At least 1 skill is required')
        .of(yup.string().required('At least 1 skill is required'))
        .required('At least 1 skill is required'),
})

const RegisterStep1 = ({
    nextStep,
    hiringTalent,
    onhiringProcess,
    isLoading,
    skipStep,
    commonHandleShow,
}) => {
    const experience = [
        { key: '0-1', value: 'Beginner (0-1 Y)' },
        { key: '1-3', value: 'Intermediate (1-3 Y)' },
        { key: '3-7', value: 'Advance (3-7 Y)' },
        { key: '7+', value: 'Expert (7+ Y)' },
    ]

    const [skills, setSkills] = useState(hiringTalent.related_skills || [])
    const [skillsSetArrayId, setskillsSetArrayId] = useState(
        hiringTalent.skill_ids || []
    )
    const [selectedSkills, setSelectedSkills] = useState(
        hiringTalent.skills_name || []
    )

    let user = localStorage.getItem('user')
    user = JSON.parse(user) || ''

    const [industries, setIndustries] = useState([])
    const [stacks, setStacks] = useState([])

    // for store delete // test ci cd
    const uniqueId = Math.random().toString(36).substring(2)
    //

    useEffect(() => {
        ;(async () => {
            let industries = await utilities('industry')
            let stacks = await utilities('role-skill')
            setIndustries(industries)
            setStacks(stacks)
        })()
    }, [])

    const options = industries.map((item) => ({ value: item, label: item }))

    const handleRelatedSkills = (value, cb) => {
        setSkills(value.technologies)
    }
    const handleSkillChange = (e, f) => {
        var skillsArrayId = [...skillsSetArrayId]
        if (skillsArrayId.indexOf(e.id) === -1) {
            skillsArrayId.push(e.id)
        } else {
            skillsArrayId = skillsArrayId.filter((item) => item !== e.id)
        }
        setskillsSetArrayId(skillsArrayId)
        f.setFieldValue('skill_ids', skillsArrayId)
        ///
        var skillsArrayName = [...selectedSkills]
        if (skillsArrayName.indexOf(e.name) === -1) {
            skillsArrayName.push(e.name)
        } else {
            skillsArrayName = skillsArrayName.filter((item) => item !== e.name)
        }
        setSelectedSkills(skillsArrayName)
        f.setFieldValue('skills_name', skillsArrayName)
    }
    return (
        <>
            <div className="dash-main-heading text-center mt-40 mb-40">
                <span className="dash-gradient">
                    Let's find an expert for you.
                </span>
            </div>
            <div className="dash-custom-form">
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        unique_id: hiringTalent.unique_id || uniqueId,
                        domain: hiringTalent.domain || '',
                        role: hiringTalent.role || '',
                        stack_id: hiringTalent.stack_id || '',
                        skill_ids: hiringTalent.skill_ids || [],
                        skills_name: hiringTalent.skills_name || [],
                        experience: hiringTalent.experience || '',
                        team_size: hiringTalent.team_size || '',
                        related_skills: hiringTalent.related_skills || [],
                        last_step: 1,
                    }}
                    onSubmit={(values) => {
                        // data send serve side
                        //document.querySelector('#my-role-search').style.display = 'none';
                        onhiringProcess(values)
                    }}
                >
                    <Form className="custom-form mt-40 row" autoComplete="off">
                        <div className="col-12">
                            <Field
                                name="domain"
                                options={options}
                                optionKey="label"
                                as={DomainSearch}
                            />
                        </div>
                        <div className="col-12">
                            <label>Select Role Details</label>
                        </div>
                        <Field
                            classAdd="col-md-12"
                            name="role"
                            stacks={stacks}
                            setSkills={setSkills}
                            setskillsSetArrayId={setskillsSetArrayId}
                            setSelectedSkills={setSelectedSkills}
                            handleRelatedSkills={handleRelatedSkills}
                            component={RoleSearch}
                        />
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <Field
                                        name="experience"
                                        placeholder="Experience (in Years)"
                                        data={experience}
                                        component={ExperienceSelectField}
                                    />
                                </div>
                                <div className="col-sm-6 col-12">
                                    <NumberField
                                        asterisks="asterisks"
                                        type="text"
                                        placeholder="Number of Openings"
                                        name="team_size"
                                    />
                                </div>
                            </div>
                        </div>

                        {skills.length > 0 && (
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="dash-light-label">
                                        Select Related Skills
                                    </label>
                                    <div className="dash-skill-list">
                                        <Field name="skill_ids">
                                            {({ field, form }) =>
                                                skills.map((skl) => (
                                                    <div
                                                        key={skl.id}
                                                        className={
                                                            skillsSetArrayId.includes(
                                                                skl.id
                                                            )
                                                                ? ' ds-tag selected'
                                                                : 'ds-tag'
                                                        }
                                                    >
                                                        {skl.name}
                                                        <input
                                                            {...field}
                                                            onChange={(e) =>
                                                                handleSkillChange(
                                                                    skl,
                                                                    form
                                                                )
                                                            }
                                                            type="checkbox"
                                                            data-id={skl.id}
                                                            className="skill-radio-btn"
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </Field>
                                        <div className="error-block">
                                            <ValidationError name="skill_ids" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className="col-12">
                                <button type="button" className="view-rate-btn" onClick={commonHandleShow}>View Rate Card</button>
                            </div> */}
                        <RegisterActionBtns
                            nextStep={nextStep}
                            isLoading={isLoading}
                            skipStep={skipStep}
                        />
                    </Form>
                </Formik>
            </div>
        </>
    )
}

export default RegisterStep1
