import { Link } from 'react-router-dom'

const DigitalGetStarted = () => {
    return (
        <>
            <section className="transforms-brand-block">
                <div className="container">
                    <div className="main-heading text-center">
                        Innovate products that transform your brand
                    </div>
                    <div className="sub-heading black-text mt-20 text-center mxw-70 mlr-auto">
                        Weteams platform simplifies your digital product journey
                        from idea to delivery by onboarding experts & teams,
                        management & payouts
                    </div>
                    <div className="text-center mt-40">
                        <Link to="/contact" className="theme-dark-btn">
                            Get started
                        </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DigitalGetStarted
