import Layout from '../../Layouts/Layout'

const Faqs = () => {
    return (
        <>
            <Layout>
                <div className="container">
                    <div className="faq-banner">
                        <div className="main-heading text-center fw-600 d-block white-text font-3-2em">
                            <span className="fw-400">I have a</span> Question
                        </div>
                    </div>
                    <div className="faq-wrap">
                        <div className="common-content faq-accordion">
                            <div className="main-heading text-center text-uppercase">
                                Frequently Asked Questions
                            </div>
                            <div
                                className="accordion mt-5"
                                id="accordionExample"
                            >
                                <div className="faq-custom-tabs">
                                    <ul
                                        className="nav nav-tabs"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li
                                            className="nav-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link active"
                                                id="getting-started-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#getting-started"
                                                type="button"
                                                role="tab"
                                                aria-controls="getting-started"
                                                aria-selected="true"
                                            >
                                                Getting Started
                                            </button>
                                        </li>
                                        <li
                                            className="nav-item"
                                            role="presentation"
                                        >
                                            <button
                                                className="nav-link"
                                                id="pricing-payment-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pricing-payment"
                                                type="button"
                                                role="tab"
                                                aria-controls="pricing-payment"
                                                aria-selected="false"
                                            >
                                                Pricing & Payment
                                            </button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                      <button className="nav-link" id="vendor-faqs-tab" data-bs-toggle="tab" data-bs-target="#vendor-faqs" type="button" role="tab" aria-controls="vendor-faqs" aria-selected="false">VENDOR FAQs</button>
                    </li> */}
                                    </ul>
                                    <div
                                        className="tab-content mt-5"
                                        id="myTabContent"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="getting-started"
                                            role="tabpanel"
                                            aria-labelledby="getting-started-tab"
                                        >
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading1"
                                                >
                                                    <button
                                                        className="accordion-button sm-title text-start fw-600 lh-1-4em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse1"
                                                        aria-expanded="true"
                                                        aria-controls="collapse1"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        How to get started with
                                                        Weteams?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse1"
                                                    className="accordion-collapse collapse show"
                                                    aria-labelledby="heading1"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Getting started with
                                                            Weteams couldn’t be
                                                            easier. All you need
                                                            to do is sign up on
                                                            our platform here at
                                                            https://weteams.io/signin,
                                                            give us some basic
                                                            information about
                                                            yourself and the
                                                            company, and voila
                                                            you can start
                                                            posting your
                                                            requirements on the
                                                            platform and start
                                                            getting vetted
                                                            talent within 72
                                                            hours. To assist you
                                                            in this journey, you
                                                            have your dedicated
                                                            Account Manager who
                                                            we love to call
                                                            “Weteams
                                                            Specialist”.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading2"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-4em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse2"
                                                        aria-expanded="false"
                                                        aria-controls="collapse2"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Is there any minimum
                                                        project size that
                                                        Weteams works on?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse2"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading2"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Not at all! We are
                                                            here to help you
                                                            with any project,
                                                            regardless of size.
                                                            We have
                                                            professionals that
                                                            have the experience
                                                            and skills to work
                                                            on projects ranging
                                                            from small ones to
                                                            long-term
                                                            engagements. You can
                                                            assemble a team with
                                                            Weteams to work on
                                                            your project.
                                                            Weteams typically
                                                            does not accept
                                                            engagements on a
                                                            project basis,
                                                            however, under some
                                                            contingencies, we
                                                            can put together a
                                                            team to work on your
                                                            project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading3"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-4em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse3"
                                                        aria-expanded="false"
                                                        aria-controls="collapse3"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What technologies do you
                                                        work with?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse3"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading3"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            With Weteams you can
                                                            build digital
                                                            solutions with all
                                                            the cutting-edge
                                                            technology on the
                                                            market, including
                                                            Blockchain, IoT,
                                                            NFT, AI/ML, and many
                                                            more. We understand
                                                            that businesses’
                                                            needs are often
                                                            unique and our
                                                            expert teams are
                                                            well-versed in
                                                            different
                                                            technologies to
                                                            cater to every need.
                                                            Check out our list
                                                            HERE.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading4"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-4em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse4"
                                                        aria-expanded="false"
                                                        aria-controls="collapse4"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Are there any upfront
                                                        recruitment costs
                                                        involved?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse4"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading4"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Absolutely not. With
                                                            Weteams you don't
                                                            have to worry about
                                                            any upfront
                                                            recruitment costs.
                                                            We have a large pool
                                                            of vetted experts to
                                                            choose from, so you
                                                            can build the
                                                            perfect team that
                                                            fits your needs and
                                                            budget. Billing
                                                            starts only when you
                                                            hire a talent.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading5"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse5"
                                                        aria-expanded="false"
                                                        aria-controls="collapse5"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        How long does it take to
                                                        start?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse5"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading5"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            With Weteams, you
                                                            can start building
                                                            your digital
                                                            solutions in no
                                                            time. The hiring
                                                            process can take
                                                            anywhere from 72
                                                            hours to a week,
                                                            depending on your
                                                            requirements.
                                                            However, once an
                                                            expert is hired,
                                                            they can begin
                                                            working immediately.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading6"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse6"
                                                        aria-expanded="false"
                                                        aria-controls="collapse6"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What happens if I am not
                                                        satisfied with a
                                                        professional?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse6"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading6"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Our vetting process
                                                            means you can rest
                                                            assured you will be
                                                            getting the best
                                                            talent for your
                                                            projects and in case
                                                            you are not
                                                            satisfied with the
                                                            work done by one of
                                                            our talent, we will
                                                            make sure to find a
                                                            replacement on a
                                                            case-by-case basis.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading7"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse7"
                                                        aria-expanded="false"
                                                        aria-controls="collapse7"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What differentiates
                                                        Weteams from its
                                                        competitors?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse7"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading7"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            We make the process
                                                            of hiring remote
                                                            teams and engineers
                                                            fairly simple. From
                                                            sourcing and vetting
                                                            talent to matching
                                                            you with the best
                                                            talent profiles, -
                                                            We go above and
                                                            beyond to ensure the
                                                            success of every
                                                            project.
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                Easy and quick
                                                                hiring
                                                                experience
                                                            </li>
                                                            <li>
                                                                Stringent
                                                                vetting process
                                                            </li>
                                                            <li>
                                                                An extensive
                                                                network of
                                                                reliable talent
                                                            </li>
                                                            <li>
                                                                Productivity
                                                                tracking on
                                                                Dashboard
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading8"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse8"
                                                        aria-expanded="false"
                                                        aria-controls="collapse8"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What types of
                                                        engagements do you
                                                        offer?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse8"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading8"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            With Weteams, you
                                                            can hire talent for
                                                            any development/ IT
                                                            role and duration.
                                                            We offer a variety
                                                            of engagements based
                                                            on your business
                                                            needs. In addition
                                                            to 40 hours
                                                            part-time, 80 hours
                                                            part-time, and 160
                                                            hours full-time on
                                                            contract roles, we
                                                            also handle all
                                                            payroll functions so
                                                            you can focus on
                                                            your business.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading9"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse9"
                                                        aria-expanded="false"
                                                        aria-controls="collapse9"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        How are your services
                                                        different from
                                                        traditional IT
                                                        outsourcing?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse9"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading9"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Our specialized
                                                            matching algorithm
                                                            matches profiles of
                                                            experts that can be
                                                            a great fit for your
                                                            project. We ensure
                                                            that we only select
                                                            talent who are ready
                                                            to begin working
                                                            immediately after
                                                            receiving your
                                                            requirements.
                                                        </p>
                                                        <p>
                                                            Unlike traditional
                                                            outsourcing, where
                                                            you need to visit
                                                            dozens of software
                                                            development
                                                            companies and
                                                            interview and vet
                                                            the professionals
                                                            for your team
                                                            personally, this way
                                                            you can get started
                                                            within one week.
                                                            With traditional
                                                            outsourcing, it may
                                                            take around 6-8
                                                            weeks for a project
                                                            to be launched and
                                                            not to mention the
                                                            cost that comes
                                                            along with.
                                                        </p>
                                                        <p>
                                                            Before engaging a
                                                            talent, you can
                                                            check their profiles
                                                            and portfolios and
                                                            interview them
                                                            personally.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading10"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse10"
                                                        aria-expanded="false"
                                                        aria-controls="collapse10"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What happens in case
                                                        there is no talent
                                                        currently available to
                                                        fill the position in my
                                                        company? How long could
                                                        I expect to wait to have
                                                        a new talent onboard?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse10"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading10"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Usually, this does
                                                            not happen as we
                                                            have a talent pool
                                                            of 1000+ Vetted
                                                            talent & experts.
                                                            However, in case
                                                            this scenario
                                                            arises, it will take
                                                            no more than three
                                                            weeks to search for
                                                            the right talent.
                                                            Our organization has
                                                            a precise screening
                                                            process that we must
                                                            go through before
                                                            bringing a candidate
                                                            into the talent
                                                            pool. We won't
                                                            present any
                                                            individual to you
                                                            until we are
                                                            absolutely sure of
                                                            their capability.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading11"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse11"
                                                        aria-expanded="false"
                                                        aria-controls="collapse11"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Do you provide
                                                        specialized candidate
                                                        vetting and screening
                                                        services to help us hire
                                                        employees outside of the
                                                        Weteams network?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse11"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading11"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Yes, we do provide
                                                            specialized
                                                            candidate vetting
                                                            and screening
                                                            services to help you
                                                            hire employees
                                                            outside of the
                                                            Weteams network. It
                                                            is permissible to
                                                            submit the resumes
                                                            and contact
                                                            information of the
                                                            candidates to us.
                                                            Our experienced
                                                            recruitment
                                                            professionals can
                                                            help you with an
                                                            advanced screening
                                                            and vetting process
                                                            that will ensure
                                                            that you get only
                                                            the best and most
                                                            qualified talent.{' '}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading12"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse12"
                                                        aria-expanded="false"
                                                        aria-controls="collapse12"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Who is Weteams
                                                        Specialist and what is
                                                        their role?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse12"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading12"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Your dedicated
                                                            Account Manager who
                                                            will help you at
                                                            every step of
                                                            building your remote
                                                            digital team and
                                                            managing them
                                                        </p>
                                                        <p>
                                                            Weteams Specialist
                                                            is your dedicated
                                                            Account Manager who
                                                            has been carefully
                                                            selected by our
                                                            team. He brings a
                                                            wealth of experience
                                                            and skills in their
                                                            respective field and
                                                            applies this
                                                            knowledge to help
                                                            you throughout the
                                                            process of building
                                                            your digital team
                                                            and managing them.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading13"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse13"
                                                        aria-expanded="false"
                                                        aria-controls="collapse13"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Where are Weteams Talent
                                                        located?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse13"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading13"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            We currently have a
                                                            talent pool
                                                            dispersed across
                                                            India, but we will
                                                            soon be expanding to
                                                            Vietnam, Singapore,
                                                            the United States,
                                                            Canada, and other
                                                            locations. Our
                                                            diverse group brings
                                                            a wealth of
                                                            experience, skills,
                                                            and perspectives to
                                                            the team. WeTeams
                                                            encourages
                                                            collaboration,
                                                            creativity, and
                                                            innovation among its
                                                            talent, which
                                                            results in
                                                            high-quality
                                                            products.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading14"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse14"
                                                        aria-expanded="false"
                                                        aria-controls="collapse14"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Can you connect me
                                                        directly with the
                                                        talent? Can I meet them
                                                        in person before
                                                        kickstarting the
                                                        development process?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse14"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading14"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Yes, We believe that
                                                            a meeting is
                                                            essential for
                                                            getting a better
                                                            understanding of the
                                                            project and its
                                                            needs. Therefore, we
                                                            are happy to arrange
                                                            an interview call
                                                            for you once you
                                                            have shortlisted
                                                            your desired talent
                                                            before you onboard
                                                            anyone.{' '}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading15"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse15"
                                                        aria-expanded="false"
                                                        aria-controls="collapse15"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        How communication is
                                                        managed with the team
                                                        members on my project?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse15"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading15"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Weteams makes sure
                                                            to have a smooth
                                                            workflow. Although
                                                            we do not currently
                                                            have a chat module,
                                                            we are working to
                                                            add one to our
                                                            platform. In the
                                                            meantime, you are
                                                            welcome to submit
                                                            your preferred
                                                            messaging platform
                                                            used by your
                                                            organization.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading16"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse16"
                                                        aria-expanded="false"
                                                        aria-controls="collapse16"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        How do you screen your
                                                        talent?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse16"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading16"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            All of our potential
                                                            hires undergo a
                                                            stringent screening
                                                            process that
                                                            includes technical
                                                            and behavioural
                                                            assessments. Our
                                                            software engineers
                                                            are appraised on
                                                            multiple criteria
                                                            like technical
                                                            expertise,
                                                            communication
                                                            skills, English
                                                            language fluency,
                                                            time management
                                                            capability, and
                                                            more. Doing so will
                                                            help us find the
                                                            best fit for our
                                                            team and ensure that
                                                            you have the most
                                                            qualified talent
                                                            possible.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading17"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse17"
                                                        aria-expanded="false"
                                                        aria-controls="collapse17"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Can I screen or test the
                                                        talent on my own?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse17"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading17"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Certainly. We
                                                            encourage you to
                                                            evaluate our talent,
                                                            examine their
                                                            backgrounds, or even
                                                            hold a conversation
                                                            with them to ensure
                                                            you are confident
                                                            about their skills.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading18"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse18"
                                                        aria-expanded="false"
                                                        aria-controls="collapse18"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What if I face some
                                                        issues with the talent?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse18"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading18"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            If you're ever
                                                            facing some issues
                                                            with a talent, you
                                                            can always turn to
                                                            Weteams Speacialist.
                                                            No matter what kind
                                                            of project you're
                                                            working on, our
                                                            experienced Weteams
                                                            Speacialist can help
                                                            you solve any issues
                                                            that arise and
                                                            provide guidance
                                                            along the way. So if
                                                            you find yourself in
                                                            a situation where
                                                            you need help with a
                                                            talent, you can
                                                            count on Weteams
                                                            Specialist to be
                                                            there for you.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm-title mt-5 mb-4 text-center theme-text">
                                                Security & Confidentiality
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading19"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse19"
                                                        aria-expanded="false"
                                                        aria-controls="collapse19"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Will my intellectual
                                                        property and
                                                        confidentiality be
                                                        protected?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse19"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading19"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Absolutely! Weteams
                                                            takes your privacy
                                                            and intellectual
                                                            property very
                                                            seriously. As a
                                                            client, you will
                                                            receive the IP
                                                            rights for all the
                                                            paid-for work.
                                                            Moreover, at the
                                                            beginning of the
                                                            project, we sign a
                                                            non-disclosure
                                                            agreement that
                                                            ensures complete
                                                            confidentiality of
                                                            all information. All
                                                            of our vetted
                                                            experts are also
                                                            required to sign a
                                                            Non-Disclosure
                                                            Agreement prior to
                                                            engaging in any
                                                            project.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="pricing-payment"
                                            role="tabpanel"
                                            aria-labelledby="pricing-payment-tab"
                                        >
                                            {/* <div className="sm-title mt-5 mb-4 text-center theme-text">Pricing & Payment</div> */}
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading20"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse20"
                                                        aria-expanded="false"
                                                        aria-controls="collapse20"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        Do I need to keep track
                                                        of whether the talent
                                                        works extra hours?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse20"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading20"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            With Weteams, you
                                                            don't have to worry
                                                            about tracking
                                                            hours. Our platform
                                                            is set up in such a
                                                            way that you will
                                                            never be billed for
                                                            any extra hours
                                                            outside the scope of
                                                            the project. On our
                                                            platform, we display
                                                            the following:
                                                        </p>
                                                        <ul className="list-type-count">
                                                            <li>
                                                                Reserved hours
                                                                (engagement type
                                                                40 hours, 80, or
                                                                160)
                                                            </li>
                                                            <li>
                                                                Planned hours
                                                            </li>
                                                            <li>
                                                                Unused hours
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            You may monitor this
                                                            on our platform, and
                                                            if the billing
                                                            exceeds the
                                                            engagement model, it
                                                            will be displayed on
                                                            your dashboard. Our
                                                            platform will manage
                                                            the working hours
                                                            for the three
                                                            categories mentioned
                                                            above.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading21"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse21"
                                                        aria-expanded="false"
                                                        aria-controls="collapse21"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        What is your preferred
                                                        mode of payment?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse21"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading21"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            We take bank
                                                            transfers as of now.
                                                            We also offer
                                                            invoicing so you can
                                                            conveniently keep
                                                            track of all your
                                                            payments.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading22"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse22"
                                                        aria-expanded="false"
                                                        aria-controls="collapse22"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        How does billing and
                                                        invoicing work?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse22"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading22"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            We make sure that
                                                            you always have full
                                                            control over your
                                                            project costs and
                                                            budgeting. Invoices
                                                            are generated
                                                            monthly and sent
                                                            automatically to
                                                            your email. The
                                                            invoice incorporates
                                                            all relevant
                                                            details, like
                                                            remuneration amounts
                                                            for each talent,
                                                            taxes, and benefits
                                                            associated with our
                                                            remote team.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="heading23"
                                                >
                                                    <button
                                                        className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapse23"
                                                        aria-expanded="false"
                                                        aria-controls="collapse23"
                                                    >
                                                        <span className="faq-icon"></span>{' '}
                                                        If I require a large
                                                        team, will you provide
                                                        any discounts?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapse23"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="heading23"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Absolutely! We
                                                            understand that
                                                            larger projects
                                                            require more
                                                            resources, and as
                                                            such, we may offer
                                                            special discounts
                                                            based on the size of
                                                            your team. Reach out
                                                            to us directly for
                                                            more information
                                                            about our offers and
                                                            customized quotes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="tab-pane fade" id="vendor-faqs" role="tabpanel" aria-labelledby="vendor-faqs-tab">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading24">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="false" aria-controls="collapse24">
                            <span className="faq-icon"></span> How many leads can we expect in a month?
                          </button>
                        </h2>
                        <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="heading24" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>You can anticipate receiving 4-5 leads per month once we begin operations, and we will notify you for every lead that any of your talent qualifies for. However, before we do so, we are enhancing our supply chain of talent & experts by collaborating with IT companies like yours.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading25">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="false" aria-controls="collapse25">
                            <span className="faq-icon"></span> How are you generating leads?
                          </button>
                        </h2>
                        <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="heading25" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>We have a growth marketing team in place that will generate leads for us in segments.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading26">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="false" aria-controls="collapse26">
                            <span className="faq-icon"></span> How will I earn from this model?
                          </button>
                        </h2>
                        <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="heading26" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>After deducting each candidate's salary and administrative expenses, you can make a net profit of somewhere between 25 - 35%. We do not charge our vendor partners; you will be paid the prices you provide for your talent.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading27">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="false" aria-controls="collapse27">
                            <span className="faq-icon"></span> What if a client contacts our talent directly and hires outside my organization?
                          </button>
                        </h2>
                        <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="heading27" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>We make sure that our vendor partners are protected from hiring theft. We only disclose the candidate's first name with clients and do not provide any other personal information about them. Our Weteams specialist will also be present throughout the interview. There will be no way for the client to get in touch with the talent.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading28">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
                            <span className="faq-icon"></span> What is the procedure for hiring a resource from us?
                          </button>
                        </h2>
                        <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="heading28" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>The steps involved in hiring a resource from Weteams are as follows:</p>
                            <ul className="no-list-type">
                              <li>Step 1: We will discuss our business model over an introductory call between your management and our co-founder during which you can ask any questions and get any uncertainties cleared.</li>
                              <li>Step 2: MOU signature</li>
                              <li>Step 3: You provide us with 10-15 talent profiles and their rate cards.</li>
                              <li>Step 4: Whenever there is a lead available, we will contact you.</li>
                              <li>Step 5. If a candidate is chosen, we sign a contract with you to employ that resource, and there you have it.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading29">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
                            <span className="faq-icon"></span> What will be the minimum contract duration and engagement model?
                          </button>
                        </h2>
                        <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>Our clients can choose between a 40, 80, or 160 -hour engagement model. We do not currently have a minimum contract time cap.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading30">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
                            <span className="faq-icon"></span> Who will manage the resource once hired by the client?
                          </button>
                        </h2>
                        <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>Weteams will provide a dedicated Weteams Specialist to manage the resource along with the client and you.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading31">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapse31">
                            <span className="faq-icon"></span> Will I have to share profiles and rate cards prior to hiring?
                          </button>
                        </h2>
                        <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="heading31" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <p>Yes, that is necessary to build a robust supply chain and demonstrate our expertise on the platform.</p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="heading32">
                          <button className="accordion-button collapsed sm-title text-start fw-600 lh-1-5em" type="button" data-bs-toggle="collapse" data-bs-target="#collapse32" aria-expanded="false" aria-controls="collapse32">
                            <span className="faq-icon"></span> What are the benefits of partnering with Weteams?
                          </button>
                        </h2>
                        <div id="collapse32" className="accordion-collapse collapse" aria-labelledby="heading32" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <ul className="list-type-count">
                              <li>You don't have to pay a penny for the leads we are providing.</li>
                              <li>We will occasionally provide frameworks for hiring, managing, rewarding, and expanding your team.</li>
                              <li>You will have the opportunity to grow your venture.</li>
                              <li>You will not have to worry about having idle resources on your business pipeline.</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Faqs
