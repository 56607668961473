import React, { useEffect, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import userCircleIcon from '../../assets/agency/img/user-circle-icon.svg'
import onboardingUploadIcon from '../../assets/agency/img/onboarding-upload.svg'
import BtnSpinner from '../Spinners/BtnSpinner'
import teamImg from '../../assets/agency/img/team-1.jpg'
import { useState } from 'react'
import Currency from '../Currency/Currency'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import PreviewImage from '../Form/PreviewImage'
import FormikDatePicker from '../Form/FormikDatePicker'
import axios from 'axios'
import AuthUser from '../../Services/AuthUser'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import SelectField from '../Form/SelectField'
import SelectCountry from '../../Components/Form/SelectCountry'
import SelectState from '../../Components/Form/SelectState'
import SelectCity from '../../Components/Form/SelectCity'
import apiCall from '../../Services/ApiInstance'
// import AddLeadModal from "./AddLeadModal";
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as CloseIcon2 } from '../../assets/agency/img/close-icon-dynamic.svg'

let base_URL = process.env.REACT_APP_base_URL
let avatarFieldValue = ''
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import { Box, Typography, useTheme } from '@mui/material'
import { utilities } from '../../Config/Helper'
import Select, { components, MultiValueGenericProps } from 'react-select'
import makeAnimated from 'react-select/animated'

const emailValidation = yup.string().email('not a valid email')
const validationSchemaTeam = yup.object({
    company_name: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    client_poc_name: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")//
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    designation_of_poc: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")//
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    client_poc_email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required!'),
    company_address_line_1: yup.string().required('This field is required!'),
    // project_end_date: yup.string().required("This field is required!"),
    authority_company_country: yup.string().required('This field is required!'),
    authority_company_state: yup.string().required('This field is required!'),
    authority_company_city: yup.string().required('This field is required!'),
    billing_email: yup.array().of(
        yup.object().shape({
            label: yup
                .string()
                .email('Invalid email format')
                .required('Email is required'),
        })
    ),
    // .min(1, 'At least one email is required'),
    pin_code: yup
        .string()
        .required('This field is required!')
        .matches(/^[A-Za-z0-9]+$/, 'Special characters are not allowed!')
        .matches(/^[^0]/, 'Pin code should not start with zero')
        .test(
            'length',
            'Pin code should have atmost 6 digits in length',
            (value) => value && value.replace(/\s/g, '').length <= 6
        ),
})

const AddNewClientModal = ({ setReload, reload, setModalOpen }) => {
    const { http2, token } = AuthUser()
    const navigate = useNavigate()

    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    const selectRef = useRef(null)
    const theme = useTheme()
    const animatedComponents = makeAnimated()

    const [addTeamOnProject, setAddTeamOnProject] = useState(false)
    const [isProjectLoading, setIsProjectLoading] = useState(false)
    const [countries, setCountries] = useState([])
    const [image, setImage] = useState('')
    const [imageName, setImageName] = useState('')
    const [imgAfterCrop, setImgAfterCrop] = useState('')
    const [imgError, setImgError] = useState('')
    const [roleData, setRoleData] = useState('')
    const [inputValue, setInputValue] = useState('')
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg)
    }
    const currencyList = ['USD', 'CAD', 'INR']
    const currencyType = currencyList.map((item) => ({
        key: item,
        value: item,
    }))

    const inputRef = useRef()
    const handleOnChange = (event, f) => {
        console.log(event)
        const imageFile = event.target.files[0]
        // const dataURL = canvasEle.toDataURL("image/jpeg");

        if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
            ) {
                const reader = new FileReader()
                reader.readAsDataURL(event.currentTarget.files[0])
                reader.onload = function (e) {
                    onImageSelected(reader.result)
                }
            }
            // setCropperModal(true);
            // setImageName(event.currentTarget.files[0].name);
            setImgError('')
            f.setFieldValue('project_logo', event.currentTarget.files[0])
            setImgAfterCrop(event.target.files[0])
            // setLogoName(imageFile.name)
            // f.setFieldValue("project_logo", imageFile);
            // f.setFieldValue("logo_name", imageFile.name);
        } else {
            setImgError('Please select only jpeg, jpg, png and svg files')
        }
    }

    const onChooseImg = () => {
        inputRef.current.click()
    }
    const removeCompanyLogo = (event, f) => {
        f.setFieldValue('project_logo', '')
        setImgAfterCrop('')
        inputRef.current.value = ''
    }
    const initialValues = {
        company_name: '',
        client_poc_name: '',
        designation_of_poc: '',
        client_poc_email: '',
        company_address_line_1: '',
        company_address_line_2: '',
        authority_company_country: '',
        authority_company_state: '',
        authority_company_city: '',
        pin_code: '',
        billing_email: [],
        gst_no: '',
        project_logo: '',
    }

    // Add New Project
    const addProjectValues = (fields) => {
        console.log(fields)
        let data = new FormData()
        data.append(`client_company_name`, fields.company_name)
        data.append(`client_name`, fields.client_poc_name)
        data.append(`poc_designation`, fields.designation_of_poc)
        data.append(`client_email`, fields.client_poc_email)
        data.append(`address_line_1`, fields.company_address_line_1)
        data.append(`address_line_2`, fields.company_address_line_2)
        data.append(`state`, fields.authority_company_state)
        data.append(`country`, fields.authority_company_country)
        data.append(`city`, fields.authority_company_city)
        data.append(`pin_code`, fields.pin_code)
        data.append(
            `billing_email`,
            JSON.stringify(fields.billing_email.map((v) => v.label))
        )
        data.append(`gst_no`, fields.gst_no)
        data.append(`client_logo`, fields.project_logo)

        try {
            setIsProjectLoading(true)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/create-client`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setModalOpen(false)
                        setReload(false)
                        setIsProjectLoading(false)
                    }
                    if (result.success == false) {
                        toast.error(result.message)
                        setModalOpen(false)
                        setIsProjectLoading(false)
                    }
                    setModalOpen(false)
                    setIsProjectLoading(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status) {
                        toast.error(err.message)
                        // setIsTeamMemberLoading(false);
                    }
                    setIsProjectLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setIsProjectLoading(false)
        }
    }

    const rolesList = async () => {
        // setIsLoading(true);
        // http2.get(`/roles/list`).then((res) => {
        //   let result = res.data.data;
        //   setRoleData(result);
        // });
        let role = await utilities('role')
        setRoleData(role)
    }

    useEffect(() => {
        ;(async () => {
            let countries = await utilities('countries')
            setCountries(countries)
            rolesList()
        })()
    }, [])

    const setEmailError = async (f) => {
        await f.setFieldError('billing_email', 'Invalid email format')
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(
                    values,
                    { resetForm, setFieldValue, ...formprops }
                ) => {
                    console.log(formprops)
                    addProjectValues(values)
                    setImgAfterCrop('')
                    resetForm()
                    // setProjectTeamList([]);
                    setAddTeamOnProject(false)
                    avatarFieldValue = ''
                }}
            >
                {(formMain) => (
                    <>
                        {/* {console.log(values.month_budget)} */}

                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Add Client
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setModalOpen(false)
                                        formMain.resetForm({
                                            values: initialValues,
                                        })
                                        setImgAfterCrop('')
                                        avatarFieldValue = ''
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon2}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                                    <div className="col-12">
                                        <div className="md-title dash-font-1-1em ad-gray-text mt-2 mb-3">
                                            Company Details
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Company Name*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="company_name"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="company_name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client POC Name*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="client_poc_name"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="client_poc_name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Designation of POC*
                                            </label>
                                            {/* <Field
                        type="text"
                        className="form-control"
                        name="designation_of_poc"
                      /> */}
                                            <Field
                                                name="designation_of_poc"
                                                className="form-control"
                                                as="select"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            >
                                                <option value="">
                                                    Designation
                                                </option>
                                                {roleData &&
                                                    roleData.map(
                                                        (item, ind) => (
                                                            <option
                                                                key={ind}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        )
                                                    )}
                                            </Field>
                                            <ValidationError name="designation_of_poc" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client POC Email*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="client_poc_email"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="client_poc_email" />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div
                                            className={`drag-drop-box project-drag-drop text-center w-100 position-relative borderradius-8 ${
                                                imgAfterCrop
                                                    ? 'p-0 add-company-bg'
                                                    : ''
                                            }`}
                                        >
                                            {imgAfterCrop ? (
                                                <>
                                                    <div
                                                        className="d-flex align-items-center justify-content-center ad-company-logo"
                                                        style={{
                                                            backgroundColor:
                                                                'white',
                                                        }}
                                                    >
                                                        <PreviewImage
                                                            file={imgAfterCrop}
                                                        />

                                                        <Field name="project_logo">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={(
                                                                            event
                                                                        ) =>
                                                                            removeCompanyLogo(
                                                                                event,
                                                                                form
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            position:
                                                                                'absolute',
                                                                            right: 8,
                                                                            top: 8,
                                                                        }}
                                                                    >
                                                                        <SvgIcon
                                                                            color="inherit"
                                                                            component={
                                                                                CloseIcon
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="w-100">
                                                    <div className="text-center">
                                                        <img
                                                            src={
                                                                onboardingUploadIcon
                                                            }
                                                            alt="upload"
                                                        />
                                                    </div>
                                                    <div className="mt-1 text-center drag-line">
                                                        <span>Upload Logo</span>
                                                    </div>
                                                </div>
                                            )}
                                            <Field name="project_logo">
                                                {({ field, form }) => (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png, .svg"
                                                            ref={inputRef}
                                                            onChange={(event) =>
                                                                handleOnChange(
                                                                    event,
                                                                    form
                                                                )
                                                            }
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                            {imgAfterCrop ? (
                                                ''
                                            ) : (
                                                <button
                                                    className="btn file-upload-input"
                                                    type="button"
                                                    onClick={onChooseImg}
                                                ></button>
                                            )}
                                        </div>
                                        <div className="error-block">
                                            {imgError ? (
                                                <div>
                                                    <label className="error">
                                                        {imgError}
                                                    </label>
                                                </div>
                                            ) : (
                                                ' '
                                            )}
                                            <ValidationError name="project_logo" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="md-title ad-billing-border-top dash-font-1-1em ad-gray-text mt-2 mb-3">
                                            Billing Address
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Company Address Line 1*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="company_address_line_1"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="company_address_line_1" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Company Address Line 2
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="company_address_line_2"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="company_address_line_2" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="position-relative">
                                                <label className="label-pos">
                                                    Country*
                                                </label>
                                                <SelectCountry
                                                    placeholder="Select Country"
                                                    name="authority_company_country"
                                                    data={countries}
                                                    // onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                />
                                                {/* <ValidationError name="authority_company_country" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Authority’s Company State */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="position-relative">
                                                <label className="label-pos">
                                                    State*
                                                </label>
                                                <SelectState
                                                    name="authority_company_state"
                                                    placeholder="Select State"
                                                    component={SelectState}
                                                    // onKeyDown={e => { e.key === 'Enter' && e.preventDefault() }}
                                                />
                                                <ValidationError name="authority_company_state" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Authority’s Company City */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="position-relative">
                                                <label className="label-pos">
                                                    City*
                                                </label>
                                                <SelectCity
                                                    name="authority_company_city"
                                                    placeholder="Select City"
                                                />
                                                <ValidationError name="authority_company_city" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Pin Code*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="pin_code"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="pin_code" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                GST (Optional)
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="gst_no"
                                                onKeyDown={(e) => {
                                                    e.key === 'Enter' &&
                                                        e.preventDefault()
                                                }}
                                            />
                                            <ValidationError name="gst_no" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Billing Email(s)
                                            </label>
                                            <Field name="billing_email">
                                                {({ field, form }) => (
                                                    <>
                                                        <Select
                                                            ref={selectRef}
                                                            className="custom-select"
                                                            placeholder="Type and enter to add emails"
                                                            value={field.value}
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.key ===
                                                                    'Enter'
                                                                ) {
                                                                    const email =
                                                                        e.target.value.trim()
                                                                    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                                                                    if (
                                                                        email !==
                                                                        ''
                                                                    ) {
                                                                        const newEmail =
                                                                            {
                                                                                value:
                                                                                    field
                                                                                        .value
                                                                                        .length >
                                                                                    0
                                                                                        ? field
                                                                                              .value[
                                                                                              field
                                                                                                  .value
                                                                                                  .length -
                                                                                                  1
                                                                                          ]
                                                                                              .value +
                                                                                          1
                                                                                        : 0,
                                                                                label: email,
                                                                            }

                                                                        form.setFieldValue(
                                                                            'billing_email',
                                                                            field.value
                                                                                ? [
                                                                                      ...field.value,
                                                                                      newEmail,
                                                                                  ]
                                                                                : [
                                                                                      newEmail,
                                                                                  ]
                                                                        )
                                                                        setInputValue(
                                                                            ''
                                                                        )
                                                                        e.preventDefault()
                                                                    }
                                                                }
                                                            }}
                                                            styles={{
                                                                control: (
                                                                    baseStyles,
                                                                    state
                                                                ) => ({
                                                                    ...baseStyles,
                                                                    minHeight:
                                                                        '50px',
                                                                    background:
                                                                        theme
                                                                            .palette
                                                                            .common
                                                                            .white,
                                                                    border: `1px solid ${theme.palette.teritiary.teritiary15}`,
                                                                    borderRadius:
                                                                        '8px',
                                                                }),
                                                            }}
                                                            inputValue={
                                                                inputValue
                                                            }
                                                            onInputChange={(
                                                                value,
                                                                action
                                                            ) => {
                                                                if (
                                                                    action.action ===
                                                                    'input-change'
                                                                )
                                                                    setInputValue(
                                                                        value
                                                                    )
                                                            }}
                                                            components={{
                                                                Menu: () =>
                                                                    null,
                                                                MenuList: () =>
                                                                    null,
                                                                DropdownIndicator:
                                                                    () => null,
                                                                IndicatorSeparator:
                                                                    () => null,
                                                            }}
                                                            isMulti
                                                            onChange={(e) => {
                                                                form.setFieldValue(
                                                                    'billing_email',
                                                                    e
                                                                )
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                            <ErrorMessage
                                                name="billing_email"
                                                render={(msg) => (
                                                    <label
                                                        htmlFor="billing_email"
                                                        className="error"
                                                    >
                                                        {typeof msg === 'object'
                                                            ? 'Invalid email format'
                                                            : msg}
                                                    </label>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-2 col-12">
                                        {isProjectLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <button
                                                className="ad-theme-btn w-100 ad-create-btn"
                                                type="submit"
                                                disabled={addTeamOnProject}
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </div>
                                {/* <AddLeadModal
                  open={openAddProjectLead}
                  onClose={() => {
                    setOpenAddProjectLead(false);
                  }}
                  PaperProps={
                    {
                      sx: {
                        width: "448px",
                        maxWidth: "1108px",
                      }
                    }
                  }
                /> */}
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddNewClientModal
