import { ErrorMessage, useField, Field } from 'formik'
import ValidationError from '../../Components/Errors/ValidationError'

const TextAreaField = ({ label, sublabel, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <>
            <div className="form-group ">
                {label ? (
                    <label
                        htmlFor={field.name}
                        className={props.asterisks || ''}
                    >
                        {label}
                    </label>
                ) : (
                    ''
                )}
                {sublabel ? (
                    <div className={props.sublableclass || ''}>{sublabel}</div>
                ) : (
                    ''
                )}
                <textarea
                    className={props.class || `form-control`}
                    {...field}
                    {...props}
                />
                {meta.touched && meta.error ? (
                    <ValidationError name={field.name} />
                ) : null}
            </div>
        </>
    )
}

export default TextAreaField
