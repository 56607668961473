import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Header from '../../Common/Header'
import ErrorImg from '../../assets/img/404.png'

const PageNotFound = () => {
    return (
        <>
            <Header />
            <section className="error404 not-found text-center">
                <img className="error-img" src={ErrorImg} alt="" />
                <div className="page-header mt-3">
                    <h5>Something went wrong!</h5>
                </div>
                <div className="page-content mt-5 text-center">
                    <p>
                        <Link to="/" className="go-back-btn ml-15">
                            Go back
                        </Link>
                    </p>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default PageNotFound
