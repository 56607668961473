import React, { useState, useEffect, useRef } from 'react'
import {
    TextField,
    Box,
    Chip,
    IconButton,
    useTheme,
    Stack,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import {
    Divider,
    Typography,
    Grid,
    Radio,
    FormControlLabel,
} from '@mui/material'
import DatePicker from 'react-datepicker'
import InputAdornment from '@mui/material/InputAdornment'
import { ReactComponent as CalenderIcon } from '../../assets/agency/img/calendar-icon.svg'
import { SvgIcon } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'
import { Button } from '@mui/material'
import * as yup from 'yup'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import FormikDatePicker from '../Form/FormikDatePicker'
import { ReactComponent as Cross } from '../../assets/dashboard/img/cross-interview.svg'
import UserForm from './UserForm'
import ValidationError from '../Errors/ValidationError'
import Select from 'react-select'
import { useApiService } from '../../Services/ApiInstance'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { parseISO } from 'date-fns'
import { ReactComponent as EditIcon } from '../../assets/dashboard/img/edit_2_dynamic.svg'
import { ReactComponent as Add } from '../../assets/dashboard/img/add.svg'
import { ReactComponent as Minus } from '../../assets/dashboard/img/minus.svg'

const validationSchema = yup.object().shape({
    interview_round_name: yup
        .string()
        .max(50, 'At most 50 characters are allowed')
        .required('Interview round name is required'),
    interview_emails: yup
        .array()
        .of(
            yup.object().shape({
                label: yup
                    .string()
                    .email('Invalid email format')
                    .required('Email is required'),
                value: yup.number().required('Value is required'),
            })
        )
        .min(1, 'At least one email is required')
        .max(4, 'At most four emails are allowed'),
    interview_slots: yup.object().shape({
        date: yup.date('Invalid date format').required('Date is required'),
        time: yup.date('Invalid time format').required('Time is required'),
    }),
    interview_link: yup.string(),
})

const ViewScheduleAcceptance = ({ data, item, modalclose }) => {
    const theme = useTheme()
    const params = useParams()
    const formRef = useRef()
    console.log('data', data, item)
    const initialValues = {
        interview_round_name: data?.interview_round_name || '',
        interview_emails:
            data?.interview_emails && data?.interview_emails.length > 0
                ? data.interview_emails.map((val, ind) => {
                      return { value: 0, label: val }
                  })
                : [],
        interview_slots: {
            date: data?.interview_slots?.val.date
                ? parseISO(data.interview_slots.val.date)
                : '',
            time: data?.interview_slots?.val.time
                ? parseISO(data?.interview_slots?.val.time)
                : '',
        },
        interview_link: data?.interview_link || '',
    }

    return (
        <>
            <ToastContainer limit={1} hideProgressBar={true} />
            <Box
                sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}
            >
                <Box
                    sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        ml: 'auto',
                        mr: 'auto',
                    }}
                >
                    Thank you! Your interview is confirmed.
                </Box>
                <Box sx={{ position: 'absolute', right: '18px' }}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            modalclose()
                        }}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <SvgIcon
                            sx={{
                                height: '29px',
                                width: '29px',
                                color: 'black',
                                pointerEvents: 'none',
                            }}
                            component={Cross}
                            inheritViewBox
                        />
                    </IconButton>
                </Box>
            </Box>
            <Box>
                <UserForm item={item} />

                <Box sx={{ padding: '17px' }}>
                    <Box
                        className="flowBoxes"
                        sx={{
                            display: 'flex',
                        }}
                    >
                        <Box
                            className="left"
                            sx={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            Interview Scheduled
                        </Box>
                        <Box
                            className="right"
                            sx={{
                                '&.MuiBox-root': {
                                    flex: 1,
                                    textAlign: 'center',
                                },
                            }}
                        >
                            {'Interview Completed'}
                        </Box>
                    </Box>
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, form) => {
                            // handleSubmit(values, form);
                        }}
                    >
                        {({
                            values,
                            setFieldValue,
                            handleSubmit,
                            validateField,
                            handleBlur,
                        }) => (
                            <Form>
                                <Box className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                                    <>
                                        <Typography
                                            variant="size3MediumTextText1"
                                            sx={{ pb: '8px', mt: '0px' }}
                                        >
                                            Interview date & time
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: '15px',
                                                p: 0,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    '&.MuiBox-root': {
                                                        mb: 0,
                                                    },
                                                }}
                                                className="col-md-6 col-12 form-group"
                                            >
                                                <div className="position-relative">
                                                    <Field
                                                        disabled={true}
                                                        type="text"
                                                        name={`interview_slots.date`}
                                                        className="form-control"
                                                        placeholder=""
                                                        dateFormat="dd-MMM-yyyy"
                                                        dropdownMode="select"
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        component={
                                                            FormikDatePicker
                                                        }
                                                    />
                                                    <span className="calendar-icon-gray"></span>
                                                </div>
                                            </Box>
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    '&.MuiBox-root': {
                                                        mb: 0,
                                                    },
                                                }}
                                                className="col-md-6 col-12 form-group"
                                            >
                                                <div className="position-relative">
                                                    <Field
                                                        type="text"
                                                        name={`interview_slots.time`}
                                                        className="form-control"
                                                        disabled={true}
                                                        placeholder=""
                                                        timeFormat="h:mm aa"
                                                        dateFormat="h:mm aa"
                                                        dropdownMode="select"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={2}
                                                        component={
                                                            FormikDatePicker
                                                        }
                                                    />
                                                    <span className="time-icon"></span>
                                                </div>
                                            </Box>
                                        </Box>
                                    </>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Interview round name
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="interview_round_name"
                                                disabled={true}
                                            />
                                            <ValidationError name="interview_round_name" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Interview emails
                                            </label>
                                            <Field name="interview_emails">
                                                {({ field, form }) => (
                                                    <>
                                                        <Select
                                                            className="custom-select"
                                                            isDisabled={true}
                                                            placeholder="Type and enter to add emails"
                                                            value={field.value}
                                                            styles={{
                                                                control: (
                                                                    baseStyles,
                                                                    state
                                                                ) => ({
                                                                    ...baseStyles,
                                                                    minHeight:
                                                                        '50px',
                                                                    background:
                                                                        theme
                                                                            .palette
                                                                            .common
                                                                            .white,
                                                                    border: `1px solid ${theme.palette.teritiary.teritiary15}`,
                                                                    borderRadius:
                                                                        '8px',
                                                                }),
                                                                multiValue: (
                                                                    styles,
                                                                    { data }
                                                                ) => {
                                                                    return {
                                                                        ...styles,
                                                                        backgroundColor:
                                                                            theme
                                                                                .palette
                                                                                .primary
                                                                                .lighter,
                                                                        borderRadius:
                                                                            '20px',
                                                                    }
                                                                },
                                                                multiValueLabel:
                                                                    (
                                                                        styles,
                                                                        { data }
                                                                    ) => ({
                                                                        ...styles,
                                                                        color: theme
                                                                            .palette
                                                                            .primary
                                                                            .main,
                                                                    }),
                                                                multiValueRemove:
                                                                    (
                                                                        styles,
                                                                        { data }
                                                                    ) => ({
                                                                        ...styles,
                                                                        color: theme
                                                                            .palette
                                                                            .primary
                                                                            .main,
                                                                        ':hover':
                                                                            {
                                                                                backgroundColor:
                                                                                    theme
                                                                                        .palette
                                                                                        .primary
                                                                                        .lighter,
                                                                                color: theme
                                                                                    .palette
                                                                                    .common
                                                                                    .black,
                                                                                borderRadius:
                                                                                    '20px',
                                                                            },
                                                                    }),
                                                            }}
                                                            components={{
                                                                Menu: () =>
                                                                    null, // Remove menu
                                                                MenuList: () =>
                                                                    null, // Remove menu list
                                                                DropdownIndicator:
                                                                    () => null, // Remove dropdown icon
                                                                IndicatorSeparator:
                                                                    () => null, // Remove separator
                                                                // MultiValueLabel
                                                            }}
                                                            isMulti
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Interview Link
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="interview_link"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </>
    )
}

export default ViewScheduleAcceptance
