import TakeDeeparCard from '../../Components/Pages/TakeDeeparCard'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
const Testimonial = () => {
    const options = {
        loop: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                margin: 0,
                nav: false,
            },
            381: {
                items: 2,
                margin: 0,
                nav: false,
            },
            1000: {
                items: 3,
                margin: 0,
                nav: false,
            },
        },
    }
    return (
        <>
            <section className="reality-wrap">
                <div className="container">
                    <div className="main-heading white-text text-center font-2-3em">
                        Take a deeper dive into scaling your technology and
                        digital teams
                    </div>
                    <div className="mt-60 reality-block reality-block-dsk">
                        <TakeDeeparCard />
                    </div>
                    <div className="mt-60 reality-block reality-block-mob">
                        <OwlCarousel {...options}>
                            <TakeDeeparCard />
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial
