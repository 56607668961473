import { useState } from 'react'
import { ReactComponent as OverviewIcon } from '../../assets/dashboard/img/overview-icon-dynamic.svg'
import { ReactComponent as HiringsIcon } from '../../assets/dashboard/img/hirings-2-dynamic.svg'
import { ReactComponent as LeadsIcon } from '../../assets/dashboard/img/leads-2-dynamic.svg'
import { ReactComponent as ProjectsIcon } from '../../assets/agency/img/projects-icon-dynamic.svg'
import { ReactComponent as TeamIcon } from '../../assets/agency/img/team-icon-dynamic.svg'
import { ReactComponent as ClientIcon } from '../../assets/dashboard/img/client_icon_dynamic.svg'
import { ReactComponent as PaymentsDynamic } from '../../assets/dashboard/img/payments-dynamic.svg'
import { ReactComponent as BillingsIcon } from '../../assets/dashboard/img/billings-2-dynamic.svg'
import { ReactComponent as SettingIcon } from '../../assets/agency/img/setting-icon-dynamic.svg'
import { ReactComponent as TalentHubIcon } from '../../assets/dashboard/img/talent-hub-dynamic.svg'
import { ReactComponent as InvoicesIcon } from '../../assets/dashboard/img/invoice-dynamic.svg'
import useFilterAssistMethods from './useFilterAssistMethods'

const useMenuContent = () => {
    const [filterSelected, checkPermissions, confirmJourneySubmenuRoute] =
        useFilterAssistMethods()
    const user = JSON.parse(localStorage.getItem('user'))
    const [menuJson, setMenuJson] = useState({
        mainJson: [
            {
                name: 'Overview',
                key: 'overview',
                permission: true,
                icon: OverviewIcon,
                route: '/dashboard/overview',
                teamMemberInvite: false,
                clientInvite: false,
                isSelected: false,
                furtherJourneyRoutes: [],
                submenu: [],
            },
            {
                name: 'Client',
                key: 'client',
                permission: true,
                icon: ClientIcon,
                route: '/dashboard/client',
                teamMemberInvite: false,
                clientInvite: false,
                isSelected: false,
                furtherJourneyRoutes: [],
                submenu: [],
            },
            {
                name: 'Projects',
                key: 'projects',
                permission: true,
                icon: ProjectsIcon,
                route: '/dashboard/projects',
                teamMemberInvite: true,
                clientInvite: true,
                isSelected: false,
                furtherJourneyRoutes: [
                    {
                        step_1: {
                            name: 'Project Details',
                            route: '/dashboard/projects/project-details/{id}',
                        },
                    },
                ],
                submenu: [],
            },
            {
                name: 'Team',
                key: 'team',
                permission: true,
                icon: TeamIcon,
                route: '/dashboard/myteams',
                teamMemberInvite: false,
                clientInvite: true,
                isSelected: false,
                furtherJourneyRoutes: [],
                submenu: [],
            },
            {
                name: 'Talent Hub',
                key: 'talentHub',
                permission: true,
                icon: TalentHubIcon,
                route: '/dashboard/hiring',
                teamMemberInvite: false,
                clientInvite: false,
                furtherJourneyRoutes: [],
                isSelected: false,
                submenu: [
                    {
                        name: 'Hiring',
                        key: 'hiring',
                        permission: true,
                        icon: HiringsIcon,
                        route: '/dashboard/hiring',
                        teamMemberInvite: false,
                        clientInvite: false,
                        isSelected: false,
                        furtherJourneyRoutes: [
                            {
                                step_1: {
                                    name: 'Hire New Talent',
                                    route: '/dashboard/hire-new-talent',
                                },
                            },
                            {
                                step_1: {
                                    name: 'Hiring Status',
                                    route: '/dashboard/view-talent/{id}',
                                },
                            },
                        ],
                    },
                    {
                        name: 'Leads',
                        key: 'leads',
                        permission: true,
                        icon: LeadsIcon,
                        route: '/dashboard/leads',
                        teamMemberInvite: false,
                        clientInvite: false,
                        isSelected: false,
                        furtherJourneyRoutes: [
                            {
                                step_1: {
                                    name: 'Lead Status',
                                    route: '/dashboard/leads/lead-detail/{id}',
                                },
                            },
                            {
                                step_1: {
                                    name: 'Lead Match',
                                    route: '/dashboard/leads/leads-matched/{id}',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: 'Payments',
                key: 'Payments',
                permission: true,
                icon: PaymentsDynamic,
                route:
                    user?.is_invite_client == 'true'
                        ? '/dashboard/settings/marketplace-billing'
                        : '/dashboard/settings/billing-subscription',
                teamMemberInvite: false,
                clientInvite: true,
                isSelected: false,
                furtherJourneyRoutes: [],
                submenu: [
                    {
                        name: 'Billings',
                        key: 'billingAndSubscription',
                        permission: true,
                        icon: BillingsIcon,
                        route:
                            user?.is_invite_client == 'true'
                                ? '/dashboard/settings/marketplace-billing'
                                : '/dashboard/settings/billing-subscription',
                        teamMemberInvite: false,
                        clientInvite: true,
                        isSelected: false,
                        furtherJourneyRoutes: [
                            {
                                step_1: {
                                    name:
                                        user?.is_invite_client == 'true'
                                            ? 'My Billings'
                                            : 'Talent Network billing',
                                    route: '/dashboard/settings/marketplace-billing',
                                },
                            },
                            {
                                step_1: {
                                    name: 'Invoice History',
                                    route: '/dashboard/settings/billing-subscription/invoice-history',
                                },
                            },
                            {
                                step_1: {
                                    name: 'Select Plan',
                                    route: '/dashboard/settings/billing-subscription/select-plan',
                                },
                                step_2: {
                                    name: 'Confirm Order',
                                    route: '/dashboard/order-confirm',
                                },
                            },
                        ],
                    },
                    {
                        name: 'Invoices',
                        key: 'projectBilling',
                        permission: true,
                        icon: InvoicesIcon,
                        route: '/dashboard/billings/project-billing',
                        teamMemberInvite: false,
                        clientInvite: false,
                        furtherJourneyRoutes: [
                            {
                                step_1: {
                                    name: 'Create Billing',
                                    route: '/dashboard/billings/project-billing/create',
                                },
                            },
                        ],
                        isSelected: false,
                    },
                ],
            },
            {
                name: 'Settings',
                key: 'settings',
                permission: true,
                icon: SettingIcon,
                route: '/dashboard/settings/organization',
                teamMemberInvite: false,
                clientInvite: false,
                isSelected: false,
                furtherJourneyRoutes: [],
                submenu: [
                    {
                        name: 'Organization',
                        key: 'organisation',
                        permission: true,
                        icon: null,
                        route: '/dashboard/settings/organization',
                        teamMemberInvite: false,
                        clientInvite: false,
                        isSelected: false,
                        furtherJourneyRoutes: [],
                    },
                    {
                        name: 'Admin User',
                        key: 'adminUser',
                        permission: true,
                        icon: null,
                        route: '/dashboard/settings/admin-user',
                        teamMemberInvite: false,
                        clientInvite: false,
                        isSelected: false,
                        furtherJourneyRoutes: [],
                    },
                    {
                        name: 'Notifications',
                        key: 'notifications',
                        permission: true,
                        icon: null,
                        route: '/dashboard/settings/notifications',
                        teamMemberInvite: false,
                        clientInvite: false,
                        isSelected: false,
                        furtherJourneyRoutes: [],
                    },
                ],
            },
        ],
        shownSubmenuJson: [],
    })

    return {
        menuJson,
        setMenuJson,
        filterSelected,
        checkPermissions,
        confirmJourneySubmenuRoute,
    }
}

export default useMenuContent
