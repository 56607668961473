import React from 'react'

const ReservedHoursModal = ({ setReservedHoursModal, reservedHoursItem }) => {
    const { talent_reserved_hours_details, project_reserved_hours_details } =
        reservedHoursItem
    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title" id="commonSMModalLabel">
                    Reserved Hours Summary
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setReservedHoursModal(false)
                    }}
                ></button>
            </div>
            <div className="modal-body py-3">
                {talent_reserved_hours_details?.length > 0 ? (
                    <>
                        <div className="ad-custom-table sau-table">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Company</th>
                                        <th className="text-center">
                                            Reserved Hours
                                        </th>
                                        <th className="text-center">
                                            Start Date
                                        </th>
                                        <th className="text-center">
                                            Contract
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        {talent_reserved_hours_details.map(
                                            (item) => (
                                                <tr key={item.id}>
                                                    <td>{item.cliet_name}</td>
                                                    <td>{item.company_name}</td>
                                                    <td className="text-center">
                                                        {item.reserved_hours}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.work_start_date}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.contract_duration}{' '}
                                                        Month(s)
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {talent_reserved_hours_details?.length > 0 &&
                    project_reserved_hours_details?.length > 0 && (
                        <hr className="inernal-rh-hr" />
                    )}
                {project_reserved_hours_details?.length > 0 && (
                    <>
                        <div className="ad-md-title dash-font-1em ad-gray-text mb-3">
                            Internal Projects
                        </div>

                        <div className="ad-custom-table sau-table">
                            <table className="w-100">
                                <thead>
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Project</th>
                                        <th className="text-center">
                                            Reserved Hours
                                        </th>
                                        <th className="text-center">
                                            Start Date
                                        </th>
                                        <th className="text-center">
                                            Contract
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        {project_reserved_hours_details.map(
                                            (item) => (
                                                <tr key={item.id}>
                                                    <td>{item.client_name}</td>
                                                    <td>{item.project_name}</td>
                                                    <td className="text-center">
                                                        {item.reserved_hours}
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            item.project_start_date
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        -
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default ReservedHoursModal
