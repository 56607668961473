import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    SvgIcon,
    Typography,
} from '@mui/material'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'

const AddLeadModal = (props) => {
    return (
        <Dialog {...props}>
            <DialogTitle>Add project lead</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => {
                    props.onClose()
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <SvgIcon color="inherit" component={CloseIcon} />
            </IconButton>
            <DialogContent
                sx={{ p: '20px', display: 'flex', flexDirection: 'column' }}
                dividers
            >
                <Stack spacing="20px">
                    <Box>
                        <Typography variant="size3MediumTerTer20">
                            Please select atleast one candidate as project lead!
                        </Typography>
                    </Box>

                    <Button
                        onClick={() => {
                            props.onClose()
                        }}
                        variant="customButtonFilled1"
                        autoFocus
                    >
                        Ok
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default AddLeadModal
