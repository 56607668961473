const AmountToword = (amount) => {
    const amountToWords = (number, symbol = null) => {
        const words = {
            0: '',
            1: 'One',
            2: 'Two',
            3: 'Three',
            4: 'Four',
            5: 'Five',
            6: 'Six',
            7: 'Seven',
            8: 'Eight',
            9: 'Nine',
            10: 'Ten',
            11: 'Eleven',
            12: 'Twelve',
            13: 'Thirteen',
            14: 'Fourteen',
            15: 'Fifteen',
            16: 'Sixteen',
            17: 'Seventeen',
            18: 'Eighteen',
            19: 'Nineteen',
            20: 'Twenty',
            30: 'Thirty',
            40: 'Forty',
            50: 'Fifty',
            60: 'Sixty',
            70: 'Seventy',
            80: 'Eighty',
            90: 'Ninety',
        }

        const digits = ['', 'Hundred', 'Thousand', 'Lakh', 'Crore']

        const decimal = Math.round((number - Math.floor(number)) * 100)
        let no = Math.floor(number)
        const digits_length = no.toString().length

        let i = 0
        const str = []
        while (i < digits_length) {
            const divider = i === 2 ? 10 : 100
            const num = no % divider
            no = Math.floor(no / divider)
            i += divider === 10 ? 1 : 2

            if (num) {
                const counter = str.length
                const plural = counter && num > 9 ? 's' : ''
                str.push(
                    num < 21
                        ? `${words[num]} ${digits[counter]} `
                        : `${words[Math.floor(num / 10) * 10]} ${words[num % 10]} ${
                              digits[counter]
                          } `
                )
            } else {
                str.push('')
            }
        }

        const Rupees = str.reverse().join('')
        const paise =
            decimal > 0
                ? `and ${words[Math.floor(decimal / 10) * 10]} ${words[decimal % 10]}`
                : ''

        return (Rupees ? `${Rupees}Only` : '') + paise
    }

    let convertedAmount = amountToWords(amount.amount)

    return <>{convertedAmount}</>
}

export default AmountToword
