import { Field, Formik, Form } from 'formik'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import ValidationError from '../Errors/ValidationError'
import * as yup from 'yup'
import { toast, ToastContainer } from 'react-toastify'
import AuthUser from '../../Services/AuthUser'
import BtnSpinner from '../Spinners/BtnSpinner'
import FormikDatePicker from '../Form/FormikDatePicker'
import SelectField from '../Form/SelectField'
import { utilities, getOrgSetting } from '../../Config/Helper'
import uploadIcon from '../../assets/agency/img/onboarding-upload.svg'
import ImgCropper from '../../Pages/Dashboard/Profile/Cropper'
import axios from 'axios'
import PreviewImage from '../Form/PreviewImage'
import Currency from '../Currency/Currency'
import apiCall from '../../Services/ApiInstance'
import Tooltip from '@mui/material/Tooltip'
import { ReactComponent as DrawIcon } from '../../assets/dashboard/img/icons8-info.svg'

import { ReactComponent as EditIcon } from '../../assets/dashboard/img/draw-dynamic.svg'
import { ReactComponent as DeleteIcon } from '../../assets/dashboard/img/delete.svg'
import { SvgIcon } from '@mui/material'
import { useFormik } from 'formik'
import { Grid, TextField } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon-dynamic.svg'
import { IconButton } from '@mui/material'

const animatedComponents = makeAnimated()
let base_URL = process.env.REACT_APP_base_URL
let avatarFieldValue = ''

const validationSchema = yup.object({
    first_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    last_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    email: yup
        .string()
        .email('Invalid email address')
        .required('This field is required!'),
    salary_per_month: yup.mixed().when('payroll_type', {
        is: (payroll_type) => payroll_type === 'full_time',
        then: yup
            .number()
            .typeError('This field is must be a number')
            .min(0, 'This field is cannot be negative')
            .required('This field is required!'),
    }),
    rate_per_hour: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
    stack_role_id: yup.string().required('This field is required!'),
    designation: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only strings are allowed')
        .required('This field is required!'),
    payroll_type: yup.string().required('This field is required!'),
    profile_type: yup.string().required('This field is required!'),
    onboarding_date: yup.string().required('This field is required!'),
    daily_working_hours: yup.mixed().when('payroll_type', {
        is: (payroll_type) => payroll_type !== 'full_time',
        then: yup
            .string()
            .matches(/^\+?[0-9]+$/, 'This field is must be a number')
            .typeError('This field must be a string')
            .required('This field is required!'),
    }),
    exp_month: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
    exp_year: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
    skills: yup
        .array()
        .min(1, 'At least 1 skill is required')
        .required('At least 1 skill is required'),
})
const EditProjectTeamMemberModal = ({
    readyforupdate,
    openHideShowEditModal,
    setHideShowEditModal,
    setReload,
}) => {
    const {
        id,
        avatar,
        company_id,
        first_name,
        last_name,
        email,
        designation,
        salary_per_month,
        internal_rate,
        phone_number,
        profile_type,
        onboarding_date,
        payroll_type,
        exp_month,
        exp_year,
        daily_working_hours,
        skills,
        team_skills,
        stack_role_id,
        rate_per_hour,
    } = readyforupdate
    const { http2, token } = AuthUser()
    const [isUpdateLoading, setUpdateIsLoading] = useState(false)
    const [skillsOption, setSkillsOption] = useState([])
    const [orgSetting, setOrgSetting] = useState({})
    const [isImageDeleted, setIsImageDeleted] = useState(false)

    // Edit Modal Data Update
    const updateTeamMember = (fields) => {
        let UpdateJsDate = new Date(fields.onboarding_date)
        let UpdateIsoFormattedDate = UpdateJsDate.toISOString()
        fields['avatar'] = avatarFieldValue
        fields['update_onboarding_date'] = UpdateIsoFormattedDate
        let data = new FormData()
        Object.keys(fields).forEach((key) => {
            data.append(key, fields[key])
        })
        try {
            setUpdateIsLoading(true)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/update-team/${id}`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        // setReload(true);
                        window.location.reload()
                        setHideShowEditModal(false)
                    }
                    if (result.success == false) {
                        toast.error('something went wrong')
                    }
                    setUpdateIsLoading(false)
                    setHideShowEditModal(false)
                })
                .catch((error) => {
                    const err = error.response.data

                    if (error.response.status) {
                        console.log(err.message)
                        toast.error(err.message)
                        setUpdateIsLoading(false)
                    }
                    setUpdateIsLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setUpdateIsLoading(false)
        }
    }

    const employment = [
        { key: 'full_time', value: 'Full time' },
        { key: 'part_time', value: 'Part time' },
        { key: 'freelance', value: 'Freelance' },
        // { key: "on_contract", value: "On Contract" },
    ]
    const profileType = [
        'Developer',
        'QA',
        'Manager',
        'DevOps',
        'Operations',
        'HR',
        'Finance',
        'Admin',
        'Customer Support',
        'Tech Lead',
    ]

    useLayoutEffect(() => {
        ;(async () => {
            let skills = await utilities('skills')
            setSkillsOption(skills)
            let setting = await getOrgSetting()
            setOrgSetting(setting)
        })()
    }, [])

    const skillsData = skillsOption.map((item) => ({
        value: item._id,
        label: item.name,
    }))

    const employmentData = employment.map((item) => ({
        key: item.key,
        value: item.value,
    }))

    const profileTypeData = profileType.map((item) => ({
        key: item,
        value: item,
    }))

    const [cropperModal, setCropperModal] = useState(false)
    const cropperModalOpen = () => {
        setCropperModal(true)
    }
    const cropperModalClose = () => {
        setCropperModal(false)
        inputRef.current.value = ''
    }

    const [image, setImage] = useState('')
    const [imageName, setImageName] = useState('')
    const [imgAfterCrop, setImgAfterCrop] = useState('')
    const [imgError, setImgError] = useState('')
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg)
    }
    const inputRef = useRef()
    const handleOnChange = (event, f) => {
        const imageFile = event.target.files[0]
        if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
            ) {
                const reader = new FileReader()
                reader.readAsDataURL(event.currentTarget.files[0])
                reader.onload = function (e) {
                    onImageSelected(reader.result)
                }
            }
            setCropperModal(true)
            setImageName(event.currentTarget.files[0].name)
            setImgError('')
            f.setFieldValue('avatar', event.currentTarget.files[0])
        } else {
            setImgError('Please select only jpeg, jpg, png and svg files')
        }
    }

    const onChooseImg = () => {
        inputRef.current.click()
    }

    const onDeleteImg = () => {
        axios
            .delete(`${base_URL}/api/v2/delete-talent-image/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let result = response.data
                if (result.success) {
                    toast.success('Image deleted successfully')
                    setIsImageDeleted(true)
                    setImage('')
                    setImageName('')
                    setImgAfterCrop('')
                    // window.location.reload();
                }
                if (result.success == false) {
                    toast.error('something went wrong')
                }
            })
            .catch((error) => {
                console.error('Error deleting image:', error)
                toast.error('Failed to delete image')
            })
    }
    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement('canvas')
        canvasEle.width = imgCroppedArea.width
        canvasEle.height = imgCroppedArea.height

        const context = canvasEle.getContext('2d')
        let imageObj1 = new Image()
        imageObj1.src = image
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            )

            const dataURL = canvasEle.toDataURL('image/png')

            setImgAfterCrop(dataURL)
            setIsImageDeleted(false)
            var file = dataURLtoFile(dataURL, imageName)
            avatarFieldValue = file
        }
        setCropperModal(false)
        inputRef.current.value = ''
    }
    const dataURLtoFile = (dataurl, filename) => {
        // split dataUrl
        var arr = dataurl.split(','),
            // get type
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            //    convert in machine readable code
            u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    }
    const handleSkillChange = (e, f) => {
        const valuesArray = e.map((e) => e.value)
        f.setFieldValue('skills', e)
        f.setFieldValue('team_skills', valuesArray)
    }

    // Create a Date object for January 1st, 2001
    const minStartDate = new Date(2001, 0, 1)

    const [roleData, setRoleData] = useState('')
    const rolesList = () => {
        // setIsLoading(true);
        http2.get(`/roles/list`).then((res) => {
            let result = res.data.data
            setRoleData(result)
        })
    }
    // /roles/list
    useEffect(() => {
        rolesList()
    }, [])
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div
                className={`modal fade ad-custom-modal ${
                    openHideShowEditModal ? 'show' : ''
                }`}
                id="commonSMModal"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Edit Team Member
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setImgAfterCrop('')
                                        setHideShowEditModal(false)
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <Formik
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            initialValues={{
                                company_id: company_id || '',
                                first_name: first_name || '',
                                last_name: last_name || '',
                                email: email || '',
                                designation: designation || '',
                                salary_per_month: salary_per_month || '',
                                internal_rate: internal_rate || '',
                                phone_number: phone_number || '',
                                profile_type: profile_type || '',
                                onboarding_date: onboarding_date
                                    ? new Date(onboarding_date)
                                    : '',
                                payroll_type: payroll_type || '',
                                exp_month: exp_month || '',
                                exp_year: exp_year || '',
                                daily_working_hours: daily_working_hours || '',
                                avatar: avatar || '',
                                team_skills: team_skills || [],
                                skills: skills || [],
                                stack_role_id: stack_role_id || '',
                                rate_per_hour: rate_per_hour || '',
                            }}
                            onSubmit={(values, { resetForm }) => {
                                updateTeamMember(values)
                                setImgAfterCrop('')
                            }}
                        >
                            {({
                                values,
                                setFieldValue,
                                field,
                                form,
                                formik,
                                touched,
                                errors,
                            }) => (
                                <Form autoComplete="off">
                                    <div className="modal-body">
                                        <div className="d-flex adc-flex">
                                            {/* <div className="ad-addteam-left">
                        <div className="ad-upload-box d-flex align-items-center position-relative">
                          {imgAfterCrop || avatar ? (
                            imgAfterCrop ? (
                              <img
                                className="crop-img"
                                src={imgAfterCrop}
                                alt=""
                              />
                            ) : 
                            isImageDeleted?
                            (
                              <div className="w-100">
                                <div className="text-center">
                                  <img src={uploadIcon} alt="upload" />
                                </div>
                                <div className="ad-xs-title text-center">
                                  Upload Photo
                                </div>
                              </div>
                            )
                            :
                            (
                              <div className="preview-img">
                                <PreviewImage avatar={avatar} />
                              </div>
                            )
                          ) : (
                            <div className="w-100">
                              <div className="text-center">
                                <img src={uploadIcon} alt="upload" />
                              </div>
                              <div className="ad-xs-title text-center">
                                Upload Photo
                              </div>
                            </div>
                          )}
                          <Field name="company_logo">
                            {({ field, form }) => (
                              <>
                                <input
                                  type="file"
                                  name="avatar"
                                  accept=".jpg, .jpeg, .png, .svg"
                                  ref={inputRef}
                                  onChange={(event) =>
                                    handleOnChange(event, form)
                                  }
                                  style={{ display: "none" }}
                                />
                              </>
                            )}
                          </Field>
                          <button
                            className="btn file-upload-input"
                            type="button"
                            // onClick={onChooseImg}
                          ></button>
                        </div>
                        
                        <div className="ad-change-profile-btn">
                          <div style={avatar ? {display:"flex",justifyContent:"center"}: {}}>
                          <button
                            className="btn"
                            type="button"
                            style={avatar ? {width:"38%"}: {}}
                            onClick={onChooseImg}
                          >
                              <SvgIcon
                                sx={{ width: "16px", height: "16px", color: "common.black" }}
                                viewBox="0 0 32 32"
                                component={EditIcon}
                              />
                          </button>
                          {avatar && !isImageDeleted ? (
                            <button className="btn" type="button" style={{width:"38%",marginTop:"2px"}} onClick={()=>{onDeleteImg()}} >
                            <SvgIcon
                              sx={{ width: "19px", height: "19px", color: "common.black" }}
                              viewBox="0 0 32 32"
                              component={DeleteIcon}
                            />
                          </button>

                          ):("")}
                          
                            </div>
                        </div>
                       
                        <ImgCropper
                          cropperModal={cropperModal}
                          cropperType="teamMemberAvatar"
                          cropperModalClose={cropperModalClose}
                          image={image}
                          onCropDone={onCropDone}
                        />
                        {imgError ? (
                          <div>
                            <label className="error">{imgError}</label>
                          </div>
                        ) : (
                          " "
                        )}
                      </div> */}
                                            {/* <div className="ad-addteam-right"> */}
                                            <div className="custom-form ad-custom-form ad-form-theme2 row">
                                                <div className="col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Name*
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="name"
                                                            className="form-control text-capitalize"
                                                        />
                                                        <ValidationError name="name" />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Role*
                                                        </label>
                                                        <Field
                                                            name="stack_role_id"
                                                            className="form-control"
                                                            as="select"
                                                        >
                                                            <option value="">
                                                                Role
                                                            </option>
                                                            {roleData &&
                                                                roleData.map(
                                                                    (item) => (
                                                                        <option
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            value={
                                                                                item.id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Field>
                                                        <ValidationError name="stack_role_id" />
                                                    </div>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Internal Hourly
                                                            Rate*
                                                        </label>
                                                        <div className="position-relative">
                                                            <Field
                                                                type="number"
                                                                className="form-control pl-55"
                                                                name="internal_rate"
                                                            />
                                                            <span className="currency-sign">
                                                                <Currency />
                                                            </span>
                                                            <ValidationError name="internal_rate" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Reserved hours*
                                                        </label>
                                                        <div className="position-relative">
                                                            <Field
                                                                type="number"
                                                                className="form-control pl-55"
                                                                name="reserved_hours"
                                                            />
                                                            <ValidationError name="reserved_hours" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-12 form-group">
                            <div className="position-relative">
                              <label className="label-pos">Last name*</label>
                              <Field
                                type="text"
                                name="last_name"
                                className="form-control text-capitalize"
                              />
                              <ValidationError name="last_name" />
                            </div>
                          </div> */}
                                                {/* <div className="col-md-6 col-12 form-group">
                            <div className="position-relative">
                              <label className="label-pos">
                                Email address*
                              </label>
                              <Field
                                type="text"
                                name="email"
                                className="form-control"
                                readOnly
                              />
                            </div>
                          </div> */}
                                                <div className="col-md-6 col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Phone number
                                                        </label>
                                                        <Field
                                                            type="number"
                                                            className="form-control"
                                                            name="phone_number"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Employment*
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            name="payroll_type"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                const payroll =
                                                                    e.target
                                                                        .value
                                                                setFieldValue(
                                                                    'payroll_type',
                                                                    payroll
                                                                )
                                                                setFieldValue(
                                                                    'salary_per_month',
                                                                    ''
                                                                )
                                                                setFieldValue(
                                                                    'internal_rate',
                                                                    ''
                                                                )
                                                                setFieldValue(
                                                                    'daily_working_hours',
                                                                    ''
                                                                )
                                                            }}
                                                        >
                                                            <option
                                                                value=""
                                                                disabled="disabled"
                                                            >
                                                                Employment
                                                            </option>
                                                            {employmentData &&
                                                                employmentData.map(
                                                                    (team) => (
                                                                        <option
                                                                            key={
                                                                                team.key
                                                                            }
                                                                            value={
                                                                                team.key
                                                                            }
                                                                        >
                                                                            {
                                                                                team.value
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </Field>
                                                    </div>
                                                    <ValidationError name="payroll_type" />
                                                </div>
                                                {values.payroll_type !== '' &&
                                                    values.payroll_type !==
                                                        'full_time' && (
                                                        <>
                                                            <div className="col-md-6 col-12 form-group">
                                                                <div className="position-relative">
                                                                    <label className="label-pos">
                                                                        Working
                                                                        hours*
                                                                    </label>
                                                                    <Field
                                                                        type="number"
                                                                        className="form-control"
                                                                        placeholder=""
                                                                        name="daily_working_hours"
                                                                    />
                                                                </div>
                                                                <ValidationError name="daily_working_hours" />
                                                            </div>
                                                        </>
                                                    )}
                                                {values.payroll_type ===
                                                    'full_time' && (
                                                    <>
                                                        <div className="col-md-6 col-12 form-group">
                                                            <div className="position-relative">
                                                                <label className="label-pos">
                                                                    Monthly CTC*
                                                                </label>
                                                                <div className="position-relative">
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control pl-55"
                                                                        name="salary_per_month"
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            const salary =
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            values.setFieldValue(
                                                                                'salary_per_month',
                                                                                salary
                                                                            )
                                                                            let internal_rate =
                                                                                (parseFloat(
                                                                                    salary
                                                                                ) +
                                                                                    parseFloat(
                                                                                        orgSetting.operation_cost
                                                                                    )) /
                                                                                parseFloat(
                                                                                    orgSetting.monthly_working_hr
                                                                                )
                                                                            values.setFieldValue(
                                                                                'internal_rate',
                                                                                salary
                                                                                    ? internal_rate.toFixed(
                                                                                          2
                                                                                      )
                                                                                    : ''
                                                                            )
                                                                        }}
                                                                    />
                                                                    <span className="currency-sign">
                                                                        <Currency />
                                                                    </span>
                                                                </div>
                                                                <ValidationError name="salary_per_month" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12 form-group">
                                                            <div className="position-relative">
                                                                <label className="label-pos">
                                                                    Internal
                                                                    Hourly Rate*
                                                                </label>
                                                                <div className="position-relative">
                                                                    <Field
                                                                        type="number"
                                                                        className="form-control pl-55"
                                                                        name="internal_rate"
                                                                        readOnly="readOnly"
                                                                    />
                                                                    <span className="currency-sign">
                                                                        <Currency />
                                                                    </span>
                                                                    <ValidationError name="internal_rate" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="col-md-6 col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Designation*
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            className="form-control text-capitalize"
                                                            name="designation"
                                                        />
                                                        <ValidationError name="designation" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="position-relative">
                                                        <SelectField
                                                            label="Profile type*"
                                                            asterisks="label-pos"
                                                            placeholder="Profile type*"
                                                            name="profile_type"
                                                            data={
                                                                profileTypeData
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-12 form-group">
                            <div className="position-relative">
                              <label className="label-pos">Talent Network Rate*</label>
                              <div className="position-relative">
                                <Field
                                  type="number"
                                  className="form-control pl-55"
                                  name="rate_per_hour"
                                />
                                <span className="currency-sign">
                                  <Currency />
                                </span>
                              </div>
                              <ValidationError name="rate_per_hour" />
                            </div>
                          </div> */}

                                                <div className="col-md-6 col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Talent Network Rate*
                                                        </label>
                                                        <div className="position-relative">
                                                            <TextField
                                                                fullWidth
                                                                type="number"
                                                                variant="outlined"
                                                                name="rate_per_hour"
                                                                value={
                                                                    values.rate_per_hour
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    const rate =
                                                                        e.target
                                                                            .value
                                                                    setFieldValue(
                                                                        'rate_per_hour',
                                                                        rate
                                                                    )
                                                                }}
                                                                error={
                                                                    touched.rate_per_hour &&
                                                                    Boolean(
                                                                        errors.rate_per_hour
                                                                    )
                                                                }
                                                                helperText={
                                                                    touched.rate_per_hour &&
                                                                    errors.rate_per_hour
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    '& .MuiInputBase-root':
                                                                        {
                                                                            height: '47px',
                                                                            borderRadius:
                                                                                '8px',
                                                                            '&:hover':
                                                                                {
                                                                                    '& .MuiOutlinedInput-notchedOutline':
                                                                                        {
                                                                                            borderColor:
                                                                                                '#CED1DA',
                                                                                        },
                                                                                },
                                                                        },
                                                                }}
                                                                InputProps={{
                                                                    startAdornment:
                                                                        (
                                                                            <span className="currency-sign">
                                                                                <Currency />
                                                                            </span>
                                                                        ),
                                                                    endAdornment:
                                                                        (
                                                                            <Tooltip
                                                                                title="This is the hourly rate for the resource when hired by other agencies through you."
                                                                                arrow
                                                                            >
                                                                                <SvgIcon
                                                                                    sx={{
                                                                                        width: '16px',
                                                                                        height: '16px',
                                                                                    }}
                                                                                    viewBox="0 0 32 32"
                                                                                    component={
                                                                                        DrawIcon
                                                                                    }
                                                                                    inheritViewBox
                                                                                />
                                                                            </Tooltip>
                                                                        ),
                                                                    inputProps:
                                                                        {
                                                                            style: {
                                                                                paddingLeft:
                                                                                    '44px',
                                                                                fontSize:
                                                                                    '14px',
                                                                            },
                                                                        },
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 form-group position-relative custom-select-group">
                                                    <label className="label-pos">
                                                        Skills*
                                                    </label>
                                                    <Field name="skills">
                                                        {({ field, form }) => (
                                                            <>
                                                                <Select
                                                                    value={
                                                                        field.value ||
                                                                        skills
                                                                    }
                                                                    className="custom-select"
                                                                    placeholder="Select skills"
                                                                    closeMenuOnSelect={
                                                                        true
                                                                    }
                                                                    components={
                                                                        animatedComponents
                                                                    }
                                                                    isMulti
                                                                    options={
                                                                        skillsData
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleSkillChange(
                                                                            e,
                                                                            form
                                                                        )
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Field>
                                                    <ValidationError name="skills" />
                                                </div>
                                                <div className="col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Onboarding date*
                                                        </label>
                                                        <div className="position-relative">
                                                            <Field
                                                                minD={
                                                                    minStartDate
                                                                }
                                                                name="onboarding_date"
                                                                placeholderText="Onboarding date"
                                                                className="form-control pe-5"
                                                                component={
                                                                    FormikDatePicker
                                                                }
                                                                type="text"
                                                            />
                                                            <span className="calendar-icon"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 form-group">
                                                    <div className="position-relative">
                                                        <label className="label-pos">
                                                            Experience*
                                                        </label>
                                                        <div className="position-relative">
                                                            <Field
                                                                type="number"
                                                                className="form-control pr-50"
                                                                name="exp_year"
                                                            />
                                                            <span className="text-right-pos">
                                                                year
                                                            </span>
                                                            <ValidationError name="exp_year" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12 form-group">
                                                    <div className="position-relative">
                                                        <div className="position-relative">
                                                            <Field
                                                                type="number"
                                                                className="form-control pr-70"
                                                                name="exp_month"
                                                            />
                                                            <span className="text-right-pos">
                                                                month
                                                            </span>
                                                            <ValidationError name="exp_month" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {isUpdateLoading ? (
                                                    <BtnSpinner
                                                        cls="ad-theme-btn w-100"
                                                        loader="true"
                                                    />
                                                ) : (
                                                    <button
                                                        className="ad-theme-btn w-100"
                                                        type="submit"
                                                    >
                                                        Update
                                                    </button>
                                                )}
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditProjectTeamMemberModal
