import ValidationError from '../../Components/Errors/ValidationError'
// import IntlTelInput from 'react-intl-tel-input';
// import 'react-intl-tel-input/dist/main.css';

// import { DialCodePreview, PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import {
    defaultCountries,
    FlagImage,
    parseCountry,
    usePhoneInput,
} from 'react-international-phone'

const PhoneInputField = ({ field, form, ...props }) => {
    console.log(field, form, props)
    const re = /^[0-9\b]+$/
    const [value, setValue] = React.useState(field.value)
    const [openMenu, setOpenMenu] = React.useState(false)
    const {
        inputValue,
        handlePhoneValueChange,
        inputRef,
        country,
        setCountry,
    } = usePhoneInput({
        defaultCountry: 'us',
        value,
        countries: defaultCountries,
        onChange: (data) => {
            if (data.inputValue === '' || re.test(data.inputValue)) {
                form.setFieldValue('phone_code', '+' + data.country.dialCode)
                form.setFieldValue('country_short_code', data.country.iso2)
                form.setFieldValue('phone_number', data.inputValue)
            }
        },
        disableFormatting: true,
        disableDialCodeAndPrefix: true,
    })

    useEffect(() => {
        setValue(field.value)
    }, [field.value])

    return (
        <>
            <div className="form-group">
                {props.label === false ? (
                    ''
                ) : (
                    <label htmlFor="phone_number" className="asterisks">
                        Contact Number
                    </label>
                )}
                {/* <Box className="customIntlPhoneInput">
                    <PhoneInput
                        defaultCountry="ua"
                        value={field.value}
                        inputClassName="form-control"
                        disableDialCodeAndPrefix
                        showDisabledDialCodeAndPrefix
                        disableFormatting
                        placeholder={`${props.placeholder === "Mobile number" ? props.placeholder : "Enter your contact number"}`}
                        preferredCountries={['ca', 'us', 'gb', 'sg', 'ae']}
                        onChange={(phone, meta) => {
                            console.log(phone, meta);
                            if (meta.inputValue === '' || re.test(meta.inputValue)) {
                                form.setFieldValue("phone_code", "+" + meta.country.dialCode);
                                form.setFieldValue("country_short_code", meta.country.iso2);
                                form.setFieldValue("phone_number", meta.inputValue);
                            }
                        }}
                    />
                </Box> */}

                <TextField
                    fullWidth
                    label={null}
                    className={
                        props.inputStyleType == 'outlined'
                            ? 'customOutlinedTextFieldVariant'
                            : 'customIntlPhnNoInput'
                    }
                    // inputProps={{ className: "customIntlPhnNoInput-mainInput" }}
                    placeholder={`${props.placeholder === 'Mobile number' ? props.placeholder : 'Enter your contact number'}`}
                    value={inputValue}
                    onChange={handlePhoneValueChange}
                    type="tel"
                    inputRef={inputRef}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ClickAwayListener
                                    onClickAway={() => {
                                        setOpenMenu(false)
                                    }}
                                >
                                    <Select
                                        MenuProps={{
                                            sx: {
                                                height: '300px',
                                                width: '360px',
                                                top: '10px',
                                                left: '-34px',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            disableScrollLock: true,
                                            disablePortal: true,
                                            hideBackdrop: true,
                                            disableEnforceFocus: true,
                                            MenuListProps: {
                                                autoFocus: true,
                                            },
                                            // keepMounted: true,
                                            TransitionProps: {
                                                easing: {
                                                    enter: 'linear',
                                                    exit: 'linear',
                                                },
                                                timeout: {
                                                    appear: 1,
                                                    enter: 1,
                                                    exit: 0.1,
                                                },
                                            },
                                            // onClose:(_, reason) => { console.log(reason, _) }
                                        }}
                                        open={openMenu}
                                        onOpen={() => {
                                            setOpenMenu(!openMenu)
                                        }}
                                        onClose={() => {
                                            setOpenMenu(false)
                                        }}
                                        value={`${country.iso2}-${country.dialCode}`}
                                        onChange={(e) => {
                                            setCountry(
                                                e.target.value.split('-')[0]
                                            )
                                        }}
                                        renderValue={(value) => (
                                            <Stack
                                                direction="row"
                                                className="customIntlPhnNoInput-flagDialCode-par"
                                            >
                                                <FlagImage
                                                    iso2={value.split('-')[0]}
                                                    style={{ display: 'flex' }}
                                                    className="customIntlPhnNoInput-flag"
                                                />
                                                <Typography
                                                    variant="size3RegularCommonBlack"
                                                    className="customIntlPhnNoInput-dialCode"
                                                >
                                                    +{value.split('-')[1]}
                                                </Typography>
                                            </Stack>
                                        )}
                                    >
                                        {defaultCountries.map((c) => {
                                            const country = parseCountry(c)
                                            return (
                                                <MenuItem
                                                    key={country.iso2}
                                                    value={`${country.iso2}-${country.dialCode}`}
                                                >
                                                    <FlagImage
                                                        iso2={country.iso2}
                                                        style={{
                                                            marginRight: '8px',
                                                        }}
                                                    />
                                                    <Typography marginRight="8px">
                                                        {country.name}
                                                    </Typography>
                                                    <Typography color="gray">
                                                        +{country.dialCode}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </ClickAwayListener>
                            </InputAdornment>
                        ),
                    }}
                />

                {/* <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    fieldName="phone_number"
                    value={field.value}
                    separateDialCode={true}
                    onPhoneNumberChange={(isValid, number, country) => {
                        if (number === '' || re.test(number)) {
                            console.log(country,number);
                            form.setFieldValue("phone_code", "+"+country.dialCode);
                            form.setFieldValue("country_short_code",country.iso2);
                            form.setFieldValue("phone_number", number);
                        }
                    }}
                    preferredCountries={['ca','us','gb','sg','ae']}
                    placeholder={`${props.placeholder === "Mobile number" ? props.placeholder : "Enter your contact number"}`}
                    /> */}
                <ValidationError name="phone_number" />
            </div>
        </>
    )
}

export default PhoneInputField
