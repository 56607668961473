import { Field, Formik, Form } from 'formik'
import React, { useState } from 'react'
import ValidationError from '../../Components/Errors/ValidationError'
import * as yup from 'yup'
import { toast, ToastContainer } from 'react-toastify'
import AuthUser from '../../Services/AuthUser'
import BtnSpinner from '../Spinners/BtnSpinner'
const validationSchema = yup.object({
    first_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'first name must be at least 2 characters')
        .max(30, 'first name must not be greater than 30 characters')
        .required('First name field is required'),
    last_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'last name must be at least 2 characters')
        .max(30, 'last name must not be greater than 30 characters')
        .required('Last name field is required'),
    designation: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'Designation must be at least 2 characters')
        .max(30, 'Designation must not be greater than 30 characters')
        .required('Designation field is required'),
    people_permission_ids: yup
        .array()
        .min(1, 'At least 1 permission is required')
        .required('At least 1 permission is required'), // these constraints take precedence
})

const EditCollabratorModal = ({
    readyforupdate,
    openHideShowEditModal,
    setHideShowEditModal,
    peoplePermissions,
    setReload,
    checkedPermissions,
    setCheckedPermissions,
}) => {
    const {
        id,
        company_id,
        first_name,
        last_name,
        email,
        designation,
        permissions,
    } = readyforupdate

    const { http } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)

    // State to track the checked status of the "Select All" checkbox
    const [selectAllChecked, setSelectAllChecked] = useState(false)

    // Event handler for when any individual permission checkbox changes
    const handlePermissionChange = (permissionId, isChecked, f) => {
        let checkedPermissionsids = [...checkedPermissions]

        if (checkedPermissionsids.indexOf(permissionId) === -1) {
            checkedPermissionsids.push(permissionId)
        } else {
            checkedPermissionsids = checkedPermissionsids.filter(
                (item) => item !== permissionId
            )
        }
        setCheckedPermissions(checkedPermissionsids)
        f.setFieldValue('people_permission_ids', checkedPermissionsids)
    }

    // Edit Modal Data Update
    const updatePeople = (fields) => {
        try {
            setIsLoading(true)
            http.post(`/people-update/${id}`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        toast.success(result.message)
                        setIsLoading(false)
                        setHideShowEditModal(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Event handler for the "Select All" checkbox change
    const handleSelectAllChange = (event, f) => {
        const isChecked = event
        setSelectAllChecked(isChecked)
        // Use map to extract the _id property from each object
        const allCheckedPermissionsids = peoplePermissions.map(
            (item) => item._id
        )
        if (isChecked) {
            f.setFieldValue('people_permission_ids', allCheckedPermissionsids)
            setCheckedPermissions(allCheckedPermissionsids)
        } else {
            f.setFieldValue('people_permission_ids', '')
            setCheckedPermissions([])
        }
    }
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div
                className={`modal fade dash-custom-modal collabrator-modal ${
                    openHideShowEditModal ? 'show' : ''
                }`}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setHideShowEditModal(false)
                                }}
                            ></button>
                            <div className="dash-md-title fw-600 text-start">
                                Edit People
                            </div>
                            <Formik
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                initialValues={{
                                    company_id: company_id || '',
                                    first_name: first_name || '',
                                    last_name: last_name || '',
                                    email: email || '',
                                    designation: designation || '',
                                    people_permission_ids: permissions || '',
                                }}
                                onSubmit={(values) => {
                                    updatePeople(values)
                                }}
                            >
                                {(values) => (
                                    <Form
                                        className="dash-custom-form mt-4 row"
                                        autoComplete="off"
                                    >
                                        <div className="col-12 form-group">
                                            <label>Email Address</label>
                                            <Field
                                                type="text"
                                                name="email"
                                                className="form-control"
                                                readOnly
                                            />
                                            <ValidationError name="email" />
                                        </div>
                                        <div className="col-md-6 col-12 form-group">
                                            <label>First Name</label>
                                            <Field
                                                type="text"
                                                name="first_name"
                                                className="form-control"
                                            />
                                            <ValidationError name="first_name" />
                                        </div>
                                        <div className="col-md-6 col-12 form-group">
                                            <label>Last Name</label>
                                            <Field
                                                type="text"
                                                name="last_name"
                                                className="form-control"
                                            />
                                            <ValidationError name="last_name" />
                                        </div>
                                        <div className="col-12 form-group">
                                            <label>Designation</label>
                                            <Field
                                                type="text"
                                                name="designation"
                                                className="form-control"
                                            />
                                            <ValidationError name="designation" />
                                        </div>
                                        <div className="form-group col-12">
                                            <label>Permissions</label>
                                            <div className="ps-3 mt-1 edit-coll-chkbox-block">
                                                <div className="custom-checkbox checkbox-right d-inline-block me-4 mt-1 mb-1 edit-coll-chkbox">
                                                    <Field>
                                                        {({ field, form }) => (
                                                            <input
                                                                className="styled-checkbox"
                                                                id="allAccess"
                                                                type="checkbox"
                                                                name="allAccess"
                                                                {...field}
                                                                onChange={(e) =>
                                                                    handleSelectAllChange(
                                                                        e.target
                                                                            .checked,
                                                                        form
                                                                    )
                                                                }
                                                                checked={
                                                                    checkedPermissions.length ===
                                                                    peoplePermissions.length
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    <label htmlFor="allAccess">
                                                        <strong>
                                                            All Access
                                                        </strong>
                                                    </label>
                                                </div>
                                                {permissions &&
                                                    peoplePermissions.map(
                                                        (
                                                            permissionsitem,
                                                            key
                                                        ) => (
                                                            <>
                                                                <div
                                                                    className="custom-checkbox checkbox-right d-inline-block me-4 mt-1 mb-1 edit-coll-chkbox"
                                                                    key={
                                                                        permissionsitem._id
                                                                    }
                                                                >
                                                                    <div className="custom-checkbox checkbox-right">
                                                                        <Field>
                                                                            {({
                                                                                field,
                                                                                form,
                                                                            }) => (
                                                                                <input
                                                                                    name="people_permission_ids"
                                                                                    type="checkbox"
                                                                                    value={
                                                                                        permissionsitem._id
                                                                                    }
                                                                                    id={
                                                                                        permissionsitem._id
                                                                                    }
                                                                                    className="styled-checkbox"
                                                                                    checked={
                                                                                        checkedPermissions.includes(
                                                                                            permissionsitem._id
                                                                                        )
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handlePermissionChange(
                                                                                            permissionsitem._id,
                                                                                            e
                                                                                                .target
                                                                                                .checked,
                                                                                            form
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>

                                                                        <label
                                                                            htmlFor={
                                                                                permissionsitem._id
                                                                            }
                                                                        >
                                                                            {
                                                                                permissionsitem.name
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                            </div>
                                            <ValidationError
                                                name={`people_permission_ids`}
                                            />
                                        </div>
                                        <div className="mt-3 mb-3 text-center">
                                            {isLoading ? (
                                                <BtnSpinner cls="dash-theme-btn br-20" />
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="dash-theme-btn br-20"
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditCollabratorModal
