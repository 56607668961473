import { Field, useField } from 'formik'
import { useState, useEffect } from 'react'
import ValidationError from '../../Components/Errors/ValidationError'
import AuthUser from '../../Services/AuthUser'

const SelectState = ({
    label,
    name,
    countryId,
    placeholder,
    disabled,
    ...props
}) => {
    const [form, meta] = useField(props)
    const { http } = AuthUser()
    //set country
    const [stateList, setStateList] = useState([])

    //console.log(form?.value?.authority_company_country);
    let cId = form?.value?.authority_company_country
    const getStateList = () => {
        if (cId) {
            http.get(`/get-states/${cId}`).then((res) => {
                let result = res.data
                setStateList(result)
            })
        }
    }

    useEffect(() => {
        getStateList()
    }, [cId])

    return (
        <>
            <Field
                name={name}
                as="select"
                className="form-control"
                disabled={disabled ? disabled : ''}
            >
                <option value="">{placeholder}</option>
                {stateList.map((val, key) => {
                    return (
                        <option key={key} value={val.id}>
                            {val.name}
                        </option>
                    )
                })}
            </Field>
        </>
    )
}

export default SelectState
