import { useState } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import PortfolioModal from './PortfolioModal'
import { Box, useTheme } from '@mui/material'
import NoImage from '../../../assets/dashboard/img/no-image.svg'

const TalentPortfolio = ({
    portfolio,
    setPortfolioDtl,
    setPortfolioModalOpen,
}) => {
    const theme = useTheme()

    const reqPortfolioModalOpen = (item) => {
        setPortfolioDtl(item)
        setPortfolioModalOpen(true)
    }

    let randomLen = Math.floor(Math.random() * 10 + 1)
    return (
        <>
            <OwlCarousel
                className="owl-theme portfolio-slider mt-10"
                loop={false}
                margin={10}
                items={2}
                nav={false}
                key={`carousel_${randomLen}`}
            >
                {portfolio &&
                    portfolio.map((item, index) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    ...(item?.portfolio_image
                                        ? {}
                                        : {
                                              display: 'flex',
                                              alignItems: 'center',
                                              flexDirection: 'column',
                                          }),
                                    '&.MuiBox-root .dash-port-img img.MuiBox-root':
                                        {
                                            maxHeight: '140px',
                                            maxWidth: 'calc(100% - 20px)',
                                            display: 'inline-block',
                                        },
                                }}
                                className="item"
                                onClick={() => reqPortfolioModalOpen(item)}
                                key={index}
                            >
                                {item?.portfolio_image ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minWidth: '160px',
                                        }}
                                        className="dash-port-img cursor-pointer"
                                    >
                                        <Box
                                            component="img"
                                            src={
                                                item?.portfolio_image || NoImage
                                            }
                                            alt={item?.name || ''}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        className="dash-hc-img ad-sm-hc ad-project-circle"
                                        sx={{
                                            background:
                                                theme.palette.common.white,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                transform: 'none',
                                                position: 'relative',
                                                top: 'initial',
                                                left: 'initial',
                                            }}
                                            className="talent-short-name"
                                        >
                                            {item?.name
                                                ? `${item.name.split(' ').length > 1 ? item.name.split(' ')[0][0] : item.name.split(' ')[0][0] + item.name.split(' ')[0][1]}${item.name.split(' ').length > 1 ? ' ' + item.name.split(' ')[item.name.split(' ').length - 1][0] : ''}`
                                                : '-'}
                                        </Box>
                                    </Box>
                                )}
                                <div className="dash-sm-title mt-10">
                                    {item?.name || ''}
                                </div>
                            </Box>
                        )
                    })}
            </OwlCarousel>
        </>
    )
}

export default TalentPortfolio
