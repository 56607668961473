import React, { useRef, useState } from 'react'
import Currency from '../Currency/Currency'
import { Formik, Form, Field } from 'formik'
import FormikDatePicker from '../Form/FormikDatePicker'
import axios from 'axios'
import AuthUser from '../../Services/AuthUser'
import { toast } from 'react-toastify'
import BtnSpinner from '../Spinners/BtnSpinner'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import PreviewImage from '../Form/PreviewImage'
import SelectField from '../Form/SelectField'
import onboardingUploadIcon from '../../assets/agency/img/onboarding-upload.svg'
import SassCurrency from '../Currency/SassCurrency'
import infoIcon from '../../assets/agency/img/information-button.png'
import apiCall from '../../Services/ApiInstance'
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import { ReactComponent as CloseIcon2 } from '../../assets/agency/img/close-icon-dynamic.svg'
import { Box } from '@mui/material'
let base_URL = process.env.REACT_APP_base_URL

const validationSchemaTeam = yup.object({
    project_name: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    client_name: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")//
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    start_date: yup.string().required('This field is required!'),
})
const EditProjectModal = ({
    close,
    editItemDtl,
    setReload,
    setEditModalOpen,
}) => {
    const { token } = AuthUser()
    const minStartDate = new Date()
    const {
        id,
        project_status,
        project_name,
        client_email,
        client_name,
        client_id,
        project_start_date,
        month_budget,
        teams,
        project_logo,
        project_currency,
        project_logo_with_url,
        my_currency_month_budget,
    } = editItemDtl
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { currency } = user
    const date = project_start_date ? new Date(project_start_date) : ''

    const [isEditLoading, setIsEditLoading] = useState(false)

    const [removeProjectLogo, setRemoveProjectLogo] = useState(false)

    const editProjectValues = (fields) => {
        const jsDate = new Date(fields.start_date).toISOString()
        fields['project_start_date'] = jsDate
        fields['is_file_logo_remove'] = removeProjectLogo
        fields['client_email'] = client_email
        fields['client_id'] = client_id
        let data = new FormData()
        Object.keys(fields).forEach((key) => {
            if (key !== '' && key === 'teams') {
                fields[key].forEach((team, index) => {
                    data.append(
                        `teams[${index}][team_member_id]`,
                        team.team_member_id
                    )
                    data.append(`teams[${index}][role]`, team.role)
                    data.append(
                        `teams[${index}][internal_rate]`,
                        team.internal_rate
                    )
                    data.append(
                        `teams[${index}][reserved_hours]`,
                        team.reserved_hours
                    )
                    data.append(
                        `teams[${index}][client_hourly_rate]`,
                        team.client_hourly_rate
                    )
                    data.append(
                        `teams[${index}][add_asteam_lead]`,
                        team.add_asteam_lead
                    )
                })
            } else {
                if (key == 'client_name') {
                    data.append('client_company_name', fields[key])
                } else {
                    data.append(key, fields[key])
                }
            }
        })
        try {
            setIsEditLoading(true)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/edit-project/${id}`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setReload(false)
                        setEditModalOpen(false)
                        // setUpdatedStatus(project_status);
                    }
                    if (result.success == false) {
                        toast.error('something went wrong')
                    }
                    setIsEditLoading(false)
                    setEditModalOpen(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status) {
                        toast.error(err.message)
                        setEditModalOpen(false)
                        setIsEditLoading(false)
                    }
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setIsEditLoading(false)
        }
    }

    // Change Select css according to value
    // const [updatedStatus, setUpdatedStatus] = useState(project_status);
    // const changeHandler = (e) => {
    //   setUpdatedStatus(e.target.value);
    // };

    const handleRemoveTeam = (e, members, item, setFieldValue) => {
        e.preventDefault()
        let updated_members = members.filter(
            (itm) => itm.team_member_id !== item.team_member_id
        )
        setFieldValue('teams', updated_members)
    }

    const currencyList = ['USD', 'CAD', 'INR']
    const currencyType = currencyList.map((item) => ({
        key: item,
        value: item,
    }))
    const [image, setImage] = useState('')
    const [imageName, setImageName] = useState('')
    const [imgAfterCrop, setImgAfterCrop] = useState('')
    const [imgError, setImgError] = useState('')
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg)
    }
    const inputRef = useRef()
    const handleOnChange = (event, f) => {
        const imageFile = event.target.files[0]
        // const dataURL = canvasEle.toDataURL("image/jpeg");

        if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
            ) {
                const reader = new FileReader()
                reader.readAsDataURL(event.currentTarget.files[0])
                reader.onload = function (e) {
                    onImageSelected(reader.result)
                }
            }
            // setCropperModal(true);
            setImageName(event.currentTarget.files[0].name)
            setRemoveProjectLogo(false)
            setImgError('')
            f.setFieldValue('project_logo', event.currentTarget.files[0])
            setImgAfterCrop(event.target.files[0])
            // setLogoName(imageFile.name)
            f.setFieldValue('update_project_logo', imageFile)
            f.setFieldValue('logo_name', imageFile.name)
        } else {
            setImgError('Please select only jpeg, jpg, png and svg files')
        }
    }

    const onChooseImg = () => {
        inputRef.current.click()
    }
    const removeCompanyLogo = (event, f) => {
        f.setFieldValue('project_logo', '')
        f.setFieldValue('update_project_logo', '')
        // f.setFieldValue("project_logo_with_url", "");
        setImgAfterCrop('')
        setRemoveProjectLogo(true)
        // setLogoName("")
    }

    const getExchangeRate = (fromCurrency, toCurrency) => {
        const exchangeRates = {
            USD: {
                CAD: 1.3,
                INR: 80,
            },
            INR: {
                USD: 1 / 80,
                CAD: 1.3 / 80,
            },
            CAD: {
                USD: 1 / 1.3,
                INR: (1 / 1.3) * 80,
            },
        }
        return exchangeRates[fromCurrency]?.[toCurrency] ?? null
    }

    const handleInputTentativeBudget = (e, projectTentative, f) => {
        const { name, value } = e.target
        let tentativeAmount
        if (projectTentative != currency) {
            const exchangeRate = getExchangeRate(projectTentative, currency)
            const convertedAmount = value * exchangeRate
            tentativeAmount = convertedAmount?.toFixed(2)
        } else {
            tentativeAmount = value
        }
        f.setFieldValue(`month_budget`, value)
        f.setFieldValue(`my_currency_month_budget`, tentativeAmount)
    }

    function properCaseName(name) {
        if (!name) return ''

        return name
            .split(' ')
            .map((word) => {
                if (word.toLowerCase() === 'and') {
                    return 'and'
                }
                if (word.endsWith('.')) {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
            })
            .join(' ')
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    project_name: properCaseName(project_name) || '',
                    client_name: properCaseName(client_name) || '',
                    project_status: project_status || '',
                    start_date: date ? new Date(date) : '',
                    month_budget: month_budget || '',
                    project_currency: project_currency || '',
                    project_logo: project_logo || '',
                    update_project_logo: '',
                    project_logo_with_url: project_logo_with_url || '',
                    my_currency_month_budget: my_currency_month_budget || '',
                    teams: teams || [],
                }}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm }) => {
                    editProjectValues(values)
                    setImgAfterCrop('')
                    // setRemoveProjectLogo(false)
                }}
            >
                {({ values, setFieldValue, field, form, formik }) => (
                    <>
                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Edit Project
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setEditModalOpen(false)
                                        // setUpdatedStatus(project_status);
                                        setImgAfterCrop('')
                                        setRemoveProjectLogo(false)
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon2}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-12 form-group">
                                        <div className="d-flex align-items-center project-status-field mb-3">
                                            <div className="me-4">
                                                <label className="mb-0">
                                                    Project Status
                                                </label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="project_status"
                                                    as="select"
                                                    className={`form-control text-start sl-control`}
                                                >
                                                    <option value="Active">
                                                        Active
                                                    </option>
                                                    <option value="OnHold">
                                                        OnHold
                                                    </option>
                                                    <option value="Inactive">
                                                        Inactive
                                                    </option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Project Name*
                                            </label>
                                            <Field
                                                type="text"
                                                name="project_name"
                                                className="form-control text-capitalize"
                                            />
                                            <ValidationError name="project_name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client Name*
                                            </label>
                                            <Field
                                                type="text"
                                                name="client_name"
                                                className="form-control text-capitalize"
                                                disabled
                                                readOnly
                                            />
                                            <ValidationError name="client_name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Currency*
                                            </label>
                                            <SelectField
                                                placeholder="Select"
                                                name="project_currency"
                                                data={currencyType}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Project Start Date*
                                            </label>
                                            <div className="position-relative">
                                                <Field
                                                    minD={minStartDate}
                                                    name="start_date"
                                                    className="form-control pe-5"
                                                    dateFormat="dd-MMM-yyyy"
                                                    ignoreTodaysDefault={true}
                                                    component={FormikDatePicker}
                                                    type="text"
                                                />
                                                <span className="calendar-icon"></span>
                                            </div>
                                            <ValidationError name="start_date" />
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Tentative Budget for a Month
                                            </label>
                                            <div className="position-relative">
                                                <Field>
                                                    {({ field, form }) => (
                                                        <input
                                                            type="text"
                                                            name="month_budget"
                                                            className="form-control pl-55"
                                                            value={
                                                                values.month_budget
                                                            }
                                                            onChange={(e) =>
                                                                handleInputTentativeBudget(
                                                                    e,
                                                                    values.project_currency,
                                                                    form
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                                <span className="currency-sign">
                                                    <SassCurrency
                                                        pCurrency={
                                                            project_currency
                                                        }
                                                    />
                                                </span>
                                                {/* <span className="ad-info-box currency-info-pos">
                          <img src={infoIcon} alt="info" />
                          <div className="ad-info-pos"><SassCurrency pCurrency={currency} /> {values.my_currency_month_budget}</div>
                        </span> */}
                                            </div>
                                            {project_currency != currency &&
                                                values.my_currency_month_budget >
                                                    0 && (
                                                    <div className="base-price-tag">
                                                        <SassCurrency
                                                            pCurrency={currency}
                                                        />{' '}
                                                        {
                                                            values.my_currency_month_budget
                                                        }
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-12 form-group">
                    <div className="position-relative">
                      <label className="label-pos">
                        Link Jira or Google sheet
                      </label>
                      <Field
                        type="text"
                        name="jira_google_url"
                        className="form-control"
                        placeholder="Enter URL"
                      />
                    </div>
                  </div> */}
                                    <div className="col-12 form-group">
                                        <div
                                            className={`drag-drop-box project-drag-drop text-center w-100 position-relative borderradius-8 ${
                                                imgAfterCrop ||
                                                values.project_logo
                                                    ? 'p-0 add-company-bg'
                                                    : ''
                                            }`}
                                        >
                                            {imgAfterCrop ||
                                            values.project_logo ? (
                                                <>
                                                    <div
                                                        className="d-flex align-items-center justify-content-center ad-company-logo"
                                                        style={{
                                                            backgroundColor:
                                                                'white',
                                                        }}
                                                    >
                                                        <PreviewImage
                                                            file={imgAfterCrop}
                                                            avatar={
                                                                project_logo_with_url
                                                            }
                                                        />
                                                    </div>
                                                    <Field name="project_logo">
                                                        {({ field, form }) => (
                                                            <>
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        removeCompanyLogo(
                                                                            event,
                                                                            form
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        position:
                                                                            'absolute',
                                                                        right: 8,
                                                                        top: 8,
                                                                    }}
                                                                >
                                                                    <SvgIcon
                                                                        color="inherit"
                                                                        component={
                                                                            CloseIcon
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </Field>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="w-100">
                                                        <div className="text-center">
                                                            <img
                                                                src={
                                                                    onboardingUploadIcon
                                                                }
                                                                alt="upload"
                                                            />
                                                        </div>
                                                        <div className="mt-1 text-center drag-line text-white">
                                                            <span>
                                                                Project
                                                                Thumbnail
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <Field name="project_logo">
                                                        {({ field, form }) => (
                                                            <>
                                                                <input
                                                                    type="file"
                                                                    accept=".jpg, .jpeg, .png, .svg"
                                                                    ref={
                                                                        inputRef
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        handleOnChange(
                                                                            event,
                                                                            form
                                                                        )
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'none',
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                    </Field>
                                                    <button
                                                        className="btn file-upload-input"
                                                        type="button"
                                                        onClick={onChooseImg}
                                                    ></button>
                                                </>
                                            )}
                                        </div>
                                        <div className="error-block">
                                            {imgError ? (
                                                <div>
                                                    <label className="error">
                                                        {imgError}
                                                    </label>
                                                </div>
                                            ) : (
                                                ' '
                                            )}
                                            <ValidationError name="avatar" />
                                        </div>
                                    </div>
                                    {values.teams.length > 0 ? (
                                        <div className="col-12">
                                            <div className="md-title ad-billing-border-top dash-font-1-1em ad-gray-text mt-2">
                                                Team Member
                                            </div>
                                            <div className="d-flex mt-2 mb-3 align-items-center">
                                                {values.teams.map((item) => (
                                                    <div
                                                        className="me-1"
                                                        key={
                                                            item.team_member_id
                                                        }
                                                    >
                                                        <span
                                                            className="avatar avatar-md rounded-circle position-relative"
                                                            style={{
                                                                background:
                                                                    item.color_code ||
                                                                    '#552FBA',
                                                            }}
                                                        >
                                                            {item.avatar ? (
                                                                <img
                                                                    src={
                                                                        item.avatar
                                                                    }
                                                                    alt={
                                                                        item.short_name
                                                                    }
                                                                />
                                                            ) : (
                                                                item.short_name ||
                                                                'PM'
                                                            )}
                                                            <button
                                                                className="ad-cross-icon"
                                                                onClick={(e) =>
                                                                    handleRemoveTeam(
                                                                        e,
                                                                        values.teams,
                                                                        item,
                                                                        setFieldValue
                                                                    )
                                                                }
                                                            ></button>
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <div className="mt-2 col-12">
                                        {isEditLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="ad-theme-btn w-100"
                                            >
                                                Update
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default EditProjectModal
