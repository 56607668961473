import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

export default function LeadsStatusListSkeleton() {
    return (
        <Stack spacing={1}>
            <Skeleton
                sx={{ borderRadius: 2 }}
                animation="wave"
                variant="rounded"
                height={200}
            />
            <Skeleton animation="wave" variant="rounded" height={200} />
        </Stack>
    )
}
