import { createSlice } from '@reduxjs/toolkit'

/** Redux slice for Leads details page */
export const commonDataSlice = createSlice({
    name: 'commonData',
    initialState: {
        value: {
            companyLogoUrl: null,
            avatarLogoUrl: null,
        },
    },
    reducers: {
        setCompanyLogoUrl: (state, action) => {
            state.value = {
                ...state.value,
                companyLogoUrl: action.payload,
            }
        },
        setClientLogoUrl: (state, action) => {
            state.value = {
                ...state.value,
                clientLogoUrl: action.payload,
            }
        },

        setAvatarLogoUrl: (state, action) => {
            state.value = {
                ...state.value,
                avatarLogoUrl: action.payload,
            }
        },
    },
})

/** Action creators are generated for each case reducer function */
export const { setCompanyLogoUrl, setClientLogoUrl, setAvatarLogoUrl } =
    commonDataSlice.actions

export default commonDataSlice.reducer
