import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Productdesign = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/ProductDesign.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Product Design
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Get the most out of your product design with
                                    Weteams qualified professionals
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Weteams team of business analysts, product
                                engineers, and UX experts will help you validate
                                the feasibility of your idea, build a prototype,
                                adjust the technology stack, and outline the
                                vision for future development based on real
                                market data
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Artificial Intelligence
                                            </div>
                                            <p>
                                                Artificial intelligence, also
                                                known as AI, is already the new
                                                norm in the technology industry.
                                                In general, AI refers to
                                                different automated technologies
                                                and algorithms that carry out
                                                complex computations and provide
                                                novel outcomes. These algorithms
                                                need a lot of information. This
                                                dramatically speeds up all
                                                phases of product design and
                                                production since an AI-powered
                                                system makes it feasible to
                                                identify patterns and solutions
                                                where they were previously
                                                inaccessible.
                                            </p>
                                            <p>
                                                The entire spectrum of
                                                information and data sets,
                                                including human experience and
                                                mathematical formula
                                                understanding, are used in
                                                product design, especially when
                                                creating industrial or practical
                                                products.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Motion design
                                            </div>
                                            <p>
                                                Motion wasn't always one of the
                                                most sought-after product
                                                development and design services.
                                                The challenging process of
                                                incorporating motion into a
                                                digital output was mostly to
                                                blame. Today, though, doing
                                                without can cost you a big
                                                competitive advantage. Motion
                                                design has come a long way
                                                toward becoming a significant
                                                industry that serves commercial
                                                needs. For instance, it is
                                                extensively used in UX design
                                                and brand communication in
                                                agriculture and healthcare.
                                            </p>
                                            <p>
                                                By enhancing engagement and user
                                                experience, motion design
                                                dramatically enhances brand and
                                                product design.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                The modern minimalistic
                                                methodology
                                            </div>
                                            <p>
                                                As sustainable design has gained
                                                in popularity, minimalism has
                                                emerged as one of the most
                                                sought-after trends in product
                                                design. The interface has a
                                                simple, uncluttered look thanks
                                                to the basic design.
                                                Additionally, the audience will
                                                find it easier to use a product
                                                with a minimalistic design.
                                            </p>
                                            <p>
                                                A great technique to make a
                                                product more approachable is
                                                through minimalist product
                                                design. People can more readily
                                                understand the design and find
                                                what they're looking for when
                                                extraneous features are removed
                                                from it.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Productdesign
