import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import apiCall from '../../Services/ApiInstance'
const base_URL = process.env.REACT_APP_base_URL

const SocialCallBack = () => {
    let location = useLocation()
    const navigate = useNavigate()
    let { provider } = useParams()

    useEffect(() => {
        try {
            apiCall
                .get(
                    `${base_URL}/api/v1/login/${provider}/callback${location.search}`
                )
                .then((response) => {
                    let result = response.data // for console log
                    // console.log(result)

                    // console.log(result.data.token);
                    // console.log(result.data.user);
                    // console.log(result.data.user.company);

                    if (result.status == false) {
                        toast.error(result.message)
                        navigate('/sign-in-with-email')
                        //setTimeout(navigate(-2), 5000000);
                    }

                    if (result.status) {
                        localStorage.setItem(
                            'token',
                            JSON.stringify(result.data.token)
                        )
                        localStorage.setItem(
                            'user',
                            JSON.stringify(result.data.user)
                        )

                        let path = result.data.user.is_org_profile_completed
                            ? '/dashboard/overview'
                            : '/onboarding-signup'
                        navigate(path, { replace: true })
                        //toast.success(result.message)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let res = error.response.data
                        console.log(error.response)
                        if (error.response.status == 422) {
                            //toast.error(res.message)
                            navigate(-3)
                        }
                        navigate(-1)
                        toast.error(error.response.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }, [])
    return (
        <>
            {/* <ToastContainer  limit={1} hideProgressBar={true} /> */}
            <div className="common-form-wrap d-flex onboarding-wrap">
                <div className="cf-left white-bg">
                    <div className="skeleton skeleton-h100">
                        <div className="cf-logo">
                            <div className="line h80 w25 m10"></div>
                        </div>
                        <div className="cf-white-box cf-white-pos">
                            <div className="d-flex align-items-center">
                                <div className="user-img line h80 w80px m10 bradius-50"></div>
                                <div className="user-info w50">
                                    <div className="line h25 w50"></div>
                                    <div className="line h15 w75 mt-10"></div>
                                </div>
                            </div>
                            <div className="sm-title mt-30 lh-1-3em">
                                <div className="line h15 w100 mt-10"></div>
                                <div className="line h15 w100 mt-10"></div>
                                <div className="line h15 w100 mt-10"></div>
                                <div className="line h15 w100 mt-10"></div>
                                <div className="line h15 w25 mt-10"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cf-right">
                    <div className="cf-position pd-0">
                        <div className="cd-padding mnh-100">
                            <div className="cf-max-width">
                                <div className="skeleton h100">
                                    <div className="line h25 w75 m10"></div>
                                    <div className="line h25 w50 m10"></div>
                                    <div className="line h15 w50 m10 mt-5"></div>
                                    <div className="line h53 w10 m10 mt-3"></div>
                                    <div className="line h15 w50 m10 mt-5"></div>
                                    <div className="line h53 w10 m10"></div>
                                    <div className="line h15 w50 m10 mt-5"></div>
                                    <div className="line h53 w10 m10"></div>
                                    <div className="text-end">
                                        <div className="line h53 w15 m10 d-inline-block"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="process-block white-bg">
                            <div className="skeleton">
                                <div className="line h15 w25"></div>
                                <div className="mt-20">
                                    <div className="line h15 w100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SocialCallBack
