import StepTabs from '../../../Dashboard/HireNewTalent/StepTabs'
import LeftCard from '../../../../Components/Auth/LeftCard'
import RegisterActionBtns from './RegisterActionBtns'
import SelectField from '../../../../Components/Form/SelectField'
import TextAreaField from '../../../../Components/Form/TextAreaField'

const RegisterStep3 = ({
    nextStep,
    prevStep,
    userdetail,
    onboardingProcess,
    isLoading,
    hiringTalent,
    sendHiringRequest,
}) => {
    let arraySkills = hiringTalent.skills_name
    const sortSkills = arraySkills.join(', ')
    const engagement_type_array = [
        { key: 40, value: 'Part time 40 hours monthly' },
        { key: 80, value: 'Part time 80 hours monthly' },
        { key: 160, value: 'Full time 160 hours monthly' },
    ]

    const filtered_eng = engagement_type_array.filter(
        ({ key }) => key == hiringTalent.engagement_type
    )
    return (
        <>
            <div className="stepper-content fade-in">
                <div className="dash-main-heading text-center mt-40 mb-40">
                    <span className="dash-gradient">Review Requirement</span>
                </div>
                <div className="dash-custom-form">
                    <table className="dash-requir-table w-100 mb-30">
                        <tbody>
                            <tr>
                                <th className="w-50">Domain Experience</th>
                                <td className="w-50 text-end">
                                    {hiringTalent.domain || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-50">Role</th>
                                <td className="w-50 text-end">
                                    {hiringTalent.role || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-50">Experience</th>
                                <td className="w-50 text-end">
                                    {hiringTalent.experience || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-50">Number of Openings</th>
                                <td className="w-50 text-end">
                                    {hiringTalent.team_size || ''}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-50">Skills</th>
                                <td className="w-50 text-end">
                                    {sortSkills.toString()}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-50">Engagement Type</th>
                                <td className="w-50 text-end">
                                    {filtered_eng[0]?.value || ''}
                                </td>
                            </tr>

                            <tr>
                                <th className="w-50">Additional Notes</th>
                                <td className="w-50 text-end additional-note-text">
                                    {hiringTalent.notes || '-'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <RegisterActionBtns
                        prevStep={prevStep}
                        nextStep={nextStep}
                        isLast={true}
                        sendHiringRequest={sendHiringRequest}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </>
    )
}
export default RegisterStep3
