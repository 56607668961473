import React from 'react'

const ViewRateCardDetail = () => {
    return (
        <>
            <div className="rate-card-table mt-10">
                <table className="w-100">
                    <tbody>
                        <tr>
                            <th width="20%">Expertise</th>
                            <th width="15%" className="text-center">
                                Experience in years
                            </th>
                            <th width="25%" className="text-center">
                                Monthly reserved hour
                            </th>
                            <th width="15%" className="text-center">
                                Hourly rate
                            </th>
                            <th width="25%" className="text-center">
                                Monthly (160 Hours){' '}
                            </th>
                        </tr>
                        <tr>
                            <td>Beginner</td>
                            <td className="text-center">0-1</td>
                            <td className="text-center">160</td>
                            <td className="text-center">$10 USD</td>
                            <td className="text-center">$1120 USD</td>
                        </tr>
                        <tr>
                            <td>Intermediate</td>
                            <td className="text-center">1-3</td>
                            <td className="text-center">160</td>
                            <td className="text-center">$18 USD</td>
                            <td className="text-center">$1760 USD</td>
                        </tr>
                        <tr>
                            <td>Advance</td>
                            <td className="text-center">3-7</td>
                            <td className="text-center">160</td>
                            <td className="text-center">$25 USD</td>
                            <td className="text-center">$3000 USD</td>
                        </tr>
                        <tr>
                            <td>Expert</td>
                            <td className="text-center">7+</td>
                            <td className="text-center">160</td>
                            <td className="text-center">$30 & above USD</td>
                            <td className="text-center">$4000 & above USD</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default ViewRateCardDetail
