import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import PasswordField from '../../../Components/Form/PasswordField'
import AuthUser from '../../../Services/AuthUser'
import { Box, IconButton, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/agency/img/close-icon-dynamic.svg'

const ChangePasswordModal = ({ closeChangePasswordModal }) => {
    const { http, token } = AuthUser()

    const [isPasswordLoading, setIsPasswordLoading] = useState(false)

    // Password Field
    const validationSchema = yup.object({
        current_password: yup
            .string()
            .required('Current password field is required'),
        password: yup
            .string()
            .required('Password field is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'Password must be more than 8 characters long, alphanumeric and should contain at-least 1 Uppercase, 1 Lowercase and Special character.'
            )
            .notOneOf(
                [yup.ref('current_password'), null],
                'Current password and new password must be different'
            ),
        confirm_password: yup
            .string()
            .required('Confirm password field is required')
            .oneOf(
                [yup.ref('password'), null],
                "The confirm password doesn't match the new password"
            ),
    })
    const changePassword = (fields) => {
        console.log('changePassword')
        try {
            setIsPasswordLoading(true)
            http.post(`/change-password`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.status) toast.success(result.message)
                    closeChangePasswordModal()
                    if (result.status == false) {
                        toast.error(result.message)
                    }
                    setIsPasswordLoading(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status) {
                        toast.error(err.message)
                        setIsPasswordLoading(false)
                    }
                })
        } catch (error) {}
    }

    return (
        <>
            <Box
                className="modal-header"
                sx={{
                    '&.MuiBox-root': {
                        backgroundColor: 'primary.main',
                    },
                }}
            >
                <Box
                    component="h5"
                    sx={{
                        '&.MuiBox-root.modal-title': {
                            color: 'common.white',
                            ml: 'auto',
                            mr: 'auto',
                        },
                    }}
                    className="modal-title"
                    id="commonSMModalLabel"
                >
                    Change Your Password
                </Box>
                <IconButton
                    sx={{ color: 'common.white' }}
                    onClick={() => {
                        closeChangePasswordModal()
                    }}
                >
                    <SvgIcon component={CloseIcon} />
                </IconButton>
                {/* <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeChangePasswordModal();
          }}
        ></button> */}
            </Box>
            <div className="dash-white-box dash-edit-profile-box profile-change-password">
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                        current_password: '',
                        password: '',
                        confirm_password: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                        // data send serve side
                        changePassword(values)
                        resetForm()
                    }}
                >
                    <Form className="ad-custom-form ad-form-theme2 custom-form dash-change-bg mt-4">
                        {/* <div className="col-md-12 form-group"> */}
                        <PasswordField
                            label="Current Password"
                            placeholder=""
                            name="current_password"
                            asterisks="label-pos"
                            disablePaste={true}
                            formRelCont={true}
                        />
                        {/* </div> */}
                        {/* <div className="col-md-12 form-group"> */}
                        <PasswordField
                            label="New Password"
                            placeholder=""
                            name="password"
                            asterisks="label-pos"
                            disablePaste={true}
                            formRelCont={true}
                        />
                        {/* </div> */}
                        {/* <div className="col-md-12 form-group"> */}
                        <PasswordField
                            label="Confirm New Password"
                            placeholder=""
                            name="confirm_password"
                            asterisks="label-pos"
                            disablePaste={true}
                            formRelCont={true}
                        />
                        {/* </div> */}

                        <div className="mt-3 text-center">
                            {isPasswordLoading ? (
                                <BtnSpinner
                                    cls="dash-theme-btn fw-400 br-20 w-100"
                                    loader="true"
                                />
                            ) : (
                                <button
                                    className="dash-theme-btn fw-400 br-20 w-100"
                                    type="submit"
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </Form>
                </Formik>
            </div>
        </>
    )
}

export default ChangePasswordModal
