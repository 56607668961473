import React from 'react'
import lsb_img1 from '../../assets/img/landing/lsb1.png'
import lsb_img2 from '../../assets/img/landing/lsb2.png'
import lsb_img3 from '../../assets/img/landing/lsb3.png'
const LandingServices = () => {
    return (
        <>
            <section className="landing-service-block">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="w-50">
                            <div className="lsb-box lsb-yellow">
                                <img src={lsb_img1} />
                            </div>
                        </div>
                        <div className="w-50 ps-5">
                            <div className="lsb-mxw">
                                <div className="main-heading fw-700 mb-2">
                                    Take control of your <br />
                                    Business efficiency
                                </div>
                                <p>
                                    Effortlessly monitor team productivity,
                                    track project allocation, resource
                                    utilization in real-time, and automate
                                    client billing all on one platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="w-50 order-2">
                            <div className="lsb-box lsb-green text-center">
                                <img src={lsb_img2} />
                            </div>
                        </div>
                        <div className="w-50 pe-5">
                            <div className="lsb-mxw">
                                <div className="main-heading fw-700 mb-2">
                                    Enhance your client <br />
                                    experience
                                </div>
                                <p>
                                    Effortlessly display project progress and
                                    keep clients updated with seamless project
                                    management tool integrations.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="w-50">
                            <div className="lsb-box lsb-red text-center">
                                <img src={lsb_img3} />
                            </div>
                        </div>
                        <div className="w-50 ps-5">
                            <div className="lsb-mxw">
                                <div className="main-heading fw-700 mb-2">
                                    Build & retain <br />a rockstar team
                                </div>
                                <p>
                                    Empower your team with Weteams to
                                    effortlessly recognize standout performers,
                                    rewarding them with well-deserved praise and
                                    accolades for exceeding expectations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LandingServices
