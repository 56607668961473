import { NavLink, Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import facbookIcon from '../assets/img/fb-icon.png'
import linkedinIcon from '../assets/img/in-icon.png'
import twitterIcon from '../assets/img/tw-icon.png'
import instagramIcon from '../assets/img/insta-icon.svg'
import footerLogo from '../assets/img/footer-logo.svg'
import footerMenuArrow from '../assets/img/icon_chevron_black.svg'
import ContactSales from '../Components/Pricing/ContactSales'
import AgencySmModal from '../Components/Modals/AgencySmModal'
import successCheck from '../assets/agency/img/success-check.svg'

const Footer = ({ page }) => {
    const [comFooterLinks, setcomFooterLinks] = useState(false)
    const [resFooterLinks, setresFooterLinks] = useState(false)
    const [solFooterLinks, setsolFooterLinks] = useState(false)
    const [successContactSales, setSuccessContactSales] = useState(false)
    const { pathname } = useLocation()

    const footerText = (pageIdetifier) => {
        switch (pageIdetifier) {
            case 'agencyManagement':
                return (
                    <>
                        <div className="main-heading white-text text-center font-2-3em">
                            Explore the Endless Possibilities with Weteams{' '}
                            <br />
                            Agency Management Suite
                        </div>
                        <div className="text-center mt-45">
                            <Link to="/signup" className="white-btn">
                                Sign Up For Free
                            </Link>
                        </div>
                    </>
                )
            case 'manageTeam':
                return (
                    <>
                        <div className="main-heading white-text text-center font-2-3em">
                            Where the future teams will be build{' '}
                        </div>
                        <div className="text-center mt-45">
                            <button
                                type="button"
                                className="white-btn"
                                onClick={handleContactSales}
                            >
                                Contact Sales
                            </button>
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="main-heading white-text text-center font-2-3em">
                            Transform the way your agency works with Weteams
                        </div>
                        <div className="text-center mt-45">
                            <Link to="/signup" className="white-btn">
                                Sign Up For Free
                            </Link>
                        </div>
                    </>
                )
        }
    }

    const [open, setOpen] = useState(false)
    const handleContactSales = () => {
        setOpen(true)
    }
    const closeContactSales = () => {
        setOpen(false)
    }

    return (
        <>
            <section className="journey-wrap">
                <div className="container">{footerText(page)}</div>
            </section>
            <footer className="footer-wrap">
                <div className="container">
                    <div className="footer-block d-flex">
                        <div className="footer-logo">
                            <div>
                                <Link to="/" className="footer-logo">
                                    <img src={footerLogo} />
                                </Link>
                            </div>
                            <div className="social-links mt-15">
                                <a
                                    href="https://www.facebook.com/Weteams.Global"
                                    target="_blank"
                                    className="social-icon"
                                >
                                    <img src={facbookIcon} />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/weteams-global/"
                                    target="_blank"
                                    className="social-icon ml-10"
                                >
                                    <img src={linkedinIcon} />
                                </a>
                                <a
                                    href="https://twitter.com/WeteamsGlobal"
                                    target="_blank"
                                    className="social-icon ml-10"
                                >
                                    <img src={twitterIcon} />
                                </a>
                                <a
                                    href="https://instagram.com/weteams.io"
                                    target="_blank"
                                    className="social-icon ml-10"
                                >
                                    <img src={instagramIcon} />
                                </a>
                            </div>
                            <div className="drop-note-msg mt-15">
                                {/* Feel free to drop us a note: <br/> */}
                                <div>
                                    <a href="mailto:hello@weteams.io">
                                        <span className="fot-icon icon-envelope"></span>
                                        hello@weteams.io
                                    </a>
                                </div>
                                {pathname == '/contact' && (
                                    <>
                                        <div>
                                            <a href="tel:+917230050332">
                                                <span className="fot-icon icon-phone"></span>
                                                +91-7230050332
                                            </a>
                                        </div>
                                        <div className="d-inline-flex text-start">
                                            <span className="fot-icon icon-location"></span>
                                            <div className="text-align-center-mobile">
                                                127, SKIT Rd, Pawan Vihar,{' '}
                                                <br />
                                                Jagatpura, Jaipur, <br />
                                                Rajasthan, 302017
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="footer-box">
                            <div className="f-title for-desktop">Company</div>
                            <div
                                className={`f-title for-mobile ${
                                    comFooterLinks ? 'active' : ''
                                }`}
                                onClick={() =>
                                    setcomFooterLinks(!comFooterLinks)
                                }
                            >
                                <div className="d-flex align-items-center">
                                    <div>Company</div>
                                    <span className="ml-auto fr-arrow">
                                        <img src={footerMenuArrow} alt="" />
                                    </span>
                                </div>
                            </div>
                            <ul
                                className={`footer-menu ${
                                    comFooterLinks ? 'active' : ''
                                }`}
                            >
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-use">Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to="/faqs">FAQ's</Link>
                                </li> */}
                            </ul>
                        </div>
                        <div className="footer-box">
                            <div className="f-title for-desktop">Resources</div>
                            <div
                                className={`f-title for-mobile ${
                                    resFooterLinks ? 'active' : ''
                                }`}
                                onClick={() =>
                                    setresFooterLinks(!resFooterLinks)
                                }
                            >
                                <div className="d-flex align-items-center">
                                    <div>Resources</div>
                                    <span className="ml-auto fr-arrow">
                                        <img src={footerMenuArrow} alt="" />
                                    </span>
                                </div>
                            </div>
                            <ul
                                className={`footer-menu ${
                                    resFooterLinks ? 'active' : ''
                                }`}
                            >
                                {/* <li>
                                    <a href="https://weteams.io/blogs/">
                                        Blogs
                                    </a>
                                </li> */}
                                <li>
                                    <NavLink to="/experts-teams">
                                        Experts & Teams
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/digital-innovation">
                                        Digital Innovation
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/how-we-do">
                                        How we do it?
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footer-box">
                            <div className="f-title for-desktop">Solutions</div>
                            <div
                                className={`f-title for-mobile ${
                                    solFooterLinks ? 'active' : ''
                                }`}
                                onClick={() =>
                                    setsolFooterLinks(!solFooterLinks)
                                }
                            >
                                <div className="d-flex align-items-center">
                                    <div>Solutions</div>
                                    <span className="ml-auto fr-arrow">
                                        <img src={footerMenuArrow} alt="" />
                                    </span>
                                </div>
                            </div>
                            <ul
                                className={`footer-menu ${
                                    solFooterLinks ? 'active' : ''
                                }`}
                            >
                                <li>
                                    <Link to="/agency-management-suite">
                                        Agency Management Suite
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/managed-teams">
                                        Managed Teams
                                    </Link>
                                </li>
                                {/* <li>
                            <Link to="/experts-teams">Experts & teams</Link>
                        </li>
                        <li>
                            <Link to="/digital-innovation" state={{ type:'technologies' }}>Technologies</Link>
                        </li>
                        <li>
                            <Link to="/digital-innovation">Industries</Link>
                        </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="copyright text-center">
                            &copy; 2024 Weteams Solutions Pvt Ltd. All Rights
                            Reserved.
                        </div>
                    </div>
                </div>
            </footer>

            <ContactSales
                open={open}
                setOpen={setOpen}
                setSuccessContactSales={setSuccessContactSales}
            />
            <AgencySmModal open={successContactSales} close={false}>
                <div className="text-center">
                    <img src={successCheck} alt="question" />
                </div>
                <div className="mt-3 content text-center">
                    <p>
                        Thank you for showing interest! Our Sales <br />
                        Team will get back to you shortly
                    </p>
                </div>
                <div className="mt-30 text-center">
                    <button
                        type="button"
                        className="ad-theme-btn ad-sm-btn"
                        onClick={() => {
                            setSuccessContactSales(false)
                        }}
                    >
                        Ok, got it!
                    </button>
                </div>
            </AgencySmModal>
        </>
    )
}

export default Footer
