import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import ProjectItem from '../../../Components/Projects/ProjectItem'
import ClientItem from '../../../Components/Client/ClientItem'
import AgencyModal from '../../../Components/Modals/AgencyModal'
import AddNewProjectModal from '../../../Components/Projects/AddNewProjectModal'
import AddNewClientModal from '../../../Components/Client/AddNewClientModal'
import EditProjectModal from '../../../Components/Projects/EditProjectModal'
import NoDataProjects from '../../../Components/Projects/NoDataProjects'
import AuthUser from '../../../Services/AuthUser'
import Skeleton from 'react-loading-skeleton'
import { toast, ToastContainer } from 'react-toastify'
import AgencySmModal from '../../../Components/Modals/AgencySmModal'
import questionIcon from '../../../assets/agency/img/question-icon.svg'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import JiraConnectModal from './JiraConnectModal'
import AddBillingCustomerDetails from '../../../Components/Projects/AddBillingCustomerDetails'
import ViewBillingCustomerDetails from '../../../Components/Projects/EditBillingCustomerDetails'
import ViewClientModal from '../../../Components/Client/ViewClientModal'
import SendClientEmailModal from '../../../Components/Client/SendClientEmailModal'
import successCheck from '../../../assets/agency/img/success-check.svg'
import { Link } from 'react-router-dom'
import upgradeIcon from '../../../assets/agency/img/upgrade-icon.svg'
import FilterGeneral from '../../../Components/Myteams/FilterGeneral'
import { Box } from '@mui/material'
import ClientItemExternal from '../../../Components/Client/ClientItemExternal'
import moment from 'moment'

const Clients = () => {
    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isEmailLoading, setIsEmailLoading] = useState(false)
    const [projectsItem, setProjectsItem] = useState([])
    const [currentFilter, setCurrentFilter] = useState('')
    const [projectsItemOrig, setProjectsItemOrig] = useState([])
    const [openAddClientModal, setOpenAddClientModal] = useState(false)
    const [clientLimit, setClientLimit] = useState(false)
    const [clientLimitNumber, setClientLimitNumber] = useState(0)
    const [clientLimitModal, setClientLimitModal] = useState(false)
    const [deleteClientModal, setDeleteClientModal] = useState({
        client_id: '',
        clien_name: '',
        modalOpen: false,
    })
    const [sendEmail, setSendEmail] = useState({
        client_id: '',
        client_email: '',
        client_name: '',
        modalOpen: false,
    })
    const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false)
    const [clientEditItem, setClientEditItem] = useState({
        showModal: false,
        item: {},
    })
    const [reload, setReload] = useState(false)
    let user = JSON.parse(localStorage.getItem('user'))

    const [totalProjectCount, setTotalProjectCount] = useState(0)

    const sortList = (array, type) => {
        let newArr = [...array]

        newArr.sort((a, b) => {
            let positiveCond = ''
            let negativeCond = ''
            let companyNameA = a?.client_company_name
                ? a.client_company_name
                : a?.company_name
                  ? a.company_name
                  : ''
            let companyNameB = b?.client_company_name
                ? b.client_company_name
                : b?.company_name
                  ? b.company_name
                  : ''

            switch (type) {
                case 'A-Z':
                    if (companyNameA && companyNameB) {
                        positiveCond =
                            companyNameA.toUpperCase() >
                            companyNameB.toUpperCase()
                        negativeCond =
                            companyNameA.toUpperCase() <
                            companyNameB.toUpperCase()
                    } else {
                        return 0
                    }
                    break
                case 'Z-A':
                    if (companyNameA && companyNameB) {
                        positiveCond =
                            companyNameA.toUpperCase() >
                            companyNameB.toUpperCase()
                        negativeCond =
                            companyNameA.toUpperCase() <
                            companyNameB.toUpperCase()
                    } else {
                        return 0
                    }
                    break
                case 'newest':
                    positiveCond =
                        moment(a.created_at).toISOString() <
                        moment(b.created_at).toISOString()
                    negativeCond =
                        moment(a.created_at).toISOString() >
                        moment(b.created_at).toISOString()
                    break
                case 'oldest':
                    positiveCond =
                        moment(a.created_at).toISOString() <
                        moment(b.created_at).toISOString()
                    negativeCond =
                        moment(a.created_at).toISOString() >
                        moment(b.created_at).toISOString()
                    break
                default:
                    return 0
            }

            if (positiveCond) {
                return 1
            }
            if (negativeCond) {
                return -1
            }

            return 0
        })

        console.log('newArr', newArr, type)
        return type == 'Z-A' || type == 'oldest' ? newArr.reverse() : newArr
    }
    // Get Projects
    const getAdminUser = (sorting, clientType) => {
        setIsLoading(true)
        http2
            .get(`/client-list${sorting ? '?is_sort=' + sorting : ''}`)
            .then((res) => {
                let result = res.data.data
                result = clientType
                    ? result.internal_clients
                          .concat(
                              result?.external_clients
                                  ? result.external_clients
                                  : []
                          )
                          .filter((val) => val.client_type == clientType)
                    : result.internal_clients.concat(
                          result?.external_clients
                              ? result.external_clients
                              : []
                      )
                setCurrentFilter({ sorting, clientType })
                setProjectsItem(
                    sortList(
                        result.filter(
                            (v) => v?.client_company_name || v.company_name
                        ),
                        sorting
                    )
                )
                setProjectsItemOrig(
                    sortList(
                        res.data.data.internal_clients
                            .concat(
                                result?.external_clients
                                    ? result.external_clients
                                    : []
                            )
                            .filter(
                                (v) => v?.client_company_name || v.company_name
                            ),
                        sorting
                    )
                )
                setTotalProjectCount(res.data.data.clientCount)
                setClientLimit(res.data.data.is_limit)
                setClientLimitNumber(res.data.data?.max_clients)

                setIsLoading(false)
            })
    }

    const sendClientDeleteRequest = (client_id) => {
        try {
            setIsDeleteLoading(true)
            http2
                .delete(`/delete-client/${client_id}`)
                .then((response) => {
                    console.log(response)
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setDeleteClientModal({
                            ...deleteClientModal,
                            modalOpen: false,
                        })
                        setOpenDeleteSuccessModal(true)
                        setReload()
                        setIsDeleteLoading(false)
                        getAdminUser()
                    }
                    setIsDeleteLoading(false)
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message)
                    }
                    setIsDeleteLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setIsDeleteLoading(false)
        }
    }

    useEffect(() => {
        if (currentFilter) {
            getAdminUser(currentFilter.sorting, currentFilter.clientType)
        } else {
            getAdminUser()
        }

        setReload()
    }, [reload])
    return (
        <>
            <Layout>
                {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
                <div className="ad-dash-info-cards">
                    <div className="d-flex align-items-center justify-content-between adts-header">
                        <div className="d-flex align-items-center ad-xxs-mb1">
                            <div className="ad-st-count w-70">
                                <div className="ad-st-title1">
                                    {totalProjectCount}
                                </div>
                            </div>
                            <div className="dash-hc-info">
                                <div className="md-title text-center fw-600 dash-black-text">
                                    Client(s)
                                </div>
                            </div>
                        </div>
                        <Box display="flex">
                            <Box
                                sx={{
                                    ml: 'auto',
                                    display: 'flex',
                                    alignSelf: 'stretch',
                                    alignItems: 'stretch',
                                }}
                            >
                                <FilterGeneral
                                    filterData={(values) => {
                                        console.log(values)
                                        // setProjectsItem(
                                        //     values?.filter_field0?
                                        //     projectsItemOrig.filter((val)=>val.client_type == values.filter_field0)
                                        //     :
                                        //     projectsItemOrig
                                        // );
                                        getAdminUser(
                                            values.filter_field1,
                                            values.filter_field0
                                        )
                                    }}
                                    clearFilter={() => {
                                        getAdminUser()
                                    }}
                                    filterFields={[
                                        {
                                            type: 'dropdown',
                                            defaultOption: 'Select client type',
                                            options: [
                                                {
                                                    id: 'internal',
                                                    option: 'Internal',
                                                },
                                                {
                                                    id: 'external',
                                                    option: 'External',
                                                },
                                            ],
                                        },
                                        {
                                            type: 'dropdown',
                                            defaultOption: 'Sort',
                                            options: [
                                                {
                                                    id: 'newest',
                                                    option: 'Newest',
                                                },
                                                {
                                                    id: 'oldest',
                                                    option: 'Oldest',
                                                },
                                                {
                                                    id: 'A-Z',
                                                    option: 'A-Z',
                                                },
                                                {
                                                    id: 'Z-A',
                                                    option: 'Z-A',
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            </Box>
                            {user.is_invite_client != 'true' && (
                                <button
                                    className="ad-theme-btn ad-sm-btn"
                                    type="button"
                                    onClick={() => {
                                        if (clientLimit) {
                                            setClientLimitModal(true)
                                        } else {
                                            setOpenAddClientModal(true)
                                        }
                                    }}
                                >
                                    Add Client
                                </button>
                            )}
                        </Box>
                    </div>
                    <div className="ad-team-flex mt-3">
                        <div className="ad-team-summary">
                            <div className="adts-body">
                                <div className="adp-list">
                                    <div
                                        className={`adp-list-width ${projectsItem.length > 0 ? '' : 'scroll-none'}`}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                containerClassName="cs-skeleton"
                                                count={5}
                                                height={80}
                                            />
                                        ) : projectsItem.length > 0 ? (
                                            projectsItem.map((item) =>
                                                item.client_type ==
                                                'internal' ? (
                                                    <ClientItem
                                                        key={item.id}
                                                        item={item}
                                                        reload={() => {
                                                            setReload(true)
                                                        }}
                                                        addClientModal={() => {
                                                            setOpenAddClientModal(
                                                                true
                                                            )
                                                        }}
                                                        deleteClientModal={() => {
                                                            console.log()
                                                            setDeleteClientModal(
                                                                {
                                                                    client_id:
                                                                        item._id,
                                                                    client_name:
                                                                        item.client_company_name,
                                                                    modalOpen: true,
                                                                }
                                                            )
                                                        }}
                                                        viewClientModal={(
                                                            item
                                                        ) => {
                                                            setClientEditItem({
                                                                item,
                                                                showModal: true,
                                                            })
                                                            // setOpenViewClientModal(true);
                                                        }}
                                                        sendEmailModal={() => {
                                                            setSendEmail({
                                                                client_id:
                                                                    item._id,
                                                                client_name:
                                                                    item.client_company_name,
                                                                client_email:
                                                                    item.client_email,
                                                                modalOpen: true,
                                                            })
                                                        }}
                                                    />
                                                ) : (
                                                    <ClientItemExternal
                                                        key={item._id}
                                                        item={item}
                                                        viewClientModal={(
                                                            item
                                                        ) => {
                                                            setClientEditItem({
                                                                item,
                                                                showModal: true,
                                                            })
                                                            // setOpenViewClientModal(true);
                                                        }}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <NoDataProjects
                                                isClient={true}
                                                addProjectModal={() => {
                                                    setOpenAddClientModal(true)
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <AgencyModal open={openAddClientModal}>
                <AddNewClientModal
                    setReload={setReload}
                    setModalOpen={() => {
                        setOpenAddClientModal(false)
                    }}
                />
            </AgencyModal>
            <AgencyModal open={clientEditItem.showModal}>
                <ViewClientModal
                    key={JSON.stringify(clientEditItem.item)}
                    clientEditItem={clientEditItem.item}
                    setReload={setReload}
                    setModalOpen={() => {
                        setClientEditItem({
                            ...clientEditItem,
                            showModal: false,
                        })
                    }}
                    getAdminUser={getAdminUser}
                />
            </AgencyModal>
            <AgencyModal open={sendEmail.modalOpen}>
                <SendClientEmailModal
                    key={sendEmail.client_email}
                    setReload={setReload}
                    sendEmail={sendEmail}
                    setModalOpen={() => {
                        setSendEmail({ ...setSendEmail, modalOpen: false })
                    }}
                />
            </AgencyModal>
            <AgencySmModal
                open={deleteClientModal.modalOpen}
                close={() => {
                    setDeleteClientModal({
                        ...deleteClientModal,
                        modalOpen: false,
                    })
                }}
            >
                <div className="text-center">
                    <img src={questionIcon} alt="question" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Are You Sure?
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        Do you really want to delete{' '}
                        {deleteClientModal.client_name}?
                    </p>
                </div>
                <div className="mt-3 text-center row">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={() =>
                                setDeleteClientModal({
                                    ...deleteClientModal,
                                    modalOpen: false,
                                })
                            }
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        {isDeleteLoading ? (
                            <BtnSpinner
                                cls="ad-theme-btn ad-sm-btn w-100"
                                loader="true"
                            />
                        ) : (
                            <button
                                type="button"
                                className="ad-theme-btn ad-sm-btn w-100"
                                onClick={() => {
                                    sendClientDeleteRequest(
                                        deleteClientModal.client_id
                                    )
                                }}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                </div>
            </AgencySmModal>
            <AgencySmModal
                open={openDeleteSuccessModal}
                close={() => {
                    setOpenDeleteSuccessModal(false)
                }}
            >
                <div className="text-center">
                    <img src={successCheck} alt="question" />
                </div>
                <div className="mt-3 content text-center">
                    <p>You have deleted {deleteClientModal.client_name}</p>
                </div>
                <div className="mt-30 text-center">
                    <button
                        type="button"
                        className="ad-theme-btn ad-sm-btn"
                        onClick={() => {
                            setOpenDeleteSuccessModal(false)
                        }}
                    >
                        Ok, got it!
                    </button>
                </div>
            </AgencySmModal>
            <AgencySmModal
                open={clientLimitModal}
                close={() => {
                    setClientLimitModal(false)
                }}
            >
                <div className="text-center">
                    <img src={upgradeIcon} alt="upgrade" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Limit exceeded
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        Your current plan supports only {clientLimitNumber}{' '}
                        clients. Please upgrade to a higher plan to add more
                        clients.
                    </p>
                </div>
                <div className="mt-3 text-center row px-3">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={() => {
                                setClientLimitModal(false)
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        <Link
                            className="ad-theme-btn ad-sm-btn w-100 d-block"
                            to="/dashboard/settings/billing-subscription/select-plan"
                        >
                            Upgrade
                        </Link>
                    </div>
                </div>
            </AgencySmModal>
        </>
    )
}

export default Clients
