// import OwlCarousel from "react-owl-carousel";
// import { useEffect } from "react";
// import { useState } from "react";
// import { useParams } from "react-router-dom";
// import AuthUser from "../../Services/AuthUser";
import Skeleton from 'react-loading-skeleton'
import noContractData from '../../assets/dashboard/img/no-data-contract.png'
import Currency from '../Currency/Currency'

const WeteamsTalentContractViewModal = ({
    //   hireTalent,
    //   renewBtn,
    //   resetviewContract,
    //   endContractRequest,
    //   open,
    //   setOpenViewContract,
    renewContract,
    setViewContract,
    isLoading,
    viewHiring,
    activeTab,
    contractViewData,
    endContractRequest,
    expireStatus,
}) => {
    // const params = useParams();
    // const { id } = params;
    // const { http, http2 } = AuthUser();
    const {
        // id,
        name,
        image,
        designation,
        experience,
        rate_per_hour,
        country,
        color_code,
        short_name,
        total_estimated_billing,
    } = contractViewData

    const { contract_status } = viewHiring
    const contarctPending =
        viewHiring &&
        viewHiring.filter((cont) => cont.contract_status == 'Pending')

    //   const removeModal = () => {
    //     setActiveTab("Ongoing");
    //     // document.getElementById("viewHiringContractModal").classList.remove("show");
    //     setOpenViewContract(false)
    //   };
    let randomLen = Math.floor(Math.random() * 10 + 1)

    let pastContract = []
    let currentContract = []
    let upcomingContract = []

    const firstAcceptContract =
        viewHiring &&
        viewHiring.find((item) => item.contract_status === 'Accepted')

    viewHiring &&
        viewHiring.map((contractItem) => {
            //var today = new Date();
            let contractEndMonth = contractItem.contract_end_month
            let months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ]
            let contractEndMonthIndex = months.indexOf(contractEndMonth) + 1
            let contractYear = contractItem.contract_year
            let complteteYear = [contractEndMonthIndex, contractYear]
            let newDate = new Date()
            // let contractEndDate = new Date(contractItem.contract_end_date)
            let contractEndDate = new Date(complteteYear[1], +complteteYear[0])
            let getPastContract = contractEndDate < newDate
            if (
                contractItem.contract_start_month != null &&
                contractEndDate <= newDate
            ) {
                contractItem.contract_status = 'Expired'
                pastContract.push(contractItem)
            }
            if (
                contractItem.contract_status === 'Accepted' &&
                !getPastContract
            ) {
                currentContract.push(contractItem)
            }
            if (contractItem.contract_status === 'Pending') {
                upcomingContract.push(contractItem)
            }
        })

    //   useEffect(() => {
    //     temp();
    //   }, [name, resetviewContract]);
    //
    return (
        <>
            {/* {console.log(viewHiring)} */}
            <div className="modal-body p-0 cs-new-modal">
                <div className="modal-header">
                    <h5 className="modal-title" id="commonSMModalLabel">
                        Contact Sales
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setViewContract(false)}
                    ></button>
                </div>

                <div className="d-flex align-items-center dash-viewdtl-top mt-0">
                    <div className="d-flex align-items-center">
                        <div
                            className="dash-hc-img"
                            style={{ background: color_code || '' }}
                        >
                            {image ? (
                                <img src={image} alt={name} />
                            ) : (
                                <span className="talent-short-name">
                                    {short_name}
                                </span>
                            )}
                        </div>
                        <div className="dash-hc-info">
                            <div className="dash-md-title dash-white-text fw-600 dash-font-0-9em">
                                {name}
                            </div>
                            <div className="dash-md-title mt-1 dash-font-0-9em dash-white-text">
                                {designation}{' '}
                                <span className="dash-exp-count dash-white-text">
                                    {experience}
                                </span>
                            </div>
                            <div className="dash-md-title dash-white-text fw-500 dash-font-0-9em mt-1">
                                <span className="dash-hc-pin hc-white-pin"></span>
                                {country}
                            </div>
                        </div>
                    </div>
                    <div className="ml-auto">
                        {total_estimated_billing > 0 ? (
                            contract_status === 'Pending' ? (
                                ''
                            ) : (
                                <div className="dash-md-title dash-font-0-8em dash-white-text fw-500 text-end">
                                    Estimated billing :{' '}
                                    <Currency
                                        amount={total_estimated_billing}
                                    />
                                </div>
                            )
                        ) : (
                            ''
                        )}
                        <div className="dash-md-title dash-font-0-8em dash-white-text fw-500 text-end">
                            Rate/Hour : <Currency amount={rate_per_hour} />
                        </div>
                    </div>
                </div>

                {activeTab ? (
                    <div className="contract-tabs-block">
                        <div className="custom-contract-tabs mt-4">
                            <ul
                                className="nav nav-tabs"
                                id="myTab"
                                role="tablist"
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${
                                            activeTab === 'Ongoing'
                                                ? 'active'
                                                : ''
                                        }`}
                                        id="Current-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#Current"
                                        type="button"
                                        role="tab"
                                        aria-controls="Current"
                                        aria-selected={activeTab === 'Ongoing'}
                                    >
                                        Ongoing
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${
                                            activeTab === 'Upcoming'
                                                ? 'active'
                                                : ''
                                        }`}
                                        id="Upcoming-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#Upcoming"
                                        type="button"
                                        role="tab"
                                        aria-controls="Upcoming"
                                        aria-selected={activeTab === 'Upcoming'}
                                    >
                                        Upcoming
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`nav-link ${
                                            activeTab === 'Past' ? 'active' : ''
                                        }`}
                                        id="Past-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#Past"
                                        type="button"
                                        role="tab"
                                        aria-controls="Past"
                                        aria-selected={activeTab === 'Past'}
                                    >
                                        Past
                                    </button>
                                </li>
                            </ul>

                            <div
                                className="tab-content ctb-pd"
                                id="myTabContent"
                            >
                                <div
                                    className={`tab-pane fade ${
                                        activeTab == 'Ongoing'
                                            ? 'show active'
                                            : ''
                                    }`}
                                    id="Current"
                                    role="tabpanel"
                                    aria-labelledby="Current-tab"
                                >
                                    {isLoading ? (
                                        <div className="mt-3">
                                            <div className="row">
                                                <div className="col-md-6 mt-4">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={2}
                                                        height={20}
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-4">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={2}
                                                        height={20}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : currentContract.length > 0 ? (
                                        <div className="contract-custom-table">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th>Work Start Date</th>
                                                        <th>
                                                            Contribution Hour
                                                        </th>
                                                        <th>
                                                            Contract Duration
                                                        </th>
                                                        <th>
                                                            Communication
                                                            Channel
                                                        </th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentContract.map(
                                                        (item) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {item.work_start_date.split(
                                                                        'T'
                                                                    )[0] || ''}
                                                                </td>
                                                                <td>
                                                                    {item.contribute_hours ||
                                                                        ''}
                                                                </td>
                                                                <td>
                                                                    {item.contract_duration ||
                                                                        ''}
                                                                </td>
                                                                <td>
                                                                    {item.communication_channel ||
                                                                        '-'}
                                                                </td>
                                                                <td className="text-center">
                                                                    <span
                                                                        className={`dash-status-btn ${item.contract_status}-bg`}
                                                                    >
                                                                        {
                                                                            item.contract_status
                                                                        }
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="no-cotract-data">
                                            <img
                                                src={noContractData}
                                                alt="no data"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`tab-pane fade ${
                                        activeTab === 'Upcoming'
                                            ? 'show active'
                                            : ''
                                    }`}
                                    id="Upcoming"
                                    role="tabpanel"
                                    aria-labelledby="Upcoming-tab"
                                >
                                    {isLoading ? (
                                        <div className="mt-3">
                                            <div className="row">
                                                <div className="col-md-6 mt-4">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={2}
                                                        height={20}
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-4">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={2}
                                                        height={20}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : upcomingContract.length > 0 ? (
                                        <div className="contract-custom-table">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th>Work Start Date</th>
                                                        <th>
                                                            Contribution Hour
                                                        </th>
                                                        <th>
                                                            Contract Duration
                                                        </th>
                                                        <th>
                                                            Communication
                                                            Channel
                                                        </th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {upcomingContract.map(
                                                        (item) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {item.work_start_date.split(
                                                                        'T'
                                                                    )[0] || ''}
                                                                </td>
                                                                <td>
                                                                    {item.contribute_hours ||
                                                                        ''}
                                                                </td>
                                                                <td>
                                                                    {item.contract_duration ||
                                                                        ''}
                                                                </td>
                                                                <td>
                                                                    {item.communication_channel ||
                                                                        '-'}
                                                                </td>
                                                                <td className="text-center">
                                                                    <span
                                                                        className={`dash-status-btn ${item.contract_status}-bg`}
                                                                    >
                                                                        {
                                                                            item.contract_status
                                                                        }
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="no-cotract-data">
                                            <img
                                                src={noContractData}
                                                alt="no data"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={`tab-pane fade ${
                                        activeTab === 'Past'
                                            ? 'show active'
                                            : ''
                                    }`}
                                    id="Past"
                                    role="tabpanel"
                                    aria-labelledby="Past-tab"
                                >
                                    {isLoading ? (
                                        <div className="mt-3">
                                            <div className="row">
                                                <div className="col-md-6 mt-4">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={2}
                                                        height={20}
                                                    />
                                                </div>
                                                <div className="col-md-6 mt-4">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={2}
                                                        height={20}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : pastContract.length > 0 ? (
                                        <div className="contract-custom-table">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th>Work Start Date</th>
                                                        <th>
                                                            Contribution Hour
                                                        </th>
                                                        <th>
                                                            Contract Duration
                                                        </th>
                                                        <th>
                                                            Communication
                                                            Channel
                                                        </th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pastContract.map(
                                                        (item) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {item.work_start_date.split(
                                                                        'T'
                                                                    )[0] || ''}
                                                                </td>
                                                                <td>
                                                                    {item.contribute_hours ||
                                                                        ''}
                                                                </td>
                                                                <td>
                                                                    {item.contract_duration ||
                                                                        ''}
                                                                </td>
                                                                <td>
                                                                    {item.communication_channel ||
                                                                        '-'}
                                                                </td>
                                                                <td className="text-center">
                                                                    <span
                                                                        className={`dash-status-btn ${item.contract_status}-bg`}
                                                                    >
                                                                        {
                                                                            item.contract_status
                                                                        }
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className="no-cotract-data">
                                            <img
                                                src={noContractData}
                                                alt="no data"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="text-center mt-4">
                                    <div className="d-inline-flex rnw-btn-block align-items-center">
                                        {currentContract.length > 0 ? (
                                            expireStatus === 'Approve' ? (
                                                <span className="ad-info-box">
                                                    <button
                                                        type="button"
                                                        className="theme-dark-btn fw-500 br-20"
                                                        disabled={true}
                                                    >
                                                        End Contract
                                                    </button>
                                                    <div className="ad-info-pos">
                                                        Your request to end the
                                                        contract has been
                                                        approved. No further
                                                        action is required.
                                                    </div>
                                                </span>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="theme-dark-btn fw-500 br-20"
                                                    onClick={() =>
                                                        endContractRequest(
                                                            firstAcceptContract
                                                        )
                                                    }
                                                >
                                                    End Contract
                                                </button>
                                            )
                                        ) : (
                                            ''
                                        )}
                                        {viewHiring.length > 0 ? (
                                            upcomingContract.length != 0 ? (
                                                <button
                                                    type="button"
                                                    className="dash-theme-btn fw-500 br-20 ms-3"
                                                    onClick={() =>
                                                        renewContract(
                                                            viewHiring
                                                        )
                                                    }
                                                    disabled={true}
                                                >
                                                    Renew Contract
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="dash-theme-btn fw-500 br-20 ms-3"
                                                    onClick={() =>
                                                        renewContract(
                                                            viewHiring
                                                        )
                                                    }
                                                >
                                                    Renew Contract
                                                </button>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-3">
                        <div className="row">
                            <div className="col-md-6 mt-4">
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={2}
                                    height={20}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={2}
                                    height={20}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={2}
                                    height={20}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={2}
                                    height={20}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={2}
                                    height={20}
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={2}
                                    height={20}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default WeteamsTalentContractViewModal
