// import moment from 'moment';
// import 'moment-timezone';
// import { utilities } from '../../Config/Helper';
// import { useEffect, useState } from 'react';
// import SocialLogin from '../../Components/Auth/SocialLogin';
// import { Link, useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as yup from 'yup';
// import TextField from '../../Components/Form/TextField';
// import ValidationError from '../../Components/Errors/ValidationError';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SelectCountry from '../../Components/Form/SelectCountry';
// import PhoneInputField from '../../Components/Form/PhoneInputField';
// import PasswordField from '../../Components/Form/PasswordField';
// import BtnSpinner from '../../Components/Spinners/BtnSpinner';
// import LeftCard from '../../Components/Auth/Onboarding/LeftCard';
// import intelSky from '../../assets/dashboard/img/intel-sky.svg'
// import apiCall from '../../Services/ApiInstance';
// let base_URL = process.env.REACT_APP_base_URL;

import PageNotFound from '../../Components/Errors/PageNotFound'

// const validationSchema = yup.object({
//     first_name: yup
//         .string()
//         .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
//         .min(2, 'first name must be at least 2 characters')
//         .max(30, 'first name must not be greater than 30 characters')
//         .required('First name field is required'),
//     last_name: yup
//         .string()
//         .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
//         .min(2, 'last name must be at least 2 characters')
//         .max(30, 'last name must not be greater than 30 characters')
//         .required('Last name field is required'),
//     phone_number: yup.number().required('Contact number field is required').positive().integer(),
//     email: yup.string().email().required('Email field is required'),
//     country_id: yup.string().required('Country field is required'),
//     password: yup
//         .string()
//         .required('Password field is required')
//         .matches(
//             /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
//             'Password must be more than 8 characters long, alphanumeric and should contain at-least 1 Uppercase, 1 Lowercase and Special character.'
//         ),
//     confirm_password: yup
//         .string()
//         .required('Confirm password field is required')
//         .oneOf(
//             [yup.ref('password'), null],
//             'Confirm Password should be the Same as Password fields'
//         ),
//     terms_conditions: yup.bool().oneOf([true], 'Accept field is required'),
//     timezone: yup.string().required('we are not fetch your time zone please page refresh.'),
// });

// const SignupNow = () => {

//     const [isLoading, setIsLoading] = useState(false);

//     const navigate = useNavigate();
//     //set country
//     const [countries, setCountries] = useState([]);

//     useEffect(() => {
//         (async () => {
//             let countries = await utilities('countries');
//             setCountries(countries);
//         })();
//     }, []);

//     const createUser = (fields) => {

//     //   Static data for test
//         try {
//             setIsLoading(true); // start loading spinner
//             apiCall
//                 .post(`${base_URL}/api/v1/sign-up`, fields)
//                 .then((response) => {
//                     let result = response.data;
//                     if (result.status) {
//                         toast.success(result.message);
//                         setIsLoading(false); // stop loading spinner
//                         navigate(
//                             '/verify-email-address',
//                             { state: { email: result.data.email } },
//                             { replace: true }
//                         );
//                     }

//                 })
//                 .catch((error) => {
//                     if (error.response) {
//                         setIsLoading(false); // stop loading spinner
//                         toast.error(error.response.data.data);
//                     }
//                 });
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     return (
//         <>
//             <ToastContainer  limit={1} hideProgressBar={true}/>
//             <div className="common-form-wrap d-flex">
//                 {/* left side Components */}
//                 <LeftCard>
//                         <div className="cf-white-box">
//                             <div className="d-flex align-items-center">
//                                 <div className="user-img bg-blue br-50">
//                                     <img src={intelSky}  alt="BlueSky" />
//                                 </div>
//                                 <div className="user-info">
//                                     <div className="sm-title">Anthony Pearlman</div>
//                                     <div className="xs-title mt-1">COO, BlueSky Intel</div>
//                                 </div>
//                             </div>
//                             <div className="sm-title mt-30 lh-1-3em">
//                                 We built our digital product innovation team with Weteams pretty much instantly within a week. Being a completely remote team we are really happy with the outcomes of our diversification project. Resources are highly professional, and quality of services are top notch.
//                             </div>
//                         </div>
//                 </LeftCard>
//                 <div className="cf-right">
//                     <div className="cf-position">
//                         <div className="cf-max-width">
//                             <div className="main-heading">
//                                 <span className="wlc-gradient">
//                                     Sign Up now & start building <br />
//                                     your team
//                                 </span>
//                             </div>
//                             <Formik
//                                 validationSchema={validationSchema}
//                                 initialValues={{
//                                     first_name: '',
//                                     last_name: '',
//                                     phone_code: '',
//                                     phone_number: '',
//                                     country_short_code: '',
//                                     email: '',
//                                     country_id: '',
//                                     password: '',
//                                     confirm_password: '',
//                                     terms_conditions: false,
//                                     timezone:moment.tz.guess(),
//                                 }}
//                                 onSubmit={(values) => {
//                                     // data send serve side
//                                     createUser(values);
//                                 }}
//                             >
//                                 <Form className="custom-form mt-40" autoComplete="off">
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <TextField
//                                                 label="First Name"
//                                                 asterisks="asterisks"
//                                                 type="text"
//                                                 placeholder="Enter your first name"
//                                                 name="first_name"
//                                             />
//                                         </div>
//                                         <div className="col-md-6">
//                                             <TextField
//                                                 label="Last Name"
//                                                 asterisks="asterisks"
//                                                 type="text"
//                                                 placeholder="Enter your last name"
//                                                 name="last_name"
//                                             />
//                                         </div>
//                                     </div>

//                                     <Field
//                                         name="phone_number"
//                                         placeholder="Enter your contact number"
//                                         component={PhoneInputField}
//                                     />
//                                     <TextField
//                                         label="Email Address"
//                                         asterisks="asterisks"
//                                         type="email"
//                                         placeholder="Enter your email address"
//                                         name="email"
//                                     />
//                                     <SelectCountry
//                                         label="Country"
//                                         placeholder="Select Country"
//                                         name="country_id"
//                                         data={countries}
//                                     />
//                                     <PasswordField
//                                         label="Password"
//                                         asterisks="asterisks"
//                                         placeholder="Enter password"
//                                         name="password"
//                                     />
//                                     <PasswordField
//                                         label="Confirm Password"
//                                         asterisks="asterisks"
//                                         placeholder="Re-Enter password"
//                                         name="confirm_password"
//                                     />
//                                     <div className="form-group">
//                                         <div className="custom-checkbox">
//                                             <Field
//                                                 className="styled-checkbox"
//                                                 id="tcAndPP"
//                                                 type="checkbox"
//                                                 name="terms_conditions"
//                                             />
//                                             <label htmlFor="tcAndPP">
//                                                 I agree to the{' '}
//                                                 <Link
//                                                     to="/terms-of-use"
//                                                     className="theme-text"
//                                                     target="_blank"
//                                                 >
//                                                     terms & conditions
//                                                 </Link>{' '}
//                                                 and{' '}
//                                                 <Link
//                                                     to="/privacy-policy"
//                                                     className="theme-text"
//                                                     target="_blank"
//                                                 >
//                                                     privacy policy
//                                                 </Link>
//                                                 .
//                                             </label>
//                                         </div>
//                                         <ValidationError name="terms_conditions" />
//                                     </div>
//                                     <ValidationError name="timezone" />
//                                     {isLoading ? (
//                                         <BtnSpinner />
//                                     ) : (
//                                         <button
//                                             type="submit"
//                                             className="theme-dark-transparent-btn w-100 mt-20 text-center"
//                                         >
//                                             Sign Up
//                                         </button>
//                                     )}
//                                 </Form>
//                             </Formik>
//                             <div className="or-block">
//                                 <span>OR</span>
//                             </div>
//                             {/* social Components */}
//                             <SocialLogin />
//                             <div className="mt-40">
//                                 <div className="link">
//                                     Already have a Weteams Account?{' '}
//                                     <Link to="/signin" className="theme-text">
//                                         Sign In
//                                     </Link>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default SignupNow;

const SignupNow = () => {
    return <PageNotFound />
}

export default SignupNow
