import Layout from '../../Layouts/Layout'

const Msa = () => {
    return (
        <>
            <Layout>
                <div className="container">
                    <div className="common-content">
                        <div className="main-heading text-center mb-3">
                            Master Service Agreement
                        </div>
                        <h3>1. Services</h3>
                        <ul>
                            <li>
                                Shall mean and refer to the IT-related
                                services/resources provided by the Service Agent
                                to the Users of the Platform.
                            </li>
                        </ul>
                        <p>
                            <strong>Please Note:</strong> If any term of this
                            agreement conflicts with any term of the statement
                            of work, the terms of this agreement shall supersede
                            and control. Any change in the scope of services set
                            forth in the statement of work must be agreed in
                            writing by the parties.{' '}
                        </p>

                        <h3>2. Fees- Payment</h3>
                        <p>
                            Service Provider shall invoice Client as described
                            in the Statement of Work and Payment Terms in
                            Annexure 1 for all Services actually rendered during
                            the previous month at the rate (the “Fees”) set
                            forth therein and for its reasonable out of pocket
                            costs and expenses (excluding travel time) incurred
                            in connection with Provider’s performance of the
                            Services, only as pre-approved in writing by Client,
                            in each case, accompanied with supporting
                            documentation. Client will pay such invoices within{' '}
                            <u>
                                <strong>Seven (7)</strong>
                            </u>{' '}
                            days after the receipt thereof. Client may, in its
                            sole and absolute discretion, make all payments to
                            Service Provider due pursuant to this Agreement via
                            NEFT, RTGS, CHEQUE to the following bank account (or
                            such other bank account in the name of Service
                            Provider identified from time to time by Service
                            Provider in writing to Client.
                        </p>

                        <h3>3. Acceptance of Deliverables</h3>
                        <p>
                            Client will have ten (10) business days after
                            receipt of any Deliverable (or portion of
                            Deliverable, if such Deliverable is to be delivered
                            in milestones/sprint plan as set forth in each
                            Statement of Work) to test and review such
                            Deliverable. If a Deliverable does not comply with
                            the specifications for such Deliverable, Client may
                            reject such Deliverable, in its sole discretion, by
                            written notice of rejection to Provider. The service
                            Provider will correct any material deficiencies and
                            provide clients with a revised Deliverable as soon
                            as practicable. Clients will have the right to
                            accept or reject the corrected Deliverable.
                        </p>

                        <h3>4. Ownership & Intellectual Right to Property</h3>
                        <ul>
                            <li>
                                In the event of a demand/ request by the client,
                                Weteams is obligated to transfer, share, and
                                surrender all the project Source coding notes,
                                source code or any other information affiliated
                                or corresponding to the said project upon the
                                clearance of the payment by the client. The
                                transfer of any such information shall take
                                place at the time of the deployment or according
                                to any instructions given by the client as per
                                the milestone / sprint payment terms.
                                Furthermore, Weteams is required to obliterate
                                and waive rights to all such information or data
                                which is kept and used for the purposes of
                                providing the services. The same shall be given
                                effect to after completion of the entire
                                delivery or at any instance of the Client for
                                that matter.
                            </li>
                            <li>
                                Pre-Existing Materials: The Service Provider
                                agrees that if in the course of performing the
                                services covered by this Agreement, the Service
                                Provider incorporates into any invention,
                                improvement, development, concept, discovery or
                                other proprietary information owned by the
                                Service Provider or in which the Service
                                Provider has an interest:
                            </li>
                            <ul>
                                <li>
                                    The Client is hereby granted and shall have
                                    a royalty-free, perpetual, irrevocable,
                                    worldwide license to make, have made,
                                    modify, use and sell such items as part of
                                    or in connection with such an invention.
                                </li>
                            </ul>
                        </ul>

                        <h3>5. Representations and Warranties</h3>
                        <p>
                            Service Provider represents, warranties and
                            covenants to Client that
                        </p>
                        <ul>
                            <li>
                                Service Provider has full power and authority to
                                enter into this Agreement, grant the rights
                                granted to Client hereunder and perform all of
                                its obligations hereunder;{' '}
                            </li>
                            <li>
                                Service Provider has sufficient skills and
                                experience to perform the Services;{' '}
                            </li>
                            <li>
                                the Services shall be performed in a
                                professional, safe and workmanlike manner in
                                accordance with all applicable general industry
                                standards;{' '}
                            </li>
                            <li>
                                Service Provider has complied and will comply
                                with all applicable laws, rules or regulations
                                regarding the performance of Services;{' '}
                            </li>
                            <li>
                                The execution, delivery and performance of this
                                Agreement will not violate the provisions of any
                                agreement to which it is a party or by which it
                                is bound;
                            </li>
                            <li>
                                Provider will use Client’s name and address in
                                all places to create accounts online and in all
                                references with third parties with proper
                                approval from the Client.
                            </li>
                        </ul>

                        <h3>
                            6. Relationship of the Parties; Withholding and
                            other Deductions
                        </h3>
                        <p>
                            Service Provider acknowledges and agrees that the
                            relationship between Client and Service Provider
                            intended to be created by this Agreement is
                            non-exclusive and is that of client and independent
                            contractor, and nothing herein contained shall be
                            construed as creating a relationship of employer and
                            employee, principal and agent, joint venture or
                            partnership between them. Service Provider shall
                            neither act nor make any representation that it is
                            authorized to act as an employee, agent or officer
                            of Client. Service Provider and its employees shall
                            not be entitled to any of Client benefits, including
                            without limitation:
                        </p>
                        <ul className="lower-alpha">
                            <li>income tax withholding; </li>
                            <li>401(k) or other retirement benefits; or</li>
                            <li>
                                employee stock purchase or stock option plans.
                            </li>
                        </ul>
                        <p>
                            Service Provider shall be solely responsible for the
                            withholding and payment of all taxes and insurance
                            premiums owed by its employees, including workers’
                            compensation insurance. Service Provider
                            acknowledges and agrees that it is responsible for
                            paying all taxes related to the compensation payable
                            to it hereunder and that Client will not withhold
                            any monies for payments which Service Provider is
                            required to make pursuant to any applicable law,
                            governmental regulation, rule or order. Service
                            Provider agrees to indemnify and hold harmless
                            Client from and against any and all claims,
                            judgments, losses, damages (including special and
                            consequential damages), costs and expenses,
                            including actual attorneys' fees and costs, imposed
                            upon or incurred by Client resulting or arising out
                            of any failure of Service Provider to pay any such
                            taxes when due.
                        </p>

                        <h3>7. Confidentiality</h3>
                        <ul>
                            <li>
                                During and after the term of this Agreement,
                                Service Providers shall keep all Confidential
                                Information confidential and shall only share
                                such with Representatives that need to know the
                                information for purposes of completing the
                                Services. Service Provider shall cause each of
                                its Representatives to
                            </li>
                            <ul>
                                <li>
                                    keep the Confidential Information
                                    confidential and;{' '}
                                </li>
                                <li>
                                    not to disclose the Confidential Information
                                    to any person except with the prior written
                                    consent of Client or use any of the
                                    Confidential Information in any way
                                    detrimental to Client (it being understood
                                    that any use is detrimental outside of the
                                    use necessary for Service Provider to
                                    fulfill its obligations hereunder).
                                </li>
                            </ul>
                            <li>
                                Service Provider may disclose Confidential
                                Information in response to a lawfully issued
                                subpoena or other court order, with the
                                understanding that Service Provider shall,
                                immediately upon receipt of such a request or
                                court order, give written notice to Client and
                                consult with and assist Client (at Client’s
                                expense) in efforts to seek to obtain an
                                injunction or other relief Client deems
                                appropriate to prevent disclosure and/or a
                                protective order or other assurance Client deems
                                reliable that confidential treatment will be
                                accorded to any Confidential Information for
                                which disclosure is sought by any third party.
                                For purposes of this Agreement:
                            </li>
                            <ul>
                                <li>
                                    “Confidential Information” shall mean the
                                    terms of this Agreement and all information
                                    relating in any manner to Client or its
                                    business (including, but not limited to,
                                    financial statements, budgets and
                                    projections, customer identities, potential
                                    customers, employees, suppliers, servicing
                                    methods, equipment, programs, strategies,
                                    analyses, profit margins and any other
                                    proprietary information) however documented
                                    and however coming into the possession of
                                    Provider, or that may later be provided or
                                    shown to Service Provider or any of its
                                    representatives; and
                                </li>
                                <li>
                                    “Representatives” means, with respect to the
                                    Provider, any of its directors, officers,
                                    employees, agents, contractors, advisors, or
                                    other representatives. Regardless as to any
                                    other provision in this Agreement, Client ‘s
                                    failure to identify information as
                                    “Confidential Information” shall not be
                                    deemed an acknowledgement or admission by
                                    Client that the information is not
                                    confidential or a waiver by Client of any of
                                    its rights with respect to the information.
                                </li>
                            </ul>
                            <li>
                                Definition: “Confidential Information” means any
                                proprietary information, technical data, trade
                                secrets or know-how, including, but not limited
                                to, research, product plans, products, services,
                                customers, customer lists, markets, software,
                                developments, inventions, processes, formulas,
                                technology, designs, drawings, engineering,
                                hardware configuration information, marketing,
                                finances or other commercial information
                                disclosed by the Client or the Service Provider
                                either directly or indirectly in writing, orally
                                or by drawings or inspection of parts or
                                equipment.
                            </li>
                            <li>
                                Non-Use and Non-Disclosure: Both the Client and
                                Service Provider agree not to use, during or
                                subsequent to the term of this Agreement,
                                confidential information of each other for any
                                purpose whatsoever other than that required in
                                the performance of the services agreed between
                                the two parties. They also agree not to disclose
                                each other’s confidential information to any
                                third party. The Client and the Service Provider
                                further agree to take all reasonable precautions
                                to prevent any unauthorized disclosure of such
                                confidential information.
                            </li>
                            <li>
                                Third Party Confidential Information: Both the
                                Client and the Service Provider may, during the
                                period of contract, receive confidential or
                                proprietary information from third parties. The
                                Client and the Service Provider agree to hold
                                all such confidential or proprietary information
                                in the strictest confidence and not to disclose
                                it to any person, firm or corporation or to use
                                it except as necessary in carrying out the
                                services covered by this agreement.
                            </li>
                            <li>
                                Return of Materials: Upon the termination of
                                this Agreement, both the Client and the Service
                                Provider agree to return any property or
                                confidential information belonging to the other
                                party that may be in their possession or under
                                their control.
                            </li>
                        </ul>

                        <h3>8. Non-Solicitation and Non-Hire</h3>
                        <p>
                            During the period from the date hereof and after 2
                            years of the termination of this agreement, either
                            party shall not, without the prior written consent
                            of the other party, directly or indirectly, solicit,
                            hire or cause to be solicited or hired by an party
                            with which it may ultimately become associated, any
                            employee of the Company or any Consultant introduced
                            by either party.
                        </p>

                        <h3>9. Force Majeure</h3>
                        <p>
                            Any delay or failure of a Party hereto to perform
                            its obligations hereunder will be excused if and to
                            the extent that it was caused by an event or
                            occurrence beyond such Party’s reasonable control
                            and without its fault or negligence (“Force
                            Majeure”). Force Majeure includes, but is not
                            limited to, acts of God, actions by any government
                            authority (whether valid or invalid), fires, floods,
                            windstorms, explosions, riots, natural disasters,
                            wars, sabotage, acts of terrorism, pendamic or court
                            injunction or order. A Party claiming Force Majeure
                            must provide the other Party with written notice of
                            such delay (including the anticipated duration of
                            the delay) within ten days of the occurrence of
                            Force Majeure. If the delay lasts more than 30 days,
                            or if the Party claiming Force Majeure does not
                            provide adequate assurances to the other Party that
                            the delay will cease within 30 days, such other
                            Party may terminate this Agreement upon written
                            notice to the Party claiming Force Majeure.
                        </p>

                        <h3>10. Miscellaneous</h3>
                        <p>
                            Waiver of any breach or failure to enforce any term
                            of this Agreement will not be deemed a waiver of any
                            breach or right to enforce which may thereafter
                            occur. No waiver may be valid against any party
                            hereto unless made in writing and signed by the
                            party against whom enforcement of such waiver is
                            sought and then only to the extent expressly
                            specified therein. If any term or provision of this
                            Agreement is found by a court of competent
                            jurisdiction to be illegal, invalid, or otherwise
                            unenforceable, such term or provision shall not
                            affect the Agreement’s other terms or provisions, or
                            the whole of this Agreement, but such term or
                            provision shall be deemed modified to the extent
                            necessary in the court’s opinion to render such term
                            or provision enforceable, and the rights and
                            obligations of the parties shall be construed and
                            enforced accordingly, preserving to the fullest
                            permissible extent the intent and the agreements of
                            the parties.
                        </p>
                        <p>
                            This Agreement constitutes the entire understanding
                            between Service Provider and Client, and supersedes
                            all prior agreements, arrangements, representations
                            and communications (whether oral or written)
                            regarding the subject matter contained herein. This
                            Agreement may be executed and delivered, including
                            by facsimile, in one or more counterparts, each of
                            which shall be deemed an original, but all of which,
                            together, shall constitute one and the same
                            instrument. This Agreement does not create, and
                            shall not be construed as creating, any rights
                            enforceable by any person not a party to this
                            Agreement. The parties agree to execute and deliver
                            such additional documents or instruments as may be
                            necessary or appropriate to carry out the terms of
                            this Agreement.
                        </p>

                        <h3>11. Dispute Resolution</h3>
                        <p>
                            In the event that any dispute arises between the
                            Parties in connection with this Contract, the
                            construction of any provision of this Contract or
                            the rights, duties or liabilities of the Parties
                            hereto under this Contract, the Parties shall
                            conduct negotiations in good faith to solve such
                            dispute. If mutual resolution cannot be reached
                            within fifteen (15) days after the commencement of
                            such negotiations, either of the Parties shall be
                            free to refer such dispute to arbitration under the
                            Arbitration and Conciliation Act, 1996 by three (3)
                            arbitrators. One arbitrator shall be appointed by
                            each Party and the third arbitrator shall be
                            appointed by the arbitrators so appointed. The
                            arbitration proceedings shall be conducted in
                            English. Any award made in such arbitration will be
                            final and binding on the Parties. During the
                            pendency of any arbitration proceedings, performance
                            of the project and all other obligations under this
                            Contract by the service Provider shall continue
                            uninterrupted.
                        </p>

                        <h3>12. Governing Law</h3>
                        <p>
                            This Contract will be governed by and constructed in
                            accordance with the laws of The United States of
                            America & India. Any or all disputes arising out of
                            this Contract shall be subject to the exclusive
                            jurisdiction of the courts International Laws.
                        </p>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Msa
