import React from 'react'
import { Link } from 'react-router-dom'
import rightArrow from '../../assets/agency/img/purple-right.svg'

const ManageSubscriptionDetail = () => {
    return (
        <>
            <div className="ad-white-card p-20">
                <div className="ad-white-header pb-4">
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="ad-st-count">
                                            <div className="ad-st-title1">
                                                10
                                            </div>
                                            <div className="ad-st-title2">
                                                users
                                            </div>
                                        </div>
                                        <div className="dash-hc-info">
                                            <div className="dash-md-title dash-black-text fw-600 ad-font-1-2em">
                                                Small Team
                                            </div>
                                            <div className="dash-md-title text-wrap dash-font-0-9em mt-1">
                                                Unlimited Projects Tracking
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="ad-white-header pt-4 pb-4">
                    <div className="bs-scroll-y">
                        <div className="bs-scroll-width w-685">
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="adwh-label">
                                                Licenses
                                            </div>
                                            <div className="adwh-value mt-1">
                                                10
                                            </div>
                                        </td>
                                        <td>
                                            <div className="adwh-label">
                                                Used Licenses
                                            </div>
                                            <div className="adwh-value mt-1">
                                                6
                                            </div>
                                        </td>
                                        <td>
                                            <div className="adwh-label">
                                                Billing cycle
                                            </div>
                                            <div className="adwh-value mt-1">
                                                15 Aug 2023
                                            </div>
                                        </td>
                                        <td>
                                            <div className="adwh-label">
                                                Estimated billing
                                            </div>
                                            <div className="adwh-value mt-1">
                                                $1,500
                                            </div>
                                        </td>
                                        <td className="text-end">
                                            <Link
                                                to="/"
                                                className="ad-purple-text dash-font-0-9em"
                                            >
                                                Manage team{' '}
                                                <img
                                                    src={rightArrow}
                                                    alt="right"
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="pt-4 text-end">
                    {/* <!-- <button className="ad-theme-btn ad-sm-btn fw-600 plr-30">Upgrade Plan</button> --> */}
                    <Link
                        to="/dashboard/settings/billing-subscription/select-plan"
                        className="ad-theme-btn ad-sm-btn fw-600 plr-30"
                    >
                        Upgrade Plan
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ManageSubscriptionDetail
