import React from 'react'
import Layout from '../Layout'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useEffect } from 'react'
import AuthUser from '../../../Services/AuthUser'
import { ReactComponent as Download } from '../../../assets/dashboard/img/download-dynamic.svg'
import { ReactComponent as ViewInvoice } from '../../../assets/dashboard/img/view-invoice-dynamic.svg'
import { Box, SvgIcon, Tooltip } from '@mui/material'
import { useApiService } from '../../../Services/ApiInstance'
import { InrSeprator, UsdCadSeprator } from '../../../Config/Helper'

import moment from 'moment'

const InvoiceHistory = () => {
    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [invoiceDetail, setInvoiceDetail] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    let currentYear = new Date().getFullYear()
    let earliestYear = 2022
    const [selectedYear, setSelectedYear] = useState(currentYear.toString()) // Initialize with a default year
    const rows = []
    const { getRequest, DOWNLOAD_SUBSCRIPTION_INVOICE } = useApiService()
    let user = JSON.parse(localStorage.getItem('user'))

    for (let i = earliestYear; i <= currentYear; i++) {
        rows.push(<option key={i}>{i}</option>)
    }
    const handleYearClick = (e) => {
        setSelectedYear(e.target.value)
    }
    const getInvoiceHistory = () => {
        setIsLoading(true)
        http2.get(`/get-invoice-history`).then((res) => {
            let result = res.data.data
            setIsLoading(false)
            setInvoiceDetail(result.invoice_details)
        })
    }

    // const downloadInvoice = (invoice) => {
    //     getRequest(`${DOWNLOAD_SUBSCRIPTION_INVOICE}${invoice}`,{responseType: 'blob'}).then((response)=>{
    //         window.open(URL.createObjectURL(response.data));
    //       })
    // };

    const downloadInvoice = (invoice) => {
        getRequest(`${DOWNLOAD_SUBSCRIPTION_INVOICE}${invoice}`, {
            responseType: 'blob',
        }).then((response) => {
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: response.data })

            // Create a link element
            const link = document.createElement('a')

            // Create a URL for the Blob and set it as the href attribute of the link
            const url = URL.createObjectURL(blob)
            link.href = url

            // Set the download attribute with the desired file name
            link.download = `${invoice}.pdf` // Assuming the file is a PDF. Change the extension if needed

            // Append the link to the DOM
            document.body.appendChild(link)

            // Programmatically click the link to trigger the download
            link.click()

            // Remove the link from the DOM
            document.body.removeChild(link)

            // Revoke the object URL to free up memory
            URL.revokeObjectURL(url)
        })
    }

    useEffect(() => {
        getInvoiceHistory()
        setReload(false) // Reset the reload state
    }, [reload])
    // const filterInvoices = () => {
    //     if (!invoiceDetail) return [];
    //     return invoiceDetail.filter((item) => {
    //         // Filter by order ID (search input)
    //         if (searchQuery && item.invoice_number) {
    //             return item.invoice_number.toLowerCase().includes(searchQuery.toLowerCase());
    //         }
    //         // Filter by selected year
    //         if (selectedYear && item.created_at) {
    //             const itemYear = new Date(item.created_at).getFullYear();
    //             return itemYear.toString() === selectedYear;
    //         }
    //         // If no filters are applied, include all items
    //         return true;
    //     });
    // };
    const filterInvoices = () => {
        if (!invoiceDetail) return []

        return invoiceDetail.filter((item) => {
            // Filter by order ID (search input)
            const matchesSearch =
                !searchQuery ||
                (item.invoice_number &&
                    item.invoice_number
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()))

            // Filter by selected year
            const matchesYear =
                !selectedYear ||
                (item.created_at &&
                    new Date(item.created_at).getFullYear().toString() ===
                        selectedYear)

            // Include item if both conditions are met
            return matchesSearch && matchesYear
        })
    }

    var a = [
        '',
        'one ',
        'two ',
        'three ',
        'four ',
        'five ',
        'six ',
        'seven ',
        'eight ',
        'nine ',
        'ten ',
        'eleven ',
        'twelve ',
        'thirteen ',
        'fourteen ',
        'fifteen ',
        'sixteen ',
        'seventeen ',
        'eighteen ',
        'nineteen ',
    ]
    var b = [
        '',
        '',
        'twenty',
        'thirty',
        'forty',
        'fifty',
        'sixty',
        'seventy',
        'eighty',
        'ninety',
    ]

    function inWords(num) {
        if ((num = num.toString()).length > 9) return 'overflow'
        let n = ('000000000' + num)
            .substr(-9)
            .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)
        if (!n) return
        var str = ''
        str +=
            n[1] != 0
                ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
                : ''
        str +=
            n[2] != 0
                ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
                : ''
        str +=
            n[3] != 0
                ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) +
                  'thousand '
                : ''
        str +=
            n[4] != 0
                ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) +
                  'hundred '
                : ''
        str +=
            n[5] != 0
                ? (str != '' ? 'and ' : '') +
                  (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
                  'only '
                : ''
        return str
    }

    const viewInvoice = (billData) => {
        const ammountInWords = inWords(
            Math.round(
                billData.estimated_billing + billData.estimated_billing * 0.18
            )
        )
        let finalObj = {
            ...billData,
            estimated_billing_in_words:
                ammountInWords.search('only') == -1
                    ? ammountInWords + ' only'
                    : ammountInWords,
            gst_amount: (billData.estimated_billing * 0.18).toFixed(2),
            totalAmount: Math.round(
                billData.estimated_billing + billData.estimated_billing * 0.18
            ),
            customer_name: user.company.company_name,
            address: `${user.company?.authority_company_address_line1 || '-'}${
                user?.company?.authority_company_address_line2
                    ? ' , ' + user.company.authority_company_address_line2
                    : ''
            }`,
            state: user.company?.state?.name || '-',
            country: user.company?.country?.name || '-',
            gst_no: user.company?.gst_no || '-',
        }
        console.log(
            Math.round(
                billData.estimated_billing + billData.estimated_billing * 0.18
            )
        )
        var encoded = btoa(JSON.stringify(finalObj))

        window.open(
            `/dashboard/billings/project-billing/view-subscription-billing?data=${encoded}`,
            '_blank',
            'noreferrer'
        )
    }

    return (
        <>
            {/* {console.log(invoiceDetail)} */}
            <Layout>
                <div className="d-flex align-items-center justify-content-between ih-header mb-3">
                    <div>
                        <div>
                            <Link
                                to="/dashboard/settings/billing-subscription"
                                className="ih-back-btn"
                            >
                                <span className="ih-back-arrow"></span> Back
                            </Link>
                        </div>
                        <div className="ad-md-title mt-2">Invoice History</div>
                    </div>
                    <div>
                        <form className="custom-form ad-custom-form">
                            <div className="d-flex align-items-center">
                                <div className="me-3 position-relative">
                                    <input
                                        className="form-control w-263px ps-42"
                                        type="text"
                                        placeholder="Enter order ID"
                                        value={searchQuery}
                                        onChange={(e) =>
                                            setSearchQuery(e.target.value)
                                        }
                                    />
                                    <span className="ih-search-icon"></span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="ih-label me-2">Year:</div>
                                    <select
                                        className="form-control w-116px"
                                        value={selectedYear}
                                        onChange={handleYearClick}
                                    >
                                        {rows}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="ad-white-card p-20">
                    <div className="ad-custom-table invoice-history-table">
                        <div className="bs-scroll-y">
                            <div className="bs-scroll-width">
                                {isLoading ? (
                                    <Skeleton
                                        containerClassName="cs-skeleton"
                                        count={6}
                                        height={60}
                                    />
                                ) : (
                                    <table className="w-100">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '30%' }}>
                                                    #Order ID
                                                </th>
                                                <th style={{ width: '30%' }}>
                                                    Billing date
                                                </th>
                                                <th style={{ width: '30%' }}>
                                                    Expiry date
                                                </th>
                                                <th style={{ width: '20%' }}>
                                                    Amount
                                                </th>
                                                <th
                                                    style={{ width: '20%' }}
                                                    className="text-end"
                                                >
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filterInvoices().length > 0 ? (
                                                filterInvoices().map((item) => (
                                                    <tr key={item.id}>
                                                        <td className="fw-600 ad-purple-text">
                                                            {item.invoice_number ||
                                                                '-'}
                                                        </td>
                                                        <td>
                                                            {moment(
                                                                item.created_at
                                                            ).format(
                                                                'DD MMM YYYY'
                                                            ) || '-'}
                                                        </td>
                                                        <td>
                                                            {moment(
                                                                item.end_date
                                                            ).format(
                                                                'DD MMM YYYY'
                                                            ) || '-'}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.currency_symbol
                                                            }
                                                            {/* {item.estimated_billing? 
                                                    Math.round(item.estimated_billing + (item.estimated_billing*0.18)) : "-"} */}
                                                            {item.estimated_billing &&
                                                            item.currency_symbol ===
                                                                '₹'
                                                                ? InrSeprator(
                                                                      Math.round(
                                                                          item.estimated_billing +
                                                                              item.estimated_billing *
                                                                                  0.18 ||
                                                                              '-'
                                                                      )
                                                                  )
                                                                : UsdCadSeprator(
                                                                      Math.round(
                                                                          item.estimated_billing +
                                                                              item.estimated_billing *
                                                                                  0.18 ||
                                                                              '-'
                                                                      )
                                                                  )}
                                                        </td>
                                                        <td className="text-end">
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Tooltip title="View Invoice">
                                                                    <SvgIcon
                                                                        sx={{
                                                                            color: 'text.text2',
                                                                            mr: '8px',
                                                                        }}
                                                                        component={
                                                                            ViewInvoice
                                                                        }
                                                                        onClick={() => {
                                                                            viewInvoice(
                                                                                {
                                                                                    invoice_number:
                                                                                        item.invoice_number,
                                                                                    created_at:
                                                                                        item.created_at,
                                                                                    estimated_billing:
                                                                                        item.estimated_billing,
                                                                                    plan_name:
                                                                                        item.plan_name,
                                                                                }
                                                                            )
                                                                        }}
                                                                        inheritViewBox
                                                                    />
                                                                </Tooltip>
                                                                <Tooltip title="Download">
                                                                    <SvgIcon
                                                                        sx={{
                                                                            color: 'text.text2',
                                                                        }}
                                                                        component={
                                                                            Download
                                                                        }
                                                                        onClick={() => {
                                                                            downloadInvoice(
                                                                                item.invoice_number
                                                                            )
                                                                        }}
                                                                        inheritViewBox
                                                                    />
                                                                </Tooltip>
                                                            </Box>
                                                            {/* <button type='button' className="employment-type-tag ad-download-btn">Download</button> */}
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan={4}
                                                        className="text-center pt-5"
                                                    >
                                                        No Invoice available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default InvoiceHistory
