import React, { useEffect, useRef } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useState, useLayoutEffect } from 'react'
import AuthUser from '../../../Services/AuthUser'
import Layout from '../Layout'
import imgEdit from '../../../assets/dashboard/img/draw.png'
import PasswordField from '../../../Components/Form/PasswordField'
import * as yup from 'yup'
import { toast, ToastContainer } from 'react-toastify'
import PreviewImage from '../../../Components/Form/PreviewImage'
import { utilities } from '../../../Config/Helper'
import SelectField from '../../../Components/Form/SelectField'
import SelectCountry from '../../../Components/Form/SelectCountry'
import SelectState from '../../../Components/Form/SelectState'
import SelectCity from '../../../Components/Form/SelectCity'
import Skeleton from 'react-loading-skeleton'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import axios from 'axios'
import ValidationError from '../../../Components/Errors/ValidationError'
import ImgCropper from './Cropper'
import apiCall, { useApiService } from '../../../Services/ApiInstance'
import Avatar from '@mui/material/Avatar'
import AvatarWithUpload from '../../../Components/Form/AvatarWIthUpload'
import Stack from '@mui/material/Stack'
import { Box, Typography, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
    setAvatarLogoUrl,
    setCompanyLogoUrl,
} from '../../../Redux/SliceReducers/CommonData'
import AddOnBenchResourcesForm from '../../../Components/Overview/AddOnBenchResourcesForm'

let base_URL = process.env.REACT_APP_base_URL

const validationSchemaProfile = yup.object({
    first_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'first name must be at least 2 characters')
        .max(30, 'first name must not be greater than 30 characters')
        .required('First name field is required'),
    last_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'last name must be at least 2 characters')
        .max(30, 'last name must not be greater than 30 characters')
        .required('Last name field is required'),
    company_name: yup
        .string()
        .min(2, 'Company name must be at least 2 characters')
        .max(50, 'Company name must not be greater than 50 characters'),
    //.required("Company name field is required.")
    company_role: yup.string().required('Current role is required.'),
    //currency: yup.string().required("Currrency is required."),
    website: yup
        .string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            ' website must be a valid URL.'
        ),
    //.required("Company website field is required.")
    other_company_role: yup.string().when('company_role', {
        is: (company_role) => company_role === 'Other',
        then: yup.string(),
        // .required("Other current role is required.")
    }),
    authority_first_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'first name must be at least 2 characters')
        .max(30, 'first name must not be greater than 30 characters'),
    authority_last_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'last name must be at least 2 characters')
        .max(30, 'last name must not be greater than 30 characters'),
    authority_company_name: yup
        .string()
        .min(2, 'Company name must be at least 2 characters')
        .max(50, 'Company name must not be greater than 50 characters'),
    authority_company_address_line1: yup
        .string()
        .required('Address is required'),
    authority_company_country: yup.string().required('Country is required.'),
    authority_company_state: yup.string().required('State is required.'),
    authority_company_city: yup.string().required('City is required.'),
    gst_no: yup
        .string()
        .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
            'Please enter a valid GST No.'
        ),
    //.required("GST No. field is required"),
    lut_no: yup.string(),
    beneficiary_name: yup.string().required('This field is required!'),
    beneficiary_bank_name: yup.string().required('This field is required!'),
    beneficiary_account: yup
        .number()
        .typeError('Value must be a positive integer')
        .positive('Value must be a positive integer')
        .integer('Value must be a positive integer')
        .required('This field is required!'),
    beneficiary_bank_id: yup.string().required('This field is required!'),
    beneficiary_ifsc_code: yup.string().required('This field is required!'),
    authority_company_zip_code: yup
        .string()
        .matches(/^[A-Z0-9]+$/, 'Only letters and numbers are allowed')
        .test(
            'no-leading-zero',
            'Zip code should not start with zero',
            (value) => value === undefined || value === '' || value[0] !== '0'
        )
        .test(
            'formatted-length',
            'Zip code should be at most 7 characters including space',
            (value) => value === undefined || value === '' || value.length <= 7
        )
        .required('Zip code is required'),
})

const Profile = () => {
    const { http, token } = AuthUser()
    let user = JSON.parse(localStorage.getItem('user'))
    const theme = useTheme()

    const [isLoading, setIsLoading] = useState(false)
    const [isPasswordLoading, setIsPasswordLoading] = useState(false)
    const [isProfileLoading, setIsProfileLoading] = useState(false)
    const [currentEdit, setCurrentEdit] = useState('')
    const [companyFile, setCompanyFile] = useState(null)
    const [avatarFile, setAvatarFile] = useState(null)
    const [zipCode, setZipCode] = useState('')
    const [error, setError] = useState('')

    //set country
    const [countries, setCountries] = useState([])

    const [profileData, setProfileData] = useState({})
    const [role, setRole] = useState([])
    const [currency, setCurrency] = useState([])
    const {
        first_name,
        last_name,
        email,
        company,
        designation,
        avatar,
        is_socail_login,
        user_role,
        permissions,
        company_logo_with_url,
        zip_code,
    } = profileData
    const [avatarState, setAvatarState] = useState({
        file: null,
        url: null,
    })
    const [companyState, setCompanyState] = useState({
        file: null,
        url: null,
    })

    console.log(company, 'Company!!!!!!lll.........................')
    const dispatch = useDispatch()
    const { postRequest, STORE_PROFILE_AVATAR_COMPANY_LOGO_V1 } =
        useApiService()

    const getProfile = () => {
        setIsLoading(true)
        http.get(`/me`).then((res) => {
            let result = res.data.data
            setProfileData(result)
            setAvatarState({ ...avatarState, url: result.avatar })
            setCompanyState({
                ...companyState,
                url: result.company_logo_with_url,
            })
            setIsLoading(false)
        })
    }

    let fLetter = first_name && first_name.charAt(0).toUpperCase()
    let lLetter = last_name && last_name.charAt(0).toUpperCase()
    let shortName = fLetter + lLetter || ''

    //console.table(company?.authority_company_country)
    // const change = (event) => {
    //   setProfileData(event.target.value)
    // }

    // Password Field
    const validationSchema = yup.object({
        current_password: yup
            .string()
            .required('Current password field is required'),
        password: yup
            .string()
            .required('Password field is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'Password must be more than 8 characters long, alphanumeric and should contain at-least 1 Uppercase, 1 Lowercase and Special character.'
            ),
        confirm_password: yup
            .string()
            .required('Confirm password field is required')
            .oneOf(
                [yup.ref('password'), null],
                "The confirm password doesn't match the new password"
            ),
    })
    const changePassword = (fields) => {
        try {
            setIsPasswordLoading(true)
            http.post(`/change-password`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.status) toast.success(result.message)
                    if (result.status == false) {
                        toast.error(result.message)
                    }
                    setIsPasswordLoading(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status) {
                        toast.error(err.message)
                        setIsPasswordLoading(false)
                    }
                })
        } catch (error) {}
    }
    const upadtedProfileValues = (fields) => {
        fields['avatar'] = avatarState.file
        fields['company_logo'] = companyState.file
        //console.log(fields);

        let data = new FormData()
        Object.keys(fields).forEach((key) => {
            data.append(key, fields[key])
        })
        //console.log(fields)
        try {
            setIsProfileLoading(true)
            dispatch(setCompanyLogoUrl(companyState.url))
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v1/profile-update`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.status) toast.success(result.message)
                    if (result.status == false) {
                        toast.error(result.message)
                    }
                    localStorage.setItem('user', JSON.stringify(result.data))

                    setIsProfileLoading(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    console.log(error)
                    if (error.response.status) {
                        toast.error(err.message)
                        setIsProfileLoading(false)
                    }
                    setIsProfileLoading(false)
                })
        } catch (error) {
            console.log(error)
            setIsProfileLoading(false)
        }
    }

    useLayoutEffect(() => {
        getProfile()
        ;(async () => {
            let role = await utilities('role')
            setRole(role)
            let currency = await utilities('currency')
            setCurrency(currency)
            let countries = await utilities('countries')
            setCountries(countries)
        })()
    }, [])

    const handleCheckBox = (e, values, setFieldValue) => {
        if (e.target.checked) {
            setFieldValue('authority_first_name', values.first_name)
            setFieldValue('authority_last_name', values.last_name)
            setFieldValue('authority_company_name', values.company_name)
            setFieldValue('authority_business_title', values.company_role)
        } else {
            setFieldValue('authority_first_name', '')
            setFieldValue('authority_last_name', '')
            setFieldValue('authority_company_name', '')
            setFieldValue('authority_business_title', '')
        }
    }

    const hasPermission = (permission) => {
        let access =
            permissions?.length > 0 ? permissions?.includes(permission) : true
        return access
    }

    const roleData = role.map((item) => ({ key: item, value: item }))
    const currencyData = currency.map((item) => ({ key: item, value: item }))
    const [cropperModal, setCropperModal] = useState(false)
    const cropperModalOpen = () => {
        setCropperModal(true)
    }
    const cropperModalClose = () => {
        setCropperModal(false)
        // inputRef.current.value = "";
    }
    //
    const [image, setImage] = useState('')
    const [imageName, setImageName] = useState('')
    const [imgAfterCrop, setImgAfterCrop] = useState('')
    const [imgError, setImgError] = useState('')
    const [imgErrorCompany, setImgErrorCompany] = useState('')
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg)
    }
    const inputRef = useRef()
    const handleOnChange = (event, type) => {
        setCurrentEdit(type)
        const imageFile = event.target.files[0]
        if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
            ) {
                const reader = new FileReader()
                reader.readAsDataURL(event.currentTarget.files[0])
                reader.onload = function (e) {
                    onImageSelected(reader.result)
                }
            }
            setCropperModal(true)
            setImageName(event.currentTarget.files[0].name)
            setImgError('')
        } else {
            setImgError('Please select only jpeg, jpg, png and svg files')
        }
    }

    const handleOnChangeCompany = (event, type) => {
        setCurrentEdit(type)
        const imageFile = event.target.files[0]
        if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
            ) {
                const reader = new FileReader()
                reader.readAsDataURL(event.currentTarget.files[0])
                reader.onload = function (e) {
                    onImageSelected(reader.result)
                }
            }
            setCropperModal(true)
            setImageName(event.currentTarget.files[0].name)
            setImgErrorCompany('')
        } else {
            setImgErrorCompany(
                'Please select only jpeg, jpg, png and svg files'
            )
        }
    }
    // const onChooseImg = () => {
    //   inputRef.current.click();
    // };

    const onCropDone = (imgCroppedArea) => {
        const canvasEle = document.createElement('canvas')
        canvasEle.width = imgCroppedArea.width
        canvasEle.height = imgCroppedArea.height

        const context = canvasEle.getContext('2d')
        let imageObj1 = new Image()
        imageObj1.src = image
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                imgCroppedArea.x,
                imgCroppedArea.y,
                imgCroppedArea.width,
                imgCroppedArea.height,
                0,
                0,
                imgCroppedArea.width,
                imgCroppedArea.height
            )

            const dataURL = canvasEle.toDataURL('image/png')

            var file = dataURLtoFile(dataURL, imageName)

            if (currentEdit == 'company') {
                setCompanyFile(file)
                setCompanyState({ url: dataURL, file })
            } else {
                setAvatarFile(file)
                setAvatarState({ url: dataURL, file })
            }
            setImgAfterCrop(dataURL)

            let formData = new FormData()

            // formData.append("first_name", first_name);
            // formData.append("last_name", last_name);
            // formData.append("company_name", company.company_name);
            // formData.append("website", company.website);

            if (currentEdit == 'company') {
                formData.append('company_logo', file)
            } else {
                formData.append('avatar', file)
            }

            postRequest(STORE_PROFILE_AVATAR_COMPANY_LOGO_V1, formData)
                .then((res) => {
                    if (currentEdit == 'company') {
                        toast.success(res.data.message)
                        dispatch(setCompanyLogoUrl(dataURL))
                    } else {
                        toast.success(res.data.message)
                        dispatch(setAvatarLogoUrl(dataURL))
                    }
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(res.data.message)
                })
        }
        setCropperModal(false)
        // inputRef.current.value = "";
    }

    const dataURLtoFile = (dataurl, filename) => {
        // split dataUrl
        var arr = dataurl.split(','),
            // get type
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            //    convert in machine readable code
            u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    }
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <Layout>
                <div className="row">
                    {user.is_invite_client != 'true' &&
                        user.is_invite_team_member != true && (
                            <div className={`col-md-12`}>
                                <div className="dash-white-box dash-edit-profile-box">
                                    {/* <div className="dash-md-title fw-600 black-text">
                Edit Profile
              </div> */}
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Typography variant="size4SemiboldCommonBlack">
                                            Edit Profile
                                        </Typography>
                                        {user?.is_invite_client != 'true' && (
                                            <Typography variant="size4SemiboldCommonBlack">
                                                Edit Company Logo
                                            </Typography>
                                        )}
                                    </Stack>
                                    {isLoading ? (
                                        <Skeleton height={470} />
                                    ) : (
                                        <Formik
                                            validationSchema={
                                                validationSchemaProfile
                                            }
                                            initialValues={{
                                                first_name: first_name || '',
                                                last_name: last_name || '',
                                                email: email || '',
                                                company_name:
                                                    (company &&
                                                        company.company_name) ||
                                                    '',
                                                website:
                                                    (company &&
                                                        company.website) ||
                                                    '',
                                                company_role:
                                                    company?.company_role || '',
                                                designation: designation || '',
                                                other_company_role:
                                                    company?.other_company_role ||
                                                    '',
                                                authority_first_name:
                                                    company?.authority_first_name ||
                                                    '',
                                                authority_last_name:
                                                    company?.authority_last_name ||
                                                    '',
                                                authority_company_name:
                                                    company?.authority_company_name ||
                                                    '',
                                                authority_business_title:
                                                    company?.authority_business_title ||
                                                    '',
                                                authority_company_country:
                                                    company?.authority_company_country ||
                                                    '',
                                                authority_company_state:
                                                    company?.authority_company_state ||
                                                    '',
                                                authority_company_city:
                                                    company?.authority_company_city ||
                                                    '',
                                                authority_company_zip_code:
                                                    company?.authority_company_zip_code ||
                                                    '',
                                                authority_company_address_line1:
                                                    company?.authority_company_address_line1 ||
                                                    '',
                                                authority_company_address_line2:
                                                    company?.authority_company_address_line2 ||
                                                    '',
                                                gst_no: company?.gst_no || '',
                                                lut_no: company?.lut_no || '',
                                                avatar: '',
                                                beneficiary_name:
                                                    company?.beneficiary_name ||
                                                    '',
                                                beneficiary_bank_name:
                                                    company?.beneficiary_bank_name ||
                                                    '',
                                                beneficiary_account:
                                                    company?.beneficiary_account ||
                                                    '',
                                                beneficiary_bank_id:
                                                    company?.beneficiary_bank_id ||
                                                    '',
                                                beneficiary_ifsc_code:
                                                    company?.beneficiary_ifsc_code ||
                                                    '',
                                            }}
                                            onSubmit={(values) => {
                                                upadtedProfileValues(values)
                                            }}
                                        >
                                            {({
                                                values,
                                                setFieldValue,
                                                field,
                                                form,
                                            }) => (
                                                <Form
                                                    className="ad-custom-form ad-form-theme2 custom-form dash-change-bg"
                                                    autoComplete="off"
                                                    encType="multipart/form-data"
                                                >
                                                    <Stack
                                                        sx={{ mt: '1em' }}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <Box>
                                                            <AvatarWithUpload
                                                                children={
                                                                    <Box
                                                                        sx={{
                                                                            textTransform:
                                                                                'uppercase',
                                                                        }}
                                                                    >
                                                                        {(first_name
                                                                            ? first_name.split(
                                                                                  ''
                                                                              )[0]
                                                                            : '') +
                                                                            (last_name
                                                                                ? ' ' +
                                                                                  last_name.split(
                                                                                      ''
                                                                                  )[0]
                                                                                : '')}
                                                                    </Box>
                                                                }
                                                                name="avatar"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleOnChange(
                                                                        e,
                                                                        'avatar'
                                                                    )
                                                                }}
                                                                src={
                                                                    avatarState.url
                                                                }
                                                                sx={{
                                                                    // boxShadow:`0px 0px 5px 0.5px ${theme.palette.text.text2}`,
                                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                                }}
                                                            />
                                                            {imgError && (
                                                                <Box
                                                                    sx={{
                                                                        position:
                                                                            'absolute',
                                                                        top: '178px',
                                                                        marginRight:
                                                                            'auto',
                                                                        left: '184px',
                                                                        transform:
                                                                            'translateX(-50%)',
                                                                        color: '#f72828',
                                                                        fontSize:
                                                                            '14px',
                                                                        fontWeight:
                                                                            '400',
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                        lineHeight:
                                                                            '22px',
                                                                    }}
                                                                >
                                                                    {imgError}
                                                                </Box>
                                                            )}
                                                        </Box>
                                                        {user.is_invite_client !=
                                                            'true' && (
                                                            <Box>
                                                                <AvatarWithUpload
                                                                    variant="square"
                                                                    name="company"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleOnChangeCompany(
                                                                            e,
                                                                            'company'
                                                                        )
                                                                    }}
                                                                    src={
                                                                        companyState.url
                                                                    }
                                                                    isCompanyLogo
                                                                    sx={{
                                                                        boxShadow: `0px 0px 5px 0.5px ${theme.palette.text.text2}`,
                                                                        border: `1px solid ${theme.palette.primary.main}`,
                                                                    }}
                                                                />

                                                                {imgErrorCompany && (
                                                                    <Box
                                                                        sx={{
                                                                            position:
                                                                                'absolute',
                                                                            top: '178px',
                                                                            marginLeft:
                                                                                'auto',
                                                                            right: '-113px',
                                                                            transform:
                                                                                'translateX(-50%)',
                                                                            color: '#f72828',
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight:
                                                                                '400',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            lineHeight:
                                                                                '22px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            imgErrorCompany
                                                                        }
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        )}
                                                        {/* <div className="profile-circle-box d-inline-block position-relative">
                          <div className="profile-circle">
                            {avatarState.url ? <img src={avatarState.url} alt="" /> : ""}
                            {avatarState.url || values.avatar ? (
                              <PreviewImage
                                avatar={avatarState.url}
                              />
                            ) : (
                              shortName
                            )}
                          </div>
                          <div className="pf-edit-btn">
                            <img src={imgEdit} alt="edit" />
                            <input
                              type="file"
                              name="avatar"
                              accept=".jpg, .jpeg, .png, .svg"
                              ref={inputRef}
                              onChange={(event) => handleOnChange(event, "avatar")}
                              style={{ display: "none" }}
                            />
                            <button className="btn dashfile-upload-btn" type="button" onClick={onChooseImg}></button>
                          </div>
                        </div> */}
                                                    </Stack>
                                                    <ImgCropper
                                                        cropperModal={
                                                            cropperModal
                                                        }
                                                        cropperModalClose={
                                                            cropperModalClose
                                                        }
                                                        image={image}
                                                        onCropDone={onCropDone}
                                                        cropperType={
                                                            currentEdit ==
                                                            'avatar'
                                                                ? 'profileAvatar'
                                                                : 'profileCompany'
                                                        }
                                                    />
                                                    <Box
                                                        sx={{
                                                            mt:
                                                                imgError ||
                                                                imgErrorCompany
                                                                    ? 7
                                                                    : 0,
                                                        }}
                                                    >
                                                        <div className="row mt-4">
                                                            <div className="col-md-6 form-group">
                                                                <div className="position-relative">
                                                                    <label className="label-pos">
                                                                        First
                                                                        Name*
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        name="first_name"
                                                                        className="form-control text-capitalize"
                                                                    />
                                                                    <ValidationError name="first_name" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <div className="position-relative">
                                                                    <label className="label-pos">
                                                                        Last
                                                                        Name*
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        name="last_name"
                                                                        className="form-control text-capitalize"
                                                                    />
                                                                    <ValidationError name="last_name" />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 form-group">
                                                                <div className="position-relative">
                                                                    <label className="label-pos">
                                                                        Email
                                                                        Address
                                                                    </label>
                                                                    <Field
                                                                        type="text"
                                                                        name="email"
                                                                        className="form-control"
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </div>
                                                            {user_role ==
                                                            'people' ? (
                                                                <>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Designation*
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="designation"
                                                                                className="form-control"
                                                                            />
                                                                            <ValidationError name="designation" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-md-6 form-group">
                                                                        {/* <div className="position-relative"> */}
                                                                        <SelectField
                                                                            intersectingLabel
                                                                            label="Role*"
                                                                            placeholder="Select Role"
                                                                            name="company_role"
                                                                            asterisks="label-pos"
                                                                            data={
                                                                                roleData
                                                                            }
                                                                        />
                                                                        {/* </div> */}
                                                                    </div>
                                                                    {values.company_role ===
                                                                        'Other' && (
                                                                        <div className="col-md-6 form-group">
                                                                            <div className="position-relative">
                                                                                <label className="label-pos">
                                                                                    Other
                                                                                    Current
                                                                                    Role
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name="other_company_role"
                                                                                    className="form-control text-capitalize"
                                                                                />
                                                                                <ValidationError name="other_company_role" />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                            {user.is_invite_client !=
                                                                'true' && (
                                                                <>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Company
                                                                                Name
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="company_name"
                                                                                className="form-control text-capitalize"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="company_name" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Company
                                                                                Website
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="website"
                                                                                className="form-control"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="website" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                GST
                                                                                No.
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="gst_no"
                                                                                className="form-control"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="gst_no" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                LUT
                                                                                No.
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="lut_no"
                                                                                className="form-control"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="lut_no" />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}

                                                            {/* <div className="col-md-6">
                          <SelectField
                            label="Currency"
                            placeholder="Select Currency"
                            name="currency"
                            data={currencyData}
                          />
                        </div> */}
                                                        </div>
                                                    </Box>

                                                    {hasPermission(
                                                        'signing-authority'
                                                    ) &&
                                                        user.is_invite_client !=
                                                            'true' && (
                                                            <>
                                                                {/* <hr /> */}
                                                                {/* <div className="sm-title">Signing Authority Details</div>
                          {
                            (user_role == "people")

                              ?
                              ""
                              :
                              <div className="custom-checkbox mt-3">
                                <input className="styled-checkbox" type="checkbox" id="autoFillUpperdata" onChange={(e) => handleCheckBox(e, values, setFieldValue)} /> <label htmlFor="autoFillUpperdata">Same as above</label>
                              </div>
                          } */}
                                                                <div className="row mt-4">
                                                                    {/* Authority’s First Name */}
                                                                    {/* <div className="col-md-6">
                              <div className="position-relative">
                                <label className="label-pos">
                                  First Name
                                </label>
                                <Field
                                  type="text"
                                  name="authority_first_name"
                                  className="form-control text-capitalize"
                                  disabled={user_role === 'people'}
                                />
                                <ValidationError name="authority_first_name" />
                              </div>
                            </div> */}
                                                                    {/* Authority’s Company Last Name */}
                                                                    {/* <div className="col-md-6">
                              <div className="position-relative">
                                <label className="label-pos">
                                  Last Name
                                </label>
                                <Field
                                  type="text"
                                  name="authority_last_name"
                                  className="form-control text-capitalize"
                                  disabled={user_role === 'people'}
                                />
                                <ValidationError name="authority_last_name" />
                              </div>
                            </div> */}
                                                                    {/* Authority’s Company Name */}
                                                                    {/* <div className="col-md-6">
                              <div className="position-relative">
                                <label className="label-pos">
                                  Company Name
                                </label>
                                <Field
                                  type="text"
                                  name="authority_company_name"
                                  className="form-control text-capitalize"
                                  disabled={user_role === 'people'}
                                />
                                <ValidationError name="authority_company_name" />
                              </div>
                            </div> */}
                                                                    {/* Authority’s Company Sign in  Business Title */}
                                                                    {/* <div className="col-md-6">
                              <div className="position-relative">
                                <label className="label-pos">
                                  Business Title
                                </label>
                                <Field
                                  type="text"
                                  name="authority_business_title"
                                  className="form-control text-capitalize"
                                  disabled={user_role === 'people'}
                                />
                                <ValidationError name="authority_business_title" />
                              </div>
                            </div> */}
                                                                    {/* Authority’s Company Country*/}
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Country*
                                                                            </label>
                                                                            <SelectCountry
                                                                                intersectingLabel
                                                                                placeholder="Select Country"
                                                                                name="authority_company_country"
                                                                                data={
                                                                                    countries
                                                                                }
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            {/* <ValidationError name="authority_company_country" /> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* Authority’s Company State */}
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                State*
                                                                            </label>
                                                                            <SelectState
                                                                                name="authority_company_state"
                                                                                placeholder="Select state"
                                                                                component={
                                                                                    SelectState
                                                                                }
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            {/* <Field
                              name="authority_company_state"
                              className="form-control"
                              placeholder="Select state"
                              countryId={countryId}
                              component={SelectState}
                            /> */}
                                                                            <ValidationError name="authority_company_state" />
                                                                        </div>
                                                                    </div>
                                                                    {/* Authority’s Company City */}
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                City*
                                                                            </label>
                                                                            <SelectCity
                                                                                name="authority_company_city"
                                                                                placeholder="Select city"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="authority_company_city" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Zip
                                                                                Code*
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="authority_company_zip_code"
                                                                                className="form-control"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="authority_company_zip_code" />
                                                                        </div>
                                                                    </div>

                                                                    {/* Authority’s Company Address */}
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Company
                                                                                Address
                                                                                Line
                                                                                1*
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="authority_company_address_line1"
                                                                                className="form-control text-capitalize"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="authority_company_address_line1" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-group">
                                                                        <div className="position-relative">
                                                                            <label className="label-pos">
                                                                                Company
                                                                                Address
                                                                                Line
                                                                                2
                                                                            </label>
                                                                            <Field
                                                                                type="text"
                                                                                name="authority_company_address_line2"
                                                                                className="form-control text-capitalize"
                                                                                disabled={
                                                                                    user_role ===
                                                                                    'people'
                                                                                }
                                                                            />
                                                                            <ValidationError name="authority_company_address_line2" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                    {user.is_invite_client !=
                                                        'true' && (
                                                        <>
                                                            <hr />
                                                            <div className="sm-title">
                                                                Bank Details
                                                            </div>

                                                            <div className="row mt-4">
                                                                <div className="col-md-6 form-group">
                                                                    <div className="position-relative">
                                                                        <label className="label-pos">
                                                                            Beneficiary
                                                                            Name*
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="beneficiary_name"
                                                                            className="form-control text-capitalize"
                                                                        />
                                                                        <ValidationError name="beneficiary_name" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 form-group">
                                                                    <div className="position-relative">
                                                                        <label className="label-pos">
                                                                            Bank
                                                                            Name*
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="beneficiary_bank_name"
                                                                            className="form-control text-capitalize"
                                                                        />
                                                                        <ValidationError name="beneficiary_bank_name" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 form-group">
                                                                    <div className="position-relative">
                                                                        <label className="label-pos">
                                                                            Account
                                                                            Number*
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="beneficiary_account"
                                                                            className="form-control text-capitalize"
                                                                        />
                                                                        <ValidationError name="beneficiary_account" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 form-group">
                                                                    <div className="position-relative">
                                                                        <label className="label-pos">
                                                                            Bank
                                                                            ID /
                                                                            SWIFT
                                                                            Code*
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="beneficiary_bank_id"
                                                                            className="form-control text-capitalize"
                                                                        />
                                                                        <ValidationError name="beneficiary_bank_id" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 form-group">
                                                                    <div className="position-relative">
                                                                        <label className="label-pos">
                                                                            Bank
                                                                            IFSC
                                                                            Code*
                                                                        </label>
                                                                        <Field
                                                                            type="text"
                                                                            name="beneficiary_ifsc_code"
                                                                            className="form-control text-capitalize"
                                                                        />
                                                                        <ValidationError name="beneficiary_ifsc_code" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                // user?.onbench_talent_resource && user.onbench_talent_resource.length !=0 &&
                                                                <AddOnBenchResourcesForm formType="update" />
                                                            }
                                                        </>
                                                    )}
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        className="mt-3"
                                                    >
                                                        {isProfileLoading ? (
                                                            <BtnSpinner
                                                                cls="dash-theme-btn fw-400 br-20 w-20"
                                                                loader="true"
                                                            />
                                                        ) : (
                                                            <button
                                                                className="dash-theme-btn fw-400 br-20"
                                                                type="submit"
                                                            >
                                                                Save Changes
                                                            </button>
                                                        )}
                                                    </Box>
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </div>
                        )}

                    {
                        isLoading && (
                            <div className="col-md-4">
                                <div className="dash-white-box dash-edit-profile-box">
                                    <Skeleton height={470} />
                                </div>
                            </div>
                        )
                        // : !is_socail_login ? (
                        //   <div className="col-md-4">
                        //     <div className="dash-white-box dash-edit-profile-box profile-change-password">
                        //       <div className="dash-md-title fw-600 black-text">
                        //         Change password
                        //       </div>
                        //       <Formik
                        //         validationSchema={validationSchema}
                        //         initialValues={{
                        //           current_password: "",
                        //           password: "",
                        //           confirm_password: "",
                        //         }}
                        //         onSubmit={(values, { resetForm }) => {
                        //           // data send serve side
                        //           changePassword(values);
                        //           resetForm();
                        //         }}
                        //       >
                        //         <Form className="dash-custom-form dash-change-bg mt-4">
                        //           <PasswordField
                        //             label="Old password"
                        //             placeholder=""
                        //             name="current_password"
                        //           />
                        //           <PasswordField
                        //             label="New password"
                        //             placeholder=""
                        //             name="password"
                        //           />
                        //           <PasswordField
                        //             label="Confirm password"
                        //             placeholder=""
                        //             name="confirm_password"
                        //           />
                        //           <div className="mt-3 text-center">
                        //             {isPasswordLoading ? (
                        //               <BtnSpinner
                        //                 cls="dash-theme-btn fw-400 br-20 w-100"
                        //                 loader="true"
                        //               />
                        //             ) : (
                        //               <button
                        //                 className="dash-theme-btn fw-400 br-20 w-100"
                        //                 type="submit"
                        //               >
                        //                 Submit
                        //               </button>
                        //             )}
                        //           </div>
                        //         </Form>
                        //       </Formik>
                        //     </div>
                        //   </div>
                        // ) : (
                        //   ""
                        // )
                    }
                </div>
            </Layout>
        </>
    )
}

export default Profile
