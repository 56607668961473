import OwlCarousel from 'react-owl-carousel'
import portfolioImg from '../../../assets/dashboard/img/portfolio-img.png'
import projectImg1 from '../../../assets/dashboard/img/portfolio-img1.jpg'
import projectImg2 from '../../../assets/dashboard/img/portfolio-img2.jpg'
import projectImg3 from '../../../assets/dashboard/img/portfolio-img3.jpg'
import weteam_icon from '../../../assets/dashboard/img/weteam_icon.svg'
import ReadMore from '../../Pages/ReadMore'
import RenderHTML from '../../Pages/RenderHTML'
import Fancybox from './Fancybox'
import NoImage from '../../../assets/dashboard/img/no-image.svg'
import { Box, useTheme } from '@mui/material'

const PortfolioModal = ({
    portfolioModalOpen,
    reqPortfolioModalClose,
    portfolioDtl,
}) => {
    let randomLen = Math.floor(Math.random() * 10 + 1)
    const theme = useTheme()
    const options = {
        responsive: {
            0: {
                items: 2,
                margin: 10,
            },
            600: {
                items: 2,
                margin: 20,
            },
            768: {
                items: 3,
                margin: 30,
            },
            1000: {
                items: 3,
                margin: 30,
            },
        },
    }

    const bulletedText = portfolioDtl?.tasks
        ? portfolioDtl.tasks
              .split('\n')
              .map((line) => `<div>${line}</div>`)
              .join('\n')
        : ''
    //console.log(bulletedText)

    const checkJson = (val) => {
        try {
            JSON.parse(val)
        } catch (error) {
            return false
        }

        return true
    }

    const spanCount = new DOMParser()
        .parseFromString(bulletedText, 'text/html')
        .querySelectorAll('span').length
    const modifyText = spanCount > 1 ? bulletedText : portfolioDtl.tasks
    return (
        <>
            <div
                className={`modal fade dash-custom-modal portfolio-modal ${
                    portfolioModalOpen ? 'show' : ''
                } `}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={reqPortfolioModalClose}
                            ></button>
                            <div className="dash-md-title fw-700 dash-black-text dash-font-1-3em">
                                {portfolioDtl.name || ' '}
                            </div>
                            <div className="dash-sm-title dash-gray-text">
                                {portfolioDtl.headline || ' '}
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <div className="pm-main-img mb-5">
                                        {portfolioDtl?.portfolio_image ? (
                                            <Box
                                                component="img"
                                                sx={{
                                                    width: '100px',
                                                    height: '100px',
                                                    objectFit: 'contain',
                                                }}
                                                src={
                                                    portfolioDtl?.portfolio_image ||
                                                    NoImage
                                                }
                                                alt="portfolioImg"
                                            />
                                        ) : (
                                            <Box
                                                className="dash-hc-img ad-sm-hc ad-project-circle"
                                                sx={{
                                                    background:
                                                        theme.palette.common
                                                            .white,
                                                    border: `1px solid ${theme.palette.primary.main}`,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        transform: 'none',
                                                        position: 'relative',
                                                        top: 'initial',
                                                        left: 'initial',
                                                    }}
                                                    className="talent-short-name"
                                                >
                                                    {portfolioDtl?.name
                                                        ? `${portfolioDtl.name.split(' ').length > 1 ? portfolioDtl.name.split(' ')[0][0] : portfolioDtl.name.split(' ')[0][0] + portfolioDtl.name.split(' ')[0][1]}${portfolioDtl.name.split(' ').length > 1 ? ' ' + portfolioDtl.name.split(' ')[portfolioDtl.name.split(' ').length - 1][0] : ''}`
                                                        : '-'}
                                                </Box>
                                                {/* <span className="dash-hc-play-icon"></span> */}
                                            </Box>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <div className="dash-md-title dash-font-1em dash-gray-text fw-600 text-uppercase">
                                            technology
                                        </div>
                                        <div className="mt-1">
                                            <p>
                                                {portfolioDtl?.technology
                                                    ? checkJson(
                                                          portfolioDtl.technology
                                                      )
                                                        ? JSON.parse(
                                                              portfolioDtl.technology
                                                          )
                                                              .map(
                                                                  (v) => v.label
                                                              )
                                                              .join(', ')
                                                        : portfolioDtl.technology
                                                    : '-'}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="mb-4">
                    <div className="dash-md-title dash-font-1em dash-gray-text fw-600 text-uppercase">
                      Industry
                    </div>
                    <div className="mt-1">
                      <p>{portfolioDtl.industry_name || "-"}</p>
                    </div>
                  </div> */}
                                    <div className="mb-4">
                                        <div className="dash-md-title dash-font-1em dash-gray-text fw-600 text-uppercase">
                                            Team Size
                                        </div>
                                        <div className="mt-1">
                                            <p>
                                                {portfolioDtl.team_size || '-'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="mb-4">
                                        <div className="dash-md-title dash-font-1em dash-gray-text fw-600 text-uppercase">
                                            Project Description
                                        </div>
                                        <div className="mt-1 port-desc-text">
                                            <ReadMore character={530}>
                                                <p>
                                                    {portfolioDtl.description ||
                                                        '-'}
                                                </p>
                                            </ReadMore>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="dash-md-title dash-font-1em dash-gray-text fw-600 text-uppercase">
                                            Tasks
                                        </div>
                                        {/* <div className={`mt-1 port-desc-text ${spanCount > 1 ? "dash-bullet-item" : ""}`}> */}
                                        <div className="dash-list-item">
                                            {/* {portfolioDtl.roles_and_responsibilities || "-"} */}
                                            <RenderHTML
                                                html={bulletedText || '-'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                {portfolioDtl.internal_images &&
                                portfolioDtl.internal_images.length > 0 ? (
                                    <div className="dash-md-title dash-font-1em dash-gray-text fw-600 text-uppercase text-center">
                                        Project snapshots
                                    </div>
                                ) : (
                                    ''
                                )}
                                <Fancybox
                                    options={{
                                        Carousel: {
                                            infinite: false,
                                        },
                                    }}
                                >
                                    <OwlCarousel
                                        className="owl-theme projects-slider mt-10"
                                        loop={true}
                                        margin={30}
                                        items={3}
                                        nav={false}
                                        lazyLoad={true}
                                        center={true}
                                        key={`carou_${randomLen}`}
                                        {...options}
                                    >
                                        {portfolioDtl.internal_images &&
                                            portfolioDtl.internal_images.map(
                                                (it, ix) => {
                                                    return (
                                                        <div
                                                            className="item"
                                                            key={ix}
                                                        >
                                                            <div className="dash-port-img">
                                                                <a
                                                                    data-fancybox="gallery"
                                                                    href={it}
                                                                >
                                                                    <img
                                                                        src={it}
                                                                        alt={
                                                                            ix ||
                                                                            ''
                                                                        }
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )}
                                    </OwlCarousel>
                                </Fancybox>
                            </div>
                            <div className="mt-5">
                                <div className="text-center dash-md-title dash-purple-text">
                                    <img
                                        className="me-2"
                                        src={weteam_icon}
                                        alt="weteams icon"
                                    />{' '}
                                    Created with Weteams
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioModal
