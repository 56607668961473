import { useState } from 'react'
const StarRating = ({ setRating, rating }) => {
    return (
        <>
            <div className="text-center dash-rating-star">
                {[...Array(5)].map((star, index) => {
                    index += 1
                    return (
                        <span
                            key={index}
                            className={
                                index <= rating
                                    ? 'dr-star-icon star-fill'
                                    : 'dr-star-icon'
                            }
                            onClick={() => setRating(index)}
                        ></span>
                    )
                })}
            </div>
        </>
    )
}

export default StarRating
