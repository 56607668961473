import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import AddTeamMemberPortFolioForm from './AddTeamMemberPortfolioForm'
import { ReactComponent as UploadedIcon } from '../../assets/dashboard/img/uploaded_file.svg'
import { ReactComponent as DeleteIcon } from '../../assets/dashboard/img/delete_icon_2.svg'
import { useState } from 'react'
import AddTeamMemberCertificatesForm from './AddTeamMemberCertificateForm'

const CertificateListItem = ({
    certificate,
    deleteCertificate,
    editCertificate,
    index,
    editImageList,
    talentId,
}) => {
    const theme = useTheme()
    const [openForm, setOpenForm] = useState(false)

    return (
        <Box mb="15px">
            <Stack
                direction="row"
                sx={{
                    border: `1px solid ${theme.palette.teritiary.teritiary35}`,
                    borderRadius: '8px',
                    p: '16px 18px',
                    mt: '16px',
                    mb: '15px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setOpenForm(!openForm)
                }}
            >
                <SvgIcon
                    component={UploadedIcon}
                    inheritViewBox
                    sx={{
                        fill: 'none',
                        width: '32px',
                        height: '32px',
                        mr: '14px',
                    }}
                />
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flex={1}
                >
                    <Typography
                        variant="size3MediumTerTer36"
                        sx={{ lineHeight: '20px' }}
                    >
                        {certificate?.certificate_name ||
                            certificate?.name ||
                            '-'}
                    </Typography>
                    <SvgIcon
                        onClick={(e) => {
                            e.stopPropagation()
                            deleteCertificate()
                        }}
                        component={DeleteIcon}
                        inheritViewBox
                        sx={{
                            fill: 'none',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                        }}
                    />
                </Stack>
            </Stack>
            {openForm && (
                <AddTeamMemberCertificatesForm
                    isList={true}
                    index={index}
                    talentId={talentId}
                    certificate={certificate}
                    uploadCertificate={editCertificate}
                    editImageList={editImageList}
                />
            )}
        </Box>
    )
}

export default CertificateListItem
