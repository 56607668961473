import { createSlice } from '@reduxjs/toolkit'

/** Redux slice for Leads details page */
export const leadsDetailsPageSlice = createSlice({
    name: 'leadsDetailsPage',
    initialState: {
        value: {
            skillsViewLimit: null,
            skillsArray: [],
            completeSkillsArray: null,
            skillsOverflow: false,
            openSkillsModal: false,
        },
    },
    reducers: {
        setSkills: (state, action) => {
            console.log('payload', action?.payload)
            if (action?.payload) {
                state.value = {
                    ...state.value,
                    skillsViewLimit: action.payload.length,
                    skillsArray: action.payload,
                    completeSkillsArray: action.payload,
                }
            } else {
                state.value = {
                    ...state.value,
                }
            }
        },
        changeSkillsViewLimit: (state, action) => {
            state.value = {
                ...state.value,
                skillsViewLimit: action.payload,
                skillsArray: state.value.skillsArray.slice(0, action.payload),
                skillsOverflow: true,
            }
        },
        skillsModalToggle: (state, action) => {
            state.value = {
                ...state.value,
                openSkillsModal: action.payload,
            }
        },
    },
})

/** Action creators are generated for each case reducer function */
export const { changeSkillsViewLimit, skillsModalToggle, setSkills } =
    leadsDetailsPageSlice.actions

export default leadsDetailsPageSlice.reducer
