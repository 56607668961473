import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Webapps = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/WebApp.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Web Apps
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Launch Powerful, Creative Web Platforms with
                                    Next-Gen Technologies
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Weteams talent provides high-quality web app
                                development services by keeping target customers
                                in mind for startups, mid-sized companies, and
                                enterprises
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Educational
                                            </div>
                                            <p>
                                                The face of education has
                                                transformed thanks to
                                                educational Web apps. The days
                                                of reading academic texts,
                                                taking written exams, and
                                                sitting through long lectures
                                                are long gone. Students of today
                                                may observe, test, and
                                                comprehend the material they
                                                study. The utilisation of
                                                various technologies,
                                                entertaining quizzes,
                                                interactive games, and
                                                educational Web apps make
                                                learning more enjoyable. These
                                                apps assist teachers and school
                                                administrators in analysing
                                                data, identifying, and
                                                addressing areas for improvement
                                                at each student level.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Finance and Banking
                                            </div>
                                            <p>
                                                For some years prior, the
                                                general web application was
                                                primarily employed by the
                                                finance sector to boost
                                                productivity. They mostly used
                                                it for project management,
                                                producing reports, and making
                                                decisions.
                                            </p>
                                            <p>
                                                Financial technology has
                                                recently experienced growth in
                                                the vertical market of finance
                                                app development services, which
                                                has the potential to
                                                significantly boost industry
                                                profitability. Bankers are now
                                                more quickly and objectively
                                                assess a customer's
                                                creditworthiness with the use of
                                                a financial application with a
                                                sturdy design. Financial
                                                companies can improve client
                                                experience and satisfaction with
                                                reliable banking app development
                                                services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Agriculture
                                            </div>
                                            <p>
                                                Modern farmers now have access
                                                to compelling data generated by
                                                sensors thanks to the
                                                development of web applications
                                                in agriculture. Farmers are now
                                                able to automate many iterative
                                                procedures thanks to innovations
                                                in agriculture web app
                                                development, which have solved
                                                numerous problems for them. They
                                                determine the crop's health
                                                independently and send the
                                                results to the farmer, typically
                                                in a day. The farmer could
                                                compare the statistics to his
                                                field and properly address the
                                                areas that require extra care.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Webapps
