import { Field, Form, Formik } from 'formik'
import AuthUser from '../../Services/AuthUser'
import { useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Box } from '@mui/material'
import DatePicker, { CalendarContainer } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import * as yup from 'yup'
import useConvertUtcToLocalTimestamp from '../../Hooks/useDateFormating'

const validationSchema = yup.object().shape({
    client_id: yup.string(),
    is_sort: yup.string(),
    start_date: yup
        .date()
        .test('validateEndDate', 'Start date must be entered', function (item) {
            if (item || this.parent?.end_date) {
                if (!item && this.parent?.end_date) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        })
        .test(
            'validateRelativeDates',
            'Start date must be smaller than end date',
            function (item) {
                if (item && this.parent?.end_date) {
                    return moment(item).isBefore(
                        moment(this.parent.end_date).format('DD-MMM-YYYY'),
                        'day'
                    )
                } else {
                    return true
                }
            }
        ),
    // .max(yup.ref('end_date'), "Start date can't be after end date")
    end_date: yup
        .date()
        .test('validateEndtDate', 'End date must be entered', function (item) {
            if (item || this.parent?.start_date) {
                if (!item && this.parent?.start_date) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        }),

    //   .min(yup.ref('start_date'), "End date can't be before Start date")
    due_date: yup.date(),
})

const FilterInvoice = ({
    filterData,
    projects,
    clearFilter,
    isBilling,
    setisFilter,
}) => {
    const { http2 } = AuthUser()
    let user = JSON.parse(localStorage.getItem('user'))

    const formikRef = useRef()
    const dropdownRef = useRef()

    const [state, setState] = useState({
        datePickerOpen: false,
        datePickerAnchor: null,
    })

    const initialValues = {
        client_id: '',
        start_date: '',
        end_date: '',
        due_date: '',
        is_sort: '',
    }

    const getClients = (obj) => {
        let projects = obj?.projects
            ? obj.projects.map((val) => ({
                  ...val,
                  isProjectClient: 'yes',
                  client_name: val?.client_name || null,
                  _id: val?._id || null,
              }))
            : []
        let clients = obj?.clients
            ? obj.clients.map((val) => ({
                  isProjectClient: 'no',
                  _id: val?._id || null,
                  client_name: val?.company?.company_name || null,
              }))
            : []

        return projects.concat(clients)
    }

    const CalendarContainer = ({ children }) => {
        const el = document.getElementById('calendar-portal')
        return <Portal container={el}>{children}</Portal>
    }

    const clearData = () => {
        localStorage.removeItem('mydata')
    }

    function properCaseName(name) {
        if (!name) return ''

        return name
            .split(' ')
            .map((word) => {
                if (word.toLowerCase() === 'and') {
                    return 'and'
                }
                if (word.endsWith('.')) {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
            })
            .join(' ')
    }

    return (
        <>
            <div
                className="btn-group ad-custom-dropdown me-2 filter-block"
                style={{ marginLeft: 'auto' }}
            >
                <button
                    style={{ borderRadius: '5px' }}
                    type="button"
                    className="btn ad-gray-btn dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    ref={dropdownRef}
                >
                    <span className="ad-filter-icon"></span> Filters
                </button>
                <ul className="dropdown-menu dropdown-menu-end filter-box">
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm, setFieldValue }) => {
                            filterData(
                                {
                                    ...values,
                                    ...{ client_id: undefined },
                                    ...(values?.start_date && {
                                        start_date:
                                            useConvertUtcToLocalTimestamp(
                                                values.start_date
                                            ),
                                    }),
                                    ...(values?.end_date && {
                                        end_date: useConvertUtcToLocalTimestamp(
                                            values.end_date
                                        ),
                                    }),
                                    ...(values?.due_date && {
                                        due_date: useConvertUtcToLocalTimestamp(
                                            values.due_date
                                        ),
                                    }),
                                    ...(values?.client_id &&
                                        (values.client_id.includes('no')
                                            ? {
                                                  company_id:
                                                      values.client_id.split(
                                                          '-'
                                                      )[0],
                                              }
                                            : {
                                                  project_id:
                                                      values.client_id.split(
                                                          '-'
                                                      )[0],
                                              })),
                                },
                                resetForm
                            )
                            dropdownRef.current.click()
                            setisFilter(false)
                        }}
                    >
                        {({
                            values,
                            setFieldValue,
                            setErrors,
                            resetForm,
                            errors,
                            touched,
                        }) => (
                            <Form autoComplete="off">
                                <div className="custom-form ad-form-theme2">
                                    <div className="form-group">
                                        {user.is_invite_client !== 'true' && (
                                            <Field
                                                as="select"
                                                name="client_id"
                                                className="form-control"
                                            >
                                                <option value="" disabled>
                                                    Select Client
                                                </option>
                                                {isBilling ? (
                                                    <option></option>
                                                ) : (
                                                    getClients(projects).map(
                                                        (client) => (
                                                            <option
                                                                key={client._id}
                                                                value={
                                                                    client._id +
                                                                    '-' +
                                                                    client.isProjectClient
                                                                }
                                                            >
                                                                {client?.client_name
                                                                    ? properCaseName(
                                                                          client.client_name
                                                                      )
                                                                    : ''}
                                                            </option>
                                                        )
                                                    )
                                                )}
                                            </Field>
                                        )}
                                    </div>

                                    {/* Start Date Picker */}
                                    <Box
                                        className="dash-calendar-icon"
                                        sx={{ position: 'relative' }}
                                    >
                                        <Box
                                            sx={{
                                                '& .react-datepicker-wrapper': {
                                                    width: '100%',
                                                },
                                            }}
                                            className="form-group"
                                        >
                                            <DatePicker
                                                id="start_date"
                                                name="start_date"
                                                placeholderText="Start Date"
                                                className="form-control"
                                                selected={values.start_date}
                                                selectsStart
                                                dateFormat="dd-MMM-yyyy"
                                                onChange={(date) =>
                                                    setFieldValue(
                                                        'start_date',
                                                        date
                                                    )
                                                }
                                                shouldCloseOnSelect={false}
                                                popperProps={{
                                                    strategy: 'fixed',
                                                }}
                                            />
                                            {errors.start_date &&
                                                touched.start_date && (
                                                    <label className="error">
                                                        {errors.start_date}
                                                    </label>
                                                )}
                                        </Box>
                                    </Box>

                                    {/* End Date Picker */}
                                    <Box className="dash-calendar-icon">
                                        <Box
                                            sx={{
                                                '& .react-datepicker-wrapper': {
                                                    width: '100%',
                                                },
                                            }}
                                            className="form-group"
                                        >
                                            <DatePicker
                                                id="end_date"
                                                name="end_date"
                                                placeholderText="End Date"
                                                className="form-control"
                                                dateFormat="dd-MMM-yyyy"
                                                selected={values.end_date}
                                                selectsEnd
                                                onChange={(date) =>
                                                    setFieldValue(
                                                        'end_date',
                                                        date
                                                    )
                                                }
                                            />
                                            {errors.end_date &&
                                                touched.end_date && (
                                                    <label className="error">
                                                        {errors.end_date}
                                                    </label>
                                                )}
                                        </Box>
                                    </Box>

                                    {/* Due Date Picker */}
                                    <Box className="dash-calendar-icon">
                                        <Box
                                            sx={{
                                                '& .react-datepicker-wrapper': {
                                                    width: '100%',
                                                },
                                            }}
                                            className="form-group"
                                        >
                                            <DatePicker
                                                id="due_date"
                                                name="due_date"
                                                placeholderText="Due date"
                                                className="form-control"
                                                selected={values.due_date}
                                                onChange={(date) =>
                                                    setFieldValue(
                                                        'due_date',
                                                        date
                                                    )
                                                }
                                                dateFormat="dd-MMM-yyyy"
                                            />
                                        </Box>
                                    </Box>

                                    <div className="form-group">
                                        <Field
                                            as="select"
                                            name="is_sort"
                                            className="form-control"
                                            value={values.is_sort}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'is_sort',
                                                    e.target.value
                                                )
                                                // setIsSort(e.target.value);
                                            }}
                                        >
                                            <option value="">Sort</option>
                                            <option value="newest">
                                                Newest
                                            </option>
                                            <option value="oldest">
                                                Oldest
                                            </option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                        </Field>
                                    </div>

                                    <Box sx={{ display: 'flex', gap: '5px' }}>
                                        <button
                                            className="ad-theme-btn ad-sm-btn w-100"
                                            type="submit"
                                        >
                                            Apply
                                        </button>
                                        <button
                                            style={{
                                                padding: '3px 5px',
                                                fontSize: '13px',
                                                fontWeight: '500',
                                                borderRadius: '8px',
                                            }}
                                            className="btn ad-gray-btn"
                                            onClick={() => {
                                                resetForm()
                                                //  setErrors();
                                                clearFilter()
                                            }}
                                        >
                                            <span className="ad-clear-filter-icon"></span>
                                        </button>
                                    </Box>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ul>
            </div>
        </>
    )
}

export default FilterInvoice
