import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import ProjectItem from '../../../Components/Projects/ProjectItem'
import AgencyModal from '../../../Components/Modals/AgencyModal'
import AddNewProjectModal from '../../../Components/Projects/AddNewProjectModal'
import EditProjectModal from '../../../Components/Projects/EditProjectModal'
import NoDataProjects from '../../../Components/Projects/NoDataProjects'
import AuthUser from '../../../Services/AuthUser'
import Skeleton from 'react-loading-skeleton'
import { toast, ToastContainer } from 'react-toastify'
import AgencySmModal from '../../../Components/Modals/AgencySmModal'
import questionIcon from '../../../assets/agency/img/question-icon.svg'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import JiraConnectModal from './JiraConnectModal'
import AddBillingCustomerDetails from '../../../Components/Projects/AddBillingCustomerDetails'
import ViewBillingCustomerDetails from '../../../Components/Projects/EditBillingCustomerDetails'
import { Box, Tooltip } from '@mui/material'
import upgradeIcon from '../../../assets/agency/img/upgrade-icon.svg'
import { Link } from 'react-router-dom'
import ToolChoiceModal from './ToolChoiceModal'
import ToolIntegrationGuidlines from './ToolIntegrationGuidlines'
import AsanaConnectModal from './AsanaConnectModal'
import JiraStatusMap from '../../../Components/Projects/JiraStatusMap'
import FilterGeneral from '../../../Components/Myteams/FilterGeneral'

const Projects = () => {
    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [toolConnectModal, setToolConnectModal] = useState(false)
    const [toolGuidlineModal, setToolGuidlineModal] = useState(false)
    const [asanaIntegrationModal, setAsanaIntegrationModal] = useState(false)
    const [chosenTool, setChosenTool] = useState('jira')
    const [projectsItem, setProjectsItem] = useState([])
    const [projectLimit, setProjectLimit] = useState(false)
    const [projectLimitNumber, setProjectLimitNumber] = useState(0)
    const [projectLimitModal, setProjectLimitModal] = useState(false)
    const [reload, setReload] = useState(false)
    const [openJiraStatus, setOpenJiraStatus] = useState(false)
    const [currentProjectId, setCurrentProjectId] = useState('')
    const [currentToolType, setCurrentToolType] = useState('')
    let user = JSON.parse(localStorage.getItem('user'))

    const [totalProjectCount, setTotalProjectCount] = useState(0)

    // Add Project Modal
    const [modalOpen, setModalOpen] = useState(false)
    const addProjectModal = () => {
        setModalOpen(true)
    }
    // Edit Project Modal
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [editItemDtl, setEditItemDtl] = useState({})
    const editProjectModal = (editItem) => {
        setEditModalOpen(true)
        setEditItemDtl(editItem)
    }
    const editModalClose = () => {
        setEditModalOpen(false)
    }
    // Get Projects
    const getAdminUser = (params) => {
        setIsLoading(true)
        http2
            .get(`/projects${params ? '?is_sort=' + params : ''}`)
            .then((res) => {
                let result = res.data.data
                setProjectsItem(result.projects)
                setTotalProjectCount(result.projectCount)
                setProjectLimit(result.is_limit)
                setProjectLimitNumber(result?.max_projects)
                setIsLoading(false)
            })
    }
    // Delete Project Modal
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteItemDtl, setDeleteItemDtl] = useState()

    const deleteProjectModal = (deleteItem) => {
        setDeleteModalOpen(true)
        setDeleteItemDtl(deleteItem)
    }
    const closeDeleteModal = () => {
        setDeleteModalOpen(false)
    }
    const [jiraConnectModal, setJiraConnectModal] = useState(false)
    const [jiraConnectDtl, setJiraConnectDtl] = useState('')

    const openJiraConnectModal = (jiraDtl) => {
        setJiraConnectModal(true)
        setJiraConnectDtl(jiraDtl)
    }
    const sendProjectDeleteRequest = () => {
        const { id } = deleteItemDtl
        try {
            setIsDeleteLoading(true)
            http2
                .delete(`/projects/${id}`)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        var filterTemlantedItem = projectsItem.filter(
                            (curElem) => {
                                return curElem.id !== id
                            }
                        )
                        setProjectsItem(filterTemlantedItem)
                        toast.success(result.message)
                        setDeleteModalOpen(false)
                        // handleRemove(id);
                        setReload(false)
                        setIsDeleteLoading(false)
                    }
                    setIsDeleteLoading(false)
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.message)
                    }
                    setIsDeleteLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setIsDeleteLoading(false)
        }
    }
    //
    const [billingCustomerInfo, setBillingCustomerInfo] = useState(false)
    const [editBillingCustomerInfo, setEditBillingCustomerInfo] =
        useState(false)
    const openBillingCustomerInfo = () => {
        setBillingCustomerInfo(true)
    }
    const openEditBillingCustomerInfo = () => {
        setEditBillingCustomerInfo(true)
    }

    useEffect(() => {
        getAdminUser()
        setReload()
    }, [reload])
    return (
        <>
            <Layout>
                {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
                <div className="ad-dash-info-cards">
                    <Box className="d-flex align-items-center justify-content-between adts-header">
                        <div className="d-flex align-items-center ad-xxs-mb1">
                            <div className="ad-st-count w-70">
                                <div className="ad-st-title1">
                                    {totalProjectCount}
                                </div>
                            </div>
                            <div className="dash-hc-info">
                                <div className="md-title text-center fw-600 dash-black-text">
                                    Project(s)
                                </div>
                            </div>
                        </div>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    ml: 'auto',
                                    display: 'flex',
                                    alignSelf: 'stretch',
                                    alignItems: 'stretch',
                                }}
                            >
                                <FilterGeneral
                                    filterData={(values) => {
                                        console.log(values)
                                        getAdminUser(values.filter_field0)
                                    }}
                                    clearFilter={() => {
                                        getAdminUser()
                                    }}
                                    filterFields={[
                                        {
                                            type: 'dropdown',
                                            defaultOption: 'Sort',
                                            options: [
                                                {
                                                    id: 'newest',
                                                    option: 'Newest',
                                                },
                                                {
                                                    id: 'oldest',
                                                    option: 'Oldest',
                                                },
                                                {
                                                    id: 'A-Z',
                                                    option: 'A-Z',
                                                },
                                                {
                                                    id: 'Z-A',
                                                    option: 'Z-A',
                                                },
                                            ],
                                        },
                                    ]}
                                />
                            </Box>
                            {
                                user.is_invite_client != 'true' &&
                                    !user.is_invite_team_member && (
                                        // <Tooltip slotProps={{ popper: { className: "tooltipPrimMain" } }} title={projectLimit? "Your current plan supports only 10 projects. Please upgrade to a higher plan to add more clients.":""}>
                                        //     <div>
                                        <button
                                            className="ad-theme-btn ad-sm-btn"
                                            type="button"
                                            onClick={() => {
                                                if (projectLimit) {
                                                    setProjectLimitModal(true)
                                                } else {
                                                    addProjectModal()
                                                }
                                            }}
                                        >
                                            Add Project
                                        </button>
                                    )
                                //     </div>
                                // </Tooltip>
                            }
                        </Box>
                    </Box>
                    <div className="ad-team-flex mt-3">
                        <div className="ad-team-summary">
                            <div className="adts-body">
                                <div className="adp-list">
                                    <div
                                        className={`adp-list-width ${projectsItem.length > 0 ? '' : 'scroll-none'}`}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                containerClassName="cs-skeleton"
                                                count={5}
                                                height={80}
                                            />
                                        ) : projectsItem.length > 0 ? (
                                            projectsItem.map((item) => (
                                                <ProjectItem
                                                    key={item.id}
                                                    item={item}
                                                    reload={() => {
                                                        setReload(true)
                                                    }}
                                                    editProjectModal={
                                                        editProjectModal
                                                    }
                                                    deleteProjectModal={
                                                        deleteProjectModal
                                                    }
                                                    // openJiraConnectModal={openJiraConnectModal}
                                                    openJiraConnectModal={(
                                                        details
                                                    ) => {
                                                        setJiraConnectDtl(
                                                            details
                                                        )
                                                        setCurrentProjectId(
                                                            item.id
                                                        )
                                                        setToolConnectModal(
                                                            true
                                                        )
                                                    }}
                                                    openBillingCustomerInfo={
                                                        openBillingCustomerInfo
                                                    }
                                                    openEditBillingCustomerInfo={
                                                        openEditBillingCustomerInfo
                                                    }
                                                />
                                            ))
                                        ) : (
                                            <NoDataProjects
                                                addProjectModal={
                                                    addProjectModal
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <AgencyModal open={modalOpen}>
                <AddNewProjectModal
                    setModalOpen={setModalOpen}
                    setReload={setReload}
                />
            </AgencyModal>
            {editItemDtl && (
                <AgencyModal open={editModalOpen}>
                    <EditProjectModal
                        close={editModalClose}
                        editItemDtl={editItemDtl}
                        setEditModalOpen={setEditModalOpen}
                        setReload={setReload}
                    />
                </AgencyModal>
            )}
            {jiraConnectModal && (
                <AgencyModal open={jiraConnectModal}>
                    <JiraConnectModal
                        setJiraConnectModal={setJiraConnectModal}
                        setReload={setReload}
                        projectId={jiraConnectDtl}
                    />
                </AgencyModal>
            )}
            <AgencyModal open={asanaIntegrationModal}>
                <AsanaConnectModal
                    key={String(Math.random() + Math.random())}
                    close={() => {
                        setAsanaIntegrationModal(false)
                    }}
                    projectId={jiraConnectDtl}
                    connectAsanaSuccess={() => {
                        // setReload(true);
                        setAsanaIntegrationModal(false)
                        setOpenJiraStatus(true)
                    }}
                />
            </AgencyModal>
            <AgencyModal open={openJiraStatus}>
                <JiraStatusMap
                    projectPage={true}
                    key={String(Math.random() + Math.random())}
                    setModalOpen={(status) => {
                        setOpenJiraStatus(status)
                        setReload(!reload)
                    }}
                    project={currentProjectId}
                    type={currentToolType}
                    syncRequest={() => {}}
                />
            </AgencyModal>
            <AgencyModal open={toolConnectModal}>
                <ToolChoiceModal
                    key={String(Math.random() + Math.random())}
                    close={() => {
                        setToolConnectModal(false)
                    }}
                    chooseNext={(tool) => {
                        setChosenTool(tool)
                        setToolConnectModal(false)
                        if (tool == 'asana') {
                            setToolGuidlineModal(true)
                            setCurrentToolType('asana')
                        } else if (tool == 'jira') {
                            setJiraConnectModal(true)
                            setCurrentToolType('jira')
                        }
                    }}
                />
            </AgencyModal>
            <AgencyModal open={toolGuidlineModal}>
                <ToolIntegrationGuidlines
                    close={() => {
                        setToolGuidlineModal(false)
                    }}
                    chooseNext={() => {
                        setAsanaIntegrationModal(true)
                        setToolGuidlineModal(false)
                    }}
                />
            </AgencyModal>
            {deleteItemDtl && (
                <AgencySmModal open={deleteModalOpen} close={closeDeleteModal}>
                    <div className="text-center">
                        <img src={questionIcon} alt="question" />
                    </div>
                    <div className="mt-3 ad-md-title text-center">
                        Are You Sure?
                    </div>
                    <div className="mt-2 content text-center">
                        <p className="ad-lgray-text">
                            Do you really want to delete this project?
                        </p>
                    </div>
                    <div className="mt-3 text-center row">
                        <div className="col-6">
                            <button
                                type="button"
                                className="ad-gray-btn ad-sm-btn w-100"
                                onClick={() => setDeleteModalOpen(false)}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-6">
                            {isDeleteLoading ? (
                                <BtnSpinner
                                    cls="ad-theme-btn ad-sm-btn w-100"
                                    loader="true"
                                />
                            ) : (
                                <button
                                    type="button"
                                    className="ad-theme-btn ad-sm-btn w-100"
                                    onClick={sendProjectDeleteRequest}
                                >
                                    Yes
                                </button>
                            )}
                        </div>
                    </div>
                </AgencySmModal>
            )}

            <AgencyModal open={billingCustomerInfo}>
                <AddBillingCustomerDetails
                    setBillingCustomerInfo={setBillingCustomerInfo}
                />
            </AgencyModal>
            <AgencyModal open={editBillingCustomerInfo}>
                <ViewBillingCustomerDetails
                    setEditBillingCustomerInfo={setEditBillingCustomerInfo}
                />
            </AgencyModal>
            <AgencySmModal
                open={projectLimitModal}
                close={() => {
                    setProjectLimitModal(false)
                }}
            >
                <div className="text-center">
                    <img src={upgradeIcon} alt="upgrade" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Limit exceeded
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        Your current plan supports only {projectLimitNumber}{' '}
                        projects. Please upgrade to a higher plan to add more
                        projects.
                    </p>
                </div>
                <div className="mt-3 text-center row px-3">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={() => {
                                setProjectLimitModal(false)
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        <Link
                            className="ad-theme-btn ad-sm-btn w-100 d-block"
                            to="/dashboard/settings/billing-subscription/select-plan"
                        >
                            Upgrade
                        </Link>
                    </div>
                </div>
            </AgencySmModal>
        </>
    )
}

export default Projects
