import React from 'react'
import Box from '@mui/material/Box'
import UserInfo from './UserInfo'
import ProjectTable from './ProjectTable'
import { Button } from '@mui/material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    width: 598,
    height: 475,
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    p: 0,
    m: 0,
}

const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 598,
    height: 470,
}

const EmployeeLeftModal = ({ leftitem, onClose, saveChanges }) => {
    return (
        <Box sx={style}>
            <Box sx={contentStyle}>
                <UserInfo
                    key={leftitem.full_name}
                    leftitem={leftitem}
                    onClose={onClose}
                />
                <ProjectTable
                    key={leftitem.full_name}
                    leftitem={leftitem}
                    saveChanges={saveChanges}
                />
            </Box>
        </Box>
    )
}

export default EmployeeLeftModal
