import { useEffect, useState } from 'react'
import AuthUser from '../../../Services/AuthUser'
import { useNavigate } from 'react-router-dom'
import RegisterStep1 from './HireNewTalentSteps/RegisterStep1'
import RegisterStep2 from './HireNewTalentSteps/RegisterStep2'
import RegisterStep3 from './HireNewTalentSteps/RegisterStep3'
import RegisterSuccess from './HireNewTalentSteps/RegisterSuccess'
import { toast, ToastContainer } from 'react-toastify'
import LeftCard from '../../../Components/Auth/LeftCard'
import StepTabs from '../../Dashboard/HireNewTalent/StepTabs'
import RegisterViewRateCard from './HireNewTalentSteps/RegisterViewRateCard'
import Success from '../../Dashboard/HireNewTalent/StepsDetail/Success'

const RegisterTalent = () => {
    // link navidate/
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    // For Hiring Process
    // getting localstorage new hiring Talent data if user skip step
    const getlocalItems = () => {
        let hiring = localStorage.getItem('hiringTalent')
        if (hiring) {
            return JSON.parse(localStorage.getItem('hiringTalent'))
        } else {
            return []
        }
    }
    // this http is from service to use for  get user instance with token
    const { http } = AuthUser()

    const [hiringTalent, setHiringTalent] = useState(getlocalItems({}))
    // if user skip/refresh his last step again user landed on his last steps
    let { last_step } = hiringTalent
    last_step = last_step + 1

    const [step, setstep] = useState(last_step || 1)

    // it will contain previous form field data along with new field
    const onhiringProcess = (fields) => {
        setHiringTalent((prev) => ({ ...prev, ...fields }))
        storeHiringLogs(fields)
        nextStep()
    }
    // End For Hiring Process
    // setting form data in localstorage
    useEffect(() => {
        localStorage.setItem('hiringTalent', JSON.stringify(hiringTalent))
    }, [hiringTalent])

    const nextStep = () => {
        setstep(step + 1)
    }
    const skipStep = () => {
        setstep(step + 1)
    }

    const prevStep = () => {
        setstep(step - 1)
    }

    // update hiring logs
    let user = localStorage.getItem('user')
    user = JSON.parse(user) || ''
    const storeHiringLogs = (logs) => {
        let uID = logs.unique_id || hiringTalent.unique_id
        //let step = logs.unique_id || '';
        let param = {
            step: logs.last_step,
            unique_id: uID,
            email: user.email,
            subject: 'Register Hire new talent',
            request_data: logs,
        }
        try {
            http.post(`/store-logs`, param)
                .then((response) => {
                    let result = response.data
                })
                .catch((error) => {
                    const err = error.response.data
                })
        } catch (error) {
            // console.log("error");
        }
    }

    const sendHiringRequest = () => {
        try {
            setIsLoading(true) // start loading spinner
            http.post(`/hiring-request`, hiringTalent)
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        //nextStep();
                        toast.success(result.message)
                        //after submit clear localstorage hiringTalent data.
                        localStorage.removeItem('hiringTalent')
                        setIsLoading(false) // stop loading spinner
                        navigate('/dashboard/hire-new-talent/success', {
                            replace: true,
                        })
                    }
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status === 422) {
                        toast.error(err.message)
                        setIsLoading(false) // stop loading spinner
                    }
                })
        } catch (error) {
            // console.log("error");
        }
    }
    // View Rate card Show on Click
    const [commonModalShow, setCommonModalShow] = useState(false)
    const handleClose = () => setCommonModalShow(false)
    const commonHandleShow = () => {
        setCommonModalShow(true)
    }

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div className="common-form-wrap d-flex onboarding-wrap">
                <LeftCard step={step} />
                <div className="cf-right">
                    <div className="cf-position pd-0">
                        <div className="cd-padding mnh-100">
                            <div className="stepper dash-steps">
                                <StepTabs step={step} />
                                <div className="stepper-content-container">
                                    {(() => {
                                        switch (step) {
                                            case 1:
                                                return (
                                                    <>
                                                        <RegisterStep1
                                                            nextStep={nextStep}
                                                            prevStep={prevStep}
                                                            skipStep={skipStep}
                                                            hiringTalent={
                                                                hiringTalent
                                                            }
                                                            onhiringProcess={
                                                                onhiringProcess
                                                            }
                                                            commonHandleShow={
                                                                commonHandleShow
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                        />
                                                    </>
                                                )
                                            case 2:
                                                return (
                                                    <>
                                                        <RegisterStep2
                                                            nextStep={nextStep}
                                                            prevStep={prevStep}
                                                            skipStep={skipStep}
                                                            hiringTalent={
                                                                hiringTalent
                                                            }
                                                            onhiringProcess={
                                                                onhiringProcess
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                        />
                                                    </>
                                                )
                                            case 3:
                                                return (
                                                    <>
                                                        <RegisterStep3
                                                            nextStep={nextStep}
                                                            prevStep={prevStep}
                                                            hiringTalent={
                                                                hiringTalent
                                                            }
                                                            sendHiringRequest={
                                                                sendHiringRequest
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                        />
                                                    </>
                                                )
                                            default:
                                                return (
                                                    <RegisterSuccess
                                                        prevStep={prevStep}
                                                        nextStep={nextStep}
                                                    />
                                                )
                                        }
                                    })()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RegisterViewRateCard
                commonModalShow={commonModalShow}
                handleClose={handleClose}
            />
        </>
    )
}

export default RegisterTalent
