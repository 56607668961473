import React from 'react'

const StepTabs = ({ step }) => {
    const stepDtl = [
        {
            stepCount: '1',
            content: 'Role details',
        },
        {
            stepCount: '2',
            content: 'Engagement Type',
        },
        {
            stepCount: '3',
            content: 'Review',
        },
    ]
    return (
        <>
            {console.log(step)}
            <div className="steps-container">
                <div className="steps" id="dash_steps">
                    {stepDtl.map((tab, index) => (
                        <div
                            className={
                                tab.stepCount <= step ? 'step active' : 'step'
                            }
                            key={index}
                        >
                            <div className="step-circle">{tab.stepCount}</div>
                            <div className="step-label">{tab.content}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default StepTabs
