import Header from '../Common/Header'
import Footer from '../Common/Footer'

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    )
}
export default Layout
