import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogContent from '@mui/material/DialogContent'
import { Field, Formik, Form } from 'formik'
import * as yup from 'yup'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { parse, getYear } from 'date-fns'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { ReactComponent as CalenderIcon } from '../../assets/dashboard/img/calender-dynamic.svg'

const LeadsContractModal = ({ ...props }) => {
    return (
        <Dialog {...props}>
            <DialogTitle>Contract Details</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => {
                    props.onClose()
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <SvgIcon color="inherit" component={CloseIcon} />
            </IconButton>
            <DialogContent
                sx={{
                    p: '20px 0px 20px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                dividers
            >
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        address_line_1: '',
                        address_line_2: '',
                        authority_company_country: '',
                        authority_company_state: '',
                        city: '',
                        pin_code: '',
                    }}
                    onSubmit={(values, { resetForm, setFieldValue }) => {}}
                >
                    {({
                        values,
                        setFieldValue,
                        field,
                        form,
                        resetForm,
                        formik,
                    }) => (
                        <>
                            <LocalizationProvider
                                localeText={{
                                    fieldMonthPlaceholder: (params) =>
                                        params.contentType === 'letter'
                                            ? 'MMM'
                                            : 'MM',
                                }}
                                dateAdapter={AdapterDateFns}
                            >
                                <Box
                                    component="form"
                                    sx={{
                                        display: 'flex',
                                        overflow: 'auto',
                                        pb: '20px',
                                    }}
                                    autoComplete="off"
                                >
                                    <Grid wrap="nowrap" container>
                                        <Grid
                                            borderRight="1px solid"
                                            borderColor="teritiary.teritiary6"
                                            pr={{
                                                xs: 0,
                                                sm: 0,
                                                md: '10px',
                                                lg: '10px',
                                                xl: '10px',
                                            }}
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            display="flex"
                                            item
                                        >
                                            <Stack
                                                sx={{
                                                    overflowY: 'scroll',
                                                    '&::-webkit-scrollbar': {
                                                        width: '4px',
                                                        borderRadius: '2px',
                                                    },
                                                    '&::-webkit-scrollbar-track':
                                                        {
                                                            borderRadius: '2px',
                                                        },
                                                    '&::-webkit-scrollbar-thumb':
                                                        {
                                                            backgroundColor:
                                                                'text.text2',
                                                            borderRadius: '2px',
                                                        },
                                                }}
                                                spacing="10px"
                                                pr="10px"
                                            >
                                                {[1, 1, 1, 1].map(() => (
                                                    <Paper variant="customCard1">
                                                        <Grid container>
                                                            <Grid
                                                                xs={6}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                item
                                                            >
                                                                <Typography
                                                                    component="div"
                                                                    variant="size2MediumTextText2"
                                                                >
                                                                    Start Date
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    variant="size3MediumTextText5"
                                                                >
                                                                    -
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={6}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                item
                                                            >
                                                                <Typography
                                                                    component="div"
                                                                    variant="size2MediumTextText2"
                                                                >
                                                                    Contract
                                                                    Duration
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    variant="size3MediumTextText5"
                                                                >
                                                                    -
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={6}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                item
                                                            >
                                                                <Typography
                                                                    component="div"
                                                                    variant="size2MediumTextText2"
                                                                >
                                                                    End Date
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    variant="size3MediumTextText5"
                                                                >
                                                                    -
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={6}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                item
                                                            >
                                                                <Typography
                                                                    component="div"
                                                                    variant="size2MediumTextText2"
                                                                >
                                                                    Contribution
                                                                    Hours
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    variant="size3MediumTextText5"
                                                                >
                                                                    -
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={6}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                item
                                                            >
                                                                <Typography
                                                                    component="div"
                                                                    variant="size2MediumTextText2"
                                                                >
                                                                    Rate per
                                                                    Hour
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    variant="size3MediumTextText5"
                                                                >
                                                                    -
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={6}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={4}
                                                                item
                                                            >
                                                                <Typography
                                                                    component="div"
                                                                    variant="size2MediumTextText2"
                                                                >
                                                                    Communication
                                                                    Channel
                                                                </Typography>
                                                                <Typography
                                                                    component="div"
                                                                    variant="size3MediumTextText5"
                                                                >
                                                                    -
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                ))}
                                            </Stack>
                                        </Grid>

                                        <Grid
                                            pr="8px"
                                            display="flex"
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                            item
                                        >
                                            <Box
                                                sx={{
                                                    pt: '5px',
                                                    pr: '8px',
                                                    overflowY: 'scroll',
                                                    '&::-webkit-scrollbar': {
                                                        width: '4px',
                                                        borderRadius: '2px',
                                                    },
                                                    '&::-webkit-scrollbar-track':
                                                        {
                                                            borderRadius: '2px',
                                                        },
                                                    '&::-webkit-scrollbar-thumb':
                                                        {
                                                            backgroundColor:
                                                                'text.text2',
                                                            borderRadius: '2px',
                                                        },
                                                }}
                                                pl={{
                                                    xs: 0,
                                                    sm: 0,
                                                    md: '24px',
                                                    lg: '24px',
                                                    xl: '24px',
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    spacing={'20px'}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <DatePicker
                                                            disabled
                                                            className="customOutlinedTextFieldVariant"
                                                            label="Start Date picker"
                                                            format="dd-MMM-yyyy"
                                                            defaultValue={parse(
                                                                '02-Mar-2014',
                                                                'dd-MMM-yyyy',
                                                                new Date()
                                                            )}
                                                            slotProps={{
                                                                textField: {
                                                                    fullWidth: true,
                                                                    required: true,
                                                                },
                                                            }}
                                                            slots={{
                                                                openPickerIcon:
                                                                    CalenderIcon,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            disabled
                                                            className="customOutlinedTextFieldVariant"
                                                            label="Contract Duration(Months)"
                                                            defaultValue="Contract Duration(Months)"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <DatePicker
                                                            disabled
                                                            className="customOutlinedTextFieldVariant"
                                                            label="End Month"
                                                            format="MMM-yyyy"
                                                            defaultValue={parse(
                                                                'Mar-2014',
                                                                'MMM-yyyy',
                                                                new Date()
                                                            )}
                                                            views={[
                                                                'month',
                                                                'year',
                                                            ]}
                                                            slotProps={{
                                                                textField: {
                                                                    fullWidth: true,
                                                                    required: true,
                                                                },
                                                            }}
                                                            slots={{
                                                                openPickerIcon:
                                                                    CalenderIcon,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            disabled
                                                            className="customOutlinedTextFieldVariant"
                                                            label="Contribution Hours"
                                                            defaultValue="Contribution Hours"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            disabled
                                                            className="customOutlinedTextFieldVariant"
                                                            label="Rate per Hour (INR)"
                                                            defaultValue="Rate per Hour (INR)"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        xl={6}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            disabled
                                                            className="customOutlinedTextFieldVariant"
                                                            label="Communication Channel"
                                                            defaultValue="Communication Channel"
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            required
                                                            className="customOutlinedTextFieldVariant"
                                                            label="Spreadsheet Id for year 2024"
                                                            defaultValue={`Spreadsheet Id for year ${getYear(new Date())}`}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <Stack
                                                            direction="row"
                                                            spacing="10px"
                                                            justifyContent="center"
                                                        >
                                                            {/* <Button variant="customButtonOutlined1" autoFocus>
                                                            Decline
                                                        </Button>
                                                        <Button type="submit" variant='customButtonFilled1' autoFocus>
                                                            Accept
                                                        </Button> */}
                                                            {/* <Button variant="custErrBtnOutl" autoFocus>
                                                                End Contract
                                                            </Button> */}
                                                            <Button
                                                                fullWidth
                                                                type="submit"
                                                                variant="customButtonFilled1"
                                                                autoFocus
                                                            >
                                                                Renew
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* <Box
                                    pb="20px"
                                    display="flex"
                                    justifyContent="center"
                                    borderTop="1px solid"
                                    borderColor="teritiary.teritiary6">
                                    <Button variant="custErrBtnOutl" autoFocus>
                                        Decline
                                    </Button>
                                </Box> */}
                                </Box>
                                <Box
                                    pt="20px"
                                    mr="20px"
                                    display="flex"
                                    justifyContent="center"
                                    borderTop="1px solid"
                                    borderColor="teritiary.teritiary6"
                                >
                                    <Button variant="custErrBtnOutl" autoFocus>
                                        End Contract
                                    </Button>
                                </Box>
                            </LocalizationProvider>
                        </>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}

export default LeadsContractModal
