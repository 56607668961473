import { Field, Form, Formik } from 'formik'
import AuthUser from '../../Services/AuthUser'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

const FilterLead = ({
    setIsLoading,
    setReload,
    reload,
    getSearchData,
    clearFilter,
}) => {
    const { http2 } = AuthUser()
    // Get Projects
    const [projectsItem, setProjectsItem] = useState('')
    const getProjectList = () => {
        setIsLoading(true)
        http2.get(`/company/list`).then((res) => {
            let result = res.data.data
            setProjectsItem(result)
            setIsLoading(false)
        })
    }

    const [roleData, setRoleData] = useState('')
    const rolesList = () => {
        http2.get(`/roles/list`).then((res) => {
            let result = res.data.data
            setRoleData(result)
        })
    }
    useEffect(() => {
        getProjectList()
        rolesList()
        setReload()
    }, [reload])

    const initialValues = {
        company_id: '',
        stack_id: '',
        is_sort: '',
    }
    function properCaseName(name) {
        if (!name) return ''

        return name
            .split(' ')
            .map((word) => {
                if (word.toLowerCase() === 'and') {
                    return 'and'
                }
                if (word.endsWith('.')) {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
            })
            .join(' ')
    }

    return (
        <div className="btn-group ad-custom-dropdown filter-block filter-lead-block">
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                    getSearchData(values)
                }}
            >
                {({ resetForm }) => (
                    <>
                        <button
                            type="button"
                            className="btn ad-theme-btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ borderRadius: '5px' }}
                        >
                            <span className="ad-filter-icon"></span> Filters
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end filter-box">
                            <Form autoComplete="off">
                                <div className="custom-form">
                                    <div className="form-group">
                                        <Field
                                            name="company_id"
                                            as="select"
                                            className="form-control"
                                        >
                                            <option value="">Company</option>
                                            {projectsItem &&
                                                Array.isArray(projectsItem) &&
                                                projectsItem.map(
                                                    (item, index) => (
                                                        <option
                                                            key={index}
                                                            value={item.id}
                                                        >
                                                            {properCaseName(
                                                                item.company_name
                                                            )}
                                                        </option>
                                                    )
                                                )}
                                        </Field>
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            name="stack_id"
                                            as="select"
                                            className="form-control"
                                        >
                                            <option value="">Role</option>
                                            {roleData &&
                                                Array.isArray(roleData) &&
                                                roleData.map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Field>
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            name="is_sort"
                                            as="select"
                                            className="form-control"
                                        >
                                            <option value="">Sort</option>
                                            <option value="newest">
                                                Newest
                                            </option>
                                            <option value="oldest">
                                                Oldest
                                            </option>
                                            <option value="A-Z">A-Z</option>
                                            <option value="Z-A">Z-A</option>
                                        </Field>
                                    </div>
                                    <Box sx={{ display: 'flex', gap: '5px' }}>
                                        <button
                                            className="ad-theme-btn ad-sm-btn w-100"
                                            type="submit"
                                        >
                                            Apply
                                        </button>
                                        <button
                                            style={{
                                                padding: '3px 5px',
                                                fontSize: '13px',
                                                fontWeight: '500',
                                                borderRadius: '8px',
                                            }}
                                            className="btn ad-gray-btn"
                                            onClick={() => {
                                                resetForm()
                                                clearFilter()
                                            }}
                                        >
                                            <span className="ad-clear-filter-icon"></span>
                                        </button>
                                    </Box>
                                </div>
                            </Form>
                        </ul>
                    </>
                )}
            </Formik>
        </div>
    )
}

export default FilterLead
