import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Cloudcomputing = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/Cloud.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Cloud Computing
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Discover your personal cloud solution with
                                    Weteams talent
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Our talented experts will help you facilitate
                                Your Business in Navigating Cloud Complexities
                                and Accelerating Digital Transformation
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Infrastructure-as-a-Service
                                                (IaaS) and Platform-as-a-Service
                                                (PaaS)
                                            </div>
                                            <p>
                                                Under a traditional cloud
                                                computing model,
                                                infrastructure-as-a-service
                                                (IaaS) provides basic compute,
                                                network, and storage resources
                                                to customers on demand over the
                                                internet and on a pay-per-use
                                                basis. For firms looking to save
                                                money on the cost of acquiring,
                                                managing, and maintaining an IT
                                                infrastructure by using one
                                                already in place.
                                            </p>
                                            <p>
                                                Platform-as-a-Service (PaaS) is
                                                a cloud computing technology
                                                that is delivering customers
                                                with a fully equipped
                                                platform—hardware, software, and
                                                infrastructure—to develop, run,
                                                and manage applications without
                                                the expense, complexity, or
                                                inflexibility of on-premises
                                                building and maintaining it.
                                                Organizations are using PaaS for
                                                the development and deployment
                                                of web, mobile, big data, or IoT
                                                applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Hybrid cloud and multicloud
                                            </div>
                                            <p>
                                                A hybrid cloud is a computing
                                                environment that combines a
                                                company's on-premises private
                                                cloud capabilities and
                                                third-party public cloud
                                                services into a single,
                                                adaptable infrastructure for
                                                running applications and
                                                workloads.
                                            </p>
                                            <p>
                                                IBM Cloud Private, IBM
                                                SmartCloud Private, and
                                                third-party services offer a
                                                cloud offering that is as unique
                                                as it is flexible. This mix of
                                                public and private cloud
                                                resources are allowing
                                                organizations to pick the best
                                                cloud for each application or
                                                workload while moving tasks
                                                freely between the two clouds as
                                                conditions change. Technical and
                                                business goals are more
                                                successfully achieved and
                                                integration risks are reduced.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Big data analytics
                                            </div>
                                            <p>
                                                The ability to take advantage of
                                                big data analytics to extract
                                                business value from massive
                                                amounts of both structured and
                                                unstructured information is one
                                                of the features that cloud
                                                computing offers.
                                            </p>
                                            <p>
                                                Manufacturers, wholesalers, and
                                                retailers are now using consumer
                                                buying habits to target their
                                                advertising and marketing
                                                efforts on a specific
                                                demographic of people. Social
                                                networking sites are presently
                                                giving the framework for
                                                behavioral analytics, which
                                                businesses are using to draw
                                                useful knowledge.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Cloudcomputing
