import { Box, Stack, Typography, useTheme } from '@mui/material'
import RightArrow from '../../../assets/dashboard/img/right_arrow_group.svg'
import Weteams from '../../../assets/dashboard/img/weteams_vertical_text_icon.svg'
import Asana from '../../../assets/dashboard/img/asana_icon_medium.svg'
import AsanaGuidline from '../../../assets/dashboard/img/asana_guidline_icon.svg'
import { IconButton, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/agency/img/close-icon-dynamic.svg'

const ToolIntegrationGuidlines = ({ close, chooseNext }) => {
    const theme = useTheme()

    return (
        <>
            <div
                className="modal-header"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#552FBA',
                    position: 'relative',
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                }}
            >
                <h5
                    className="modal-title"
                    id="commonSMModalLabel"
                    style={{
                        flex: 1,
                        textAlign: 'center',
                        color: 'white',
                        margin: 0,
                    }}
                >
                    Connect With Asana
                </h5>
                <Box sx={{ position: 'absolute', right: 0 }}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            close()
                        }}
                        sx={{
                            color: 'black',
                            '&:hover': { backgroundColor: '#552FBA' },
                            marginBottom: '4px',
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: '10px',
                        }}
                    >
                        <SvgIcon component={CloseIcon} inheritViewBox />
                    </IconButton>
                </Box>
            </div>

            <Box>
                <Stack
                    direction="row"
                    mt="17px"
                    mb="17px"
                    justifyContent="space-evenly"
                >
                    <img src={Asana} />
                    <img src={RightArrow} />
                    <img src={Weteams} />
                </Stack>
            </Box>
            <Stack alignItems="center">
                <Typography
                    p="28px 40px 16px 40px"
                    variant="size5MediumTextText2"
                >
                    To ensure smooth integration and better task management,
                    Please make sure to :
                </Typography>
                <Stack alignItems="center" mb="29px" spacing="16px">
                    <Box
                        p="6.2px 25px"
                        border={`1px solid ${theme.palette.teritiary.teritiary6}`}
                        borderRadius="8px"
                        minWidth="438px"
                    >
                        <img src={AsanaGuidline} />
                        <Typography
                            sx={{ pl: '20px' }}
                            variant="size3SemiboldTextText5"
                        >
                            Track actual time spent on tasks.
                        </Typography>
                    </Box>
                    <Box
                        p="6.2px 25px"
                        border={`1px solid ${theme.palette.teritiary.teritiary6}`}
                        borderRadius="8px"
                        minWidth="438px"
                    >
                        <img src={AsanaGuidline} />
                        <Typography
                            sx={{ pl: '20px' }}
                            variant="size3SemiboldTextText5"
                        >
                            Set estimated time for each task.
                        </Typography>
                    </Box>
                    <Box
                        p="6.2px 25px"
                        border={`1px solid ${theme.palette.teritiary.teritiary6}`}
                        borderRadius="8px"
                        minWidth="438px"
                    >
                        <img src={AsanaGuidline} />
                        <Typography
                            sx={{ pl: '20px' }}
                            variant="size3SemiboldTextText5"
                        >
                            Specify task type (e.g., story, task, bug).
                        </Typography>
                    </Box>
                    <Box
                        p="6.2px 25px"
                        border={`1px solid ${theme.palette.teritiary.teritiary6}`}
                        borderRadius="8px"
                        minWidth="438px"
                    >
                        <img src={AsanaGuidline} />
                        <Typography
                            sx={{ pl: '20px' }}
                            variant="size3SemiboldTextText5"
                        >
                            Update status (e.g., on track, delayed).
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
            <Box sx={{ p: '24px' }}>
                <Box
                    component="button"
                    type="button"
                    className="ad-theme-btn ad-sm-btn w-100 d-block"
                    onClick={() => {
                        chooseNext()
                    }}
                >
                    Yes ! I Understand
                </Box>
            </Box>
        </>
    )
}

export default ToolIntegrationGuidlines
