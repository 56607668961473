import { Field } from 'formik'
import ValidationError from '../../Components/Errors/ValidationError'

const SelectField = ({
    label,
    name,
    data,
    placeholder,
    asterisks,
    sublabel,
    disabled,
    intersectingLabel,
    ...props
}) => {
    return (
        <>
            <div
                className={
                    intersectingLabel ? 'position-relative' : 'form-group'
                }
            >
                {label ? (
                    <label htmlFor={name} className={asterisks || ''}>
                        {label}
                    </label>
                ) : (
                    ''
                )}
                {sublabel ? (
                    <div className={props.sublableclass || ''}>{sublabel}</div>
                ) : (
                    ''
                )}
                <Field
                    name={name}
                    as="select"
                    className="form-control"
                    disabled={disabled === true ? 'disabled' : ''}
                >
                    {placeholder ? (
                        <option value="" disabled="disabled">
                            {placeholder}
                        </option>
                    ) : (
                        ''
                    )}
                    {data.map((val, key) => {
                        return (
                            <option key={key} value={val.key}>
                                {val.value}
                            </option>
                        )
                    })}
                </Field>
                <ValidationError name={name} />
            </div>
        </>
    )
}

export default SelectField
