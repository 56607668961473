import { Formik, Field, Form } from 'formik'
import ValidationError from '../Errors/ValidationError'
import SelectCountry from '../Form/SelectCountry'
import SelectState from '../Form/SelectState'
import { useLayoutEffect, useState } from 'react'
import { utilities } from '../../Config/Helper'
import AuthUser from '../../Services/AuthUser'
import { useParams } from 'react-router'
import BtnSpinner from '../Spinners/BtnSpinner'
import { toast } from 'react-toastify'
import * as yup from 'yup'
const validationSchemaTeam = yup.object({
    customer_name: yup
        .string()
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    address: yup.string().required('This field is required!'),
    authority_company_country: yup.string().required('This field is required!'),
    authority_company_state: yup.string().required('This field is required!'),
})
const AddBillingCustomerDetails = ({
    setBillingCustomerInfo,
    setReload,
    projectId,
}) => {
    // add-project-billing-details/658eab390fce138a5d0d7302
    const { http2 } = AuthUser()
    const params = useParams()
    const { id } = params
    const [isLoading, setIsLoading] = useState(false)

    const billingCustomerDetails = (fields) => {
        fields['country'] = fields.authority_company_country
        fields['state'] = fields.authority_company_state
        try {
            setIsLoading(true)
            http2
                .post(
                    `/add-project-billing-details/${projectId ? projectId : id}`,
                    fields
                )
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setIsLoading(false)
                        setBillingCustomerInfo(false)
                        setReload(true)
                    }
                    if (result.success === false) {
                        toast.error(result.data)
                        setIsLoading(false)
                        setBillingCustomerInfo(false)
                    }
                })
                .catch((error) => {
                    let res = error.response.data
                    if (res.status === false) {
                        toast.error(res.message)
                        setBillingCustomerInfo(false)
                        console.log(res.message)
                    } else {
                        toast.error('Internal Server Error')
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const initialValues = {
        customer_name: '',
        address: '',
        authority_company_country: '',
        authority_company_state: '',
        gst_no: '',
    }
    const [countries, setCountries] = useState([])
    useLayoutEffect(() => {
        ;(async () => {
            let countries = await utilities('countries')
            setCountries(countries)
        })()
    }, [])
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    // console.log(values)
                    billingCustomerDetails(values)
                    resetForm()
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title" id="commonSMModalLabel">
                                Add Billing Details
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setBillingCustomerInfo(false)
                                    resetForm()
                                }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Customer Name*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control text-capitalize"
                                                name="customer_name"
                                            />
                                            <ValidationError name="customer_name" />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Address*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control text-capitalize"
                                                name="address"
                                            />
                                            <ValidationError name="address" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="position-relative">
                                            <label className="custom-label">
                                                Country*
                                            </label>
                                            <SelectCountry
                                                placeholder="Select Country"
                                                name="authority_company_country"
                                                data={countries}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                State*
                                            </label>
                                            <SelectState
                                                name="authority_company_state"
                                                placeholder="Select state"
                                                component={SelectState}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                GST No (optional)
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control text-capitalize"
                                                name="gst_no"
                                            />
                                            <ValidationError name="gst_no" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {isLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader={true}
                                            />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="ad-theme-btn w-100"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddBillingCustomerDetails
