import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SassCurrency from '../Currency/SassCurrency'
import Currency from '../Currency/Currency'
import { Tooltip, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { ReactComponent as Cross } from '../../assets/dashboard/img/cross-clear.svg'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon-dynamic.svg'
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { Divider } from '@mui/material'
import { Formik, Field, Form } from 'formik'
import exclamation from '../../assets/agency/img/exclamation-icon.svg'
import { toast, ToastContainer } from 'react-toastify'
import AuthUser from '../../Services/AuthUser'
import { ErrorMessage } from 'formik'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    width: 598,
    height: 335,
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    p: 0,
    m: 0,
}

const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 598,
    height: 300,
}

const LeadMatchItem = ({
    item,
    handleCheckboxChange,
    updated_data,
    applyMatchTalent,
    required_skill_ids,
    hiring_id,
    matched_skills_hide,
    openReservedHoursModal,
    setReload,
    talentNetwork,
}) => {
    const {
        id,
        image,
        name,
        internal_rate,
        short_name,
        skills,
        experience_in_month,
        experience_in_year,
        role,
        rate_per_hour,
        total_reserved_hours,
        color_code,
        match_by_companies,
        talent_reserved_hours_details,
        total_project_reserved_hours,
        project_reserved_hours_details,
    } = item

    const { http2 } = AuthUser()
    const [open, setOpen] = React.useState(false)
    const [formValues, setFormValues] = React.useState({})
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [confirm, setConfirm] = React.useState(false)
    const handleConfirmOpen = () => setConfirm(true)
    const handleConfirmClose = () => setConfirm(false)

    const theme = useTheme()

    // console.log("item", item)
    const resHoursTotal = total_reserved_hours + total_project_reserved_hours
    // console.log("resHoursTotal", resHoursTotal)
    const idsSkills = skills?.map((skill) => skill.id)
    // console.log("match_by_companies",match_by_companies)
    let matchedSkills = idsSkills.length
        ? idsSkills.filter((skill) => required_skill_ids.includes(skill)).length
        : 0
    let is_matched =
        match_by_companies && match_by_companies.includes(hiring_id)
            ? true
            : false

    const unmatchTalent = () => {
        let payload = { talent_id: id }
        try {
            http2
                .post(`/unmatch-talent/${hiring_id}`, payload)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success('Talent Unmatched Successfully!')
                        setReload(true)
                    } else {
                        toast.error(response.data.data)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Something went wrong')
                        setIsLoadingMatchTalent(false)
                        toast.error(error.response.data.data)
                    }
                })
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }
    }

    const result = Array.isArray(updated_data)
        ? updated_data.find((item) => item.talent_id.includes(id))
        : undefined

    const talentNetworkCost = result ? result.talent_network_cost : null

    return (
        <>
            {/* <ToastContainer/> */}
            {/* {console.log("is_matched", is_matched)} */}
            <tr>
                <td style={{ width: '25%' }}>
                    <div
                        className="d-flex align-items-center"
                        style={{ width: 'fit-content' }}
                    >
                        {/* {is_matched ? 

                            <div className="custom-checkbox lead-team-checkbox">
                                <input className="styled-checkbox" type="checkbox" name="leadMatchItem" id={id} checked readOnly />                             
                                <label htmlFor={id} className='opacity-0'></label>
                            </div> : */}
                        {/* <div className="custom-checkbox lead-team-checkbox">
                                <input className="styled-checkbox" type="checkbox" name="leadMatchItem" id={id} onClick={()=>handleCheckboxChange(id)} disabled={is_matched}/>                             
                                <label htmlFor={id}></label>
                            </div> */}
                        {/* } */}
                        <div
                            className="dash-hc-img ad-sm-hc ad-project-circle"
                            style={{
                                background: image
                                    ? theme.palette.common.white
                                    : color_code,
                                border: `1px solid ${color_code}`,
                            }}
                        >
                            {image ? (
                                <img src={image} alt={name} />
                            ) : (
                                <span className="talent-short-name text-white">
                                    {short_name || ''}
                                </span>
                            )}
                        </div>
                        <div
                            className="dash-hc-info"
                            style={{ width: 'fit-content' }}
                        >
                            <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">
                                {name || '-'}{' '}
                            </div>
                            {/* {is_matched ? <span className="employment-type-tag Active-tag ms-3 lead-match-tag">Matched</span> : "" } */}
                            <div className="mt-1">
                                <span className="dash-com-tag dash-pink-tag d-inline-block me-2">
                                    Exp:{' '}
                                    {experience_in_year > 0
                                        ? `${experience_in_year > 0 && experience_in_month > 0 ? `${experience_in_year}+ yrs` : experience_in_year > 0 && experience_in_month === 0 ? (experience_in_year > 1 ? `${experience_in_year} years` : `${experience_in_year} year`) : ''}`
                                        : `${experience_in_month > 0 ? `${experience_in_month} month${experience_in_month > 1 ? 's' : ''}` : ''}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </td>
                <td style={{ width: 'fit-content' }}>
                    <div className="dash-md-title dash-font-0-8em text-wrap">
                        Role
                    </div>
                    <div className="common-label mt-1 text-type2">
                        {role || '-'}
                    </div>
                </td>
                <td className="text-center">
                    <span className="dash-custom-tooltip d-inline-block">
                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text">
                            <span className="lead-rate-icon"></span>
                            <Currency />
                            {talentNetworkCost
                                ? talentNetworkCost
                                : rate_per_hour}
                            /hr
                        </div>
                        <span className="dash-ct-value text-nowrap">
                            Rate/Hour
                        </span>
                    </span>
                </td>
                <td style={{ width: '15%' }} className="text-center">
                    <span className="dash-custom-tooltip d-inline-block">
                        <div
                            className="common-label fw-500 ad-font-0-9em ad-purple-text cursor-pointer"
                            onClick={() => openReservedHoursModal(item)}
                        >
                            <span className="lead-clock-icon"></span>
                            {resHoursTotal}
                        </div>
                        {/* <div className="common-label fw-500 ad-font-0-9em ad-purple-text cursor-pointer" onClick={()=>openReservedHoursModal(talent_reserved_hours_details, total_project_reserved_hours)}><span className='lead-clock-icon'></span>{total_reserved_hours}</div> */}
                        <span className="dash-ct-value text-nowrap">
                            Reserved Hours
                        </span>
                    </span>
                </td>
                {/* <td className='text-center'>
                    <span className="dash-custom-tooltip d-inline-block">
                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text"><span className='lead-clock-icon'></span>{total_project_reserved_hours}</div>
                        <span className="dash-ct-value">Project&nbsp;Reserved <br/>Hours</span>
                    </span>
                </td> */}
                {matched_skills_hide ? (
                    ''
                ) : (
                    <td style={{ width: '15%' }} className="text-center">
                        <span className="dash-custom-tooltip d-inline-block">
                            <div className="common-label fw-500 ad-font-0-9em ad-purple-text">
                                <span className="lead-matched-icon"></span>
                                {matchedSkills || '-'}
                            </div>
                            <span className="dash-ct-value text-nowrap">
                                Matched Skills
                            </span>
                        </span>
                    </td>
                )}

                <td style={{ width: '13%' }} className="text-center">
                    {!is_matched ? (
                        <Button
                            variant={'LeadMatch'}
                            sx={{
                                textTransform: 'capitalize',
                                '&.Mui-disabled': {
                                    color: 'whitesmoke',
                                    opacity: '1',
                                },
                                '&.MuiButtonBase-root': {
                                    marginTop: '17px',
                                },
                            }}
                            id={id}
                            onClick={() => {
                                handleOpen()
                                handleCheckboxChange(id)
                            }}
                        >
                            Apply
                        </Button>
                    ) : (
                        <Tooltip
                            title="Click here to revoke request"
                            slotProps={{
                                popper: { className: 'tooltipPrimMain' },
                            }}
                        >
                            <Button
                                variant={'LeadMatch2'}
                                sx={{
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': {
                                        color: 'whitesmoke',
                                        opacity: '1',
                                    },
                                    '&.MuiButtonBase-root': {
                                        marginTop: '17px',
                                    },
                                }}
                                onClick={unmatchTalent}
                            >
                                Applied
                            </Button>
                        </Tooltip>
                    )}
                    <Modal
                        open={open}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{
                            '& .MuiBackdrop-root': {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            },
                            '& .MuiTypography-root': {
                                fontSize: '16px',
                            },
                        }}
                    >
                        <Box sx={style}>
                            <Box sx={contentStyle}>
                                <Formik
                                    initialValues={{
                                        name: name || '',
                                        role: role || '',
                                        rate_per_hour: internal_rate || '',
                                        talent_network_cost: talentNetworkCost
                                            ? talentNetworkCost
                                            : rate_per_hour || '',
                                    }}
                                    onSubmit={(values) => {
                                        setFormValues(values)
                                        handleConfirmOpen()
                                    }}
                                >
                                    {({ values }) => (
                                        <Form>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#552FBA',
                                                    padding: '16px',
                                                    borderTopLeftRadius: '8px',
                                                    borderTopRightRadius: '8px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                        padding: '10px',
                                                    }}
                                                >
                                                    <Typography
                                                        id="modal-modal-title"
                                                        variant="h6"
                                                        component="h2"
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            fontSize: '18px',
                                                            fontWeight: 600,
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Match Talent
                                                    </Typography>

                                                    <Box
                                                        sx={{
                                                            position:
                                                                'absolute',
                                                            right: 0,
                                                        }}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={
                                                                handleClose
                                                            }
                                                            sx={{
                                                                color: 'black',
                                                                '&:hover': {
                                                                    backgroundColor:
                                                                        '#552FBA',
                                                                },
                                                                marginBottom:
                                                                    '10px',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                            }}
                                                        >
                                                            <SvgIcon
                                                                component={
                                                                    CloseIcon
                                                                }
                                                                inheritViewBox
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            {/* <Divider sx={{ marginLeft: "-32px", marginRight: "-32px", width: "calc(100% + 64px)" }} /> */}

                                            <Box sx={{ padding: '16px' }}>
                                                <Box sx={{ mt: 3 }}>
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Field
                                                                name="name"
                                                                as={TextField}
                                                                fullWidth
                                                                sx={{
                                                                    width: '100%',
                                                                    '& .MuiInputBase-root':
                                                                        {
                                                                            borderRadius:
                                                                                '8px',
                                                                            backgroundColor:
                                                                                '#F5F5F5',
                                                                            color: '#898989',
                                                                            height: '50px',
                                                                            fontSize:
                                                                                '14px',
                                                                            '&:hover':
                                                                                {
                                                                                    '& .MuiOutlinedInput-notchedOutline':
                                                                                        {
                                                                                            borderColor:
                                                                                                '#CED1DA',
                                                                                        },
                                                                                },
                                                                        },
                                                                    '& .MuiFormLabel-root':
                                                                        {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight: 500,
                                                                            color: '#898989',
                                                                            alignContent:
                                                                                'center',
                                                                        },
                                                                }}
                                                                label="Name"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Field
                                                                name="role"
                                                                as={TextField}
                                                                fullWidth
                                                                sx={{
                                                                    width: '100%',
                                                                    '& .MuiInputBase-root':
                                                                        {
                                                                            borderRadius:
                                                                                '8px',
                                                                            height: '50px',
                                                                            fontSize:
                                                                                '14px',
                                                                            backgroundColor:
                                                                                '#F5F5F5',
                                                                            color: '#898989',
                                                                            '&:hover':
                                                                                {
                                                                                    '& .MuiOutlinedInput-notchedOutline':
                                                                                        {
                                                                                            borderColor:
                                                                                                '#CED1DA',
                                                                                        },
                                                                                },
                                                                        },
                                                                    '& .MuiFormLabel-root':
                                                                        {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight: 500,
                                                                            color: '#898989',
                                                                        },
                                                                }}
                                                                label="Role"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Field
                                                                name="rate_per_hour"
                                                                as={TextField}
                                                                fullWidth
                                                                sx={{
                                                                    width: '100%',
                                                                    '& .MuiInputBase-root':
                                                                        {
                                                                            borderRadius:
                                                                                '8px',
                                                                            height: '50px',
                                                                            fontSize:
                                                                                '14px',
                                                                            backgroundColor:
                                                                                '#F5F5F5',
                                                                            color: '#898989',
                                                                            '&:hover':
                                                                                {
                                                                                    '& .MuiOutlinedInput-notchedOutline':
                                                                                        {
                                                                                            borderColor:
                                                                                                '#CED1DA',
                                                                                        },
                                                                                },
                                                                        },
                                                                    '& .MuiFormLabel-root':
                                                                        {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight: 500,
                                                                            color: '#898989',
                                                                        },
                                                                }}
                                                                label="Internal Hourly Cost"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                            />
                                                        </Grid>

                                                        {/* <Grid item xs={12} sm={6}>
                                <Field
                                    name="talent_network_cost"
                                    as={TextField}
                                    fullWidth
                                    sx={{
                                    width: "100%",
                                    '& .MuiInputBase-root': {
                                        borderRadius: "8px",
                                        height: "50px",
                                        fontSize: "14px",
                                        color: "#898989",
                                        "&:hover": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: '#CED1DA',
                                        }
                                        }
                                    },
                                    '& .MuiFormLabel-root': {
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        color: "#898989"
                                    }
                                    }}
                                    label="Talent Network Cost*"
                                    variant="outlined"
                                />
                                </Grid> */}
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <Field
                                                                name="talent_network_cost"
                                                                as={TextField}
                                                                type="number"
                                                                fullWidth
                                                                sx={{
                                                                    width: '100%',
                                                                    '& .MuiInputBase-root':
                                                                        {
                                                                            borderRadius:
                                                                                '8px',
                                                                            height: '50px',
                                                                            fontSize:
                                                                                '14px',
                                                                            color: '#898989',
                                                                            '&:hover':
                                                                                {
                                                                                    '& .MuiOutlinedInput-notchedOutline':
                                                                                        {
                                                                                            borderColor:
                                                                                                '#CED1DA',
                                                                                        },
                                                                                },
                                                                        },
                                                                    '& .MuiFormLabel-root':
                                                                        {
                                                                            fontSize:
                                                                                '14px',
                                                                            fontWeight: 500,
                                                                            color: '#898989',
                                                                        },
                                                                }}
                                                                label="Talent Network Cost*"
                                                                variant="outlined"
                                                                onKeyDown={(
                                                                    e
                                                                ) => {
                                                                    if (
                                                                        [
                                                                            'e',
                                                                            'E',
                                                                            '+',
                                                                            '-',
                                                                            '.',
                                                                        ].includes(
                                                                            e.key
                                                                        ) ||
                                                                        (e.key ===
                                                                            '-' &&
                                                                            e.target.value.includes(
                                                                                '-'
                                                                            )) ||
                                                                        (e.key ===
                                                                            '+' &&
                                                                            e.target.value.includes(
                                                                                '+'
                                                                            ))
                                                                    ) {
                                                                        e.preventDefault()
                                                                    }
                                                                }}
                                                                validate={(
                                                                    value
                                                                ) => {
                                                                    let error
                                                                    const regex =
                                                                        /^[0-9]*$/
                                                                    if (
                                                                        !value
                                                                    ) {
                                                                        error =
                                                                            'Talent Network Cost is required'
                                                                    } else if (
                                                                        !regex.test(
                                                                            value
                                                                        )
                                                                    ) {
                                                                        error =
                                                                            'Only numbers are allowed, no special characters.'
                                                                    }
                                                                    return error
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name="talent_network_cost"
                                                                component="div"
                                                                style={{
                                                                    color: 'red',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            />
                                                        </Grid>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Button
                                                                type="submit"
                                                                variant="Selected"
                                                                sx={{
                                                                    height: '50px',
                                                                    width: '200px',
                                                                    borderRadius:
                                                                        '8px',
                                                                    textTransform:
                                                                        'capitalize',
                                                                    '&.MuiButtonBase-root':
                                                                        {
                                                                            '&:hover':
                                                                                {
                                                                                    border: `2px solid #552FBA`,
                                                                                    backgroundColor:
                                                                                        'white',
                                                                                    color: '#552FBA',
                                                                                },
                                                                        },
                                                                }}
                                                            >
                                                                Match
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>

                                            <Modal
                                                open={confirm}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    '& .MuiBackdrop-root': {
                                                        backgroundColor:
                                                            'rgba(0, 0, 0, 0.5)',
                                                    },
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                        padding: '20px',
                                                        borderRadius: '12px',
                                                        width: '350px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <div className="text-center">
                                                        <img
                                                            src={exclamation}
                                                            alt="exclamation"
                                                            style={{
                                                                width: '55px',
                                                                height: '55px',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mt-2 content text-center">
                                                        <p
                                                            style={{
                                                                fontSize:
                                                                    '14px',
                                                                fontWeight:
                                                                    '500',
                                                                color: '#898989',
                                                            }}
                                                        >
                                                            Are you sure?
                                                        </p>
                                                    </div>
                                                    <div className="mt-3 text-center row px-3">
                                                        <div className="col-6">
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor:
                                                                        '#E2E2E2',
                                                                    border: '1px solid #CED1DA',
                                                                    color: '#898989',
                                                                    borderRadius:
                                                                        '8px',
                                                                    fontSize:
                                                                        '14px',
                                                                    padding:
                                                                        '10px',
                                                                    fontWeight:
                                                                        '600',
                                                                    width: '100%',
                                                                }}
                                                                onClick={() => {
                                                                    handleConfirmClose()
                                                                }}
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                        <div className="col-6">
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor:
                                                                        '#552FBA',
                                                                    color: '#fff',
                                                                    borderRadius:
                                                                        '8px',
                                                                    border: 'none',
                                                                    padding:
                                                                        '10px',
                                                                    fontWeight:
                                                                        '600',
                                                                    fontSize:
                                                                        '14px',
                                                                    width: '100%',
                                                                }}
                                                                onClick={() => {
                                                                    handleConfirmClose()
                                                                    handleClose()
                                                                    applyMatchTalent(
                                                                        formValues
                                                                    )
                                                                }}
                                                            >
                                                                Yes
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Modal>
                </td>

                {/* <td className='text-end'>
                    <div className="btn-group ad-custom-dropdown dropstart">
                        <button type="button" className="btn ad-dropdown-menu dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="addp-menu-icon"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><Link className="dropdown-item" to="">View Details</Link></li>
                        </ul>
                    </div>
                </td> */}
            </tr>
        </>
    )
}

export default LeadMatchItem
