import React, { useEffect, useState } from 'react'

const DashboardProjectStatus = ({ item }) => {
    const { currentSprint, jira_report_status, sprint_status } = item
    const [setupStatus, setSetupStatus] = useState('')
    const [statusClassName, setStatusClassName] = useState('')
    const [progressBarColor, setProgressBarColor] = useState('')

    const delay_percentage = currentSprint?.percentage_delay
    const bug_percentage = currentSprint?.percentage_of_bugs_worklog

    const status = () => {
        if (delay_percentage < 10 && bug_percentage < 15) {
            setSetupStatus('On Track')
            setStatusClassName('addash-green-text')
            setProgressBarColor('#24BFA8')
        } else if (bug_percentage > 15 && bug_percentage <= 25) {
            setSetupStatus('At Risk')
            setStatusClassName('addash-yellow-text')
            setProgressBarColor('#F1C40F')
        } else if (bug_percentage > 25) {
            setSetupStatus('Delayed')
            setStatusClassName('addash-red-text')
            setProgressBarColor('#ED3342')
        }
    }
    const properCaseTeamMembers = (text) => {
        return text
            .split(' ')
            .map((word) => {
                if (word === '(s)') {
                    return '(s)'
                } else {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
            })
            .join(' ')
    }

    useEffect(() => {
        status()
    }, [item])

    const processbarValue =
        jira_report_status?.sprint_status > 100
            ? 100
            : !jira_report_status?.sprint_status
              ? 0
              : jira_report_status?.sprint_status
    const rightPos = 100 - processbarValue

    return (
        <div className="d-inline-block">
            <div className="d-flex align-items-center justify-content-between">
                <div className="dash-md-title dash-font-0-8em text-wrap">
                    {properCaseTeamMembers('Sprint status')}
                </div>
                <div>
                    <span className="employment-type-tag InProgress-tag ms-4">
                        In Progress
                    </span>
                </div>
            </div>
            <div className="d-flex align-items-center mt-1-5">
                <div
                    className={`allocation-label text-nowrap ${statusClassName} me-2`}
                >
                    {setupStatus}&nbsp;
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className="progress ad-allocation-progress ms-0 position-relative me-0"
                >
                    <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                            width: processbarValue + '%',
                            background: progressBarColor,
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            height: '14px',
                        }}
                    ></div>
                    {processbarValue > 30 ? (
                        <div
                            className="allocation-label black-text ad-font-0-8em font-10"
                            style={{
                                right: `calc(${rightPos}% + 10px)`,
                                top: '0px',
                                zIndex: 98,
                            }}
                        >
                            {jira_report_status?.sprint_status || 0}%
                        </div>
                    ) : (
                        <div
                            className="allocation-label black-text ad-font-0-8em font-10"
                            style={{
                                left: `calc(${sprint_status}% + 10px)`,
                                top: '0px',
                                zIndex: 98,
                            }}
                        >
                            {jira_report_status?.sprint_status === '' ||
                            jira_report_status?.sprint_status === 0 ||
                            !jira_report_status?.sprint_status
                                ? 0
                                : jira_report_status?.sprint_status}
                            %
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DashboardProjectStatus
