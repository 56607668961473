import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import BtnSpinner from '../Spinners/BtnSpinner'
import { useNavigate, useLocation } from 'react-router-dom'
import ValidationError from '../Errors/ValidationError'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Box, Stack, Tooltip } from '@mui/material'
import AuthUser from '../../Services/AuthUser'
import AgencyModal from '../Modals/AgencyModal'
import InvoiceDueDate from './InvoiceDueDate'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon-dynamic.svg'
import { IconButton, SvgIcon } from '@mui/material'

const validationSchemaTeam = yup.object({
    invoice_type: yup.string().required('This field is required!'),
    client_id: yup.string().required('This field is required!'),
    from_month: yup.date().required('This field is required!'),
    to_month: yup
        .date()
        .min(yup.ref('from_month'), "End date can't be before Start date")
        .required('This field is required!'),
})

const ProjectInvoiceType = ({
    setProjectType,
    openSelectInvoiceProject,
    clientsObj,
    filteredProject,
    selected,
    setServicesData,
    setProjectBillingData,
}) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const [addBilling, setAddBilling] = useState({ show: false, id: '' })
    const [selectedClientInfo, setSelectedClientInfo] = useState({
        openDueDate: false,
        duration: {},
        clientId: {},
    })

    const { http2 } = AuthUser()

    const getClients = (obj) => {
        let projects = obj?.projects
            ? obj.projects.map((val) => {
                  return {
                      ...val,
                      isLeadsClient: 'no',
                  }
              })
            : []
        let clients = obj?.clients
            ? obj.clients.map((val) => {
                  return {
                      isLeadsClient: 'yes',
                      _id: val?._id || null,
                      company_name: val?.company?.company_name || null,
                  }
              })
            : []

        return projects.concat(clients)
    }

    const readyforBilling = (values) => {
        navigate(
            '/dashboard/billings/project-billing/create',
            { state: { values } },
            { replace: true }
        )
        setIsLoading(false)
    }

    // const servicesforInvoice = (values) => {
    //   console.log(values);
    //   let clientIdObj = {};
    //   if(values.client_id.split("-")[1] == "no"){
    //     clientIdObj = {
    //       project_id: values.client_id.split("-")[0]
    //     }
    //   }else{
    //     clientIdObj = {
    //       client_id: values.client_id.split("-")[0]
    //     }
    //   }

    //   setSelectedClientInfo({
    //     openDueDate: true,
    //     duration: {
    //       from_month: values.from_month,
    //       to_month: values.to_month
    //     },
    //     clientId: clientIdObj
    //   });
    // };

    function properCaseName(name) {
        if (!name) return ''

        return name
            .split(' ')
            .map((word) => {
                if (word.toLowerCase() === 'and') {
                    return 'and'
                }
                if (word.endsWith('.')) {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
            })
            .join(' ')
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchemaTeam}
                initialValues={{
                    client_id: filteredProject || '',
                    invoice_type: 'monthly',
                    from_month: '',
                    to_month: '',
                    clients: clientsObj ? getClients(clientsObj) : {},
                    projects: clientsObj?.projects || {},
                }}
                onSubmit={(values, { resetForm }) => {
                    if (values.invoice_type === 'services') {
                        setServicesData(values)
                    } else {
                        console.log('MyID.......', values.client_id)
                        setIsLoading(true)
                        readyforBilling(values)
                    }
                }}
            >
                {({ values, setFieldValue, field, form, resetForm }) => (
                    <>
                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Create Invoice
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setProjectType(false)
                                        resetForm()
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '5px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body py-4">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 invoice-type-form">
                                    <div className="col-12 text-staet">
                                        <div className="form-group">
                                            <label>Select Invoice Type</label>
                                            <br />
                                            <div className="position-relative d-inline-flex">
                                                <div className="custom-radio ad-custom-radio me-5">
                                                    <input
                                                        className="styled-radio"
                                                        type="radio"
                                                        name="invoice_type"
                                                        value="monthly"
                                                        checked={
                                                            values.invoice_type ===
                                                            'monthly'
                                                        }
                                                        id="monthly"
                                                        onChange={() =>
                                                            setFieldValue(
                                                                'invoice_type',
                                                                'monthly'
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="monthly">
                                                        Monthly
                                                    </label>
                                                </div>
                                                <div className="custom-radio ad-custom-radio">
                                                    <input
                                                        className="styled-radio"
                                                        type="radio"
                                                        name="invoice_type"
                                                        value="services"
                                                        checked={
                                                            values.invoice_type ===
                                                            'services'
                                                        }
                                                        id="services"
                                                        onChange={() =>
                                                            setFieldValue(
                                                                'invoice_type',
                                                                'services'
                                                            )
                                                        }
                                                    />
                                                    <label htmlFor="services">
                                                        Services
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client*
                                            </label>
                                            <Field
                                                as="select"
                                                name="client_id"
                                                className="form-control"
                                                onChange={(val) => {
                                                    setFieldValue(
                                                        'client_id',
                                                        val.target.value
                                                    )
                                                    // console.log(val.target.options[val.target.selectedIndex].attributes["data-is-billing-done"].value);
                                                    if (
                                                        val?.target?.value?.split(
                                                            '-'
                                                        )?.[1] == 'no' &&
                                                        val?.target?.options?.[
                                                            val?.target
                                                                ?.selectedIndex
                                                        ]?.attributes?.[
                                                            'data-is-billing-done'
                                                        ]?.value == 'false'
                                                    ) {
                                                        console.log(
                                                            val.target.value.split(
                                                                '-'
                                                            )[0],
                                                            val.target.value
                                                        )
                                                        setAddBilling({
                                                            show: true,
                                                            id: val.target.value.split(
                                                                '-'
                                                            )[0],
                                                        })
                                                    } else {
                                                        setAddBilling({
                                                            show: false,
                                                            id: val.target.value.split(
                                                                '-'
                                                            )[0],
                                                        })
                                                    }
                                                }}
                                            >
                                                <option
                                                    value=""
                                                    disabled="disabled"
                                                >
                                                    Select Client
                                                </option>
                                                {getClients(clientsObj).map(
                                                    (client) => (
                                                        <option
                                                            key={client._id}
                                                            data-is-billing-done={
                                                                client.is_project_billing_details
                                                            }
                                                            value={`${client._id}-${client.isLeadsClient}`}
                                                        >
                                                            {properCaseName(
                                                                client?.project_name
                                                            ) ||
                                                                properCaseName(
                                                                    client?.company_name
                                                                ) ||
                                                                ''}
                                                        </option>
                                                    )
                                                )}
                                            </Field>
                                        </div>
                                        <ValidationError name="client_id" />
                                    </div>

                                    <div className="form-group">
                                        <div className="position-relative dash-calendar-icon mod-dash-calendar">
                                            <Stack
                                                direction="row"
                                                spacing="10px"
                                            >
                                                <Box
                                                    position="relative"
                                                    width="100%"
                                                >
                                                    <label className="label-pos">
                                                        Start Date
                                                    </label>
                                                    <DatePicker
                                                        id="from_month"
                                                        name="from_month"
                                                        className="form-control"
                                                        selected={
                                                            values.from_month
                                                        }
                                                        onChange={(date) =>
                                                            setFieldValue(
                                                                'from_month',
                                                                date
                                                            )
                                                        }
                                                        dateFormat="dd-MM-yyyy"
                                                    />
                                                    <ValidationError name="from_month" />
                                                </Box>

                                                <Box
                                                    position="relative"
                                                    width="100%"
                                                >
                                                    <label className="label-pos">
                                                        End Date
                                                    </label>
                                                    <DatePicker
                                                        id="to_month"
                                                        name="to_month"
                                                        className="form-control"
                                                        selected={
                                                            values.to_month
                                                        }
                                                        onChange={(date) =>
                                                            setFieldValue(
                                                                'to_month',
                                                                date
                                                            )
                                                        }
                                                        dateFormat="dd-MM-yyyy"
                                                    />
                                                    <ValidationError name="to_month" />
                                                </Box>
                                            </Stack>
                                        </div>
                                        {/* <ValidationError name="month" /> */}
                                    </div>
                                    <div>
                                        {isLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn ad-sm-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <Tooltip
                                                title={
                                                    addBilling?.show &&
                                                    values.invoice_type ===
                                                        'services'
                                                        ? 'You are required to add billing address for project to generate the invoice.'
                                                        : ''
                                                }
                                            >
                                                <Box>
                                                    <button
                                                        className="ad-theme-btn ad-sm-btn w-100"
                                                        type="submit"
                                                        disabled={
                                                            addBilling.show &&
                                                            values.invoice_type ===
                                                                'services'
                                                        }
                                                    >
                                                        Create
                                                    </button>
                                                </Box>
                                            </Tooltip>
                                        )}
                                        {addBilling?.show &&
                                            values.invoice_type ===
                                                'services' && (
                                                <Box
                                                    component="button"
                                                    sx={{ mt: '10px' }}
                                                    className="ad-theme-btn ad-sm-btn w-100"
                                                    onClick={() => {
                                                        console.log(
                                                            addBilling.id
                                                        )
                                                        setProjectBillingData(
                                                            addBilling.id
                                                        )
                                                    }}
                                                >
                                                    Add Billing Address
                                                </Box>
                                            )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
            {/* <AgencyModal open={selectedClientInfo.openInvoiceDue} clsSm={true}>
        <InvoiceDueDate
          setOpenInvoiceDue={(status)=>{setSelectedClientInfo({
            ...selectedClientInfo,
            openDueDate: status
          })}}
          selectedTalents={[]}
          selectedClientId={selectedClientInfo.clientId}
          invoice_type="services"
          month={undefined}
          selectedClient={selectedClientInfo.duration}
        />
      </AgencyModal> */}
        </>
    )
}

export default ProjectInvoiceType
