import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Microservices = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/Microservice.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Microservices
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Flexible solutions with a
                                    microservices-first approach by Weteams
                                    talent
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Microservices give you the flexibility you need
                                to extend your platform more easily than on a
                                monolithic platform. By combining the benefits
                                of SaaS with the flexibility of a fully
                                customized solution, our experts introduces a
                                new paradigm in software architecture
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Real-time Data Processing
                                            </div>
                                            <p>
                                                For streaming systems to provide
                                                intelligent outputs, the
                                                publish-subscribe messaging
                                                pattern employed in
                                                microservices architecture
                                                offers a seamless asynchronous
                                                communication to process and
                                                analyse data in real-time.
                                            </p>
                                            <p>
                                                To deliver a well-polished
                                                online video platform and
                                                production firm, it needs more
                                                than 500 microservices and APIs.
                                                And as an early adopter, it also
                                                serves as an engaging case study
                                                emphasizing the numerous
                                                advantages of microservices.
                                            </p>
                                            <p>
                                                With the help of microservices,
                                                deployment times can be
                                                shortened and obstacles can be
                                                easily found. Using Scala and
                                                Java, an American communications
                                                company also switched from a
                                                standard web application to a
                                                microservices architecture.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                The DevOps Model
                                            </div>
                                            <p>
                                                When paired, DevOps and
                                                microservices operate
                                                flawlessly. Because they employ
                                                a shared toolkit for both
                                                development and operations,
                                                microservices increase the
                                                productivity of the DevOps team.
                                                This makes it possible for
                                                project needs, task
                                                dependencies, and problems to
                                                have an uniform nomenclature and
                                                methods. Now that a build
                                                configuration or build script
                                                has been successfully fixed,
                                                developers and operations staff
                                                can work together on problems.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Big Data Applications
                                            </div>
                                            <p>
                                                In many instances, complex
                                                applications and cloud settings
                                                are a good fit for microservices
                                                and event-driven architecture
                                                (EDA). Because of its loosely
                                                linked architecture, it offers
                                                the desired application
                                                scalability and extensibility.
                                            </p>
                                            <p>
                                                In order To handle the massive
                                                amount of data processing, one
                                                of the leading E-commerce
                                                companies reduced its pipeline
                                                for application deployment,
                                                identified issue areas, rebuilt
                                                a reliable application
                                                framework, and introduced a
                                                reliable open source system.
                                                Every function was optimally
                                                supported by the
                                                service-oriented microservices
                                                architecture wherever in the
                                                world.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Microservices
