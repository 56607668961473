import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const NFT = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/NFT.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    NFT
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Develop distinctive NFT solutions with
                                    Weteams Experts
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Weteams highly skilled talent delivers the
                                finest NFT Marketplace Development Services. Our
                                blockchain engineers and domain experts work
                                together to offer diligently-crafted
                                decentralized NFT development services to help
                                you accomplish your business goals. Get on board
                                with us and avail your NFT solution today
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Healthcare
                                            </div>
                                            <p>
                                                Since NFT transactions are
                                                validated on multiple nodes
                                                before being added to the
                                                blockchain permanently, they can
                                                be used to store a person's
                                                medical records without
                                                compromising confidentiality or
                                                running the risk of outside
                                                tampering. This ensures that
                                                every record is accurate and
                                                safe from malicious attempts at
                                                manipulation.
                                            </p>
                                            <p>
                                                Applications for NFT have also
                                                been created expressly to help
                                                medical practitioners.
                                                Healthcare professionals may
                                                issue NFT birth certificates to
                                                newborns. A quick and efficient
                                                way to immediately establish a
                                                lifelong identity on the
                                                blockchain that is connected to
                                                each child's birth certificate
                                                and is then validated by NFT
                                                verification apps.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Academic Credentials
                                            </div>
                                            <p>
                                                NFTs can be used to represent
                                                academic credentials in a secure
                                                and private manner. NFTs can
                                                provide proof of attendance,
                                                degree earned, and other vital
                                                information that will be
                                                recorded on the NFT chain and
                                                cannot be tampered with or
                                                hacked. By issuing tokens for
                                                each course completed using
                                                smart contract verification
                                                tools, NFTs may create permanent
                                                and unforgeable records of
                                                learning outcomes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                NFT community platforms
                                            </div>
                                            <p>
                                                Users and fans are spending more
                                                time online as a result of
                                                increased exposure to social
                                                media. These influencers- or
                                                celebrity-loyal people might be
                                                provided with limited edition
                                                NFTs of their favourite entity,
                                                as well as unique perks, in
                                                exchange for their attention.{' '}
                                            </p>
                                            <p>
                                                The accessibility to special
                                                events, merchandise, or even
                                                live events is just one of the
                                                perks that comes with being a
                                                subscriber. Brands may give
                                                die-hard fans limited edition
                                                NFTs for free and enhance their
                                                offerings to grow their fan
                                                base.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default NFT
