import { useEffect } from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import apiCall from '../../Services/ApiInstance'
const base_URL = process.env.REACT_APP_base_URL

const RedirectHireTalent = () => {
    let { userId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            apiCall
                .get(`${base_URL}/api/v1/login-as-admin/${userId}`)
                .then((response) => {
                    let result = response.data // for console log
                    // console.log(result)

                    // console.log(result.data.token);
                    // console.log(result.data.user);
                    // console.log(result.data.user.company);

                    if (result.status == false) {
                        navigate('/sign-in-with-email')
                        //setTimeout(navigate(-2), 5000000);
                    }

                    if (result.status) {
                        localStorage.setItem(
                            'token',
                            JSON.stringify(result.data.token)
                        )
                        localStorage.setItem(
                            'user',
                            JSON.stringify(result.data.user)
                        )

                        let path = '/dashboard/overview'
                        navigate(path, { replace: true })
                        //toast.success(result.message)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        let res = error.response.data
                        console.log(error.response)
                        if (error.response.status == 422) {
                            //toast.error(res.message)
                            navigate(-1)
                        }
                        navigate(-1)
                        //toast.error(error.response.message);
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }, [])

    return <></>
}

export default RedirectHireTalent
