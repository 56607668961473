import moment from 'moment'

const useConvertUtcToLocalTimestamp = (time) => {
    const momentInst = moment(time)
    try {
        return moment(momentInst)
            [
                Math.sign(momentInst.utcOffset()) == -1 ? 'subtract' : 'add'
            ](momentInst.utcOffset(), 'm')
            .utc()
            .format()
    } catch (error) {
        return moment().format()
    }
}

export default useConvertUtcToLocalTimestamp
