import React, { useState, useEffect } from 'react'
import Layout from '../Layout'
import { Formik, Form, Field } from 'formik'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import AgencyModal from '../../../Components/Modals/AgencyModal'
import InvoiceDueDate from '../../../Components/BillingsModule/InvoiceDueDate'
import AuthUser from '../../../Services/AuthUser'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Skeleton from 'react-loading-skeleton'
import noBillingIcon from '../../../assets/agency/img/no-billing-icon.svg'
import SassCurrency from '../../../Components/Currency/SassCurrency'
import moment from 'moment'
import { Box } from '@mui/material'
import AddBillingCustomerDetails from '../../../Components/Projects/AddBillingCustomerDetails'

const ProjectInvoiceSearchList = () => {
    const { http2 } = AuthUser()
    const location = useLocation()
    const selectedClient = location.state?.values

    const [isLoading, setIsLoading] = useState(false)
    const [openInvoiceDue, setOpenInvoiceDue] = useState(false)
    const [talents, setTalents] = useState([])
    const [selectedTalents, setSelectedTalents] = useState([])
    const [selectedTalentsIds, setSelectedTalentsIds] = useState([])
    const [selectedClientId, setSelectedClientId] = useState({})
    const [isProfileCompleted, setIsProfileCompleted] = useState()
    const [isBillingAddressCompleted, setIsBillingAddressCompleted] =
        useState(false)
    const [billingCustomerInfo, setBillingCustomerInfo] = useState(false)

    const openInvoiceModal = () => {
        setOpenInvoiceDue(true)
    }

    //
    const toggleSelectAll = () => {
        if (selectedTalents.length === talents.length) {
            // If all items are selected, unselect all
            setSelectedTalents([])
            setSelectedTalentsIds([])
        } else {
            // Otherwise, select all items
            setSelectedTalents(talents)
            setSelectedTalentsIds(talents.map((item) => item.talent_id))
        }
    }

    const toggleSelectItem = (item) => {
        console.log(item)
        if (selectedTalentsIds.includes(item.talent_id)) {
            // Item is selected, unselect it
            setSelectedTalents(
                selectedTalents.filter(
                    (talent) => talent.talent_id !== item.talent_id
                )
            )
            setSelectedTalentsIds(
                selectedTalentsIds.filter(
                    (talent_id) => talent_id !== item.talent_id
                )
            )
        } else {
            // Item is unselected, select it
            setSelectedTalents([...selectedTalents, item])
            setSelectedTalentsIds([...selectedTalentsIds, item.talent_id])
        }
    }

    const getProjectTeams = (fields = null) => {
        console.log('fields', fields, selectedClient)
        try {
            let client_id = null
            let param = null
            let isCompany =
                fields == null
                    ? selectedClient?.client_id.split('-')[1] || ''
                    : fields.client_id.split('-')[1]
            if (isCompany == 'no') {
                client_id =
                    fields === null
                        ? selectedClient.client_id.split('-')[0]
                        : fields.client_id.split('-')[0]
                setSelectedClientId({ project_id: client_id })
                let newObj = {
                    invoice_type: selectedClient.invoice_type,
                    // invoice_duration_from: moment(selectedClient.from_month).format("DD-MM-YYYYT00:00:00.000+00:00"),
                    // invoice_duration_to: moment(selectedClient.to_month).format("DD-MM-YYYYT00:00:00.000+00:00"),
                    project_id: client_id,
                    projects: selectedClient.projects,
                }

                param =
                    fields === null
                        ? newObj
                        : {
                              ...newObj,
                              project_id: client_id,
                              month: fields.month,
                          }

                setIsLoading(true)
                http2
                    .post(
                        `/get-assigned-talent-from-project/${client_id}`,
                        param
                    )
                    .then((response) => {
                        let result = response.data
                        setTalents(result?.data?.teams)
                        setIsProfileCompleted(
                            result?.data?.is_profile_completed
                        )
                        setIsBillingAddressCompleted(
                            result?.data?.is_project_billing_details
                        )
                        setIsLoading(false) // stop loading spinner
                    })
                    .catch((error) => {
                        if (error.response) {
                            toast.error(error.response.data.data)
                            setIsLoading(false) // stop loading spinner
                        }
                    })
            } else {
                client_id =
                    fields === null
                        ? selectedClient.client_id.split('-')[0]
                        : fields.client_id.split('-')[0]
                setSelectedClientId({ company_id: client_id })
                let newObj = {
                    invoice_type: selectedClient.invoice_type,
                    // invoice_duration_from: moment(selectedClient.from_month).format("DD-MM-YYYYT00:00:00.000+00:00"),
                    // invoice_duration_to: moment(selectedClient.to_month).format("DD-MM-YYYYT00:00:00.000+00:00"),
                    company_id: client_id,
                }
                param =
                    fields === null
                        ? newObj
                        : {
                              ...newObj,
                              company_id: client_id,
                              month: fields.month,
                          }

                setIsLoading(true)
                http2
                    .post(`/get-assigned-talent-from-client`, param)
                    .then((response) => {
                        let result = response.data
                        setTalents(result?.data?.teams)
                        setIsProfileCompleted(
                            result?.data?.is_profile_completed
                        )
                        setIsLoading(false) // stop loading spinner
                    })
                    .catch((error) => {
                        if (error.response) {
                            toast.error(error.response.data.data)
                            setIsLoading(false) // stop loading spinner
                        }
                    })
            }
        } catch (error) {
            toast.error('something went wrong')
            console.log(error)
        }
    }

    const TalentCardInternal = ({ item }) => {
        return (
            <div
                className="adts-box project-box d-flex align-items-center justify-content-between mb-10"
                key={item.talent_id}
            >
                <div className="adts-col-info col1">
                    <div className="d-flex align-items-center">
                        <div className="custom-checkbox billing-checkbox">
                            <input
                                className="styled-checkbox"
                                id={`invoiceBillNo${item.talent_id}`}
                                type="checkbox"
                                checked={selectedTalentsIds.includes(
                                    item.talent_id
                                )}
                                onChange={() => toggleSelectItem(item)}
                                disabled={
                                    item.is_invoice_generated ? true : false
                                }
                            />
                            <label
                                htmlFor={`invoiceBillNo${item.talent_id}`}
                            ></label>
                        </div>
                        <div className="dash-hc-info">
                            <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em pe-3">
                                {item.talent_full_name || '-'}
                            </div>
                            {/* <div className="dash-md-title dash-font-0-8em text-wrap mt-1">
                                          Wade Warren
                                        </div> */}
                        </div>
                    </div>
                </div>
                <div className="col2">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Used hours
                        </div>
                        <div className="common-label mt-1 text-type2">
                            {item.used_hours || '0'}
                        </div>
                    </div>
                </div>
                <div className="col2">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Reserved hours
                        </div>
                        <div className="common-label mt-1 text-type2">
                            {item.reserved_hours || '0'}
                        </div>
                    </div>
                </div>
                <div className="col3">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Per hour
                        </div>
                        <div className="common-label mt-1 text-type2">
                            <SassCurrency
                                pCurrency={item.project_currency || 'USD'}
                            />
                            {item.client_hourly_rate || '-'}
                        </div>
                    </div>
                </div>
                <div className="col5">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Taxable value
                        </div>
                        <div className="common-label mt-1 text-type2">0</div>
                    </div>
                </div>
                <div className="col3">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            GST
                        </div>
                        <div className="common-label mt-1 text-type2">0</div>
                    </div>
                </div>
                <div className="col3">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Total amount
                        </div>
                        <div className="common-label mt-1 text-type2">
                            <SassCurrency
                                pCurrency={item.project_currency || 'USD'}
                            />
                            {item.client_billing_amount || '0'}
                        </div>
                    </div>
                </div>
                {/* <div className="col4">
          <div className="d-block">
            <div className="dash-md-title dash-font-0-8em text-wrap">
              Status
            </div>
            <div className="mt-1">{item.is_invoice_generated || '-'}</div>
          </div>
        </div> */}
            </div>
        )
    }

    useEffect(() => {
        getProjectTeams()
    }, [])

    const initialval = {
        client_id: selectedClient.client_id,
        month: selectedClient.month || '',
    }
    return (
        <>
            <Layout>
                {/* <ToastContainer hideProgressBar={true} /> */}
                <div className="ad-white-card p-0">
                    <div className="p-3 pb-0">
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialval}
                            onSubmit={(values) => {
                                // data send serve side
                                getProjectTeams(values)
                            }}
                        >
                            {({
                                values,
                                setFieldValue,
                                field,
                                form,
                                resetForm,
                                formik,
                            }) => (
                                <Form autoComplete="off">
                                    <div className="custom-form ad-custom-form ad-form-theme2 search-project-form row gx-3">
                                        <div className="col-md-4 my-1">
                                            <div className="position-relative">
                                                <label className="label-pos">
                                                    Search By Project Name
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="client_id"
                                                    className="form-control"
                                                >
                                                    <option
                                                        value=""
                                                        disabled="disabled"
                                                    >
                                                        Select project
                                                    </option>
                                                    {selectedClient?.clients &&
                                                        selectedClient?.clients.map(
                                                            (client) => (
                                                                <option
                                                                    key={
                                                                        client._id
                                                                    }
                                                                    value={`${client._id}-${client.isLeadsClient}`}
                                                                    select={
                                                                        selectedClient.client_id.split(
                                                                            '-'
                                                                        )[0] ==
                                                                        client._id
                                                                    }
                                                                >
                                                                    {client?.project_name ||
                                                                        client?.company_name ||
                                                                        ''}
                                                                </option>
                                                            )
                                                        )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className="col-md-4 my-1">
                                            <div className="position-relative dash-calendar-icon">
                                                <label className="label-pos">
                                                    Search By Month
                                                </label>
                                                <DatePicker
                                                    id="month"
                                                    name="month"
                                                    className="form-control"
                                                    selected={values.month}
                                                    onChange={(date) =>
                                                        setFieldValue(
                                                            'month',
                                                            date
                                                        )
                                                    }
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 my-1">
                                            <div className="position-relative">
                                                <button
                                                    className="ad-theme-btn ps-4 pe-4 me-3"
                                                    type="submit"
                                                >
                                                    Create
                                                </button>
                                                <Link
                                                    to="/dashboard/billings/project-billing"
                                                    className="ad-trans-gray-btn ps-4 pe-4 me-3"
                                                >
                                                    Cancel
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <hr className="mt-2 mb-1 custom-hr" />
                    {isLoading ? (
                        <Skeleton
                            containerClassName="cs-skeleton"
                            count={8}
                            height={70}
                        />
                    ) : (
                        <>
                            <div className="p-3 pt-2">
                                {talents.length > 0 ? (
                                    <>
                                        <div className="d-flex align-items-center justify-content-between adts-header mb-3">
                                            <div className="pl-20">
                                                <div className="custom-checkbox billing-checkbox">
                                                    <input
                                                        className="styled-checkbox"
                                                        id="invoiceSelectAll"
                                                        type="checkbox"
                                                        checked={
                                                            selectedTalents.length ===
                                                            talents.length
                                                        }
                                                        onChange={
                                                            toggleSelectAll
                                                        }
                                                    />
                                                    <label
                                                        htmlFor="invoiceSelectAll"
                                                        className="common-label text-type2 dash-black-text"
                                                    >
                                                        Select
                                                    </label>
                                                </div>
                                            </div>
                                            <div>
                                                {console.log(
                                                    `(selectedClient?.client_id.split("-")[1] == "yes" && isProfileCompleted)`,
                                                    selectedClient?.client_id.split(
                                                        '-'
                                                    )[1] == 'yes' &&
                                                        isProfileCompleted,
                                                    !isBillingAddressCompleted &&
                                                        selectedClient?.client_id.split(
                                                            '-'
                                                        )[1] == 'no',
                                                    selectedClient?.client_id
                                                )}
                                                {(!isBillingAddressCompleted &&
                                                    selectedClient?.client_id.split(
                                                        '-'
                                                    )[1] == 'no') ||
                                                (selectedClient?.client_id.split(
                                                    '-'
                                                )[1] == 'yes' &&
                                                    !isProfileCompleted) ? (
                                                    <Box>
                                                        <button
                                                            className="btn ad-dropdown-btn"
                                                            type="button"
                                                            onClick={() => {
                                                                setBillingCustomerInfo(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            Add Billing Address
                                                        </button>
                                                        <span className="ad-info-box">
                                                            <button
                                                                className="btn ad-dropdown-btn"
                                                                type="button"
                                                                disabled={true}
                                                            >
                                                                <span className="ad-billing-icon"></span>{' '}
                                                                Generate Invoice
                                                            </button>
                                                            <div className="ad-info-pos ad-pos-right">
                                                                You are required
                                                                to add billing
                                                                address for
                                                                project to
                                                                generate the
                                                                invoice.
                                                            </div>
                                                        </span>
                                                    </Box>
                                                ) : isProfileCompleted ? (
                                                    <button
                                                        className="btn ad-dropdown-btn"
                                                        type="button"
                                                        onClick={
                                                            openInvoiceModal
                                                        }
                                                        disabled={
                                                            selectedTalents.length >
                                                            0
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        <span className="ad-billing-icon"></span>{' '}
                                                        Generate Invoice
                                                    </button>
                                                ) : (
                                                    <span className="ad-info-box">
                                                        <button
                                                            className="btn ad-dropdown-btn"
                                                            type="button"
                                                            disabled={true}
                                                        >
                                                            <span className="ad-billing-icon"></span>{' '}
                                                            Generate Invoice
                                                        </button>
                                                        <div className="ad-info-pos ad-pos-right">
                                                            You are required to
                                                            complete your
                                                            profile to generate
                                                            the invoice.
                                                        </div>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}

                                <div className="ad-team-flex">
                                    <div className="ad-team-summary">
                                        <div className="adts-body">
                                            <div className="adp-list">
                                                <div className="adp-list-width">
                                                    {talents.length > 0 ? (
                                                        talents.map((item) => (
                                                            <TalentCardInternal
                                                                key={
                                                                    item.talent_id
                                                                }
                                                                item={item}
                                                            />
                                                        ))
                                                    ) : (
                                                        <div className="ad-white-card mt-3 p-5 no-data-box">
                                                            <div className="mt-5 text-center">
                                                                <img
                                                                    src={
                                                                        noBillingIcon
                                                                    }
                                                                    alt="no team"
                                                                />
                                                            </div>
                                                            <div className="content ad-gray-text text-center mt-2 mb-5">
                                                                No data found
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Layout>
            <AgencyModal open={openInvoiceDue} clsSm={true}>
                <InvoiceDueDate
                    setOpenInvoiceDue={setOpenInvoiceDue}
                    selectedTalents={selectedTalents}
                    selectedClientId={selectedClientId}
                    invoice_type={selectedClient.invoice_type}
                    month={selectedClient.month}
                    selectedClient={selectedClient}
                />
            </AgencyModal>
            <AgencyModal open={billingCustomerInfo}>
                <AddBillingCustomerDetails
                    projectId={
                        Object.keys(selectedClientId).includes('project_id')
                            ? selectedClientId.project_id
                            : null
                    }
                    setBillingCustomerInfo={setBillingCustomerInfo}
                    setReload={() => {
                        getProjectTeams(null)
                    }}
                />
            </AgencyModal>
        </>
    )
}

export default ProjectInvoiceSearchList
