import { Box, Stack, Typography, useTheme } from '@mui/material'
import Asana from '../../../assets/dashboard/img/asana_configure_icon.svg'
import Jira from '../../../assets/dashboard/img/jira_configure_icon.svg'
import { useState } from 'react'

const ToolChoiceModal = ({ close, chooseNext }) => {
    const theme = useTheme()
    const [chosenTool, setChosenTool] = useState('')

    return (
        <Box sx={{ p: '40px' }}>
            <Box
                component="button"
                type="button"
                className="btn-close"
                sx={{
                    '&.MuiBox-root': {
                        position: 'absolute',
                        right: '12px',
                        top: '12px',
                        padding: '15px',
                        boxSizing: 'border-box',
                    },
                }}
                onClick={() => {
                    setChosenTool('')
                    close()
                }}
            ></Box>
            <Typography
                component="div"
                variant="size5SemiboldTextText1"
                sx={{ pb: '30px', textAlign: 'center' }}
            >
                Select the project management tool you would like to integrate.
            </Typography>
            <Stack direction="row" mb="35px" spacing="30px">
                <Box
                    flex={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => {
                        setChosenTool('jira')
                    }}
                    sx={{
                        border: `1px solid ${theme.palette.teritiary.teritiary6}`,
                        borderRadius: '8px',
                        cursor: 'pointer',
                        '&:hover': {
                            boxShadow: `0px 4px 10px 0px ${theme.palette.teritiary.teritiary39}`,
                        },
                        ...(chosenTool == 'jira' && {
                            boxShadow: `0px 4px 10px 0px ${theme.palette.teritiary.teritiary39}`,
                        }),
                    }}
                >
                    <img src={Jira} style={{ height: '80px', width: '80px' }} />
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => {
                        setChosenTool('asana')
                    }}
                    sx={{
                        border: `1px solid ${theme.palette.teritiary.teritiary6}`,
                        cursor: 'pointer',
                        borderRadius: '8px',
                        '&:hover': {
                            boxShadow: `0px 4px 10px 0px ${theme.palette.teritiary.teritiary39}`,
                        },
                        ...(chosenTool == 'asana' && {
                            boxShadow: `0px 4px 10px 0px ${theme.palette.teritiary.teritiary39}`,
                        }),
                    }}
                >
                    <img
                        src={Asana}
                        style={{ height: '80px', width: '80px' }}
                    />
                </Box>
            </Stack>
            <Box
                sx={{
                    ...(!chosenTool && {
                        backgroundColor: theme.palette.teritiary.teritiary6,
                        color: theme.palette.common.black,
                        borderColor: theme.palette.teritiary.teritiary6,
                        '&:hover': {
                            backgroundColor: theme.palette.teritiary.teritiary6,
                            color: theme.palette.common.black,
                            borderColor: theme.palette.teritiary.teritiary6,
                        },
                    }),
                }}
                component="button"
                type="button"
                className="ad-theme-btn ad-sm-btn w-100 d-block"
                onClick={() => {
                    if (chosenTool) {
                        chooseNext(chosenTool)
                    }
                }}
            >
                Configure
            </Box>
        </Box>
    )
}

export default ToolChoiceModal
