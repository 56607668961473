import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Gaming = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/Gaming.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Gaming
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Redefining Gaming Experiences With
                                    Innovation & Excitement
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Our team of experts can provide you with the
                                best gaming solutions for all platforms matching
                                your expectations
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Blockchain-Based Gaming
                                            </div>
                                            <p>
                                                Blockchain is a game-changing
                                                technology, and it's not just
                                                affecting e-commerce — it's
                                                revolutionizing the gaming
                                                industry as well.
                                            </p>
                                            <p>
                                                The traditional gaming industry
                                                is fraught with problems, from
                                                third-party sellers selling
                                                in-game items for real-world
                                                money to centralized servers
                                                that are easy to hack.
                                                Blockchain provides a solution
                                                to these problems by
                                                decentralizing the game economy
                                                and making it more secure.
                                            </p>
                                            <p>
                                                Blockchain technology is
                                                assisting enterprises by
                                                providing a secure environment
                                                for their apps and preventing
                                                hackers from stealing their
                                                data. Blockchain integration has
                                                set the stage for a new category
                                                of games including play-to-earn,
                                                pay-to-earn, and NFT-based
                                                gaming by integrating it with
                                                crypto.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                E-Sports
                                            </div>
                                            <p>
                                                For many years, the electronic
                                                sports (E-sports) gaming
                                                business has been on trend and
                                                is now exploding. Tournaments
                                                are held frequently in this
                                                world, with gamers from
                                                different league teams competing
                                                against one another.
                                            </p>
                                            <p>
                                                Fortnite, League of Legends,
                                                Counter-Strike, Call of Duty,
                                                Overwatch, and Madden NFL are
                                                just a few of the incredibly
                                                popular video games in the
                                                e-sports industry. These gamers
                                                have millions of followers all
                                                around the world who follow
                                                their gameplay strategies. Teams
                                                or individual players might win
                                                millions of dollars in cash
                                                prizes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Fraud detection
                                            </div>
                                            <p>
                                                In the world of gaming,
                                                everything happens quickly. The
                                                fraudsters are interested in the
                                                rapidity of all of these
                                                procedures for a reason. As a
                                                result, businesses have to
                                                combat fraudulent activity while
                                                yet maintaining customer
                                                satisfaction at a high enough
                                                level. In every sector, security
                                                concerns are tough.
                                                <br />
                                                Payment fraud is another issue
                                                that affects a lot of online
                                                gaming. The criminals create
                                                customized bots to acquire the
                                                data necessary for payment
                                                transactions. As a result, game
                                                companies must provide strict
                                                security measures for the
                                                player's personal information
                                                and transactions conducted.
                                            </p>
                                            <p>
                                                Game companies are finding new
                                                ways to make money through
                                                machine learning technologies.
                                                Their use allows for the rapid
                                                identification of suspicious
                                                activity. Due to the amount of
                                                data they can analyze, fraud
                                                detection has become much more
                                                automated and efficient.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Gaming
