import {
    Avatar,
    Box,
    ClickAwayListener,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    SvgIcon,
    Typography,
    useTheme,
} from '@mui/material'
import { ReactComponent as PaymentsBilling } from '../../assets/dashboard/img/open-payments-actions-dynamic.svg'
import { ReactComponent as Download } from '../../assets/dashboard/img/download-dynamic.svg'
import { ReactComponent as SendEmail } from '../../assets/dashboard/img/send-email-dynamic.svg'
import { ReactComponent as ViewInvoice } from '../../assets/dashboard/img/view-invoice-dynamic.svg'
import { ReactComponent as MenuIcon } from '../../assets/agency/img/dp-menu-icon.svg'
import { ReactComponent as UpArrow } from '../../assets/dashboard/img/up-arrow-dynamic.svg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SendEmailModal from './SendEmaiModal'
import { useApiService } from '../../Services/ApiInstance'
import { toast, ToastContainer } from 'react-toastify'
import { InrSeprator, UsdCadSeprator } from '../../Config/Helper'

const ProjectInvoiceItem = ({
    item,
    changeStatusBilling,
    changeIndexCCEmail,
}) => {
    const [menuData, setMenuData] = useState({
        open: false,
        anchorEl: null,
        submenuOpen: false,
        submenuAnchorEl: null,
        openEmailModal: false,
    })
    const navigate = useNavigate()
    const theme = useTheme()
    const {
        postRequest,
        getRequest,
        SEND_INVOICE_EMAIL,
        DOWNLOAD_INVOICE,
        UPDATE_CC_EMAIL,
    } = useApiService()

    const {
        id,
        invoice_date,
        invoice_number,
        month,
        invoice_duration_to,
        invoice_duration_from,
        project_name,
        short_name,
        status,
        type,
        project_currency,
        project_billing_customer_name,
        client_bank_beneficiary_name,
        project_billing_country,
        client_name,
        client_email,
        due_date,
        total_taxable_amount,
        sender_company_logo,
        sender_company,
        project_logo,
        billing_email,
    } = item

    const shortName = () => {
        if (project_billing_customer_name.includes(' ')) {
            let arr = project_billing_customer_name.split(' ')
            let first = arr[0].charAt(0).toUpperCase()
            let last = arr[arr.length - 1].charAt(0).toUpperCase()
            return `${first}${last}`
        } else {
            let first = project_billing_customer_name.charAt(0).toUpperCase()
            let last = project_billing_customer_name
                .charAt(project_billing_customer_name.length - 1)
                .toUpperCase()
            return `${first}${last}`
        }
    }

    const handleClick = (event) => {
        setMenuData({
            ...menuData,
            open: !menuData.open,
            anchorEl: event.currentTarget,
        })
    }

    const handleSubmenuClick = (event) => {
        setMenuData({
            ...menuData,
            ...(event.target.innerText == 'Paid' && { open: false }),
            submenuOpen: !menuData.submenuOpen,
            submenuAnchorEl: event.currentTarget,
        })
    }

    const handleClose = () => {
        setMenuData({
            ...menuData,
            open: false,
        })
    }

    const handlePaidClose = () => {
        changeStatusBilling(id)
    }

    const handleSubmenuClose = () => {
        setMenuData({
            ...menuData,
            submenuOpen: false,
        })
    }

    // const sendEmail = (to, cc,client_name) => {

    //   console.log(billing_email,cc,"cccccccccccccccccccccccccccccccccccccccccccccc")
    //   setMenuData({
    //     ...menuData,
    //     openEmailModal:false
    //   });
    //   postRequest(SEND_INVOICE_EMAIL, {
    //     email: to,
    //     cc_email: billing_email ?  billing_email.split(",")  : cc,
    //     attachment: invoice_number,
    //     name: client_bank_beneficiary_name,
    //     duration: month,
    //     due_date: due_date,
    //     name: client_name
    //   }).then((val) => {
    //     // toast.success("Email sent successfully!");
    //   })
    // }

    const sendEmail = (to, cc, client_name) => {
        console.log(cc, 'Processed CC Emails') // Check the processed CC emails

        setMenuData({
            ...menuData,
            openEmailModal: false,
        })

        // postRequest(UPDATE_CC_EMAIL+id).then(()=>{
        postRequest(SEND_INVOICE_EMAIL, {
            email: to,
            cc_email: cc, // Use the processed cc list
            attachment: invoice_number,
            name: client_bank_beneficiary_name,
            duration: month,
            due_date: due_date,
            client_name: client_name, // Ensure correct key usage
        }).then((result) => {
            if (!result.isError) {
                const res = result.data
                toast.success(res.message, {
                    onOpen: () => {
                        changeIndexCCEmail(cc)
                    },
                })
            } else {
                toast.error('Something went wrong!')
            }
        })
        // })
    }

    // const downloadInvoice = (invoiceNumber) => {
    //   getRequest(`${DOWNLOAD_INVOICE}${invoiceNumber}`,{responseType: 'blob'}).then((response)=>{
    //     window.open(URL.createObjectURL(response.data));
    //   })
    // }

    const downloadInvoice = (invoiceNumber) => {
        getRequest(`${DOWNLOAD_INVOICE}${invoiceNumber}`, {
            responseType: 'blob',
        }).then((response) => {
            // Create a Blob object from the response data
            const blob = new Blob([response.data], { type: response.data })

            // Create a link element
            const link = document.createElement('a')

            // Create a URL for the Blob and set it as the href attribute of the link
            const url = URL.createObjectURL(blob)
            link.href = url

            // Set the download attribute with the desired file name
            link.download = `${invoiceNumber}.pdf` // Assuming the file is a PDF. Change the extension if needed

            // Append the link to the DOM
            document.body.appendChild(link)

            // Programmatically click the link to trigger the download
            link.click()

            // Remove the link from the DOM
            document.body.removeChild(link)

            // Revoke the object URL to free up memory
            URL.revokeObjectURL(url)
        })
    }

    function properCaseName(name) {
        if (!name) return ''

        return name
            .split(' ')
            .map((word) => {
                if (word.toLowerCase() === 'and') {
                    return 'and'
                }
                if (word.endsWith('.')) {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
                return (
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
            })
            .join(' ')
    }

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true}/> */}
            <div className="adts-box project-box d-flex align-items-center justify-content-between mb-10">
                <div className="adts-col-info col1">
                    <div className="d-flex align-items-center">
                        {/* <Box
          className="dash-hc-img ad-sm-hc ad-project-circle"
          sx={{background:theme.palette.common.white,border:`1px solid ${theme.palette.primary.main}`}}
        >
          <Box component="span" className="talent-short-name">
            { shortName() || "-"}
          </Box>
        </Box> */}
                        <Avatar
                            sx={{
                                width: 44,
                                height: 44,
                                backgroundColor: theme.palette.common.white,
                                border: `1px solid ${theme.palette.primary.main}`,
                                color: theme.palette.primary.main,
                            }}
                            src={project_logo}
                            slotProps={{
                                img: {
                                    sx: {
                                        width: 42,
                                        height: 42,
                                        objectFit: 'contain',
                                    },
                                },
                            }}
                        >
                            {sender_company ? shortName(sender_company) : '-'}
                        </Avatar>
                        <div className="dash-hc-info">
                            <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em pe-3">
                                {properCaseName(
                                    project_billing_customer_name
                                ) ||
                                    properCaseName(
                                        project_billing_customer_name
                                    ) ||
                                    '-'}
                            </div>
                            {/* <div className="dash-md-title dash-font-0-8em text-wrap mt-0">
            {client_name || "-"}
          </div> */}
                            {/* <div className="dash-md-title dash-font-0-8em text-wrap mt-0">
            {project_billing_country || "-"}
          </div> */}
                        </div>
                    </div>
                </div>

                {/* Invoice Number */}
                <div className="col3">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Invoice #
                        </div>
                        <div className="common-label mt-1-5 text-type2">
                            {invoice_number || '-'}
                        </div>
                    </div>
                </div>
                {/* Invoice Date */}
                <div className="col5">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Invoice Date
                        </div>
                        <div className="common-label mt-1-5 text-type2">
                            {invoice_date || '-'}
                        </div>
                    </div>
                </div>
                {/* Due Date */}
                <div className="col2">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Due Date
                        </div>
                        <div className="common-label mt-1-5 text-type2">
                            {due_date || '-'}
                        </div>
                    </div>
                </div>
                {/* Invoice Duration */}
                <Box
                    sx={{ '&.MuiBox-root': { width: '33%', pr: '10px' } }}
                    className="col2"
                >
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Invoice Duration
                        </div>
                        <Box className="common-label mt-1-5 text-type2">
                            <Box>{invoice_duration_from || '-'} To</Box>
                            <Box>{invoice_duration_to || '-'}</Box>
                        </Box>
                    </div>
                </Box>

                {/* Amount */}
                <div className="col2">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Amount
                        </div>
                        <div className="common-label mt-1-5 text-type2">
                            {project_currency || '-'}{' '}
                            {project_currency === 'INR'
                                ? InrSeprator(Math.round(total_taxable_amount))
                                : project_currency === 'USD' ||
                                    project_currency === 'CAD'
                                  ? UsdCadSeprator(
                                        Math.round(total_taxable_amount)
                                    )
                                  : '-'}
                        </div>
                    </div>
                </div>

                <div className="col4">
                    <div className="d-block">
                        <div className="dash-md-title dash-font-0-8em text-wrap">
                            Status
                        </div>
                        <div className="mt-1">
                            {status === 'Pending' ? (
                                <span className="employment-type-tag Inactive-tag">
                                    Pending
                                </span>
                            ) : (
                                <span className="employment-type-tag Active-tag">
                                    Paid
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <Box
                    display="flex"
                    alignItems="center"
                    className="col6 text-end"
                >
                    <ClickAwayListener
                        disableReactTree
                        onClickAway={() => {
                            handleClose()
                        }}
                    >
                        <Box className="btn-group ad-custom-dropdown dropstart">
                            <IconButton onClick={handleClick}>
                                <SvgIcon component={MenuIcon} inheritViewBox />
                            </IconButton>
                            {/* <button
          type="button"
          className="btn ad-dropdown-menu dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="addp-menu-icon"></span>
        </button> */}
                            <Menu
                                key={id}
                                anchorEl={menuData.anchorEl}
                                open={menuData.open}
                                onClose={handleClose}
                                anchorReference="none"
                                sx={{
                                    position: 'absolute',
                                    width: '182px',
                                    top: '40px',
                                    left: '-125px',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                slotProps={{
                                    root: {
                                        sx: {
                                            '& .MuiMenu-list': {
                                                p: 0,
                                            },
                                            '& .MuiMenuItem-root': {
                                                '&:hover': {
                                                    backgroundColor:
                                                        'primary.main',
                                                    '&>.MuiListItemIcon-root': {
                                                        '&>.MuiSvgIcon-root': {
                                                            color: 'common.white',
                                                        },
                                                    },
                                                    '&>.MuiListItemText-root': {
                                                        '&>.MuiTypography-root':
                                                            {
                                                                color: 'common.white',
                                                            },
                                                    },
                                                    '&>.MuiSvgIcon-root': {
                                                        color: 'common.white',
                                                    },
                                                    '&>.MuiTypography-root': {
                                                        color: 'common.white',
                                                    },
                                                },
                                            },
                                        },
                                    },
                                    paper: {
                                        sx: {
                                            width: '100%',
                                            height: 'auto',
                                            maxHeight: 'max-content',
                                            overflow: 'visible',
                                            borderRadius: '8px',
                                            border: '1px solid',
                                            borderColor: 'primary.main',
                                        },
                                    },
                                }}
                                disableScrollLock
                                disablePortal
                                hideBackdrop
                                disableEnforceFocus
                            >
                                <MenuItem
                                    sx={{
                                        p: '7px 14px',
                                        borderTopRightRadius: '8px',
                                        borderTopLeftRadius: '8px',
                                    }}
                                    onClick={() => {
                                        window.open(
                                            `/dashboard/billings/project-billing/view-billing/${id}`,
                                            '_blank',
                                            'noreferrer'
                                        )
                                    }}
                                >
                                    <ListItemIcon>
                                        <SvgIcon
                                            sx={{ color: 'text.text2' }}
                                            component={ViewInvoice}
                                            inheritViewBox
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'size3MediumTextText1',
                                            align: 'left',
                                        }}
                                    >
                                        View
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    sx={{ p: '7px 14px' }}
                                    onClick={() => {
                                        downloadInvoice(invoice_number)
                                    }}
                                >
                                    <ListItemIcon>
                                        <SvgIcon
                                            sx={{ color: 'text.text2' }}
                                            component={Download}
                                            inheritViewBox
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'size3MediumTextText1',
                                            align: 'left',
                                        }}
                                    >
                                        Download
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    sx={{ p: '7px 14px' }}
                                    onClick={() => {
                                        setMenuData({
                                            ...menuData,
                                            openEmailModal: true,
                                        })
                                    }}
                                >
                                    <ListItemIcon>
                                        <SvgIcon
                                            sx={{ color: 'text.text2' }}
                                            component={SendEmail}
                                            inheritViewBox
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            variant: 'size3MediumTextText1',
                                            align: 'left',
                                        }}
                                    >
                                        Email
                                    </ListItemText>
                                </MenuItem>
                                {status === 'Pending' && (
                                    <MenuItem
                                        sx={{
                                            p: '7px 14px',
                                            borderBottomRightRadius: '8px',
                                            borderBottomLeftRadius: '8px',
                                        }}
                                        onClick={handleSubmenuClick}
                                    >
                                        <ListItemIcon>
                                            <SvgIcon
                                                sx={{ color: 'text.text2' }}
                                                component={PaymentsBilling}
                                                inheritViewBox
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: 'size3MediumTextText1',
                                                align: 'left',
                                            }}
                                        >
                                            Status
                                        </ListItemText>
                                        <SvgIcon
                                            sx={{
                                                ...(!menuData.submenuOpen && {
                                                    transform: 'rotate(180deg)',
                                                }),
                                            }}
                                            component={UpArrow}
                                            inheritViewBox
                                        />
                                        <Menu
                                            anchorReference="none"
                                            sx={{
                                                position: 'absolute',
                                                width: '132px',
                                                top: '50px',
                                                left: '50px',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            slotProps={{
                                                paper: {
                                                    sx: {
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxHeight:
                                                            'max-content',
                                                        borderRadius: '8px',
                                                        border: '1px solid',
                                                        borderColor:
                                                            'primary.main',
                                                    },
                                                },
                                            }}
                                            anchorEl={menuData.submenuAnchorEl}
                                            open={menuData.submenuOpen}
                                            onClose={handleSubmenuClose}
                                            disableScrollLock
                                            disablePortal
                                            hideBackdrop
                                            disableEnforceFocus
                                        >
                                            <MenuItem
                                                sx={{ p: '7px 14px' }}
                                                onClick={handlePaidClose}
                                            >
                                                <Typography variant="size3MediumTextText1">
                                                    Paid
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem sx={{ p: '7px 14px' }}>
                                                <Typography variant="size3MediumTextText1">
                                                    Cancel
                                                </Typography>
                                            </MenuItem>
                                        </Menu>
                                    </MenuItem>
                                )}
                            </Menu>

                            {/* <ul className="dropdown-menu dropdown-menu-end">
          <li>
            <Link className="dropdown-item" to={`/dashboard/billings/project-billing/view-billing/${id}`} target="_blank">
              View Invoice
            </Link>
          </li>
          {/* <li>
            <button className="dropdown-item" type="button">
              Download Invoice PDF
            </button>
          </li> */}
                            {/* </ul> */}
                        </Box>
                    </ClickAwayListener>
                </Box>
                <SendEmailModal
                    open={menuData.openEmailModal}
                    onClose={() => {
                        setMenuData({
                            ...menuData,
                            openEmailModal: false,
                        })
                    }}
                    billing_email={billing_email}
                    client_email={client_email}
                    client_name={client_name}
                    sendEmail={(to, cc, client_name) => {
                        sendEmail(to, cc, client_name)
                    }}
                    PaperProps={{
                        sx: {
                            width: '448px',
                            maxWidth: '1108px',
                        },
                    }}
                    onSubmit={({ to_email, cc_email, client_name }) => {
                        sendEmail(to_email, cc_email, client_name)
                    }}
                />
            </div>
        </>
    )
}

export default ProjectInvoiceItem
