import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const API = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/API.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    API
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Diversify the workflows with secure API
                                    solutions by Weteams expert
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Deliver the engaging experiences that your
                                customers expect. Enable connectivity and
                                personalization with the Weteams API solution
                                experts
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Ecommerce
                                            </div>
                                            <p>
                                                APIs allow one application (such
                                                as your eCommerce platform) to
                                                expose services to other
                                                applications (such as catalogue
                                                content, order management,
                                                pricing information, and
                                                customer data). Simply speaking,
                                                an API helps systems “talk” to
                                                each other.
                                            </p>
                                            <p>
                                                APIs can provide enormous
                                                benefits for eCommerce websites
                                                because they can allow you to
                                                create flexible tech stacks by
                                                integrating with the solutions
                                                you need. Most eCommerce
                                                platforms provide APIs to make
                                                it easy for their applications
                                                to connect with other systems.
                                                These API examples can run your
                                                business smoothly - catalog API,
                                                login API, Cart API, Checkout
                                                API, Payments API, Shipping API,
                                                etc.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Healthcare
                                            </div>
                                            <p>
                                                The best healthcare APIs serve
                                                as a framework for addressing
                                                complex clinical issues. For
                                                example, with the help of
                                                artificial intelligence in
                                                healthcare, APIs are being
                                                deployed within specialized
                                                software platforms to create a
                                                centralized, longitudinal view
                                                of the patient. APIs in
                                                healthcare enable information
                                                systems to communicate and
                                                transfer data from one to
                                                another.
                                            </p>
                                            <p>
                                                A healthcare organization, for
                                                example, can enter a patient's
                                                medical coverage information
                                                into a system that works with
                                                insurance companies to instantly
                                                determine the patient's coverage
                                                for a specific medication or
                                                procedure. An API makes this
                                                possible. The best healthcare
                                                APIs enable systems to send and
                                                receive data that can be used to
                                                update a patient's record or to
                                                provide aggregate data for
                                                healthcare analytics.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Remote and Web APIs
                                            </div>
                                            <p>
                                                Remote APIs enable developers to
                                                manipulate remote resources via
                                                protocols, which are
                                                communication standards that
                                                allow different technologies to
                                                work together regardless of
                                                language or platform.
                                                <br />
                                                Web APIs are services that are
                                                accessed from client devices
                                                (such as mobile phones, laptops,
                                                etc.) to a web server via the
                                                Hypertext Transfer Protocol
                                                (HTTP).
                                            </p>
                                            <p>
                                                Client devices send an HTTP
                                                request and are met with a
                                                response message, which is
                                                typically in JavaScript Object
                                                Notation (JSON) or Extensible
                                                Markup Language (XML) format.
                                                Web APIs are typically used by
                                                developers to query a server for
                                                a specific set of data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default API
