import { useState } from 'react'
import { Link } from 'react-router-dom'
import TextField from '../../Components/Form/TextField'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BtnSpinner from '../../Components/Spinners/BtnSpinner'
import logo from '../../assets/agency/img/weteams-icon.svg'
import apiCall from '../../Services/ApiInstance'
const base_URL = process.env.REACT_APP_base_URL

const validationSchema = yup.object({
    email: yup.string().email().required('Email field is required'),
})

const ResetPasswordSend = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [disable, setDisable] = useState(false)

    const resendPasswordLink = (email) => {
        try {
            setIsLoading(true) // start loading spinner
            setDisable(true) // disable resend btn
            apiCall
                .post(`${base_URL}/api/v1/send-reset-password-link`, email)
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        toast.success(result.message)
                    } else {
                        toast.error(result.message)
                    }
                    setIsLoading(false) // stop loading spinner
                    setDisable(false) // able resend btn
                })
                .catch((error) => {
                    setIsLoading(false) // stop loading spinner
                    setDisable(false) // able resend btn
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div className="ad-form-page-bg">
                <div className="ad-form-block">
                    <div className="white-box ad-form-box">
                        <div className="ad-logo-icon text-center mb-4">
                            <img src={logo} alt="weteams" />
                        </div>
                        <div className="md-title fw-700 text-center mb-2">
                            Forgot your password
                        </div>
                        <div className="ad-xs-title text-center mb-4">
                            Enter your email address and we will send you
                            instructions to reset your password.
                        </div>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={{
                                email: '',
                            }}
                            onSubmit={(values) => {
                                // data send serve side
                                resendPasswordLink(values)
                            }}
                        >
                            <Form
                                className="custom-form ad-custom-form"
                                autoComplete="off"
                            >
                                <div className="form-group position-relative">
                                    <TextField
                                        type="email"
                                        placeholder="Email address"
                                        name="email"
                                        className="form-control pl-45"
                                        icon="ad-iconEnv ad-iconPos"
                                    />
                                </div>
                                {/* <div className="mt-40">
                                    <div className="link">
                                        Didn't receive the Reset link?

                                        <button disabled={disable} type="submit" className="theme-text">
                                            Resend
                                        </button>
                                    </div>
                                </div> */}
                                <div className="mt-20">
                                    {isLoading ? (
                                        <BtnSpinner cls="ad-theme-btn w-100 d-block text-center" />
                                    ) : (
                                        <button
                                            type="submit"
                                            className="ad-theme-btn w-100 d-block text-center"
                                        >
                                            Continue
                                        </button>
                                    )}
                                </div>
                            </Form>
                        </Formik>
                        <div className="mt-4 text-center">
                            <div className="ad-link">
                                Back to?{' '}
                                <Link to="/signin" className="ad-theme-text">
                                    Sign In
                                </Link>
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <div className="ad-link">
                                Don’t have an account?{' '}
                                <Link to="/signup" className="ad-theme-text">
                                    Create account
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ad-form-footer">
                    <Link to="/terms-of-use" target="_blank">
                        Terms of Services
                    </Link>
                    <Link to="/privacy-policy" target="_blank">
                        Privacy Policy
                    </Link>
                </div>
            </div>
        </>
    )
}

export default ResetPasswordSend
