import { useField, Field } from 'formik'
import ValidationError from '../../Components/Errors/ValidationError'

const TextField = ({ label, spanlabel, ...props }) => {
    const [field, meta] = useField(props)

    const handleKeyPress = (e) => {
        if (e.which === 32 && !e.target.value.length) e.preventDefault()
    }

    return (
        <>
            <div className="form-group">
                {label ? (
                    <label
                        htmlFor={field.name}
                        className={props.asterisks || ''}
                    >
                        {label}
                        {spanlabel ? (
                            <span className={props.spanclass || ''}>
                                {spanlabel}
                            </span>
                        ) : (
                            ''
                        )}
                    </label>
                ) : (
                    ''
                )}
                <div className="position-relative">
                    <Field
                        className={props.class || `form-control`}
                        {...field}
                        {...props}
                        onKeyPress={(e) => handleKeyPress(e)}
                    />
                    {props.icon ? (
                        <span className={props.icon || ''}></span>
                    ) : (
                        ''
                    )}
                </div>
                <ValidationError name={field.name} />
            </div>
        </>
    )
}

export default TextField
