import { useState } from 'react'

const ReadMore = ({ children, character }) => {
    const text = children.props.children
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }
    let maxChr = character || 125
    let textLength = text.length
    return (
        <p className="text">
            {isReadMore ? text.slice(0, maxChr) : text}
            {maxChr < textLength ? (
                <span onClick={toggleReadMore} className="read-or-hide">
                    {isReadMore ? '... Read more' : ' Less'}
                </span>
            ) : (
                ''
            )}
        </p>
    )
}

export default ReadMore
