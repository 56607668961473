import { useField, Field } from 'formik'
import ValidationError from '../../Components/Errors/ValidationError'

const NumberField = ({ label, spanlabel, ...props }) => {
    const [field, meta] = useField(props)

    const handleKeyPress = (e) => {
        let keyCode = e.which ? e.which : e.keyCode

        // first character 0 not allow
        if (e.which === 48 && !e.target.value.length) e.preventDefault()
        if (!(keyCode >= 48 && keyCode <= 57)) {
            e.preventDefault()
            return false
        }
    }

    return (
        <>
            <div className="form-group ">
                {label ? (
                    <label
                        htmlFor={field.name}
                        className={props.asterisks || ''}
                    >
                        {label}
                        {spanlabel ? (
                            <span className={props.spanclass || ''}>
                                {spanlabel}
                            </span>
                        ) : (
                            ''
                        )}
                    </label>
                ) : (
                    ''
                )}
                <Field
                    className={props.class || `form-control`}
                    {...field}
                    {...props}
                    pattern="[0-9]*"
                    onKeyPress={(e) => handleKeyPress(e)}
                />
                <ValidationError name={field.name} />
            </div>
        </>
    )
}

export default NumberField
