import { Formik, Form, Field } from 'formik'
import FormikDatePicker from '../../Components/Form/FormikDatePicker'
import ExperienceSelectField from '../../Components/Hiring/HireTalent/ExperienceSelectField'
import { Link } from 'react-router-dom'
const ProjectInvoiceSelect = ({ setSelectInvoiceProject }) => {
    const projectNameData = [
        { key: '1', value: 'Keeep' },
        { key: '2', value: 'Land Deal' },
        { key: '3', value: 'Adobe' },
        { key: '4', value: 'Atlassian' },
        { key: '4', value: 'Freshworks' },
        { key: '4', value: 'Microsoft' },
    ]
    const initialval = {
        project_name: '',
        month: '',
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialval}
                //initialValues=""
                onSubmit={(values) => {
                    // data send serve side
                    console.log(values)
                }}
            >
                {(formik) => (
                    <Form autoComplete="off">
                        <div className="modal-header">
                            <h5 className="modal-title" id="commonSMModalLabel">
                                Search Billing
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setSelectInvoiceProject(false)
                                }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-form ad-custom-form ad-form-theme2">
                                <div className="form-group">
                                    <div className="position-relative">
                                        <label className="label-pos">
                                            Search by project name
                                        </label>
                                        <Field
                                            as="select"
                                            className="form-control"
                                            name="project_name"
                                            data={projectNameData}
                                            component={ExperienceSelectField}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="position-relative dash-calendar-icon mod-dash-calendar">
                                        <label className="label-pos">
                                            Search by month
                                        </label>
                                        <Field
                                            // minD={newContractDt}
                                            name="month"
                                            placeholder="month"
                                            component={FormikDatePicker}
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="position-relative text-end">
                                        <button className="ad-trans-gray-btn ps-5 pe-5 me-3">
                                            Cancel
                                        </button>
                                        {/* <button className='ad-theme-btn ps-5 pe-5' type='submit'>Create</button> */}
                                        <Link
                                            to="/dashboard/billings/project-billing/create"
                                            className="ad-theme-btn ps-5 pe-5 d-inline-block"
                                        >
                                            Create
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ProjectInvoiceSelect
