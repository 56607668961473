import React, { useState, useEffect } from 'react'
import notifiction from '../../../assets/dashboard/img/notification-icon.svg'
import avtar from '../../../assets/dashboard/img/avatar_Kopie.png'
import { NavLink, useNavigate } from 'react-router-dom'
import AuthUser from '../../../Services/AuthUser'
import { useDispatch, useSelector } from 'react-redux'
import { setAvatarLogoUrl } from '../../../Redux/SliceReducers/CommonData'
import { Avatar, Box, useTheme } from '@mui/material'
import AgencyModal from '../../../Components/Modals/AgencyModal'
import ChangePasswordModal from './ChangePasswordModal'

const HeaderAction = () => {
    const { http } = AuthUser()
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const avatarLogoUrl = useSelector(
        (state) => state.commonData.value.avatarLogoUrl
    )

    const [changePasswordModal, setChangePasswordModal] = useState(false)

    let user = localStorage.getItem('user')
    user = user ? JSON.parse(user) : {}

    const { first_name = '', last_name = '', avatar = '' } = user
    const fLetter = first_name.charAt(0).toUpperCase()
    const lLetter = last_name.charAt(0).toUpperCase()
    const shortName = fLetter + lLetter || ''

    // Logout handler
    const handleUserLogout = () => {
        http.post(`/sign-out`).then((res) => {
            localStorage.removeItem('token')
            localStorage.removeItem('mydata')
            localStorage.removeItem('user')
            localStorage.removeItem('hiringTalent')
            navigate('/', { replace: true })
        })
    }

    useEffect(() => {
        dispatch(setAvatarLogoUrl(avatar))
    }, [avatar, dispatch])

    const openChangePasswordModal = () => {
        setChangePasswordModal(true)
    }

    const closeChangePasswordModal = () => {
        setChangePasswordModal(false)
    }

    return (
        <>
            {/* <a href="#" className="notification-icon">
        <span className="position-relative">
          <img src={notifiction} alt="notifiction" />
          <span className="noti-red-circle"></span>
        </span>
      </a> */}
            <div className="dropdown custom-dropdown user-dropdown ml-10">
                <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <Box
                        component="span"
                        sx={{
                            ...(!avatarLogoUrl
                                ? {
                                      backgroundColor:
                                          theme.palette.common.white,
                                      color: theme.palette.primary.main,
                                  }
                                : {}),
                        }}
                        className="usershort-circle"
                    >
                        {avatar || avatarLogoUrl ? (
                            <Avatar
                                alt={shortName}
                                src={avatarLogoUrl}
                                sx={{
                                    '&.MuiAvatar-root': {
                                        '& .MuiAvatar-img': {
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            width: 'auto',
                                            height: 'auto',
                                            borderRadius: 0,
                                        },
                                    },
                                }}
                            />
                        ) : (
                            shortName
                        )}
                    </Box>
                </button>
                <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                >
                    {user.is_invite_client != 'true' &&
                        !user.is_invite_team_member && (
                            <li>
                                <NavLink
                                    className="dropdown-item"
                                    to="/dashboard/profile"
                                >
                                    Profile
                                </NavLink>
                            </li>
                        )}
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={openChangePasswordModal}
                        >
                            Change Password
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={handleUserLogout}
                        >
                            Logout
                        </button>
                    </li>
                </ul>
            </div>
            {changePasswordModal && (
                <AgencyModal open={changePasswordModal}>
                    <ChangePasswordModal
                        closeChangePasswordModal={closeChangePasswordModal}
                    />
                </AgencyModal>
            )}
        </>
    )
}

export default HeaderAction
