import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import ValidationError from '../../../Components/Errors/ValidationError'
import { toast } from 'react-toastify'
import AuthUser from '../../../Services/AuthUser'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import { IconButton, Box, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/agency/img/close-icon-dynamic.svg'

const validationSchemaTeam = yup.object({
    email: yup.string().required('This field is required!'),
    url: yup.string().url().nullable().required('This field is required!'),
    access_token: yup.string().required('This field is required!'),
    key: yup.string().required('This field is required!'),
})
const JiraConnectModal = ({ setReload, setJiraConnectModal, projectId }) => {
    console.log(projectId)
    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    const updateJiraDetails = (fields) => {
        try {
            setIsLoading(true)
            http2
                .post(`/add-jira-credential-in-project/${projectId}`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setIsLoading(false)
                        setJiraConnectModal(false)
                        setReload(true)
                    }
                    if (result.success === false) {
                        toast.error(result.data)
                        setIsLoading(false)
                        setJiraConnectModal(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.message)
                        setJiraConnectModal(false)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: '',
                    url: '',
                    access_token: '',
                    key: '',
                }}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm }) => {
                    console.log(values)
                    updateJiraDetails(values)
                    resetForm()
                }}
            >
                {({ values, setFieldValue, field, form, formik }) => (
                    <>
                        {/* <div className="modal-header">
              <h5 className="modal-title" id="commonSMModalLabel">
                Connect With Jira
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setJiraConnectModal(false);
                }}
              ></button>
            </div> */}

                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Connect With Jira
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setJiraConnectModal(false)
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Jira Email ID*
                                            </label>
                                            <Field
                                                type="email"
                                                name="email"
                                                className="form-control"
                                            />
                                            <ValidationError name="email" />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Jira Host URL*
                                            </label>
                                            <Field
                                                type="text"
                                                name="url"
                                                className="form-control"
                                                placeholder="https://example.atlassian.net"
                                            />
                                            <ValidationError name="url" />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Jira Access Token*
                                            </label>
                                            <Field
                                                type="text"
                                                name="access_token"
                                                className="form-control"
                                            />
                                            <ValidationError name="access_token" />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Project Key*
                                            </label>
                                            <Field
                                                type="text"
                                                name="key"
                                                className="form-control"
                                            />
                                            <ValidationError name="key" />
                                        </div>
                                    </div>

                                    <div className="mt-2 col-12">
                                        {isLoading ? (
                                            <BtnSpinner />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="ad-theme-btn w-100"
                                            >
                                                Configure Jira
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default JiraConnectModal
