import Layout from '../Layout'
import rightArrow from '../../../assets/agency/img/purple-right.svg'
import { Link, useNavigate } from 'react-router-dom'
import AuthUser from '../../../Services/AuthUser'
import { useState } from 'react'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import AgencyModal from '../../../Components/Modals/AgencyModal'
import questionIcon from '../../../assets/agency/img/question-icon.svg'
import { toast, ToastContainer } from 'react-toastify'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import AgencySmModal from '../../../Components/Modals/AgencySmModal'
import BillingSubscriptionHeader from '../../../Components/BillingSubscription/BillingSubscriptionHeader'
import { Box, useTheme } from '@mui/material'
import { InrSeprator, UsdCadSeprator } from '../../../Config/Helper'

const BillingSubscription = () => {
    const { http2 } = AuthUser()
    const theme = useTheme()
    let user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    // Get Subscription list
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [subscriptionList, setSubscriptionList] = useState([])
    const [currentSubscription, setCurrentSubscription] = useState()
    const getSubscriptionTeam = () => {
        setIsLoading(true)
        http2.get(`/subscription-plan/team-members-list`).then((res) => {
            let result = res.data
            setSubscriptionList(result.data.teams)
            setCurrentSubscription(result.data.currect_subscription)
            setIsLoading(false)
        })
    }
    // Revoke License Modal
    const [isRevokeLoading, setIsRevokeLoading] = useState(false)
    const [revokeLicenseModal, setRevokeLicenseModal] = useState(false)
    const [revokeLicenseDtl, setRevokeLicenseDtl] = useState()
    const openRevokeLicenseModal = (revokeDtl) => {
        setRevokeLicenseModal(true)
        setRevokeLicenseDtl(revokeDtl)
    }
    const closeRevokeModal = () => {
        setRevokeLicenseModal(false)
    }
    const RevokeLicense = () => {
        setIsRevokeLoading(true)
        try {
            http2
                .post(`/revoke-license/${revokeLicenseDtl.id}`)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setIsRevokeLoading(false)
                        setRevokeLicenseModal(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                        setIsRevokeLoading(false)
                        setRevokeLicenseModal(false)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Grant site access Modal
    const [isGrantLoading, setIsGrantLoading] = useState(false)
    const [grantLicenseModal, setGrantLicenseModal] = useState(false)
    const [grantLicenseDtl, setGrantLicenseDtl] = useState()
    const openGrantLicenseModal = (grantDtl) => {
        setGrantLicenseModal(true)
        setGrantLicenseDtl(grantDtl)
    }
    const closeGrantModal = () => {
        setGrantLicenseModal(false)
    }
    const GrantLicense = () => {
        setIsGrantLoading(true)
        try {
            http2
                .post(`/grant-license/${grantLicenseDtl.id}`)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setIsGrantLoading(false)
                        setGrantLicenseModal(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                        setIsGrantLoading(false)
                        setGrantLicenseModal(false)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (user.is_invite_client == 'true') {
            navigate('/dashboard/settings/marketplace-billing')
        }
        getSubscriptionTeam()
        setReload()
    }, [reload])

    console.log(
        currentSubscription?.currency_symbol,
        'currentSubscription?.currency_symbolcurrentSubscription?.currency_symbol'
    )
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <Layout>
                <BillingSubscriptionHeader
                    currentSubscription={currentSubscription}
                />
                <div className="ad-white-card">
                    <div className="bs-scroll-y">
                        <div className="bs-scroll-width">
                            <div className="ad-white-header p-20">
                                {isLoading ? (
                                    <Skeleton
                                        containerClassName="cs-skeleton"
                                        count={1}
                                        height={80}
                                    />
                                ) : (
                                    <table className="w-100">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className="ad-st-count">
                                                            <div className="ad-st-title1">
                                                                {currentSubscription
                                                                    ? currentSubscription?.total_no_of_licences
                                                                    : '10'}
                                                            </div>
                                                            <div className="ad-st-title2">
                                                                Users
                                                            </div>
                                                        </div>
                                                        <div className="dash-hc-info">
                                                            <div className="dash-md-title dash-black-text fw-600 ad-font-1-2em">
                                                                {currentSubscription
                                                                    ? currentSubscription?.plan_name
                                                                    : 'Small Team'}
                                                            </div>
                                                            {/* <div className="dash-md-title text-wrap dash-font-0-9em mt-1">Unlimited projects tracking</div> */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="adwh-label">
                                                        Licenses
                                                    </div>
                                                    <div className="adwh-value mt-1">
                                                        {currentSubscription
                                                            ? currentSubscription?.total_no_of_licences
                                                            : '10'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="adwh-label">
                                                        Used Licenses
                                                    </div>
                                                    <div className="adwh-value mt-1">
                                                        {currentSubscription
                                                            ? currentSubscription?.used_licenses
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="adwh-label">
                                                        Next Billing Date
                                                    </div>
                                                    <div className="adwh-value mt-1">
                                                        {currentSubscription
                                                            ? currentSubscription?.end_date
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="adwh-label">
                                                        Annual Billing
                                                    </div>
                                                    <div className="adwh-value mt-1">
                                                        <span className="font-arial fw-500">
                                                            {currentSubscription?.currency_symbol
                                                                ? currentSubscription?.currency_symbol
                                                                : ''}
                                                        </span>

                                                        {currentSubscription?.estimated_billing &&
                                                        currentSubscription?.currency_symbol ===
                                                            '₹'
                                                            ? InrSeprator(
                                                                  Math.round(
                                                                      currentSubscription?.estimated_billing
                                                                  )
                                                              )
                                                            : UsdCadSeprator(
                                                                  Math.round(
                                                                      currentSubscription?.estimated_billing
                                                                  )
                                                              )}
                                                    </div>
                                                </td>
                                                <td>
                                                    {currentSubscription?.estimated_billing ? (
                                                        <Link
                                                            to="/dashboard/settings/billing-subscription/invoice-history"
                                                            className="ad-purple-text dash-font-0-9em"
                                                        >
                                                            Invoice History{' '}
                                                            <img
                                                                src={rightArrow}
                                                                alt="right"
                                                            />
                                                        </Link>
                                                    ) : (
                                                        ''
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div className="ad-white-body p-20">
                                {subscriptionList?.length > 0 ? (
                                    <div className="ad-custom-table">
                                        <table className="w-100">
                                            <thead>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <Skeleton
                                                                containerClassName="cs-skeleton"
                                                                count={1}
                                                                height={60}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <th>Sr. No.</th>
                                                        <th>Team Members</th>
                                                        <th>Last Activity</th>
                                                        <th
                                                            style={{
                                                                width: '180px',
                                                            }}
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                )}
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <Skeleton
                                                                containerClassName="cs-skeleton"
                                                                count={5}
                                                                height={65}
                                                            />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    subscriptionList &&
                                                    subscriptionList.map(
                                                        (item, index) => (
                                                            <tr key={item.id}>
                                                                <td
                                                                    className="dash-md-title dash-black-text fw-600 ad-font-1em"
                                                                    style={{
                                                                        width: '70px',
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        width: '42%',
                                                                    }}
                                                                >
                                                                    <div className="d-flex align-items-center adts-col-info w-100">
                                                                        <Box
                                                                            className="dash-hc-img ad-sm-hc ad-w36"
                                                                            sx={{
                                                                                background:
                                                                                    item?.avatar_with_url
                                                                                        ? theme
                                                                                              .palette
                                                                                              .common
                                                                                              .white
                                                                                        : item.color_code,
                                                                                border: `1px solid ${item.color_code}`,
                                                                            }}
                                                                        >
                                                                            {item.avatar_with_url ? (
                                                                                <img
                                                                                    src={
                                                                                        item.avatar_with_url
                                                                                    }
                                                                                    alt={
                                                                                        item.first_name
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <span className="talent-short-name dash-font-1-1em">
                                                                                    {
                                                                                        item.short_name
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </Box>
                                                                        {/* <div className="dash-hc-img ad-sm-hc ad-w36" style={{ background: "#C2A1A1" }}><span className="talent-short-name dash-font-1-1em">DC</span></div> */}
                                                                        <div className="dash-hc-info">
                                                                            <div className="dash-md-title dash-black-text fw-600 ad-font-1-2em">
                                                                                {
                                                                                    item.full_name
                                                                                }
                                                                            </div>
                                                                            <div className="dash-md-title text-wrap ad-font-1em">
                                                                                {
                                                                                    item.email
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        item.updated_at
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {!item.revoke_license ? (
                                                                        <button
                                                                            className="revoke-license-btn ad-red-text fw-600"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                openRevokeLicenseModal(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            Revoke
                                                                            License
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className="revoke-license-btn ad-purple-text fw-600"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                openGrantLicenseModal(
                                                                                    item
                                                                                )
                                                                            }
                                                                        >
                                                                            Grant
                                                                            Site
                                                                            Access
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            {/* Revoke License Modal */}
            <AgencySmModal open={revokeLicenseModal} close={closeRevokeModal}>
                <div className="text-center">
                    <img src={questionIcon} alt="question" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Are You Sure?
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        Do you really want to revoke{' '}
                        <strong>{revokeLicenseDtl?.full_name}</strong> License?
                    </p>
                </div>
                <div className="mt-3 text-center row">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={closeRevokeModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        {isRevokeLoading ? (
                            <BtnSpinner
                                cls="ad-theme-btn ad-sm-btn w-100"
                                loader="true"
                            />
                        ) : (
                            <button
                                type="button"
                                className="ad-theme-btn ad-sm-btn w-100"
                                onClick={RevokeLicense}
                            >
                                Yes
                            </button>
                        )}
                    </div>
                </div>
            </AgencySmModal>
            {/* Grant License Modal */}
            <AgencySmModal open={grantLicenseModal} close={closeGrantModal}>
                <div className="text-center">
                    <img src={questionIcon} alt="question" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Are You Sure?
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        Do you want to grant{' '}
                        <strong>{grantLicenseDtl?.full_name}</strong> a license?
                    </p>
                </div>
                <div className="mt-3 text-center row">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={closeGrantModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        {isGrantLoading ? (
                            <BtnSpinner
                                cls="ad-theme-btn ad-sm-btn w-100"
                                loader="true"
                            />
                        ) : (
                            <button
                                type="button"
                                className="ad-theme-btn ad-sm-btn w-100"
                                onClick={GrantLicense}
                            >
                                Yes
                            </button>
                        )}
                    </div>
                </div>
            </AgencySmModal>
        </>
    )
}

export default BillingSubscription
