import Layout from '../../Layouts/Layout'

const PrivacyPolicy = () => {
    return (
        <>
            <Layout>
                <div className="container">
                    <div className="common-content">
                        <div className="main-heading text-center">
                            Privacy Policy
                        </div>
                        <h3>Our Policy</h3>
                        <p>
                            Welcome to the web site (the “Site”) of Weteams, a
                            Weteams Private Limited product (“Weteams”, “we”,
                            “us” and/or “our”). This Site is operated by Weteams
                            and has been created to provide information about
                            our company and our software-as-a-service platform
                            for hiring software developers and managing software
                            development projects, mobile applications and
                            related services (together with the Site, the
                            “Services”) to our Service visitors (“you”, “your”).
                            This Privacy Policy sets forth Weteams’s policy with
                            respect to information including personally
                            identifiable data (“Personal Data”) and other
                            information that is collected from visitors to the
                            Site and Services.
                        </p>
                        <h3>Information We Collect</h3>
                        <p>
                            When you interact with us through the Services, we
                            may collect Personal Data and other information from
                            you, as further described below:
                        </p>
                        <p>
                            Personal Data That You Provide Through the Services:
                            We collect Personal Data from you when you
                            voluntarily provide such information, such as when
                            you contact us with inquiries, respond to one of our
                            surveys, register for access to the Services or use
                            certain Services. Wherever Weteams collects Personal
                            Data we make an effort to provide a link to this
                            Privacy Policy.
                        </p>
                        <p>
                            By voluntarily providing us with Personal Data, you
                            are consenting to our use of it in accordance with
                            this Privacy Policy. If you provide Personal Data to
                            the Services, you acknowledge and agree that such
                            Personal Data may be transferred from your current
                            location to the offices and servers of Weteams and
                            the authorized third parties referred to here in
                            located in India .
                        </p>
                        <h3>Other Information:</h3>
                        <p>
                            Non-Identifiable Data: When you interact with
                            Weteams through the Services, we receive and store
                            certain personally non-identifiable information.
                            Such information, which is collected passively using
                            various technologies, cannot presently be used to
                            specifically identify you. Weteams may store such
                            information itself or such information may be
                            included in databases owned and maintained by
                            Weteams affiliates, agents or service providers. The
                            Services may use such information and pool it with
                            other information to track, for example, the total
                            number of visitors to our Site, the number of
                            visitors to each page of our Site, and the domain
                            names of our visitors’ Internet service providers.
                            It is important to note that no Personal Data is
                            available or used in this process.
                        </p>
                        <p>
                            In operating the Services, we may use a technology
                            called “cookies.” A cookie is a piece of information
                            that the computer that hosts our Services gives to
                            your browser when you access the Services. Our
                            cookies help provide additional functionality to the
                            Services and help us analyze Services usage more
                            accurately. For instance, our Site may set a cookie
                            on your browser that allows you to access the
                            Services without needing to remember and then enter
                            a password more than once during a visit to the
                            Site. In all cases in which we use cookies, we will
                            not collect Personal Data except with your
                            permission. On most web browsers, you will find a
                            “help” section on the toolbar. Please refer to this
                            section for information on how to receive
                            notification when you are receiving a new cookie and
                            how to turn cookies off. We recommend that you leave
                            cookies turned on because they allow you to take
                            advantage of some of the Service features.
                        </p>
                        <p>
                            Aggregated Personal Data: In an ongoing effort to
                            better understand and serve the users of the
                            Services, Weteams often conducts research on its
                            customer demographics, interests and behavior based
                            on the Personal Data and other information provided
                            to us. This research may be compiled and analyzed on
                            an aggregate basis, and Weteams may share this
                            aggregate data with its affiliates, agents and
                            business partners. This aggregate information does
                            not identify you personally. Weteams may also
                            disclose aggregated user statistics in order to
                            describe our services to current and prospective
                            business partners, and to other third parties for
                            other lawful purposes.
                        </p>
                        <p>
                            Third Party Tracking and Analytics: We may allow
                            third party service providers to use cookies or
                            similar technologies to collect information about
                            your browsing activities over time and across
                            different websites following your use of the
                            Services. For example, we may use Google Analytics,
                            a web analytics service provided by Google, Inc.
                            (“Google”), and Mixpanel, a mobile and web analytics
                            platform provided by Mixpanel, Inc. (“Mixpanel”).
                            Google and Mixpanel use cookies or similar
                            technologies to help us analyze how users function
                            and use our mobile applications and the Site to
                            improve our Service. We may also implement Google
                            Analytics features based on Display Advertising
                            (which may include Remarketing with Google
                            Analytics). Visitors can opt out by using Google’s
                            Ads Settings or going to
                            tools.google.com/dlpage/gaoptout/. To opt-out of
                            Mixpanel’s collection of your data, go to
                            mixpanel.com/optout/. Please note that if you get a
                            new computer, install a new browser, erase or
                            otherwise alter your browser’s cookie file
                            (including upgrading certain browsers) you may also
                            clear the Mixpanel opt-out cookie.
                        </p>
                        <h3>
                            Our Use of Your Personal Data and Other Information
                        </h3>
                        <p>
                            Weteams uses the Personal Data you provide in a
                            manner that is consistent with this Privacy Policy.
                            If you provide Personal Data for a certain reason,
                            we may use the Personal Data in connection with the
                            reason for which it was provided. For instance, if
                            you contact us by email, we will use the Personal
                            Data you provide to answer your question or resolve
                            your problem. Also, if you provide Personal Data in
                            order to obtain access to the Services, we will use
                            your Personal Data to provide you with access to
                            such services and to monitor your use of such
                            services. Weteams and its subsidiaries and
                            affiliates (the “Related Companies”) may also use
                            your Personal Data and other personally
                            non-identifiable information collected through the
                            Services to help us improve the content and
                            functionality of the Services, to better understand
                            our users and to improve the Services. Weteams and
                            its affiliates may use this information to contact
                            you in the future to tell you about services we
                            believe will be of interest to you. If we do so,
                            each marketing communication we send you will
                            contain instructions permitting you to “opt-out” of
                            receiving future marketing communications. In
                            addition, if at any time you wish not to receive any
                            future marketing communications or you wish to have
                            your name deleted from our mailing lists, please
                            contact us as indicated below. If Weteams intends on
                            using any Personal Data in any manner that is not
                            consistent with this Privacy Policy, you will be
                            informed of such anticipated use prior to or at the
                            time at which the Personal Data is collected.
                        </p>
                        <h3>
                            Our Disclosure of Your Personal Data and Other
                            Information
                        </h3>
                        <p>
                            Weteams is not in the business of selling your
                            information. We consider this information to be a
                            vital part of our relationship with you. There are,
                            however, certain circumstances in which we may share
                            your Personal Data with certain third parties
                            without further notice to you, as set forth below:
                        </p>
                        <p>
                            Business Transfers: As we develop our business, we
                            might sell or buy businesses or assets. In the event
                            of a corporate sale, merger, reorganization,
                            dissolution or similar event, Personal Data may be
                            part of the transferred assets.
                        </p>
                        <p>
                            Related Companies: We may also share your Personal
                            Data with our Related Companies for purposes
                            consistent with this Privacy Policy.
                        </p>
                        <p>
                            Agents, Consultants and Related Third Parties:
                            Weteams, like many businesses, sometimes hires other
                            companies to perform certain business-related
                            functions. Examples of such functions include
                            mailing information, maintaining databases and
                            processing payments. When we employ another entity
                            to perform a function of this nature, we only
                            provide them with the information that they need to
                            perform their specific function.
                        </p>
                        <p>
                            Legal Requirements: Weteams may disclose your
                            Personal Data if required to do so by law or in the
                            good faith belief that such action is necessary to
                            (i) comply with a legal obligation, (ii) protect and
                            defend the rights or property of Weteams, (iii) act
                            in urgent circumstances to protect the personal
                            safety of users of the Services or the public, or
                            (iv) protect against legal liability.
                        </p>
                        <h3>Your Choices</h3>
                        <p>
                            You can visit the Site without providing any
                            Personal Data. If you choose not to provide any
                            Personal Data, you may not be able to use certain
                            Services.
                        </p>
                        <h3>Exclusions</h3>
                        <p>
                            This Privacy Policy does not apply to any Personal
                            Data collected by Weteams other than Personal Data
                            collected through the Services. This Privacy Policy
                            shall not apply to any unsolicited information you
                            provide to Weteams through the Services or through
                            any other means. This includes, but is not limited
                            to, information posted to any public areas of the
                            Services, such as forums, any ideas for new products
                            or modifications to existing products, and other
                            unsolicited submissions (collectively, “Unsolicited
                            Information”). All Unsolicited Information shall be
                            deemed to be non-confidential and Weteams shall be
                            free to reproduce, use, disclose, and distribute
                            such Unsolicited Information to others without
                            limitation or attribution.
                        </p>
                        <h3>Children</h3>
                        <p>
                            Weteams does not knowingly collect Personal Data
                            from children under the age of 13. If you are under
                            the age of 13, please do not submit any Personal
                            Data through the Services. We encourage parents and
                            legal guardians to monitor their children’s Internet
                            usage and to help enforce our Privacy Policy by
                            instructing their children never to provide Personal
                            Data on the Services without their permission. If
                            you have reason to believe that a child under the
                            age of 13 has provided Personal Data to Weteams
                            through the Services, please contact us, and we will
                            endeavor to delete that information from our
                            databases.
                        </p>
                        <h3>Links to Other Websites</h3>
                        <p>
                            This Privacy Policy applies only to the Services.
                            The Services may contain links to other web sites
                            not operated or controlled by Weteams (the “Third
                            Party Sites”). The policies and procedures we
                            described here do not apply to the Third Party
                            Sites. The links from the Services do not imply that
                            Weteams endorses or has reviewed the Third Party
                            Sites. We suggest contacting those sites directly
                            for information on their privacy policies.
                        </p>
                        <h3>Security</h3>
                        <p>
                            Weteams takes reasonable steps to protect the
                            Personal Data provided via the Services from loss,
                            misuse, and unauthorized access, disclosure,
                            alteration, or destruction. However, no Internet or
                            email transmission is ever fully secure or error
                            free. In particular, email sent to or from the
                            Services may not be secure. Therefore, you should
                            take special care in deciding what information you
                            send to us via email. Please keep this in mind when
                            disclosing any Personal Data to Weteams via the
                            Internet.
                        </p>
                        <h3>Other Terms and Conditions</h3>
                        <p>
                            Your access to and use of the Services is subject to
                            the Terms of Service at{' '}
                            <a href="https://www.weteams.io/terms-of-use/">
                                weteams.io/terms-of-use/
                            </a>
                            .
                        </p>
                        <h3>
                            Access to Information; Contacting Weteams Policy:
                        </h3>
                        <p>
                            To keep your Personal Data accurate, current, and
                            complete, please contact us as specified below. We
                            will take reasonable steps to update or correct
                            Personal Data in our possession that you have
                            previously submitted via the Services. Furthermore,
                            please contact us for any requests for a copy of
                            your user data or to deactivate your account.
                        </p>
                        <p>
                            Please also feel free to contact us if you have any
                            questions about Weteams’s Privacy Policy or the
                            information practices of the Services.
                        </p>
                        <p>
                            You may contact us as follows:&nbsp;
                            <a href="mailto:support@weteams.io">
                                support@weteams.io
                            </a>
                            .
                        </p>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default PrivacyPolicy
