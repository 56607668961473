import { Link } from 'react-router-dom'
import bannerImg from '../../assets/img/hm-banner-img.svg'
import AuthUser from '../../Services/AuthUser'

const HomeBanner = () => {
    const { token } = AuthUser()
    return (
        <>
            <section className="banner-wrap">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="banner-left w-50">
                            <div className="banner-title">
                                Scale your technology and digital teams within
                                weeks
                            </div>
                            <p className="mt-20 mb-40">
                                Weteams lets you build your digital solutions
                                with vetted experts and on-demand teams ensuring
                                success of every project.
                            </p>
                            <div className="mt-10">
                                {token ? (
                                    ''
                                ) : (
                                    <Link
                                        to="/signup"
                                        className="theme-btn mr-15 d-inline-block"
                                    >
                                        Sign Up for Free
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="banner-right w-50 text-end">
                            <div className="banner-img-box d-inline-block animate-this">
                                <img src={bannerImg} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBanner
