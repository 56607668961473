import csvimage from '../../assets/agency/img/csv-icon.svg'
import * as yup from 'yup'
import AuthUser from '../../Services/AuthUser'
import { useState } from 'react'
import axios from 'axios'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'
import BtnSpinner from '../../Components/Spinners/BtnSpinner'
import ValidationError from '../../Components/Errors/ValidationError'
import { useNavigate } from 'react-router-dom'
import apiCall from '../../Services/ApiInstance'
let base_URL = process.env.REACT_APP_base_URL

const validationSchemaTeam = yup.object({
    file: yup.string().required('File is required'),
})
const ImportCsvModal = ({
    openAddImportCsvModal,
    setOpenAddImportCsvModal,
    setReload,
}) => {
    const { token } = AuthUser()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [csvName, setCsvName] = useState('')
    const [inputKey, setInputKey] = useState(0)

    const handleOnChange = (event, f) => {
        const csv = event.target.files[0]

        console.log(csv)
        setCsvName(csv.name)
        f.setFieldValue('file', csv)
    }

    const addCsvFile = (fields) => {
        let data = new FormData()
        Object.keys(fields).forEach((key) => {
            data.append(key, fields[key])
        })

        try {
            setIsLoading(true)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/upload-csv`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setReload(true)
                        navigate('/dashboard/myteams')
                    }
                    setOpenAddImportCsvModal(false)
                    if (result.success == false) {
                        toast.error(result.message)
                    }
                    setIsLoading(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    console.log(error)
                    if (error.response.status) {
                        toast.error(err.message)
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setIsLoading(false)
        }
    }

    const closeCsvModal = () => {
        setCsvName('')
        setOpenAddImportCsvModal(false)
        setInputKey(inputKey + 1)
    }

    return (
        <>
            <div
                className={`modal fade ad-custom-modal ${openAddImportCsvModal ? 'show' : ''}`}
                id="commonSMModal"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
                style={{ display: openAddImportCsvModal ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="commonSMModalLabel">
                                Add Team
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeCsvModal}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="text-center">
                                <a
                                    href={`${base_URL}/api/v2/download-csv-format`}
                                    className="download-csv"
                                    target="_blank"
                                >
                                    <span className="download-icon"></span>{' '}
                                    Download .xlsx format
                                </a>
                            </div>
                            <Formik
                                initialValues={{
                                    file: '',
                                }}
                                validationSchema={validationSchemaTeam}
                                onSubmit={(values, { resetForm }) => {
                                    addCsvFile(values)
                                    resetForm()
                                    setCsvName('')
                                    setInputKey(inputKey + 1)
                                }}
                            >
                                {({ values, setFieldValue, field, form }) => (
                                    <Form
                                        autoComplete="off"
                                        encType="multipart/form-data"
                                    >
                                        <div className="mt-4 csv-upload-box text-center position-relative">
                                            <div>
                                                <img src={csvimage} alt="csv" />
                                            </div>
                                            <div className="mt-4 ad-sm-title">
                                                Select a .xlsx file to import
                                            </div>
                                            <div className="mt-2 ad-xs-title">
                                                Or drag and drop it here
                                            </div>

                                            <Field name="file">
                                                {({ field, form }) => (
                                                    <>
                                                        <input
                                                            key={inputKey}
                                                            type="file"
                                                            className="file-upload-input"
                                                            accept=".xlsx"
                                                            onChange={(event) =>
                                                                handleOnChange(
                                                                    event,
                                                                    form
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                            <ValidationError name="file" />
                                        </div>
                                        <div className="xs-title text-centrer mt-2">
                                            {csvName}
                                        </div>
                                        <div className="mt-4 text-center">
                                            {isLoading ? (
                                                <BtnSpinner
                                                    cls="ad-theme-btn w-100"
                                                    loader="true"
                                                />
                                            ) : (
                                                <button
                                                    className="ad-theme-btn w-100"
                                                    type="submit"
                                                >
                                                    Upload
                                                </button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImportCsvModal
