import { ErrorMessage, useField, Field } from 'formik'
import ValidationError from '../../Components/Errors/ValidationError'
import { useState } from 'react'
import { Box } from '@mui/material'

const PasswordField = ({ label, disablePaste, ...props }) => {
    const [field, meta] = useField(props)

    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
        setIsPasswordShown((isPasswordShown) => !isPasswordShown)
    }

    const handleKeyPress = (e) => {
        if (e.which === 32) e.preventDefault()
    }

    return (
        <>
            <Box
                {...(props?.formRelCont && { position: 'relative' })}
                className="form-group"
            >
                {label ? (
                    <label
                        htmlFor={field.name}
                        className={props.asterisks || ''}
                    >
                        {label}
                    </label>
                ) : (
                    ''
                )}
                <div className="position-relative">
                    {disablePaste ? (
                        <input
                            className={props.class || `form-control`}
                            type={isPasswordShown ? 'text' : 'password'}
                            onPaste={(e) => e.preventDefault()}
                            {...field}
                            {...props}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                    ) : (
                        <Field
                            className={props.class || `form-control`}
                            type={isPasswordShown ? 'text' : 'password'}
                            {...field}
                            {...props}
                            onKeyPress={(e) => handleKeyPress(e)}
                        />
                    )}
                    {props.icon ? (
                        <span className={props.icon || ''}></span>
                    ) : (
                        ''
                    )}

                    <span
                        className={
                            isPasswordShown ? 'eye-icon' : 'eye-icon closed'
                        }
                        onClick={togglePasswordVisiblity}
                    ></span>
                </div>
                <ValidationError name={field.name} />
            </Box>
        </>
    )
}

export default PasswordField
