import React, { useEffect, useRef, useState } from 'react'
import Currency from '../Currency/Currency'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Formik, Form, Field } from 'formik'
import FormikDatePicker from '../Form/FormikDatePicker'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import AuthUser from '../../Services/AuthUser'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import BtnSpinner from '../Spinners/BtnSpinner'
import SassCurrency from '../Currency/SassCurrency'
import infoIcon from '../../assets/agency/img/information-button.png'
import apiCall from '../../Services/ApiInstance'
import { Box, Typography } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon-dynamic.svg'
import { IconButton, SvgIcon } from '@mui/material'
const animatedComponents = makeAnimated()
let base_URL = process.env.REACT_APP_base_URL
const validationSchemaTeam = yup.object({
    team_member_id: yup.string().required('This field is required!'),
    role: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    reserved_hours: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
    client_hourly_rate: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
})
const AddTeamMemberModal = ({
    close,
    reload,
    setReload,
    setModalOpen,
    assignedTeamMemberIds,
    projectCurrency,
}) => {
    const { http2, token } = AuthUser()
    const { id } = useParams()
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { currency } = user

    let teamMembersForProject
    //get-team-members
    const selectRef = useRef(null)
    const [getTeamItem, setGetTeamItem] = useState([])
    const getTeam = () => {
        // setIsLoading(true);
        http2.get(`/get-team-members`).then((res) => {
            let result = res.data.data
            setGetTeamItem(typeof result != 'string' ? result : [])
        })
    }
    let defaultProjectCurrency = projectCurrency ? projectCurrency : 'USD'
    const [isTeamLoading, setIsTeamLoading] = useState(false)

    // Filter data according to team member
    const [selectedValue, setSelectedValue] = useState('')
    const [teamMembers, setTeamMembers] = useState()
    const handleSelectChange = (event) => {
        const newValue = event.target.value
        setSelectedValue(newValue)
        //   setTeamMembers(getTeamItem)
        const filterTeamMember = getTeamItem.filter(
            (getTeamItem) => getTeamItem.full_name == newValue
        )
        setTeamMembers(filterTeamMember[0])
        setReload()
    }

    const addTeamValues = (fields) => {
        let data = new FormData()
        Object.keys(fields).forEach((key) => {
            if (key === 'hide_avatar_toggle') {
                data.append('is_hide_picture', String(fields[key]))
            } else if (key === 'add_asteam_lead') {
                if (fields[key] == true) {
                    data.append(
                        'add_asteam_lead',
                        fields[key] == true ? 'on' : 'off'
                    )
                }
            } else {
                data.append(key, fields[key])
            }
        })
        try {
            setIsTeamLoading(true)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/add-team-member/${id}`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message, {
                            onOpen: () => {
                                setReload(false)
                            },
                        })
                    }
                    setModalOpen(false)
                    if (result.status == false) {
                        toast.error(result.message)
                    }
                    if (result.success == false) {
                        toast.error(result.message)
                    }
                    setIsTeamLoading(false)
                    selectRef?.current?.clearValue()
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status) {
                        toast.error(err.message)
                        setIsTeamLoading(false)
                    }
                    setIsTeamLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            setIsTeamLoading(false)
        }
    }
    const initialValues = {
        team_member_id: '',
        role: '',
        add_asteam_lead: '',
        internal_rate: '',
        reserved_hours: '',
        client_hourly_rate: '',
        my_currency_internal_rate: '',
        my_currency_client_hourly_rate: '',
        hide_avatar_toggle: false,
    }
    useEffect(() => {
        getTeam()
    }, [reload])

    teamMembersForProject = getTeamItem
        ? getTeamItem.filter((team) => !assignedTeamMemberIds.includes(team.id))
        : getTeamItem

    const handleAlphaKeyPress = (e) => {
        let code = 'charCode' in e ? e.charCode : e.keyCode
        if (
            !(code === 32) && // space
            !(code >= 65 && code <= 90) && // upper alpha (A-Z)
            !(code >= 97 && code <= 122) // lower alpha (a-z)
        ) {
            e.preventDefault()
        }
    }
    const getExchangeRate = (fromCurrency, toCurrency) => {
        const exchangeRates = {
            USD: {
                CAD: 1.3,
                INR: 80,
            },
            INR: {
                USD: 1 / 80,
                CAD: 1.3 / 80,
            },
            CAD: {
                USD: 1 / 1.3,
                INR: (1 / 1.3) * 80,
            },
        }
        return exchangeRates[fromCurrency]?.[toCurrency] ?? null
    }

    const handleInputClientHourlyRate = (e, clientHourlyRate, f) => {
        const { name, value } = e.target
        let tentativeAmount
        if (clientHourlyRate != currency) {
            const exchangeRate = getExchangeRate(clientHourlyRate, currency)
            const convertedAmount = value * exchangeRate
            tentativeAmount = convertedAmount?.toFixed(2)
        } else {
            tentativeAmount = value
        }
        f.setFieldValue(`client_hourly_rate`, value)
        f.setFieldValue(`my_currency_client_hourly_rate`, tentativeAmount)
    }

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm }) => {
                    console.log(values)
                    addTeamValues(values)
                    resetForm()
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <>
                        {/* <div className="modal-header">
                            <h5 className="modal-title" id="commonSMModalLabel">Add Team Member</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    setModalOpen(false)
                                    resetForm({ values: initialValues });
                                }}></button>
                        </div> */}

                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Add Team Member
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setModalOpen(false)
                                        resetForm({ values: initialValues })
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Name*
                                            </label>
                                            <select
                                                value={values.team_member_id}
                                                onChange={(e) => {
                                                    console.log(e.target.value)

                                                    const filterTeamMember =
                                                        getTeamItem.filter(
                                                            (getTeamItem) =>
                                                                getTeamItem.id ==
                                                                e.target.value
                                                        )
                                                    setFieldValue(
                                                        'role',
                                                        filterTeamMember[0].role
                                                    )
                                                    setFieldValue(
                                                        'add_asteam_lead',
                                                        filterTeamMember[0]
                                                            .add_asteam_lead
                                                    )
                                                    setFieldValue(
                                                        'internal_rate',
                                                        filterTeamMember[0]
                                                            ?.is_outsource_talent
                                                            ? filterTeamMember[0]
                                                                  ?.currency_rate[
                                                                  defaultProjectCurrency
                                                              ] || ''
                                                            : filterTeamMember[0]
                                                                  ?.internal_rate_currencies[
                                                                  defaultProjectCurrency
                                                              ] || ''
                                                    )
                                                    setFieldValue(
                                                        'my_currency_internal_rate',
                                                        filterTeamMember[0]
                                                            ?.is_outsource_talent
                                                            ? filterTeamMember[0]
                                                                  ?.currency_rate[
                                                                  currency
                                                              ] || ''
                                                            : filterTeamMember[0]
                                                                  ?.internal_rate_currencies[
                                                                  currency
                                                              ] || ''
                                                    )
                                                    setFieldValue(
                                                        'team_member_id',
                                                        e.target.value
                                                    )
                                                }}
                                                className="form-control"
                                                name="team_member_id"
                                            >
                                                <option value="">Select</option>
                                                {teamMembersForProject &&
                                                    teamMembersForProject.map(
                                                        (team) => (
                                                            <option
                                                                key={team.id}
                                                                value={team.id}
                                                            >
                                                                {team.full_name}
                                                            </option>
                                                        )
                                                    )}
                                            </select>
                                        </div>
                                        <ValidationError name="team_member_id" />
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Role*
                                            </label>
                                            <Field
                                                type="text"
                                                name="role"
                                                className="form-control"
                                                onKeyPress={(e) =>
                                                    handleAlphaKeyPress(e)
                                                }
                                            />
                                        </div>
                                        {/* <ValidationError name="role" /> */}
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Internal Hourly Rate*
                                            </label>
                                            <div className="position-relative">
                                                {/* <Field
                                                    type="number"
                                                    name="internal_rate"
                                                    className="form-control pl-55"
                                                    readOnly
                                                /> */}

                                                <input
                                                    type="text"
                                                    name="internal_rate"
                                                    className="form-control pl-55"
                                                    value={
                                                        values.internal_rate ||
                                                        ''
                                                    }
                                                    readOnly
                                                />
                                                <span className="currency-sign">
                                                    <SassCurrency
                                                        pCurrency={
                                                            projectCurrency
                                                        }
                                                    />
                                                </span>
                                                {/* <span className="ad-info-box currency-info-pos">
                                                    <img src={infoIcon} alt="info"/>
                                                    <div className="ad-info-pos"><SassCurrency pCurrency={currency} /> 
                                                        {values.my_currency_internal_rate}
                                                    </div>
                                                </span> */}
                                            </div>
                                            {projectCurrency != currency &&
                                                values.my_currency_internal_rate >
                                                    0 && (
                                                    <div className="base-price-tag">
                                                        <SassCurrency
                                                            pCurrency={currency}
                                                        />
                                                        {
                                                            values.my_currency_internal_rate
                                                        }
                                                    </div>
                                                )}
                                        </div>
                                        {/* <ValidationError name="internal_rate" /> */}
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Reserved Hours*
                                            </label>
                                            <Field
                                                type="number"
                                                name="reserved_hours"
                                                className="form-control"
                                            />
                                        </div>
                                        <ValidationError name="reserved_hours" />
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client Hourly Rate*
                                            </label>
                                            <div className="position-relative">
                                                <Field>
                                                    {({ field, form }) => (
                                                        <input
                                                            type="number"
                                                            name="client_hourly_rate"
                                                            className="form-control pl-55"
                                                            value={
                                                                values.client_hourly_rate
                                                            }
                                                            onChange={(e) =>
                                                                handleInputClientHourlyRate(
                                                                    e,
                                                                    projectCurrency,
                                                                    form
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                                <span className="currency-sign">
                                                    <SassCurrency
                                                        pCurrency={
                                                            projectCurrency
                                                        }
                                                    />
                                                </span>
                                                {/* <span className="ad-info-box currency-info-pos">
                                                    <img src={infoIcon} alt="info"/>
                                                    <div className="ad-info-pos"><SassCurrency pCurrency={currency} /> 
                                                        {values.my_currency_client_hourly_rate}
                                                    </div>
                                                </span> */}
                                            </div>
                                            {projectCurrency != currency &&
                                                values.my_currency_client_hourly_rate >
                                                    0 && (
                                                    <div className="base-price-tag">
                                                        <SassCurrency
                                                            pCurrency={currency}
                                                        />
                                                        {
                                                            values.my_currency_client_hourly_rate
                                                        }
                                                    </div>
                                                )}
                                        </div>
                                        <ValidationError name="client_hourly_rate" />
                                    </div>
                                    {/* { */}
                                    {/* teamMembers?.is_outsource_talent && */}

                                    <>
                                        {teamMembers?.is_outsource_talent && (
                                            <Box
                                                className="col-12 form-group"
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography variant="size3MediumTextText1">
                                                    Hide profile picture for the
                                                    client
                                                </Typography>
                                                <div className="notification-switch main-switch">
                                                    <label>
                                                        <Field
                                                            type="checkbox"
                                                            name="hide_avatar_toggle"
                                                        />
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                ...(!values.hide_avatar_toggle && {
                                                                    '&.slider':
                                                                        {
                                                                            backgroundColor:
                                                                                'teritiary.teritiary6',
                                                                            borderColor:
                                                                                'teritiary.teritiary6',
                                                                        },
                                                                }),
                                                            }}
                                                            className="slider"
                                                        ></Box>
                                                    </label>
                                                </div>
                                            </Box>
                                        )}
                                        <div className="col-12 form-group">
                                            <div className="custom-checkbox">
                                                <Field
                                                    className="styled-checkbox"
                                                    type="checkbox"
                                                    name="add_asteam_lead"
                                                    id="addAsTeamLead"
                                                />
                                                <Typography
                                                    component="label"
                                                    sx={{
                                                        '&.MuiTypography-root':
                                                            {
                                                                color: 'text.text1',
                                                                fontWeight: 500,
                                                                cursor: 'pointer',
                                                            },
                                                    }}
                                                    variant="size3MediumTextText1"
                                                    htmlFor="addAsTeamLead"
                                                >
                                                    Add as a Project Lead
                                                </Typography>
                                            </div>
                                        </div>
                                    </>
                                    {/* // } */}

                                    <div className="mt-2 col-12">
                                        {isTeamLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <button
                                                className="ad-theme-btn w-100"
                                                type="submit"
                                            >
                                                Add
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddTeamMemberModal
