import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Nocodelowcode = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/NoCodeLowCode.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    No Code/Low Code
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Developers that will simplify the way your
                                    apps are developed
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Low code/no-code platform enables the rapid
                                building, deployment, and operation of the apps
                                that make your business run. Weteams platform
                                will help you eliminate silos between IT and
                                development
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Web-Based Customer Portal
                                            </div>
                                            <p>
                                                A web-based portal is an
                                                excellent digital tool for
                                                delivering a self-service
                                                experience. Customers can
                                                execute common operations like
                                                getting quotes, paying bills,
                                                obtaining services, and more
                                                without actually speaking to you
                                                directly. Despite the benefits,
                                                many businesses have trouble
                                                launching a customer portal.
                                                <br />
                                                These problems are now being
                                                resolved by low-code, which
                                                enables business and IT
                                                developers to work together and
                                                create customer portals with
                                                consumer-grade user interfaces
                                                in a fraction of the time it
                                                takes with traditional
                                                development. Additionally,
                                                businesses can create the
                                                interface just once and utilize
                                                it across a number of platforms
                                                and devices.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                B2C Mobile App
                                            </div>
                                            <p>
                                                The customer experience can be
                                                significantly improved, and new
                                                business revenue can be added,
                                                by investing in innovative
                                                digital self-service solutions
                                                like mobile apps. The necessity
                                                to create versions for various
                                                mobile architectures and a lack
                                                of competent resources are
                                                frequently the reasons why
                                                businesses choose not to create
                                                mobile apps.
                                            </p>
                                            <p>
                                                Low/no code is making it simple
                                                for businesses to create
                                                functional mobile apps from a
                                                single platform, using their
                                                current staff. ReactNative is a
                                                mature low-code framework that
                                                helps developers create native
                                                apps for Android and iOS
                                                quickly.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Minimum Viable Products (MVPs)
                                            </div>
                                            <p>
                                                Developers are creating Minimum
                                                Viable Products (MVPs) quickly
                                                with low-code platforms while
                                                they explore new concepts more
                                                quickly and affordably. While
                                                writing little to no code,
                                                developers are taking advantage
                                                of technologies like IoT, AI,
                                                machine learning, and legacy
                                                systems.
                                            </p>
                                            <p>
                                                For instance, a healthcare
                                                company created an IoT app for
                                                tracking medications using a
                                                low-code development platform to
                                                take advantage of its expertise
                                                in the pharmaceutical supply
                                                chain. The supply chain origin
                                                and temperature of medications
                                                can be tracked and monitored
                                                using the app.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Nocodelowcode
