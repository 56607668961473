import React, { useEffect, useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import userCircleIcon from '../../assets/agency/img/user-circle-icon.svg'
import onboardingUploadIcon from '../../assets/agency/img/onboarding-upload.svg'
import BtnSpinner from '../Spinners/BtnSpinner'
import teamImg from '../../assets/agency/img/team-1.jpg'
import { useState } from 'react'
import Currency from '../Currency/Currency'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import PreviewImage from '../Form/PreviewImage'
import FormikDatePicker from '../Form/FormikDatePicker'
import axios from 'axios'
import AuthUser from '../../Services/AuthUser'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import SelectField from '../Form/SelectField'
import SelectCountry from '../Form/SelectCountry'
import SelectState from '../Form/SelectState'
import SelectCity from '../Form/SelectCity'
import apiCall from '../../Services/ApiInstance'
// import AddLeadModal from "./AddLeadModal";
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import InviteClient from '../../assets/dashboard/img/invite_client_mail.svg'

let base_URL = process.env.REACT_APP_base_URL
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import { utilities } from '../../Config/Helper'
import { Avatar, Box, Stack, useTheme } from '@mui/material'

const validationSchemaTeam = yup.object({
    client_poc_email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required!'),
})
const SendTeamMemberAccountInvite = ({
    setReload,
    reload,
    setModalOpen,
    sendInvite,
}) => {
    const { http2, token } = AuthUser()
    const navigate = useNavigate()
    const theme = useTheme()

    let user = localStorage.getItem('user')
    user = JSON.parse(user)

    const [addTeamOnProject, setAddTeamOnProject] = useState(false)
    const [isProjectLoading, setIsProjectLoading] = useState(false)

    const initialValues = {
        client_poc_email: sendInvite.data.email,
        client_name: sendInvite.data.name,
    }

    const sendInviteAction = (fields) => {
        console.log(fields)
        let data = new FormData()
        data.append(`talent_email`, fields.client_poc_email)
        data.append(`talent_name`, fields.client_name)

        try {
            setIsProjectLoading(true)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/send-team-member-invitation`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setModalOpen(false)
                        // setReload(false);
                        setIsProjectLoading(false)
                    }
                    if (result.success == false) {
                        toast.error(result.message)
                        setModalOpen(false)
                        setIsProjectLoading(false)
                    }
                    setModalOpen(false)
                    setIsProjectLoading(false)
                })
                .catch((error) => {
                    const err = error.response.data
                    if (error.response.status) {
                        toast.error(err.message)
                        // setIsTeamMemberLoading(false);
                    }
                    setIsProjectLoading(false)
                })
        } catch (error) {
            toast.error(error.response.data.message)
            console.log(error)
            setIsProjectLoading(false)
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    // addProjectValues(values);
                    // setImgAfterCrop("");
                    // resetForm();
                    // setProjectTeamList([]);
                    setAddTeamOnProject(false)
                    sendInviteAction(values)
                    // avatarFieldValue = ""
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <>
                        {/* {console.log(values.month_budget)} */}
                        <Box
                            component="button"
                            type="button"
                            className="btn-close"
                            sx={{
                                '&.MuiBox-root': {
                                    marginLeft: '590px',
                                    marginTop: '12px',
                                    padding: '15px',
                                    boxSizing: 'border-box',
                                },
                            }}
                            onClick={() => {
                                setModalOpen(false)
                            }}
                        ></Box>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="text-center">
                                    <img
                                        src={InviteClient}
                                        alt="Invite client"
                                    />
                                </div>
                                <div className="mt-3 ad-md-title text-center">
                                    Invite Team Member
                                </div>
                                <div className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                                    <div className="col-md-12 col-12 form-group mt-4">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Team member email*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="client_poc_email"
                                                disabled
                                            />
                                            <ValidationError name="client_poc_email" />
                                        </div>
                                    </div>

                                    <div className="mt-2 col-12">
                                        {isProjectLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <button
                                                className="ad-theme-btn w-100 ad-create-btn"
                                                type="submit"
                                            >
                                                Send Invite
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default SendTeamMemberAccountInvite
