import { useState, useEffect } from 'react'
import { useField } from 'formik'
import ValidationError from '../../Errors/ValidationError'

const DomainSearch = ({ options, optionKey, ...props }) => {
    const [field, form, helpers] = useField(props)
    const { setValue } = helpers

    const [searchText, setSearchText] = useState('')
    const [selected, setSelected] = useState(form.value || '')
    const [allOption, setAllOption] = useState([])

    // useEffect(() => {
    //     setAllOption(options);
    // }, [options]);

    const selectHandle = (val) => {
        setSearchText('')
        setSelected(val)
        //props.value("domain_name", val);
        setValue(val)
    }
    const handleChange = ({ target }) => {
        setValue(target.value)
        setSearchText(target.value)
        setSelected(target.value)
        let tempOptions = [...options]
        tempOptions = tempOptions.filter((obj) =>
            obj[optionKey]
                ?.toLowerCase()
                .startsWith(target.value?.toLowerCase())
        )
        setAllOption(tempOptions)
    }

    const handleFocus = () => {
        // document.querySelector('#my-role-search').style.display = 'none';
    }

    return (
        <>
            <div className="form-group ">
                <label htmlFor="domain_name">
                    Choose the Domain Expertise You Need
                    <span className="opt">(Optional)</span>
                </label>
                <div className="position-relative">
                    <input
                        name={field.name}
                        className="form-control text-capitalize"
                        spanclassName="opt"
                        asterisks="asterisks"
                        type="text"
                        placeholder="Search for Domain (e.g. Web3, IOT, Finance)"
                        value={selected || searchText}
                        //value={field.value}
                        onChange={handleChange}
                        onFocus={handleFocus}
                    />
                    <ValidationError name={field.name} />
                    <div
                        className="domain-search cs-dropdown-block"
                        id="domain-search"
                        style={{
                            display:
                                allOption.length && searchText
                                    ? 'flex'
                                    : 'none',
                        }}
                    >
                        {allOption.map((option, index) => (
                            <div
                                key={`${index}`}
                                onClick={() => selectHandle(option.value)}
                                className="autocomplete-items"
                            >
                                {option.value}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DomainSearch
