import React, { useEffect, useRef, useState } from 'react'
import Currency from '../Currency/Currency'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Formik, Form, Field } from 'formik'
import FormikDatePicker from '../Form/FormikDatePicker'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import AuthUser from '../../Services/AuthUser'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import BtnSpinner from '../Spinners/BtnSpinner'
import SassCurrency from '../Currency/SassCurrency'
import infoIcon from '../../assets/agency/img/information-button.png'
import apiCall from '../../Services/ApiInstance'
import { Box, Typography, useTheme } from '@mui/material'
const animatedComponents = makeAnimated()
let base_URL = process.env.REACT_APP_base_URL
const validationSchemaTeam = yup.object({
    // team_member_name: yup
    //     .string()
    //     .required("This field is required!"),
    role: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    reserved_hours: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
    client_hourly_rate: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
})
const EditTeamMemberModalNew = ({
    close,
    reload,
    setReload,
    setHideShowEditModal,
    assignedTeamMemberIds,
    projectCurrency,
    readyforupdate,
    projectDashboard,
}) => {
    const { http2, token } = AuthUser()
    const { id } = useParams()
    const theme = useTheme()
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { currency } = user
    const [isEditLoading, setIsEditLoading] = useState(false)

    const {
        id: team_member_id,
        avatar,
        short_name,
        color_code,
        full_name,
        role,
        experience,
        reserved_hours,
        project_details,
        rate_per_hour,
        is_hide_picture,
        add_asteam_lead,
        is_outsource_talent,
        projects,
    } = readyforupdate
    const initialValues = {
        role: role || '',
        team_member_id: team_member_id || '',
        reserved_hours:
            projects && projectDashboard?.project_name
                ? projects.filter(
                      (v) => v.project_name == projectDashboard.project_name
                  )[0]?.reserved_hours
                    ? projects.filter(
                          (v) => v.project_name == projectDashboard.project_name
                      )[0]?.reserved_hours
                    : reserved_hours
                      ? reserved_hours
                      : ''
                : reserved_hours
                  ? reserved_hours
                  : '',
        client_hourly_rate: rate_per_hour || '',
        hide_avatar_toggle: is_hide_picture == 'true' ? true : false,
        add_asteam_lead: add_asteam_lead || '',
    }

    function formatName(full_name) {
        const [firstName, lastName] = full_name.split(' ')

        const lastNameInitial = lastName.charAt(0) + '.'

        const formattedName = `${firstName} ${lastNameInitial}`

        return formattedName
    }

    const updateTeamMember = (fields) => {
        try {
            setIsEditLoading(true)
            fields.team_member_id = readyforupdate.id
            let data = new FormData()
            Object.keys(fields).forEach((key) => {
                if (key === 'hide_avatar_toggle') {
                    data.append('is_hide_picture', String(fields[key]))
                } else if (key === 'add_asteam_lead') {
                    if (fields[key] == true) {
                        data.append(
                            'add_asteam_lead',
                            fields[key] == true ? 'on' : 'off'
                        )
                    }
                } else {
                    data.append(key, fields[key])
                }
            })
            data.append('team_member_id', readyforupdate.id)
            apiCall({
                method: 'post',
                url: `${base_URL}/api/v2/edit-team-member/${id}`,
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: data,
            })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        // window.location.reload();
                        setReload(false)
                    }
                    if (result.success == false) {
                        toast.error('something went wrong')
                    }
                    setIsEditLoading(false)
                    setHideShowEditModal(false)
                })
                .catch((error) => {
                    const err = error.response.data

                    if (error.response.status) {
                        toast.error(err.message)
                    }
                    setIsEditLoading(false)
                })
        } catch (error) {
            toast.error('Oops something went wrong!')
            console.log(error)
            setIsEditLoading(false)
        }
    }

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm }) => {
                    console.log(values)
                    updateTeamMember(values)
                    // resetForm()
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <>
                        <div className="modal-header">
                            <div className="d-flex align-items-center">
                                <Box
                                    className="dash-hc-img ad-sm-hc"
                                    sx={{
                                        background: avatar
                                            ? theme.palette.common.white
                                            : color_code,
                                        border: `1px solid ${color_code}` /*boxShadow:`0px 0px 5px 0.5px ${theme.palette.text.text2}` */,
                                    }}
                                >
                                    {avatar ? (
                                        <img src={avatar} alt={name} />
                                    ) : (
                                        <span className="talent-short-name">
                                            {short_name ? short_name : '-'}
                                        </span>
                                    )}
                                    {/* <span className="dash-hc-play-icon"></span> */}
                                </Box>
                                <div className="dash-hc-info">
                                    <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">
                                        {full_name
                                            ? formatName(full_name)
                                            : '-'}
                                    </div>
                                    <div className="dash-md-title dash-font-0-8em text-wrap mt-0-5">
                                        {role ? role : '-'}{' '}
                                    </div>

                                    <div>
                                        <span className="ad-exp-custom">
                                            {experience ? experience : '-'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    setHideShowEditModal(false)
                                    resetForm({ values: initialValues })
                                }}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Role*
                                            </label>
                                            <Field
                                                type="text"
                                                name="role"
                                                className="form-control"
                                            />
                                        </div>
                                        <ValidationError name="role" />
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Reserved Hours*
                                            </label>
                                            <Field
                                                type="text"
                                                name="reserved_hours"
                                                className="form-control"
                                            />
                                        </div>
                                        <ValidationError name="reserved_hours" />
                                    </div>
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Hourly Rate*
                                            </label>
                                            <div className="position-relative">
                                                <Field
                                                    type="text"
                                                    name="client_hourly_rate"
                                                    className="form-control pl-55"
                                                />
                                                {project_details && (
                                                    <span className="currency-sign">
                                                        <SassCurrency
                                                            pCurrency={
                                                                projectCurrency ||
                                                                '-'
                                                            }
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <ValidationError name="client_hourly_rate" />
                                    </div>
                                    {/* {
                                        is_outsource_talent && */}
                                    <>
                                        {is_outsource_talent && (
                                            <Box
                                                className="col-12 form-group"
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography variant="size3MediumTextText1">
                                                    Hide profile picture for the
                                                    client
                                                </Typography>
                                                <div className="notification-switch main-switch">
                                                    <label>
                                                        <Field
                                                            type="checkbox"
                                                            name="hide_avatar_toggle"
                                                            id="hideAvatarToggle"
                                                        />
                                                        <Box
                                                            htmlFor="hideAvatarToggle"
                                                            component="label"
                                                            sx={{
                                                                ...(!values.hide_avatar_toggle && {
                                                                    '&.slider':
                                                                        {
                                                                            backgroundColor:
                                                                                'teritiary.teritiary6',
                                                                            borderColor:
                                                                                'teritiary.teritiary6',
                                                                        },
                                                                }),
                                                            }}
                                                            className="slider"
                                                        ></Box>
                                                    </label>
                                                </div>
                                            </Box>
                                        )}
                                        <div className="col-12 form-group">
                                            <div className="custom-checkbox">
                                                <Field
                                                    className="styled-checkbox"
                                                    type="checkbox"
                                                    name="add_asteam_lead"
                                                    id="addTeamLead"
                                                />
                                                <Typography
                                                    component="label"
                                                    sx={{
                                                        '&.MuiTypography-root':
                                                            {
                                                                color: 'text.text1',
                                                                fontWeight: 500,
                                                                cursor: 'pointer',
                                                            },
                                                    }}
                                                    variant="size3MediumTextText1"
                                                    htmlFor="addTeamLead"
                                                >
                                                    Add as a project lead
                                                </Typography>
                                            </div>
                                        </div>
                                    </>
                                    {/* } */}

                                    <div className="mt-2 col-12">
                                        {isEditLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <button
                                                className="ad-theme-btn w-100"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default EditTeamMemberModalNew
