import React, { useState, useEffect } from 'react'
import Layout from '../Layout'
import Skeleton from 'react-loading-skeleton'
import { toast, ToastContainer } from 'react-toastify'
import CollaboratorList from '../../../Components/Collaborator/CollaboratorList'
import CollabratiorItem from '../../../Components/Collaborator/CollabratiorItem'
import collNoDataImg from '../../../assets/dashboard/img/collaborator-bg.png'
import AddCollabratorModal from '../../../Components/Collaborator/AddCollabratorModal'
import DeleteCollabratorModal from '../../../Components/Collaborator/DeleteCollabratorModal'
import EditCollabratorModal from '../../../Components/Collaborator/EditCollabratorModal'
import AuthUser from '../../../Services/AuthUser'
import EditInvitedPeopleModal from '../../../Components/Collaborator/EditInvitedPeopleModal'

const Collaborator = () => {
    const [currentCard, setCurrentCard] = useState('all')
    const [isLoading, setIsLoading] = useState(false)

    const [mypeople, setMyPeople] = useState({})
    const [readyfordelete, setReadyForDelete] = useState({})
    const [readyforupdate, setReadyForUpdate] = useState({})
    const [resendLink, setResendLink] = useState({})

    // State to track the checked status of each permission
    const [checkedPermissions, setCheckedPermissions] = useState({})

    const { http } = AuthUser()
    // filter data store
    const [filterPeople, SetFilterPeople] = useState([])
    const [reload, setReload] = useState(false)

    const getMyPeople = () => {
        setIsLoading(true)
        http.get(`/list-of-people`).then((res) => {
            let result = res.data.data
            setMyPeople(result)
            SetFilterPeople(result)
            setIsLoading(false)
        })
    }

    // filter people list
    const handleFlButton = (e) => {
        let active_tab = e.target.value
        setCurrentCard(active_tab) // set filter status
        let filter_people = mypeople.filter((people) => {
            if (active_tab === 'All') {
                return mypeople
            }
            return people.confirmed === active_tab
        })
        SetFilterPeople(filter_people)
    }

    useEffect(() => {
        getMyPeople()
        setReload()
    }, [reload])

    const handleRemove = (index) => {
        const updatedItems = filterPeople.filter((curElem) => {
            return curElem.id !== index
        })
        SetFilterPeople(updatedItems)
    }
    const destroyPeople = (id) => {
        try {
            http.delete(`/people-destroy/${id}`)
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        toast.success(result.message)
                        setHideShowDeleteModal(false)
                        handleRemove(id)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    // Add Collabrator
    const [openAddCollabratorModal, setOpenAddCollabratorModal] =
        useState(false)
    const addCollaboratorModal = () => {
        setOpenAddCollabratorModal(true)
    }
    // Delete Collabrator
    const [openHideShowDeleteModal, setHideShowDeleteModal] = useState(false)
    const deleteCollabratorItem = (item) => {
        setReadyForDelete(item)
        setHideShowDeleteModal(true)
    }

    //Resend Link
    const resendPeopleInviteLink = (item) => {
        setResendLink(item)
        const { email, company_id } = item
        try {
            http.post(`/resend-people-invite-link`, {
                email: email,
                company_id: company_id,
            })
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        toast.success(result.message)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    // Edit Collabrator
    const [openHideShowEditModal, setHideShowEditModal] = useState(false)
    const editCollabratorItem = (item) => {
        //   const peoPerm = item.permissions.reduce((result, item) => {
        //     result[item] = true;
        //     return result;
        // }, {});
        setCheckedPermissions(item.permissions)
        setReadyForUpdate(item)
        setHideShowEditModal(true)
    }
    // Edit editInvitedPeopleModal
    const [editInvitedPeopleItem, setEditInvitedPeopleItem] = useState([])
    const [openEditInvitedPeople, setOpenEditInvitedPeople] = useState(false)
    const editInvitedPeople = (item) => {
        setOpenEditInvitedPeople(true)
        setEditInvitedPeopleItem(item)
        setCheckedPermissions(item.permissions)
    }
    // Add People
    const [peoplePermissions, setPeoplePermissions] = useState([])

    // Search Filter
    const [searchQuery, setSearchQuery] = useState('')
    const keys = ['full_name', 'designation', 'email']
    const search = (data) => {
        return data.filter((item) =>
            keys.some((key) => item[key].toLowerCase().includes(searchQuery))
        )
    }
    return (
        <>
            <Layout>
                {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-12">
                        <div className="dash-search-block position-relative">
                            <input
                                type="text"
                                className="sb-input"
                                placeholder="Search via Name, Role, Email..."
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <span className="sb-icon"></span>
                        </div>
                    </div>
                </div>
                <div className="heading-filter mb-30 d-flex align-items-center justify-content-between coll-heading-flex">
                    {filterPeople.length > 0 ? (
                        <div className="position-relative order-md-2">
                            <button
                                className="fl-btn selected me-0"
                                type="button"
                                value="AddCollaborator"
                                onClick={addCollaboratorModal}
                            >
                                Add People
                            </button>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="fl-action-block">
                        <button
                            onClick={handleFlButton}
                            className={
                                currentCard === 'All'
                                    ? 'fl-btn selected'
                                    : 'fl-btn'
                            }
                            type="button"
                            value="All"
                        >
                            All
                        </button>
                        <button
                            onClick={handleFlButton}
                            className={
                                currentCard === 'Active'
                                    ? 'fl-btn selected'
                                    : 'fl-btn'
                            }
                            type="button"
                            value="Active"
                        >
                            Active
                        </button>
                        <button
                            onClick={handleFlButton}
                            className={
                                currentCard === 'Invited'
                                    ? 'fl-btn selected'
                                    : 'fl-btn'
                            }
                            type="button"
                            value="Invited"
                        >
                            Invited
                        </button>
                    </div>
                </div>
                {/* "Matches": "No data matches"} */}
                {isLoading ? (
                    <Skeleton
                        containerClassName="cs-skeleton"
                        count={5}
                        height={80}
                    />
                ) : filterPeople.length > 0 ? (
                    search(filterPeople).length > 0 ? (
                        <CollaboratorList>
                            {search(filterPeople).map((item, index) => (
                                <>
                                    <CollabratiorItem
                                        key={index}
                                        item={item}
                                        deleteCollabratorItem={
                                            deleteCollabratorItem
                                        }
                                        editCollabratorItem={
                                            editCollabratorItem
                                        }
                                        resendPeopleInviteLink={
                                            resendPeopleInviteLink
                                        }
                                        editInvitedPeople={editInvitedPeople}
                                    />
                                </>
                            ))}
                        </CollaboratorList>
                    ) : (
                        <>
                            <div className="white-box pt-5 pb-5 text-center">
                                No People found at the moment.
                            </div>
                        </>
                    )
                ) : (
                    <>
                        <div className="collabrator-no-data position-relative">
                            <img src={collNoDataImg} alt="no data" />
                            <div className="cnd-position">
                                <div className="xs-title">
                                    No People found. Click the button <br />{' '}
                                    below to add People.
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="dash-theme-btn br-20"
                                        onClick={addCollaboratorModal}
                                    >
                                        Add People
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </Layout>
            <AddCollabratorModal
                openAddCollabratorModal={openAddCollabratorModal}
                setOpenAddCollabratorModal={setOpenAddCollabratorModal}
                setReload={setReload}
                peoplePermissions={peoplePermissions}
                setPeoplePermissions={setPeoplePermissions}
            />
            <DeleteCollabratorModal
                readyfordelete={readyfordelete}
                openHideShowDeleteModal={openHideShowDeleteModal}
                setHideShowDeleteModal={setHideShowDeleteModal}
                destroyPeople={destroyPeople}
            />
            <EditCollabratorModal
                readyforupdate={readyforupdate}
                openHideShowEditModal={openHideShowEditModal}
                setHideShowEditModal={setHideShowEditModal}
                //updatePeople={updatePeople}
                peoplePermissions={peoplePermissions}
                setCheckedPermissions={setCheckedPermissions}
                checkedPermissions={checkedPermissions}
                setReload={setReload}
            />
            <EditInvitedPeopleModal
                setReload={setReload}
                peoplePermissions={peoplePermissions}
                setCheckedPermissions={setCheckedPermissions}
                checkedPermissions={checkedPermissions}
                editInvitedPeopleItem={editInvitedPeopleItem}
                openEditInvitedPeople={openEditInvitedPeople}
                setOpenEditInvitedPeople={setOpenEditInvitedPeople}
            />
        </>
    )
}

export default Collaborator
