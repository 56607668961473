import React, { useEffect, useState } from 'react'
import Currency from '../Currency/Currency'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import SassCurrency from '../Currency/SassCurrency'
import {
    Avatar,
    Box,
    Button,
    NativeSelect,
    Paper,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { VictoryLabel, VictoryLegend, VictoryPie, VictoryPortal } from 'victory'
import { ReactComponent as InspectIcon } from '../../assets/dashboard/img/inspect-dynamic.svg'
import { ReactComponent as DownArrow } from '../../assets/dashboard/img/down-arrow-2-dynamic.svg'
import HeadingHamburgerMenu from './HeadingHamburgerMenu'
import CircularProgress from '@mui/material/CircularProgress'
import moment from 'moment'

const ProjectsWidget = ({
    projectDashboard,
    teamItem,
    openJiraConnectModal,
    projectCurrency,
    handleCheckboxClick,
    switchCurrency,
    clientCurrency,
    openBillingCustomerInfo,
    openEditBillingCustomerInfo,
    projectBillingDtl,
    clientInvitationCallback,
    sprintList,
    handleSprintSelect,
    syncRequest,
    syncLoad,
    setReload,
    loading_value,
    toolType,
}) => {
    const sprintDurationMethod = (list, val, defaultVal) => {
        if (defaultVal) {
            return `${list[0].startDate ? moment(list[0].startDate).format('DD MMM, YYYY') : '-'} - ${list[0].endDate ? moment(list[0].endDate).format('DD MMM, YYYY') : '-'}`
        }
        let filteredSprint = list.filter((item) => {
            return item.id == val
        })

        return `${moment(filteredSprint[0].startDate).format('DD MMM, YYYY')} - ${moment(filteredSprint[0].endDate).format('DD MMM, YYYY')}`
    }

    const theme = useTheme()
    let user = localStorage.getItem('user')
    let [bugsLabel, setBugsLabel] = useState(0 + '%')
    let [totalHoursLabel, setTotalHoursLabel] = useState(0 + '%')
    let [sprintValue, setSprintValue] = useState(
        sprintList
            ? (toolType == 'jira'
                  ? sprintList.filter((v) => v.state == 'active')[0].id
                  : sprintList[0]?.id) || 0
            : 0
    )
    let [syncLoading, setSyncLoading] = useState(false)
    let [sprintDuration, setSprintDuration] = useState(
        sprintList
            ? sprintList?.length > 0
                ? sprintDurationMethod(sprintList, 1, true)
                : ''
            : ''
    )
    user = JSON.parse(user)
    let { currency } = user
    const { id } = useParams
    const processbarValue =
        projectDashboard?.sprint_status_percentage > 100
            ? 100
            : projectDashboard?.sprint_status_percentage
    const rightPos = 100 - processbarValue

    useEffect(() => {
        setSyncLoading(false)
    }, [sprintList])

    const tooltipProps = {
        followCursor: true,
        arrow: false,
        slotProps: {
            tooltip: {
                sx: {
                    p: 0,
                    borderRadius: '8px',
                    backgroundColor: 'common.white',
                },
            },
        },
        title: (
            <Box
                borderRadius="8px"
                border="1px solid"
                borderColor="teritiary.teritiary22"
                pb="6px"
                pt="6px"
            >
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary1"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        Completed
                    </Typography>
                </Box>
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary17"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        In Progress
                    </Typography>
                </Box>
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary24"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        Pending
                    </Typography>
                </Box>
            </Box>
        ),
    }

    const secondTooltipProps = {
        arrow: false,
        slotProps: {
            tooltip: {
                sx: {
                    p: 0,
                    borderRadius: '8px',
                    backgroundColor: 'common.white',
                },
            },
        },
        title: (
            <Box
                borderRadius="8px"
                border="1px solid"
                borderColor="teritiary.teritiary22"
                pb="6px"
                pt="6px"
            >
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary1"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        On Track
                    </Typography>
                </Box>
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary17"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        At Risk
                    </Typography>
                </Box>
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="error.main"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        Delayed
                    </Typography>
                </Box>
            </Box>
        ),
    }

    const bugsPieColor = (val) => {
        if (val < 10) {
            return theme.palette.teritiary.teritiary1
        }

        if (val >= 10 && val <= 15) {
            return theme.palette.teritiary.teritiary17
        }

        if (val > 15) {
            return theme.palette.error.main
        }
    }

    const sprintText = (val) => {
        if (val == 'active') {
            return 'In Progress'
        }

        if (val == 'future') {
            return 'Planned'
        }

        if (val == 'closed') {
            return 'Completed'
        }

        // if (val > 15) {
        //     return theme.palette.error.main;
        // }
    }

    const shortName = () => {
        if (teamItem?.dashboard?.project_name.includes(' ')) {
            let arr = teamItem?.dashboard?.project_name.split(' ')
            let first = arr[0].charAt(0).toUpperCase()
            let last = arr[arr.length - 1].charAt(0).toUpperCase()
            return `${first}${last}`
        } else {
            let first = teamItem?.dashboard?.project_name
                .charAt(0)
                .toUpperCase()
            let last = teamItem?.dashboard?.project_name
                .charAt(teamItem?.dashboard?.project_name.length - 1)
                .toUpperCase()
            return `${first}${last}`
        }
    }

    const sprintStatus = projectDashboard?.sprint_status_percentage || 0
    const backgroundColor =
        sprintStatus > 0 && sprintStatus < 15
            ? '#219653'
            : sprintStatus >= 15 && sprintStatus < 30
              ? '#F2994A'
              : sprintStatus >= 30
                ? 'rgb(237, 51, 66)'
                : 'green'
    return (
        <>
            {console.log('projectDashboard', syncLoad, syncLoading)}
            {projectDashboard ? (
                <>
                    <Box sx={{ mb: '15px' }} className="ad-cards-block">
                        <Stack
                            mt="16px"
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            width="100%"
                            pb="36px"
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing="24px"
                            >
                                <Avatar
                                    src={
                                        teamItem?.dashboard?.project_logo || ''
                                    }
                                    slotProps={{
                                        img: {
                                            sx: {
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                            },
                                        },
                                    }}
                                    sx={{
                                        width: 61,
                                        height: 61,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        color: theme.palette.primary.main,
                                        backgroundColor:
                                            theme.palette.common.white,
                                    }}
                                >
                                    {shortName() || ''}
                                </Avatar>
                                <Stack alignItems="flex-start">
                                    <Stack
                                        direction="row"
                                        alignItems="flex-start"
                                        position="relative"
                                    >
                                        <Stack alignItems="flex-start">
                                            <Typography
                                                variant="size7MediumTerTer18"
                                                sx={{
                                                    lineHeight: '33px',
                                                    fontSize: '18px',
                                                }}
                                            >
                                                {
                                                    teamItem?.dashboard
                                                        ?.project_name
                                                }
                                            </Typography>
                                            {/* <Box component="span" sx={{ml:0}} className={`employment-type-tag ${teamItem?.dashboard?.project_status}-tag`}>{teamItem?.dashboard?.project_status}</Box> */}
                                        </Stack>
                                        {/* {projectBillingDtl === "" ?
                                                <button type='button' className='pd-billing-btn ms-2' onClick={openBillingCustomerInfo}><span className='blue-billing-icon'></span> Add Billing Details</button> :
                                                <button type='button' className='pd-billing-btn ms-2' onClick={openEditBillingCustomerInfo}><span className='blue-billing-icon'></span> Billing Details</button>
                                            } */}
                                        {/* <Tooltip {...thirdTooltipProps}>
                                                <SvgIcon
                                                    sx={{ ml: "11.33px", width: "13.33px", height: "13.33px" }}
                                                    component={InspectIcon}
                                                    inheritViewBox
                                                />
                                            </Tooltip> */}
                                        {user.is_invite_client != 'true' && (
                                            <HeadingHamburgerMenu
                                                setReload={setReload}
                                                project_name={
                                                    teamItem?.dashboard
                                                        ?.project_name || '-'
                                                }
                                                project_status={
                                                    teamItem?.dashboard
                                                        ?.project_status || '-'
                                                }
                                                project_logo={
                                                    teamItem?.dashboard
                                                        ?.project_logo || '-'
                                                }
                                                gst_no={
                                                    teamItem?.dashboard
                                                        ?.gst_no || ''
                                                }
                                                projectCurrency={
                                                    projectCurrency || ''
                                                }
                                                client_name={
                                                    teamItem?.dashboard
                                                        ?.client_name || '-'
                                                }
                                                monthly_budget={
                                                    clientCurrency
                                                        ? teamItem?.dashboard
                                                              ?.my_currency_month_budget ||
                                                          '-'
                                                        : teamItem?.dashboard
                                                              ?.monthly_budget ||
                                                          '-'
                                                }
                                                start_date={
                                                    teamItem?.dashboard
                                                        ?.project_start_date
                                                }
                                                currency={currency || '-'}
                                                customer_name={
                                                    teamItem?.dashboard
                                                        ?.client_name
                                                }
                                                client_email={
                                                    teamItem?.dashboard
                                                        ?.client_email || ''
                                                }
                                                project_billing_details={
                                                    projectBillingDtl
                                                }
                                                clientInvitationCallback={
                                                    clientInvitationCallback
                                                }
                                            />
                                        )}
                                    </Stack>
                                    <Typography
                                        variant="size4MediumTerTer8"
                                        sx={{
                                            lineHeight: '24px',
                                            fontSize: '13px',
                                        }}
                                    >
                                        {teamItem?.dashboard?.client_name}
                                    </Typography>
                                    <Box
                                        component="span"
                                        sx={{ ml: 0 }}
                                        className={`employment-type-tag ${teamItem?.dashboard?.project_status}-tag`}
                                    >
                                        {teamItem?.dashboard?.project_status}
                                    </Box>
                                </Stack>
                            </Stack>
                            {console.log('sprintList===========', sprintList)}
                            {
                                teamItem?.is_jira_credentials_completed ||
                                teamItem?.is_asana_credentials_completed ? (
                                    <Stack alignItems="flex-end">
                                        <Box width="201px">
                                            <NativeSelect
                                                sx={{
                                                    width: '201px',
                                                    border: '1px solid',
                                                    borderColor: 'primary.main',
                                                    borderRadius: '4px',
                                                    backgroundColor:
                                                        'primary.lighter',
                                                    padding: '8px 12px',
                                                    '&.MuiInputBase-root.MuiInput-root::before':
                                                        {
                                                            border: '0px',
                                                        },
                                                    '&.MuiInputBase-root.MuiInput-root::after':
                                                        {
                                                            border: '0px',
                                                        },
                                                    '& .MuiNativeSelect-icon': {
                                                        top: 'calc(50% - 0.8em)',
                                                        mr: '12px',
                                                        color: 'primary.main',
                                                    },
                                                }}
                                                inputProps={{
                                                    sx: {
                                                        height: '18px',
                                                        padding: '0px',
                                                        fontSize: '14px',
                                                        lineHeight: '18px',
                                                        color: 'primary.main',
                                                        '&:focus': {
                                                            backgroundColor:
                                                                'transparent',
                                                        },
                                                    },
                                                }}
                                                value={sprintValue}
                                                onChange={(e) => {
                                                    setSprintValue(
                                                        e.target.value
                                                    )

                                                    setSprintDuration(
                                                        sprintDurationMethod(
                                                            sprintList,
                                                            e.target.value
                                                        )
                                                    )
                                                    handleSprintSelect(
                                                        e.target.value
                                                    )
                                                }}
                                                IconComponent={DownArrow}
                                            >
                                                {sprintList &&
                                                    sprintList.map(
                                                        (sprint, index) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        sprint.id ||
                                                                        sprint.gid
                                                                    }
                                                                >
                                                                    {
                                                                        sprint.name
                                                                    }{' '}
                                                                    {sprint?.state
                                                                        ? sprintText(
                                                                              sprint.state
                                                                          )
                                                                        : ''}
                                                                </option>
                                                            )
                                                        }
                                                    )}
                                            </NativeSelect>
                                        </Box>
                                        {!teamItem?.is_asana_credentials_completed && (
                                            <>
                                                <Typography
                                                    sx={{ mt: '8px' }}
                                                    variant="size3MediumTerTer19"
                                                >
                                                    {sprintDuration}
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    sx={{ mt: '8px' }}
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        sx={{
                                                            lineHeight: '18px',
                                                        }}
                                                        variant="size3MediumTerTer19"
                                                    >
                                                        Sprint Status
                                                    </Typography>
                                                    <Tooltip
                                                        {...secondTooltipProps}
                                                    >
                                                        <SvgIcon
                                                            sx={{
                                                                ml: '11.33px',
                                                                width: '13.33px',
                                                                height: '13.33px',
                                                            }}
                                                            component={
                                                                InspectIcon
                                                            }
                                                            inheritViewBox
                                                        />
                                                    </Tooltip>
                                                    <Box
                                                        sx={{
                                                            width: '201px',
                                                            mr: '0px',
                                                            ml: '8px',
                                                            justifyContent:
                                                                'center',
                                                            position:
                                                                'relative',
                                                        }}
                                                        className="progress ad-allocation-progress position-relative"
                                                    >
                                                        {loading_value ? (
                                                            <>
                                                                <div
                                                                    className="progress-bar"
                                                                    role="progressbar"
                                                                    style={{
                                                                        width: `${sprintStatus}%`,
                                                                        background:
                                                                            backgroundColor,
                                                                        position:
                                                                            'absolute',
                                                                        left: '0px',
                                                                        height: '14px',
                                                                    }}
                                                                    aria-valuenow={
                                                                        sprintStatus
                                                                    }
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                ></div>

                                                                {sprintStatus >
                                                                30 ? (
                                                                    <div
                                                                        className="allocation-label black-text ad-font-0-8em font-10 fw-600"
                                                                        style={{
                                                                            top: '0px',
                                                                            zIndex: 98,
                                                                        }}
                                                                    >
                                                                        {sprintStatus.toFixed(
                                                                            0
                                                                        )}
                                                                        %
                                                                    </div>
                                                                ) : sprintStatus ? (
                                                                    <div
                                                                        className="allocation-label black-text ad-font-0-8em font-10 fw-600"
                                                                        style={{
                                                                            top: '0px',
                                                                            zIndex: 98,
                                                                        }}
                                                                    >
                                                                        {sprintStatus.toFixed(
                                                                            0
                                                                        )}
                                                                        %
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className="allocation-label black-text ad-font-0-8em font-10 fw-600"
                                                                        style={{
                                                                            top: '0px',
                                                                            zIndex: 98,
                                                                        }}
                                                                    >
                                                                        0%
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    marginTop:
                                                                        '1px',
                                                                }}
                                                            >
                                                                <CircularProgress
                                                                    color="primary"
                                                                    size={10}
                                                                />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Stack>
                                            </>
                                        )}

                                        {/* <Box mt="8px">
                                            <button className="ad-theme-btn ad-sm-btn ad-orange-btn" type="button" onClick={() => {
                                                if (!syncLoading) {
                                                    setSyncLoading(true);
                                                    syncRequest(sprintValue);
                                                }
                                            }}>{syncLoading ? "...Syncing" : "Sync"}</button>
                                        </Box> */}
                                    </Stack>
                                ) : (
                                    <></>
                                )
                                // user.is_invite_client != "true"  &&
                                // <button className="ad-theme-btn ad-sm-btn ad-orange-btn" type="button" onClick={() => openJiraConnectModal(id)}>Configure Jira</button>
                            }
                        </Stack>
                        {(teamItem?.is_jira_credentials_completed ||
                            teamItem?.is_asana_credentials_completed) && (
                            <>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    mb="8px"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Typography
                                            component="div"
                                            lineHeight="29px"
                                            variant="size7MediumTextText5"
                                        >
                                            Overview
                                        </Typography>
                                    </Box>

                                    <button
                                        style={{
                                            borderWidth: '1px',
                                            paddingTop: '2px',
                                            paddingBottom: '2px',
                                            borderColor:
                                                theme.palette.primary.main,
                                            backgroundColor:
                                                theme.palette.primary.lighter,
                                            color: theme.palette.primary.main,
                                        }}
                                        className="ad-theme-btn ad-sm-btn "
                                        type="button"
                                        onClick={() => {
                                            if (!syncLoading) {
                                                setSyncLoading(true)
                                                syncRequest(sprintValue)
                                            }
                                        }}
                                    >
                                        {syncLoading ? '...Syncing' : 'Sync'}
                                    </button>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing="15px"
                                    justifyContent="space-between"
                                >
                                    <Paper
                                        sx={{
                                            p: '18px 16px',
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="flex-end"
                                            >
                                                <Typography
                                                    lineHeight="91px"
                                                    variant="sizexxlRegularTextText4"
                                                >
                                                    {projectDashboard?.kpiObj
                                                        ?.completed_sprint_stories ||
                                                        '0'}
                                                    <Typography
                                                        lineHeight="25px"
                                                        variant="size8_5RegularTerTer20"
                                                    >
                                                        /
                                                        {projectDashboard
                                                            ?.kpiObj
                                                            ?.total_sprint_stories ||
                                                            '0'}
                                                    </Typography>
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                lineHeight="18px"
                                                variant="size3RegularTextText3"
                                            >
                                                {toolType == 'jira'
                                                    ? 'Stories'
                                                    : 'Tasks'}{' '}
                                                Completed
                                            </Typography>
                                        </Stack>
                                        <Tooltip {...tooltipProps}>
                                            <Box width="100px" height="100px">
                                                {console.log(
                                                    'projectDashboard',
                                                    projectDashboard
                                                )}
                                                <VictoryPie
                                                    padAngle={0}
                                                    innerRadius={32.5}
                                                    radius={50}
                                                    height={100}
                                                    width={100}
                                                    labelRadius={({
                                                        innerRadius,
                                                    }) => innerRadius + 4}
                                                    colorScale={[
                                                        theme.palette.teritiary
                                                            .teritiary1,
                                                        theme.palette.teritiary
                                                            .teritiary17,
                                                        theme.palette.teritiary
                                                            .teritiary24,
                                                        theme.palette.teritiary
                                                            .teritiary21,
                                                    ]}
                                                    style={{
                                                        labels: {
                                                            fill: theme.palette
                                                                .common.white,
                                                            fontSize: 10,
                                                            fontWeight: 600,
                                                        },
                                                    }}
                                                    data={[
                                                        {
                                                            x: '',
                                                            y: projectDashboard
                                                                ?.kpiObj
                                                                ?.completed_sprint_stories,
                                                            label:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.completed_sprint_stories ||
                                                                ' ',
                                                        },
                                                        {
                                                            x: '',
                                                            y: projectDashboard
                                                                ?.kpiObj
                                                                ?.in_progress_sprint_stories,
                                                            label:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.in_progress_sprint_stories ||
                                                                ' ',
                                                        },
                                                        {
                                                            x: '',
                                                            y: projectDashboard
                                                                ?.kpiObj
                                                                ?.pending_sprint_stories,
                                                            label:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.pending_sprint_stories ||
                                                                ' ',
                                                        },
                                                        // { x: "", y: projectDashboard?.total_planned_stories - (projectDashboard?.completed_stories + projectDashboard?.pending_stories), label: projectDashboard?.total_planned_stories - (projectDashboard?.completed_stories + projectDashboard?.pending_stories) || " " },
                                                    ]}
                                                    events={[
                                                        {
                                                            target: 'parent',
                                                            eventHandlers: {
                                                                onMouseOver:
                                                                    () => {
                                                                        console.log(
                                                                            'mouse over'
                                                                        )
                                                                    },
                                                                onMouseLeave:
                                                                    () => {
                                                                        console.log(
                                                                            'mouse leave'
                                                                        )
                                                                    },
                                                            },
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Paper>
                                    <Paper
                                        sx={{
                                            p: '18px 16px',
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="flex-end"
                                            >
                                                <Typography
                                                    lineHeight="91px"
                                                    variant="sizexxlRegularTextText4"
                                                >
                                                    {console.log(
                                                        projectDashboard?.kpiObj
                                                            ?.consumed_hours_on_stories
                                                    )}
                                                    {(
                                                        projectDashboard?.kpiObj
                                                            ?.consumed_hours_on_stories +
                                                        ''
                                                    ).split('.').length > 1
                                                        ? (
                                                              projectDashboard
                                                                  ?.kpiObj
                                                                  ?.consumed_hours_on_stories +
                                                              ''
                                                          ).split('.')[0]
                                                        : projectDashboard
                                                              ?.kpiObj
                                                              ?.consumed_hours_on_stories}
                                                    <Typography variant="sizexlRegularTextText4">
                                                        {projectDashboard
                                                            ?.kpiObj
                                                            ?.consumed_hours_on_stories
                                                            ? (
                                                                  projectDashboard
                                                                      ?.kpiObj
                                                                      ?.consumed_hours_on_stories +
                                                                  ''
                                                              ).split('.')
                                                                  .length > 1
                                                                ? '.' +
                                                                  (
                                                                      projectDashboard
                                                                          ?.kpiObj
                                                                          ?.consumed_hours_on_stories +
                                                                      ''
                                                                  ).split(
                                                                      '.'
                                                                  )[1]
                                                                : ''
                                                            : ''}
                                                    </Typography>
                                                    <Typography
                                                        lineHeight="25px"
                                                        variant="size8_5RegularTerTer20"
                                                    >
                                                        /
                                                        {(
                                                            projectDashboard
                                                                ?.kpiObj
                                                                .total_hours_on_stories +
                                                            ''
                                                        ).split('.').length > 1
                                                            ? (
                                                                  projectDashboard
                                                                      ?.kpiObj
                                                                      .total_hours_on_stories +
                                                                  ''
                                                              ).split('.')[0]
                                                            : projectDashboard
                                                                  ?.kpiObj
                                                                  .total_hours_on_stories}
                                                        <Typography variant="size2_5RegularTerTer20">
                                                            {projectDashboard
                                                                ?.kpiObj
                                                                ?.total_hours_on_stories
                                                                ? (
                                                                      projectDashboard
                                                                          ?.kpiObj
                                                                          .total_hours_on_stories +
                                                                      ''
                                                                  ).split('.')
                                                                      .length >
                                                                  1
                                                                    ? '.' +
                                                                      (
                                                                          projectDashboard
                                                                              ?.kpiObj
                                                                              .total_hours_on_stories +
                                                                          ''
                                                                      ).split(
                                                                          '.'
                                                                      )[1]
                                                                    : ''
                                                                : ''}
                                                        </Typography>
                                                    </Typography>
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                lineHeight="18px"
                                                variant="size3RegularTextText3"
                                            >
                                                Hours Consumed
                                            </Typography>
                                        </Stack>
                                        {/* <Tooltip {...tooltipProps}> */}
                                        <Box width="100px" height="100px">
                                            <svg viewBox="0 0 100 100">
                                                <VictoryPie
                                                    // padAngle={0}
                                                    standalone={false}
                                                    innerRadius={32.5}
                                                    radius={50}
                                                    height={100}
                                                    width={100}
                                                    // labelRadius={({ innerRadius }) => innerRadius + 4}
                                                    labels={({ datum }) => {
                                                        if (
                                                            datum.dataType ==
                                                            'main'
                                                        ) {
                                                            // console.log("datum",datum,props);
                                                            setTotalHoursLabel(
                                                                datum.y.toFixed(
                                                                    1
                                                                ) + '%'
                                                            )
                                                        }
                                                    }}
                                                    colorScale={[
                                                        theme.palette.teritiary
                                                            .teritiary1,
                                                        theme.palette.teritiary
                                                            .teritiary21,
                                                    ]}
                                                    style={{
                                                        labels: {
                                                            fill: theme.palette
                                                                .common.white,
                                                            fontSize: 10,
                                                            fontWeight: 600,
                                                        },
                                                    }}
                                                    data={[
                                                        {
                                                            x: 'Hours Consumed',
                                                            y:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.consumed_hours_on_stories &&
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.total_hours_on_stories
                                                                    ? (projectDashboard
                                                                          ?.kpiObj
                                                                          ?.consumed_hours_on_stories /
                                                                          projectDashboard
                                                                              ?.kpiObj
                                                                              ?.total_hours_on_stories) *
                                                                      100
                                                                    : 0,
                                                            label: '',
                                                            dataType: 'main',
                                                        },
                                                        {
                                                            x: 'Total Hours',
                                                            y:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.consumed_hours_on_stories &&
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.total_hours_on_stories
                                                                    ? 100 -
                                                                      (projectDashboard
                                                                          ?.kpiObj
                                                                          ?.consumed_hours_on_stories /
                                                                          projectDashboard
                                                                              ?.kpiObj
                                                                              ?.total_hours_on_stories) *
                                                                          100
                                                                    : 100,
                                                            label: '',
                                                            dataType: 'rest',
                                                        },
                                                    ]}
                                                />
                                                <VictoryLabel
                                                    textAnchor="middle"
                                                    style={{
                                                        fill: theme.palette.text
                                                            .text1,
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                    x={50}
                                                    y={50}
                                                    text={totalHoursLabel}
                                                />
                                            </svg>
                                        </Box>
                                        {/* </Tooltip> */}
                                    </Paper>
                                    <Paper
                                        sx={{
                                            p: '18px 16px',
                                            flex: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Stack>
                                            <Stack
                                                direction="row"
                                                alignItems="flex-end"
                                            >
                                                <Typography
                                                    lineHeight="91px"
                                                    variant="sizexxlRegularTextText4"
                                                >
                                                    {projectDashboard?.kpiObj
                                                        ?.consumed_hours_on_bugs ||
                                                        '0'}
                                                    <Typography
                                                        lineHeight="25px"
                                                        variant="size8_5RegularTextText4"
                                                    >
                                                        hrs
                                                    </Typography>
                                                </Typography>
                                            </Stack>
                                            <Typography
                                                lineHeight="18px"
                                                variant="size3RegularTextText3"
                                            >
                                                Consumed on Bugs
                                            </Typography>
                                        </Stack>
                                        {/* <Tooltip {...tooltipProps}> */}
                                        <Box width="100px" height="100px">
                                            <svg viewBox="0 0 100 100">
                                                <VictoryPie
                                                    // padAngle={0}
                                                    standalone={false}
                                                    innerRadius={32.5}
                                                    radius={50}
                                                    height={100}
                                                    width={100}
                                                    colorScale={[
                                                        bugsPieColor(
                                                            projectDashboard
                                                                ?.kpiObj
                                                                ?.percentage_consumed_hours_bugs
                                                        ),
                                                        theme.palette.teritiary
                                                            .teritiary21,
                                                    ]}
                                                    // labelRadius={-4}
                                                    labels={({ datum }) => {
                                                        if (
                                                            datum.dataType ==
                                                            'main'
                                                        ) {
                                                            // console.log("datum",datum,props);
                                                            setBugsLabel(
                                                                datum.y.toFixed(
                                                                    1
                                                                ) + '%'
                                                            )
                                                        }
                                                    }}
                                                    style={{
                                                        labels: {
                                                            fill: theme.palette
                                                                .text.text1,
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                        },
                                                    }}
                                                    data={[
                                                        {
                                                            x: '',
                                                            y:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.percentage_consumed_hours_bugs &&
                                                                typeof projectDashboard
                                                                    ?.kpiObj
                                                                    ?.percentage_consumed_hours_bugs ==
                                                                    'number'
                                                                    ? projectDashboard
                                                                          ?.kpiObj
                                                                          ?.percentage_consumed_hours_bugs
                                                                    : 0,
                                                            label: '',
                                                            dataType: 'main',
                                                        },
                                                        {
                                                            x: '',
                                                            y:
                                                                projectDashboard
                                                                    ?.kpiObj
                                                                    ?.percentage_consumed_hours_bugs &&
                                                                typeof projectDashboard
                                                                    ?.kpiObj
                                                                    ?.percentage_consumed_hours_bugs ==
                                                                    'number'
                                                                    ? 100 -
                                                                      projectDashboard
                                                                          ?.kpiObj
                                                                          ?.percentage_consumed_hours_bugs
                                                                    : 100,
                                                            label: '',
                                                            dataType: 'rest',
                                                        },
                                                    ]}
                                                />
                                                <VictoryLabel
                                                    textAnchor="middle"
                                                    style={{
                                                        fill: theme.palette.text
                                                            .text1,
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                    x={50}
                                                    y={50}
                                                    text={bugsLabel}
                                                />
                                            </svg>
                                        </Box>
                                        {/* </Tooltip> */}
                                    </Paper>
                                </Stack>
                            </>
                        )}
                    </Box>
                </>
            ) : (
                // :
                // <>
                //     <div className="ad-cards-block">
                //         <div className="d-flex justify-content-between  flex-wrap">
                //             <div>
                //                 <div className='d-inline-flex align-items-center  mb-2'>
                //                     <div className="md-title text-start fw-600 dash-black-text">
                //                         {teamItem?.dashboard?.project_name}
                //                         <span className={`employment-type-tag ${teamItem?.dashboard?.project_status}-tag ms-2`}>{teamItem?.dashboard?.project_status}</span>
                //                         {projectBillingDtl === "" ?
                //                             <button type='button' className='pd-billing-btn ms-2' onClick={openBillingCustomerInfo}><span className='blue-billing-icon'></span> Add Billing Details</button> :
                //                             <button type='button' className='pd-billing-btn ms-2' onClick={openEditBillingCustomerInfo}><span className='blue-billing-icon'></span> Billing Details</button>
                //                         }
                //                         <Tooltip {...thirdTooltipProps}>
                //                             <SvgIcon
                //                                 sx={{ ml: "11.33px", width: "13.33px", height: "13.33px" }}
                //                                 component={InspectIcon}
                //                                 inheritViewBox
                //                             />
                //                         </Tooltip>
                //                     </div>
                //                 </div>
                //                 <div className="d-flex align-items-center">
                //                     <div className="dash-md-title ad-gray-text fw-600 dash-font-0-9em me-5">
                //                         <span className="ad-lgray-text fw-500 dash-font-0-9em me-1">Monthly budget:</span>
                //                         <SassCurrency pCurrency={clientCurrency ? currency : projectCurrency} />
                //                         {clientCurrency ? teamItem?.dashboard?.my_currency_month_budget : teamItem?.dashboard?.monthly_budget}
                //                     </div>
                //                     <div className="dash-md-title ad-gray-text fw-600 dash-font-0-9em"><span className="ad-lgray-text fw-500 dash-font-0-9em">Start date:</span> {teamItem?.dashboard?.project_start_date}</div>
                //                 </div>
                //                 {projectCurrency === currency ? "" :
                //                     <div className='mt-2'>
                //                         <div className='d-flex align-items-center'>
                //                             <span className='switch-currency-btn ad-lgray-text'>
                //                                 Billing
                //                             </span>
                //                             <div className='notification-switch main-switch inr-switch-btn ms-2 me-2'>
                //                                 <label>
                //                                     <input
                //                                         type="checkbox"
                //                                         checked={clientCurrency}
                //                                         onChange={() => handleCheckboxClick()}
                //                                     />
                //                                     <span className="slider"></span>
                //                                     <span className='msw-label msw-label1'><SassCurrency pCurrency={projectCurrency} /></span>
                //                                     <span className='msw-label msw-label2'><SassCurrency pCurrency={currency} /></span>
                //                                 </label>
                //                             </div>
                //                             <span className='switch-currency-btn ad-lgray-text'>
                //                                 Base
                //                             </span>
                //                         </div>
                //                     </div>
                //                 }
                //             </div>
                //             <div className='text-end'>
                //                 <button className="ad-theme-btn ad-sm-btn ad-orange-btn" type="button" onClick={() => openJiraConnectModal(id)}>Integrate with Jira</button>
                //             </div>
                //         </div>
                //     </div>
                // </>
                <div className="row mt-10 gx-3">
                    <div className="col-md-3 col-sm-6">
                        <Skeleton
                            containerClassName="cs-skeleton"
                            count={1}
                            height={100}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Skeleton
                            containerClassName="cs-skeleton"
                            count={1}
                            height={100}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Skeleton
                            containerClassName="cs-skeleton"
                            count={1}
                            height={100}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <Skeleton
                            containerClassName="cs-skeleton"
                            count={1}
                            height={100}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default ProjectsWidget
