import { Field } from 'formik'
import ValidationError from '../../Components/Errors/ValidationError'

const SelectCountry = ({
    label,
    name,
    data,
    placeholder,
    handleCountryChange,
    disabled,
    intersectingLabel,
    ...restProps
}) => {
    return (
        <>
            <div
                className={
                    intersectingLabel ? 'position-relative' : 'form-group'
                }
            >
                {/* <label htmlFor={name} className="asterisks">{label}</label> */}
                {label ? (
                    <label
                        htmlFor={name}
                        className={
                            intersectingLabel ? 'label-pos' : 'asterisks'
                        }
                    >
                        {label}
                    </label>
                ) : (
                    ''
                )}
                <Field
                    name={name}
                    as="select"
                    className="form-control"
                    disabled={disabled ? disabled : ''}
                >
                    <option value="">{placeholder}</option>
                    {data.map((val, key) => {
                        return (
                            <option key={key} value={val.id}>
                                {val.name}
                            </option>
                        )
                    })}
                </Field>
                <ValidationError name={name} />
            </div>
        </>
    )
}

export default SelectCountry
