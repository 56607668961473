import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import UserForm from './UserForm'
import { IconButton } from '@mui/material'
import { SvgIcon } from '@mui/material'
import { ReactComponent as Cross } from '../../assets/dashboard/img/cross-interview.svg'
import { TextField } from '@mui/material'
import AddDetail from './AddDetail'

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    width: 650,
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    p: 0,
    m: 0,
}

const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: 650,
}

const RequestInterview = ({
    Modalclose,
    shortlisted,
    interviewRound,
    myid,
    item,
    reload,
    openDecline,
    scheduledCallback,
    isHired,
    isDeclined,
}) => {
    const roundData =
        item?.interview_details && item.interview_details.length > 0
            ? item.interview_details.filter(
                  (val) => val.interview_round_no - 1 == interviewRound
              )[0]
            : []

    const headingFunc = (conditionval) => {
        switch (conditionval) {
            case 'requested':
                return 'Interview Requested'
            case 'declined':
                return 'Request Declined'
            case 'cancelled':
                return 'Interview Cancelled'
            case 'completed':
                return 'Interview Completed'
            case 'completed':
                return 'Interview Completed'
            case 'planned':
                return 'Interview Scheduled'
            default:
                return 'Schedule Interview'
        }
    }
    console.log('firstRoundDetails.......', shortlisted, interviewRound)
    const [refreshToken, setRefreshToken] = useState(interviewRound)

    return (
        <Box sx={style}>
            <Box
                sx={{ display: 'flex', alignItems: 'center', padding: '16px' }}
            >
                <Box sx={{ fontSize: '18px', fontWeight: 600 }}>
                    {headingFunc(roundData?.interview_status)}
                </Box>
                <Box sx={{ marginLeft: 'auto' }}>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setRefreshToken('99999')
                            Modalclose()
                        }}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                    >
                        <SvgIcon
                            sx={{
                                height: '29px',
                                width: '29px',
                                color: 'black',
                                pointerEvents: 'none',
                            }}
                            component={Cross}
                            inheritViewBox
                        />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={contentStyle}>
                <UserForm shortlisted={shortlisted} item={item} />
                <AddDetail
                    key={String(refreshToken)}
                    reload={reload}
                    Modalclose={Modalclose}
                    interviewRound={interviewRound}
                    isHired={isHired}
                    isDeclined={isDeclined}
                    shortlisted={item}
                    myid={myid}
                    openDecline={openDecline}
                    scheduledCallback={scheduledCallback}
                />
            </Box>
        </Box>
    )
}

export default RequestInterview
