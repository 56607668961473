import { Link } from 'react-router-dom'
import usersIcon from '../../assets/dashboard/img/users-icon.svg'
import likeIcon from '../../assets/dashboard/img/like-icon.svg'
import disLikeIcon from '../../assets/dashboard/img/dislike.svg'
import checkTickIcon from '../../assets/dashboard/img/view-tick-icon.svg'
import optionsIcon from '../../assets/dashboard/img/options-icon.svg'
import collImg from '../../assets/dashboard/img/coll-img.png'

const CollabratiorItem = ({
    deleteCollabratorItem,
    editCollabratorItem,
    item,
    resendPeopleInviteLink,
    editInvitedPeople,
}) => {
    const {
        id,
        image,
        email,
        full_name,
        confirmed,
        designation,
        short_name,
        avatar,
        permissions,
    } = item
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let login_id = user.id
    return (
        <>
            <table className="w-100 inside-table">
                <tbody>
                    <tr>
                        <td style={{ width: '25%' }}>
                            <div className="d-flex align-items-center">
                                <div
                                    className="dash-tech-box"
                                    style={{ background: 'rgb(49, 117, 248)' }}
                                >
                                    {/* <img
                    className="dash-tech-icon"
                    src={avatar ? avatar : collImg}
                    alt=""
                  /> */}
                                    {avatar ? (
                                        <img src={avatar} alt={full_name} />
                                    ) : (
                                        <span className="talent-short-name">
                                            {short_name || 'P'}
                                        </span>
                                    )}
                                </div>
                                <div className="ps-3">{full_name}</div>
                            </div>
                        </td>
                        <td style={{ width: '30%' }}>{email}</td>
                        <td style={{ width: '20%' }}>
                            {designation ? designation : '-'}
                        </td>
                        {confirmed == 'Invited' ? (
                            <td
                                style={{ width: '15%' }}
                                className="dash-blue-text"
                            >
                                {confirmed}
                            </td>
                        ) : (
                            <td
                                style={{ width: '15%' }}
                                className="dash-green-text"
                            >
                                {confirmed}
                            </td>
                        )}

                        <td style={{ width: '10%' }} className="text-center">
                            {login_id != id && (
                                <>
                                    <div className="dropdown dash-menu-dropdown">
                                        <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src={optionsIcon}
                                                alt="optionsIcon"
                                            />
                                        </button>

                                        <ul
                                            className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            {confirmed === 'Invited' && (
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) =>
                                                            editInvitedPeople(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                            )}
                                            {confirmed === 'Invited' && (
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) =>
                                                            resendPeopleInviteLink(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        Resend
                                                    </button>
                                                </li>
                                            )}
                                            {confirmed === 'Active' && (
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        type="button"
                                                        onClick={(e) =>
                                                            editCollabratorItem(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                            )}
                                            <li>
                                                <button
                                                    className="dropdown-item dash-orange-text"
                                                    type="button"
                                                    onClick={(e) =>
                                                        deleteCollabratorItem(
                                                            item
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default CollabratiorItem
