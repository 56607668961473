import React from 'react'
import Layout from '../../Pages/Dashboard/Layout'
import checkIcon from '../../assets/agency/img/check-icon.svg'
import { useNavigate } from 'react-router-dom'

const OrderConfirm = () => {
    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate('/dashboard/myteams') //
    }

    return (
        <>
            <Layout>
                <div className="ad-billing-flex">
                    <div className="ad-white-card p-50">
                        <div className="pt-5 pb-5 text-center">
                            <img src={checkIcon} alt="check" />
                            <div className="md-title text-center mt-4 fw-600 ad-dgray-text">
                                Thank You for your payment!
                            </div>
                            <div className="dash-md-title dash-black-text fw-600 ad-font-1-1em mt-4">
                                Your order has been placed succesfully
                            </div>
                            {/* <div className="dash-md-title text-wrap dash-font-0-9em mt-3 ad-lgray-text">An order confirmation email has been sent to your <br />
                                e-mail address <span className="ad-dgray-text">ankish@weteams.io</span></div> */}
                            <div className="mt-4 text-center">
                                <button
                                    type="button"
                                    onClick={handleGoBack}
                                    className="ad-theme-btn"
                                >
                                    Add Team Member
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default OrderConfirm
