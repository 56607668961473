import React from 'react'
import { Formik, Form, Field } from 'formik'
import ValidationError from '../Errors/ValidationError'
import * as yup from 'yup'
import { IconButton, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import { useApiService } from '../../Services/ApiInstance'
import { toast, ToastContainer } from 'react-toastify'
import PhoneInputField from '../Form/PhoneInputField'

const ContactSales = ({
    open,
    closeContactSales,
    setOpen,
    clickOpenModal,
    setSuccessContactSales,
    type,
}) => {
    const { postRequest, STORE_CONTACT_SALES, ADD_CONTACT_SALES } =
        useApiService()

    const validationSchemaTeam = yup.object({
        name: yup
            .string()
            .trim()
            .matches(
                /^[A-Za-z\s]+$/,
                'Only alphabets are allowed for this field'
            )
            .min(2, 'This field must be at least 2 characters')
            .max(30, 'This field must not be greater than 30 characters')
            .required('This field is required!'),
        ...(type != 'upgradePlan' && {
            company_name: yup
                .string()
                .trim()
                .min(2, 'This field must be at least 2 characters')
                .max(30, 'This field must not be greater than 30 characters')
                .required('This field is required!'),
        }),
        email: yup
            .string()
            .email('Invalid email address')
            .required('This field is required!'),
        team_size: yup.string().required('This field is required!'),
        phone_number: yup
            .string()
            .matches(/^\+?[0-9]+$/, 'This field is must be a number')
            .required('This field is required!'),
    })

    const team_size = [
        { key: '0-10', value: '0-10' },
        { key: '11-25', value: '11-25' },
        { key: '26-50', value: '26-50' },
        { key: '50+', value: '50+' },
    ]

    const phone_number = [
        { code: '+93', country: 'Afghanistan' },
        { code: '+355', country: 'Albania' },
        { code: '+213', country: 'Algeria' },
        { code: '+1684', country: 'American Samoa' },
        { code: '+376', country: 'Andorra' },
        { code: '+244', country: 'Angola' },
        { code: '+1264', country: 'Anguilla' },
        { code: '+672', country: 'Antarctica' },
        { code: '+1268', country: 'Antigua and Barbuda' },
        { code: '+54', country: 'Argentina' },
        { code: '+374', country: 'Armenia' },
        { code: '+297', country: 'Aruba' },
        { code: '+61', country: 'Australia' },
        { code: '+43', country: 'Austria' },
        { code: '+994', country: 'Azerbaijan' },
        { code: '+1242', country: 'Bahamas' },
        { code: '+973', country: 'Bahrain' },
        { code: '+880', country: 'Bangladesh' },
        { code: '+1246', country: 'Barbados' },
        { code: '+975', country: 'Bhutan' },
        { code: '+673', country: 'Brunei' },
        { code: '+855', country: 'Cambodia' },
        { code: '+86', country: 'China' },
        { code: '+357', country: 'Cyprus' },
        { code: '+91', country: 'India' },
        { code: '+62', country: 'Indonesia' },
        { code: '+98', country: 'Iran' },
        { code: '+964', country: 'Iraq' },
        { code: '+972', country: 'Israel' },
        { code: '+81', country: 'Japan' },
        { code: '+962', country: 'Jordan' },
        { code: '+7', country: 'Kazakhstan' },
        { code: '+965', country: 'Kuwait' },
        { code: '+996', country: 'Kyrgyzstan' },
        { code: '+856', country: 'Laos' },
        { code: '+961', country: 'Lebanon' },
        { code: '+853', country: 'Macao' },
        { code: '+60', country: 'Malaysia' },
        { code: '+960', country: 'Maldives' },
        { code: '+976', country: 'Mongolia' },
        { code: '+95', country: 'Myanmar' },
        { code: '+977', country: 'Nepal' },
        { code: '+850', country: 'North Korea' },
        { code: '+968', country: 'Oman' },
        { code: '+92', country: 'Pakistan' },
        { code: '+970', country: 'Palestine' },
        { code: '+63', country: 'Philippines' },
        { code: '+974', country: 'Qatar' },
        { code: '+82', country: 'South Korea' },
        { code: '+94', country: 'Sri Lanka' },
        { code: '+963', country: 'Syria' },
        { code: '+886', country: 'Taiwan' },
        { code: '+66', country: 'Thailand' },
        { code: '+670', country: 'Timor-Leste' },
        { code: '+90', country: 'Turkey' },
        { code: '+993', country: 'Turkmenistan' },
        { code: '+971', country: 'United Arab Emirates' },
        { code: '+1', country: 'United States' },
        { code: '+44', country: 'United Kingdom' },
        { code: '+1', country: 'Canada' },
        { code: '+998', country: 'Uzbekistan' },
        { code: '+84', country: 'Vietnam' },
        { code: '+967', country: 'Yemen' },
    ]

    const contactSalesSubmit = (values) => {
        const restUrl =
            type == 'upgradePlan' ? ADD_CONTACT_SALES : STORE_CONTACT_SALES
        if (type != 'upgradePlan') {
            values.country_code = values.phone_code
            delete values.country_short_code
            delete values.phone_code
        } else {
            delete values.country_code
            delete values.company_name
        }

        postRequest(restUrl, values).then((result) => {
            if (!result.isError) {
                const res = result.data
                // toast.success(res.message);
                setSuccessContactSales(true)
            } else {
                toast.error('Something went wrong!')
            }
            setOpen(false)
        })
    }

    const formatName = (value) => {
        if (!value || !value.trim()) return ''
        return value
            .split(/(\s+)/)
            .map((word) =>
                word.trim()
                    ? word.charAt(0).toUpperCase() + word.slice(1)
                    : word
            )
            .join('')
    }

    const formatCompanyName = (value) => {
        return value.replace(/^\s+/, '')
    }

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div
                className={`modal fade dash-custom-modal mxw-550 ${open ? 'show' : ''}`}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <Formik
                                initialValues={{
                                    email: '',
                                    name: '',
                                    company_name: '',
                                    phone_number: '',
                                    team_size: '',
                                    country_code: '',
                                }}
                                validationSchema={validationSchemaTeam}
                                onSubmit={(values, { resetForm }) => {
                                    contactSalesSubmit(values)
                                    resetForm()
                                }}
                            >
                                {({ values, setFieldValue, ...rest }) => (
                                    <Form className="custom-form ad-custom-form ad-form-theme2 gx-3">
                                        <div className="dash-md-title fw-600 dash-purple-text text-center font-1-6em mb-4">
                                            Contact Sales
                                        </div>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                            }}
                                        >
                                            <SvgIcon
                                                color="inherit"
                                                component={CloseIcon}
                                            />
                                        </IconButton>
                                        <div className="form-group position-relative">
                                            <label className="label-pos">
                                                Name<span>*</span>
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'name',
                                                        formatName(
                                                            e.target.value
                                                        )
                                                    )
                                                }
                                            />
                                            <ValidationError name="name" />
                                        </div>
                                        <div className="form-group position-relative">
                                            <label className="label-pos">
                                                Email Address<span>*</span>
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="email"
                                            />
                                            <ValidationError name="email" />
                                        </div>
                                        <div className="form-group position-relative">
                                            {/* <label className="label-pos">Phone number<span>*</span></label>
                                            <Field type="number" className="form-control" name="phone_number" /> */}
                                            <label className="label-pos">
                                                Phone Number<span>*</span>
                                            </label>
                                            {/* <div className="input-group country-code-field">
                                                <Field as="select" name="country_code" className="form-control" >
                                                {phone_number.map(option => (
                                                    <option key={option.code} value={option.code}>
                                                    {option.country} ({option.code})
                                                    </option>
                                                ))}
                                                </Field>
                                                <Field type="number" className="form-control" name="phone_number"  />
                                            </div> */}
                                            {console.log(rest)}
                                            <Field
                                                name="phone_number"
                                                // placeholder="Mobile number"
                                                inputStyleType="outlined"
                                                component={PhoneInputField}
                                                label={false}
                                            />
                                        </div>
                                        {type != 'upgradePlan' && (
                                            <div className="form-group position-relative">
                                                <label className="label-pos">
                                                    Company Name<span>*</span>
                                                </label>
                                                <Field
                                                    type="text"
                                                    className="form-control"
                                                    name="company_name"
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'company_name',
                                                            formatCompanyName(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                />
                                                <ValidationError name="company_name" />
                                            </div>
                                        )}
                                        <div className="form-group position-relative">
                                            <label className="label-pos">
                                                Team Size<span>*</span>
                                            </label>
                                            <Field
                                                name="team_size"
                                                className="form-control"
                                                as="select"
                                            >
                                                <option value=""></option>
                                                {team_size.map((val, key) => {
                                                    return (
                                                        <option
                                                            key={key}
                                                            value={val.key}
                                                        >
                                                            {val.value}
                                                        </option>
                                                    )
                                                })}
                                            </Field>
                                            <ValidationError name="team_size" />
                                        </div>
                                        <div className="mt-3 text-center">
                                            <button
                                                className="dash-theme-btn br-20 fw-400"
                                                type="submit"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactSales
