import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import SkillsOverflow from './SkillsOverflow'

const LeadsStatusTalentSkills = ({ item }) => {
    return (
        <>
            {
                item.selected_skills && item.selected_skills.length > 0 && (
                    <SkillsOverflow
                        label="TOP SKILLS"
                        skillsArray={item.selected_skills.map(
                            (item) => item.name
                        )}
                        labelPosition="column"
                        labelVariant="size3MediumTerTer8"
                        parentStyle={{ marginBottom: '10px' }}
                    />
                )
                // <Stack spacing={"12px"}>
                //     <Typography variant="size3MediumTerTer9">TOP SKILLS</Typography>
                //     <Stack
                //         direction="row"
                //         alignItems="center"
                //         spacing={2}
                //         sx={{ overflowX: 'auto', width: '75%' }}>
                //         {item.selected_skills.map((item, index) => (
                //             <Chip key={index} variant="skillsChip" label={item.name} />
                //         ))}
                //     </Stack>
                // </Stack>
            }

            {
                item.selected_other_skills &&
                    item.selected_other_skills.length > 0 && (
                        <SkillsOverflow
                            label="OTHER SKILLS"
                            skillsArray={item.selected_other_skills.map(
                                (item) => item.name
                            )}
                            overflowWidth="100%"
                            rightSkills={false}
                            labelVariant="size3MediumTerTer8"
                        />
                    )
                // <Stack spacing={"12px"}>
                //     <Typography variant="size3MediumTerTer9">OTHER SKILLS</Typography>
                //     <Stack
                //         direction="row"
                //         alignItems="center"
                //         spacing={2}
                //         sx={{ overflowX: 'auto', width: '75%' }}>
                //         {item.selected_other_skills.map((item, index) => (
                //             <Chip key={index} variant="skillsChip" label={item.name} />
                //         ))}
                //     </Stack>
                // </Stack>
            }
        </>
    )
}

export default LeadsStatusTalentSkills
