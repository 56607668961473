import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Retailtech = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/Retail.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    RetailTech
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Boost your retail operations and customer
                                    experiences
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Use the power of data, digital, and domain
                                expertise to turn your{' '}
                                <strong>
                                    retail vision into retail reality
                                </strong>{' '}
                                with Weteams talent
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Smart point-of-sale systems
                                            </div>
                                            <p>
                                                A "brainy" cash register is a
                                                figurative description of a
                                                smart POS. Modern POS solutions,
                                                on the other hand, can do much
                                                more than just handle cash. They
                                                may store client databases and
                                                manage loyalty programs, as well
                                                as keep track of inventory and
                                                monitor employee productivity.
                                                They also offer analytics
                                                reports and support mobile
                                                payments.
                                                <br />
                                                POS solutions that satisfy the
                                                needs of merchants and their
                                                customers are becoming more
                                                feature-rich, giving them a
                                                competitive edge. This feature
                                                richness transforms POS
                                                solutions into cutting-edge
                                                retail technology tools that
                                                improve store management from
                                                every perspective, including:
                                                <br />
                                                With lightning-fast checkouts,
                                                adaptable payments, tailored
                                                shopping experiences, and
                                                loyalty programs for customers,
                                                you can boost client experience.
                                                <br />
                                                Optimize store management by
                                                giving a clear view of a firm's
                                                inventory levels at all times.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Retail data analytics
                                            </div>
                                            <p>
                                                AI-powered data analytics, which
                                                is one of the most popular
                                                retail technology trends, may
                                                assist businesses in making
                                                better marketing and procurement
                                                choices.
                                                <br />
                                                For example, Take sales
                                                forecasting. Analyzing past
                                                sales data for each SKU against
                                                seasonality, special events,
                                                marketing promotion campaigns,
                                                and other demand-influencing
                                                variables might help retailers
                                                synchronize supply with demand,
                                                avoid stockouts and
                                                overstocking, anticipate
                                                staffing demands, and plan
                                                advertising campaigns.
                                                <br />
                                                With customer sentiment
                                                analysis, you may examine your
                                                consumer's remarks and find out
                                                what they think of your goods,
                                                service, and brand.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Smart kiosks{' '}
                                            </div>
                                            <p>
                                                You may let your consumers order
                                                and pay for items without the
                                                assistance of staff using smart
                                                self-service kiosks and
                                                self-checkout machines. As a
                                                result, merchants can avoid
                                                congestion while shoppers have
                                                access to safer and more
                                                convenient shopping without
                                                having to wait in lines.
                                            </p>
                                            <p>
                                                Modern self-service kiosks and
                                                units may be enhanced with AI
                                                capabilities, such as providing
                                                customers with tailored
                                                advertising and special offers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Retailtech
