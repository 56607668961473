import axios from 'axios'
import googleImg from '../../assets/img/google.svg'
import linkedinImg from '../../assets/img/linkedin.svg'
import apiCall from '../../Services/ApiInstance'
const base_URL = process.env.REACT_APP_base_URL

const SocialLogin = () => {
    const fireSocial = async (provider) => {
        const res = await apiCall
            .get(`${base_URL}/api/v1/login/${provider}`)
            .then((response) => response.data)
            .catch((error) => console.log(error))
        //return res.data
        window.location.href = res
        console.log(res)
    }

    return (
        <>
            <div className="social-access-btns">
                <button
                    className="white-transparent-btn black-text w-100"
                    onClick={() => fireSocial('google')}
                >
                    <span className="d-block mxw-250">
                        <span className="mr-20 d-inline-block sab-icon">
                            <img src={googleImg} />
                        </span>{' '}
                        Continue with Google
                    </span>
                </button>
                {/* <button className="white-transparent-btn black-text w-100 mt-20" onClick={() => fireSocial('linkedin')}>
            <span className="d-block mxw-250"><span className="mr-20 d-inline-block sab-icon"><img src={linkedinImg}/></span> Sign In with Linkedin</span>
        </button> */}
            </div>
        </>
    )
}

export default SocialLogin
