import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const IoT = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/IOT.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    IoT
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    From Innovation to Mass Production. Weteams
                                    provide 'Internet of Things' experts that
                                    brings real value
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Weteams professionals provide IoT software
                                solutions that are customized to your specific
                                needs and help you maximize productivity,
                                flexibility, and security with tailored IoT
                                solutions
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Safe Driving
                                            </div>
                                            <p>
                                                IoT apps can be used to analyse
                                                driver behaviour and provide
                                                coaching based on such
                                                behaviour. The usage of IoT in
                                                self-driving cars is a recent
                                                development. These vehicles
                                                feature cutting-edge gyroscopes
                                                and sensors that are all linked
                                                to the internet and cloud
                                                platforms.
                                            </p>
                                            <p>
                                                The Internet of Things (IoT)
                                                enables self-driving cars to
                                                collect data from a number of
                                                sources that informs them about
                                                road conditions, potholes,
                                                abrupt curves, speed limiters,
                                                and other important information
                                                needed for driving. A Smart City
                                                can benefit greatly from the
                                                installation of self-driving
                                                vehicles to ensure efficient
                                                traffic flow.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                IoT-based Process Automation
                                            </div>
                                            <p>
                                                According to a recent Forrester
                                                study, 33% of organizations are
                                                using IoT-based process
                                                automation. This sort of process
                                                automation refers to operational
                                                procedures that were either
                                                entirely manual in the past or
                                                relied on outdated,
                                                industrial-automation equipment
                                                but have since been modernized
                                                with cutting-edge hardware and
                                                software.
                                            </p>
                                            <p>
                                                Many companies are utilizing
                                                DevOps to enable them to respond
                                                more quickly and flexibly to
                                                changes in the market, customer
                                                needs, and employee
                                                expectations. To add flexibility
                                                and agility into their
                                                operations process, many
                                                organizations introduce this use
                                                case to upgrade their existing
                                                setups. This is significant
                                                because businesses are
                                                increasingly interested in
                                                synchronizing their
                                                manufacturing and business
                                                processes with ever-changing
                                                customer needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Vehicle Fleet Management
                                                (track/trace)
                                            </div>
                                            <p>
                                                The management of vehicles in a
                                                fleet is, by far, the most
                                                common IoT supply chain
                                                application right now. The
                                                greater the number of trucks (or
                                                other modes of transportation)
                                                to manage, the more complicated
                                                it becomes. Cross-border vehicle
                                                management may be especially
                                                daunting, which is why 31% of
                                                businesses have implemented a
                                                professional vehicle fleet
                                                management solution to collect
                                                real-time information.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default IoT
