import React, { useEffect, useState, useRef } from 'react'
import Layout from '../Layout'
import AuthUser from '../../../Services/AuthUser'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import { toast, ToastContainer } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import addMoreIcon from '../../../assets/agency/img/add.png'
import removeIcon from '../../../assets/agency/img/minus.png'
import { Field, Form, Formik, FieldArray } from 'formik'
import * as yup from 'yup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import ValidationError from '../../../Components/Errors/ValidationError'
import { json } from 'react-router-dom'

const animatedComponents = makeAnimated()

const validationSchemaTeam = yup.object({
    resource_webhook_url: yup
        .string()
        .url('Enter a valid URL')
        .required('This field is required!'),
    projects: yup
        .array()
        .of(
            yup.object().shape({
                projects_webhook_url: yup
                    .string()
                    .url('Enter a valid URL')
                    .required('This field is required!'), // these constraints take precedence
                projects_ids: yup
                    .array()
                    .min(1, 'At least 1 project is required')
                    .required('At least 1 project is required'), // these constraints take precedence
            })
        )
        .required('Must have project webhook url'),
})

const Notifications = () => {
    const { http2 } = AuthUser()
    const [notifications, setNotifications] = useState([
        {
            id: 1,
            label: 'Notifications For Overall Resource Utilization',
            isChecked: false,
            resource_utilization_percentage: '20',
            resource_utilization: false,
        },
        {
            id: 2,
            label: 'Weteams Team Member Resource Utilization Alert (Team)',
            isChecked: false,
            team_member_resource_utilization_percentage: '20',
            team_member_resource_utilization: false,
        },
        {
            id: 3,
            label: 'Weteams Sprint Tracking & Status Alert',
            isChecked: false,
            sprint_tracking_status_alert: false,
        },
        {
            id: 4,
            label: 'Weteams Time Tracking Alert',
            isChecked: false,
            sprint_time_tracking: false,
        },
        {
            id: 5,
            label: 'Weteams Project Planning Alert',
            isChecked: false,
            project_planning_alert: false,
        },
    ])
    const [isLoading, setIsLoading] = useState(false)
    const [isSlackLoading, setIsSlackLoading] = useState(false)
    const [isChanging, setIsChanging] = useState(false)
    const [selectedProjectForWebhook, setSelectedProjectForWebhook] =
        useState(false)

    const switchButton = (id) => {
        setIsChanging(true)
        setNotifications((prevNotifications) =>
            prevNotifications.map((checkbox) =>
                checkbox.id === id
                    ? { ...checkbox, isChecked: !checkbox.isChecked }
                    : checkbox
            )
        )
    }

    const handleInputChange = (id, fieldName, value) => {
        setIsChanging(true)
        setNotifications((prevNotifications) =>
            prevNotifications.map((checkbox) =>
                checkbox.id === id
                    ? { ...checkbox, [fieldName]: value }
                    : checkbox
            )
        )
    }

    const handleCheckboxClick = (id) => {
        switchButton(id)
    }

    const storeNotificationSetting = () => {
        if (isChanging) {
            try {
                http2
                    .post(`/notification/setting`, notifications)
                    .then((response) => {
                        let result = response.data
                        if (result.success) {
                            setIsChanging(false)
                            //toast.success(result.message);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            toast.error(error.response.data.message)
                        }
                    })
            } catch (error) {
                toast.error(error.response.data.message)
                console.log(error)
            }
        }
    }
    const getNotificationSetting = () => {
        setIsLoading(true)
        http2.get(`/get-notification/setting`).then((res) => {
            let result = res.data.data
            setNotifications([
                {
                    id: 1,
                    label: 'Notifications For Overall Resource Utilization',
                    isChecked: result?.resource_utilization
                        ? result?.resource_utilization
                        : false,
                    resource_utilization_percentage:
                        result?.resource_utilization_percentage
                            ? result?.resource_utilization_percentage
                            : '20',
                    resource_utilization: true,
                },
                {
                    id: 2,
                    label: 'Weteams Team Member Resource Utilization Alert (Team)',
                    isChecked: result?.team_member_resource_utilization
                        ? result?.team_member_resource_utilization
                        : false,
                    team_member_resource_utilization_percentage:
                        result?.team_member_resource_utilization_percentage
                            ? result?.team_member_resource_utilization_percentage
                            : '20',
                    team_member_resource_utilization: true,
                },
                {
                    id: 3,
                    label: 'Weteams Sprint Tracking & Status Alert',
                    isChecked: result?.sprint_tracking_status_alert
                        ? result?.sprint_tracking_status_alert
                        : false,
                    sprint_tracking_status_alert: false,
                },
                {
                    id: 4,
                    label: 'Weteams Time Tracking Alert',
                    isChecked: result?.sprint_time_tracking
                        ? result?.sprint_time_tracking
                        : false,
                    sprint_time_tracking: false,
                },
                {
                    id: 5,
                    label: 'Weteams Project Planning Alert',
                    isChecked: result?.project_planning_alert
                        ? result?.project_planning_alert
                        : false,
                    project_planning_alert: false,
                },
            ])
            setIsLoading(false)
        })
    }

    const checkSlackStatus = selectedProjectForWebhook?.resource_webhook_url
        ? true
        : false
    const [manageNotifaction, setManageNotifaction] = useState(checkSlackStatus)
    const slackHandleCheckboxClick = () => {
        setManageNotifaction(!manageNotifaction)
    }
    // console.log("checkSlackStatus", checkSlackStatus)
    useEffect(() => {
        getSlackWebHook()
        getNotificationSetting()
    }, [])

    useEffect(() => {
        setManageNotifaction(checkSlackStatus)
    }, [checkSlackStatus])
    // console.log("manageNotifaction", manageNotifaction)

    useEffect(() => {
        storeNotificationSetting()
    }, [isChanging])

    // Slack Form
    const storeSlackWebHook = (fields) => {
        try {
            setIsSlackLoading(true)
            http2
                .post(`/slack-web-hook-store`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setIsSlackLoading(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const getSlackWebHook = () => {
        try {
            http2
                .get(`/get-slack-web-hook`)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        setSelectedProjectForWebhook(result.data)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    const selectRef = useRef(null)
    const [reload, setReload] = useState(false)
    const [projectsItem, setProjectsItem] = useState([])
    const getProjectsUser = () => {
        setIsLoading(true)
        http2.get(`/projects`).then((res) => {
            let result = res.data.data
            setProjectsItem(result.projects)
            setIsLoading(false)
        })
    }
    const projectsItemData = projectsItem.map((item) => ({
        value: item.id,
        label: item.project_name,
    }))
    const handleProjectChange = (index, e, f) => {
        f.setFieldValue(`projects.${index}.projects_ids`, e)
    }
    useEffect(() => {
        getProjectsUser()
        setReload()
    }, [reload])

    const getUniqueOptions = (currentIndex, allOptions, selectedProjects) => {
        let selectedProjectIds = []
        if (currentIndex >= 0 && currentIndex < selectedProjects.length) {
            selectedProjectIds = selectedProjects
                .filter((project, index) => index !== currentIndex)
                .flatMap((project) =>
                    (project.projects_ids || []).map((idObj) => idObj.value)
                )
        }
        const uniqueOptions = allOptions.filter(
            (option) => !selectedProjectIds.includes(option.value)
        )
        return uniqueOptions
    }
    return (
        <>
            <Layout>
                {/* <ToastContainer hideProgressBar={true} /> */}
                <div className="ad-white-card p-4 custom-form ad-custom-form ad-form-theme2">
                    <div className="d-inline-flex align-items-center mb-4">
                        <div className="me-2">Slack</div>
                        <div className="notification-switch main-switch">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={manageNotifaction}
                                    onChange={slackHandleCheckboxClick}
                                />
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="ms-2">Manage Notifications</div>
                    </div>

                    {manageNotifaction ? (
                        <>
                            <div className="dash-md-title dash-black-text fw-600 ad-font-1-2em mb-2">
                                Manage Notifications
                            </div>
                            {isLoading ? (
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={5}
                                    height={65}
                                />
                            ) : (
                                notifications.map((item) => (
                                    <>
                                        <div
                                            className={`notifaction-box ${item.isChecked ? 'open' : ''}`}
                                            key={item.id}
                                        >
                                            <div className="d-flex align-items-center justify-content-between notifaction-header">
                                                <div className="dash-md-title dash-lgray-text fw-600 ad-font-1-1em">
                                                    <span className="ad-dot"></span>{' '}
                                                    {item.label}
                                                </div>
                                                <div className="notification-switch">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={
                                                                item.isChecked
                                                            }
                                                            onChange={() =>
                                                                handleCheckboxClick(
                                                                    item.id
                                                                )
                                                            }
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="notifaction-body">
                                                {item.resource_utilization ? (
                                                    <>
                                                        <p>
                                                            Set minimum
                                                            Resources
                                                            utilization
                                                            percentage for your
                                                            team
                                                        </p>
                                                        <div className="position-relative mxw-100">
                                                            <input
                                                                type="number"
                                                                className="form-control pe-4"
                                                                name="resource_utilization_percentage"
                                                                value={
                                                                    item.resource_utilization_percentage
                                                                }
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        item.id,
                                                                        'resource_utilization_percentage',
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            <span className="percent-sign">
                                                                %
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {item.team_member_resource_utilization ? (
                                                    <>
                                                        <p>
                                                            Set minimum Weteam
                                                            Resources
                                                            utilization
                                                            percentage
                                                        </p>
                                                        <div className="position-relative mxw-100">
                                                            <input
                                                                type="number"
                                                                className="form-control pe-4"
                                                                name="team_member_resource_utilization_percentage"
                                                                value={
                                                                    item.team_member_resource_utilization_percentage
                                                                }
                                                                onChange={(e) =>
                                                                    handleInputChange(
                                                                        item.id,
                                                                        'team_member_resource_utilization_percentage',
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                            <span className="percent-sign">
                                                                %
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))
                            )}
                        </>
                    ) : (
                        <>
                            <div className="dash-md-title dash-black-text fw-600 ad-font-1-2em mb-2">
                                Slack
                            </div>
                            {isLoading ? (
                                <Skeleton
                                    containerClassName="cs-skeleton"
                                    count={5}
                                    height={65}
                                />
                            ) : (
                                <Formik
                                    initialValues={{
                                        resource_webhook_url:
                                            selectedProjectForWebhook.resource_webhook_url ||
                                            '',
                                        projects:
                                            selectedProjectForWebhook?.projects
                                                ? selectedProjectForWebhook?.projects
                                                : [
                                                      {
                                                          projects_webhook_url:
                                                              '',
                                                          projects_ids: [],
                                                      },
                                                  ],
                                    }}
                                    validationSchema={validationSchemaTeam}
                                    onSubmit={(
                                        values,
                                        { resetForm, setFieldValue }
                                    ) => {
                                        storeSlackWebHook(values)
                                    }}
                                >
                                    {({
                                        values,
                                        setFieldValue,
                                        field,
                                        form,
                                        resetForm,
                                        formik,
                                    }) => (
                                        <>
                                            <Form autoComplete="off">
                                                <div className="row mb-3">
                                                    <div className="col-md-4">
                                                        <div className="position-relative">
                                                            <label className="label-pos">
                                                                Resource Webhook
                                                                Url
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                className="form-control"
                                                                name="resource_webhook_url"
                                                            />
                                                        </div>
                                                        <ValidationError
                                                            name={`resource_webhook_url`}
                                                        />
                                                    </div>
                                                </div>
                                                <FieldArray
                                                    name="projects"
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            {values.projects.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        className="row mb-3"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <div className="col-md-4">
                                                                            <div className="position-relative notification-select-group">
                                                                                <label className="label-pos">
                                                                                    Projects
                                                                                </label>
                                                                                <Field
                                                                                    name={`projects.${index}.projects_ids`}
                                                                                >
                                                                                    {({
                                                                                        field,
                                                                                        form,
                                                                                    }) => (
                                                                                        <>
                                                                                            <Select
                                                                                                value={
                                                                                                    field.value ||
                                                                                                    item?.projects_ids
                                                                                                }
                                                                                                ref={
                                                                                                    selectRef
                                                                                                }
                                                                                                className="custom-select notifaction-project"
                                                                                                placeholder="Select project"
                                                                                                closeMenuOnSelect={
                                                                                                    true
                                                                                                }
                                                                                                components={
                                                                                                    animatedComponents
                                                                                                }
                                                                                                isMulti
                                                                                                options={getUniqueOptions(
                                                                                                    index,
                                                                                                    projectsItemData,
                                                                                                    form
                                                                                                        .values
                                                                                                        .projects
                                                                                                )}
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleProjectChange(
                                                                                                        index,
                                                                                                        e,
                                                                                                        form
                                                                                                    )
                                                                                                }
                                                                                                styles={{
                                                                                                    menu: (
                                                                                                        provided
                                                                                                    ) => ({
                                                                                                        ...provided,
                                                                                                        zIndex: 9, // Set the desired z-index value
                                                                                                    }),
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            <ValidationError
                                                                                name={`projects.${index}.projects_ids`}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="position-relative">
                                                                                <label className="label-pos">
                                                                                    Projects
                                                                                    Webhook
                                                                                    Url
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name={`projects.${index}.projects_webhook_url`}
                                                                                />
                                                                            </div>
                                                                            <ValidationError
                                                                                name={`projects.${index}.projects_webhook_url`}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            {values
                                                                                .projects
                                                                                .length <=
                                                                            1 ? (
                                                                                ''
                                                                            ) : (
                                                                                <button
                                                                                    className="ad-remove-btn"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.remove(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            removeIcon
                                                                                        }
                                                                                        alt="icon"
                                                                                    />
                                                                                </button>
                                                                            )}

                                                                            {index ===
                                                                                values
                                                                                    .projects
                                                                                    .length -
                                                                                    1 && (
                                                                                <button
                                                                                    className="ad-add-more-btn"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.push(
                                                                                            {
                                                                                                projects_webhook_url:
                                                                                                    '',
                                                                                                projects_ids:
                                                                                                    [],
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            addMoreIcon
                                                                                        }
                                                                                        alt="icon"
                                                                                    />
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                />

                                                {isSlackLoading ? (
                                                    <BtnSpinner cls="ad-theme-btn ad-create-btn px-5" />
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="ad-theme-btn ad-create-btn px-5"
                                                    >
                                                        Submit
                                                    </button>
                                                )}
                                            </Form>
                                        </>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </div>
            </Layout>
        </>
    )
}

export default Notifications
