import { useState, useEffect, useRef } from 'react'
import { useField } from 'formik'
import ValidationError from '../../Errors/ValidationError'

const RoleSearch = ({
    stacks,
    setSkills,
    setskillsSetArrayId,
    setSelectedSkills,
    handleRelatedSkills,
    field,
    classAdd,
    form,
    ...props
}) => {
    //const [stacks, handleRelatedSkills] = useField(props.stacks);
    //console.log("form",setSkills);
    const [searchRoleId, setSearchRoleId] = useState('')
    const [allStacks, setAllStacks] = useState(stacks || [])
    const [searchRole, setSearchRole] = useState('')

    const [selected, setSelected] = useState(form?.values?.role || '')

    const [isOpen, setIsOpen] = useState(false)
    //const [shouldBlur, setShouldBlur] = useState(true);

    let shouldBlur = true

    const selectHandle = (val) => {
        setSelected(val.name)
        setSearchRole('')

        if (val) {
            //setShouldBlur(true)
            setSearchRoleId(val.id)
            setIsOpen(false)
            form.setFieldValue('role', val.name)
            form.setFieldValue('stack_id', val.id)

            // this state mange for not data empty
            //console.log("val.id",val.id);

            //related_skills
            form.setFieldValue('related_skills', val.technologies)

            handleRelatedSkills(val)
            return true
        }
    }

    const handleChange = ({ target }) => {
        //console.log(form?.values);
        setSearchRoleId('')
        setSearchRole(target.value)
        form.setFieldValue('role', target.value)
        form.setFieldValue('related_skills', '')
        form.setFieldValue('skill_ids', '')
        form.setFieldValue('stack_id', '')
        form.setFieldValue('skills_name', '')
        var hiringTalent = JSON.parse(localStorage.getItem('hiringTalent'))
        delete hiringTalent.role
        delete hiringTalent.skill_ids
        delete hiringTalent.related_skills
        delete hiringTalent.stack_id
        delete hiringTalent.skills_name
        localStorage.setItem('hiringTalent', JSON.stringify(hiringTalent))

        setskillsSetArrayId([])
        setSkills([])
        setSelectedSkills([])
        let tempOptions = [...stacks]

        tempOptions = tempOptions.filter((obj) =>
            obj.name.toLowerCase().startsWith(target.value?.toLowerCase())
        )
        setAllStacks(tempOptions)
        setIsOpen(target.value.length > 0)
        //setShouldBlur(target.value.length>0);
    }

    const handleFocus = () => {
        document.querySelector('#domain-search').style.display = 'none'
    }

    const handleBlur = (e) => {
        if (shouldBlur) setFirstRoleSkill()
    }

    const setFirstRoleSkill = () => {
        if (allStacks?.length > 0) {
            setSearchRoleId(allStacks[0].id)
            form.setFieldValue('role', allStacks[0].name)
            form.setFieldValue('stack_id', allStacks[0].id)
            form.setFieldValue('related_skills', allStacks[0].technologies)
            handleRelatedSkills(allStacks[0])
            setIsOpen(false)
            shouldBlur = true
            return true
        }
    }

    return (
        <>
            <div className={classAdd ? 'col-md-12' : 'col-md-6'}>
                <div className="form-group ">
                    {/* <label htmlFor="role" className="asterisks"></label> */}
                    <div className="position-relative">
                        <input
                            name={field.name}
                            className="form-control text-capitalize"
                            asterisks="asterisks"
                            type="text"
                            placeholder="Search For Role"
                            id="roleInput"
                            value={field.value}
                            onChange={handleChange}
                            onBlur={() => {
                                setTimeout(() => {
                                    handleBlur()
                                }, 500)
                            }}
                            onFocus={handleFocus}
                        />
                        <ValidationError name="stack_id" />
                        {isOpen && (
                            <div
                                className="my-role-search cs-dropdown-block"
                                id="my-role-search"
                            >
                                {!allStacks.length ? (
                                    <div> No Items </div>
                                ) : (
                                    allStacks.map((option, index) => (
                                        <div
                                            key={`${index}`}
                                            onClick={() => {
                                                shouldBlur = false
                                                selectHandle(option)
                                            }}
                                            className="autocomplete-items"
                                        >
                                            {option.name}
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoleSearch
