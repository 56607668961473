import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const RPA = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/RPA.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    RPA
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Empower your business and shape the future
                                    with RPA. Make Things Happen
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Our RPA development talent entails in-depth
                                technology expertise and is perfectly competent
                                to handle your simple-to-complex project
                                requirements with sheer ease
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Customer Service
                                            </div>
                                            <p>
                                                Nowadays customers are used to
                                                receiving prompt responses and
                                                convenient solutions. With the
                                                use of automation, it is
                                                feasible to achieve the outcomes
                                                that customers desire. Automated
                                                customer service systems can
                                                categorize questions and provide
                                                customers with quick answers.{' '}
                                                <br />
                                                The automated system is capable
                                                of classifying requests into a
                                                variety of groups, including the
                                                tech department, service
                                                department, and others. Sorting
                                                makes sure the queries get to
                                                the correct customer service
                                                representative for a prompt
                                                response. The customer's call
                                                does not need to be passed from
                                                one executive to another.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Processing HR Information
                                            </div>
                                            <p>
                                                Processing and storing HR data
                                                is a difficult task. It is a
                                                long and sometimes tedious
                                                procedure. It can be challenging
                                                to filter through and arrange
                                                the enormous volumes of
                                                personnel data that a successful
                                                company produces.
                                                <br />
                                                The RPA system is helping in
                                                gathering and organizing all the
                                                data needed by your HR
                                                department. RPA can filter and
                                                store data such as personnel
                                                history, payroll,
                                                reimbursements, and degree of
                                                training. It can perform all of
                                                the routine duties so that your
                                                HR staff may concentrate on the
                                                social aspect.{' '}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Financial Services
                                            </div>
                                            <p>
                                                RPA aids in bridging gaps
                                                between several legacy system
                                                applications.
                                                <br />
                                                For instance, many Canadian
                                                banks have successfully adopted
                                                RPA technology.
                                                <br />
                                                The largest financial
                                                institution in Canada has been
                                                enhancing customer service with
                                                intelligent chatbots for more
                                                than a year. Few other top
                                                companies have also integrated
                                                RPA technology into their
                                                procedures and have already had
                                                some success. They can now
                                                handle, utilize, and feed data
                                                into the main corporate
                                                application by computing,
                                                copying, pasting, accessing, or
                                                using the current business
                                                rules.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default RPA
