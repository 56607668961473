import React, { useState, useRef, useEffect } from 'react'
import AddNewAddress from './AddNewAddress'
import AgencyModal from '../Modals/AgencyModal'
import ContactSalesModal from './ContactSalesModal'
import AgencySmModal from '../Modals/AgencySmModal'
import AuthUser from '../../Services/AuthUser'
import UpgradePlanItem from './UpgradePlanItem'
import { toast, ToastContainer } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import AddressesList from './AddressesList'

import questionIcon from '../../assets/agency/img/question-icon.svg'
import BtnSpinner from '../Spinners/BtnSpinner'
import EditAddress from './EditAddress'
import successCheck from '../../assets/agency/img/success-check.svg'
import ContactSales from '../Pricing/ContactSales'

const UpgradeYourPlan = ({
    selectPlanOption,
    planInfo,
    isLoading,
    selectAddress,
    currentSubscription,
}) => {
    const [open, setOpen] = useState(false)
    const sectionRef = useRef(null)
    const sectionEditRef = useRef(null)
    // const [currentPlan, setCurrentPlan] = useState(currentSubscription?.plan_name);

    const openContactSalesModal = () => {
        setOpen(true)
    }
    // const close = () => {
    //   setOpen(false);
    // };

    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const clickOpenModal = () => {
        setOpenSuccessModal(true)
        setOpen(false)
    }
    // const closeSuccessModal = () => {
    //   setOpenSuccessModal(false);
    // };
    const [newAddress, setNewAddress] = useState(false)
    const [editAddress, setEditAddress] = useState(false)
    const openAddNewAddress = () => {
        setNewAddress(true)
        setEditAddress(false)
    }
    const [editAddressItem, setEditAddressItem] = useState()
    const openEditAddress = (editItem) => {
        setEditAddress(!editAddress)
        setEditAddressItem(editItem)
        setNewAddress(false)
    }

    // Get Address
    const { http2 } = AuthUser()
    const [reload, setReload] = useState(false)
    const [isAddressLoading, setIsAddressLoading] = useState(false)
    const [addressList, setAddressList] = useState([])
    const getAddressesList = () => {
        setIsAddressLoading(true)
        http2.get(`/get-billing-address`).then((res) => {
            console.log('res', res)
            let result = res.data.data
            setAddressList(result.billing_address)
            setIsAddressLoading(false)
        })
    }
    const [alertDeleteModal, setAlertDeleteModal] = useState(false)
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [deleteItemId, setDeleteItemId] = useState('')
    const addressDeleteAlertModal = (deleteitem) => {
        setAlertDeleteModal(true)
        setDeleteItemId(deleteitem)
    }
    const closeAlertDeleteModal = () => {
        setAlertDeleteModal(false)
    }

    //
    const [successContactSales, setSuccessContactSales] = useState(false)
    const closeSuccessContactSales = () => {
        setSuccessContactSales(false)
    }
    const getDeleteAddress = () => {
        setIsDeleteLoading(true)
        try {
            http2
                .post(`/delete-billing-address/${deleteItemId}`)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message)
                        setIsDeleteLoading(false)
                        setAlertDeleteModal(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                        setIsDeleteLoading(false)
                        setAlertDeleteModal(false)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAddressesList()
        // getDeleteAddress();
        setReload()
    }, [reload])

    useEffect(() => {
        if (newAddress && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        if (editAddress && sectionEditRef.current) {
            sectionEditRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [newAddress, editAddress])

    // console.log("getSelectedAddress", getSelectedAddress)
    // console.log("getPlanItem", getPlanItem)
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div className="dash-md-title dash-black-text fw-600 ad-font-1-2em mb-4">
                Select Your Plan
            </div>
            <div className="ad-md-title dash-font-1em mb-2 ad-gray-text">
                Billing Plan
            </div>
            {isLoading ? (
                <Skeleton
                    containerClassName="cs-skeleton"
                    count={2}
                    height={120}
                />
            ) : planInfo.length > 0 ? (
                <div className="ads-plan-list">
                    {planInfo.map((item) => (
                        <UpgradePlanItem
                            key={item.id}
                            item={item}
                            selectPlanOption={(item) => {
                                selectPlanOption(item)
                                // setCurrentPlan(item.plan_name);
                            }}
                            currentSubscription={currentSubscription?.plan_name}
                        />
                    ))}
                </div>
            ) : (
                ''
            )}
            <div className="ad-white-card p-20 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="ad-main-heading ad-font-20">
                        <span className="ad-gradient-text">Enterprises</span>
                    </div>
                    <div>
                        <button
                            className="ad-gradient-btn"
                            type="button"
                            onClick={openContactSalesModal}
                        >
                            Contact Sales
                        </button>
                    </div>
                </div>
            </div>
            <div className="ad-billing-bottom-block">
                <div className="address-list">
                    {addressList &&
                    Object.keys(addressList).length > 0 &&
                    addressList.country != '' ? (
                        <AddressesList
                            isAddressLoading={isAddressLoading}
                            addressList={[addressList]}
                            addressDeleteAlertModal={addressDeleteAlertModal}
                            selectAddress={selectAddress}
                            openEditAddress={openEditAddress}
                        />
                    ) : (
                        ''
                    )}
                    {!addressList ||
                    Object.keys(addressList).length == 0 ||
                    addressList.country == '' ? (
                        <div className="mt-1">
                            <button
                                className="add-address-btn"
                                type="button"
                                onClick={openAddNewAddress}
                            >
                                <span className="ad-plus-icon"></span>{' '}
                                {addressList.length > 0
                                    ? 'Add Company Details and Address'
                                    : 'Add Company Details and Address'}
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                {newAddress ? (
                    <div className="addAddressForm" ref={sectionRef}>
                        <AddNewAddress
                            setReload={setReload}
                            setNewAddress={setNewAddress}
                        />
                    </div>
                ) : (
                    ''
                )}
                {editAddress ? (
                    <div className="addAddressForm" ref={sectionEditRef}>
                        <EditAddress
                            setReload={setReload}
                            setEditAddress={setEditAddress}
                            editAddressItem={editAddressItem}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
            {/* <AgencyModal open={open}>
        <ContactSalesModal 
          setOpen={setOpen} 
          clickOpenModal={clickOpenModal} 
          setSuccessContactSales={setSuccessContactSales} 
        />
      </AgencyModal> */}

            <ContactSales
                open={open}
                type="upgradePlan"
                setOpen={setOpen}
                setSuccessContactSales={setSuccessContactSales}
            />

            <AgencySmModal open={successContactSales} close={false}>
                <div className="text-center">
                    <img src={successCheck} alt="question" />
                </div>
                <div className="mt-3 content text-center">
                    <p>
                        Thank you for showing interest! Our Sales <br />
                        Team will get back to you shortly
                    </p>
                </div>
                <div className="mt-30 text-center">
                    <button
                        type="button"
                        className="ad-theme-btn ad-sm-btn"
                        onClick={closeSuccessContactSales}
                    >
                        Ok, got it!
                    </button>
                </div>
            </AgencySmModal>

            <AgencySmModal
                open={alertDeleteModal}
                close={closeAlertDeleteModal}
            >
                <div className="text-center">
                    <img src={questionIcon} alt="question" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Are you sure?
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        Do you really want to delete?
                    </p>
                </div>
                <div className="mt-3 text-center row">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={closeAlertDeleteModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        {isDeleteLoading ? (
                            <BtnSpinner
                                cls="ad-theme-btn ad-sm-btn w-100"
                                loader="true"
                            />
                        ) : (
                            <button
                                type="button"
                                className="ad-theme-btn ad-sm-btn w-100"
                                onClick={getDeleteAddress}
                            >
                                Yes
                            </button>
                        )}
                    </div>
                </div>
            </AgencySmModal>
            {/* <AgencySmModal open={alertDeleteModal} close={closeAlertDeleteModal}>
        <div className="text-center">
          <img src={questionIcon} alt="question" />
        </div>
        <div className="mt-3 ad-md-title text-center">Are you sure?</div>
        <div className="mt-2 content text-center">
          <p className="ad-lgray-text">Do you really want to delete?</p>
        </div>
        <div className="mt-3 text-center row">
          <div className="col-6">
            <button
              type="button"
              className="ad-gray-btn ad-sm-btn w-100"
              onClick={closeAlertDeleteModal}
            >
              Cancel
            </button>
          </div>
          <div className="col-6">
            {isDeleteLoading ? (
              <BtnSpinner cls="ad-theme-btn ad-sm-btn w-100" loader="true" />
            ) : (
              <button
                type="button"
                className="ad-theme-btn ad-sm-btn w-100"
                onClick={getDeleteAddress}
              >
                Yes
              </button>
            )}
          </div>
        </div>
      </AgencySmModal> */}
        </>
    )
}

export default UpgradeYourPlan
