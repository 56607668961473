import TopSection from './TopSection'
import Solution from './Solution'
import Testimonial from './Testimonial'
// import Journey from "./Journey";
import Footer from '../../Common/Footer'

const Home = () => {
    return (
        <>
            <TopSection />
            <Solution />
            <Testimonial />
            <Footer page="manageTeam" />
        </>
    )
}

export default Home
