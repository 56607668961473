import { Field, ErrorMessage, useField } from 'formik'
import ValidationError from '../../Errors/ValidationError'

const ExperienceSelectField = ({
    placeholder,
    data,
    field, // { name, value, onChange, onBlur }
    form,
    ...props
}) => {
    //const [field, form] = useField(props);

    const handleFocus = () => {
        let check_stack_id = form?.values?.stack_id
        if (check_stack_id == null || check_stack_id == '') {
            form.setFieldValue('role', '')
        }
        //document.querySelector(".my-role-search").style.display = "none";
        //document.querySelector(".domain-search").style.display = "none";
    }

    // const handleChange = ({target}) =>{
    //   console.log(target);
    //   //form.setFieldValue('role', '')
    // }
    //console.log(field);

    return (
        <>
            <div className="form-group">
                <Field
                    name={field.name}
                    className="form-control"
                    as="select"
                    onFocus={handleFocus}
                >
                    {placeholder ? <option value="">{placeholder}</option> : ''}
                    {data.map((val, key) => {
                        return (
                            <option key={key} value={val.key}>
                                {val.value}
                            </option>
                        )
                    })}
                </Field>
                <ValidationError name={field.name} />
            </div>
        </>
    )
}

export default ExperienceSelectField
