import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import { useParams } from 'react-router-dom'
import AuthUser from '../../../Services/AuthUser'
import DetailOverview from '../../../Components/Leads/DetailOverview'
import LeadMyTeamFilter from '../../../Components/Leads/LeadMyTeamFilter'
import LeadMatchTalent from '../../../Components/Leads/LeadMatchTalent'

const LeadDetails = () => {
    const params = useParams()
    const { id } = params
    const { http2 } = AuthUser()
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [leadDetailData, setLeadDetailData] = useState('')
    const [goodMatch, setGoodMatch] = useState('')
    const [mydata, setMydata] = useState('')
    const [topMatch, setTopMatch] = useState('')
    const [matched, setMatched] = useState('')
    const [clearVal, setClearVal] = useState(false)
    const [searchBy, setSearchBy] = useState('top-match')
    const [filterData, setFilterData] = useState('')
    const [skillsMatch, setSkillsMatch] = useState([])

    const initialValues = {
        keyword: !clearVal ? '' : '',
        experience: !clearVal ? leadDetailData?.experience || '' : '',
        role_id: !clearVal ? leadDetailData?.stack_role_id || '' : '',
        skills: !clearVal ? skillsMatch.map((skill) => skill.id) || [] : [],
        allocation: !clearVal ? '' : '',
    }

    const getSearchData = (searchItem) => {
        setFilterData(searchItem)
        getLeadList(searchItem)
    }

    const getLeadList = (filter = null, isInitialLoad = false) => {
        setIsLoading(true)
        let params = {
            hiring_id: id,
            search_type: searchBy,
            is_search: isInitialLoad ? true : filter ? true : false,
            keyword: isInitialLoad
                ? initialValues.keyword
                : filter?.keyword || '',
            experience: isInitialLoad
                ? initialValues.experience
                : filter?.experience || '',
            role_id: isInitialLoad
                ? initialValues.role_id
                : filter?.role_id || '',
            skills: isInitialLoad ? initialValues.skills : filter?.skills || '',
            is_clear: isInitialLoad ? false : filter?.role_id ? clearVal : true,
            allocation: isInitialLoad
                ? initialValues.allocation
                : filter?.allocation
                ? filter.allocation.match(/\d+/)[0]
                : '',
        }

        http2.post(`/leads-details`, params).then((res) => {
            try {
                let result = res.data.data
                setMydata(result.data.matched_talent_rates)
                setGoodMatch(result.good_match)
                setTopMatch(result.top_match)
                setMatched(result?.data?.matched_candidates || [])
                setLeadDetailData(result.data)
                setSkillsMatch(result.data.skills)
                setIsLoading(false)
            } catch (error) {
                console.log(error)
            }
        })
    }

    const [roleData, setRoleData] = useState('')
    const rolesList = () => {
        http2.get(`/roles/list`).then((res) => {
            let result = res.data.data
            setRoleData(result)
        })
    }

    useEffect(() => {
        getLeadList(null, true)
        rolesList()
    }, [reload])

    return (
        <>
            <Layout>
                <div className="ad-white-card p-0">
                    <DetailOverview
                        leadDetailData={leadDetailData}
                        isLoading={isLoading}
                    />
                    <div className="lead-my-team-box p-3">
                        <div className="ad-md-title dash-font-1em ad-gray-text mb-3">
                            My Team
                        </div>
                        <LeadMyTeamFilter
                            roleData={roleData}
                            setclearVal={setClearVal}
                            clearVal={clearVal}
                            leadDetailData={leadDetailData}
                            getSearchData={(val) => {
                                getSearchData(val)
                            }}
                            isLoading={isLoading}
                        />
                        <LeadMatchTalent
                            key={
                                (filterData?.keyword || 'emptyfilter') +
                                String(Math.random() + Math.random())
                            }
                            updated_data={mydata}
                            goodMatch={goodMatch}
                            topMatch={topMatch}
                            matched={matched}
                            setSearchBy={setSearchBy}
                            required_skill_ids={
                                leadDetailData?.required_skill_ids
                            }
                            isLoading={isLoading}
                            setReload={setReload}
                        />
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default LeadDetails
