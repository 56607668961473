import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/material'

const LeadsSkillsModal = ({ skills, skillsChipType, ...props }) => {
    return (
        <Dialog {...props}>
            <DialogTitle>
                {skillsChipType == 'teamListChip'
                    ? 'All Projects'
                    : 'All Skills'}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    props.onClose()
                }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <SvgIcon color="inherit" component={CloseIcon} />
            </IconButton>
            <DialogContent dividers>
                <Grid container rowSpacing={1} columnSpacing={2}>
                    {skills &&
                        skills.map((item) => (
                            <Grid item>
                                <Box>
                                    <Chip
                                        variant={
                                            skillsChipType == 'teamListChip'
                                                ? skillsChipType
                                                : 'skillsChip'
                                        }
                                        label={item}
                                    />
                                </Box>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default LeadsSkillsModal
