import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
/** Redux store config object import */
import store from './Redux/Store'
/** Redux provider for the application */
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    // <React.StrictMode>
    /** Redux provider with config object */
    <Provider store={store}>
        <App />
    </Provider>
    // </React.StrictMode>
)
