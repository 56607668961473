import { useEffect, useState } from 'react'

const PreviewImage = ({ e, file, avatar }) => {
    const [preview, setPreview] = useState(null)
    //const preview = avatar||'null'
    if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            setPreview(e.target.result)
        }
    }
    useEffect(() => {
        setPreview(avatar || 'null')
    }, [avatar])
    return <>{preview ? <img src={preview} alt={preview} /> : ''}</>
}

export default PreviewImage
