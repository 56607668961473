import Layout from '../Layout'
import visaCard from '../../../assets/agency/img/visa.png'
import masterCard from '../../../assets/agency/img/master.png'
import maestroCard from '../../../assets/agency/img/maestro.png'
import americanCard from '../../../assets/agency/img/american.png'
import discoverCard from '../../../assets/agency/img/discover.png'
import UpgradeYourPlan from '../../../Components/BillingSubscription/UpgradeYourPlan'
import BillEstimate from '../../../Components/BillingSubscription/BillEstimate'
import { useState } from 'react'
import AuthUser from '../../../Services/AuthUser'
import { useEffect } from 'react'
import { useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import useRazorpay from 'react-razorpay'
import logo from '../../../assets/dashboard/img/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'

const UpgradePlanLayout = () => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { currency } = user
    let location = useLocation()

    const { http2 } = AuthUser()
    const sectionRef = useRef(null)
    const [Razorpay] = useRazorpay()
    // Get plan
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [paymentIsLoading, setPaymentIsLoading] = useState(false)
    const [planInfo, setPlanInfo] = useState([])

    const navigate = useNavigate()

    const getPlanYour = () => {
        setIsLoading(true)
        http2.get(`/subscription-plan-list`).then((res) => {
            let result = res.data
            setPlanInfo(result.data.subscription_plans)
            setIsLoading(false)
        })
    }
    // Pay for upgrade plan
    const [isSelectedPlan, setIsSelectedPlan] = useState(false)
    const [isSelectedAddress, setIsSelectedAddress] = useState(false)
    // Get Selected  Plan
    const [getPlanItem, setGetPlanItem] = useState()
    const selectPlanOption = (planItem) => {
        setGetPlanItem(planItem)
        setIsSelectedPlan(true)
    }
    // Get Selected Address
    const [getSelectedAddress, setGetSelectedAddress] = useState()
    const selectAddress = (sltAddress) => {
        console.log('sltAddress', sltAddress)
        setGetSelectedAddress(sltAddress)
        setIsSelectedAddress(true)
    }
    // const subscriptionPayment = () => {
    //     console.log("Plan", getPlanItem)
    //     console.log("Address", getSelectedAddress)
    // }
    useEffect(() => {
        getPlanYour()
        setReload()
    }, [reload])

    useEffect(() => {
        if (isSelectedPlan && isSelectedAddress && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [isSelectedPlan && isSelectedAddress])
    // Select Plan

    const subscriptionPayment = () => {
        let amountPay =
            Math.round(getPlanItem?.price + getPlanItem?.price * 0.18) + '00'
        let paymentCurrency = currency === 'INR' ? 'INR' : 'USD'
        try {
            // Initialize Razorpay
            const options = {
                key: 'rzp_live_7ODFZsgCYbrvCH',
                //amount: amountPay || "50000",
                amount: amountPay,
                name: 'Weteams',
                currency: paymentCurrency,
                image: { logo },
                handler: function (response) {
                    storeSubscriptionPayment(response.razorpay_payment_id)

                    //alert(response.razorpay_payment_id);
                    // alert(response.razorpay_order_id);
                    // alert(response.razorpay_signature);
                },
                theme: {
                    color: '#552fba',
                },
            }

            const rzp1 = new Razorpay(options)
            rzp1.on('payment.failed', function (response) {
                //alert(response.error.code);
                //alert(response.error.description);
                toast.error(response.error.description)
                // alert(response.error.source);
                // alert(response.error.step);
                // alert(response.error.reason);
                // alert(response.error.metadata.order_id);
                // alert(response.error.metadata.payment_id);
            })

            rzp1.open()
        } catch (error) {
            console.error('Error creating order:', error)
        }
    }

    const storeSubscriptionPayment = (razorpay_payment_id) => {
        let subscriptionPlanId = getPlanItem.id
        const selectedPlanData = {
            selectedPlan: getPlanItem,
            selectedAddress: getSelectedAddress,
            razorpay_payment_id: razorpay_payment_id,
        }
        setPaymentIsLoading(true)
        try {
            http2
                .post(
                    `/select-billing-plan/${subscriptionPlanId}`,
                    selectedPlanData
                )
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        navigate('/dashboard/order-confirm')
                        //toast.success(result.message);
                        setPaymentIsLoading(false)
                        setIsSelectedPlan(false)
                        setIsSelectedAddress(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                        setPaymentIsLoading(false)
                        setIsSelectedPlan(false)
                        setIsSelectedAddress(false)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Layout>
                {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
                <div className="d-flex ad-billing-flex">
                    <div
                        className={`ad-billing-left ${
                            getPlanItem ? '' : 'w-100'
                        }`}
                    >
                        <div className="ad-white-card p-20">
                            <UpgradeYourPlan
                                selectPlanOption={selectPlanOption}
                                planInfo={planInfo}
                                isLoading={isLoading}
                                getPlanItem={getPlanItem}
                                selectAddress={selectAddress}
                                currentSubscription={
                                    location?.state?.currentPlan || ''
                                }
                            />
                            {isSelectedPlan && isSelectedAddress ? (
                                <div
                                    className={`text-center mt-4`}
                                    ref={sectionRef}
                                >
                                    {paymentIsLoading ? (
                                        <BtnSpinner />
                                    ) : (
                                        <button
                                            type="button"
                                            className="ad-theme-btn w-100"
                                            onClick={subscriptionPayment}
                                        >
                                            Pay
                                        </button>
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    {getPlanItem ? (
                        <div className="ad-billing-right">
                            <div className="pos-sticky">
                                <BillEstimate getPlanItem={getPlanItem} />
                                <div className="card-block pt-5 text-center">
                                    <div className="ad-md-title dash-font-0-9em ad-gray-text fw-500">
                                        We accept the following cards
                                    </div>
                                    <div className="d-inline-flex align-items-center mt-2 text-center cc-cards">
                                        <div>
                                            <img src={visaCard} alt="card" />
                                        </div>
                                        <div>
                                            <img src={masterCard} alt="card" />
                                        </div>
                                        <div>
                                            <img src={maestroCard} alt="card" />
                                        </div>
                                        <div>
                                            <img
                                                src={americanCard}
                                                alt="card"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={discoverCard}
                                                alt="card"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Layout>
        </>
    )
}

export default UpgradePlanLayout
