import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Blockchain = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/Blockchain.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Blockchain
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Explore the world of secure decentralized
                                    applications with Weteams Blockchain
                                    developers
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Equip your business with blockchain solutions
                                that are secure, decentralized, and immutable.
                                With our comprehensive range of blockchain
                                development teams, we help start-ups,
                                businesses, and enterprises develop more
                                transparent, efficient, and automated versions
                                of their businesses
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Finance
                                            </div>
                                            <p>
                                                Blockchain has the potential to
                                                improve payment procedures. For
                                                instance, sidechains like
                                                Arbitrum and blockchains like
                                                Polygon and Solana may settle
                                                transactions in split seconds
                                                for $0.01 or less, which is
                                                significantly less expensive
                                                than Visa, Mastercard, and Amex.
                                                Financial blockchain
                                                applications enable financial
                                                institutions to reduce the cost
                                                of cross-border transactions,
                                                with banks possibly saving $27
                                                billion by 2030.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Insurance
                                            </div>
                                            <p>
                                                Smart contracts are potentially
                                                the greatest blockchain
                                                application for insurance.
                                                Customers and insurers can
                                                manage claims in a clear and
                                                secure manner thanks to these
                                                contracts. Since the blockchain
                                                would reject numerous claims on
                                                the same accident, all contracts
                                                and claims could be recorded
                                                there and verified by the
                                                network. This would eliminate
                                                any claims that were not valid.
                                            </p>
                                            <p>
                                                For instance, openIDL, a network
                                                developed by the American
                                                Association of Insurance
                                                Services and IBM on the IBM
                                                Blockchain Platform, automates
                                                insurance regulatory reporting
                                                and streamlines compliance
                                                procedures.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Money Laundering Protection
                                            </div>
                                            <p>
                                                The encryption that is so
                                                important to blockchain enables
                                                it to be very beneficial in
                                                combatting money laundering. The
                                                technology underneath allows for
                                                record-keeping, which aids with
                                                "Know Your Customer" (KYC), the
                                                process by which a company
                                                validates and identifies its
                                                clients. Blockchain allows for
                                                the secure storage of customer
                                                data, which helps to streamline
                                                and protect against fraud.
                                            </p>
                                            <p>
                                                In addition, blockchain can help
                                                to track the ownership of
                                                assets, which is important in
                                                preventing money laundering. The
                                                public nature of the ledger
                                                means that all transactions are
                                                transparent and traceable. This
                                                makes it very difficult for
                                                criminals to hide their assets
                                                or to transfer them without
                                                detection.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Blockchain
