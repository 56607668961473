import React from 'react'
import { Link } from 'react-router-dom'
import StatusTag from '../Hiring/StatusTag'
import MatchedTalesntAndSkillsRequired from './MatchedTalesntAndSkillsRequired'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Grid from '@mui/material/Grid'
import { ReactComponent as LockIcon } from '../../assets/agency/img/lock-icons-dynamic.svg'
import Paper from '@mui/material/Paper'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import { ReactComponent as Calender } from '../../assets/dashboard/img/calendar-icon.svg'
import AuthUser from '../../Services/AuthUser'

const LeadItem = ({
    item,
    openApplyModal,
    is_premium_member,
    talent_network,
}) => {
    // const ref = React.createRef();
    // const [showLink, setShowLink] = React.useState(false);

    // React.useLayoutEffect(() => {
    //     if (ref.current.clientWidth < ref.current.scrollWidth) {
    //       setShowLink(true);
    //     }
    //   }, [ref]);

    const {
        id,
        stack_name,
        stack_image,
        experience,
        total_hired_talents,
        team_size,
        created_at,
        company,
        engagement_type,
        contract_duration,
        budget_range,
        skills,
        matched_candidates,
        hiring_status,
    } = item

    const navigate = useNavigate()
    const { postRequest } = AuthUser()
    const theme = useTheme()

    const handleClick = () => {
        if (matched_candidates.length > 0) {
            navigate(
                `/dashboard/leads/leads-matched/${id}?status=${hiring_status}`
            )
        } else {
            navigate(`/dashboard/leads/lead-detail/${id}`)
        }
    }

    const properCaseClient = (text) => {
        return text
            .split(' ')
            .map((word) => {
                if (word === '(s)') {
                    return '(s)'
                } else {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
            })
            .join(' ')
    }

    return (
        <>
            <Paper
                variant="leadsCardMain"
                onClick={(e) => {
                    is_premium_member && handleClick()
                }}
                sx={{
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
            >
                <Stack>
                    <Grid
                        container
                        columns={{ xs: 12, sm: 12, md: 23, lg: 23 }}
                        sx={{ alignItems: 'center' }}
                    >
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <div className="d-flex align-items-center">
                                <Box
                                    className="dash-hc-img ad-sm-hc ad-project-circle"
                                    sx={{
                                        background: stack_image
                                            ? theme.palette.common.white
                                            : theme.palette.primary.main,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img src={stack_image} alt={stack_name} />
                                </Box>
                                <div
                                    className="dash-hc-info"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">
                                        {stack_name}
                                    </div>
                                    <div className="mt-1">
                                        <span className="dash-com-tag dash-blue-tag d-inline-block mr-6 mb-1">
                                            Hiring: {team_size}
                                        </span>
                                        <span className="dash-com-tag dash-pink-tag d-inline-block mb-1">
                                            Exp: {experience}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} lg={3}>
                            <div className="d-block">
                                <div
                                    className="dash-md-title dash-font-0-8em text-wrap"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Company
                                </div>
                                <div
                                    className="common-label mt-1 text-type2"
                                    style={{ cursor: 'pointer' }}
                                >
                                    {is_premium_member ? (
                                        properCaseClient(company)
                                    ) : (
                                        <Chip
                                            variant="lockChip1"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                openApplyModal()
                                            }}
                                            icon={
                                                <SvgIcon
                                                    color="inherit"
                                                    component={LockIcon}
                                                />
                                            }
                                            label="Unlock"
                                        />
                                    )}
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent="center"
                            item
                            xs={4}
                            sm={4}
                            md={3}
                            lg={3}
                        >
                            <div className="d-block">
                                <span className="dash-custom-tooltip d-inline-block">
                                    <div
                                        className="common-label fw-500 ad-font-0-9em ad-purple-text text-nowrap"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="lead-clock-icon"></span>
                                        {engagement_type}Hrs/Month
                                    </div>
                                    <span className="dash-ct-value">
                                        Engagement
                                    </span>
                                </span>
                            </div>
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent="center"
                            item
                            xs={3}
                            sm={3}
                            md={2}
                            lg={2}
                        >
                            <div className="d-block">
                                <span className="dash-custom-tooltip d-inline-block">
                                    <div
                                        className="common-label fw-500 ad-font-0-9em ad-purple-text text-nowrap"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="lead-tick-icon"></span>
                                        {total_hired_talents}
                                    </div>
                                    <span className="dash-ct-value text-nowrap">
                                        Hired Talents
                                    </span>
                                </span>
                            </div>
                        </Grid>

                        <Grid
                            display="flex"
                            justifyContent="center"
                            item
                            xs={3}
                            sm={3}
                            md={2}
                            lg={2}
                        >
                            <div className="d-block">
                                <span className="dash-custom-tooltip d-inline-block">
                                    <div
                                        className="common-label fw-500 ad-font-0-9em ad-purple-text text-nowrap"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <span className="lead-calender-icon"></span>
                                        {contract_duration
                                            ? `${contract_duration} ${contract_duration > 1 ? 'Months' : 'Month'}`
                                            : '-'}
                                    </div>
                                    <span className="dash-ct-value text-nowrap">
                                        Contract Duration
                                    </span>
                                </span>
                            </div>
                        </Grid>
                        {/* <Grid display="flex" justifyContent="center" item xs={3} sm={3} md={2} lg={2}>
                            <div className="d-block">
                            
                                <span className="dash-custom-tooltip d-inline-block">
                                <div className="common-label fw-500 ad-font-0-9em ad-purple-text text-nowrap" style={{cursor:"pointer"}}>
                                 
                                <span className='lead-calender-icon'></span>
                                   
                                    <Tooltip
                                      
                                        placement='top'
                                        slotProps={{
                                            popper: { className: "tooltipPrimMain" },
                                        }}
                                        title="Contract duration">
                                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text text-nowrap" style={{cursor:"pointer"}}>{contract_duration || "-"}</div>
                                    </Tooltip>
                                    </div>
                                </span>
                            </div>
                        </Grid> */}
                        {/* <Grid display="flex"
                            justifyContent="center"
                            item
                            xs={3} sm={3} md={2} lg={2}>
                            <div className="d-block">
                                <span className="dash-custom-tooltip d-inline-block">
                                    <Tooltip
                                        slotProps={{
                                                popper: { className: "tooltipPrimMain" },
                                            }}
                                        title="Budget range">
                                        <div className="common-label fw-500 ad-font-0-9em ad-purple-text text-nowrap">{budget_range || budget_range == "" ? " - " : budget_range}</div>
                                    </Tooltip>
                                </span>
                            </div>
                        </Grid> */}
                        <Grid
                            style={{ cursor: 'pointer' }}
                            display="flex"
                            justifyContent={{
                                xs: 'flex-end',
                                sm: 'flex-end',
                                md: 'center',
                                lg: 'center',
                            }}
                            item
                            xs={6}
                            sm={6}
                            md={4}
                            lg={4}
                        >
                            <Stack spacing={1}>
                                <Chip
                                    variant="dateChip1"
                                    size="small"
                                    label={`Created: ${created_at}`}
                                />
                                <StatusTag item={item} />
                            </Stack>
                        </Grid>
                        {/* <div className="col1 col-box">
                    <div className="d-block">
                        <span className="dash-com-tag dash-green-tag d-inline-block fw-600">Created: {created_at}</span>
                        <StatusTag item={item} />
                    </div>
                </div> */}
                        {/* <div className="col6 col-box">
                    {team_size <= total_hired_talents ?
                        <>
                            <span className="dash-com-tag dash-green-tag text-center d-inline-block">Hired: {total_hired_talents}</span>
                        </>
                        : <StatusTag item={item} />
                    }
                </div> */}
                        <Grid
                            display="flex"
                            justifyContent="flex-end"
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                        >
                            <Tooltip
                                slotProps={{
                                    popper: { className: 'tooltipPrimMain' },
                                }}
                                title={
                                    is_premium_member
                                        ? ''
                                        : 'Please upgrade your plan to match talent'
                                }
                            >
                                <Box
                                    sx={{
                                        ...(!is_premium_member && {
                                            opacity: '0.5',
                                        }),
                                    }}
                                >
                                    {is_premium_member ? (
                                        <Box
                                            className="ad-trans-theme-btn ad-lead-apply-btn"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                navigate(
                                                    `/dashboard/leads/lead-detail/${id}`
                                                )
                                            }}
                                        >
                                            Match
                                        </Box>
                                    ) : (
                                        <Box sx={{ pointerEvents: 'none' }}>
                                            <button
                                                className="ad-trans-theme-btn ad-lead-apply-btn"
                                                onClick={openApplyModal}
                                            >
                                                Match
                                            </button>
                                        </Box>
                                    )}
                                </Box>
                            </Tooltip>
                            {/* <button className='ad-trans-theme-btn ad-lead-apply-btn' onClick={openApplyModal}>Apply</button> */}
                        </Grid>
                        {/* <Grid display="flex" justifyContent="flex-end" item xs={3} sm={3} md={1} lg={1}>
                            <div className="btn-group ad-custom-dropdown dropstart">
                                <button type="button" className="btn ad-dropdown-menu dropdown-toggle"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                    <span className="addp-menu-icon"></span>
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {is_premium_member ?
                                            matched_candidates.length > 0 ?
                                            <li><Link className="dropdown-item" to={`/dashboard/leads/leads-matched/${id}?status=${hiring_status}`}>View Details</Link></li>
                                            :
                                            <li><Link className="dropdown-item" to={`/dashboard/leads/lead-detail/${id}`}>View Details</Link></li>
                                        :
                                        <li><button className='dropdown-item' onClick={openApplyModal}>View Details</button></li>
                                    }
                                </ul>
                            </div>
                        </Grid> */}
                    </Grid>
                    <MatchedTalesntAndSkillsRequired
                        skills={skills}
                        matched_candidates={matched_candidates}
                        talent_network={talent_network}
                        contract_duration={contract_duration}
                        budget_range={budget_range}
                    />
                </Stack>
            </Paper>
            {/* <div className="adts-box d-flex align-items-center justify-content-between flex-wrap mb-10"> */}

            {/* </div> */}
        </>
    )
}

export default LeadItem
