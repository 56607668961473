import { Outlet, Navigate } from 'react-router-dom'

// Custom route component to protect routes based on authentication and permissions
const ProtectedRoute = ({ element, requiredPermissions }) => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { is_invite_client, is_invite_team_member } = user

    let hasRequiredPermissions = true

    let role = 'user'

    let fallback = '/dashboard/overview'

    if (is_invite_client == 'true') {
        role = 'client'
        fallback = '/dashboard/projects'
    } else if (is_invite_team_member) {
        role = 'lead'
        fallback = '/dashboard/projects'
    }

    hasRequiredPermissions =
        role && requiredPermissions
            ? requiredPermissions.every((permissions) =>
                  permissions.includes(role)
              )
            : true

    return hasRequiredPermissions ? <Outlet /> : <Navigate to={fallback} />
}

export default ProtectedRoute
