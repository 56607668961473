import React from 'react'
import Layout from '../Layout'
import { useState } from 'react'
import Overview from '../../../Components/DashboardOverview/Overview'
import DashboardProjectInfo from '../../../Components/DashboardOverview/DashboardProjectInfo'
import DashboardNoData from '../../../Components/DashboardOverview/DashboardNoData'
import AuthUser from '../../../Services/AuthUser'
import { useLayoutEffect } from 'react'
import AgencyModal from '../../../Components/Modals/AgencyModal'
import AddNewProjectModal from '../../../Components/Projects/AddNewProjectModal'
import Skeleton from 'react-loading-skeleton'
import { useEffect } from 'react'
import JiraConnectModal from '../Projects/JiraConnectModal'
import { useLocation, useNavigate } from 'react-router-dom'
import AddOnBenchResourcesForm from '../../../Components/Overview/AddOnBenchResourcesForm'
const Dashboard = () => {
    let user = JSON.parse(localStorage.getItem('user'))
    let { state } = useLocation()
    console.log('routeState', state)

    const { http, http2 } = AuthUser()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [onBenchModal, setOnBenchModal] = useState(false)

    const me = () => {
        http.get('/me')
            .then(function (response) {
                let result = response.data.data
                localStorage.setItem('user', JSON.stringify(result))
            })
            .catch(function (error) {
                // handle error
                return false
            })
    }
    // const [projectDetail, setProjectDetail] = useState(false);

    // Add Project Modal
    const [modalOpen, setModalOpen] = useState(false)
    const addProjectModal = () => {
        setModalOpen(true)
    }
    // Dashboard Data
    const [reload, setReload] = useState(false)
    const [dashboardData, setDashboardData] = useState('')
    const [projectsList, setProjectsList] = useState([])
    const [resourceUtilization, setResourceUtilization] = useState([])
    const getDashboardData = () => {
        setIsLoading(true)
        http2.get(`/get-overview-data`).then((res) => {
            if (user.is_invite_client == 'true') {
                navigate('/dashboard/projects')
            } else if (user.is_invite_team_member) {
                navigate('/dashboard/projects')
            }
            let result = res.data
            setDashboardData(result.data)
            setProjectsList(result.data.projects)
            setResourceUtilization(result.data.resource_utilization)
            setIsLoading(false)
        })
    }

    const [jiraConnectModal, setJiraConnectModal] = useState(false)
    const [jiraConnectDtl, setJiraConnectDtl] = useState('')

    const openJiraConnectModal = (projectId) => {
        setJiraConnectModal(true)
        setJiraConnectDtl(projectId)
    }
    useLayoutEffect(() => {
        // me();=
    }, [])
    useEffect(() => {
        getDashboardData()
        setReload()
        if (state?.isSignUp) {
            setOnBenchModal(true)
        }
    }, [reload])
    return (
        <>
            <Layout>
                {/* <div className="d-flex align-items-end filter-myteam justify-content-between mb-4">
                    <div className="dash-md-title fw-500 dash-font-0-9em">
                        <span className="d-inline-block">
                            Overview -
                            <select class="year-select"><option value="2022">2022</option><option value="2023">2023</option></select>
                            &nbsp; &nbsp;
                            <select class="month-select">
                                <option value="January">January</option>
                                <option value="February">February</option>
                                <option value="March">March</option>
                                <option value="April">April</option>
                                <option value="May">May</option>
                                <option value="June">June</option>
                                <option value="July">July</option>
                                <option value="August">August</option>
                                <option value="September">September</option>
                                <option value="October">October</option>
                                <option value="November">November</option>
                                <option value="December">December</option>
                            </select>
                        </span>
                    </div>
                </div> */}
                {isLoading ? (
                    <div className="d-flex ad-dash-block">
                        <div className="ad-dash-card1">
                            <Skeleton
                                containerClassName="cs-skeleton"
                                count={1}
                                height={220}
                            />
                        </div>
                        <div className="ad-dash-card2">
                            <Skeleton
                                containerClassName="cs-skeleton"
                                count={1}
                                height={220}
                            />
                        </div>
                        <div className="ad-dash-card3">
                            <Skeleton
                                containerClassName="cs-skeleton"
                                count={1}
                                height={220}
                            />
                        </div>
                    </div>
                ) : (
                    <Overview
                        // projectDetail={projectDetail}
                        dashboardData={dashboardData}
                        resourceUtilization={resourceUtilization}
                    />
                )}
                {isLoading ? (
                    <Skeleton
                        containerClassName="cs-skeleton"
                        count={1}
                        height={350}
                    />
                ) : projectsList?.length > 0 ? (
                    <DashboardProjectInfo
                        projectsList={projectsList}
                        openJiraConnectModal={openJiraConnectModal}
                    />
                ) : (
                    <DashboardNoData addProjectModal={addProjectModal} />
                )}
            </Layout>
            <AgencyModal open={modalOpen}>
                <AddNewProjectModal setModalOpen={setModalOpen} />
            </AgencyModal>

            <AgencyModal
                dialogStyle={{ maxWidth: '680px' }}
                open={onBenchModal}
                clsSm={() => {
                    setOnBenchModal(false)
                }}
            >
                <AddOnBenchResourcesForm
                    modalclose={() => {
                        setOnBenchModal(false)
                    }}
                />
            </AgencyModal>

            {jiraConnectModal && (
                <AgencyModal open={jiraConnectModal}>
                    <JiraConnectModal
                        setJiraConnectModal={setJiraConnectModal}
                        setReload={setReload}
                        projectId={jiraConnectDtl}
                    />
                </AgencyModal>
            )}
        </>
    )
}

export default Dashboard
