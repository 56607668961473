import { Formik, Field, Form } from 'formik'
import { useLayoutEffect, useRef, useState } from 'react'
import makeAnimated from 'react-select/animated'
import { utilities, getOrgSetting } from '../../Config/Helper'
import ExperienceSelectField from '../Hiring/HireTalent/ExperienceSelectField'
import BtnSpinner from '../Spinners/BtnSpinner'
import { MultiSelect } from 'react-multi-select-component'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import ListItem from '@mui/material/ListItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as DownIcon } from '../../assets/dashboard/img/down-arrow-dynamic.svg'
import { ReactComponent as SearchIcon } from '../../assets/dashboard/img/search-input.svg'
import { IconButton } from '@mui/material'
import { Grid } from '@mui/material'

const animatedComponents = makeAnimated()

const LeadMyTeamFilter = ({
    roleData,
    leadDetailData,
    clearVal,
    setclearVal,
    setIsSearch,
    getSearchData,
    isLoading,
}) => {
    const experience = [
        { key: '0-1', value: '0-1 Y' },
        { key: '1-3', value: '1-3 Y' },
        { key: '3-7', value: '3-7 Y' },
        { key: '7+', value: '7+ Y' },
    ]

    const allocationOptions = ['40hr', '80hr', '120hr', '160hr']
    const selectRef = useRef(null)
    const [skills, setSkills] = useState([])
    const [orgSetting, setOrgSetting] = useState({})
    const [isInputFocused, setInputFocused] = useState(false)
    const [isSearchIconVisible, setSearchIconVisible] = useState(true)
    const [selectedSkills, setSelectedSkills] = useState([])
    const [toggle, setToggle] = useState(true)

    const toggleElementsVisibility = () => {
        setSearchIconVisible(!isSearchIconVisible)
        setInputFocused(!isSearchIconVisible)
    }
    const skillsData = skills.map((item) => ({
        value: item._id,
        label: item.name,
    }))

    const idsSkills = leadDetailData?.skills?.map((skill) => skill.id)
    const initialValues = {
        keyword: !clearVal ? '' : '',
        experience: !clearVal ? leadDetailData?.experience || '' : '',
        role_id: !clearVal ? leadDetailData?.stack_role_id || '' : '',
        skills: !clearVal ? idsSkills || [] : [],
        allocation: !clearVal ? '' : '',
    }

    const [initialFormValues, setInitialFormValues] = useState(initialValues)

    const resetToInitialValues = (resetForm) => {
        resetForm({ values: initialFormValues })
        setclearVal(false)
    }

    const handleClear = (resetForm) => {
        setclearVal(true)
        resetForm({ values: initialFormValues })
    }

    const handleSkillChange = (e, f) => {
        const valuesArray = e.map((e) => e.value)
        f.setFieldValue('skills', valuesArray)
    }
    useLayoutEffect(() => {
        ;(async () => {
            let skills = await utilities('skills')
            setSkills(skills)
            let setting = await getOrgSetting()
            setOrgSetting(setting)
        })()
    }, [])

    const handleClick = (resetForm) => {
        if (toggle) {
            handleClear(resetForm)
        } else {
            resetToInitialValues(resetForm)
        }
        setToggle(!toggle)
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    getSearchData(values)
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <Form autoComplete="off" encType="multipart/form-data">
                        <div className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                            <div className="w-35 form-group">
                                <div className="position-relative">
                                    <label className="label-pos">
                                        Search By Name
                                    </label>
                                    <Field
                                        type="text"
                                        className="form-control pl-35"
                                        name="keyword"
                                        placeholder="Enter name"
                                    />
                                    <span className="lead-search-icon"></span>
                                </div>
                            </div>

                            <div className="w-35 form-group">
                                <div className="position-relative">
                                    <label className="label-pos">
                                        Experience
                                    </label>
                                    <Field
                                        name="experience"
                                        className="form-control"
                                        as="select"
                                    >
                                        <option value="">Experience</option>
                                        {experience.map((val, key) => {
                                            return (
                                                <option
                                                    key={key}
                                                    value={val.key}
                                                >
                                                    {val.value}
                                                </option>
                                            )
                                        })}
                                    </Field>
                                </div>
                            </div>
                            <div className="w-30 form-group">
                                <div className="position-relative">
                                    <label className="label-pos">Role</label>
                                    <Field
                                        name="role_id"
                                        className="form-control"
                                        as="select"
                                    >
                                        <option value="">Role</option>
                                        {roleData &&
                                            roleData.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                    </Field>
                                </div>
                            </div>

                            {/** AutoComplete Usage for Multiselect */}

                            <div className="w-55 form-group position-relative custom-select-group lead-filter-select">
                                <div className="position-relative">
                                    <label className="label-pos">Skills</label>
                                    <Field name="skills">
                                        {({ field, form }) => (
                                            <Autocomplete
                                                multiple
                                                id="skills-autocomplete"
                                                options={skillsData}
                                                getOptionLabel={(option) =>
                                                    option.label
                                                }
                                                onChange={(e, newValue) => {
                                                    form.setFieldValue(
                                                        'skills',
                                                        newValue.map(
                                                            (item) => item.value
                                                        )
                                                    )
                                                }}
                                                value={skillsData.filter(
                                                    (option) =>
                                                        field.value.includes(
                                                            option.value
                                                        )
                                                )}
                                                sx={{
                                                    width: '100%',
                                                    maxBlockSize: '45px',
                                                    marginLeft: '1px',
                                                    '& .MuiAutocomplete-inputRoot':
                                                        {
                                                            flexWrap: 'nowrap',
                                                        },
                                                }}
                                                renderTags={(
                                                    value,
                                                    getTagProps
                                                ) => (
                                                    <Box
                                                        display="flex"
                                                        flexWrap="nowrap"
                                                        width="80%"
                                                        sx={{
                                                            overflowX: 'scroll',
                                                            borderRadius:
                                                                '10px',
                                                            '&::-webkit-scrollbar':
                                                                {
                                                                    height: '2px',
                                                                    borderRadius:
                                                                        '9px',
                                                                },
                                                            '&::-webkit-scrollbar-track':
                                                                {
                                                                    borderRadius:
                                                                        '9px',
                                                                },
                                                            '&::-webkit-scrollbar-thumb':
                                                                {
                                                                    backgroundColor:
                                                                        'teritiary.customScrollbarThumb',
                                                                    borderRadius:
                                                                        '9px',
                                                                },
                                                        }}
                                                    >
                                                        {value.map(
                                                            (option, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    variant="outlined"
                                                                    label={
                                                                        option.label
                                                                    }
                                                                    style={{
                                                                        color: 'white',
                                                                        backgroundColor:
                                                                            '#552FBA',
                                                                        borderRadius:
                                                                            '9px',
                                                                        height: '23px',
                                                                    }}
                                                                    deleteIcon={
                                                                        <span
                                                                            style={{
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            ×
                                                                        </span>
                                                                    }
                                                                    {...getTagProps(
                                                                        {
                                                                            index,
                                                                        }
                                                                    )}
                                                                />
                                                            )
                                                        )}
                                                    </Box>
                                                )}
                                                renderOption={(
                                                    props,
                                                    option,
                                                    { selected }
                                                ) => (
                                                    <ListItem {...props}>
                                                        <Checkbox
                                                            checked={selected}
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                option.label
                                                            }
                                                            sx={{
                                                                '.MuiTypography-root':
                                                                    {
                                                                        fontSize:
                                                                            '14px',
                                                                        color: 'black',
                                                                        fontStyle:
                                                                            'normal',
                                                                        fontFamily:
                                                                            'Poppins',
                                                                    },
                                                            }}
                                                        />
                                                    </ListItem>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        className="AutocompletecustomOutlinedTextFieldVariant"
                                                        multiline={false}
                                                        name="skills"
                                                        onFocus={() =>
                                                            setInputFocused(
                                                                true
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            setInputFocused(
                                                                false
                                                            )
                                                        }
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {!isInputFocused && (
                                                                        <SvgIcon
                                                                            color="inherit"
                                                                            component={
                                                                                SearchIcon
                                                                            }
                                                                            onClick={
                                                                                toggleElementsVisibility
                                                                            }
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                marginTop:
                                                                                    '4px',
                                                                                marginRight:
                                                                                    !isInputFocused
                                                                                        ? field
                                                                                              .value
                                                                                              .length >
                                                                                          0
                                                                                            ? '-54px'
                                                                                            : '-30px'
                                                                                        : '-54px',
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {field.value
                                                                        .length >
                                                                        0 &&
                                                                    isInputFocused ? (
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() => {
                                                                                form.setFieldValue(
                                                                                    'skills',
                                                                                    []
                                                                                )
                                                                                setInputFocused(
                                                                                    false
                                                                                )
                                                                            }}
                                                                            sx={{
                                                                                color: 'grey',
                                                                                '&:hover':
                                                                                    {
                                                                                        backgroundColor:
                                                                                            'white',
                                                                                    },
                                                                                marginRight:
                                                                                    '-46px',
                                                                                mb: 1,
                                                                            }}
                                                                        >
                                                                            x
                                                                        </IconButton>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                open={isInputFocused}
                                            />
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className="w-20 form-group">
                                <div className="position-relative">
                                    <label className="label-pos">
                                        Minimum Allocation
                                    </label>
                                    <Field
                                        name="allocation"
                                        as="select"
                                        className="form-control"
                                    >
                                        <option value="">Allocation</option>
                                        {allocationOptions.map(
                                            (option, index) => (
                                                <option
                                                    key={index}
                                                    value={option}
                                                >
                                                    {option}
                                                </option>
                                            )
                                        )}
                                    </Field>
                                </div>
                            </div>

                            <div className="w-25 form-group">
                                <div className="position-relative">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: '12px',
                                        }}
                                    >
                                        <Box sx={{ flexGrow: 2 }}>
                                            {isLoading ? (
                                                <BtnSpinner
                                                    className="ad-theme-btn w-100"
                                                    loader="true"
                                                />
                                            ) : (
                                                <button
                                                    className="ad-theme-btn"
                                                    style={{
                                                        height: '44px',
                                                        width: '100%',
                                                    }}
                                                    type="submit"
                                                >
                                                    Search
                                                </button>
                                            )}
                                        </Box>
                                        <Box sx={{ flexGrow: 1 }}>
                                            <button
                                                className="btn ad-gray-btn"
                                                style={{
                                                    height: '44px',
                                                    width: '100%',
                                                }}
                                                onClick={() =>
                                                    handleClick(resetForm)
                                                }
                                            >
                                                <span
                                                    className="ad-clear-filter-icon"
                                                    style={{
                                                        marginLeft: '3px',
                                                        marginBottom: '7px',
                                                        alignItems: 'center',
                                                    }}
                                                ></span>
                                            </button>
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default LeadMyTeamFilter
