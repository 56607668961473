import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import React from 'react'
import Typography from '@mui/material/Typography'

const LogoInfoTooltip = ({ Message }) => {
    return (
        <Card
            sx={{
                backgroundColor: 'inherit',
                color: 'inherit',
                borderRadius: '8px',
            }}
            elevation={0}
        >
            <CardHeader
                disableTypography
                sx={{ p: 1 }}
                title={
                    <Typography
                        variant="size3SemiboldMy"
                        sx={{
                            '&.MuiTypography-root': {
                                color: 'white',
                            },
                        }}
                    >
                        {Message}
                    </Typography>
                }
            />
        </Card>
    )
}

export default LogoInfoTooltip
