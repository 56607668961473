import React, { createContext, useState, useEffect } from 'react'
import AuthUser from '../Services/AuthUser'

// Create the PermissionContext
const PermissionContext = createContext()

// Create the PermissionProvider component
const PermissionProvider = ({ children }) => {
    const { http } = AuthUser()
    //const {http} = AuthUser();
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    const permissions = user?.permissions || []

    const [peoplePermissions, setPeoplePermissions] = useState(permissions)
    // Add your permission-related functions here
    // For example:
    const setPermissions = (permissions) => {
        setPeoplePermissions(permissions)
    }

    const hasPermission = (permission) => {
        let access =
            peoplePermissions.length > 0
                ? peoplePermissions.includes(permission)
                : true
        return access
    }

    useEffect(() => {
        // Check if 'user' key is not empty (null or undefined)
        setPeoplePermissions(permissions)
    }, [])
    // useLayoutEffect(() => {
    //   http.get(`/me`).then((res) => {
    //     let permissions = res?.data?.data?.permissions;
    //     setPeoplePermissions(permissions || []);
    //   });
    // }, []);

    return (
        <PermissionContext.Provider
            value={{ peoplePermissions, setPermissions, hasPermission }}
        >
            {children}
        </PermissionContext.Provider>
    )
}

export { PermissionContext, PermissionProvider }
