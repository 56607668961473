import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import LeadMatchItem from './LeadMatchItem'
import AuthUser from '../../Services/AuthUser'
import { toast, ToastContainer } from 'react-toastify'
import BtnSpinner from '../Spinners/BtnSpinner'
import AgencySmModal from '../Modals/AgencySmModal'
import successCheck from '../../assets/agency/img/success-check.svg'
import Skeleton from 'react-loading-skeleton'
import AgencyModal from '../Modals/AgencyModal'
import ReservedHoursModal from './ReservedHoursModal'
import { Stack, Button, Typography } from '@mui/material'
import NoDataLead from './NoDataLead'
import { useEffect } from 'react'

const LeadMatchTalent = ({
    goodMatch,
    topMatch,
    matched,
    updated_data,
    setSearchBy,
    required_skill_ids,
    isLoading,
    setReload,
}) => {
    const { http2 } = AuthUser()
    const { id } = useParams()
    const [isLoadingMatchTalent, setIsLoadingMatchTalent] = useState(false)
    const [checkboxes, setCheckboxes] = useState([])
    const [successMatchTalent, setSuccessMatchTalent] = useState(false)
    const [talentNetwork, setTalentNetwork] = useState('')

    const closeSuccessMatchTalent = () => {
        setSuccessMatchTalent(false)
    }

    const handleCheckboxChange = (checkboxName) => {
        if (checkboxes.includes(checkboxName)) {
            setCheckboxes((prevCheckboxes) =>
                prevCheckboxes.filter((name) => name !== checkboxName)
            )
        } else {
            setCheckboxes((prevCheckboxes) => [...prevCheckboxes, checkboxName])
        }
    }

    const checkBoxId = checkboxes[0]

    const getRatePerHour = (id) => {
        let ratePerHour
        if (topMatch?.length > 0) {
            topMatch.map((item) => {
                if (item.id === id) {
                    ratePerHour = item.rate_per_hour
                }
            })
        }

        if (goodMatch?.length > 0) {
            if (ratePerHour === undefined) {
                goodMatch.map((item) => {
                    if (item.id === id) {
                        ratePerHour = item.rate_per_hour
                    }
                })
            }
        }

        return ratePerHour
    }

    const applyMatchTalent = (formValues) => {
        let payload = {
            assigned_talents: checkboxes,
            talent_network_cost: formValues
                ? Number(formValues.talent_network_cost)
                : getRatePerHour(checkBoxId),
        }
        try {
            setIsLoadingMatchTalent(true)
            http2
                .post(`/match-talents/${id}`, payload)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        console.log('result', result)

                        toast.success('Talent Matched Successfully!', {
                            onOpen: () => {
                                setIsLoadingMatchTalent(false)
                                setSuccessMatchTalent(true)
                                setReload(true)
                            },
                        })
                    } else {
                        toast.error('Something went wrong')
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Something went wrong')
                        setIsLoadingMatchTalent(false)
                        toast.error(error.response.data.data)
                    }
                })
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }
    }

    const [reservedHoursModal, setReservedHoursModal] = useState(false)
    const [reservedHoursItem, setReservedHoursItem] = useState('')

    const openReservedHoursModal = (resHoursItem) => {
        setReservedHoursModal(true)
        setReservedHoursItem(resHoursItem)
    }

    const totalMatches = goodMatch.length + topMatch.length

    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div className="match-talent-tabs">
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        marginRight: '15px',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    {isLoadingMatchTalent ? (
                        <BtnSpinner
                            cls="ad-theme-btn ad-sm-btn ad-green-btn"
                            loader="true"
                        />
                    ) : (
                        <>
                            <Typography
                                variant="size3SemiboldTextText1"
                                sx={{
                                    marginLeft: '5px',
                                    '&.MuiTypography-root': {
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    },
                                }}
                            >
                                Total Matches: {totalMatches}
                            </Typography>
                            {/* {totalMatches > 0 ? (
            <Button
            variant={checkboxes.length === 0 ? 'Match' : 'Match'}
            disabled={!checkboxes.length > 0}
            sx={{ textTransform: "capitalize",
            "&.Mui-disabled": {
              color: "whitesmoke", 
              opacity:"1.1"
            }
          }}
            onClick={applyMatchTalent}
          >
            Match
          </Button>

          ):
          <Button
            variant={checkboxes.length === 0 ? 'Match' : 'Match'}
            disabled={!checkboxes.length > 0}
            sx={{ textTransform: "capitalize",
            "&.Mui-disabled": {
              color: "whitesmoke", 
              opacity:"0.5"
            }
          }}
            onClick={applyMatchTalent}
          >
            Match
          </Button>
          } */}
                        </>
                    )}
                </Stack>

                {matched && matched.length > 0 && (
                    <div
                        className="top-results"
                        style={{
                            backgroundColor: 'common.white',
                            padding: '10px',
                        }}
                    >
                        <Typography
                            component="div"
                            variant="size3SemiboldTextText1"
                            sx={{
                                backgroundColor: 'primary.morelighter',
                                color: 'text.text1',
                            }}
                        >
                            <div style={{ marginLeft: '6px' }}>Matches</div>
                        </Typography>
                        <div className="lead-team-table ltt-min-height">
                            <table className="w-100">
                                <tbody>
                                    {isLoading ? (
                                        <tr>
                                            <td className="text-center py-3">
                                                <Skeleton
                                                    containerClassName="cs-skeleton"
                                                    count={4}
                                                    height={60}
                                                />
                                            </td>
                                        </tr>
                                    ) : matched?.length > 0 ? (
                                        matched.map((item) => (
                                            <LeadMatchItem
                                                key={item.id}
                                                talentNetwork={getRatePerHour(
                                                    checkBoxId
                                                )}
                                                item={item}
                                                updated_data={updated_data}
                                                setReload={setReload}
                                                handleCheckboxChange={
                                                    handleCheckboxChange
                                                }
                                                applyMatchTalent={
                                                    applyMatchTalent
                                                }
                                                required_skill_ids={
                                                    required_skill_ids
                                                }
                                                hiring_id={id}
                                                openReservedHoursModal={
                                                    openReservedHoursModal
                                                }
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-center py-3">
                                                <Typography variant="size3SemiboldTextText2">
                                                    You don't have any top
                                                    matches! However there are
                                                    some good matches that you
                                                    can select from Below.
                                                </Typography>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {totalMatches > 0 ? (
                    <>
                        <div
                            className="top-results"
                            style={{
                                backgroundColor: 'common.white',
                                padding: '10px',
                            }}
                        >
                            <Typography
                                component="div"
                                variant="size3SemiboldTextText1"
                                sx={{
                                    backgroundColor: 'primary.morelighter',
                                    color: 'text.text1',
                                }}
                            >
                                <div style={{ marginLeft: '6px' }}>
                                    Top Match
                                </div>
                            </Typography>
                            <div className="lead-team-table ltt-min-height">
                                <table className="w-100">
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td className="text-center py-3">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={4}
                                                        height={60}
                                                    />
                                                </td>
                                            </tr>
                                        ) : topMatch?.length > 0 ? (
                                            topMatch.map((item) => (
                                                <LeadMatchItem
                                                    key={item.id}
                                                    talentNetwork={getRatePerHour(
                                                        checkBoxId
                                                    )}
                                                    item={item}
                                                    updated_data={updated_data}
                                                    setReload={setReload}
                                                    handleCheckboxChange={
                                                        handleCheckboxChange
                                                    }
                                                    applyMatchTalent={
                                                        applyMatchTalent
                                                    }
                                                    required_skill_ids={
                                                        required_skill_ids
                                                    }
                                                    hiring_id={id}
                                                    openReservedHoursModal={
                                                        openReservedHoursModal
                                                    }
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center py-3">
                                                    <Typography variant="size3SemiboldTextText2">
                                                        You don't have any top
                                                        matches! However there
                                                        are some good matches
                                                        that you can select from
                                                        below.
                                                    </Typography>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div
                            className="good-results"
                            style={{
                                backgroundColor: 'common.white',
                                padding: '10px',
                            }}
                        >
                            <Typography
                                component="div"
                                variant="size3SemiboldTextText1"
                                sx={{
                                    backgroundColor: 'primary.morelighter',
                                    color: 'text.text1',
                                }}
                            >
                                <div style={{ marginLeft: '6px' }}>
                                    Good Match
                                </div>
                            </Typography>
                            <div className="lead-team-table">
                                <table className="w-100">
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td className="text-center py-3">
                                                    <Skeleton
                                                        containerClassName="cs-skeleton"
                                                        count={4}
                                                        height={60}
                                                    />
                                                </td>
                                            </tr>
                                        ) : goodMatch?.length > 0 ? (
                                            goodMatch.map((item) => (
                                                <LeadMatchItem
                                                    key={item.id}
                                                    talentNetwork={getRatePerHour(
                                                        checkBoxId
                                                    )}
                                                    item={item}
                                                    updated_data={updated_data}
                                                    setReload={setReload}
                                                    handleCheckboxChange={
                                                        handleCheckboxChange
                                                    }
                                                    applyMatchTalent={
                                                        applyMatchTalent
                                                    }
                                                    required_skill_ids={
                                                        required_skill_ids
                                                    }
                                                    hiring_id={id}
                                                    openReservedHoursModal={
                                                        openReservedHoursModal
                                                    }
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                <td className="text-center py-3">
                                                    <Typography variant="size3SemiboldTextText2">
                                                        You dont have any good
                                                        matches
                                                    </Typography>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                ) : (
                    <NoDataLead />
                )}
            </div>
            <AgencySmModal open={successMatchTalent} close={false}>
                <div className="text-center">
                    <img src={successCheck} alt="question" />
                </div>
                <div className="mt-3 ad-md-title text-center">Success!</div>
                <div className="mt-1 content text-center mb-3">
                    <p>Talent matched successfully</p>
                </div>
                <div className="text-center">
                    <button
                        type="button"
                        className="ad-theme-btn ad-sm-btn"
                        onClick={closeSuccessMatchTalent}
                    >
                        Ok, got it!
                    </button>
                </div>
            </AgencySmModal>
            {reservedHoursItem && (
                <AgencyModal open={reservedHoursModal}>
                    <ReservedHoursModal
                        setReservedHoursModal={setReservedHoursModal}
                        reservedHoursItem={reservedHoursItem}
                    />
                </AgencyModal>
            )}
        </>
    )
}

export default LeadMatchTalent
