import { useEffect, useState } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import logo from '../assets/img/logo.png'
import BlackLogo from '../assets/img/black-logo.png'
import AuthUser from '../Services/AuthUser'

const Header = () => {
    const { pathname } = useLocation()
    const { http, token } = AuthUser()
    const [isMob, setisMob] = useState(false)
    const [mobDropMenu, setMobDropMenu] = useState()
    const [mobDropMenu1, setMobDropMenu1] = useState()
    const [isShrunk, setShrunk] = useState(false)
    const [HClass, setHClass] = useState('')
    const [isLogin, setIsLogin] = useState(false)

    let user = localStorage.getItem('user')
    localStorage.removeItem('email_verification')

    user = user ? JSON.parse(user) : {}

    let isOrganizationComplated = user.is_org_profile_completed

    useEffect(() => {
        //checkIsLoginUser();
        //
        const onScroll = () => {
            setShrunk((isShrunk) => {
                if (
                    !isShrunk &&
                    (document.body.scrollTop > 20 ||
                        document.documentElement.scrollTop > 20)
                ) {
                    return true
                }

                if (
                    isShrunk &&
                    document.body.scrollTop < 4 &&
                    document.documentElement.scrollTop < 4
                ) {
                    return false
                }

                return isShrunk
            })
        }

        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    useEffect(() => {
        if (window.location.pathname == '/how-we-do') {
            setHClass('theme-light-bg')
        } else if (window.location.pathname == '/resources') {
            setHClass('theme-light-bg')
        } else if (window.location.pathname == '/digital-innovation') {
            setHClass('theme-light-bg')
        } else {
            setHClass('')
        }
    }, [window.location.pathname])

    const onChangeBodyClass = (value) => {
        setisMob(value)
        value
            ? document.body.classList.add('overflow-active')
            : document.body.removeAttribute('class')
    }

    const checkIsLoginUser = () => {
        try {
            http.get(`/me`)
                .then((response) => {
                    let result = response.data
                    if (result.status == false) {
                        setIsLogin(false)
                    }
                    setIsLogin(true)
                })
                .catch((error) => {
                    if (error.response.status) {
                        setIsLogin(false)
                    }
                })
        } catch (error) {
            setIsLogin(false)
        }
    }

    return (
        <>
            <header
                className={`${
                    isShrunk
                        ? `header-wrap black-header fix-header ${HClass}`
                        : `header-wrap black-header ${HClass}`
                }`}
            >
                {/* test theme-light-bg use this class for how its  works and use case pages */}
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="main-logo">
                            <Link to="/">
                                <img
                                    className="white-logo"
                                    src={logo}
                                    alt="white-logo"
                                />
                                <img
                                    className="black-logo"
                                    src={BlackLogo}
                                    alt="white-logo"
                                />
                            </Link>
                        </div>
                        <nav
                            className={`navbar navbar-expand-lg main-menu ${
                                isMob ? 'active' : ''
                            }`}
                        >
                            <div className="container-fluid">
                                <div className="collapse navbar-collapse">
                                    <ul className="navbar-nav">
                                        <li
                                            className={`nav-item  ${
                                                mobDropMenu ? 'show' : ''
                                            }`}
                                        >
                                            <a
                                                className="nav-link cursor-pointer"
                                                aria-current="page"
                                                onClick={() =>
                                                    setMobDropMenu(!mobDropMenu)
                                                }
                                            >
                                                Solutions{' '}
                                                <span className="downArrow"></span>
                                            </a>
                                            <ul className="sub-menu">
                                                <li
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                >
                                                    <NavLink to="/agency-management-suite">
                                                        Agency Management Suite
                                                    </NavLink>
                                                </li>
                                                <li
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                >
                                                    <NavLink to="/managed-teams">
                                                        Managed Teams
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={() =>
                                                onChangeBodyClass(!isMob)
                                            }
                                            className="nav-item"
                                        >
                                            <NavLink
                                                className="nav-link"
                                                to="/pricing"
                                            >
                                                Pricing
                                            </NavLink>
                                        </li>
                                        <li
                                            className={`nav-item  ${
                                                mobDropMenu1 ? 'show' : ''
                                            }`}
                                        >
                                            <a
                                                className="nav-link cursor-pointer"
                                                aria-current="page"
                                                onClick={() =>
                                                    setMobDropMenu1(
                                                        !mobDropMenu1
                                                    )
                                                }
                                            >
                                                Resources{' '}
                                                <span className="downArrow"></span>
                                            </a>
                                            <ul className="sub-menu">
                                                {/* <li
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                >
                                                    <a href="https://weteams.io/blogs">
                                                        Blogs
                                                    </a>
                                                </li> */}
                                                <li
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                >
                                                    <NavLink to="/experts-teams">
                                                        Experts & Teams
                                                    </NavLink>
                                                </li>
                                                <li
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                >
                                                    <NavLink to="/digital-innovation">
                                                        Digital Innovation
                                                    </NavLink>
                                                </li>
                                                <li
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                    className="nav-item"
                                                >
                                                    <NavLink
                                                        className="nav-link"
                                                        to="/how-we-do"
                                                    >
                                                        How we do it?
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={() =>
                                                onChangeBodyClass(!isMob)
                                            }
                                            className="nav-item"
                                        >
                                            <NavLink
                                                className="nav-link"
                                                to="/contact"
                                            >
                                                Contact
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <div className="mt-25 text-center for-mobile">
                                        <div>
                                            {token ? (
                                                isOrganizationComplated ? (
                                                    <Link
                                                        to="/dashboard/overview"
                                                        onClick={() =>
                                                            onChangeBodyClass(
                                                                !isMob
                                                            )
                                                        }
                                                        className="theme-transparent-btn d-inline-block mnw-160"
                                                    >
                                                        Dashboard
                                                    </Link>
                                                ) : (
                                                    <a
                                                        href="/onboarding-signup"
                                                        onClick={() =>
                                                            onChangeBodyClass(
                                                                !isMob
                                                            )
                                                        }
                                                        className="theme-transparent-btn d-inline-block mnw-160"
                                                    >
                                                        Dashboard
                                                    </a>
                                                )
                                            ) : (
                                                <Link
                                                    to="/signin"
                                                    onClick={() =>
                                                        onChangeBodyClass(
                                                            !isMob
                                                        )
                                                    }
                                                    className="theme-transparent-btn d-inline-block mnw-160"
                                                >
                                                    Sign In
                                                </Link>
                                            )}
                                        </div>
                                        {pathname != '/pricing' && (
                                            <Link
                                                to="/signup"
                                                onClick={() =>
                                                    onChangeBodyClass(!isMob)
                                                }
                                                className="theme-btn  d-inline-block mnw-160 mt-2"
                                            >
                                                Sign Up
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="header-right ml-auto">
                            {token ? (
                                isOrganizationComplated ? (
                                    <Link
                                        to="/dashboard/overview"
                                        className="theme-transparent-btn d-inline-block for-desktop"
                                    >
                                        Dashboard
                                    </Link>
                                ) : (
                                    <a
                                        href="/onboarding-signup"
                                        className="theme-transparent-btn d-inline-block for-desktop"
                                    >
                                        Dashboard
                                    </a>
                                )
                            ) : (
                                <Link
                                    to="/signin"
                                    className="theme-transparent-btn d-inline-block for-desktop"
                                >
                                    Sign In
                                </Link>
                            )}
                            {pathname != '/pricing' && (
                                <Link
                                    to="/signup"
                                    className="theme-btn ml-15 d-inline-block for-desktop"
                                >
                                    Sign Up
                                </Link>
                            )}
                            <div
                                className="mobile-inner-header"
                                onClick={() => onChangeBodyClass(!isMob)}
                            >
                                <div
                                    className={`mobile-inner-header-icon mobile-inner-header-icon-out ${
                                        isMob ? 'active' : ''
                                    }`}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header
