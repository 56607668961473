import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ValidationError from '../Errors/ValidationError'
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'

const FormikDatePicker = ({
    placeholderText,
    minD,
    maxD,
    minT,
    maxT,
    field,
    form,
    ignoreTodaysDefault,
    changeDetect,
    dateFormat,
    showMonthDropdown,
    showYearDropdown,
    showTimeSelect,
    showTimeSelectOnly,
    dropdownMode,
    timeIntervals,
    disabled,
    ...props
}) => {
    console.log('dateFormat', dateFormat)
    return (
        <>
            <DatePicker
                {...(disabled && { disabled })}
                {...(timeIntervals && { timeIntervals })}
                {...(showTimeSelect && { showTimeSelect })}
                {...(showTimeSelectOnly && { showTimeSelectOnly })}
                {...(showMonthDropdown && { showMonthDropdown })}
                {...(showYearDropdown && { showYearDropdown })}
                {...(dropdownMode && { dropdownMode })}
                name={field.name}
                placeholderText={placeholderText}
                value={field.value}
                selected={field.value}
                className="form-control"
                minTime={
                    minT ||
                    (ignoreTodaysDefault
                        ? ''
                        : setHours(setMinutes(new Date())))
                }
                maxTime={
                    maxT ||
                    (ignoreTodaysDefault
                        ? ''
                        : setHours(setMinutes(new Date(), 59), 23))
                }
                minDate={minD || (ignoreTodaysDefault ? '' : new Date())}
                maxDate={maxD || ''}
                onKeyDown={(value) => {
                    console.log(value)
                }}
                popperModifiers={[
                    {
                        name: 'arrow',
                        options: { padding: 24 },
                    },
                ]}
                dateFormat={dateFormat && dateFormat}
                onChange={(value, e) => {
                    console.log('value', value)
                    if (changeDetect) {
                        if (
                            e.nativeEvent.inputType == 'deleteContentBackward'
                        ) {
                        } else {
                            changeDetect(value)
                        }
                    }
                    form.setFieldValue(field.name, value)
                }}
            />
            <ValidationError name={field.name} />
        </>
    )
}

export default FormikDatePicker
