import '../../assets/dashboard/css/dashboard.css'
import '../../assets/dashboard/css/dashboard-responsive.css'
import '../../assets/agency/css/admin.css'
import '../../assets/agency/css/admin-responsive.css'
import logo from '../../assets/img/black-logo.png'
import { NavLink } from 'react-router-dom'
import Leftsidebar from './common/Leftsidebar'
import Breadcrumb from './common/Breadcrumb'
import HeaderAction from './common/HeaderAction'
import 'react-loading-skeleton/dist/skeleton.css'
import { useState } from 'react'
import { PermissionProvider } from '../../Contexts/PermissionContext'
import Box from '@mui/material/Box'

const Layout = ({ children, props }) => {
    const [isMob, setisMob] = useState(false)
    const onChangeBodyClass = (value) => {
        setisMob(value)
        value
            ? document.body.classList.add('overflow-active')
            : document.body.removeAttribute('class')
    }

    return (
        <>
            {/* <HiringProvider> */}
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: 'primary.main',
                    width: '100%',
                    minHeight: '100vh',
                }}
            >
                <PermissionProvider>
                    <Leftsidebar isMob={isMob} setisMob={setisMob} />
                </PermissionProvider>
                <Box sx={{ borderRadius: 0 }} className="dashboard-right">
                    <Box
                        component="div"
                        sx={{ pr: '12px' }}
                        className="dash-header-inner"
                    >
                        <div className="dash-header d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div
                                    className="dash-mobile-inner-header"
                                    onClick={() => onChangeBodyClass(!isMob)}
                                >
                                    <div
                                        className={`dash-mobile-inner-header-icon dash-mobile-inner-header-icon-out ${
                                            isMob ? 'active' : ''
                                        }`}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="mob-logo d-lg-none">
                                    <NavLink to="/dashboard/overview">
                                        <img src={logo} alt="logo" />
                                    </NavLink>
                                </div>
                                <div className="brd-block brd-for-desktop">
                                    <Breadcrumb />
                                </div>
                            </div>
                            <div className="ml-auto d-inline-flex align-items-center">
                                <HeaderAction />
                            </div>
                        </div>
                        <div className="brd-block brd-for-mobile d-none">
                            <Breadcrumb />
                        </div>
                    </Box>
                    <div className="page-scrollbar position-relative mt-20">
                        {children}
                    </div>
                </Box>
            </Box>
            {/* </HiringProvider> */}
        </>
    )
}

export default Layout

// const Layout = ({children}) => {
//   return <div>{children}</div>
// }

// export default Layout;
