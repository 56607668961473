import { Field, Form, Formik } from 'formik'
import AuthUser from '../../Services/AuthUser'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Box } from '@mui/material'

const validationSchema = yup.object().shape({
    filter_field: yup.array().of(yup.string()),
})

const FilterGeneral = ({ filterData, clearFilter, filterFields }) => {
    const initialValues = {
        filter_field: '',
    }

    const properCaseClient = (text) => {
        return text
            .split(' ')
            .map((word) => {
                if (word === '(s)') {
                    return '(s)'
                } else {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
            })
            .join(' ')
    }

    return (
        <div className="btn-group ad-custom-dropdown me-2 filter-block">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    filterData(values)
                    // resetForm();
                }}
            >
                {({ values, resetForm }) => (
                    <>
                        <Box
                            component="button"
                            sx={{ borderRadius: '5px', p: '8px 25px' }}
                            type="button"
                            className="btn ad-gray-btn dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span className="ad-filter-icon"></span> Filters
                        </Box>
                        <ul className="dropdown-menu dropdown-menu-end filter-box">
                            <Form autoComplete="off">
                                <div className="custom-form">
                                    {filterFields.map((val, index) => {
                                        return (
                                            <>
                                                {val.type == 'text' ? (
                                                    <div className="form-group">
                                                        <Field
                                                            name={`filter_field${index}`}
                                                            value={
                                                                values?.[
                                                                    `filter_field${index}`
                                                                ] || ''
                                                            }
                                                            as="text"
                                                            className="form-control"
                                                        />
                                                    </div>
                                                ) : (
                                                    val.type == 'dropdown' && (
                                                        <div className="form-group">
                                                            <Field
                                                                name={`filter_field${index}`}
                                                                value={
                                                                    values?.[
                                                                        `filter_field${index}`
                                                                    ] || ''
                                                                }
                                                                as="select"
                                                                className="form-control"
                                                            >
                                                                <option value="">
                                                                    {val?.defaultOption
                                                                        ? properCaseClient(
                                                                              val.defaultOption
                                                                          )
                                                                        : 'Option'}
                                                                </option>
                                                                {val?.options &&
                                                                    val.options.map(
                                                                        (
                                                                            item,
                                                                            id
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.option
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                            </Field>
                                                        </div>
                                                    )
                                                )}
                                            </>
                                        )
                                    })}
                                    <Box sx={{ display: 'flex', gap: '5px' }}>
                                        <button
                                            className="ad-theme-btn ad-sm-btn w-100"
                                            type="submit"
                                        >
                                            Apply
                                        </button>
                                        <Box
                                            component="button"
                                            sx={{
                                                padding: '3px 10px',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                borderRadius: '8px',
                                                p: '8px 25px',
                                            }}
                                            className="btn ad-gray-btn"
                                            onClick={() => {
                                                resetForm()
                                                clearFilter()
                                            }}
                                        >
                                            <span className="ad-clear-filter-icon"></span>
                                        </Box>
                                    </Box>
                                </div>
                            </Form>
                        </ul>
                    </>
                )}
            </Formik>
        </div>
    )
}

export default FilterGeneral
