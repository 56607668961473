import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as MapPinIcon } from '../../assets/dashboard/img/map-pin-dynamic.svg'
import LeadsStatusTalentSkills from './LeadsStatusTalentSkills'
import LeadsStatusProfileCard from './LeadsStatusProfileCard'
import LeadsContractModal from '../../Components/Leads/LeadsContractModal'
import TeamDetails from '../Myteams/TeamDetails'
import moment from 'moment'
import { Tooltip, useTheme } from '@mui/material'
import AgencySmModal from '../../Components/Modals/AgencySmModal'
import {
    FormControl,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'
import RequestInterview from './RequestInterview'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import AgencyModal from '../Modals/AgencyModal'
import exclamation from '../../assets/agency/img/exclamation-icon.svg'
import AuthUser from '../../Services/AuthUser'
import { toast } from 'react-toastify'
import Currency from '../Currency/Currency'
import ViewScheduleAcceptance from './ViewScheduleAcceptance'
import LeadContract from './LeadContract/LeadContract'
import ContractCreation from '../Contacts/ContractCreation'
import successCheck from '../../assets/agency/img/success-check.svg'

const LeadsCard = ({
    item,
    engagement_type,
    minBudget,
    shortlisted,
    currentTab,
    reload,
    switchTab,
    isHired,
    isDeclined,
    contractDuration,
    talent_rate,
}) => {
    const { http2, http } = AuthUser()
    const [contractSuccess, setContractSuccess] = useState({
        open: false,
        status: '',
    })
    const [showProfile, setShowProfile] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [hover, setHover] = useState(false)
    const [interviewRoundName, setInterviewRoundName] = useState('')
    const [interviewEmails, setInterviewEmails] = useState([])
    const [selectedSlot, setSelectedSlot] = useState('')
    const [currency, setCurrency] = useState('')
    const [isInterviewModalOpen, setIsInterviewModalOpen] = useState(false)
    const [hoveredButton, setHoveredButton] = useState(null)
    const [enabledButtons, setEnabledButtons] = useState([false, false, false])
    const [firstRoundDetails, setFirstRoundDetails] = useState(null)
    const [selectedInterviewRound, setSelectedInterviewRound] = useState(null)
    const [openDeclineConfirm, setOpenDeclineConfirm] = useState({
        val: '',
        open: false,
    })
    const [scheduleConfirmation, setScheduleConfirmation] = useState({
        data: '',
        open: false,
    })
    const [docFile, setDocFile] = useState(null)
    const [pdfFile, setPdfFile] = useState(null)
    const [final, setfinal] = useState('')

    const [subdoc, setsubdoc] = useState(null)
    const [subpdf, setsubpdf] = useState(null)

    const [contributionHours, setContributionHours] = useState('')
    const [rateperHour, setrateperHour] = useState('')

    const [startDate, setStartDate] = useState(
        () => new Date().toISOString().split('T')[0]
    )
    const [endDate, setEndDate] = useState(() => {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        return tomorrow.toISOString().split('T')[0]
    })

    const [leadmodal, setLeadmodal] = useState(false)
    const theme = useTheme()

    const user = JSON.parse(localStorage.getItem('user'))

    const { id } = useParams()

    const talentId = item._id

    const result = talent_rate?.find((item) =>
        item.talent_id.includes(talentId)
    )

    const talentNetworkCost = result ? result.talent_network_cost : null

    useEffect(() => {
        const checkInterviewRounds = () => {
            let rounds = [false, false, false]
            let firstRound = null
            // if (shortlisted?.length !== 0) {
            //   item.forEach(lead => {
            if (item?.interview_details) {
                item.interview_details.forEach((detail) => {
                    if (detail.interview_round_no === 1) {
                        rounds[0] = true
                        firstRound = detail
                    }
                    if (detail.interview_round_no === 2) {
                        rounds[1] = true
                        firstRound = detail
                    }
                    if (detail.interview_round_no === 3) {
                        rounds[2] = true
                        firstRound = detail
                    }
                })
            }
            //   });
            // }
            setEnabledButtons(rounds)
            setFirstRoundDetails(firstRound)
        }
        checkInterviewRounds()
    }, [shortlisted])

    const handleCloseLead = () => {
        setLeadmodal(false)
    }

    const handleOpenLead = () => {
        setLeadmodal(true)
    }

    const convertCurrToSymbol = (currency) => {
        switch (currency) {
            case 'USD':
                return ' $ '
            case 'INR':
                return ' INR '
            case 'CAD':
                return ' $ '
            default:
                return ' $ '
        }
    }

    const convertCurrToSymbol1 = (currency) => {
        switch (currency) {
            case 'USD':
                return 'USD $'
            case 'INR':
                return 'INR ₹'
            case 'CAD':
                return 'CAD $'
            default:
                return 'USD $'
        }
    }

    const filterMinContract = (minContract) => {
        console.log('min contract', minContract)
        let rupeeDollarMainArr = minContract.split('/')
        let rupeeArr = rupeeDollarMainArr[0].split(' ')
        let dollarArr = rupeeDollarMainArr[1].split(' ')

        if (user.currency == 'INR') {
            return rupeeArr[0]
        } else {
            return dollarArr[1]
        }
    }

    console.log(item, 'fjiksdnsdjcnsdjhcsdjhIIIIIIIIIIIIIIIIIIIIIIIII')

    const handleInterviewRoundNameChange = (event) => {
        setInterviewRoundName(event.target.value)
    }

    const handleInterviewEmailsChange = (event) => {
        setInterviewEmails(event.target.value)
    }

    const handleSlotChange = (event) => {
        setSelectedSlot(event.target.value)
    }

    const handleMouseEnter = (index) => {
        setHoveredButton(index)
    }

    const handleMouseLeave = () => {
        setHoveredButton(null)
    }

    const handleInterviewSubmit = () => {
        console.log('Interview Scheduled:', {
            interviewRoundName,
            interviewEmails,
            selectedSlot,
        })
        setIsInterviewModalOpen(false)
    }

    const shortName = () => {
        let firstName = item.first_name[0].charAt(0).toUpperCase()
        let lastName = item.last_name[0].charAt(0).toUpperCase()
        return `${firstName}${lastName}`
    }

    const buttons = ['Round 1', 'Round 2', 'Round 3']

    const modalSwitchCondition = (interviewDetails, roundNo) => {
        if (!interviewDetails) {
            return 'notScheduled'
        }

        let temp = interviewDetails.filter(
            (val) => val?.interview_round_no == roundNo
        )
        console.log('temp', temp)

        if (temp.length == 0) {
            return 'notScheduled'
        }

        if (temp[0].interview_status !== undefined) {
            if (temp[0].interview_status == 'declined') {
                return 'declined'
            } else if (temp[0].interview_status == 'completed') {
                return 'completed'
            } else if (temp[0].interview_status == 'cancelled') {
                return 'cancelled'
            } else if (temp[0].interview_status == 'requested') {
                return 'requested'
            }
        }

        if (temp[0].scheduled_slot !== undefined) {
            console.log(
                moment().valueOf() > moment(temp[0].scheduled_slot).valueOf(),
                moment(moment().format('YYYY-MM-DD hh:mm:ss')).valueOf(),
                moment(
                    moment(moment.utc(temp[0].scheduled_slot)).format(
                        'YYYY-MM-DD hh:mm:ss'
                    )
                ).valueOf()
            )
            const currentTime = moment(
                moment().format('YYYY-MM-DD hh:mm:ss')
            ).valueOf()
            const scheduledTime = moment(
                moment(moment.utc(temp[0].scheduled_slot)).format(
                    'YYYY-MM-DD hh:mm:ss'
                )
            ).valueOf()
            if (currentTime > scheduledTime) {
                return 'crossed'
            }
        }

        return 'planned'
    }

    const buttonText = (text) => {
        switch (text) {
            case 'declined':
                return 'Request declined'
            case 'crossed':
                return 'Interview Scheduled'
            case 'cancelled':
                return 'Interview Cancelled'
            case 'planned':
                return 'Interview Scheduled'
            case 'requested':
                return 'Interview Requested'
            case 'completed':
                return 'Interview completed'
            default:
                return 'Interview Scheduled'
        }
    }

    const declineRequestCall = async (data) => {
        http2
            .post(`/update-interview-detail/${data.interviewId}`, {
                interview_status: 'declined',
                hiring_id: data.hiringId,
            })
            .then((resp) => {
                toast.success('Request declined')
                setIsInterviewModalOpen(false)
                reload()
            })
    }

    return (
        <Grid container>
            <Grid item md={12} lg={12} xl={12}>
                <Paper variant="leadsCard">
                    <Stack spacing={2} sx={{ marginTop: '2px' }}>
                        <Grid
                            container
                            alignItems="flex-start"
                            columns={{ md: 14, lg: 14, xl: 14 }}
                        >
                            <Grid md={7} lg={7} xl={7} item>
                                <Stack direction="row">
                                    {item?.avatar ? (
                                        <Avatar
                                            sx={{
                                                width: 68,
                                                height: 68,
                                                mr: '16px',
                                                backgroundColor:
                                                    item.color_code,
                                                border: `1px solid ${item.color_code}`,
                                                fontSize: '1.35rem',
                                            }}
                                            src={item.avatar}
                                            slotProps={{
                                                img: {
                                                    sx: {
                                                        width: 66,
                                                        height: 66,
                                                    },
                                                },
                                            }}
                                        >
                                            {shortName()}
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            sx={{
                                                width: 75,
                                                height: 75,
                                                mr: '12px',
                                                border: `1px solid ${item.color_code}`,
                                                display: 'flex',
                                                color: 'white',
                                                backgroundColor: `${item.color_code}`,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                            }}
                                        >
                                            {shortName()}
                                        </Avatar>
                                    )}
                                    <Stack>
                                        <Typography
                                            sx={{ mb: '5px' }}
                                            variant="size3SemiboldTextText3"
                                        >{`${item.first_name} ${item.last_name.charAt(0)}`}</Typography>
                                        <Typography variant="sizeleadRegularTer2">
                                            {item.role ? item.role : '-'}
                                        </Typography>
                                        {(() => {
                                            const years =
                                                item.experience_in_year
                                            const months =
                                                item.experience_in_month
                                            let displayText = ''

                                            if (years > 1 && months === 0) {
                                                displayText = `${years} yrs`
                                            } else if (
                                                years > 1 &&
                                                months > 0
                                            ) {
                                                displayText = `${years}+ yrs`
                                            } else if (
                                                years === 1 &&
                                                months === 0
                                            ) {
                                                displayText = `${years} yr`
                                            } else if (
                                                years === 1 &&
                                                months > 0
                                            ) {
                                                displayText = `${years}+ yrs `
                                            } else if (
                                                years === 0 &&
                                                months > 0
                                            ) {
                                                displayText = `${months} + mths`
                                            } else {
                                                displayText = 'No experience'
                                            }

                                            useEffect(() => {
                                                setfinal(displayText)
                                            }, [])

                                            return (
                                                <span className="ad-exp-custom">
                                                    {displayText}
                                                </span>
                                            )
                                        })()}
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                md={7}
                                lg={7}
                                xl={7}
                                sx={{ alignItems: 'center' }}
                            >
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <Paper variant="leadsEstimateCard">
                                        {console.log('min contract', minBudget)}
                                        <Typography
                                            sx={{
                                                mb: '10px',
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="sizeleadRegularTer"
                                        >
                                            Client Budget
                                        </Typography>
                                        <Typography variant="size4SemiboldPrimMain">
                                            {/* {user
                        ? user?.currency
                          ? convertCurrToSymbol(user.currency)
                          : "$"
                        : "$"}{" "} */}
                                            <Currency
                                                amount={
                                                    user && minBudget
                                                        ? user?.currency ==
                                                          'INR'
                                                            ? filterMinContract(
                                                                  minBudget
                                                              )
                                                            : user?.currency ==
                                                                'USD'
                                                              ? filterMinContract(
                                                                    minBudget
                                                                )
                                                              : filterMinContract(
                                                                    minBudget
                                                                )
                                                        : '-'
                                                }
                                            />
                                            {/* {user && minBudget
                        ? user?.currency == "INR"
                          ? filterMinContract(minBudget)
                          : user?.currency == "USD"
                          ? filterMinContract(minBudget)
                          : filterMinContract(minBudget)
                        : "-"} */}
                                        </Typography>
                                    </Paper>
                                    <Paper variant="leadsEstimateCard">
                                        <Typography
                                            sx={{
                                                mb: '10px',
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="sizeleadRegularTer"
                                        >
                                            Rate/Hour
                                        </Typography>
                                        <Typography variant="size4SemiboldPrimMain">
                                            {/* {user
                        ? user?.currency
                          ? convertCurrToSymbol(user.currency)
                          : "$"
                        : "$"}{" "}
                      {user
                        ? user?.currency == "INR"
                          ? item.internal_rate_currencies.INR
                          : user?.currency == "USD"
                          ? item.internal_rate_currencies.USD
                          : item.internal_rate_currencies.CAD
                        : "-"} */}
                                            <Currency
                                                amount={
                                                    user
                                                        ? user?.currency ==
                                                          'INR'
                                                            ? talent_rate?.length >
                                                              0
                                                                ? talentNetworkCost
                                                                : item
                                                                      .currency_rate
                                                                      .INR
                                                            : talent_rate?.length >
                                                                0
                                                              ? talentNetworkCost
                                                              : user?.currency ==
                                                                  'USD'
                                                                ? talent_rate?.length >
                                                                  0
                                                                    ? talentNetworkCost
                                                                    : item
                                                                          .currency_rate
                                                                          .USD
                                                                : talent_rate?.length >
                                                                    0
                                                                  ? talentNetworkCost
                                                                  : item
                                                                        .currency_rate
                                                                        .CAD
                                                        : '-'
                                                }
                                            />
                                        </Typography>
                                    </Paper>

                                    <Paper variant="leadsEstimateCard">
                                        <Typography
                                            sx={{
                                                mb: '10px',
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="sizeleadRegularTer"
                                        >
                                            Duration
                                        </Typography>
                                        <Typography variant="size4SemiboldPrimMain">
                                            {contractDuration
                                                ? contractDuration > 1
                                                    ? contractDuration +
                                                      ' Months'
                                                    : contractDuration +
                                                      ' Month'
                                                : ''}
                                        </Typography>
                                    </Paper>
                                    <Paper variant="leadsEstimateCard">
                                        <Typography
                                            sx={{
                                                mb: '10px',
                                                whiteSpace: 'nowrap',
                                            }}
                                            variant="sizeleadRegularTer"
                                        >
                                            Engagement
                                        </Typography>
                                        <Typography variant="size4SemiboldPrimMain">
                                            {engagement_type
                                                ? engagement_type + ' hrs'
                                                : '-'}
                                        </Typography>
                                    </Paper>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Box sx={{ marginBottom: '10px' }}>
                            <LeadsStatusTalentSkills item={item} />
                        </Box>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                {(isHired || isDeclined) &&
                                modalSwitchCondition(
                                    item?.interview_details,
                                    1
                                ) == 'notScheduled' &&
                                modalSwitchCondition(
                                    item?.interview_details,
                                    2
                                ) == 'notScheduled' &&
                                modalSwitchCondition(
                                    item?.interview_details,
                                    3
                                ) == 'notScheduled' ? (
                                    <></>
                                ) : (
                                    currentTab != 'Matched' && (
                                        <>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    mb: '16px',
                                                }}
                                                variant="size3MediumTerTer8"
                                            >
                                                INTERVIEW(S)
                                            </Typography>
                                            <Box display="flex" gap="15px">
                                                {/* {console.log("enabledButtons", enabledButtons, buttons)} */}
                                                {buttons.map((text, index) => {
                                                    return (
                                                        <Box
                                                            sx={{
                                                                position:
                                                                    'relative',
                                                            }}
                                                        >
                                                            {modalSwitchCondition(
                                                                item?.interview_details,
                                                                index + 1
                                                            ) !=
                                                                'notScheduled' && (
                                                                <Box
                                                                    component="button"
                                                                    className="dash-theme-btn fw-500 br-20 dashview-profile-btn"
                                                                    sx={{
                                                                        fontSize:
                                                                            '12px',
                                                                        p: '0 5px',
                                                                        minWidth:
                                                                            'auto',
                                                                        position:
                                                                            'absolute',
                                                                        top: '-12px',
                                                                        left: '18px',
                                                                        '&:hover':
                                                                            {
                                                                                backgroundColor:
                                                                                    theme
                                                                                        .palette
                                                                                        .primary
                                                                                        .main,
                                                                                color: theme
                                                                                    .palette
                                                                                    .common
                                                                                    .white,
                                                                            },
                                                                    }}
                                                                >
                                                                    Round{' '}
                                                                    {index + 1}
                                                                </Box>
                                                            )}
                                                            {((isHired &&
                                                                isHired.filter(
                                                                    (v) =>
                                                                        v._id ==
                                                                        item._id
                                                                ).length > 0) ||
                                                                isDeclined) &&
                                                            modalSwitchCondition(
                                                                item?.interview_details,
                                                                index + 1
                                                            ) ==
                                                                'notScheduled' ? (
                                                                <></>
                                                            ) : (
                                                                <Tooltip
                                                                    slotProps={{
                                                                        popper: {
                                                                            className:
                                                                                'tooltipPrimMain',
                                                                        },
                                                                    }}
                                                                    title={
                                                                        modalSwitchCondition(
                                                                            item?.interview_details,
                                                                            index +
                                                                                1
                                                                        ) ==
                                                                        'notScheduled'
                                                                            ? "You'll see the interview details once they're requested"
                                                                            : ''
                                                                    }
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            key={
                                                                                index
                                                                            }
                                                                            component="button"
                                                                            className={`dash-theme-gray-btn fw-500 br-20 dashview-profile-btn`}
                                                                            sx={{
                                                                                textTransform:
                                                                                    'capitalize',
                                                                                ml: '0px',
                                                                                opacity:
                                                                                    modalSwitchCondition(
                                                                                        item?.interview_details,
                                                                                        index +
                                                                                            1
                                                                                    ) ==
                                                                                    'notScheduled'
                                                                                        ? 0.5
                                                                                        : 1,
                                                                                minWidth:
                                                                                    '101px',
                                                                                ...((modalSwitchCondition(
                                                                                    item?.interview_details,
                                                                                    index +
                                                                                        1
                                                                                ) ==
                                                                                    'cancelled' ||
                                                                                    modalSwitchCondition(
                                                                                        item?.interview_details,
                                                                                        index +
                                                                                            1
                                                                                    ) ==
                                                                                        'declined') && {
                                                                                    color: theme
                                                                                        .palette
                                                                                        .error
                                                                                        .main,
                                                                                    borderColor:
                                                                                        theme
                                                                                            .palette
                                                                                            .error
                                                                                            .main,
                                                                                    '&.dash-theme-gray-btn:hover':
                                                                                        {
                                                                                            color: theme
                                                                                                .palette
                                                                                                .common
                                                                                                .white,
                                                                                            backgroundColor:
                                                                                                theme
                                                                                                    .palette
                                                                                                    .error
                                                                                                    .main,
                                                                                        },
                                                                                }),
                                                                            }}
                                                                            onMouseEnter={() =>
                                                                                handleMouseEnter(
                                                                                    index
                                                                                )
                                                                            }
                                                                            onMouseLeave={
                                                                                handleMouseLeave
                                                                            }
                                                                            onClick={() => {
                                                                                setSelectedInterviewRound(
                                                                                    index
                                                                                )
                                                                                setIsInterviewModalOpen(
                                                                                    true
                                                                                )
                                                                            }}
                                                                            disabled={
                                                                                !enabledButtons[
                                                                                    index
                                                                                ]
                                                                            }
                                                                        >
                                                                            {modalSwitchCondition(
                                                                                item?.interview_details,
                                                                                index +
                                                                                    1
                                                                            ) ==
                                                                            'notScheduled'
                                                                                ? 'Round ' +
                                                                                  (index +
                                                                                      1)
                                                                                : buttonText(
                                                                                      modalSwitchCondition(
                                                                                          item?.interview_details,
                                                                                          index +
                                                                                              1
                                                                                      )
                                                                                  )}
                                                                        </Box>
                                                                    </Box>
                                                                </Tooltip>
                                                            )}
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </>
                                    )
                                )}
                            </Grid>

                            <Grid item>
                                <Box sx={{ display: 'flex' }}>
                                    {currentTab == 'Hired' &&
                                        isHired &&
                                        isHired.find(
                                            (v) => v._id === item._id
                                        ) &&
                                        (item?.contract_details &&
                                        item?.contract_details.length != 0 ? (
                                            item?.contract_details[0]?.contract_documents?.[0]?.document_original_name.split(
                                                '.'
                                            )?.[
                                                item.contract_details[0].contract_documents[0].document_original_name.split(
                                                    '.'
                                                ).length - 1
                                            ] == 'pdf' &&
                                            item?.contract_details[0]?.contract_documents?.[1]?.document_original_name.split(
                                                '.'
                                            )[
                                                item.contract_details[0].contract_documents[1].document_original_name.split(
                                                    '.'
                                                ).length - 1
                                            ] == 'pdf' &&
                                            item?.contract_details[0]
                                                ?.contract_documents?.[1]
                                                ?.uploaded_by !=
                                                item?.contract_details[0]
                                                    ?.contract_documents?.[0]
                                                    ?.uploaded_by ? (
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{ display: 'flex' }}
                                                    >
                                                        <Box
                                                            component="button"
                                                            className={`dash-theme-gray-btn fw-500 br-20 dashview-profile-btn`}
                                                            sx={{
                                                                textTransform:
                                                                    'capitalize',
                                                                ml: '0px',
                                                                minWidth:
                                                                    '101px',
                                                            }}
                                                            onClick={
                                                                handleOpenLead
                                                            }
                                                        >
                                                            Contract
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <Box
                                                        component="button"
                                                        className="dash-theme-btn fw-500 br-20 dashview-profile-btn"
                                                        sx={{
                                                            fontSize: '12px',
                                                            p: '0 5px',
                                                            minWidth: 'auto',
                                                            position:
                                                                'absolute',
                                                            top: '-12px',
                                                            left: '18px',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    theme
                                                                        .palette
                                                                        .primary
                                                                        .main,
                                                                color: theme
                                                                    .palette
                                                                    .common
                                                                    .white,
                                                            },
                                                        }}
                                                    >
                                                        Contract
                                                    </Box>
                                                    <Box
                                                        sx={{ display: 'flex' }}
                                                    >
                                                        <Box
                                                            component="button"
                                                            className={`dash-theme-gray-btn fw-500 br-20 dashview-profile-btn`}
                                                            sx={{
                                                                textTransform:
                                                                    'capitalize',
                                                                ml: '0px',
                                                                minWidth:
                                                                    '101px',
                                                            }}
                                                            onClick={
                                                                handleOpenLead
                                                            }
                                                        >
                                                            Review Contract
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        ) : (
                                            <Tooltip title="You can view contract details once the contract creation process is initiated">
                                                <Box
                                                    disabled
                                                    type="button"
                                                    className="dash-theme-gray-btn fw-500 br-20 dashview-profile-btn"
                                                    sx={{
                                                        textTransform:
                                                            'capitalize',
                                                        ml: '16px',
                                                        opacity: 0.5,
                                                    }}
                                                >
                                                    Contract
                                                </Box>
                                            </Tooltip>
                                        ))}

                                    <Box
                                        // variant='NotSelected2'
                                        type="button"
                                        onClick={() =>
                                            setShowProfile(!showProfile)
                                        }
                                        className="dash-theme-gray-btn fw-500 br-20 dashview-profile-btn"
                                        sx={{
                                            textTransform: 'capitalize',
                                            ml: '16px',
                                            // "&:hover":{
                                            //   backgroundColor: theme.palette.primary.main,
                                            //   color: theme.palette.common.white
                                            // }
                                        }}
                                    >
                                        View Profile
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* {item?.interview_details?.length > 0 && (currentTab == "Shortlisted" ||
                  currentTab == "Interviewed") &&
              item?.interview_details?.map((val, ind) => {
                if (val?.scheduled_slot) {
                  return (
                    <Typography
                      component="div"
                      sx={{ whiteSpace: "nowrap", mt: "12px" }}
                      variant="size3MediumTerTer8"
                    >
                      {`Round ${val.interview_round_no} - ${moment(
                        val.scheduled_slot
                      ).format("dddd, DD MMM YYYY - hh:mm A")}`}
                    </Typography>
                  );
                } else {
                  return <></>;
                }
              })} */}
                    </Stack>
                </Paper>
            </Grid>

            <TeamDetails
                openTeamDetail={showProfile}
                closeTeamDtl={() => {
                    setShowProfile(false)
                }}
                experience_in_year={`${item.experience_in_year}`}
                experience_in_month={`${item.experience_in_month}`}
                final={final}
                teamDetails={{
                    name: item.full_name,
                    email: item.email,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    full_name: `${item.first_name} ${item.last_name[0]}.`,
                    color_code: item.color_code,
                    experience_in_year: `${item.experience_in_year}`,
                    experience_in_month: `${item.experience_in_month}`,
                    phone_number: item.phone_number,
                    avatar: item.avatar,
                    short_name: `${item.first_name} ${item.last_name[0]}.`,
                    role: item.role,
                    // experience: `${item.experience_in_year} years ${item.experience_in_month} months`,
                    experience: final,

                    salary_per_month_format: item.salary_per_month / 1000 + 'K',
                    internal_rate_format:
                        /*convertCurrToSymbol1(user?.currency) + */ item
                            .internal_rate_currencies[user?.currency],
                    skills: item.selected_skills.map((val, ind) => {
                        return {
                            value: ind,
                            label: val.name,
                        }
                    }),
                    onboarding_date: moment(item.onboarding_date).format(
                        'DD-MMM-YYYY'
                    ),
                    monthly_working_hours: item.daily_working_hours * 30,
                    payroll_type: item.payroll_type,
                    portfolio: item?.portfolio || '',
                    certification: item.certification || '',
                    other_skills:
                        item?.selected_other_skills?.map((val, ind) => {
                            return {
                                value: ind,
                                label: val.name,
                            }
                        }) || '',
                }}
                // teamDetails={item}
            />

            <LeadsContractModal
                open={showModal}
                onClose={() => {
                    setShowModal(false)
                }}
                PaperProps={{
                    sx: {
                        width: 'auto',
                        maxWidth: '1108px',
                    },
                }}
            />

            <AgencyModal
                open={isInterviewModalOpen}
                // close={() => setIsInterviewModalOpen(false)}
                // title="Schedule Interview"
            >
                <RequestInterview
                    key={selectedInterviewRound + Math.random()}
                    scheduledCallback={(data) => {
                        setIsInterviewModalOpen(false)
                        console.log('data===========', data)
                        setScheduleConfirmation({ data: data, open: true })
                    }}
                    isHired={
                        isHired &&
                        isHired.filter((v) => v._id == item._id).length > 0
                    }
                    isDeclined={isDeclined}
                    Modalclose={(val) => {
                        reload()
                        // if(val){
                        //   switchTab();
                        // }
                        setIsInterviewModalOpen(false)
                    }}
                    reload={reload}
                    firstRoundDetails={firstRoundDetails}
                    shortlisted={shortlisted}
                    item={item}
                    interviewRound={selectedInterviewRound}
                    openDecline={(interviewId, hiringId) => {
                        setOpenDeclineConfirm({
                            val: { interviewId, hiringId },
                            open: true,
                        })
                    }}
                    myid={id}
                />
            </AgencyModal>
            <AgencySmModal
                open={openDeclineConfirm.open}
                close={() => {
                    setOpenDeclineConfirm({
                        ...openDeclineConfirm,
                        open: false,
                    })
                }}
            >
                <div className="text-center">
                    <img src={exclamation} alt="question" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Are you sure?
                </div>
                {/* <div className="mt-2 content text-center">
            <p className="ad-lgray-text">
              Are you sure ?
            </p>
          </div> */}
                <div className="mt-3 text-center row px-3">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={() => {
                                setOpenDeclineConfirm({
                                    ...openDeclineConfirm,
                                    open: false,
                                })
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-theme-btn ad-sm-btn w-100 d-block"
                            onClick={() => {
                                declineRequestCall(openDeclineConfirm.val)
                            }}
                        >
                            Yes
                        </button>
                    </div>
                </div>
            </AgencySmModal>

            {/* <AgencyModal
        open={leadmodal}
        close={handleCloseLead}
        >
          <LeadContract
            handleCLose={handleCloseLead}
            setDocFile={setDocFile} 
            setPdfFile={setPdfFile}  
            docfile={docFile} 
            pdffile={pdfFile}
            setContributionHours = {setContributionHours}
            setrateperHour = {setrateperHour}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}

            contributionHours= {contributionHours}
            rateperHour= {rateperHour}
          />


        </AgencyModal> */}
            <AgencyModal
                dialogStyle={{ maxWidth: '780px' }}
                open={leadmodal}
                close={handleCloseLead}
            >
                {item && (
                    <ContractCreation
                        contractStatus={
                            item?.contract_details &&
                            item?.contract_details.length != 0
                                ? item?.contract_details[0]?.contract_documents?.[0]?.document_original_name.split(
                                      '.'
                                  )?.[
                                      item.contract_details[0].contract_documents[0].document_original_name.split(
                                          '.'
                                      ).length - 1
                                  ] == 'pdf' &&
                                  item?.contract_details[0]?.contract_documents?.[1]?.document_original_name.split(
                                      '.'
                                  )[
                                      item.contract_details[0].contract_documents[1].document_original_name.split(
                                          '.'
                                      ).length - 1
                                  ] == 'pdf' &&
                                  item?.contract_details[0]
                                      ?.contract_documents?.[1]?.uploaded_by !=
                                      item?.contract_details[0]
                                          ?.contract_documents?.[0]?.uploaded_by
                                    ? 'Contract'
                                    : 'Review Contract'
                                : 'Contract'
                        }
                        engagementType={engagement_type}
                        talent_rate={talent_rate}
                        handleCLose={handleCloseLead}
                        datePrefill={item}
                        success={(status) => {
                            handleCloseLead()
                            setContractSuccess({ status: status, open: true })
                        }}
                        contractDuration={contractDuration}
                    />
                )}
            </AgencyModal>

            <AgencyModal open={scheduleConfirmation.open}>
                <ViewScheduleAcceptance
                    key={
                        scheduleConfirmation.data.interview_round_name +
                        Math.round()
                    }
                    data={scheduleConfirmation.data}
                    item={item}
                    modalclose={() => {
                        setScheduleConfirmation({
                            ...scheduleConfirmation,
                            open: false,
                        })
                        reload()
                    }}
                />
            </AgencyModal>
            <AgencySmModal
                open={contractSuccess.open}
                close={() => {
                    reload(3)
                    setContractSuccess({ ...contractSuccess, open: false })
                }}
            >
                <div className="text-center">
                    <img src={successCheck} alt="question" />
                </div>
                <div className="mt-3 content text-center">
                    <p>
                        {contractSuccess.status == 'completed'
                            ? 'Congratulations! your contract has been started'
                            : 'Contract request has been sent successfully.'}
                    </p>
                </div>
                <div className="mt-30 text-center">
                    <button
                        type="button"
                        className="ad-theme-btn ad-sm-btn"
                        onClick={() => {
                            reload(3)
                            setContractSuccess({
                                ...contractSuccess,
                                open: false,
                            })
                        }}
                    >
                        Okay
                    </button>
                </div>
            </AgencySmModal>
        </Grid>
    )
}

export default LeadsCard
