import { InrSeprator, UsdCadSeprator } from '../../Config/Helper'

const Currency = ({
    amount,
    removeCurrencySymble,
    removeCurrencyType,
    showicon,
    showSeperator,
    disableDefaultFontFamily,
}) => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { currency } = user
    let currencySymble = currency === 'INR' ? ' ₹' : ' $'
    return (
        <>
            {showicon ? (
                amount === '-' ? (
                    '-'
                ) : (
                    <>
                        <span
                            className={
                                disableDefaultFontFamily ? '' : 'font-arial'
                            }
                        >
                            {currency !== 'INR' ? currency : ''}
                            {removeCurrencySymble ? '' : currencySymble}
                        </span>
                        {showSeperator
                            ? currency !== 'INR'
                                ? UsdCadSeprator(Math.round(amount))
                                : InrSeprator(Math.round(amount))
                            : amount}
                    </>
                )
            ) : amount === '-' ? (
                '-'
            ) : (
                <>
                    <span
                        className={disableDefaultFontFamily ? '' : 'font-arial'}
                    >
                        {removeCurrencyType ? '' : currency}
                        {removeCurrencySymble ? '' : currencySymble}
                    </span>
                    {showSeperator
                        ? currency !== 'INR'
                            ? UsdCadSeprator(Math.round(amount))
                            : InrSeprator(Math.round(amount))
                        : amount}
                </>
            )}
        </>
    )
}

export default Currency
