import FormikDatePicker from '../Form/FormikDatePicker'
import { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import AuthUser from '../../Services/AuthUser'
import BtnSpinner from '../Spinners/BtnSpinner'
import moment from 'moment'
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon-dynamic.svg'
import { IconButton, SvgIcon, Box } from '@mui/material'

const validationSchema = yup.object({
    due_date: yup.string().required('This field is required!'),
})
const InvoiceDueDate = ({
    setOpenInvoiceDue,
    selectedTalents,
    selectedClientId,
    invoice_type,
    month,
    selectedClient,
}) => {
    const navigate = useNavigate()

    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)

    const previewInvoice = (fields) => {
        const jsDate = new Date(fields.due_date)
        const isoFormattedDate = jsDate.toISOString()
        console.log('fields........', fields)
        fields['due_date'] = isoFormattedDate
        fields = { ...selectedClientId, ...fields }
        try {
            setIsLoading(true)
            http2
                .post(`/project/preview-invoice/data`, fields)
                .then((response) => {
                    let result = response.data.data
                    if (result.project_currency === 'INR') {
                        navigate(
                            '/dashboard/billings/project-billing/preview-gst',
                            {
                                state: {
                                    result: {
                                        ...result,
                                        invoice_duration_from:
                                            fields.invoice_duration_from,
                                        invoice_duration_to:
                                            fields.invoice_duration_to,
                                    },
                                },
                            }
                        )
                    } else {
                        navigate(
                            '/dashboard/billings/project-billing/preview',
                            {
                                state: {
                                    result: {
                                        ...result,
                                        invoice_duration_from:
                                            fields.invoice_duration_from,
                                        invoice_duration_to:
                                            fields.invoice_duration_to,
                                    },
                                },
                            }
                        )
                    }

                    setIsLoading(false) // stop loading spinner
                })
                .catch((error) => {
                    if (error.response) {
                        //toast.error(error.response.data.data);
                        setIsLoading(false) // stop loading spinner
                    }
                })
        } catch (error) {
            // toast.error("something went wrong");
            console.log(error)
        }
    }
    ///
    return (
        <>
            {console.log('invoice_type', invoice_type)}
            <Formik
                enableReinitialize={true}
                initialValues={{
                    due_date: '',
                    month: month,
                    invoice_duration_from: moment(
                        selectedClient.from_month
                    ).format('DD-MM-YYYYT00:00:00.000+00:00'),
                    invoice_duration_to: moment(selectedClient.to_month).format(
                        'DD-MM-YYYYT00:00:00.000+00:00'
                    ),
                    invoice_type: invoice_type,
                    selectedTalents: selectedTalents || '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    previewInvoice(values)
                    resetForm()
                }}
            >
                {({ values, setFieldValue, field, form, formik }) => (
                    <>
                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Add Billing
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setOpenInvoiceDue(false)
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2">
                                    <div className="position-relative dash-calendar-icon mod-dash-calendar">
                                        <div className="col-12">
                                            <div className="position-relative">
                                                <label className="label-pos">
                                                    Due Date
                                                </label>
                                                <Field
                                                    // minD={newContractDt}
                                                    dateFormat="dd-MMM-yyyy"
                                                    name="due_date"
                                                    placeholder="Contract start date"
                                                    component={FormikDatePicker}
                                                    type="text"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-4 col-12 text-center">
                                            {isLoading ? (
                                                <BtnSpinner className="ad-theme-btn w-100" />
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="ad-theme-btn w-100"
                                                >
                                                    Preview Invoice
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default InvoiceDueDate
