import React, { useState } from 'react'
import Currency from '../Currency/Currency'
import weteamsbadge from '../../assets/agency/img/white-weteams-icon.svg'
import SassCurrency from '../Currency/SassCurrency'
import {
    Avatar,
    Box,
    ClickAwayListener,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { VictoryLabel, VictoryPie } from 'victory'
import NoDataProjectTeam from '../Myteams/NoDataProjectTeam'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as MenuIcon } from '../../assets/agency/img/dp-menu-icon.svg'
import AgencyModal from '../Modals/AgencyModal'
import SendTeamMemberAccountInvite from '../Myteams/SendTeamMemberAccountInvite'

const ProjectTeamMembers = ({
    item,
    openEditTeamModal,
    deleteProjectTeamModal,
    jira_completed,
    clientCurrency,
    openAddTeamModal,
    viewTeamDetails,
    toolType,
}) => {
    const {
        id,
        color_code,
        avatar,
        designation,
        full_name,
        short_name,
        isAddedOnJira,
        experience,
        first_name,
        role,
        reserved_hours,
        used_hours,
        project_currency,
        client_hourly_rate,
        internal_monthly_cost,
        client_billing_amount,
        work_progress_percentage,
        percentage_bar_color_code,
        weteams_talent,
        my_currency_internal_monthly_cost,
        my_currency_client_hourly_rate,
        my_currency_client_billing_amount,

        totalDelayedStories,
        totalOntimeStories,
        inProgressDelayedStories,
        completedDelayedStories,
        pendingDelayedStories,
        inProgressOnTimeStories,
        completedOnTimeStories,
        pendingOnTimeStories,
        totalHoursConsumedOnStories,
        totalHoursConsumedOnBugs,
        totalHoursPlanned,
        totalPendingStories,
        totalInProgressStories,
        totalCompletedStories,
        totalHoursPlannedOnBugs,
    } = item

    const theme = useTheme()
    let user = localStorage.getItem('user')
    let [bugsLabel, setBugsLabel] = useState(0 + '%')
    const [menuData, setMenuData] = useState({
        open: false,
        anchorEl: null,
    })
    let [totalHoursLabel, setTotalHoursLabel] = useState(0 + '%')
    let navigate = useNavigate()
    user = JSON.parse(user)
    let [sendInvite, setSendInvite] = useState({ open: false, data: '' })
    let { currency } = user
    // const processbarValue = work_progress_percentage > 100 ? 100 : work_progress_percentage
    // const rightPos = 100 - processbarValue;

    const tooltipProps = {
        followCursor: true,
        arrow: false,
        slotProps: {
            tooltip: {
                sx: {
                    p: 0,
                    borderRadius: '8px',
                    backgroundColor: 'common.white',
                },
            },
        },
        title: (
            <Box
                borderRadius="8px"
                border="1px solid"
                borderColor="teritiary.teritiary22"
                pb="6px"
                pt="6px"
            >
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary1"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        Completed
                    </Typography>
                </Box>
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary17"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        In Progress
                    </Typography>
                </Box>
                <Box p="8px 16px" display="flex" alignItems="center">
                    <Box
                        mr="10px"
                        backgroundColor="teritiary.teritiary24"
                        borderRadius="50%"
                        width="10px"
                        height="10px"
                    ></Box>
                    <Typography
                        variant="size2MediumTextText1"
                        lineHeight="16px"
                    >
                        Pending
                    </Typography>
                </Box>
            </Box>
        ),
    }

    const CustomLabel = (props) => {
        return (
            <g>
                <VictoryLabel {...props} />
            </g>
        )
    }

    const bugsPieColor = (val) => {
        if (val < 10) {
            return theme.palette.teritiary.teritiary1
        }

        if (val >= 10 && val <= 15) {
            return theme.palette.teritiary.teritiary17
        }

        if (val > 15) {
            return theme.palette.error.main
        }
    }

    const shortName = (full_name) => {
        let arr = full_name.split(' ')
        let first = arr[0].charAt(0).toUpperCase()
        let last = arr[arr.length - 1].charAt(0).toUpperCase()
        return `${first}${last}`
    }

    const detectDecimalAndLimit = (num) => {
        if (String(num).indexOf('.') == -1) {
            return parseInt(num)
        } else {
            return parseFloat(num.toFixed(2))
        }
    }

    const handleClick = (event) => {
        setMenuData({
            ...menuData,
            open: !menuData.open,
            anchorEl: event.currentTarget,
        })
    }

    const handleClose = () => {
        setMenuData({
            ...menuData,
            open: false,
        })
    }

    return (
        <>
            {console.log(
                inProgressDelayedStories,
                completedDelayedStories,
                pendingDelayedStories,
                inProgressOnTimeStories,
                completedOnTimeStories,
                pendingOnTimeStories,
                totalHoursConsumedOnStories,
                totalHoursConsumedOnBugs,
                totalHoursPlanned,
                totalPendingStories,
                totalInProgressStories,
                totalCompletedStories
            )}
            {jira_completed ? (
                <Paper
                    sx={{
                        p: '24px',
                        mb: '10px',
                        borderRadius: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        mb="12px"
                        display="flex"
                        alignItems="center"
                        position="relative"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                right: '-16px',
                                top: '-17px',
                            }}
                            className="btn-group ad-custom-dropdown dropstart"
                        >
                            <button
                                type="button"
                                className="btn ad-dropdown-menu dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <span className="addp-menu-icon"></span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                {/* <li><button className="dropdown-item" type="button" onClick={(e) => viewTeamDetails(item)}>View Profile</button></li> */}
                                <li>
                                    <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={(e) => viewTeamDetails(item)}
                                    >
                                        View Details
                                    </button>
                                </li>

                                <li>
                                    <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={(e) => openEditTeamModal(item)}
                                    >
                                        Edit
                                    </button>
                                </li>

                                <li>
                                    <button
                                        className="dropdown-item"
                                        type="button"
                                        onClick={(e) =>
                                            deleteProjectTeamModal(item)
                                        }
                                    >
                                        Delete
                                    </button>
                                </li>
                                {item.is_sent_invite &&
                                    !user.is_invite_team_member && (
                                        <li>
                                            <button
                                                className="dropdown-item"
                                                type="button"
                                                onClick={(e) => {
                                                    setSendInvite({
                                                        data: item,
                                                        open: true,
                                                    })
                                                }}
                                            >
                                                Invite
                                            </button>
                                        </li>
                                    )}
                            </ul>
                        </Box>
                        {/* <ClickAwayListener disableReactTree onClickAway={handleClose}>
                            <Box position="relative" sx={{position:"absolute",right:"-16px",top:"-17px"}}>

                                <IconButton onClick={handleClick} sx={{ height: "24px", width: "24px" }}>
                                    <SvgIcon
                                        component={MenuIcon}
                                        inheritViewBox
                                    />
                                </IconButton>

                                <Menu
                                    anchorEl={menuData.anchorEl}
                                    open={menuData.open}
                                    onClose={handleClose}
                                    anchorReference="none"
                                    sx={{
                                        position: "absolute",
                                        width: "198px",
                                        top: "40px",
                                        left: "-163px"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                    }}
                                    slotProps={{
                                        root: {
                                            sx: {
                                                "& .MuiMenu-list": {
                                                    p: 0
                                                },
                                                "& .MuiMenuItem-root": {
                                                    "&:hover": {
                                                        borderRadius: "8px",
                                                        backgroundColor: "primary.main",
                                                        "&>.MuiListItemIcon-root": {
                                                            "&>.MuiSvgIcon-root": {
                                                                color: "common.white"
                                                            },
                                                        },
                                                        "&>.MuiListItemText-root": {
                                                            "&>.MuiTypography-root": {
                                                                color: "common.white"
                                                            },
                                                        },
                                                        "&>.MuiBox-root": {
                                                            "&>.MuiListItemText-root": {
                                                                "&>.MuiTypography-root": {
                                                                    color: "common.white"
                                                                },
                                                            }
                                                        },
                                                        "&>.MuiSvgIcon-root": {
                                                            color: "common.white"
                                                        },
                                                        "&>.MuiTypography-root": {
                                                            color: "common.white"
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        paper: {
                                            sx: {
                                                width: "100%",
                                                height: "auto",
                                                maxHeight: "max-content",
                                                overflow: "visible",
                                                borderRadius: "8px",
                                                border: "1px solid",
                                                borderColor: "primary.main"
                                            }
                                        }
                                    }}
                                    disableScrollLock
                                    disablePortal
                                    hideBackdrop
                                    disableEnforceFocus
                                >
                                    <MenuItem sx={{ p: "7px 14px" }} onClick={()=>{
                                        viewTeamDetails(item);}}>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: "size3MediumTextText1",
                                                align: "left"
                                            }}
                                        >View Details</ListItemText>
                                    </MenuItem>
                                    <MenuItem sx={{ p: "7px 14px" }} onClick={()=>{
                                        openEditTeamModal(item);
                                        console.log(item);}}>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: "size3MediumTextText1",
                                                align: "left"
                                            }}
                                        >Edit</ListItemText>
                                    </MenuItem>
                                    <MenuItem sx={{ p: "7px 14px 13px 14px", borderBottomRightRadius: "8px", borderBottomLeftRadius: "8px" }} onClick={()=>{deleteProjectTeamModal(item)}}>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: "size3MediumTextText1",
                                                align: "left"
                                            }}
                                        >Delete</ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </ClickAwayListener> */}
                        <Box p="10px 16px 10px 10px" width="20%">
                            <Stack direction="row">
                                {avatar ? (
                                    <Avatar
                                        alt={short_name}
                                        src={avatar}
                                        slotProps={{
                                            img: {
                                                sx: {
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    bgcolor:
                                                        theme.palette.common
                                                            .white,
                                                },
                                            },
                                        }}
                                        sx={{
                                            width: 53,
                                            height: 53,
                                            mr: '24px',
                                            border: `1px solid ${color_code}` /*,boxShadow:`0px 0px 5px 0.5px ${theme.palette.text.text2}`*/,
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        sx={{
                                            width: 53,
                                            height: 53,
                                            mr: '24px',
                                            border: `1px solid ${color_code}`,
                                            display: 'flex',
                                            color: 'white',
                                            backgroundColor: `${color_code}`,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {shortName(full_name)}
                                    </Avatar>
                                )}
                                <Stack>
                                    <Typography
                                        fontSize="17px"
                                        mb="6px"
                                        lineHeight="23px"
                                        variant="size4MediumTextText5"
                                    >
                                        {full_name.split(' ')[0]}{' '}
                                        {full_name.split(' ')[1][0]}.
                                    </Typography>
                                    <Typography
                                        lineHeight="16px"
                                        mb="6px"
                                        variant="size2RegularTerTer8"
                                    >
                                        {role || '-'}
                                    </Typography>
                                    <div>
                                        <span className="ad-exp-custom">
                                            {experience || '-'}
                                        </span>
                                    </div>
                                </Stack>
                            </Stack>
                        </Box>
                        {isAddedOnJira ? (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    pr="16px"
                                >
                                    <Box
                                        p="10px 16px 10px 32px"
                                        borderLeft="1px solid"
                                        borderColor="teritiary.teritiary6"
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        {/* <Stack direction="row"> */}
                                        <Typography
                                            mb="14px"
                                            lineHeight="23px"
                                            variant="size5SemiboldTextText5"
                                        >
                                            {detectDecimalAndLimit(
                                                totalCompletedStories
                                            )}
                                            /
                                            {detectDecimalAndLimit(
                                                totalCompletedStories +
                                                    totalInProgressStories +
                                                    totalPendingStories
                                            )}
                                        </Typography>
                                        <Typography
                                            lineHeight="16px"
                                            variant="size2MediumTextText2"
                                        >
                                            Stories completed
                                        </Typography>
                                        {/* </Stack> */}
                                    </Box>
                                    <Tooltip {...tooltipProps}>
                                        {console.log(
                                            'totalCompletedStories',
                                            totalHoursConsumedOnStories,
                                            totalHoursConsumedOnBugs,
                                            totalHoursPlanned
                                        )}
                                        <Box width="100px" height="100px">
                                            <VictoryPie
                                                padAngle={0}
                                                innerRadius={32.5}
                                                radius={50}
                                                height={100}
                                                width={100}
                                                colorScale={[
                                                    theme.palette.teritiary
                                                        .teritiary1,
                                                    theme.palette.teritiary
                                                        .teritiary17,
                                                    theme.palette.teritiary
                                                        .teritiary24,
                                                    theme.palette.teritiary
                                                        .teritiary21,
                                                ]}
                                                labels={(d) => d.y}
                                                labelRadius={({
                                                    innerRadius,
                                                    datum,
                                                }) => {
                                                    return innerRadius + 5
                                                }}
                                                style={{
                                                    labels: {
                                                        fill: theme.palette
                                                            .common.white,
                                                        fontSize: 10,
                                                        fontWeight: 600,
                                                    },
                                                }}
                                                data={[
                                                    {
                                                        x: '',
                                                        y: totalCompletedStories,
                                                        label:
                                                            totalCompletedStories ||
                                                            ' ',
                                                    },
                                                    {
                                                        x: '',
                                                        y: totalInProgressStories,
                                                        label:
                                                            totalInProgressStories ||
                                                            ' ',
                                                    },
                                                    {
                                                        x: '',
                                                        y: totalPendingStories,
                                                        label:
                                                            totalPendingStories ||
                                                            ' ',
                                                    },
                                                    {
                                                        x: '',
                                                        y:
                                                            !totalPendingStories &&
                                                            !totalInProgressStories &&
                                                            !totalCompletedStories
                                                                ? 100
                                                                : 0,
                                                        label: ' ',
                                                    },
                                                    // { x: "Total Hours", y: 5, label: " " },
                                                ]}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    pr="16px"
                                >
                                    <Box
                                        p="10px 16px 10px 32px"
                                        borderLeft="1px solid"
                                        borderColor="teritiary.teritiary6"
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        {/* <Stack direction="row"> */}
                                        <Typography
                                            mb="14px"
                                            lineHeight="23px"
                                            variant="size5SemiboldTextText5"
                                        >
                                            {detectDecimalAndLimit(
                                                totalHoursConsumedOnStories
                                            )
                                                ? detectDecimalAndLimit(
                                                      totalHoursConsumedOnStories
                                                  )
                                                : 0}
                                            /
                                            {detectDecimalAndLimit(
                                                totalHoursPlanned
                                            )
                                                ? detectDecimalAndLimit(
                                                      totalHoursPlanned
                                                  )
                                                : 0}
                                        </Typography>
                                        <Typography
                                            lineHeight="16px"
                                            variant="size2MediumTextText2"
                                        >
                                            Hours Consumed
                                        </Typography>
                                        {/* </Stack> */}
                                    </Box>
                                    {/* <Tooltip {...tooltipProps}> */}
                                    <Box width="100px" height="100px">
                                        <svg viewBox="0 0 100 100">
                                            <VictoryPie
                                                // padAngle={0}
                                                standalone={false}
                                                innerRadius={32.5}
                                                radius={50}
                                                height={100}
                                                width={100}
                                                colorScale={[
                                                    theme.palette.teritiary
                                                        .teritiary24,
                                                    theme.palette.teritiary
                                                        .teritiary21,
                                                ]}
                                                // labelRadius={-4}
                                                // labelComponent={<VictoryLabel
                                                //     textAnchor="middle"
                                                //     style={{ fill: theme.palette.text.text1, fontSize: 14, fontWeight: 600 }}
                                                //     x={50}
                                                //     y={55}
                                                //     text={({ datum }) => datum.label}
                                                // />}
                                                labels={({ datum }) => {
                                                    if (
                                                        datum.dataType == 'main'
                                                    ) {
                                                        console.log(
                                                            'datum',
                                                            datum
                                                        )
                                                        setTotalHoursLabel(
                                                            detectDecimalAndLimit(
                                                                datum.y
                                                            ) + '%'
                                                        )
                                                    }
                                                }}
                                                style={{
                                                    labels: {
                                                        fill: theme.palette.text
                                                            .text1,
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    },
                                                }}
                                                data={[
                                                    {
                                                        x: '',
                                                        y:
                                                            totalHoursConsumedOnStories &&
                                                            totalHoursPlanned
                                                                ? detectDecimalAndLimit(
                                                                      (totalHoursConsumedOnStories /
                                                                          totalHoursPlanned) *
                                                                          100
                                                                  )
                                                                : 0,
                                                        label: '',
                                                        dataType: 'main',
                                                    },
                                                    {
                                                        x: '',
                                                        y:
                                                            totalHoursConsumedOnStories &&
                                                            totalHoursPlanned
                                                                ? detectDecimalAndLimit(
                                                                      100 -
                                                                          (totalHoursConsumedOnStories /
                                                                              totalHoursPlanned) *
                                                                              100
                                                                  )
                                                                : 100,
                                                        label: '',
                                                        dataType: 'rest',
                                                    },
                                                ]}
                                            />
                                            <VictoryLabel
                                                textAnchor="middle"
                                                style={{
                                                    fill: theme.palette.text
                                                        .text1,
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                }}
                                                x={50}
                                                y={50}
                                                text={totalHoursLabel}
                                            />
                                        </svg>
                                    </Box>
                                    {/* </Tooltip> */}
                                </Box>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    pr="16px"
                                >
                                    <Box
                                        p="10px 16px 10px 32px"
                                        borderLeft="1px solid"
                                        borderColor="teritiary.teritiary6"
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        {/* <Stack direction="row"> */}
                                        <Typography
                                            mb="14px"
                                            lineHeight="23px"
                                            variant="size5SemiboldTextText5"
                                        >
                                            {totalHoursConsumedOnBugs
                                                ? detectDecimalAndLimit(
                                                      totalHoursConsumedOnBugs
                                                  )
                                                : 0}
                                            hrs
                                        </Typography>
                                        <Typography
                                            lineHeight="16px"
                                            variant="size2MediumTextText2"
                                        >
                                            Consumed on Bugs
                                        </Typography>
                                        {/* </Stack> */}
                                    </Box>
                                    {/* <Tooltip {...tooltipProps}> */}
                                    <Box width="100px" height="100px">
                                        <svg viewBox="0 0 100 100">
                                            <VictoryPie
                                                // padAngle={0}
                                                standalone={false}
                                                innerRadius={32.5}
                                                radius={50}
                                                height={100}
                                                width={100}
                                                colorScale={[
                                                    bugsPieColor(
                                                        (totalHoursConsumedOnBugs /
                                                            totalHoursPlanned) *
                                                            100
                                                    ),
                                                    theme.palette.teritiary
                                                        .teritiary21,
                                                ]}
                                                // labelRadius={-5}
                                                // labelComponent={<VictoryLabel
                                                //     textAnchor="middle"
                                                //     style={{ fill: theme.palette.text.text1, fontSize: 14, fontWeight: 600  }}
                                                //     x={50}
                                                //     y={55}
                                                //     text={({datum})=>datum.label}
                                                // />}
                                                labels={({ datum }) => {
                                                    if (
                                                        datum.dataType == 'main'
                                                    ) {
                                                        console.log(
                                                            'datum',
                                                            datum
                                                        )
                                                        setBugsLabel(
                                                            detectDecimalAndLimit(
                                                                datum.y
                                                            ) + '%'
                                                        )
                                                    }
                                                }}
                                                style={{
                                                    labels: {
                                                        fill: theme.palette.text
                                                            .text1,
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    },
                                                }}
                                                data={[
                                                    {
                                                        x: '',
                                                        y:
                                                            totalHoursConsumedOnBugs &&
                                                            totalHoursPlannedOnBugs
                                                                ? detectDecimalAndLimit(
                                                                      (totalHoursConsumedOnBugs /
                                                                          totalHoursPlanned) *
                                                                          100
                                                                  )
                                                                : 0,
                                                        label: '',
                                                        dataType: 'main',
                                                    },
                                                    {
                                                        x: '',
                                                        y:
                                                            totalHoursConsumedOnBugs &&
                                                            totalHoursPlannedOnBugs
                                                                ? detectDecimalAndLimit(
                                                                      100 -
                                                                          (totalHoursConsumedOnBugs /
                                                                              totalHoursPlanned) *
                                                                              100
                                                                  )
                                                                : 100,
                                                        label: '',
                                                        dataType: 'rest',
                                                    },
                                                ]}
                                            />
                                            <VictoryLabel
                                                textAnchor="middle"
                                                style={{
                                                    fill: theme.palette.text
                                                        .text1,
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                }}
                                                x={50}
                                                y={50}
                                                text={bugsLabel}
                                            />
                                        </svg>
                                    </Box>
                                    {/* </Tooltip> */}
                                </Box>
                            </>
                        ) : (
                            <Typography
                                ml="auto"
                                mr="auto"
                                alignSelf="stretch"
                                display="flex"
                                alignItems="center"
                                pl="15px"
                                borderLeft="1px solid"
                                borderColor="teritiary.teritiary6"
                                variant="size2_5MediumGradient1"
                            >
                                No data found for this user on{' '}
                                {toolType == 'jira' ? 'Jira' : 'Asana'} for the
                                selected{' '}
                                {toolType == 'jira' ? 'sprint' : 'project'}. Add
                                User / Create ticket on{' '}
                                {toolType == 'jira' ? 'Jira' : 'Asana'} to start
                                tracking
                            </Typography>
                        )}
                    </Box>
                    {isAddedOnJira && (
                        <Stack spacing="16px">
                            <Typography
                                lineHeight="21px"
                                variant="size4MediumTextText5"
                            >
                                Stories Overview
                            </Typography>
                            <Stack direction="row" spacing="29px">
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        mr="8px"
                                        borderRadius="50%"
                                        height="8px"
                                        width="8px"
                                        backgroundColor="teritiary.teritiary1"
                                    ></Box>
                                    <Typography
                                        lineHeight="16px"
                                        variant="size0_8emRegularTextText4"
                                    >
                                        <Box display="flex">
                                            <Box mr="5px">
                                                {completedOnTimeStories || 0}
                                            </Box>
                                            Completed/On Time
                                        </Box>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        mr="8px"
                                        borderRadius="50%"
                                        height="8px"
                                        width="8px"
                                        backgroundColor="teritiary.teritiary17"
                                    ></Box>
                                    <Typography
                                        lineHeight="16px"
                                        variant="size0_8emRegularTextText4"
                                    >
                                        <Box display="flex">
                                            <Box mr="5px">
                                                {completedDelayedStories || 0}
                                            </Box>{' '}
                                            Completed/Delayed
                                        </Box>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        mr="8px"
                                        borderRadius="50%"
                                        height="8px"
                                        width="8px"
                                        backgroundColor="teritiary.teritiary17"
                                    ></Box>
                                    <Typography
                                        lineHeight="16px"
                                        variant="size0_8emRegularTextText4"
                                    >
                                        <Box display="flex">
                                            <Box mr="5px">
                                                {inProgressDelayedStories || 0}
                                            </Box>{' '}
                                            In Progress/Delayed
                                        </Box>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        mr="8px"
                                        borderRadius="50%"
                                        height="8px"
                                        width="8px"
                                        backgroundColor="teritiary.teritiary1"
                                    ></Box>
                                    <Typography
                                        lineHeight="16px"
                                        variant="size0_8emRegularTextText4"
                                    >
                                        <Box display="flex">
                                            <Box mr="5px">
                                                {inProgressOnTimeStories || 0}
                                            </Box>{' '}
                                            In Progress/On Time
                                        </Box>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        mr="8px"
                                        borderRadius="50%"
                                        height="8px"
                                        width="8px"
                                        backgroundColor="teritiary.teritiary1"
                                    ></Box>
                                    <Typography
                                        lineHeight="16px"
                                        variant="size0_8emRegularTextText4"
                                    >
                                        <Box display="flex">
                                            <Box mr="5px">
                                                {pendingOnTimeStories || 0}
                                            </Box>{' '}
                                            Pending/On Time
                                        </Box>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <Box
                                        mr="8px"
                                        borderRadius="50%"
                                        height="8px"
                                        width="8px"
                                        backgroundColor="teritiary.teritiary17"
                                    ></Box>
                                    <Typography
                                        lineHeight="16px"
                                        variant="size0_8emRegularTextText4"
                                    >
                                        <Box display="flex">
                                            <Box mr="5px">
                                                {pendingDelayedStories || 0}
                                            </Box>{' '}
                                            Pending/Delayed
                                        </Box>
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Paper>
            ) : (
                <NoDataProjectTeam
                    addTeamCallback={() => {
                        navigate('/dashboard/projects')
                    }}
                />
            )}
            {/*<div className="adts-box d-flex align-items-center justify-content-between mb-10 project-dtl-cols">
                {weteams_talent ? 
                    <div className="weteams-tag">
                        <img src={weteamsbadge} alt="weteams" /> Weteams
                    </div> : ""    
                }
                <div className="col1">
                    <div className="d-flex align-items-center adts-col-info w-100">
                        <div className="dash-hc-img ad-sm-hc" style={{ background: (color_code || "#A19DC4") }}>
                            {avatar ? <img src={avatar} alt={full_name} /> : <span className="talent-short-name">{short_name}</span>}
                        </div>
                        <div className="dash-hc-info">
                            <div className="dash-md-title dash-black-text fw-600 dash-font-0-9em">{first_name} </div>
                            <div className="dash-md-title dash-font-0-8em text-wrap">{role}</div>
                        </div>
                    </div>
                </div>
                {/* {jira_completed ? */}
            {/*<div className="col2">
                        <div className="dash-md-title dash-font-0-8em text-wrap">Used hours</div>
                        <div className="d-flex align-items-center mt-1-5">}
                            {/* <div className="allocation-label text-nowrap">{reserved_hours} hrs</div> */}
            {/*<span className="ad-tooltip ad-tooltip-center mnw-42">
                                <div className="allocation-label text-nowrap">{used_hours || 0} hrs</div>
                                <div className="ad-tooltip-box">
                                <p className="text-white text-nowrap">Used Hours</p>
                                </div>
                            </span>
                            <div className="progress ad-allocation-progress position-relative ms-2">
                                <div className="progress-bar" role="progressbar" style={{ width: `${work_progress_percentage}` + "%", background: percentage_bar_color_code }}
                                    aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                {/* <div className="allocation-label white-text ad-font-0-8em position-absolute font-10 fw-500" style={{right:"calc(20% + 5px)", top: "0px"}}>80%</div> */}
            {/*} {processbarValue && processbarValue > 30 ?
                                    <div className="allocation-label white-text ad-font-0-8em position-absolute font-10 fw-600" style={{ right: `calc(${rightPos}% + 10px)`, top: "0px" }}>{work_progress_percentage ? work_progress_percentage : "0"}%</div>
                                    :
                                    <div className="allocation-label black-text ad-font-0-8em position-absolute font-10 fw-600" style={{ left: `calc(${processbarValue}% + 10px)`, top: "0px" }}>{work_progress_percentage ? work_progress_percentage : "0"}%</div>
                                }
                            </div>
                            <span className="ad-tooltip ad-tooltip-center mnw-42">
                                <div className="allocation-label text-nowrap">{reserved_hours || 0} hrs</div>
                                <div className="ad-tooltip-box">
                                <p className="text-white text-nowrap">Reserved Hours</p>
                                </div>
                            </span>
                        </div>
                    </div>
                    {/* : ""}*/}
            {/*<div className="col2">
                    <div className="dash-md-title dash-font-0-8em text-wrap">Internal monthly cost</div>
                    <div className="dash-md-title ad-gray-text fw-600 dash-font-0-9em mt-1">
                        {/* {clientCurrency ?  */}
            {/*<SassCurrency pCurrency={clientCurrency ? currency : project_currency} />{clientCurrency ? my_currency_internal_monthly_cost : internal_monthly_cost} 
                            {/* <SassCurrency pCurrency={project_currency} />${my_currency_internal_monthly_cost}
                        } */}
            {/*</div>
                </div>
                <div className="col2">
                    <div className="dash-md-title dash-font-0-8em text-wrap">Per hour cost</div>
                    <div className="dash-md-title ad-gray-text fw-600 dash-font-0-9em mt-1">
                        <SassCurrency pCurrency={clientCurrency ? currency : project_currency} />{clientCurrency ? my_currency_client_hourly_rate : client_hourly_rate} 
                    </div>
                </div>
                <div className="col2">
                    <div className="dash-md-title dash-font-0-8em text-wrap">Client billing amount</div>
                    <div className="dash-md-title ad-gray-text fw-600 dash-font-0-9em mt-1">
                        <SassCurrency pCurrency={clientCurrency ? currency : project_currency} />{clientCurrency ? my_currency_client_billing_amount : client_billing_amount} 
                    </div>
                </div>
                <div className="col3 text-end">
                    <div className="btn-group ad-custom-dropdown dropstart">
                        <button type="button" className="btn ad-dropdown-menu dropdown-toggle"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <span className="addp-menu-icon"></span>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end">
                            <li><button className="dropdown-item" type="button"
                                onClick={()=>openEditTeamModal(item)}>Edit</button></li>
                            <li><button className="dropdown-item" type="button"
                                onClick={() => deleteProjectTeamModal(item)}>Delete</button></li>
                        </ul>
                    </div>
                </div>
            </div>*/}

            {/* } */}
            <AgencyModal open={sendInvite.open}>
                <SendTeamMemberAccountInvite
                    key={sendInvite.data.name}
                    sendInvite={sendInvite}
                    setModalOpen={() => {
                        setSendInvite({ ...sendInvite, open: false })
                    }}
                />
            </AgencyModal>
        </>
    )
}

export default ProjectTeamMembers
