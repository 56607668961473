import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Mobileapps = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/MobileApp.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    Mobile Apps
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Get High-end mobile app development experts
                                    at Weteams platform
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Weteams mobile app developers can help improve
                                brand engagement by developing spontaneous,
                                speedy, and creative native and cross-platform
                                mobile apps{' '}
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Presentations and demos
                                            </div>
                                            <p>
                                                Since everything can be accessed
                                                through mobile applications,
                                                your staff may communicate
                                                product descriptions, demos, and
                                                other information with clients
                                                while they are on the road.
                                                <br />
                                                By now, you should be aware of
                                                the value and significance a
                                                mobile application has for a
                                                business, its personnel, and its
                                                clients. A single software is
                                                now accomplish a variety of
                                                tasks and serve a variety of
                                                functions. In general, a
                                                business can employ mobile
                                                applications to streamline,
                                                automate, and improve routine
                                                activities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Time And Assets Tracking
                                            </div>
                                            <p>
                                                The time tracking mobile app
                                                enables field service workers to
                                                keep track of their time while
                                                traveling and visiting clients.
                                                This makes payroll and customer
                                                billing simpler.
                                            </p>
                                            <p>
                                                In addition to this, businesses
                                                are now choosing to design
                                                mobile apps for a variety of
                                                operational use cases, including
                                                inventory management, internal
                                                security personnel management,
                                                facilities management, and much
                                                more. All they have to do is
                                                decide which tasks or
                                                organizational functions they
                                                wish to outsource to mobile
                                                applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Scheduling And Approvals
                                            </div>
                                            <p>
                                                Numerous events are scheduled
                                                and approved using mobile
                                                applications. With the use of
                                                mobile apps, scheduling and
                                                approval of leaves, time off,
                                                late arrivals, and several other
                                                employee-related actions may be
                                                efficiently managed. The
                                                managers have the freedom to
                                                submit approvals or disapprovals
                                                for the same even when on the
                                                go, while it is simple and
                                                rather convenient for the
                                                employees to make requests for
                                                time off, comp time, or leaves
                                                at any time using these mobile
                                                apps.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Mobileapps
