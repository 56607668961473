import LeftCard from '../../Components/Auth/LeftCard'
import { useLocation, useNavigate } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BtnSpinner from '../../Components/Spinners/BtnSpinner'
import info from '../../assets/img/info.svg'
import { checkPropertyChange } from 'json-schema'
import apiCall from '../../Services/ApiInstance'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
const baseUrl = process.env.REACT_APP_base_URL

const VerifyOtp = () => {
    const { state } = useLocation()
    const { email } = state

    const handleBackButton = (event) => {
        console.log('preventDefault')
        event.preventDefault()
        // You can perform custom actions or show a message here
    }

    // link navidate
    const navigate = useNavigate()

    const [inputOtp, setInputOtp] = useState('')
    const [OTPTimer, setOTPTimer] = useState(false)
    const [disable, setDisable] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    //Errors
    const [error, setError] = useState()

    const [minutes, setMinutes] = useState(1)
    const [seconds, setSeconds] = useState(30)
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    setOTPTimer(true)
                } else {
                    setMinutes(minutes - 1)
                    setSeconds(59)
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval)
        }
    })

    const reset = () => {
        setOTPTimer(false)
        setMinutes(1)
        setSeconds(30)
    }
    // //check otp correct or not and update status
    const verifyOtp = () => {
        // navigate to test route and UI
        // localStorage.setItem('token', "172|F7CSXEA0SYPcE6DsyQhKHy5LmxYpw5O4yk8MOacd");
        // localStorage.setItem('user', "abc@gmail.com");
        // navigate('/onboarding-signup', { replace: true });

        if (inputOtp) {
            try {
                setIsLoading(true) // stop loading spinner
                apiCall
                    .post(`${baseUrl}/api/v1/verify-otp`, {
                        email: email,
                        otp: inputOtp,
                    })
                    .then((response) => {
                        let result = response.data
                        if (result.status) {
                            toast.success(result.message)
                            localStorage.setItem(
                                'token',
                                JSON.stringify(result.data.token)
                            )
                            localStorage.setItem(
                                'user',
                                JSON.stringify(result.data.user)
                            )
                            navigate('/onboarding-signup', { replace: true })
                        }
                        setIsLoading(false) // stop loading spinner
                        setError(result.message)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } catch (error) {
                console.log(error)
            }
        } else {
            setError('please enter otp.')
        }
    }

    // otp resend
    const resendOtp = () => {
        try {
            setDisable(true) // disable resend btn
            apiCall
                .post(`${baseUrl}/api/v1/resend-otp`, { email: email })
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        reset() // for timer start
                        setError('')
                        setInputOtp('')
                        toast.success(result.message)
                        setDisable(false) // able resend btn
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.addEventListener('popstate', handleBackButton)
        console.log('ddd')
        return () => {
            window.removeEventListener('popstate', handleBackButton)
        }
    }, [])

    return (
        <>
            {/* <ToastContainer  limit={1} hideProgressBar={true}/> */}
            <div className="common-form-wrap d-flex">
                {/* left side Components */}
                <LeftCard />
                <div className="cf-right">
                    <div className="cf-position">
                        <div className="cf-max-width">
                            <div className="main-heading">
                                <span className="wlc-gradient">
                                    Verify your email address
                                </span>
                            </div>
                            <p className="mt-15">
                                We have sent an email to {email}
                            </p>
                            <Box display="inline-block" mt="1.5rem">
                                <div className="form-group mb-0">
                                    <label>
                                        Enter the code below to confirm your
                                        email address
                                    </label>

                                    <Paper
                                        elevation={0}
                                        variant="customOtpInputs"
                                    >
                                        <OtpInput
                                            value={inputOtp}
                                            onChange={(e) => setInputOtp(e)}
                                            numInputs={4}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => (
                                                <input {...props} />
                                            )}
                                            inputStyle={
                                                error ? 'inputStyle error' : ''
                                            }
                                            inputType="number"
                                            skipDefaultStyles
                                            containerStyle={{
                                                justifyContent: 'space-between',
                                            }}
                                            // isInputNum={true}
                                        />
                                    </Paper>

                                    {error ? (
                                        <label className="error">
                                            <img src={info} alt="info" />{' '}
                                            {error}
                                        </label>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="mt-20">
                                    <div className="link d-flex">
                                        <span>Didn't receive the OTP?</span>
                                        {OTPTimer && (
                                            <button
                                                type="button"
                                                className="theme-text"
                                                onClick={resendOtp}
                                                disabled={disable}
                                            >
                                                Resend
                                            </button>
                                        )}
                                        {minutes === 0 &&
                                        seconds === 0 ? null : (
                                            <>
                                                <label>
                                                    <span className="theme-text resend-disabled">
                                                        &nbsp; Resend{' '}
                                                    </span>
                                                </label>

                                                <label className="ml-auto  otp-timer">
                                                    {minutes}:
                                                    {seconds < 10
                                                        ? `0${seconds}`
                                                        : seconds}
                                                </label>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Box>
                            <div className="mt-30">
                                {isLoading ? (
                                    <BtnSpinner cls="theme-dark-transparent-btn w-100 text-center" />
                                ) : (
                                    <button
                                        onClick={verifyOtp}
                                        className="theme-dark-transparent-btn w-100 text-center"
                                    >
                                        Verify Now
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyOtp
