import Header from '../../Common/Header'
import HomeBanner from '../../Components/Banner/HomeBanner'
import ClientBanner from '../../Components/Banner/ClientBanner'

const TopSection = () => {
    return (
        <>
            <section className="home-top-bg">
                <Header />
                <HomeBanner />
                <ClientBanner />
            </section>
        </>
    )
}

export default TopSection
