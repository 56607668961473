import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import Select from 'react-select'
import { useEffect, useLayoutEffect, useState } from 'react'
import { utilities } from '../../Config/Helper'
import makeAnimated from 'react-select/animated'
import ValidationError from '../Errors/ValidationError'
import { Box, IconButton, Stack, SvgIcon } from '@mui/material'
import { ReactComponent as Cross } from '../../assets/dashboard/img/cross-interview.svg'
import { ReactComponent as Add } from '../../assets/dashboard/img/add.svg'
import { ReactComponent as Minus } from '../../assets/dashboard/img/minus.svg'
import BtnSpinner from '../Spinners/BtnSpinner'
import { useApiService } from '../../Services/ApiInstance'
import { toast } from 'react-toastify'
import AuthUser from '../../Services/AuthUser'
import { ReactComponent as DeleteIcon } from '../../assets/dashboard/img/delete_icon_dynamic.svg'

const validationSchema = yup.object({
    onbench: yup
        .array()
        .of(
            yup.object().shape({
                skills: yup.string().required('This field is required'),
                expertise: yup.string().required('This field is required'),
                talents: yup
                    .number('Value must be a number')
                    .integer('Talents must be a whole number')
                    .positive('Only positive values are allowed')
                    .min(1, 'Enter value greater than 0')
                    .max(99999, 'Value cannot be greater than 99999')
                    .required('This field is required'),
            })
        )
        .min(1, 'At least one slot is required')
        // .max(5, "At most five slots are allowed")
        .test('unique', 'All entries should be unique.', (value) => {
            const newVal = value.map((entry) => {
                return entry.skills + entry.expertise
            })
            return value ? value.length === new Set(newVal)?.size : true
        }),
})

const AddOnBenchResourcesForm = ({ modalclose, formType }) => {
    let user = JSON.parse(localStorage.getItem('user'))

    const [initialValues, setinitialValues] = useState({
        onbench: [
            {
                skills: '',
                expertise: '',
                talents: '',
            },
        ],
    })

    const { postRequest, STORE_ON_BENCH_TALENT, UPDATE_ON_BENCH_TALENT } =
        useApiService()

    const { http, http2 } = AuthUser()
    const [isEdit, setIsEdit] = useState(formType == 'update' ? false : true)
    const [skills, setSkills] = useState([{}])
    const [isLoading, setIsLoading] = useState(false)
    const [expertise, setExpertise] = useState([
        { value: '0-1', label: 'Beginner (0-1 Y)' },
        { value: '1-3', label: 'Intermediate (1-3 Y)' },
        { value: '3-7', label: 'Advanced (3-7 Y)' },
        { value: '7+', label: 'Expert (7+ Y)' },
    ])

    useEffect(() => {
        ;(async () => {
            // let skills = await utilities("skills");
            // setSkills(
            //   skills.map(({ name, _id }) => {
            //     return { label: name, value: _id };
            //   })
            // );

            http2.get(`/roles/list`).then((res) => {
                let result = res.data.data
                setSkills(
                    result.map(({ name, id }) => {
                        return { label: name, value: id }
                    })
                )
            })

            if (formType == 'update') {
                console.log('initial values set', {
                    onbench: user.onbench_talent_resource.map((v) => {
                        return {
                            skills: v.skills.id,
                            expertise: v.expertise,
                            talents: v.no_of_talents,
                        }
                    }),
                })
                console.log(
                    'user?.onbench_talent_resource && user.onbench_talent_resource.length!=0',
                    user?.onbench_talent_resource &&
                        user.onbench_talent_resource.length != 0
                )

                setinitialValues({
                    onbench:
                        user?.onbench_talent_resource &&
                        user?.onbench_talent_resource.length != 0
                            ? user.onbench_talent_resource.map((v) => {
                                  return {
                                      skills: v.skills.id,
                                      expertise: v.expertise,
                                      talents: parseInt(v.no_of_talents),
                                  }
                              })
                            : [
                                  {
                                      skills: '',
                                      expertise: '',
                                      talents: '',
                                  },
                              ],
                })
                if (
                    !(
                        user?.onbench_talent_resource &&
                        user.onbench_talent_resource.length != 0
                    )
                ) {
                    setIsEdit(true)
                }
            }
        })()
    }, [])

    const submitFormCustom = (values) => {
        console.log(values)
        setIsLoading(true)
        let data = new FormData()
        values.onbench.forEach((entry, index) => {
            data.append(
                `onbench_talent[${index}][skill_id]`,
                entry?.skills || ''
            )
            data.append(
                `onbench_talent[${index}][expertise]`,
                entry?.expertise || ''
            )
            data.append(
                `onbench_talent[${index}][no_of_talents]`,
                entry?.talents || ''
            )
        })
        console.log(data)

        let url = ''

        if (formType == 'update') {
            if (
                user?.onbench_talent_resource &&
                user.onbench_talent_resource.length != 0
            ) {
                url = UPDATE_ON_BENCH_TALENT + user.onbench_talent_resource_id
            } else {
                url = STORE_ON_BENCH_TALENT
            }
        } else {
            url = STORE_ON_BENCH_TALENT
        }

        postRequest(url, data).then((res) => {
            try {
                if (res.status == '200') {
                    toast.success(res.data.message, {
                        onOpen: () => {
                            setIsLoading(false)
                            http.get('/me')
                                .then(function (response) {
                                    let result = response.data.data
                                    localStorage.setItem(
                                        'user',
                                        JSON.stringify(result)
                                    )
                                    setIsEdit(false)
                                    if (formType != 'update') {
                                        modalclose()
                                    }
                                })
                                .catch(function (error) {
                                    // handle error
                                    return false
                                })
                        },
                    })
                }
            } catch (error) {
                console.log(error)
                toast.error('Oops something went wrong')
            }
        })
    }

    const handleChange = (e, setFieldValue, field) => {
        const value = e.target.value.replace(/\D/g, '')
        console.log(value)
        setFieldValue(field, value)
    }

    return (
        <>
            {formType != 'update' && (
                <Box
                    sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        pl: '31px',
                        pr: '31px',
                        pt: '16px',
                        pb: '16px',
                        textAlign: 'center',
                        backgroundColor: '#552FBA',
                        color: 'white',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                    }}
                >
                    Map Your On Bench Talent
                </Box>
            )}
            <Box
                sx={{
                    padding: formType == 'update' ? 0 : '17px',
                    mt: formType == 'update' ? '16px' : 0,
                }}
            >
                {console.log('initialValuesinitialValues', initialValues)}
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm, setFieldValue }) => {
                        console.log(values, values)
                        submitFormCustom(values)
                    }}
                >
                    {({
                        values,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting,
                        validateForm,
                        submitForm,
                    }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit}>
                            {formType == 'update' && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        pl: '0px',
                                        pr: '0px',
                                        padding:
                                            formType == 'update' ? 0 : '16px',
                                        ...(formType == 'update' && {
                                            borderTop: '1.5px solid #bfbfbf',
                                            pt: '16px',
                                            pb: '16px',
                                        }),
                                    }}
                                >
                                    {formType == 'update' && (
                                        <>
                                            <div className="sm-title">
                                                Resource Availability
                                            </div>
                                        </>
                                    )}
                                    {formType == 'update' && (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Box
                                                onClick={() => {
                                                    submitForm()
                                                    // submitFormCustom(values);
                                                }}
                                                sx={{ mr: '16px' }}
                                                component="button"
                                                className="dash-theme-btn fw-400 br-20"
                                                type="button"
                                            >
                                                Save
                                            </Box>
                                            <button
                                                onClick={() => {
                                                    setIsEdit(!isEdit)
                                                }}
                                                className="dash-theme-btn fw-400 br-20"
                                                type="button"
                                            >
                                                Edit
                                            </button>
                                        </Box>
                                    )}
                                    {/* {formType != "update" && (
          <Box sx={{ marginLeft: "auto" }}>
            <IconButton
              size="small"
              onClick={() => {
                modalclose();
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <SvgIcon
                sx={{
                  height: "29px",
                  width: "29px",
                  color: "black",
                  pointerEvents: "none",
                }}
                component={Cross}
                inheritViewBox
              />
            </IconButton>
          </Box>
        )} */}
                                </Box>
                            )}
                            <div
                                style={{
                                    margin: 0,
                                    borderBottom:
                                        formType == 'update'
                                            ? '1.5px solid #bfbfbf'
                                            : 0,
                                    paddingBottom: '21px',
                                }}
                                className="custom-form ad-custom-form ad-form-theme2 row"
                            >
                                {console.log('values', values)}
                                <Box
                                    maxHeight={
                                        formType !== 'update'
                                            ? '260px'
                                            : '443px'
                                    }
                                    overflow="auto"
                                    pt={formType == 'update' ? '0px' : '10px'}
                                    pl={formType == 'update' ? '0px' : '16px'}
                                    pr="0px"
                                >
                                    {values.onbench.map((item, index) => (
                                        <Stack
                                            direction="row"
                                            spacing="15px"
                                            sx={{
                                                ...(formType == 'update' && {
                                                    m: '10px 0 0 0',
                                                    pointerEvents: !isEdit
                                                        ? 'none'
                                                        : 'all',
                                                }),
                                                // ...(index == 0 & isEdit && {mr:"40px"}),
                                            }}
                                        >
                                            {/* <div>Isedit<div/> */}
                                            <Box
                                                className="form-group"
                                                flex={1}
                                            >
                                                <div className="position-relative">
                                                    <label className="label-pos">
                                                        Skill*
                                                    </label>
                                                    <Field
                                                        value={
                                                            values.onbench[
                                                                index
                                                            ].skills
                                                        }
                                                        name={`onbench[${index}].skills`}
                                                        as="select"
                                                        className="form-control"
                                                    >
                                                        <option value="">
                                                            Select Skill
                                                        </option>
                                                        {skills &&
                                                            skills.length > 0 &&
                                                            skills.map(
                                                                (item) => (
                                                                    <option
                                                                        key={
                                                                            item.value
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </Field>
                                                </div>
                                                <ValidationError
                                                    name={`onbench[${index}].skills`}
                                                />
                                            </Box>
                                            <Box
                                                className="form-group"
                                                flex={1}
                                            >
                                                <div className="position-relative">
                                                    <label className="label-pos">
                                                        Experience*
                                                    </label>
                                                    <Field
                                                        value={
                                                            values.onbench[
                                                                index
                                                            ].expertise
                                                        }
                                                        name={`onbench[${index}].expertise`}
                                                        className="form-control"
                                                        as="select"
                                                    >
                                                        <option value="">
                                                            Select Experience
                                                        </option>
                                                        {expertise &&
                                                            expertise.length >
                                                                0 &&
                                                            expertise.map(
                                                                (item) => (
                                                                    <option
                                                                        key={
                                                                            item.value
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                    </Field>
                                                </div>
                                                <ValidationError
                                                    name={`onbench[${index}].expertise`}
                                                />
                                            </Box>
                                            <Box
                                                className="form-group"
                                                flex={1}
                                            >
                                                <div className="position-relative">
                                                    <label className="label-pos">
                                                        Number of Talent(s)*
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Number"
                                                        value={
                                                            values.onbench[
                                                                index
                                                            ].talents
                                                        }
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            // e.preventDefault();
                                                            const { value } =
                                                                e.target
                                                            const regex =
                                                                /^\d+$/
                                                            console.log(
                                                                value,
                                                                regex.test(
                                                                    value
                                                                )
                                                            )
                                                            if (
                                                                value == '' ||
                                                                regex.test(
                                                                    value.toString()
                                                                )
                                                            ) {
                                                                setFieldValue(
                                                                    `onbench[${index}].talents`,
                                                                    value
                                                                )
                                                            }
                                                            // e.preventDefault();
                                                        }}
                                                        name={`onbench[${index}].talents`}
                                                    />
                                                </div>
                                                <ValidationError
                                                    name={`onbench[${index}].talents`}
                                                />
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="flex-start"
                                                pt="5px"
                                                alignSelf="stretch"
                                            >
                                                {isEdit &&
                                                    values.onbench.length >
                                                        1 && (
                                                        <>
                                                            <IconButton
                                                                onClick={() => {
                                                                    if (
                                                                        values
                                                                            .onbench
                                                                            .length >
                                                                        1
                                                                    ) {
                                                                        var newArr =
                                                                            values.onbench.map(
                                                                                (
                                                                                    v
                                                                                ) =>
                                                                                    v
                                                                            )
                                                                        newArr.splice(
                                                                            index,
                                                                            1
                                                                        )
                                                                        setFieldValue(
                                                                            'onbench',
                                                                            newArr
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                <SvgIcon
                                                                    sx={{
                                                                        color: 'error.main',
                                                                    }}
                                                                    component={
                                                                        DeleteIcon
                                                                    }
                                                                    inheritViewBox
                                                                />
                                                            </IconButton>
                                                            {/* {index === values.onbench.length - 1 &&
                              values.onbench.length > 1 && (
                                <IconButton
                                  onClick={() => {
                                    if (values.onbench.length > 1) {
                                      var newArr = values.onbench.map((v) => v);
                                      newArr.splice(index, 1);
                                      setFieldValue("onbench", newArr);
                                    }
                                  }}
                                >
                                  <SvgIcon
                                    sx={{ color: "error.main" }}
                                    component={DeleteIcon}
                                    inheritViewBox
                                  />
                                </IconButton>
                              )} */}
                                                        </>
                                                    )}
                                            </Box>
                                        </Stack>
                                    ))}
                                    {isEdit && (
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            {formType == 'update' ? (
                                                <Box
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'onbench',
                                                            values.onbench.concat(
                                                                [
                                                                    {
                                                                        skills: '',
                                                                        expertise:
                                                                            '',
                                                                        talents:
                                                                            '',
                                                                    },
                                                                ]
                                                            )
                                                        )
                                                    }}
                                                    component="button"
                                                    className="dash-theme-btn fw-400 br-20"
                                                    type="button"
                                                >
                                                    Add More
                                                </Box>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="ad-theme-btn ad-sm-btn"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'onbench',
                                                            values.onbench.concat(
                                                                [
                                                                    {
                                                                        skills: '',
                                                                        expertise:
                                                                            '',
                                                                        talents:
                                                                            '',
                                                                    },
                                                                ]
                                                            )
                                                        )
                                                    }}
                                                >
                                                    Add More
                                                </button>
                                            )}
                                        </Box>
                                    )}
                                    <ErrorMessage
                                        name="onbench"
                                        render={(msg) => (
                                            <label
                                                htmlFor="onbench"
                                                className="error"
                                            >
                                                {typeof msg != 'object'
                                                    ? msg
                                                    : ''}
                                            </label>
                                        )}
                                    />
                                </Box>

                                {formType != 'update' && (
                                    // <Box display="flex">
                                    <Stack
                                        direction="row"
                                        spacing="16px"
                                        mt="10px"
                                        display="flex"
                                    >
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="ad-gray-btn ad-sm-btn w-100"
                                                onClick={() => {
                                                    modalclose()
                                                }}
                                            >
                                                Skip
                                            </button>
                                        </div>

                                        <div className="col-6">
                                            {isLoading ? (
                                                <BtnSpinner
                                                    cls="ad-theme-btn w-100"
                                                    loader="true"
                                                />
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="ad-theme-btn ad-sm-btn w-100 d-block"
                                                >
                                                    Save
                                                </button>
                                            )}
                                        </div>
                                    </Stack>
                                    // {/* </Box> */}
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    )
}

export default AddOnBenchResourcesForm
