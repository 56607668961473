import React from 'react'

const DeleteCollabratorModal = ({
    readyfordelete,
    openHideShowDeleteModal,
    setHideShowDeleteModal,
    destroyPeople,
}) => {
    const { id, full_name } = readyfordelete
    return (
        <>
            <div
                className={`modal fade dash-custom-modal collabrator-modal delete-collabrator-modal ${openHideShowDeleteModal ? 'show' : ''}`}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setHideShowDeleteModal(false)
                                }}
                            ></button>
                            <div className="dash-md-title fw-600 text-center pt-5">
                                Do You Want To Remove the {full_name} ?
                            </div>

                            <div className="text-center mt-5 pb-4">
                                <div className="d-inline-flex">
                                    <div>
                                        <button
                                            type="button"
                                            className="dash-theme-transparent-btn dash-font-1-1em mnw-190"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="ms-4">
                                        <button
                                            type="button"
                                            className="dash-red-transparent-btn dash-font-1-1em mnw-190"
                                            onClick={() => destroyPeople(id)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteCollabratorModal
