import exclamationMark from '../../assets/dashboard/img/danger.png'

const Modal = ({
    commonModalTitle,
    commonModalContent,
    commonModalShow,
    commonModalClose,
}) => {
    return (
        <>
            <div
                className={`modal fade dash-custom-modal mxw-550 ${commonModalShow ? 'show' : ''} `}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="dash-modal-check text-center">
                                <img src={exclamationMark} alt="check" />
                            </div>
                            <div className="dash-md-title fw-600 dash-purple-text text-center font-1-6em">
                                {commonModalTitle}
                            </div>
                            <div className="xs-title mt-3 fw-400 text-center lh-1-4em">
                                {commonModalContent}
                            </div>
                            <div className="mt-3 text-center">
                                <button
                                    className="dash-theme-btn br-20 fw-400"
                                    type="button"
                                    onClick={commonModalClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal
