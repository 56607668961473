import { Link } from 'react-router-dom'
import logo from '../../assets/img/black-logo.png'
import musicWellness from '../../assets/dashboard/img/music-wellness.svg'
import intelSky from '../../assets/dashboard/img/intel-sky.svg'
import snatchJobs from '../../assets/dashboard/img/snatchjobs.svg'
import signupBg from '../../assets/img/signup-bg1.jpg'

const LeftCard = ({ step }) => {
    return (
        <>
            {(() => {
                switch (step) {
                    case 1:
                        return (
                            <>
                                <div
                                    className="cf-left bgimg"
                                    style={{
                                        background: `url(${signupBg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'left top',
                                    }}
                                >
                                    <div className="cf-logo">
                                        <Link to="/">
                                            <img src={logo} />
                                        </Link>
                                    </div>
                                    <div className="cd-h-block">
                                        <div className="cf-block">
                                            <div className="cf-white-box">
                                                <div className="d-flex align-items-center">
                                                    <div className="user-img br-50">
                                                        <img
                                                            src={musicWellness}
                                                            alt="music Wellness"
                                                        />
                                                    </div>
                                                    <div className="user-info">
                                                        <div className="sm-title">
                                                            Patrick Deehan
                                                        </div>
                                                        <div className="xs-title mt-1">
                                                            Founder, Music
                                                            Wellness App
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sm-title mt-30 lh-1-3em">
                                                    I've built my mobile app
                                                    team on Weteams and it has
                                                    been a pleasure working with
                                                    them. Weteams Specialist
                                                    that I worked with ensured
                                                    all project sprints are
                                                    delivered smoothly and there
                                                    were no issues with the
                                                    team. The talent on Weteams
                                                    is incredibly hard-working
                                                    and honest. I highly
                                                    recommend Weteams to build
                                                    your technology team.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    case 2:
                        return (
                            <>
                                <div
                                    className="cf-left bgimg"
                                    style={{
                                        background: `url(${signupBg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'left top',
                                    }}
                                >
                                    <div className="cf-logo">
                                        <Link to="/">
                                            <img src={logo} />
                                        </Link>
                                    </div>
                                    <div className="cd-h-block">
                                        <div className="cf-block">
                                            <div className="cf-white-box">
                                                <div className="d-flex align-items-center">
                                                    <div className="user-img br-50">
                                                        <img
                                                            src={intelSky}
                                                            alt="Intel Sky"
                                                        />
                                                    </div>
                                                    <div className="user-info">
                                                        <div className="sm-title">
                                                            Anthony Pearlman
                                                        </div>
                                                        <div className="xs-title mt-1">
                                                            COO, BlueSky Intel
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sm-title mt-30 lh-1-3em">
                                                    We built our digital product
                                                    innovation team with Weteams
                                                    pretty much instantly within
                                                    a week. Being a completely
                                                    remote team we are really
                                                    happy with the outcomes of
                                                    our diversification project.
                                                    Resources are highly
                                                    professional, and quality of
                                                    services are top notch.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    case 3:
                        return (
                            <>
                                <div
                                    className="cf-left bgimg"
                                    style={{
                                        background: `url(${signupBg})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'left top',
                                    }}
                                >
                                    <div className="cf-logo">
                                        <Link to="/">
                                            <img src={logo} />
                                        </Link>
                                    </div>
                                    <div className="cd-h-block">
                                        <div className="cf-block">
                                            <div className="cf-white-box">
                                                <div className="d-flex align-items-center">
                                                    <div className="user-img">
                                                        <img
                                                            src={snatchJobs}
                                                            alt="Snatchjobs"
                                                        />
                                                    </div>
                                                    <div className="user-info">
                                                        <div className="sm-title">
                                                            Sky Fam
                                                        </div>
                                                        <div className="xs-title mt-1">
                                                            Co-founder,
                                                            Snatchjobs
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sm-title mt-30 lh-1-3em">
                                                    The team is highly
                                                    professional and delivered
                                                    quality design and code, so
                                                    we were able to meet our
                                                    deadlines without any
                                                    delays. I am super happy
                                                    with Weteams!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    default:
                        return (
                            <div
                                className="cf-left bgimg"
                                style={{
                                    background: `url(${signupBg})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'left top',
                                }}
                            >
                                <div className="cf-logo">
                                    <Link to="/">
                                        <img src={logo} />
                                    </Link>
                                </div>
                                <div className="cd-h-block">
                                    <div className="cf-block">
                                        <div className="cf-white-box">
                                            <div className="d-flex align-items-center">
                                                <div className="user-img br-50">
                                                    <img
                                                        src={intelSky}
                                                        alt="Intel Sky"
                                                    />
                                                </div>
                                                <div className="user-info">
                                                    <div className="sm-title">
                                                        Anthony Pearlman
                                                    </div>
                                                    <div className="xs-title mt-1">
                                                        COO, BlueSky Intel
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm-title mt-30 lh-1-3em">
                                                We built our digital product
                                                innovation team with Weteams
                                                pretty much instantly within a
                                                week. Being a completely remote
                                                team we are really happy with
                                                the outcomes of our
                                                diversification project.
                                                Resources are highly
                                                professional, and quality of
                                                services are top notch.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                }
            })()}
        </>
    )
}

export default LeftCard
