import React from 'react'
import ViewRateCardDetail from '../../../Dashboard/HireNewTalent/ViewRateCardDetail'

const RegisterViewRateCard = ({ commonModalShow, handleClose }) => {
    return (
        <>
            <div
                className={`modal fade dash-custom-modal rate-card-modal ${commonModalShow ? 'show' : ''} `}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={handleClose}
                            ></button>
                            <div className="dash-md-title fw-600 text-start black-text text-center">
                                Rate Card
                            </div>
                            <ViewRateCardDetail />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterViewRateCard
