import { Field, Formik, Form, FieldArray } from 'formik'
import * as yup from 'yup'
import imgRemove from '../../assets/dashboard/img/remove.svg'
import { useState, useLayoutEffect } from 'react'
import { utilities } from '../../Config/Helper'
import TextField from '../Form/TextField'
import ValidationError from '../Errors/ValidationError'
import AuthUser from '../../Services/AuthUser'
import BtnSpinner from '../Spinners/BtnSpinner'
import { toast, ToastContainer } from 'react-toastify'

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Must be a valid email')
        .required('Email field is required'), // these constraints take precedence
    people_permission_ids: yup
        .array()
        .min(1, 'At least 1 permission is required')
        .required('At least 1 permission is required'),
})

const EditInvitedPeopleModal = ({
    openEditInvitedPeople,
    setOpenEditInvitedPeople,
    setReload,
    editInvitedPeopleItem,
    peoplePermissions,
    checkedPermissions,
    setCheckedPermissions,
}) => {
    const { http } = AuthUser()
    const { email, permissions, id } = editInvitedPeopleItem
    const [isLoading, setIsLoading] = useState(false)

    // State to track the checked status of the "Select All" checkbox
    const [selectAllChecked, setSelectAllChecked] = useState(false)
    // Event handler for the "Select All" checkbox change
    const handleSelectAllChange = (event, f) => {
        const isChecked = event
        setSelectAllChecked(isChecked)
        // Use map to extract the _id property from each object
        const allCheckedPermissionsids = peoplePermissions.map(
            (item) => item._id
        )
        if (isChecked) {
            f.setFieldValue('people_permission_ids', allCheckedPermissionsids)
            setCheckedPermissions(allCheckedPermissionsids)
        } else {
            f.setFieldValue('people_permission_ids', '')
            setCheckedPermissions([])
        }
    }
    // Event handler for when any individual permission checkbox changes
    const handlePermissionChange = (permissionId, isChecked, f) => {
        let checkedPermissionsids = [...checkedPermissions]

        if (checkedPermissionsids.indexOf(permissionId) === -1) {
            checkedPermissionsids.push(permissionId)
        } else {
            checkedPermissionsids = checkedPermissionsids.filter(
                (item) => item !== permissionId
            )
        }
        setCheckedPermissions(checkedPermissionsids)
        f.setFieldValue('people_permission_ids', checkedPermissionsids)
    }
    // Edit Modal Data Update
    const updatePeople = (fields) => {
        try {
            setIsLoading(true)
            http.post(`/people-update/${id}`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        toast.success(result.message)
                        setIsLoading(false)
                        setOpenEditInvitedPeople(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.data.data)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            {/* {console.log(checkedPermissions)} */}
            <div
                className={`modal fade dash-custom-modal collabrator-modal ${
                    openEditInvitedPeople ? 'show' : ''
                }`}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setOpenEditInvitedPeople(false)
                                }}
                            ></button>
                            <div className="dash-md-title fw-600 text-start">
                                Edit People
                            </div>
                            <Formik
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                initialValues={{
                                    email: email || '',
                                    people_permission_ids: permissions || '',
                                }}
                                onSubmit={(values) => {
                                    updatePeople(values)
                                }}
                            >
                                {({ values }) => (
                                    <Form
                                        className="dash-custom-form mt-4"
                                        autoComplete="off"
                                    >
                                        <div className="collabrator-table">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="w-40 pe-4">
                                                            Email Address
                                                        </th>
                                                        <th className="w-60 pe-1">
                                                            Permissions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="pe-4 form-group-mb-0">
                                                            <Field
                                                                type="text"
                                                                name="email"
                                                                placeholder="Email"
                                                                className="form-control"
                                                                readOnly
                                                            />
                                                        </td>
                                                        <td className="text-end">
                                                            {/* <ul className="add-coll-list">
                                        <li>
                                            <div className="custom-checkbox checkbox-right">
                                                <input className="styled-checkbox" id="peoples" type="checkbox" checked="" />
                                                <label htmlFor="peoples"><strong>All Access</strong></label>
                                            </div>
                                        </li> */}
                                                            <ul className="add-coll-list">
                                                                <li>
                                                                    <div className="custom-checkbox checkbox-right">
                                                                        <Field>
                                                                            {({
                                                                                field,
                                                                                form,
                                                                            }) => (
                                                                                <input
                                                                                    className="styled-checkbox"
                                                                                    id="editallAccess"
                                                                                    type="checkbox"
                                                                                    name="editallAccess"
                                                                                    {...field}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleSelectAllChange(
                                                                                            e
                                                                                                .target
                                                                                                .checked,
                                                                                            form
                                                                                        )
                                                                                    }
                                                                                    checked={
                                                                                        checkedPermissions.length ===
                                                                                        peoplePermissions.length
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <label htmlFor="editallAccess">
                                                                            <strong>
                                                                                All
                                                                                Access
                                                                            </strong>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                                {permissions &&
                                                                    peoplePermissions.map(
                                                                        (
                                                                            permissionsitem,
                                                                            key
                                                                        ) => (
                                                                            <>
                                                                                <li
                                                                                    key={
                                                                                        permissionsitem._id
                                                                                    }
                                                                                >
                                                                                    <div className="custom-checkbox checkbox-right">
                                                                                        <Field>
                                                                                            {({
                                                                                                field,
                                                                                                form,
                                                                                            }) => (
                                                                                                <input
                                                                                                    name="people_permission_ids"
                                                                                                    type="checkbox"
                                                                                                    value={
                                                                                                        permissionsitem._id
                                                                                                    }
                                                                                                    id={
                                                                                                        permissionsitem._id
                                                                                                    }
                                                                                                    className="styled-checkbox"
                                                                                                    checked={
                                                                                                        checkedPermissions.includes(
                                                                                                            permissionsitem._id
                                                                                                        )
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        handlePermissionChange(
                                                                                                            permissionsitem._id,
                                                                                                            e
                                                                                                                .target
                                                                                                                .checked,
                                                                                                            form
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </Field>

                                                                                        <label
                                                                                            htmlFor={
                                                                                                permissionsitem._id
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                permissionsitem.name
                                                                                            }
                                                                                        </label>
                                                                                    </div>
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    )}
                                                            </ul>
                                                            <ValidationError
                                                                name={`people_permission_ids`}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="mt-3 mb-3 text-center">
                                                {isLoading ? (
                                                    <BtnSpinner cls="dash-theme-btn br-20" />
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="dash-theme-btn br-20"
                                                    >
                                                        Update
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditInvitedPeopleModal
