import dash_check_icon from '../../../../assets/dashboard/img/check-icon.png'
import { Link } from 'react-router-dom'

const Success = () => {
    return (
        <>
            <div className="stepper-content fade-in">
                <div className="text-center dash-check-icon mt-100">
                    <img src={dash_check_icon} alt="wee" />
                </div>
                <div className="dash-main-heading text-center mt-20 dash-blue-text">
                    Congratulations! Your hiring request has been successfully
                    submitted.
                </div>
                <div className="dash-sub-heading text-center mt-10">
                    Sit back and relax
                    <br />
                    you'll start receiving email notifications as soon as you
                    have any leads.
                </div>
                <div className="mt-40 text-center">
                    <Link
                        to="/dashboard/hire-new-talent"
                        onClick={() => window.location.reload()}
                        className="dash-theme-btn"
                        type="button"
                    >
                        Hire New Talent
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Success
