import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import { Formik } from 'formik'
import SelectCountry from '../Form/SelectCountry'
import SelectState from '../Form/SelectState'
import { useEffect, useLayoutEffect, useState } from 'react'
import * as yup from 'yup'
import AuthUser from '../../Services/AuthUser'
import { utilities } from '../../Config/Helper'
import { ReactComponent as ViewInvoice } from '../../assets/dashboard/img/view-invoice-dynamic.svg'
import { ReactComponent as SendEmail } from '../../assets/dashboard/img/send-email-dynamic.svg'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactComponent as DownArrow } from '../../assets/dashboard/img/down-arrow-2-dynamic.svg'
import { ReactComponent as EditIcon } from '../../assets/dashboard/img/draw-dynamic.svg'
import Modal from '@mui/material/Modal'
import React from 'react'
import { ReactComponent as Cross } from '../../assets/dashboard/img/cross-clear.svg'
import moment from 'moment'

const validationSchemaTeam = yup.object({
    customer_name: yup.string().required('Customer name is required'),
    address: yup.string().required('Address is required'),
    authority_company_country: yup.string().required('Country is required!'),
    authority_company_state: yup.string().required('State is required!'),
})

const HeadingHamburgerMenu = ({
    project_name,
    project_status,
    gst_no,
    project_logo,
    projectCurrency,
    client_name,
    monthly_budget,
    start_date,
    customer_name,
    project_billing_details,
    client_email,
    clientInvitationCallback,
    currency,
    setReload,
}) => {
    const params = useParams()
    const { id } = params
    const [countries, setCountries] = useState([])
    const [editemail, setEditemail] = useState(false)
    const [clientEmail, setClientEmail] = useState('')
    const [item, setItem] = useState(false)
    const [state, setState] = useState({
        openMenu: false,
        anchorEl: null,
    })

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false)
        setEditemail(false)
    }

    const { http2 } = AuthUser()
    const theme = useTheme()

    const validationSchemaEmail = yup.object({
        project_email: yup.string(),
    })

    const initialValues = {
        customer_name: project_billing_details?.customer_name || '',
        address: project_billing_details?.address || '',
        authority_company_country: project_billing_details?.country || '',
        authority_company_state: project_billing_details?.state || '',
        gst_no: gst_no && gst_no != 'null' ? gst_no : '',
    }

    const emailInitial = {
        project_email: project_billing_details?.project_email || '',
    }

    const billingCustomerDetails = (fields) => {
        fields['country'] = parseInt(fields.authority_company_country)
        fields['state'] = parseInt(fields.authority_company_state)
        fields['authority_company_country'] = parseInt(
            fields.authority_company_country
        )
        fields['authority_company_state'] = parseInt(
            fields.authority_company_state
        )
        try {
            http2
                .post(`/add-project-billing-details/${id}`, fields)
                .then((response) => {
                    let result = response.data
                    console.log(result)
                    if (result.success) {
                        setOpen(false)
                        // window.location.reload();
                        setReload(false)
                        toast.success(result.message)
                    }
                    if (result.success === false) {
                        toast.error(result.data)
                    }
                })
                .catch((error) => {
                    let res = error.response.data
                    if (res.status === false) {
                        toast.error(res.message)
                    } else {
                        toast.error('Internal Server Error')
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleEdit = () => {
        setEditemail(true)
        setClientEmail(client_email)
    }

    const handleSave = async () => {
        try {
            const response = await http2.post(`/update-client-email/${id}`, {
                client_email: clientEmail,
            })
            const result = response.data
            if (result.success) {
                setItem(true)
                window.location.reload()

                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.error('Error:', error)
            toast.error('An error occurred while updating the client email.')
        }
        setEditemail(false)
    }

    const shortName = () => {
        if (project_name.includes(' ')) {
            let arr = project_name.split(' ')
            let first = arr[0].charAt(0).toUpperCase()
            let last = arr[arr.length - 1].charAt(0).toUpperCase()
            return `${first}${last}`
        } else {
            let first = project_name.charAt(0).toUpperCase()
            let last = project_name
                .charAt(project_name.length - 1)
                .toUpperCase()
            return `${first}${last}`
        }
    }

    useLayoutEffect(() => {
        ;(async () => {
            let countries = await utilities('countries')
            setCountries(countries)
        })()
    }, [])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        opacity: 1,
        borderRadius: '8px',
    }

    const formatName = (value) => {
        if (!value || !value.trim()) return ''
        return value
            .split(/(\s+)/)
            .map((word) =>
                word.trim()
                    ? word.charAt(0).toUpperCase() + word.slice(1)
                    : word
            )
            .join('')
    }

    return (
        <Box
            position="relative"
            pl="8px"
            display="flex"
            alignItems="center"
            height="33px"
        >
            <Tooltip
                slotProps={{
                    popper: { className: 'tooltipPrimMain' },
                }}
                title="Project Details"
            >
                <IconButton
                    onClick={handleOpen}
                    sx={{ height: '24px', width: '24px' }}
                >
                    <SvgIcon component={DownArrow} inheritViewBox />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
            >
                <Box sx={style}>
                    <Grid container rowSpacing="16px" columnSpacing="16px">
                        <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            item
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            sx={{ mb: 1 }}
                        >
                            <Box mr="auto">
                                <Stack
                                    direction="row"
                                    spacing="24px"
                                    alignItems="center"
                                >
                                    <Avatar
                                        // alt={teamItem?.dashboard?.project_name.split("")[0] || "C"}
                                        src={project_logo}
                                        slotProps={{
                                            img: {
                                                sx: {
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                },
                                            },
                                        }}
                                        sx={{
                                            width: 61,
                                            height: 61,
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            color: theme.palette.primary.main,
                                            backgroundColor:
                                                theme.palette.common.white,
                                        }}
                                    >
                                        {shortName() || ''}
                                    </Avatar>
                                    <Stack alignItems="flex-start">
                                        <Stack
                                            direction="row"
                                            alignItems="flex-start"
                                            position="relative"
                                        >
                                            <Stack alignItems="flex-start">
                                                <Typography
                                                    variant="size7MediumTerTer18"
                                                    sx={{ lineHeight: '33px' }}
                                                >
                                                    {project_name}
                                                </Typography>
                                                {/* <Box component="span" sx={{ ml: 0 }} className={`employment-type-tag ${project_status}-tag`}>{project_status}</Box> */}
                                            </Stack>
                                        </Stack>
                                        <Typography
                                            variant="size4MediumTerTer8"
                                            sx={{
                                                lineHeight: '24px',
                                                mt: '4px',
                                            }}
                                        >
                                            {client_name}
                                        </Typography>
                                        <Box
                                            component="span"
                                            sx={{ ml: 0 }}
                                            className={`employment-type-tag ${project_status}-tag`}
                                        >
                                            {project_status}
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Box>
                            {/* <Typography align="center" ml="auto" mr="auto" variant="size4SemiboldTextText3">{project_name}</Typography> */}
                            <Box>
                                <IconButton
                                    size="small"
                                    onClick={handleClose}
                                    sx={{
                                        color: 'black',
                                        '&:hover': { backgroundColor: 'white' },
                                        marginBottom: '30px',
                                        marginTop: '-40px',
                                    }}
                                >
                                    <SvgIcon
                                        sx={{ height: '29px', width: '29px' }}
                                        component={Cross}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                            <Typography variant="size3RegularTextText3">
                                Name :
                            </Typography>
                            <Typography
                                variant="size3RegularTerTer20"
                                ml="16px"
                            >
                                {customer_name}
                            </Typography>
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                            <Typography variant="size3RegularTextText3">
                                Start Date :
                            </Typography>
                            <Typography
                                variant="size3RegularTerTer20"
                                ml="16px"
                            >
                                {moment(start_date).format('DD-MMM-YYYY') ||
                                    '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                            <Typography variant="size3RegularTextText3">
                                Monthly Budget :
                            </Typography>
                            <Typography
                                variant="size3RegularTerTer20"
                                ml="16px"
                            >
                                {projectCurrency} {monthly_budget}
                            </Typography>
                        </Grid>

                        {client_email.length > 0 ? (
                            <Grid
                                item
                                xs="12"
                                sm="12"
                                md="6"
                                lg="6"
                                xl="6"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    variant="size3RegularTextText3"
                                    noWrap
                                >
                                    Email :
                                </Typography>
                                {!editemail ? (
                                    <>
                                        <Box>
                                            <Typography
                                                variant="size3RegularTerTer20"
                                                ml="16px"
                                                style={{
                                                    maxWidth:
                                                        'calc(22ch + 2px)',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {!item
                                                    ? client_email.length > 22
                                                        ? client_email.substring(
                                                              0,
                                                              22
                                                          ) + '..'
                                                        : client_email
                                                    : clientEmail}
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <Box flex={1}>
                                        <Formik
                                            enableReinitialize={true}
                                            validationSchema={
                                                validationSchemaEmail
                                            }
                                            initialValues={emailInitial}
                                            onSubmit={(
                                                values,
                                                { resetForm, setFieldValue }
                                            ) => {
                                                // billingCustomerDetails(values);
                                            }}
                                        >
                                            {({
                                                values,
                                                setFieldValue,
                                                field,
                                                form,
                                                resetForm,
                                                errors,
                                                handleChange,
                                                handleBlur,
                                                touched,
                                                handleSubmit,
                                            }) => (
                                                <Box
                                                    display="flex"
                                                    flexDirection="row"
                                                    alignItems="center"
                                                >
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        name="project_email"
                                                        value={clientEmail}
                                                        className="customOutlinedTextFieldVariant"
                                                        onChange={(e) => {
                                                            setClientEmail(
                                                                e.target.value
                                                            )
                                                        }}
                                                        onBlur={handleBlur}
                                                        error={
                                                            touched.project_email &&
                                                            Boolean(
                                                                errors.project_email
                                                            )
                                                        }
                                                        helperText={
                                                            touched.project_email &&
                                                            errors.project_email
                                                        }
                                                        InputProps={{
                                                            placholder:
                                                                'Customer name',
                                                            endAdornment: (
                                                                <button
                                                                    className="btn ad-dropdown-btn"
                                                                    type="button"
                                                                    style={{
                                                                        marginLeft:
                                                                            '5px',
                                                                    }}
                                                                    onClick={() =>
                                                                        handleSave()
                                                                    }
                                                                >
                                                                    Save
                                                                </button>
                                                            ),
                                                        }}
                                                        sx={{
                                                            flex: 1,
                                                            marginLeft: '16px',
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Formik>
                                    </Box>
                                )}
                            </Grid>
                        ) : (
                            ''
                        )}

                        {/* <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                                <Typography variant="size3RegularTextText3">Add billing details</Typography>
                            </Grid>
                            <Grid item xs="12" sm="12" md="12" lg="12" xl="12" className="ad-form-theme2">
                                <Formik
                                    enableReinitialize={true}
                                    validationSchema={validationSchemaTeam}
                                    initialValues={initialValues}
                                    onSubmit={(values, { resetForm, setFieldValue }) => {
                                        billingCustomerDetails(values);
                                        
                                        
                                    }}
                                >
                                    {({ values, setFieldValue, field, form, resetForm, errors, handleChange, handleBlur, touched, handleSubmit }) => {

                                        return (
                                            <Grid container rowSpacing="16px" columnSpacing="16px">
                                                <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        name="customer_name"
                                                        className='customOutlinedTextFieldVariant'
                                                        // onChange={handleChange}
                                                        onChange={(e) => setFieldValue('customer_name', formatName(e.target.value))}
                                                        onBlur={handleBlur}
                                                        label="Customer Name"
                                                        value={values.customer_name}
                                                        error={touched.customer_name && Boolean(errors.customer_name)}
                                                        helperText={touched.customer_name && errors.customer_name}
                                                        InputProps={{
                                                            placholder: "Customer Name"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        name="address"
                                                        className='customOutlinedTextFieldVariant'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label="Address"
                                                        value={values.address}
                                                        error={touched.address && Boolean(errors.address)}
                                                        helperText={touched.address && errors.address}
                                                        InputProps={{
                                                            placholder: "Address"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <Box sx={{
                                                        "& select.form-control": {
                                                            borderRadius: "8px",
                                                            fontSize: "14px"
                                                        },
                                                        "& .label-pos": {
                                                            fontWeight: 400
                                                        }
                                                    }} className="position-relative">
                                                        <label className="label-pos">Country*</label>
                                                        <SelectCountry
                                                            placeholder="Select Country"
                                                            name="authority_company_country"
                                                            data={countries}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <Box sx={{
                                                        "& select.form-control": {
                                                            borderRadius: "8px",
                                                            fontSize: "14px"
                                                        },
                                                        "& .label-pos": {
                                                            fontWeight: 400
                                                        }
                                                    }} className="position-relative">
                                                        <label className="label-pos">State*</label>
                                                        <SelectState
                                                            name="authority_company_state"
                                                            placeholder="Select state"
                                                            component={SelectState}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <TextField
                                                        fullWidth
                                                        name="gst_no"
                                                        className='customOutlinedTextFieldVariant'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        label="GST no (optional)"
                                                        value={values.gst_no}
                                                        error={touched.gst_no && Boolean(errors.gst_no)}
                                                        helperText={touched.gst_no && errors.gst_no}
                                                        InputProps={{
                                                            placholder: "GST No"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <Button fullWidth onClick={handleSubmit} variant='customButtonFilled1' autoFocus>
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }}
                                </Formik>
                            </Grid> */}
                    </Grid>
                </Box>
            </Modal>
        </Box>
    )
}

export default HeadingHamburgerMenu
