import Layout from '../../../Layouts/Layout'
import DigitalGetStarted from './DigitalGetStarted'

const Qaautomation = () => {
    return (
        <>
            <Layout>
                <section className="banner-wrap blockchain-banner">
                    <div className="container">
                        <div className="d-flex align-items-center">
                            <div className="banner-right order-2 w-60 text-end">
                                <div className="banner-img-box d-inline-block animate-this">
                                    <img
                                        src={require('../../../assets/img/QAAutomation.jpg')}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="banner-left w-40">
                                <div className="banner-title black-text">
                                    QA Automation
                                </div>
                                <p className="mt-20 mb-40 black-text">
                                    Top-notch QA Automation engineers for the
                                    latest digital technologies
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blockchain-wrap">
                    <div className="container">
                        <div className="blockchain-block">
                            <div className="xs-title gradient-text">
                                <span>Use Cases</span>
                            </div>
                            <div className="main-heading mt-20 mb-20">
                                Experts & Teams that solve your biggest
                                development challenges
                            </div>
                            <p>
                                Our experts provide an end-to-end ecosystem
                                approach, which includes intelligent and
                                automated QA processes, results in higher
                                quality, faster time-to-value, and a better
                                customer experience
                            </p>
                            <div className="row mt-60">
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Reduced Business Expenses
                                            </div>
                                            <p>
                                                When using an automated test
                                                environment, your company will
                                                save money as fewer resources
                                                are spent on testing your
                                                product. The idea is that no
                                                manual testing should be
                                                performed. This can make a
                                                significant difference over the
                                                course of a project.
                                                <br />
                                                Installing and configuring an
                                                automated testing environment,
                                                of course, takes time and
                                                resources. You will also most
                                                likely have to pay for a proper
                                                test automation tool to assist
                                                you in creating a stable test
                                                automation environment.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Higher Test Coverage
                                            </div>
                                            <p>
                                                Automation frees up time for you
                                                to spend writing new tests and
                                                adding them to your automated
                                                test suite. This increases the
                                                test coverage for your product,
                                                resulting in a higher quality
                                                application with more features
                                                properly tested.
                                                <br />
                                                Furthermore, automated testing
                                                enables developers to write more
                                                detailed tests that test complex
                                                use cases. Longer tests, which
                                                are frequently avoided during
                                                manual testing, can be run
                                                unattended.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="blockchain-box">
                                        <div className="usecase-content mnh-usecase">
                                            <div className="sm-title text-start fw-600 lh-1-4em">
                                                Faster Time to Market and better
                                                insights
                                            </div>
                                            <p>
                                                With test automation, newly
                                                developed features can be
                                                continuously tested and
                                                validated. This shortens the
                                                feedback and testing cycle,
                                                allowing businesses to bring
                                                their products to market faster.
                                                <br />
                                                When some tests fail, automated
                                                testing provides better insights
                                                than manual testing. Not only
                                                does automated software testing
                                                provide insights into the
                                                application, but it also
                                                displays the memory contents,
                                                data tables, file contents, and
                                                other internal program states.
                                                This assists developers in
                                                determining what went wrong.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DigitalGetStarted />
                </section>
            </Layout>
        </>
    )
}

export default Qaautomation
