import CardHeader from '@mui/material/CardHeader'
import Card from '@mui/material/Card'
import React from 'react'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import SvgIcon from '@mui/material/SvgIcon'
import { ReactComponent as ClockIcon } from '../../assets/agency/img/lead-clock-dynamic.svg'
import Typography from '@mui/material/Typography'

export default function TalentInfoTooltip({
    item,
    fullName,
    totalExperience,
    perHourRate,
    minContractDuration,
    minBudget,
    stackRole,
    currentStatus,
    talent_network,
}) {
    function getTalentNetworkCost(talent_network, searchTalentId) {
        for (const nestedArray of talent_network) {
            if (Array.isArray(nestedArray)) {
                const result = nestedArray.find((item) =>
                    item.talent_id.includes(searchTalentId)
                )
                if (result) {
                    return result.talent_network_cost
                }
            }
        }
        return null
    }

    const cost = getTalentNetworkCost(talent_network, item.id)

    return (
        <Card
            sx={{
                backgroundColor: 'inherit',
                color: 'inherit',
                borderRadius: '8px',
            }}
            elevation={0}
        >
            <CardHeader
                disableTypography
                sx={{ p: 1, borderBottom: '0.1px solid' }}
                title={
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="size2Semibold">
                            {fullName}
                        </Typography>
                        <Chip
                            icon={
                                <SvgIcon
                                    color="inherit"
                                    component={ClockIcon}
                                    viewBox="-4 -4 24 24"
                                />
                            }
                            label={currentStatus ? currentStatus : ' - '}
                            variant="talentInfoTooltipChip"
                            size="small"
                        />
                    </Box>
                }
            />
            <Box sx={{ p: 1 }}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        Exp:
                        <Box sx={{ pl: 1 }} component="span">
                            {totalExperience}
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        Rate/Hour:
                        <Box sx={{ pl: 1 }} component="span">
                            {cost ? cost : perHourRate}
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        Min contract:
                        <Box sx={{ pl: 1 }} component="span">
                            {minContractDuration === "" ? "-" : minContractDuration}
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        Min budget:
                        <Box sx={{ pl: 1 }} component="span">
                            {minBudget === "" ? "-" : minBudget}
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        Role:
                        <Box sx={{ pl: 1 }} component="span">
                            {stackRole ? stackRole : '-'}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    )
}
