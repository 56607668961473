import React, { useEffect, useState } from 'react'
import Layout from '../Layout'
import FilterLead from '../../../Components/Leads/FilterLead'
import SortFilterLead from '../../../Components/Leads/SortFilterLead'
import LeadItem from '../../../Components/Leads/LeadItem'
import Skeleton from 'react-loading-skeleton'
import AuthUser from '../../../Services/AuthUser'
import AgencySmModal from '../../../Components/Modals/AgencySmModal'
import upgradeIcon from '../../../assets/agency/img/upgrade-icon.svg'
import noLeadIcon from '../../../assets/agency/img/no-lead-data.svg'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { setLeadsItem } from '../../../Redux/SliceReducers/LeadsMainPage'
import moment from 'moment'

const Leads = () => {
    const dispatch = useDispatch()
    const leadsItemState = useSelector(
        (state) => state.leadsMainPage.value.leadsItem
    )
    const { http2, postRequest } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const [filterData, setFilterData] = useState('')
    const [leadItem, setLeadItem] = useState([])
    const [leadResult, setLeadResult] = useState('')
    const [sortType, setSortType] = useState('desc')
    const [selectedButton, setSelectedButton] = useState('desc')
    const [talentnetVal, setTalentnetVal] = useState([])

    const getSearchData = (serarchItem) => {
        setFilterData(serarchItem)
    }

    const talent_network = leadItem.map((item) => item.matched_talent_rates)

    const getLeadList = () => {
        setIsLoading(true)
        let payload = {
            is_sort: filterData?.is_sort || '',
            company_id: filterData?.company_id || '',
            stack_id: filterData?.stack_id || '',
        }
        postRequest(`/hiring-request/list`, payload).then((res) => {
            let result = res.data.data
            dispatch(setLeadsItem(result.hirings))
            setLeadItem(result.hirings)
            setLeadResult(result)
            setIsLoading(false)
            setTalentnetVal(result.hirings)
        })
    }

    const handleSortButton = (type) => {
        setSortType(type)
        setSelectedButton(type)
    }

    const [applyModal, setApplyModal] = useState(false)
    const openApplyModal = () => {
        setApplyModal(true)
    }
    const closeApplyModal = () => {
        setApplyModal(false)
    }

    useEffect(() => {
        getLeadList()
        setReload()
    }, [reload, sortType, filterData])

    // Filtering leadsItemState based on sortType
    const filteredLeads = leadsItemState?.filter((item) => {
        if (sortType === 'desc') {
            return item.matched_candidates.length === 0
        } else if (sortType === 'asc') {
            return item.matched_candidates.length > 0
        }
        return true // Default to return all items if sortType is neither "asc" nor "desc"
    })

    const clearFilter = () => {
        setFilterData('')
        setReload(!reload)
    }

    const filterExpired = (entries) => {
        return entries.filter(
            (v) =>
                moment(v.hiring_end_date).valueOf() > moment().valueOf() ||
                (v.matched_candidates && v.matched_candidates.length > 0)
        )
    }

    const expiredLeadsCount = (entries) => {
        console.log(
            entries.filter(
                (v) => moment(v.hiring_end_date).valueOf() > moment().valueOf()
            )
        )
        return entries.filter(
            (v) =>
                moment(v.hiring_end_date).valueOf() > moment().valueOf() ||
                (v.matched_candidates && v.matched_candidates.length > 0)
        ).length
    }

    return (
        <>
            <Layout>
                <div className="ad-dash-info-cards lead-info-card">
                    <div className="d-flex align-items-center justify-content-between adts-header">
                        <div className="d-flex align-items-center ad-xxs-mb1">
                            <div className="ad-st-count w-70">
                                <div className="ad-st-title1">
                                    {leadsItemState
                                        ? expiredLeadsCount(leadsItemState)
                                        : '0'}
                                </div>
                            </div>
                            <div className="dash-hc-info">
                                <div className="md-title text-center fw-600 dash-black-text">
                                    Hiring Leads
                                </div>
                            </div>
                        </div>

                        <div className="d-inline-flex align-items-center">
                            <FilterLead
                                setIsLoading={setIsLoading}
                                setReload={setReload}
                                reload={reload}
                                getSearchData={getSearchData}
                                clearFilter={clearFilter}
                            />
                            {/* <SortFilterLead
                                setSortType={setSortType}
                            /> */}
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '14px',
                            gap: '10px',
                        }}
                    >
                        <Button
                            variant={
                                selectedButton === 'desc'
                                    ? 'Selected'
                                    : 'NotSelected'
                            }
                            sx={{ textTransform: 'capitalize' }}
                            onClick={() => handleSortButton('desc')}
                        >
                            Newest
                        </Button>
                        <Button
                            variant={
                                selectedButton === 'asc'
                                    ? 'Selected'
                                    : 'NotSelected'
                            }
                            sx={{ textTransform: 'capitalize' }}
                            onClick={() => handleSortButton('asc')}
                        >
                            Applied
                        </Button>
                    </div>
                </div>

                <div className="ad-team-flex mt-3">
                    <div className="ad-team-summary">
                        <div className="adts-body">
                            <div className="adp-list ad-lead-list">
                                <div className="adp-list-width">
                                    {isLoading ? (
                                        <Skeleton
                                            containerClassName="cs-skeleton"
                                            count={5}
                                            height={80}
                                        />
                                    ) : filteredLeads ? (
                                        filteredLeads.length > 0 ? (
                                            filterExpired(filteredLeads)
                                                .length > 0 ? (
                                                filterExpired(
                                                    filteredLeads
                                                ).map((item) => (
                                                    <LeadItem
                                                        item={item}
                                                        talent_network={
                                                            talent_network
                                                        }
                                                        key={item.id}
                                                        openApplyModal={
                                                            openApplyModal
                                                        }
                                                        is_premium_member={
                                                            leadResult?.is_premium_member
                                                        }
                                                    />
                                                ))
                                            ) : (
                                                <div className="ad-white-card mt-3 p-5 no-data-box">
                                                    <div className="mt-5 text-center">
                                                        <img
                                                            src={noLeadIcon}
                                                            alt="no lead"
                                                        />
                                                    </div>
                                                    <div className="content ad-gray-text text-center mt-4 mb-5">
                                                        You don’t have any
                                                        hiring lead yet!
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="ad-white-card mt-3 p-5 no-data-box">
                                                <div className="mt-5 text-center">
                                                    <img
                                                        src={noLeadIcon}
                                                        alt="no lead"
                                                    />
                                                </div>
                                                <div className="content ad-gray-text text-center mt-4 mb-5">
                                                    You don’t have any hiring
                                                    lead yet!
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="ad-white-card mt-3 p-5 no-data-box">
                                            <div className="mt-5 text-center">
                                                <img
                                                    src={noLeadIcon}
                                                    alt="no lead"
                                                />
                                            </div>
                                            <div className="content ad-gray-text text-center mt-4 mb-5">
                                                You don’t have any hiring lead
                                                yet!
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <AgencySmModal open={applyModal} close={closeApplyModal}>
                <div className="text-center">
                    <img src={upgradeIcon} alt="upgrade" />
                </div>
                <div className="mt-3 ad-md-title text-center">
                    Upgrade your plan
                </div>
                <div className="mt-2 content text-center">
                    <p className="ad-lgray-text">
                        You've the <strong>Free</strong> plan. Please upgrade
                        your account to continue.
                    </p>
                </div>
                <div className="mt-3 text-center row px-3">
                    <div className="col-6">
                        <button
                            type="button"
                            className="ad-gray-btn ad-sm-btn w-100"
                            onClick={closeApplyModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="col-6">
                        <Link
                            className="ad-theme-btn ad-sm-btn w-100 d-block"
                            to="/dashboard/settings/billing-subscription/select-plan"
                        >
                            Upgrade
                        </Link>
                    </div>
                </div>
            </AgencySmModal>
        </>
    )
}

export default Leads
