import React from 'react'
import noTeamIcon from '../../assets/agency/img/no-team.svg'

const NoDataLead = () => {
    return (
        <>
            <div className="ad-white-card mt-3 p-5 no-data-box">
                <div className="mt-3 text-center">
                    <img src={noTeamIcon} alt="no team" />
                </div>
                <div className="content ad-gray-text text-center mt-4 mb-4">
                    <p>No Match Found</p>
                </div>
            </div>
        </>
    )
}

export default NoDataLead
