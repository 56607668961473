import { Field, Formik, Form } from 'formik'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import PhoneInputField from '../Form/PhoneInputField'
import { useEffect } from 'react'
import AuthUser from '../../Services/AuthUser'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import BtnSpinner from '../Spinners/BtnSpinner'
const validationSchemaTeam = yup.object({
    name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    email: yup
        .string()
        .email('Invalid email address')
        .required('This field is required!'),
    team_size: yup
        .number()
        .typeError('This field is must be a number')
        .min(0, 'This field is cannot be negative')
        .required('This field is required!'),
    phone_number: yup
        .string()
        .matches(/^\+?[0-9]+$/, 'This field is must be a number')
        .required('This field is required!'),
})
const ContactSalesModal = ({
    clickOpenModal,
    close,
    setOpen,
    setReload,
    setSuccessContactSales,
}) => {
    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    let user = localStorage.getItem('user')
    user = user ? JSON.parse(user) : {}

    const contactSalesTeam = (fields) => {
        console.log(fields)
        try {
            setIsLoading(true)
            http2
                .post('/add-contact-sales', fields)
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        // toast.success(result.message);
                        setIsLoading(false)
                        setOpen(false)
                        setSuccessContactSales(true)
                    }
                    if (result.success === false) {
                        toast.error(result.data)
                        setIsLoading(false)
                        setOpen(false)
                    }
                })
                .catch((error) => {
                    let res = error.response.data
                    if (res.status === false) {
                        toast.error(res.message)
                        setOpen(false)
                        console.log(res.message)
                    } else {
                        toast.error('Internal Server Error')
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: user.full_name || '',
                    email: user.email || '',
                    team_size: '',
                    phone_code: '',
                    phone_number: '',
                    country_short_code: '',
                }}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    contactSalesTeam(values)
                    resetForm()
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <Form autoComplete="off">
                        <div className="modal-header">
                            <h5 className="modal-title" id="commonSMModalLabel">
                                Contact Sales
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setOpen(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="custom-form ad-custom-form ad-form-theme2 row">
                                <div className="col-12 form-group">
                                    <div className="position-relative">
                                        <label className="label-pos">
                                            Full name*
                                        </label>
                                        <Field
                                            type="text"
                                            className="form-control text-capitalize"
                                            name="name"
                                        />
                                    </div>
                                    <ValidationError name="name" />
                                </div>
                                <div className="col-12 form-group">
                                    <div className="position-relative">
                                        <label className="label-pos">
                                            Email address*
                                        </label>
                                        <Field
                                            type="email"
                                            className="form-control"
                                            placeholder=""
                                            name="email"
                                        />
                                    </div>
                                    <ValidationError name="email" />
                                </div>
                                <div className="col-12 form-group">
                                    <div className="position-relative">
                                        <label className="label-pos">
                                            Team size*
                                        </label>
                                        <Field
                                            type="number"
                                            className="form-control"
                                            placeholder=""
                                            name="team_size"
                                        />
                                    </div>
                                    <ValidationError name="team_size" />
                                </div>
                                <div className="col-12">
                                    <div className="mobile-number-field">
                                        <Field
                                            name="phone_number"
                                            placeholder="Mobile number"
                                            inputStyleType="outlined"
                                            component={PhoneInputField}
                                            label={false}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 col-12">
                                    {isLoading ? (
                                        <BtnSpinner />
                                    ) : (
                                        <button
                                            type="submit"
                                            className="ad-theme-btn w-100"
                                        >
                                            Submit
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ContactSalesModal
