/**
 *
 * we are here to handle all hiring status
 *
 */

import Chip from '@mui/material/Chip'

const StatusTag = ({ item }) => {
    const { total_hired_talents, hiring_status, hiring_left_days } = item

    let clg, val

    // if (total_hired_talents !== 0 && hiring_status !== "archive" && hiring_status !== "pause") {
    //   clg = "green"; // test ci ci
    //   val = `Hired:${total_hired_talents}`;
    // }else{
    if (['pause'].includes(hiring_status)) {
        clg = 'orange'
        val = 'Paused'
    } else if (['archive'].includes(hiring_status)) {
        clg = 'red'
        val = 'Archived'
    } else {
        if (hiring_left_days > 10) {
            clg = 'sblue'
            val = `${hiring_left_days} Days left`
        } else {
            clg = 'red'
            val = `${hiring_left_days} Days left`
        }
    }
    //}

    return (
        <Chip
            variant="dateChip2"
            size="small"
            label={`Closing: ${item.hiring_end_date}`}
        />
    )
}

export default StatusTag
