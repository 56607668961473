import { Field, Formik, Form, FieldArray } from 'formik'
import * as yup from 'yup'
import imgRemove from '../../assets/dashboard/img/remove.svg'
import { useState, useLayoutEffect } from 'react'
import { utilities } from '../../Config/Helper'
import TextField from '../Form/TextField'
import ValidationError from '../Errors/ValidationError'
import AuthUser from '../../Services/AuthUser'
import BtnSpinner from '../Spinners/BtnSpinner'
import { toast, ToastContainer } from 'react-toastify'

const validationSchema = yup.object({
    peoples: yup
        .array()
        .of(
            yup.object().shape({
                email: yup
                    .string()
                    .email('Must be a valid email')
                    .required('Email field is required'), // these constraints take precedence
                permissions: yup
                    .array()
                    .min(1, 'At least 1 permission is required')
                    .required('At least 1 permission is required'), // these constraints take precedence
            })
        )
        .required('Must have peoples'),
})

const AddCollabratorModal = ({
    openAddCollabratorModal,
    setOpenAddCollabratorModal,
    setReload,
    peoplePermissions,
    setPeoplePermissions,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const { http } = AuthUser()
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { my_company_id } = user
    // console.log(user)

    const createPeopleProfile = (fields) => {
        try {
            setIsLoading(true)
            http.post(`/create-people`, fields)
                .then((response) => {
                    let result = response.data
                    if (result.status) {
                        toast.success(result.message)
                        setIsLoading(false)
                        setOpenAddCollabratorModal(false)
                        setReload(false)
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(error.response.message)
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleAllPermissionsToggle = (index, checked, f) => {
        const permissionsArray = peoplePermissions.map(
            (permissionsitem) => permissionsitem._id
        )
        if (checked) {
            f.setFieldValue(`peoples.${index}.permissions`, permissionsArray)
        } else {
            f.setFieldValue(`peoples.${index}.permissions`, [])
        }
    }

    useLayoutEffect(() => {
        ;(async () => {
            let permissions = await utilities('people-permissions')
            setPeoplePermissions(permissions)
        })()
    }, [])
    return (
        <>
            {/* <ToastContainer limit={1} hideProgressBar={true} /> */}
            <div
                className={`modal fade dash-custom-modal collabrator-modal ${
                    openAddCollabratorModal ? 'show' : ''
                }`}
                id="commonSMModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setOpenAddCollabratorModal(false)
                                }}
                            ></button>
                            <div className="dash-md-title fw-600 text-start">
                                Add People
                            </div>
                            <Formik
                                //enableReinitialize={true}
                                validationSchema={validationSchema}
                                initialValues={{
                                    peoples: [{ email: '', permissions: [] }],
                                    company_id: my_company_id,
                                }}
                                onSubmit={(values, { resetForm }) => {
                                    // data send serve side
                                    createPeopleProfile(values)
                                    resetForm()
                                }}
                            >
                                {({ values }) => (
                                    <Form
                                        className="dash-custom-form mt-4"
                                        autoComplete="off"
                                    >
                                        <div className="collabrator-table">
                                            <table className="w-100">
                                                <thead>
                                                    <tr>
                                                        <th className="w-40 pe-4">
                                                            Email Address
                                                        </th>
                                                        <th className="w-55 pe-1">
                                                            Permissions
                                                        </th>
                                                        <th className="w-5 p-0"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FieldArray
                                                        name="peoples"
                                                        render={(
                                                            arrayHelpers
                                                        ) => (
                                                            <>
                                                                {values.peoples.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td className="pe-4 form-group-mb-0">
                                                                                <TextField
                                                                                    type="email"
                                                                                    name={`peoples.${index}.email`}
                                                                                    placeholder="Email"
                                                                                    className="form-control"
                                                                                />
                                                                            </td>
                                                                            <td className="ps-1 pe-1">
                                                                                <ul className="add-coll-list">
                                                                                    <li>
                                                                                        <div className="custom-checkbox checkbox-right">
                                                                                            <Field>
                                                                                                {({
                                                                                                    field,
                                                                                                    form,
                                                                                                }) => (
                                                                                                    <input
                                                                                                        className="styled-checkbox"
                                                                                                        id={`peoples.${index}`}
                                                                                                        type="checkbox"
                                                                                                        checked={
                                                                                                            values
                                                                                                                .peoples[
                                                                                                                index
                                                                                                            ]
                                                                                                                .permissions
                                                                                                                .length ===
                                                                                                            peoplePermissions.length
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) =>
                                                                                                            handleAllPermissionsToggle(
                                                                                                                index,
                                                                                                                e
                                                                                                                    .target
                                                                                                                    .checked,
                                                                                                                form
                                                                                                            )
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                            <label
                                                                                                htmlFor={`peoples.${index}`}
                                                                                            >
                                                                                                <strong>
                                                                                                    All
                                                                                                    Access
                                                                                                </strong>
                                                                                            </label>
                                                                                        </div>
                                                                                    </li>
                                                                                    {peoplePermissions &&
                                                                                        peoplePermissions.map(
                                                                                            (
                                                                                                permissionsitem,
                                                                                                key
                                                                                            ) => (
                                                                                                <li
                                                                                                    key={
                                                                                                        key
                                                                                                    }
                                                                                                >
                                                                                                    <div className="custom-checkbox checkbox-right">
                                                                                                        <Field
                                                                                                            type="checkbox"
                                                                                                            name={`peoples.${index}.permissions`}
                                                                                                            value={
                                                                                                                permissionsitem._id
                                                                                                            }
                                                                                                            id={`${index}-${permissionsitem._id}`}
                                                                                                            className="styled-checkbox"
                                                                                                        />
                                                                                                        <label
                                                                                                            htmlFor={`${index}-${permissionsitem._id}`}
                                                                                                        >
                                                                                                            {
                                                                                                                permissionsitem.name
                                                                                                            }
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    <ValidationError
                                                                                        name={`peoples.${index}.permissions`}
                                                                                    />
                                                                                </ul>
                                                                            </td>
                                                                            <td className="ps-0 pe-0 align-top">
                                                                                {values
                                                                                    .peoples
                                                                                    .length <=
                                                                                1 ? (
                                                                                    ''
                                                                                ) : (
                                                                                    <button
                                                                                        className="removeicon-btn mt-2"
                                                                                        type="button"
                                                                                        onClick={() =>
                                                                                            arrayHelpers.remove(
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                imgRemove
                                                                                            }
                                                                                            alt="remove"
                                                                                        />
                                                                                    </button>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                                <tr>
                                                                    <td
                                                                        colSpan={
                                                                            3
                                                                        }
                                                                        className="add-more-btn"
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className="add-more-text"
                                                                            onClick={() =>
                                                                                arrayHelpers.push(
                                                                                    {
                                                                                        email: '',
                                                                                        permissions:
                                                                                            [],
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            Add
                                                                            More
                                                                            +
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    />
                                                </tbody>
                                            </table>
                                            <div className="mt-3 mb-3 text-center">
                                                {isLoading ? (
                                                    <BtnSpinner cls="dash-theme-btn br-20" />
                                                ) : (
                                                    <button
                                                        type="submit"
                                                        className="dash-theme-btn br-20"
                                                    >
                                                        Invite
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCollabratorModal
