import React, { useEffect, useRef } from 'react'
import { Formik, Form, Field } from 'formik'
import userCircleIcon from '../../assets/agency/img/user-circle-icon.svg'
import onboardingUploadIcon from '../../assets/agency/img/onboarding-upload.svg'
import BtnSpinner from '../Spinners/BtnSpinner'
import teamImg from '../../assets/agency/img/team-1.jpg'
import { useState } from 'react'
import Currency from '../Currency/Currency'
import * as yup from 'yup'
import ValidationError from '../Errors/ValidationError'
import PreviewImage from '../Form/PreviewImage'
import FormikDatePicker from '../Form/FormikDatePicker'
import axios from 'axios'
import AuthUser from '../../Services/AuthUser'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import SelectField from '../Form/SelectField'
import SelectFieldNew from '../Form/SelectFieldNew'
import SassCurrency from '../Currency/SassCurrency'
import infoIcon from '../../assets/agency/img/information-button.png'
import apiCall from '../../Services/ApiInstance'
import AddLeadModal from './AddLeadModal'
import SvgIcon from '@mui/material/SvgIcon'
import IconButton from '@mui/material/IconButton'

import { ReactComponent as CloseIcon2 } from '../../assets/agency/img/close-icon-dynamic.svg'

let base_URL = process.env.REACT_APP_base_URL
let avatarFieldValue = ''
import { ReactComponent as CloseIcon } from '../../assets/agency/img/close-icon.svg'
import { Box, Typography } from '@mui/material'
import moment from 'moment'

const validationSchemaTeam = yup.object({
    project_name: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .min(2, 'This field must be at least 2 characters')
        .max(30, 'This field must not be greater than 30 characters')
        .required('This field is required!'),
    client_name: yup
        .string()
        // .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")//
        // .min(2, "This field must be at least 2 characters")
        // .max(30, "This field must not be greater than 30 characters")
        .required('This field is required!'),
    client_email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required!'),
    project_start_date: yup.string().required('This field is required!'),
    // project_end_date: yup.string().required("This field is required!"),
    project_currency: yup.string().required('This field is required!'),
})
const AddNewProjectModal = ({ setReload, reload, setModalOpen }) => {
    const { http2, token } = AuthUser()
    const navigate = useNavigate()

    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    let { currency } = user

    const [addTeamOnProject, setAddTeamOnProject] = useState(false)
    const [isProjectLoading, setIsProjectLoading] = useState(false)
    const [openAddProjectLead, setOpenAddProjectLead] = useState(false)

    let selectdTeamMemberIds
    let teamMembersForProject

    const [image, setImage] = useState('')
    const [imageName, setImageName] = useState('')
    const [imgAfterCrop, setImgAfterCrop] = useState('')
    const [imgError, setImgError] = useState('')
    const [getClientList, setGetClientList] = useState([])
    const onImageSelected = (selectedImg) => {
        setImage(selectedImg)
    }
    const currencyList = ['USD', 'CAD', 'INR']
    const currencyType = currencyList.map((item) => ({
        key: item,
        value: item,
    }))

    const inputRef = useRef()

    const imgRef = useRef(null)

    const handleOnChange = (event, f) => {
        const imageFile = event.target.files[0]
        // const dataURL = canvasEle.toDataURL("image/jpeg");

        if (imageFile.name.match(/\.(jpg|jpeg|png|svg)$/)) {
            if (
                event.currentTarget.files &&
                event.currentTarget.files.length > 0
            ) {
                const reader = new FileReader()
                reader.readAsDataURL(event.currentTarget.files[0])
                reader.onload = function (e) {
                    onImageSelected(reader.result)
                }
            }
            // setCropperModal(true);
            setImageName(event.currentTarget.files[0].name)
            setImgError('')
            f.setFieldValue('project_logo', event.currentTarget.files[0])
            setImgAfterCrop(event.target.files[0])
            // setLogoName(imageFile.name)
            f.setFieldValue('project_logo', imageFile)
            f.setFieldValue('logo_name', imageFile.name)
        } else {
            setImgError('Please select only jpeg, jpg, png and svg files')
        }
        event.target.value = null
    }

    const onChooseImg = () => {
        imgRef.current.click()
    }

    const resetFileInput = () => {
        if (imgRef.current) {
            imgRef.current.value = null
        }
    }

    const removeCompanyLogo = (event, f) => {
        f.setFieldValue('project_logo', '')
        f.setFieldValue('logo_name', '')
        setImgAfterCrop('')
        // setLogoName("")
    }
    // Create a Date object for January 1st, 2001
    const minDate = () => {
        let startDate = new Date(2001, 0, 1)
        const minStartDate = startDate.toISOString()
        return minStartDate
    }

    //get-team-members
    const [getTeamItem, setGetTeamItem] = useState([])
    const getTeam = () => {
        // setIsLoading(true);
        http2.get(`/get-team-members`).then((res) => {
            let result = res.data.data

            setGetTeamItem(typeof result != 'string' ? result : [])
        })
    }
    const [projectTeamList, setProjectTeamList] = useState([])
    const initialValues = {
        project_name: '',
        client_name: '',
        client_id: '',
        project_currency: '',
        project_start_date: '',
        // project_end_date: "",
        month_budget: '',
        client_email: '',
        jira_google_url: '',
        project_logo: '',
        my_currency_month_budget: '',
        teams: [],
    }

    const getClientDropdown = () => {
        apiCall({
            method: 'get',
            url: `${base_URL}/api/v2/get-client-dropdown`,
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                let result = response.data
                if (result.success) {
                    const filteredClientList = result.data.filter(
                        (client) =>
                            client?.client_company_name && client?.client_email
                    )

                    const listType = filteredClientList.map((item, index) => ({
                        key: index,
                        client_id: item._id,
                        value: item.client_company_name,
                        email: item.client_email,
                        index: index,
                    }))

                    // const address = getClientList.map(client => client.address_line_1)

                    setGetClientList(listType)
                    console.log(result.data)
                } else {
                    toast.error('Failed to fetch client list')
                }
            })
            .catch((error) => {
                toast.error('An error occurred while fetching client list')
            })
    }
    // console.log("address",address);

    const addProjectValues = (fields, resetForm) => {
        console.log(
            'fields.teams.length',
            moment(moment(fields.project_start_date))
                [
                    Math.sign(moment(fields.project_start_date).utcOffset()) ==
                    -1
                        ? 'subtract'
                        : 'add'
                ](moment(fields.project_start_date).utcOffset(), 'm')
                .utc()
                .format()
        )
        // const jsDate = new Date(fields.project_start_date);
        // const isoFormattedDate = jsDate.toISOString();
        fields['project_start_date'] = moment(moment(fields.project_start_date))
            [
                Math.sign(moment(fields.project_start_date).utcOffset()) == -1
                    ? 'subtract'
                    : 'add'
            ](moment(fields.project_start_date).utcOffset(), 'm')
            .utc()
            .format()
        let data = new FormData()
        let filteredArr = []
        console.log(fields)

        Object.keys(fields).forEach((key) => {
            if (key === 'teams') {
                fields[key].forEach((team, index) => {
                    data.append(
                        `teams[${index}][team_member_id]`,
                        team.team_member_id
                    )
                    data.append(`teams[${index}][role]`, team.role)
                    data.append(
                        `teams[${index}][internal_rate]`,
                        team.internal_rate
                    )
                    data.append(
                        `teams[${index}][reserved_hours]`,
                        team.reserved_hours
                    )
                    data.append(
                        `teams[${index}][client_hourly_rate]`,
                        team.client_hourly_rate
                    )
                    data.append(
                        `teams[${index}][add_asteam_lead]`,
                        team.add_asteam_lead
                    )
                    data.append(
                        `teams[${index}][is_hide_picture]`,
                        team.hide_avatar_toggle == false ? false : true
                    )
                    data.append(
                        `teams[${index}][my_currency_internal_rate]`,
                        team.my_currency_internal_rate
                    )
                    data.append(
                        `teams[${index}][my_currency_client_hourly_rate]`,
                        team.my_currency_client_hourly_rate
                    )
                })

                filteredArr = fields[key].filter((member) => {
                    return member.add_asteam_lead != '' ? true : false
                })
                console.log(
                    'fields.teams.length2',
                    fields.teams.length,
                    filteredArr
                )
            } else {
                if (key == 'client_name') {
                    data.append(
                        'client_company_name',
                        getClientList.find(
                            (client) => client.key == fields.client_name
                        ).value
                    )
                } else {
                    data.append(key, fields[key])
                }
            }
        })

        const matchingClient = getClientList.find(
            (client) => client.client_name === fields.client_name
        )
        if (matchingClient) {
            const excludeKeys = [
                'address_line_1',
                'state',
                'country',
                'city',
                'pin_code',
            ]
            Object.keys(matchingClient).forEach((key) => {
                if (!excludeKeys.includes(key)) {
                    console.log(matchingClient[key], typeof matchingClient[key])
                    if (key === '_id') {
                        data.append('client_id', matchingClient[key])
                    } else if (key === 'billing_email') {
                        if (matchingClient[key]) {
                            data.append(
                                key,
                                matchingClient[key].split(',').map((v) => v)
                            )
                        } else {
                            data.append(key, matchingClient[key])
                        }
                    } else {
                        data.append(key, matchingClient[key])
                    }
                }
            })
        }
        console.log('fields.teams.length3', fields.teams.length, filteredArr)
        if (fields.teams.length != 0 && filteredArr.length == 0) {
            setErrors({
                team_lead_present:
                    'Please select atleast one candidate as project lead!',
            })
            if (fields.teams.length > 1) {
                setOpenAddProjectLead(true)
            }
        } else {
            try {
                setIsProjectLoading(true)
                apiCall({
                    method: 'post',
                    url: `${base_URL}/api/v2/projects`,
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    data: data,
                })
                    .then((response) => {
                        let result = response.data
                        if (result.success) {
                            toast.success(result.message)
                            resetForm()
                            setModalOpen(false)
                            setReload(false)
                            setIsProjectLoading(false)
                            navigate('/dashboard/projects')
                        }
                        if (result.success == false) {
                            toast.error(result.message)
                            setModalOpen(false)
                            setIsProjectLoading(false)
                        }
                        setModalOpen(false)
                        setIsProjectLoading(false)
                    })
                    .catch((error) => {
                        const err = error.response.data
                        if (error.response.status) {
                            toast.error(err.message)
                        }
                        setIsProjectLoading(false)
                    })
            } catch (error) {
                toast.error(error.response.data.message)
                console.log(error)
                setIsProjectLoading(false)
            }
        }
    }

    useEffect(() => {
        getTeam()
        getClientDropdown()
    }, [])

    const initialFormData = {
        team_member_id: '',
        role: '',
        internal_rate: '',
        reserved_hours: '',
        client_hourly_rate: '',
        add_asteam_lead: '',
        hide_avatar_toggle: false,
        my_currency_internal_rate: '',
        my_currency_client_hourly_rate: '',
    }
    const [teamData, setTeamData] = useState({
        team_member_id: '',
        role: '',
        internal_rate: '',
        reserved_hours: '',
        client_hourly_rate: '',
        add_asteam_lead: '',
        hide_avatar_toggle: false,
        short_name: '',
        project_logo: '',
        color_code: '',
        my_currency_client_hourly_rate: '',
    })
    const [errors, setErrors] = useState({})
    const handleInputChange = (e) => {
        const { name, value } = e.target
        console.log('e', e.target, e)
        setTeamData({ ...teamData, [name]: value })
        setErrors({ ...errors, [name]: validateInput(name, value) })
    }

    const clickAddTeamOnProject = () => {
        setAddTeamOnProject(true)
    }
    const clickCancelTeamOnProject = () => {
        setAddTeamOnProject(false)
        setTeamData({
            team_member_id: '',
            role: '',
            internal_rate: '',
            reserved_hours: '',
            client_hourly_rate: '',
            add_asteam_lead: '',
            hide_avatar_toggle: false,
            short_name: '',
            project_logo: '',
            color_code: '',
            my_currency_client_hourly_rate: '',
        })
    }

    const validateInput = (name, value) => {
        if (name === 'team_member_id' && value === '') {
            return 'This field is required!'
        }
        if (name === 'role' && value === '') {
            return 'This field is required!'
        }
        // if (name === "internal_rate" && value === "") {
        //   return "This field is required!";
        // }
        if (name === 'reserved_hours' && value === '') {
            return 'This field is required!'
        }
        if (name === 'client_hourly_rate' && value === '') {
            return 'This field is required!'
        }
        return null // No error
    }
    const handleSubmit = (e, setFieldValue) => {
        e.preventDefault()

        const validationErrors = {}

        if (teamData.team_member_id === '') {
            validationErrors.team_member_id = 'This field is required!'
        }

        const reservedHoursRegex = /^[0-9]*$/
        if (teamData.reserved_hours === '') {
            validationErrors.reserved_hours = 'Reserved Hours is required!'
        } else if (!reservedHoursRegex.test(teamData.reserved_hours)) {
            validationErrors.reserved_hours =
                'Only numbers are allowed, no special characters.'
        }

        const hourlyRateRegex = /^[0-9]*$/
        if (teamData.client_hourly_rate === '') {
            validationErrors.client_hourly_rate =
                'Client Hourly Rate is required!'
        } else if (!hourlyRateRegex.test(teamData.client_hourly_rate)) {
            validationErrors.client_hourly_rate =
                'Only numbers are allowed, no special characters.'
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
        } else {
            // Add the team member to the project
            const projectTeamLists = [...projectTeamList, teamData]
            setProjectTeamList(projectTeamLists)
            setTeamData(initialFormData)
            setAddTeamOnProject(false)
            setFieldValue('teams', projectTeamLists)
            setErrors({})
        }
    }

    const handleAddTeamChange = (e, projectCr) => {
        const selectdTeamMember = getTeamItem.find(
            (team) => team.id === e.target.value
        )
        if (!selectdTeamMember) {
            setTeamData({
                team_member_id: '',
                role: '',
                internal_rate: '',
                short_name: '',
                avatar: '',
                color_code: '',
                reserved_hours: '',
                client_hourly_rate: '',
                add_asteam_lead: '',
                hide_avatar_toggle: false,
                my_currency_internal_rate: '',
            })
            return
        }

        setTeamData({
            team_member_id: selectdTeamMember.id || '',
            role: selectdTeamMember.role || '',
            internal_rate:
                selectdTeamMember.internal_rate_currencies[projectCr] || '',
            short_name: selectdTeamMember.short_name || '',
            avatar: selectdTeamMember.avatar || '',
            color_code: selectdTeamMember.color_code || '',
            reserved_hours: '',
            client_hourly_rate: '',
            add_asteam_lead: '',
            hide_avatar_toggle: false,
            my_currency_internal_rate: selectdTeamMember.internal_rate || '',
        })
    }

    const handleKeyPress = (e) => {
        let keyCode = e.which ? e.which : e.keyCode
        // first character 0 not allow
        if (e.which === 48 && !e.target.value.length) e.preventDefault()
        if (!(keyCode >= 48 && keyCode <= 57)) {
            e.preventDefault()
            return false
        }
    }

    const handleAlphaKeyPress = (e) => {
        let code = 'charCode' in e ? e.charCode : e.keyCode
        if (
            !(code === 32) && // space
            !(code >= 65 && code <= 90) && // upper alpha (A-Z)
            !(code >= 97 && code <= 122) // lower alpha (a-z)
        ) {
            e.preventDefault()
        }
    }

    const handleRemoveTeam = (e, member) => {
        e.preventDefault()
        let updated_members = projectTeamList.filter(
            (itm) => itm.team_member_id !== member.team_member_id
        )
        setProjectTeamList(updated_members)
        //setTeamData(updated_members)
    }

    selectdTeamMemberIds =
        projectTeamList && projectTeamList.map((item) => item.team_member_id)

    teamMembersForProject = projectTeamList
        ? getTeamItem.filter((team) => !selectdTeamMemberIds.includes(team.id))
        : getTeamItem

    const isAddTeamLeadChecked =
        projectTeamList &&
        projectTeamList.some((item) => item.add_asteam_lead === 'on')

    const getExchangeRate = (fromCurrency, toCurrency) => {
        const exchangeRates = {
            USD: {
                CAD: 1.3,
                INR: 80,
            },
            INR: {
                USD: 1 / 80,
                CAD: 1.3 / 80,
            },
            CAD: {
                USD: 1 / 1.3,
                INR: (1 / 1.3) * 80,
            },
        }
        return exchangeRates[fromCurrency]?.[toCurrency] ?? null
    }

    const handleInputChangeRate = (e, projectCurrency) => {
        const { name, value } = e.target
        setTeamData({ ...teamData, [name]: value })
        setErrors({ ...errors, [name]: validateInput(name, value) })
        let clientAmount
        if (projectCurrency != currency) {
            const exchangeRate = getExchangeRate(projectCurrency, currency)
            const convertedAmount = parseFloat(value) * exchangeRate
            clientAmount = convertedAmount.toFixed(2)
        } else {
            clientAmount = value
        }

        setTeamData((prevTeamData) => {
            const newTeamData = { ...prevTeamData }
            newTeamData[name] = value
            newTeamData['my_currency_client_hourly_rate'] = clientAmount
            return newTeamData
        })
    }
    const [monthBudget, setMonthBudget] = useState('')
    const handleInputTentativeBudget = (e, projectTentative, f) => {
        const { name, value } = e.target
        let tentativeAmount
        if (projectTentative != currency) {
            const exchangeRate = getExchangeRate(projectTentative, currency)
            const convertedAmount = parseFloat(value) * exchangeRate
            tentativeAmount = convertedAmount.toFixed(2)
        } else {
            tentativeAmount = value
        }
        f.setFieldValue(`month_budget`, value)
        f.setFieldValue(`my_currency_month_budget`, tentativeAmount)
    }
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                    addProjectValues(values, resetForm)
                    // setImgAfterCrop("");
                    // resetForm();
                    setProjectTeamList([])
                    setAddTeamOnProject(false)
                    setImgAfterCrop('')
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    resetForm,
                    formik,
                }) => (
                    <>
                        {/* {console.log(values.month_budget)} */}

                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Add New Project
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setModalOpen(false)
                                        resetForm({ values: initialValues })
                                        setImgAfterCrop('')
                                        setProjectTeamList([])
                                        setAddTeamOnProject(false)
                                        avatarFieldValue = ''
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon2}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row gx-3">
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Project Name*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control text-capitalize"
                                                name="project_name"
                                            />
                                            <ValidationError name="project_name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client*
                                            </label>
                                            {/* <Field
                        type="text"
                        className="form-control text-capitalize"
                        name="client_name"
                      />
                      <ValidationError name="client_name" /> */}
                                            <SelectFieldNew
                                                placeholder="Select Client"
                                                name="client_name"
                                                key={values.client_name}
                                                value={values.client_name}
                                                data={getClientList}
                                                onChange={(e) => {
                                                    // console.log(getClientList,e.target.value,getClientList.find(client => client.client_name === e.target.value));
                                                    console.log(
                                                        'getClientList.find(client => client.client_company_name === e.target.value)',
                                                        getClientList,
                                                        getClientList.find(
                                                            (client) =>
                                                                client.key ==
                                                                e.target.value
                                                        )
                                                    )
                                                    console.dir(e.target)
                                                    setFieldValue(
                                                        'client_name',
                                                        getClientList.find(
                                                            (client) =>
                                                                client.key ==
                                                                e.target.value
                                                        ).key
                                                    )
                                                    setFieldValue(
                                                        'client_id',
                                                        getClientList.find(
                                                            (client) =>
                                                                client.key ==
                                                                e.target.value
                                                        ).client_id
                                                    )
                                                    setFieldValue(
                                                        'client_email',
                                                        getClientList.find(
                                                            (client) =>
                                                                client.key ==
                                                                e.target.value
                                                        ).email
                                                    )
                                                }}
                                            />
                                            {/* <ValidationError name="client_name" /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Currency*
                                            </label>
                                            <SelectField
                                                placeholder="Select"
                                                name="project_currency"
                                                data={currencyType}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Project Start Date*
                                            </label>
                                            <div className="position-relative">
                                                <Field
                                                    minD={minDate()}
                                                    name="project_start_date"
                                                    className="form-control pe-5"
                                                    dateFormat="dd-MMM-yyyy"
                                                    component={(props) => {
                                                        return FormikDatePicker(
                                                            props
                                                        )
                                                    }}
                                                    type="text"
                                                />
                                                <span className="calendar-icon"></span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6 col-12 form-group">
                    <div className="position-relative">
                      <label className="label-pos">Project end date*</label>
                      <div className="position-relative">
                        <Field
                          minD={minStartDate}
                          name="project_end_date"
                          className="form-control pe-5"
                          component={FormikDatePicker}
                          type="text"
                        />
                        <span className="calendar-icon"></span>
                      </div>
                    </div>
                  </div> */}
                                    <div className="col-md-6 col-6 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Tentative Budget for a Month
                                            </label>
                                            <div className="position-relative">
                                                <Field>
                                                    {({ field, form }) => (
                                                        <input
                                                            type="text"
                                                            className="form-control pl-55"
                                                            name="month_budget"
                                                            value={
                                                                values.month_budget
                                                            }
                                                            onChange={(e) =>
                                                                handleInputTentativeBudget(
                                                                    e,
                                                                    values.project_currency,
                                                                    form
                                                                )
                                                            }
                                                            onKeyPress={(e) =>
                                                                handleKeyPress(
                                                                    e
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                                <span className="currency-sign">
                                                    <SassCurrency
                                                        pCurrency={
                                                            values.project_currency
                                                        }
                                                    />
                                                </span>
                                                {/* <span className="ad-info-box currency-info-pos">
                            <img src={infoIcon} alt="info"/>
                            <div className="ad-info-pos"><SassCurrency pCurrency={currency} /> {values.my_currency_month_budget}</div>
                        </span> */}
                                            </div>
                                            {values.project_currency !=
                                                currency &&
                                                values.my_currency_month_budget >
                                                    0 && (
                                                    <div className="base-price-tag">
                                                        <SassCurrency
                                                            pCurrency={currency}
                                                        />{' '}
                                                        {
                                                            values.my_currency_month_budget
                                                        }
                                                    </div>
                                                )}
                                            <ValidationError name="month_budget" />
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Client Email*
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                name="client_email"
                                                readOnly
                                            />
                                            <ValidationError name="client_email" />
                                        </div>
                                    </div>
                                    <div className="col-12 form-group">
                                        <div
                                            className={`drag-drop-box project-drag-drop text-center w-100 position-relative borderradius-8 ${
                                                imgAfterCrop
                                                    ? 'p-0 add-company-bg'
                                                    : ''
                                            }`}
                                        >
                                            {imgAfterCrop ? (
                                                <>
                                                    <div
                                                        className="d-flex align-items-center justify-content-center ad-company-logo"
                                                        style={{
                                                            backgroundColor:
                                                                'white',
                                                        }}
                                                    >
                                                        <PreviewImage
                                                            file={imgAfterCrop}
                                                        />

                                                        <Field name="project_logo">
                                                            {({
                                                                field,
                                                                form,
                                                            }) => (
                                                                <>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={(
                                                                            event
                                                                        ) =>
                                                                            removeCompanyLogo(
                                                                                event,
                                                                                form
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            position:
                                                                                'absolute',
                                                                            right: 8,
                                                                            top: 8,
                                                                        }}
                                                                    >
                                                                        <SvgIcon
                                                                            color="inherit"
                                                                            component={
                                                                                CloseIcon
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="w-100">
                                                    <div className="text-center">
                                                        <img
                                                            src={
                                                                onboardingUploadIcon
                                                            }
                                                            alt="upload"
                                                        />
                                                    </div>
                                                    <div className="mt-1 text-center drag-line">
                                                        <span>
                                                            Project Thumbnail
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <Field name="project_logo">
                                                {({ field, form }) => (
                                                    <>
                                                        <input
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png, .svg"
                                                            ref={imgRef}
                                                            onChange={(event) =>
                                                                handleOnChange(
                                                                    event,
                                                                    form
                                                                )
                                                            }
                                                            style={{
                                                                display: 'none',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                            {imgAfterCrop ? (
                                                ''
                                            ) : (
                                                <button
                                                    className="btn file-upload-input"
                                                    type="button"
                                                    onClick={onChooseImg}
                                                ></button>
                                            )}
                                        </div>
                                        <div className="error-block">
                                            {imgError ? (
                                                <div>
                                                    <label className="error">
                                                        {imgError}
                                                    </label>
                                                </div>
                                            ) : (
                                                ' '
                                            )}
                                            <ValidationError name="project_logo" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="md-title ad-billing-border-top dash-font-1-1em ad-gray-text mt-2">
                                            Add Team Member (Optional)
                                        </div>
                                        <div className="d-flex mt-2 mb-3 align-items-center flex-wrap">
                                            <div className="d-flex">
                                                {/* <Field name="teams"> */}
                                                {projectTeamList.length > 0 ? (
                                                    projectTeamList.map(
                                                        (item, index) => (
                                                            <div
                                                                className="me-1"
                                                                key={index}
                                                            >
                                                                <span
                                                                    className="avatar avatar-md rounded-circle position-relative"
                                                                    style={{
                                                                        background:
                                                                            item.color_code ||
                                                                            '#552FBA',
                                                                    }}
                                                                >
                                                                    {item.avatar ? (
                                                                        <img
                                                                            src={
                                                                                item.avatar
                                                                            }
                                                                            alt={
                                                                                item.short_name
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        item.short_name ||
                                                                        ''
                                                                    )}
                                                                    <button
                                                                        className="ad-cross-icon"
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            handleRemoveTeam(
                                                                                e,
                                                                                item
                                                                            )
                                                                        }
                                                                    ></button>
                                                                </span>
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="me-2">
                                                        <img
                                                            src={userCircleIcon}
                                                            alt="user"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {addTeamOnProject ? (
                                                ''
                                            ) : (
                                                <div>
                                                    <button
                                                        className="ad-theme-link"
                                                        type="button"
                                                        onClick={
                                                            clickAddTeamOnProject
                                                        }
                                                    >
                                                        + Add Team Member
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {addTeamOnProject ? (
                                        <>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12 form-group">
                                                        <div className="position-relative">
                                                            <label className="label-pos">
                                                                Name*
                                                            </label>
                                                            <select
                                                                type="text"
                                                                name="team_member_id"
                                                                className="form-control"
                                                                value={
                                                                    teamData.team_member_id
                                                                }
                                                                onChange={(e) =>
                                                                    handleAddTeamChange(
                                                                        e,
                                                                        values.project_currency
                                                                    )
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select Team
                                                                    Member
                                                                </option>
                                                                {teamMembersForProject.length >
                                                                0 ? (
                                                                    teamMembersForProject.map(
                                                                        (
                                                                            item,
                                                                            key
                                                                        ) => (
                                                                            <>
                                                                                <option
                                                                                    value={
                                                                                        item.id
                                                                                    }
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.full_name
                                                                                    }
                                                                                </option>
                                                                            </>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <option>
                                                                        Team
                                                                        Member
                                                                        Not
                                                                        Found.
                                                                    </option>
                                                                )}
                                                            </select>
                                                            {errors.team_member_id && (
                                                                <label className="error">
                                                                    {
                                                                        errors.team_member_id
                                                                    }
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 form-group">
                                                        <div className="position-relative">
                                                            <label className="label-pos">
                                                                Role*
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="role"
                                                                className="form-control text-capitalize"
                                                                value={
                                                                    teamData.role
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                onKeyPress={(
                                                                    e
                                                                ) =>
                                                                    handleAlphaKeyPress(
                                                                        e
                                                                    )
                                                                }
                                                                readOnly
                                                            />
                                                            {errors.role && (
                                                                <label className="error">
                                                                    {
                                                                        errors.role
                                                                    }
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 form-group">
                                                        <div className="position-relative">
                                                            <label className="label-pos">
                                                                Internal Hourly
                                                                Rate*
                                                            </label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type="text"
                                                                    name="internal_rate"
                                                                    className="form-control pl-55"
                                                                    value={
                                                                        teamData.my_currency_internal_rate ||
                                                                        ''
                                                                    }
                                                                    readOnly
                                                                />
                                                                <span className="currency-sign">
                                                                    <SassCurrency
                                                                        pCurrency={
                                                                            currency
                                                                        }
                                                                    />
                                                                </span>

                                                                {/* <span className="ad-info-box currency-info-pos">
                                    <img src={infoIcon} alt="info"/>
                                    <div className="ad-info-pos"><SassCurrency pCurrency={currency} /> {teamData.my_currency_internal_rate}</div>
                                </span> */}
                                                            </div>
                                                            {/* {values.project_currency != currency && teamData.my_currency_internal_rate > 0 && <div className="base-price-tag"><SassCurrency pCurrency={currency} /> {teamData.my_currency_internal_rate}</div>} */}
                                                            {errors.internal_rate && (
                                                                <label className="error">
                                                                    {
                                                                        errors.internal_rate
                                                                    }
                                                                </label>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12 form-group">
                                                        <div className="position-relative">
                                                            <label className="label-pos">
                                                                Reserved Hours*
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="reserved_hours"
                                                                className="form-control"
                                                                value={
                                                                    teamData.reserved_hours
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                pattern="[0-9]*"
                                                                onKeyPress={(
                                                                    e
                                                                ) =>
                                                                    handleKeyPress(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        {errors.reserved_hours && (
                                                            <label className="error">
                                                                {
                                                                    errors.reserved_hours
                                                                }
                                                            </label>
                                                        )}
                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <div className="position-relative">
                                                            <label className="label-pos">
                                                                Client Hourly
                                                                Rate*
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="client_hourly_rate"
                                                                className="form-control pl-55"
                                                                value={
                                                                    teamData.client_hourly_rate
                                                                }
                                                                onChange={(e) =>
                                                                    handleInputChangeRate(
                                                                        e,
                                                                        values.project_currency
                                                                    )
                                                                }
                                                                pattern="[0-9]*"
                                                                onKeyPress={(
                                                                    e
                                                                ) =>
                                                                    handleKeyPress(
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                            <span className="currency-sign">
                                                                <SassCurrency
                                                                    pCurrency={
                                                                        values.project_currency
                                                                    }
                                                                />
                                                            </span>

                                                            {/* <span className="ad-info-box currency-info-pos">
                                  <img src={infoIcon} alt="info"/>
                                  <div className="ad-info-pos"><SassCurrency pCurrency={currency} /> {teamData.my_currency_client_hourly_rate}</div>
                              </span> */}
                                                        </div>
                                                        {values.project_currency !=
                                                            currency &&
                                                            teamData.my_currency_client_hourly_rate >
                                                                0 && (
                                                                <div className="base-price-tag">
                                                                    <SassCurrency
                                                                        pCurrency={
                                                                            currency
                                                                        }
                                                                    />{' '}
                                                                    {
                                                                        teamData.my_currency_client_hourly_rate
                                                                    }
                                                                </div>
                                                            )}
                                                        {errors.client_hourly_rate && (
                                                            <label className="error">
                                                                {
                                                                    errors.client_hourly_rate
                                                                }
                                                            </label>
                                                        )}
                                                    </div>
                                                    <Box
                                                        className="col-12 form-group"
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Typography variant="size3MediumTextText1">
                                                            Hide profile picture
                                                            for the client
                                                        </Typography>
                                                        <div className="notification-switch main-switch">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="hide_avatar_toggle"
                                                                    onClick={
                                                                        handleInputChange
                                                                    }
                                                                />
                                                                {console.log(
                                                                    'values.hide_avatar_toggle',
                                                                    values
                                                                )}
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        ...(!values.hide_avatar_toggle
                                                                            ? {
                                                                                  '&.slider':
                                                                                      {
                                                                                          backgroundColor:
                                                                                              'teritiary.teritiary6',
                                                                                          borderColor:
                                                                                              'teritiary.teritiary6',
                                                                                      },
                                                                              }
                                                                            : {}),
                                                                    }}
                                                                    className="slider"
                                                                ></Box>
                                                            </label>
                                                        </div>
                                                    </Box>
                                                    <div className="col-12 form-group">
                                                        {!isAddTeamLeadChecked ? (
                                                            <div className="custom-checkbox">
                                                                <input
                                                                    className="styled-checkbox"
                                                                    type="checkbox"
                                                                    name="add_asteam_lead"
                                                                    onClick={
                                                                        handleInputChange
                                                                    }
                                                                    id="addTeamLead"
                                                                />
                                                                <label htmlFor="addTeamLead">
                                                                    Add as a
                                                                    Project Lead
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                    <div className="col-12 form-group d-flex">
                                                        <div className="me-3">
                                                            <button
                                                                className="ad-trans-gray-btn ps-4 pe-4"
                                                                type="button"
                                                                onClick={
                                                                    clickCancelTeamOnProject
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className="ad-trans-theme-btn ps-4 pe-4"
                                                                type="button"
                                                                onClick={(e) =>
                                                                    handleSubmit(
                                                                        e,
                                                                        setFieldValue
                                                                    )
                                                                }
                                                            >
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {errors.team_lead_present && (
                                        <label className="error">
                                            {errors.team_lead_present}
                                        </label>
                                    )}
                                    <div className="mt-2 col-12">
                                        {isProjectLoading ? (
                                            <BtnSpinner
                                                cls="ad-theme-btn w-100"
                                                loader="true"
                                            />
                                        ) : (
                                            <button
                                                className="ad-theme-btn w-100 ad-create-btn"
                                                type="submit"
                                                disabled={addTeamOnProject}
                                            >
                                                Create
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <AddLeadModal
                                    open={openAddProjectLead}
                                    onClose={() => {
                                        setOpenAddProjectLead(false)
                                    }}
                                    PaperProps={{
                                        sx: {
                                            width: '448px',
                                            maxWidth: '1108px',
                                        },
                                    }}
                                />
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AddNewProjectModal
