import { Link, useLocation, useSearchParams } from 'react-router-dom'
import Layout from '../../Layouts/Layout'
import bi_arrow_down from '../../assets/img/bi_arrow_down.svg'
import usecase_banner from '../../assets/img/usecase-banner-img.svg'
const DigitalInnovation = () => {
    const [searchParams] = useSearchParams()

    const location = useLocation()
    const innovation = location.state // "useLocation" to get the state
    const innovationType = searchParams.get('type')
        ? searchParams.get('type')
        : innovation?.type
          ? innovation?.type
          : 'industry'

    const scroll = () => {
        const section = document.querySelector('#contact-us')
        section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    return (
        <Layout>
            <section className="banner-wrap usecase-banner">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="banner-left w-40">
                            <div className="banner-title black-text">
                                Digital Innovation <br />
                                with Weteams
                            </div>
                            <p className="mt-20 mb-40 black-text">
                                Explore the use cases to get innovation insights
                                and how Weteams can help you build an innovation
                                team.
                            </p>
                            <div className="mt-0">
                                <Link
                                    to="/signup"
                                    className="theme-dark-btn mr-15 d-inline-block"
                                >
                                    Sign Up for Free
                                </Link>
                            </div>
                        </div>
                        <div className="banner-right w-60 text-end">
                            <div className="banner-img-box d-inline-block animate-this">
                                <img src={usecase_banner} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="usecase-wrap">
                <div className="container">
                    <div className="custom-tabs usecase-tabs">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${
                                        innovationType == 'industry'
                                            ? 'active'
                                            : ''
                                    }`}
                                    id="industry-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#industry"
                                    type="button"
                                    role="tab"
                                    aria-controls="industry"
                                    aria-selected="true"
                                >
                                    Industry
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className={`nav-link ${
                                        innovationType == 'technologies'
                                            ? 'active'
                                            : ''
                                    }`}
                                    id="trendingTechnologies-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#trendingTechnologies"
                                    type="button"
                                    role="tab"
                                    aria-controls="trendingTechnologies"
                                    aria-selected="false"
                                >
                                    Trending Technologies
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div
                                className={`nav-link ${
                                    innovationType == 'industry'
                                        ? 'tab-pane fade active show'
                                        : 'tab-pane fade'
                                }`}
                                id="industry"
                                role="tabpanel"
                                aria-labelledby="industry-tab"
                            >
                                <div className="row usecase-tab-block">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/fintech"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Fintech.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    FinTech
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/healthtech"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Healthcare.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    HealthTech
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/retailtech"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Retail.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    RetailTech
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/edtech"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Edtech.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    EdTech
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/greentech"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/GreenTech.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    GreenTech
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/vod"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/VOD.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    VOD
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/gaming"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Gaming.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Gaming
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/consumertech"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/ConsumerService.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    ConsumerTech
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/saas"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Saas.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    SaaS
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`nav-link ${
                                    innovationType == 'technologies'
                                        ? 'tab-pane fade active show'
                                        : 'tab-pane fade'
                                }`}
                                id="trendingTechnologies"
                                role="tabpanel"
                                aria-labelledby="trendingTechnologies-tab"
                            >
                                <div className="row usecase-tab-block">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/blockchain"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Blockchain.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Blockchain
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/nft"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/NFT.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    NFT
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/ar-vr"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/ARVR.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    AR/VR
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/iot"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/IOT.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    IoT
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/ai-ml"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/AI.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    AI/ML
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/ev"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/EV.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    EV
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/data-science"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/DataScience.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Data Science
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/cybersecurity"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/CyberSecutrity.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Cybersecurity
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/product-design"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/ProductDesign.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Product Design
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/rpa"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/RPA.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    RPA
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/no-code-low-code"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/NoCodeLowCode.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    No Code/Low Code
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/cloud-computing"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Cloud.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Cloud Computing
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/mobile-apps"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/MobileApp.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Mobile Apps
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/web-apps"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/WebApp.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Web Apps
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/crm"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/CRM.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    CRM
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/api"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/API.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    API
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/microservices"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/Microservice.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Microservices
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/big-data"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/BigData.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    Big Data
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <Link
                                            target="_blank"
                                            to="/digital-innovation/qa-automation"
                                            className="tech-blog-box"
                                        >
                                            <div className="blog-img">
                                                <img
                                                    src={require('../../assets/img/QAAutomation.jpg')}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="blog-title sm-title fw-600 text-start">
                                                    QA Automation
                                                </div>
                                                <div className="ml-auto">
                                                    <span className="uc-right-arrow">
                                                        <img
                                                            src={bi_arrow_down}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default DigitalInnovation
