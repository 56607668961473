import { useState } from 'react'
import bestSeller from '../../assets/dashboard/img/best-seller.png'
import intermediate from '../../assets/dashboard/img/intermediate.png'
import advance from '../../assets/dashboard/img/advance.png'
import StarRating from './StarRating'
import { ToastContainer } from 'react-toastify'
import { toast } from 'react-toastify'
const RateApplicant = ({ handleClose, modalShow }) => {
    const [isChecked, setIsChecked] = useState('')
    const [error, setError] = useState()
    const [rating, setRating] = useState(0)
    const handleClick = (e) => {
        setIsChecked(e.target.id)
    }
    const submitRating = () => {
        if (isChecked) {
            toast.success('Your talent rating submitted.')
            setError('')
            setIsChecked('')
            setRating(0)
            handleClose()
        } else {
            setError('Please select rating')
        }
    }
    return (
        <>
            {/* <ToastContainer  limit={1} hideProgressBar={true} /> */}
            <div
                className={`modal fade dash-custom-modal mxw-550 ${modalShow ? 'show' : ''} `}
                id="rateApplicantModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editRequirmentModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            ></button>
                            <div className="dash-md-title fw-600 dash-black-text">
                                Your Opinion Matters to us
                            </div>
                            <form className="dash-custom-form dash-rating-form mt-20">
                                <StarRating
                                    setRating={setRating}
                                    rating={rating}
                                />
                                <div className="form-group mb-0 mt-20">
                                    <textarea
                                        className="form-control"
                                        placeholder="Leave a message, if you want.."
                                    ></textarea>
                                    <hr className="mt-30" />
                                </div>
                                <div className="dash-rating-list-block row mt-30">
                                    <div className="col-md-4 col-sm-4 col-4">
                                        <div
                                            className={
                                                isChecked == 'bestSeller'
                                                    ? 'selected drl-box chbox'
                                                    : 'drl-box chbox'
                                            }
                                        >
                                            <input
                                                type="radio"
                                                name="ratingType"
                                                id="bestSeller"
                                                value="Best Seller"
                                                onChange={handleClick}
                                            />
                                            <div className="drl-icon">
                                                <img
                                                    src={bestSeller}
                                                    alt="bestSeller"
                                                />
                                            </div>
                                            <div className="dash-sm-title text-center dash-purple-text fw-500 mt-15">
                                                Best Seller
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-4">
                                        <div
                                            className={
                                                isChecked == 'intermediate'
                                                    ? 'selected drl-box chbox'
                                                    : 'drl-box chbox'
                                            }
                                        >
                                            <input
                                                type="radio"
                                                name="ratingType"
                                                id="intermediate"
                                                value="Intermediate"
                                                onChange={handleClick}
                                            />
                                            <div className="drl-icon">
                                                <img
                                                    src={intermediate}
                                                    alt="intermediate"
                                                />
                                            </div>
                                            <div className="dash-sm-title text-center dash-purple-text fw-500 mt-15">
                                                Intermediate
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-4 col-4">
                                        <div
                                            className={
                                                isChecked == 'advance'
                                                    ? 'selected drl-box chbox'
                                                    : 'drl-box chbox'
                                            }
                                        >
                                            <input
                                                type="radio"
                                                name="ratingType"
                                                id="advance"
                                                value="Advance"
                                                onChange={handleClick}
                                            />
                                            <div className="drl-icon">
                                                <img
                                                    src={advance}
                                                    alt="advance"
                                                />
                                            </div>
                                            <div className="dash-sm-title text-center dash-purple-text fw-500 mt-15">
                                                Advance
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-danger text-center">
                                    {error}
                                </div>
                                <div className="mt-30 text-center">
                                    {/* {isChecked ? <button className='dash-theme-btn br-20 fw-500 mnw-250' type='button' data-bs-dismiss="modal" onClick={submitRating}>Submit</button> : <button className='dash-theme-btn br-20 fw-500 mnw-250' type='button' onClick={submitRating}>Submit</button>} */}
                                    <button
                                        className="dash-theme-btn br-20 fw-500 mnw-250"
                                        type="button"
                                        data-bs-dismiss={
                                            isChecked ? 'modal' : ''
                                        }
                                        onClick={submitRating}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RateApplicant
