import { useEffect, useState } from 'react'
import AddTeamMemberModal from '../Myteams/AddTeamMemberModal'
import ImportCsvModal from '../Myteams/ImportCsvModal'
import { Link } from 'react-router-dom'
import { useApiService } from '../../Services/ApiInstance'
import RenderHTML from '../Pages/RenderHTML'
import { Box, SvgIcon, Tooltip } from '@mui/material'
import { ReactComponent as DrawIcon } from '../../assets/dashboard/img/icons8-info.svg'

const Overview = ({ dashboardData, resourceUtilization }) => {
    const {
        low_utilization,
        moderate_utilization,
        higher_utilization,
        maximum_utilization,
    } = resourceUtilization
    const { getRequest, GET_CURRENT_SUBSCRIPTION } = useApiService()
    // Add Team Member
    let user = JSON.parse(localStorage.getItem('user'))
    const [reload, setReload] = useState(false)
    const [openAddTeamModal, setOpenAddTeamModal] = useState(false)
    const addTeamMemberModal = () => {
        setOpenAddTeamModal(true)
    }
    //Import Csv
    const [openAddImportCsvModal, setOpenAddImportCsvModal] = useState(false)
    const openImportCsvModal = () => {
        setOpenAddImportCsvModal(true)
    }
    const [currentSubscription, setCurrentSubscription] = useState({})

    useEffect(() => {
        getRequest(GET_CURRENT_SUBSCRIPTION).then((res) => {
            var response = res?.data?.data
            console.log(response)
            setCurrentSubscription(
                Object.keys(response.currect_subscription).length != 0
                    ? response.currect_subscription
                    : {}
            )
        })
    }, [])

    const properCaseTeamMembers = (text) => {
        return text
            .split(' ')
            .map((word) => {
                if (word === '(s)') {
                    return '(s)'
                } else {
                    return (
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                }
            })
            .join(' ')
    }

    const renderHtml = (string) => {
        const finalHtml = (
            <Box
                sx={{
                    maxHeight: '34px',
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                {<RenderHTML html={string} />}
                <Tooltip
                    title={<RenderHTML html={string} />}
                    arrow
                    slotProps={{
                        popper: { className: 'tooltipPrimMain' },
                    }}
                >
                    <span>
                        <SvgIcon
                            sx={{ width: '16px', height: '16px' }}
                            viewBox="0 0 32 32"
                            component={DrawIcon}
                            inheritViewBox
                        />
                    </span>
                </Tooltip>
            </Box>
        )
        return finalHtml
    }


    return (
        <>
            <div className="d-flex ad-dash-block">
                <div className="ad-dash-card1">
                    <div className="dash-mteam-card ad-mteam-card ad-dash-tc-red mt-0">
                        <div className="ad-md-title fw-600 white-text ad-font-1-2em">
                            Team Utilization
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div>
                                <div className="ad-md-title fw-600 text-uppercase dash-white-text ad-font-2-6em">
                                    {dashboardData?.total_team_count}
                                </div>
                                <div className="ad-md-title fw-600 dash-white-text ad-font-1em ad-opacity-8">
                                    {properCaseTeamMembers('Team member(s)')}
                                </div>
                            </div>
                            <div>
                                <div className="ad-pie-process ad-hs-value ad-dash-progressbar">
                                    <div
                                        role="progressbar"
                                        aria-valuenow={Math.min(
                                            dashboardData?.teamUtilizationPercentage ||
                                            0,
                                            999
                                        )}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                            '--value': Math.min(
                                                dashboardData?.teamUtilizationPercentage ||
                                                0,
                                                999
                                            ),
                                            // "--fg":
                                            //     dashboardData?.color_code_for_pending_task || "",
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                        {!user.is_invite_team_member && (
                            <div className="mt-3">
                                <button
                                    className="ad-add-team ad-theme-btn ad-theme-btn ad-xs-btn fw-500"
                                    type="button"
                                    onClick={addTeamMemberModal}
                                >
                                    <span className="add-team-icon"></span> Add
                                    Team Member
                                </button>
                                <button
                                    type="button"
                                    className="blue-download-csv download-csv import-csv-btn ms-4"
                                    onClick={openImportCsvModal}
                                >
                                    <span className="line-file-icon"></span>{' '}
                                    Import .xlsx
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ad-dash-card2">
                    <div className="ad-mteam-card ad-white-card pd-mteam-card">
                        <div className="ad-md-title fw-600 ad-font-1-2em">
                            Team Utilization Breakdown
                        </div>
                        <div className="ru-table-box mt-2">
                            <table className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div
                                                className="ru-processbar"
                                                style={{
                                                    background: '#ED3342',
                                                    width: '10px',
                                                }}
                                            ></div>
                                        </td>
                                        <td className="ru-slot w-50 text-start">
                                            <span>
                                                {low_utilization?.range?.start}%
                                            </span>{' '}
                                            to{' '}
                                            <span>
                                                {low_utilization?.range?.end}%
                                            </span>
                                        </td>
                                        <td>
                                            <div className="avatar-group d-flex cs-avatar-flex">
                                                {low_utilization?.members
                                                    .slice(0, 4)
                                                    .map((item) => (
                                                        <span
                                                            key={
                                                                item.team_member
                                                                    .id
                                                            }
                                                            className="avatar avatar-xs rounded-circle"
                                                            style={{
                                                                background:
                                                                    item
                                                                        .team_member
                                                                        .color_code ||
                                                                    '#552FBA',
                                                                border: `1px solid ${item.team_member.color_code}`,
                                                            }}
                                                        >
                                                            {item.team_member
                                                                .image_with_url ? (
                                                                <img
                                                                    src={
                                                                        item
                                                                            .team_member
                                                                            .image_with_url
                                                                    }
                                                                    alt={
                                                                        item
                                                                            .team_member
                                                                            .first_name
                                                                    }
                                                                />
                                                            ) : (
                                                                <span className="talent-short-name dash-font-0-8em fw-600">
                                                                    {
                                                                        item
                                                                            .team_member
                                                                            .short_name
                                                                    }
                                                                </span>
                                                            )}
                                                        </span>
                                                    ))}
                                            </div>
                                        </td>
                                        <td className="ru-team-count text-end text-nowrap">
                                            <span className="sm-user-icon"></span>{' '}
                                            {low_utilization?.count || '0'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                className="ru-processbar"
                                                style={{
                                                    background: '#F57A37',
                                                    width: '20px',
                                                }}
                                            ></div>
                                        </td>
                                        <td className="ru-slot w-50 text-start">
                                            <span>
                                                {
                                                    moderate_utilization?.range
                                                        ?.start
                                                }
                                                %
                                            </span>{' '}
                                            to{' '}
                                            <span>
                                                {
                                                    moderate_utilization?.range
                                                        ?.end
                                                }
                                                %
                                            </span>
                                        </td>
                                        <td>
                                            <div className="avatar-group d-flex cs-avatar-flex">
                                                {moderate_utilization?.members
                                                    .slice(0, 4)
                                                    .map((item) => (
                                                        <span
                                                            key={
                                                                item.team_member
                                                                    .id
                                                            }
                                                            className="avatar avatar-xs rounded-circle"
                                                            style={{
                                                                background:
                                                                    item
                                                                        .team_member
                                                                        .color_code ||
                                                                    '#552FBA',
                                                                border: `1px solid ${item.team_member.color_code}`,
                                                            }}
                                                        >
                                                            {item.team_member
                                                                .image_with_url ? (
                                                                <img
                                                                    src={
                                                                        item
                                                                            .team_member
                                                                            .image_with_url
                                                                    }
                                                                    alt={
                                                                        item
                                                                            .team_member
                                                                            .first_name
                                                                    }
                                                                />
                                                            ) : (
                                                                <span className="talent-short-name dash-font-0-8em fw-600">
                                                                    {
                                                                        item
                                                                            .team_member
                                                                            .short_name
                                                                    }
                                                                </span>
                                                            )}
                                                        </span>
                                                    ))}
                                            </div>
                                        </td>
                                        <td className="ru-team-count text-end text-nowrap">
                                            <span className="sm-user-icon"></span>{' '}
                                            {moderate_utilization?.count || '0'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                className="ru-processbar"
                                                style={{
                                                    background: '#EDB62E',
                                                    width: '30px',
                                                }}
                                            ></div>
                                        </td>
                                        <td className="ru-slot w-50 text-start">
                                            <span>
                                                {
                                                    higher_utilization?.range
                                                        ?.start
                                                }
                                                %
                                            </span>{' '}
                                            to{' '}
                                            <span>
                                                {higher_utilization?.range?.end}
                                                %
                                            </span>
                                        </td>
                                        <td>
                                            <div className="avatar-group d-flex cs-avatar-flex">
                                                {higher_utilization?.members
                                                    .slice(0, 4)
                                                    .map((item) => (
                                                        <span
                                                            key={
                                                                item.team_member
                                                                    .id
                                                            }
                                                            className="avatar avatar-xs rounded-circle"
                                                            style={{
                                                                background:
                                                                    item
                                                                        .team_member
                                                                        .color_code ||
                                                                    '#552FBA',
                                                                border: `1px solid ${item.team_member.color_code}`,
                                                            }}
                                                        >
                                                            {item.team_member
                                                                .image_with_url ? (
                                                                <img
                                                                    src={
                                                                        item
                                                                            .team_member
                                                                            .image_with_url
                                                                    }
                                                                    alt={
                                                                        item
                                                                            .team_member
                                                                            .first_name
                                                                    }
                                                                />
                                                            ) : (
                                                                <span className="talent-short-name dash-font-0-8em fw-600">
                                                                    {
                                                                        item
                                                                            .team_member
                                                                            .short_name
                                                                    }
                                                                </span>
                                                            )}
                                                        </span>
                                                    ))}
                                            </div>
                                        </td>
                                        <td className="ru-team-count text-end text-nowrap">
                                            <span className="sm-user-icon"></span>{' '}
                                            {higher_utilization?.count || '0'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div
                                                className="ru-processbar"
                                                style={{
                                                    background: '#24BFA8',
                                                    width: '40px',
                                                }}
                                            ></div>
                                        </td>
                                        <td className="ru-slot w-50 text-start">
                                            <span>
                                                {
                                                    maximum_utilization?.range
                                                        ?.start
                                                }
                                                %
                                            </span>{' '}
                                            to{' '}
                                            <span>
                                                {
                                                    maximum_utilization?.range
                                                        ?.end
                                                }
                                                %
                                            </span>
                                        </td>
                                        <td>
                                            <div className="avatar-group d-flex cs-avatar-flex">
                                                {maximum_utilization?.members
                                                    .slice(0, 4)
                                                    .map((item) => (
                                                        <span
                                                            key={
                                                                item.team_member
                                                                    .id
                                                            }
                                                            className="avatar avatar-xs rounded-circle"
                                                            style={{
                                                                background:
                                                                    item
                                                                        .team_member
                                                                        .color_code ||
                                                                    '#552FBA',
                                                                border: `1px solid ${item.team_member.color_code}`,
                                                            }}
                                                        >
                                                            {item.team_member
                                                                .image_with_url ? (
                                                                <img
                                                                    src={
                                                                        item
                                                                            .team_member
                                                                            .image_with_url
                                                                    }
                                                                    alt={
                                                                        item
                                                                            .team_member
                                                                            .first_name
                                                                    }
                                                                />
                                                            ) : (
                                                                <span className="talent-short-name dash-font-0-8em fw-600">
                                                                    {
                                                                        item
                                                                            .team_member
                                                                            .short_name
                                                                    }
                                                                </span>
                                                            )}
                                                        </span>
                                                    ))}
                                            </div>
                                        </td>
                                        <td className="ru-team-count text-end text-nowrap">
                                            <span className="sm-user-icon"></span>{' '}
                                            {maximum_utilization?.count || '0'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="ad-dash-card3">
                    <div className="ad-mteam-card ad-white-card pd-mteam-card">
                        <div className="ad-md-title fw-600 ad-font-1-2em">
                            Current Plan
                        </div>
                        <div className="d-flex align-items-center mt-3 mb-3">
                            <div className="ad-st-count ad-ru-count">
                                <div className="ad-st-title1">
                                    {currentSubscription?.total_no_of_licences ||
                                        '-'}
                                </div>
                                <div className="ad-xs-title dash-font-0-8em white-text">
                                    Users
                                </div>
                            </div>
                            <div className="ps-2">
                                {console.log(
                                    renderHtml(
                                        currentSubscription.custom_features
                                    )
                                )}
                                <Box sx={{ display: 'flex' }}>
                                    <div className="ad-xs-title ad-green-text currentplan" >
                                        {currentSubscription?.plan_name || '-'}
                                    </div>
                                    {currentSubscription?.custom_features &&
                                        currentSubscription.custom_features
                                            .length > 0 &&
                                        currentSubscription.custom_features
                                            .length > 15 && (
                                            <Tooltip
                                                title={
                                                    // <RenderHTML
                                                    //     html={
                                                    //         // currentSubscription.custom_features
                                                    //         "<ul><li><strong>Add upto 15 team members</strong></li><li><strong>Track upto 15 projects</strong></li><li><strong>Jira &amp; Asana Integration for performance tracking</strong></li><li><strong>Client Invoicing</strong></li><li><strong>Resource Utilization Dashboard</strong></li><li><strong>Sprint progress tracking &amp; performance report</strong></li></ul>"
                                                    //     }


                                                    // />
                                                    <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                                        <RenderHTML
                                                            html={
                                                                "<ul style='padding: 0; margin: 0; list-style: none;'><li><strong>Add upto 15 team members</strong></li><li><strong>Track upto 15 projects</strong></li><li><strong>Jira &amp; Asana Integration for performance tracking</strong></li><li><strong>Client Invoicing</strong></li><li><strong>Resource Utilization Dashboard</strong></li><li><strong>Sprint progress tracking &amp; performance report</strong></li></ul>"
                                                            }
                                                        />
                                                    </div>
                                                }
                                                slotProps={{
                                                    popper: {
                                                        className:
                                                            'tooltipPrimMain',
                                                        modifiers: [
                                                            {
                                                                name: "offset",
                                                                options: {
                                                                    offset: [-30, 0],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    tooltip: {
                                                        sx: {
                                                            marginLeft: '10px',
                                                        }
                                                    },
                                                }}
                                            >
                                                <SvgIcon
                                                    sx={{
                                                        width: '12px',
                                                        height: '12px',
                                                        ml: '5px',
                                                        mt: '3px',
                                                    }}
                                                    viewBox="0 0 32 32"
                                                    component={DrawIcon}
                                                    inheritViewBox
                                                />
                                            </Tooltip>
                                        )}
                                </Box>

                                {currentSubscription?.custom_features &&
                                    currentSubscription?.custom_features !=
                                    '-' && (
                                        <Box
                                            sx={{
                                                maxHeight: '34px',
                                                overflow: 'hidden',
                                            }}
                                            className="ad-xs-title dash-font-0-8em"
                                        >
                                            {
                                                <RenderHTML
                                                    html={
                                                        currentSubscription.custom_features
                                                    }
                                                />
                                            }
                                        </Box>
                                    )}
                            </div>
                        </div>
                        {/* <div className='mt-3 ad-xs-title dash-font-0-8em'>{currentSubscription?.estimated_billing ? <>Renews on <span className='ad-lgray-text'>{currentSubscription?.end_date}</span></> : <span>&nbsp;</span>}</div> */}
                        {!user.is_invite_team_member && (
                            <Link
                                to="/dashboard/settings/billing-subscription/select-plan"
                                style={{ display: 'inline-block' }}
                                className="mt-2 ad-add-team ad-theme-btn ad-theme-btn ad-xs-btn fw-500"
                                type="button"
                            >
                                Upgrade Plan
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <AddTeamMemberModal
                openAddTeamModal={openAddTeamModal}
                setOpenAddTeamModal={setOpenAddTeamModal}
                setReload={setReload}
            />
            <ImportCsvModal
                openAddImportCsvModal={openAddImportCsvModal}
                setOpenAddImportCsvModal={setOpenAddImportCsvModal}
                setReload={setReload}
            />
        </>
    )
}

export default Overview
