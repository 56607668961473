import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import Fancybox from './Fancybox'
import NoImage from '../../../assets/dashboard/img/no-image.svg'
import moment from 'moment'
import { Box } from '@mui/material'

const TalentCertificate = ({ certification }) => {
    let randomLen = Math.floor(Math.random() * 10 + 1)

    return (
        <>
            {/* {console.log(certifications)} */}

            <Box sx={{ width: '100%' }}>
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}
                >
                    <OwlCarousel
                        className="owl-theme portfolio-slider mt-10"
                        loop={false}
                        margin={10}
                        items={2}
                        nav={false}
                        lazyLoad={true}
                        key={`carousel_${randomLen}`}
                    >
                        {certification &&
                            certification.map((item, index) => {
                                return (
                                    <Box
                                        sx={{
                                            '&.MuiBox-root .dash-port-img img.MuiBox-root':
                                                {
                                                    maxHeight: '140px',
                                                    maxWidth:
                                                        'calc(100% - 20px)',
                                                    display: 'inline-block',
                                                },
                                        }}
                                        className="item"
                                        key={index}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            className="dash-port-img"
                                        >
                                            <a
                                                data-fancybox="gallery"
                                                href={item.certificate_image}
                                            >
                                                <Box
                                                    component="img"
                                                    src={
                                                        item?.certificate_image ||
                                                        NoImage
                                                    }
                                                    alt={item?.name || ''}
                                                />
                                            </a>
                                        </Box>
                                        <div className="dash-md-title dash-font-0-9em ad-dgray-text fw-600 mt-2">
                                            {item?.name || ''}
                                        </div>
                                        <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                                            {item?.organization || ''}
                                        </div>
                                        <div className="dash-md-title dash-font-0-9em ad-gray-text fw-500">
                                            {item?.start_date
                                                ? moment(
                                                      item.start_date
                                                  ).format('DD-MMM-YYYY')
                                                : ''}{' '}
                                            to{' '}
                                            {item?.end_date
                                                ? moment(item.end_date).format(
                                                      'DD-MMM-YYYY'
                                                  )
                                                : ''}
                                        </div>
                                    </Box>
                                )
                            })}
                    </OwlCarousel>
                </Fancybox>
            </Box>
        </>
    )
}

export default TalentCertificate
