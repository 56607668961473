import React from 'react'

const AgencyModal = ({
    open,
    title,
    close,
    children,
    clsSm,
    modalStyle,
    dialogStyle,
    closeModal,
}) => {
    return (
        <>
            <div
                style={{ ...(modalStyle && { ...modalStyle }) }}
                className={`modal fade ad-custom-modal ${open ? 'show' : ''} ${clsSm ? 'invoice-md-modal' : ''}`}
                id="commonSMModal"
                tabIndex="-1"
                aria-labelledby="commonSMModalLabel"
                aria-hidden="true"
            >
                {(title || closeModal) && (
                    <div className="modal-header">
                        {title && (
                            <h5 className="modal-title" id="commonSMModalLabel">
                                {title}
                            </h5>
                        )}
                        {closeModal && (
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => {
                                    closeModal()
                                }}
                            ></button>
                        )}
                    </div>
                )}
                <div
                    style={{ ...(dialogStyle && { ...dialogStyle }) }}
                    className="modal-dialog modal-lg modal-dialog-centered"
                >
                    <div className="modal-content">{children}</div>
                </div>
            </div>
        </>
    )
}

export default AgencyModal
