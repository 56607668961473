import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import ValidationError from '../../../Components/Errors/ValidationError'
import { toast, ToastContainer } from 'react-toastify'
import AuthUser from '../../../Services/AuthUser'
import BtnSpinner from '../../../Components/Spinners/BtnSpinner'
import { IconButton, Box, SvgIcon } from '@mui/material'
import { ReactComponent as CloseIcon } from '../../../assets/agency/img/close-icon-dynamic.svg'

const validationSchemaTeam = yup.object({
    access_token: yup.string().required('This field is required!'),
})
const AsanaConnectModal = ({ close, connectAsanaSuccess, projectId }) => {
    const { http2 } = AuthUser()
    const [isLoading, setIsLoading] = useState(false)
    const updateAsanaDetails = (fields) => {
        try {
            setIsLoading(true)
            toast.dismiss()
            http2
                .post(`/connect-project-from-asana/${projectId}`, {
                    asana_token: fields.access_token,
                })
                .then((response) => {
                    let result = response.data
                    if (result.success) {
                        toast.success(result.message, {
                            toastId: 'success1',
                            onOpen: () => {
                                setIsLoading(false)
                                connectAsanaSuccess()
                            },
                        })
                    }
                    if (result.success === false) {
                        toast.error(result.data, {
                            toastId: 'error1',
                            // onOpen:()=>close()
                        })
                        setIsLoading(false)
                        close()
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log('error', error)
                        setIsLoading(false)
                        toast.error(
                            error?.response?.data?.error
                                ? error.response.data.error
                                : 'Oops something went wrong!',
                            {
                                toastId: 'error2',
                                // onOpen:()=>close()
                            }
                        )
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    access_token: '',
                }}
                validationSchema={validationSchemaTeam}
                onSubmit={(values, { resetForm }) => {
                    console.log(values)
                    updateAsanaDetails(values)
                    resetForm()
                }}
            >
                {({
                    values,
                    setFieldValue,
                    field,
                    form,
                    formik,
                    resetForm,
                }) => (
                    <>
                        <div
                            className="modal-header"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#552FBA',
                                position: 'relative',
                                borderTopLeftRadius: '5px',
                                borderTopRightRadius: '5px',
                            }}
                        >
                            <h5
                                className="modal-title"
                                id="commonSMModalLabel"
                                style={{
                                    flex: 1,
                                    textAlign: 'center',
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                Connect With Asana
                            </h5>
                            <Box sx={{ position: 'absolute', right: 0 }}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        resetForm()
                                        close()
                                    }}
                                    sx={{
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#552FBA',
                                        },
                                        marginBottom: '4px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginRight: '10px',
                                    }}
                                >
                                    <SvgIcon
                                        component={CloseIcon}
                                        inheritViewBox
                                    />
                                </IconButton>
                            </Box>
                        </div>

                        <div className="modal-body">
                            <Form autoComplete="off">
                                <div className="custom-form ad-custom-form ad-form-theme2 row">
                                    <div className="col-12 form-group">
                                        <div className="position-relative">
                                            <label className="label-pos">
                                                Asana Access Token*
                                            </label>
                                            <Field
                                                type="text"
                                                name="access_token"
                                                className="form-control"
                                            />
                                            <ValidationError name="access_token" />
                                        </div>
                                    </div>

                                    <div className="mt-2 col-12">
                                        {isLoading ? (
                                            <BtnSpinner />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="ad-theme-btn w-100"
                                            >
                                                Configure Asana
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AsanaConnectModal
