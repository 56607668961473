import Layout from '../../Layouts/Layout'

const TermsOfUse = () => {
    return (
        <>
            <Layout>
                <div className="container">
                    <div className="common-content">
                        <div className="main-heading text-center">
                            Terms of Use
                        </div>
                        <p className="mt-3">
                            Welcome to Weteams.io (the “Platform”). By accessing
                            or using the Platform, you agree to comply with and
                            be bound by the following terms and conditions of
                            use (the “Terms of Use”). These Terms govern your
                            access to and use of the services, products, and
                            functionalities provided by Weteams.io, including
                            any content, data, or materials available on the
                            Platform.
                        </p>
                        <h3>1. Acceptance of Terms</h3>
                        <p>
                            By using the Platform, you agree to abide by these
                            Terms of Use and all applicable laws and
                            regulations. If you do not agree to these Terms, you
                            must not use the Platform.
                        </p>
                        <h3>2. Eligibility</h3>
                        <p>
                            Weteams.io is available to all users, regardless of
                            age. By using the Platform, you represent that you
                            have the legal capacity to enter into and comply
                            with these Terms of Use. If you are using the
                            Platform on behalf of an organization, you warrant
                            that you are authorized to do so.
                        </p>
                        <h3>3. User Accounts</h3>
                        <p>
                            You are responsible for maintaining the
                            confidentiality of your account information,
                            including your username and password. You agree to
                            notify us immediately of any unauthorized use of
                            your account. Weteams.io will not be liable for any
                            loss or damage arising from your failure to
                            safeguard your account.
                        </p>
                        <h3>4. Services Provided</h3>
                        <p>
                            Weteams.io provides access to:
                            <ul className="pb-0">
                                <li className="list-style-type-disc p-0">
                                    Managed Teams Services
                                </li>
                                <li className="list-style-type-disc p-0">
                                    A SaaS platform for Agency Management
                                </li>
                            </ul>
                        </p>
                        <h3>5. Subscription and Payment Terms</h3>
                        <p>
                            Certain features of the Platform are available only
                            through a paid subscription. By subscribing to the
                            Platform’s services, you agree to provide accurate
                            payment information and authorize Weteams.io to
                            charge the subscription fees to your chosen payment
                            method.
                        </p>
                        <h3>6. Refund and Cancellation Policy</h3>
                        <p>
                            All subscription fees paid to Weteams.io are
                            <b> non-refundable</b>. Once a subscription is
                            purchased, it <b> cannot be canceled</b> for the
                            remainder of the subscription term. In the event of
                            a subscription renewal, the user is responsible for
                            canceling the subscription before the renewal date
                            if they wish to stop using the services.
                        </p>
                        <p>
                            We reserve the right to change subscription fees,
                            pricing models, and the refund policy at our
                            discretion.
                        </p>
                        <h3>7. Use of the Platform </h3>
                        <p>
                            You agree to use the Platform for lawful purposes
                            only. You are prohibited from:
                            <ul className="pb-0">
                                <li className="list-style-type-disc p-0">
                                    Engaging in any fraudulent activity or
                                    misrepresentation
                                </li>
                                <li className="list-style-type-disc p-0">
                                    Violating any applicable laws, regulations,
                                    or third-party rights
                                </li>
                                <li className="list-style-type-disc p-0">
                                    Accessing or attempting to access areas of
                                    the Platform you are not authorized to use
                                </li>
                                <li className="list-style-type-disc p-0">
                                    Uploading viruses, malware, or any harmful
                                    content
                                </li>
                            </ul>
                        </p>
                        <h3>8. User Content</h3>
                        <p>
                            The Platform may allow you to upload, store, and
                            share content. By submitting content, you grant
                            Weteams.io a non-exclusive, royalty-free, worldwide
                            license to use, display, and distribute the content
                            within the Platform as necessary for providing the
                            services.
                        </p>
                        <p>
                            You are responsible for ensuring that your content
                            does not infringe any intellectual property rights
                            or violate any laws.
                        </p>
                        <h3>9. Termination of Access</h3>
                        <p>
                            Weteams.io reserves the right to terminate or
                            suspend your account and access to the Platform at
                            its sole discretion, without notice, for any
                            violation of these Terms or for any reason deemed
                            necessary to protect the integrity of the Platform.
                        </p>
                        <h3>10. Limitation of Liability</h3>
                        <p>
                            To the fullest extent permitted by law, Weteams.io
                            shall not be liable for any indirect, incidental,
                            special, or consequential damages, including loss of
                            profits or data, arising from your use of or
                            inability to use the Platform.
                        </p>
                        <h3>11. Indemnification</h3>
                        <p>
                            You agree to indemnify, defend, and hold harmless
                            Weteams.io and its affiliates from any claims,
                            damages, liabilities, costs, or expenses (including
                            attorneys' fees) arising from your use of the
                            Platform, violation of these Terms, or infringement
                            of any third-party rights.
                        </p>
                        <h3>12. Governing Law</h3>
                        <p>
                            These Terms of Use shall be governed by and
                            construed in accordance with the laws of India where
                            Weteams.io is based, without regard to its conflict
                            of law principles.
                        </p>
                        <h3>13. Modifications to Terms</h3>
                        <p>
                            Weteams.io reserves the right to modify these Terms
                            of Use at any time. We will notify users of any
                            significant changes by posting the updated Terms on
                            the Platform. Your continued use of the Platform
                            following the posting of changes will constitute
                            your acceptance of such changes.
                        </p>
                        <h3>14. Contact Information</h3>
                        <p>
                            If you have any questions about these Terms of Use,
                            please contact us at: <br />
                            <b>Email:</b> hello@weteams.io
                        </p>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default TermsOfUse
